//@ts-nocheck
import { Box, colors, styled } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { theme } from '../../../../theme/theme';
import ContactDetails from './ContactDetails';
import LinkedAccounts from './LinkedAccounts';
import LinkedOpportunities from './LinkedOpportunities';
import { Contact } from 'ReduxStore/Reducers/opportunity/contacts.reducer';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    right: 8,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    borderRadius: '4px',
    width: 24,
    height: 22,
  },
}));

const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: '48px',
    height: '48px',
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
  },
  customSubTab: {
    minHeight: 42,
    color:'#000056',
    borderBottom: '3px solid #ffffff',
  },
  customSubTabPanel: {
    padding: '16px 0px',
  },
};

type Props = {
  closeViewModal: () => void;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChangeFromMainLayout:(
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  contactToBeEdited: Contact,
  commentSectionOpened: boolean;
};

function ContactsViewLayout({
  closeViewModal,
  setShowAddNew,
  setShowViewModal,
  handleTabChangeFromMainLayout,
  contactToBeEdited,
  commentSectionOpened,
}: Props) {
  const [tabValue, setTabValue] = React.useState('1');
  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <Box className="Opportunity-Contacts">
      <TabContext value={tabValue}>
        <Box
          display={'flex'}
          alignItems={'center'}
        >
          <Box>
            <IconButton size="small" onClick={closeViewModal}>
              <KeyboardBackspaceIcon color="primary" />
            </IconButton>
          </Box>
          <TabList
            style={styles.customTabList}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
            aria-label="lab API tabs example"
          >
            <Tab
              style={styles.customSubTab}
              // icon={<img alt="" src={AccountIcon} />}
              // iconPosition="start"
              label={
                <StyledBadge badgeContent={0} color="primary">
                  <Box sx={{}}>Details</Box>
                </StyledBadge>
              }
              value="1"
            ></Tab>
            <Tab
              style={styles.customSubTab}
              // icon={<img alt="" src={OpportunityIcon} />}
              // iconPosition="start"
              label={
                <StyledBadge badgeContent={0} color="primary">
                  <Box sx={{}}>Linked Accounts</Box>
                </StyledBadge>
              }
              value="2"
            >
              {/* @ts-ignore */}
              {' '}
            </Tab>
            <Tab
              style={styles.customSubTab}
              // icon={<img alt="" src={ContactsIcon} />}
              // iconPosition="start"
              label={
                <StyledBadge badgeContent={0} color="primary">
                  <Box sx={{}}>Linked Opportunities</Box>
                </StyledBadge>
              }
              value="3"
            >
              {/* @ts-ignore */}
              {' '}
            </Tab>

              {/*------ commented as per prithvi's required ------*/}
            {/* <Tab
              style={styles.customSubTab}
              // icon={<img alt="" src={ContactsIcon} />}
              // iconPosition="start"
              label={
                <StyledBadge badgeContent={0} color="primary">
                  <Box sx={{}}>Change logs</Box>
                </StyledBadge>
              }
              value="4"
            >

              {' '}
            </Tab> */}

          </TabList>
        </Box>
        <TabPanel style={styles.customSubTabPanel} value="1">
          <ContactDetails
            contactToBeEdited={contactToBeEdited}
            closeViewModal={closeViewModal}
            setShowAddNew={setShowAddNew}
            setShowViewModal={setShowViewModal}
            commentSectionOpened={commentSectionOpened}
          />
        </TabPanel>
        <TabPanel style={styles.customSubTabPanel} value="2">
          <LinkedAccounts contactId={contactToBeEdited._id}  handleTabChangeFromMainLayout={handleTabChangeFromMainLayout} reqFrom={"linkedAccount"}/>
        </TabPanel>
        <TabPanel style={styles.customSubTabPanel} value="3">
          <LinkedOpportunities contactId={contactToBeEdited._id} handleTabChangeFromMainLayout={handleTabChangeFromMainLayout } reqFrom={"linkedOpportunity"}/>
        </TabPanel>
        <TabPanel style={styles.customSubTabPanel} value="4">
          <Box>
            <TableContainer className="list-table">
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="long-text sticky-cell">
                      Account Name
                    </TableCell>
                    <TableCell className="text">Contact Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="email sticky-cell link">
                      {' '}
                      Streamline Dynamics
                    </TableCell>

                    <TableCell className="text">HR Contact</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="email sticky-cell link">
                      {' '}
                      Streamline Dynamics
                    </TableCell>

                    <TableCell className="text">HR Contact</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="email sticky-cell link">
                      {' '}
                      Streamline Dynamics
                    </TableCell>

                    <TableCell className="text">HR Contact</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="email sticky-cell link">
                      {' '}
                      Streamline Dynamics
                    </TableCell>

                    <TableCell className="text">HR Contact</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default ContactsViewLayout;


import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import { Stack } from '@mui/material';
// import '../../sass/main.scss';
import SearchIconBar from '../../assets/images/searchIco.png';
import { Button, FormGroup } from '@mui/material';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Pagination from 'react-js-pagination';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddUserModal from './addUserModal';
import UpdateUserModal from './updateUserModal';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import moment from 'moment';

import Grid from '@mui/material/Grid2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import ImportIcon from '../../assets/images/icons/import.svg';
import ExportIcon from '../../assets/images/icons/export.svg';
import SearchIcon from '../../assets/images/icons/search.svg';
import InfoIcon from '../../assets/images/icons/setting-user-Info.svg';
import DeleteIcon from '../../assets/images/icons/setting-user-delete.svg';
import InviteIcon from '../../assets/images/icons/setting-user-mail.svg';
import StarIcon from '../../assets/images/icons/setting-user-star.svg';
import MailIcon from '../../assets/images/icons/email.svg';
import EditIcon from '../../assets/images/icons/edit.svg';
import accountMultipleOutline from '../../assets/images/icons/account-multiple-outline.svg';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/styles';
import MoreIcon from '../../assets/images/icons/more.svg';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import RoundBadge from '../../Components/CustomBadges/RoundBadge';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Radio, RadioGroup } from '@mui/material';
import downArrow from '../../assets/images/acor-down-arrow.svg';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { includes } from 'lodash';
import filterIcon from '../../assets/images/icons/filter_alt.svg';
import filterIconWhite from '../../assets/images/icons/filter_alt_white.svg';
import ClearIcon from '@mui/icons-material/Clear';


class Users extends Component {
  constructor(props) {
    super(props);
    this.anchorRefFilter1 = React.createRef();
    this.anchorRefFilter3 = React.createRef();
    this.anchorRefFilter2 = React.createRef();
    this.anchorRef3 = React.createRef();
    this.anchorRef4 = React.createRef();
    this.permissionsMultiSelectArray = [];
    this.anchorRefAction = React.createRef();
    this.checkBoxIdsArr = [];
    this.checkBoxIdsArr2 = [];
    this.checkBoxIdsArr3 = [];
    this.checkBoxIdsArr4 = [];
    this.anchorRef = React.createRef();
    this.state = {
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      updateOpen: false,
      singleData: {},
      data: [],
      users: [],
      showDelete: false,
      InviteUserModal: false,
      ResendInviteModal: false,
      invitedEmail: "",
      roleAssign: 2,
      openDeleteConfirmation: false,
      openChangeRoleConfirmation: false,
      openChangeStatusConfirmation: false,
      changeRoleType: "",
      changeStatusType: "",
      selectedUser: null,
      openFilter1: false,
      openFilter2: false,
      openFilter3: false,
      typeFilterOptionsList: ['Co-Admin', 'Member'],
      typeFilterOptionsSelected: [],
      statusFilterOptionsList: ['Active', 'Disabled', 'Pending Invite'],
      statusFilterOptionsSelected: [],
      itemCount: 10,
      sort_by: "createdAt",
      sortOrder: "desc",
      startCount: 1,
      openMultiSelectForPermissions: false,
      openMultiSelectForEditPermissions: false,
      permissionsSelected: [],
      editUserPermissionModal: false,
      searchText: '',
      openAssignManagerModel: false,
      checkedData: false,
      checkedAll: false,
      changeState: false,
      manager: [],
      SearchDropdown: false,
      SearchEnabled: false,
      email: "",
      allUserList: [],
      unAssign: false,
      checkAllmanager: false,
      allFilterData: [""],
      unassignedCount: 0,
      isEdit: false,
      filterByPermission: [],
      openPermissionFilter: false,
      permissionCheckboxes: {
        'Audience': false,
        'AI Agent': false,
        "Opportunity Management": false
      },
    };
  }

  componentDidMount() {
    if (this.props) {
      this.getUsersList();
      this.props.getPermissionList({ group_name: "Audience" });
      this.handleLoading();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data.assignManagerSuccess !==
      prevProps.data.assignManagerSuccess &&
      this.props.data.assignManagerSuccess === true
    ) {
      createNotification('success', this.props.data.message);
      this.checkBoxIdsArr = [];
      this.checkBoxIdsArr2 = [];
      this.setState({
        errors: {},
        unAssign: false,
        loading: false,
        openAssignManagerModel: false,
        email: "",
        manager: []
      }, () => this.getUsersList());
    }

    if (
      this.props.data.allUserListSuccess !==
      prevProps.data.allUserListSuccess &&
      this.props.data.allUserListSuccess === true
    ) {
      this.setState({
        loading: false,
        allUserList: this.props.data.allUserList,
      });
    }

    if (this.props.data.listSuccess === true && prevProps.data.listSuccess === false && this.props.data.users.message !== prevProps.data.message) {
      this.props.data.listSuccess = false;
      this.checkBoxIdsArr = [];
      this.checkBoxIdsArr2 = [];
      this.setState({
        checkedData: false,
        loading: false,
        unassignedCount: this.props.data &&
          this.props.data.filterByManager &&
          this.props.data.filterByManager.unassignManager &&
          this.props.data.filterByManager.unassignManager[0] &&
          this.props.data.filterByManager.unassignManager[0].count !== undefined ? this.props.data?.filterByManager?.unassignManager[0]?.count : 0
      });
      this.updatePaginationDropDownState();
    }

    if (this.props.data.deleteUserSuccess === true && prevProps.data.deleteUserSuccess === false && this.props.data.message !== prevProps.data.message) {
      this.setState({
        openDeleteConfirmation: false,
        selectedUser: null
      });
      createNotification('success', this.props.data.message);
      if (this.state.loading) { this.handleLoading(); }
      this.updatePaginationDropDownState();

    }

    if (this.props.data.inviteUserSuccess === true && prevProps.data.inviteUserSuccess === false && this.props.data.message !== prevProps.data.message) {
      this.checkBoxIdsArr3 = [];
      this.setState({
        invitedEmail: '',
        InviteUserModal: false,
        ResendInviteModal: false,
        open: false,
        permissionsSelected: []
      });
      createNotification("success", this.props.data.message);
      if (this.state.loading) { this.handleLoading(); }
      this.updatePaginationDropDownState();
    }

    if (this.props.data.editUserPermissionSuccess === true && prevProps.data.editUserPermissionSuccess === false && this.props.data.message !== prevProps.data.message) {
      this.checkBoxIdsArr3 = [];
      this.setState({
        isEdit: false,
        InviteUserModal: false,
        invitedEmail: '',
        searchText: ''
      }, () => this.getUsersList());
      createNotification("success", this.props.data.message);
      if (this.state.loading) { this.handleLoading(); }
    }

    if (this.props.data.changeUserRoleSuccess === true && prevProps.data.changeUserRoleSuccess === false && this.props.data.message !== prevProps.data.message) {
      this.setState({
        openChangeRoleConfirmation: false,
        selectedUser: null,
        changeRoleType: ''
      });
      createNotification('success', this.props.data.message);
      if (this.state.loading) { this.handleLoading(); }
    }

    if (this.props.data.changeUserStatusSuccess === true && prevProps.data.changeUserStatusSuccess === false && this.props.data.message !== prevProps.data.message) {
      this.setState({
        openChangeStatusConfirmation: false,
        selectedUser: null,
        changeStatusType: ''
      });
      createNotification('success', this.props.data.message);
      if (this.state.loading) { this.handleLoading(); }
    }

    if (this.props.data.error === true && this.props.data.message !== prevProps.data.message) {
      if (this.state.loading) { this.handleLoading(); }
      createNotification("error", this.props.data.message);
    }
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount } = this.state;
      let totalResults = this.props.data.totalResults;
      this.setState((prevState, props) => ({
        startCount: pageNumber !== 1 ? (itemCount * (pageNumber - 1) + 1) : 1,
        endCount: totalResults > itemCount * pageNumber ? itemCount * pageNumber : totalResults,
        page_no: pageNumber,
      }), () => this.getUsersList()
      );
    } catch (error) {
      createNotification("error", "something went wrong");
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  onCloseUpdateModal = () => {
    this.setState({ updateOpen: false });
  };
  openUpdateModal = (data) => {
    this.setState({ updateOpen: true, singleData: data });
  };

  deleteMember = (id) => {
    this.props.deleteMemberRequest(id);
    this.handleLoading();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ page_no: 1, startCount: 1 }, () => this.getUsersList());
  };

  onCloseInviteUserModal = () => {
    this.setState({
      InviteUserModal: false,
      permissionsSelected: [],
      errors: '',
      invitedEmail: '',
      isEdit: false,
    });
  };


  onCloseResendInviteModal = () => {
    this.setState({
      ResendInviteModal: false
    });
  };

  onInviteUserModal = () => {
    this.setState({ InviteUserModal: true });
  };

  onResendInviteModal = (invitedEmail, roleAssign) => {
    this.setState({ invitedEmail, roleAssign, ResendInviteModal: true });
  };


  handleEmailAssignment = (e) => {
    e.preventDefault();
    this.setState({
      invitedEmail: e.target.value
    });
  };

  handleRoleAssignement = (role) => {
    this.setState({
      roleAssign: role
    });
  };

  handleOnInviteUser = async (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.handleLoading();
      const { invitedEmail, roleAssign } = this.state;
      const storeLangCode = this.props.users.newLangState;
      if (roleAssign == -1) {
        createNotification("error", "Please select a role to invite user");
        return;
      }
      if (this.state.isEdit) {
        const requestbody = {
          userId: this.state.selectedUser._id,
          permissionsSelected: this.checkBoxIdsArr3,
          lngCode: this.props.users.newLangState
        };
        this.props.editUserPermissionRequest(requestbody);
      } else {
        const requestbody = {
          invitedUser: invitedEmail,
          roleId: roleAssign,
          lngCode: storeLangCode,
          permissionsSelected: this.state.roleAssign === 2 ? this.checkBoxIdsArr3 : []
        };
        this.props.inviteUserRequest(requestbody);
      }
    }
  };

  handleOnResendUser = async (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.handleLoading();
      const { invitedEmail, roleAssign } = this.state;
      if (roleAssign == -1) {
        createNotification("error", "Please select a role to invite user");
        return;
      }
      const requestbody = {
        invitedUser: invitedEmail,
        roleId: roleAssign,
        resentInvite: true,
        lngCode: this.props.users.newLangState
      };
      this.props.inviteUserRequest(requestbody);
    };
  };


  isValid = () => {
    const { invitedEmail } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (invitedEmail === '' || invitedEmail.trim().length === 0) {
      formIsValid = false;
      error['invitedEmail'] = '*Please enter email.';
    }
    if (invitedEmail && regexTest.test(invitedEmail) === false) {
      formIsValid = false;
      error['invitedEmail'] = '*Please enter a valid email address.';
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  openDeleteConfirmationBox = (user) => {
    this.setState({
      openDeleteConfirmation: true,
      selectedUser: { ...user }
    });
  };

  closeDeleteConfirmation = () => {
    this.setState({
      openDeleteConfirmation: false,
      selectedUser: null
    });
  };

  openChangeRoleConfirmationBox = (user) => {
    this.setState({
      openChangeRoleConfirmation: true,
      selectedUser: { ...user },
      changeRoleType: user.role === 'Co-Admin' ? 'Member' : 'Co-Admin'
    });
  };

  closeChangeRoleConfirmation = () => {
    this.setState({
      openChangeRoleConfirmation: false,
      selectedUser: null,
      changeRoleType: ''
    });
  };

  openChangeStatusConfirmationBox = (user) => {
    this.setState({
      openChangeStatusConfirmation: true,
      selectedUser: { ...user },
      changeStatusType: user.acceptSt === 'Active' ? 'Disable' : 'Activate'
    });
  };

  openEditUserPermissionModal = (user) => {
    this.checkBoxIdsArr3 = user.systemLevelPermissions || [];
    this.setState({
      isEdit: true,
      invitedEmail: user.email,
      InviteUserModal: true,
      selectedUser: { ...user },
    });

  };


  closeChangeStatusConfirmation = () => {
    this.setState({
      openChangeStatusConfirmation: false,
      selectedUser: null,
      changeStatusType: ''
    });
  };

  deleteUser = () => {
    if (this.state.selectedUser) {
      this.props.deleteUserRequest({ _id: this.state.selectedUser._id, lngCode: this.props.users.newLangState });
      this.handleLoading();
    }
  };

  changeUserRole = () => {
    if (this.state.selectedUser) {
      this.props.changeUserRoleRequest({
        userId: this.state.selectedUser._id,
        role: this.state.selectedUser.role === 'Co-Admin' ? 2 : 3,
        lngCode: this.props.users.newLangState
      });
      this.handleLoading();
    }
  };

  changeUserStatus = () => {
    if (this.state.selectedUser) {
      this.props.changeUserStatusRequest({
        userId: this.state.selectedUser._id,
        status: this.state.selectedUser.acceptSt === 'Active' ? 3 : 2,
        lngCode: this.props.users.newLangState
      });
      this.handleLoading();
    }
  };

  handleFilterToggle1 = () => {
    this.setState({
      openFilter1: !this.state.openFilter1
    });

  };

  handleFilterToggle2 = () => {
    this.setState({
      openFilter2: !this.state.openFilter2
    });

  };

  handleFilterToggle3 = () => {
    this.setState({
      openFilter3: !this.state.openFilter3
    });

  };

  filterHandleClose = (event) => {
    this.setState({
      openFilter1: false,
      openFilter2: false,
      openFilter3: false,
    });
  };

  handleFilterChange = (event, selectedFilter) => {
    event.preventDefault();
    try {
      let typeFilterOptionsSelectedArray = [...this.state.typeFilterOptionsSelected];
      if (selectedFilter === 'type') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            typeFilterOptionsSelectedArray = [...this.state.typeFilterOptionsList];
          } else {
            typeFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = typeFilterOptionsSelectedArray.indexOf(event.target.name);
          if (filterIndex > -1) {
            typeFilterOptionsSelectedArray.splice(filterIndex, 1);
          } else {
            typeFilterOptionsSelectedArray.push(event.target.name);
          }
        }
      }

      let statusFilterOptionsSelectedArray = [...this.state.statusFilterOptionsSelected];
      if (selectedFilter === 'status') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            statusFilterOptionsSelectedArray = [...this.state.statusFilterOptionsList];
          } else {
            statusFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = statusFilterOptionsSelectedArray.indexOf(event.target.name);
          if (filterIndex > -1) {
            statusFilterOptionsSelectedArray.splice(filterIndex, 1);
          } else {
            statusFilterOptionsSelectedArray.push(event.target.name);
          }
        }
      }

      this.setState({
        statusFilterOptionsSelected: [...statusFilterOptionsSelectedArray],
        typeFilterOptionsSelected: [...typeFilterOptionsSelectedArray],
        startCount: 1,
        page_no: 1
      }, () => this.getUsersList()
      );

    } catch (error) {
      createNotification("error", "something went wrong");
    }
  };

  getUsersList = () => {
    const {
      statusFilterOptionsSelected,
      typeFilterOptionsSelected,
      page_no,
      itemCount,
      sort_by,
      sortOrder,
      searchText,
      manager,
    } = this.state;
    const requestbody = {
      status: statusFilterOptionsSelected,
      type: typeFilterOptionsSelected,
      sort_by: sort_by,
      order: sortOrder,
      page_no,
      limit: itemCount,
      searchText: searchText.trim(),
      manager: manager,
      filterByPermission: this.checkBoxIdsArr4
    };
    this.props.getUsersList(requestbody);
    this.handleLoading();
  };

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === "asc") {
        this.setState({
          sortOrder: "desc"
        }, () => this.getUsersList()
        );
      } else {
        this.setState({
          sortOrder: "asc"
        }, () => this.getUsersList()
        );
      }
    } else {
      this.setState({
        sort_by: value,
        sortOrder: "asc"
      }, () => this.getUsersList()
      );
    }
  };

  selectItemCount = (e) => {
    this.setState({
      itemCount: e.target.value,
      page_no: 1,
      startCount: 1
    }, () =>
      this.getUsersList()
    );
  };

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.data.totalResults) {
      this.setState({
        endCount: this.props.data.totalResults
      });
    } else {
      this.setState({
        endCount: this.props.data.totalResults > (this.state.itemCount * this.state.page_no) ? (this.state.itemCount * this.state.page_no) : this.props.data.totalResults
      });
    }
  }

  handleCloseMultiSelectForPermissions = () => {
    this.setState({
      openMultiSelectForPermissions: false
    });
  };

  handleCloseMultiSelectForEditPermissions = () => {
    this.setState({
      openMultiSelectForEditPermissions: false
    });
  };

  handleOpenMultiSelectForPermissions = () => {
    this.setState({
      openMultiSelectForPermissions: true,
    });
  };

  handleToggleMultiSelectPermissions = () => {
    this.setState({
      openMultiSelectForPermissions: !this.state.openMultiSelectForPermissions
    });
  };

  handleToggleMultiSelectEditPermissions = () => {
    this.setState({
      openMultiSelectForEditPermissions: !this.state.openMultiSelectForEditPermissions
    });
  };

  handleClose = (event) => {
    this.setState({
      open1: false,
      open2: false,
      openAction: false,
    });
  };

  handleToggleAction = () => {
    this.setState({
      openAction: !this.state.openAction,
    });
  };

  handleListKeyDownForAction = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({});
    }
  };

  handleCloseAssignManagerModal = () => {
    this.checkBoxIdsArr = [];
    this.checkBoxIdsArr2 = [];
    this.setState({
      unAssign: false,
      openAssignManagerModel: false,
      email: '',
      errors: {},
    });
  };

  handleClickOpenAssignManagerModel = () => {
    this.setState({
      unAssign: false,
      openAssignManagerModel: true,
    }, () => this.allUserList());
  };

  handleClickOpenUnAssignManagerModel = () => {
    this.setState({
      unAssign: true,
      openAssignManagerModel: true,
    });
  };

  allUserList = () => {
    this.props.allUserList({ email: this.state.email });
  };

  handelAssignManager = () => {
    let error = {};
    const foundObject = this.checkBoxIdsArr2.some(item => item.email === this.state.email);
    if (foundObject) {
      error['invalidManager'] = 'The same user cannot be assigned as a manager';
      console.log(foundObject);
      this.setState({ errors: error });
    } else {

      this.props.assignManager(
        {
          userIds: this.checkBoxIdsArr,
          email: this.state.email,
          operation: this.state.unAssign ? "unAssign" : "assign",
          lngCode: this.props.users.newLangState
        }
      );
      this.setState({
        loading: true
      });
    }
  };

  selectCheckBox = (e, data) => {
    try {
      if (data) {
        const index = this.checkBoxIdsArr.indexOf(data._id);
        if (index === -1) {
          this.checkBoxIdsArr.push(data._id);
          this.checkBoxIdsArr2.push(data);
        } else {
          this.checkBoxIdsArr.splice(index, 1);
          this.checkBoxIdsArr2.splice(index, 1);
        }
        this.setState({
          changeState: !this.state.changeState,
        });
      }
      if (this.checkBoxIdsArr.length !== this.state.itemCount) {
        this.setState({
          checkedData: false,
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleSelectAllUsers = (e) => {
    this.setState({
      checkedData: e.target.checked,
    });
    if (e.target.checked === true) {
      this.props.data.users.forEach((user) => {
        if (this.checkBoxIdsArr.indexOf(user._id) === -1) {
          this.checkBoxIdsArr.push(user._id);
          this.checkBoxIdsArr2.push(user);
        }
      });
    }
    if (e.target.checked === false) {
      this.checkBoxIdsArr = [];
      this.checkBoxIdsArr2 = [];
    }
  };

  filterByManager = (event, type) => {
    const { value, checked } = event.target;
    let assignedValue = this.props.data?.filterByManager?.assignedManager?.map((option) => option.email);
    let unassignedValue = [null];
    let allFilterData = assignedValue.concat(unassignedValue);

    event.preventDefault();
    if (checked) {
      if (type === "all") {
        this.setState({
          manager: allFilterData,
        });
      } else if (type === "unAssign") {
        this.state.manager.push(null);
        this.setState({
          manager: this.state.manager,
        });
      }
      else {
        this.state.manager.push(value);
        this.setState({
          manager: this.state.manager,
        });
      }
    } else {
      if (type === "all") {
        this.setState({
          manager: [],
        });
      }
      else if (type === "unAssign") {
        let check = this.state.manager?.filter((event) => event !== null);
        this.setState({
          manager: check,
        });
      }
      else {
        let check = this.state.manager?.filter((event) => event !== value);
        this.setState({
          manager: check,
        });
      }
    }
    this.setState(
      {
        page_no: 1,
        startCount: 1,
        searchText: '',
        open2: false,
        allFilterData: allFilterData

      },
      () => this.getUsersList(),
    );
  };

  handleSearchDropdown = () => {
    this.setState({
      SearchDropdown: true
    });
  };

  handleClearSearchDropdown = () => {
    this.setState({
      email: '',
      SearchDropdown: false,
      SearchEnabled: false,
    }, () => this.allUserList());
  };


  handleSearchEnabled = (e) => {
    this.setState({
      SearchDropdown: true,
      SearchEnabled: true,
      email: e.target.value,
    }, () => this.allUserList());
  };

  handleSearchDropdownClose = (event) => {
    this.setState({
      SearchDropdown: false
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        SearchDropdown: false
      });
    }
  };

  handleSelectResult = (data) => {
    this.setState({
      email: data.email,
      SearchDropdown: false
    });
  };


  optionsList = (val) => {
    let temp;
    switch (val) {
      case 'Active': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.active;
        return temp;

      case 'Disabled': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.disabled;
        return temp;

      case 'Pending Invite': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.pendingInvite;
        return temp;

      case 'Member': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.member;
        return temp;

      case 'Co-Admin': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.coAdmin;
        return temp;

      case 'Create Audience': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.createAudience;
        return temp;

      case 'View Audience': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.viewAudience;
        return temp;

      case 'Edit Audience': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.editAudience;
        return temp;

      case 'Delete Audience': temp = this.props.users.languageStateForRedux?.settingsSidebar?.users?.deleteAudience;
        return temp;

      default: temp = val;
        break;
    }
  };

  checkDisabled = () => {
    if (Array.isArray(this.checkBoxIdsArr2) && this.checkBoxIdsArr2?.length > 0) {

      let value = this.checkBoxIdsArr2.some(obj => obj.manager !== null && obj.manager !== '');
      return !value;
    } else {
      return true;
    }
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter')
      e.preventDefault();
  };

  selectPermission = (e, permData) => {
    try {
      if (permData) {
        const index = this.checkBoxIdsArr3.indexOf(permData.permission_id);
        if (index === -1) {
          this.checkBoxIdsArr3.push(permData.permission_id);
        } else {
          this.checkBoxIdsArr3.splice(index, 1);
        }
        this.setState({
          changeState: !this.state.changeState,
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  selectCheckBoxForPermission = (e, id, selectionType, permission) => {
    try {
      const isChecked = e.target.checked;
      if (id) {
        if (selectionType === "multiple") {
          if (isChecked) {
            // Add all permission ids to the checked array
            id.forEach((obj) => {
              if (!this.checkBoxIdsArr4.includes(obj.permission_id)) {
                this.checkBoxIdsArr4.push(obj.permission_id);
              }
            });
            if (permission === 'Audience') {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  Audience: true
                }
              });

            } else if (permission === 'AI Agent') {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'AI Agent': true
                }
              });
            } else if (permission === 'Opportunity Management') {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'Opportunity Management': true
                }
              });
            }
          } else {
            // Remove all permission ids from the checked array
            id.forEach((obj) => {
              const index = this.checkBoxIdsArr4.indexOf(obj.permission_id);
              if (index !== -1) {
                this.checkBoxIdsArr4.splice(index, 1);
              }
            });
            if (permission === 'Audience') {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  Audience: false
                }
              });

            } else if (permission === 'AI Agent') {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'AI Agent': false
                }
              });
            } else if (permission === 'Opportunity Management') {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'Opportunity Management': false
                }
              });
            }
          }
        } else {
          // Handle single 
          let audience = [44, 45, 46, 47];
          let aiAgent = [77, 78, 79, 80, 81];
          let opportunity = [82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96];
          const index = this.checkBoxIdsArr4.indexOf(id.permission_id);
          if (index === -1) {
            this.checkBoxIdsArr4.push(id.permission_id);

          } else {
            this.checkBoxIdsArr4.splice(index, 1);
          }
          this.setState({
            changeState: !this.state.changeState,
          });

          if (permission === 'Audience') {
            let allIncluded = audience.every(value => this.checkBoxIdsArr4.includes(value));
            if (allIncluded) {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  Audience: true
                }
              });
            } else {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  Audience: false
                }
              });
            }

          } else if (permission === 'AI Agent') {
            let allIncluded = aiAgent.every(value => this.checkBoxIdsArr4.includes(value));
            if (allIncluded) {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'AI Agent': true
                }
              });
            } else {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'AI Agent': false
                }
              });
            }
          } else if (permission === 'Opportunity Management') {
            let allIncluded = opportunity.every(value => this.checkBoxIdsArr4.includes(value));
            if (allIncluded) {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'Opportunity Management': true
                }
              });
            } else {
              this.setState({
                permissionCheckboxes: {
                  ...this.state.permissionCheckboxes,
                  'Opportunity Management': false
                }
              });
            }
          }
        }
        this.getUsersList();
      }

    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  handlePermissionFilter = () => {
    this.setState({
      openPermissionFilter: true
    });
  };

  handlePermissionFilterClose = () => {
    this.setState({
      openPermissionFilter: false
    });
  };

  clearFilter = () => {
    this.checkBoxIdsArr4 = [];
    this.setState({
      changeState: !this.state.changeState,
    }, () => this.allUserList());
  };

  render() {
    const {
      openPermissionFilter,
      openAction,
      open,
      loading,
      page_no,
      updateOpen,
      singleData,
      searchText,
      InviteUserModal,
      openFilter1,
      openFilter2,
      openFilter3,
      typeFilterOptionsList,
      typeFilterOptionsSelected,
      statusFilterOptionsList,
      statusFilterOptionsSelected,
      sort_by,
      sortOrder,
      startCount,
      endCount,
      itemCount,
    } = this.state;

    const typeFilterOptionsSelectedAll = typeFilterOptionsSelected.length === typeFilterOptionsList.length ? true : false;
    const statusFilterOptionsSelectedAll = statusFilterOptionsSelected.length === statusFilterOptionsList.length ? true : false;
    return (
      <ThemeProvider theme={theme}>
        <Box pb={3} pt={2.5} mb={1} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
          <Grid container alignItems="center" spacing={2} width={'100%'}>
            <Grid
              size={{ md: "grow", }}
            >
              <Grid container spacing={(2)} alignItems="center" justifyContent={'space-between'} width={'100%'}>
                <Grid >
                  <Box component="h4" className='subsecondary' sx={{ marginRight: 0, marginBottom: 0, }}>

                    <RoundBadge badgeContent={this.props.data.totalResults} color='primary'>
                      {this.props.users.languageStateForRedux?.buttons?.teams?.users}
                    </RoundBadge>
                  </Box>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: '#000056 !important',
                          color: theme.palette.primary.contrastText,
                        }
                      }}
                      size='small'
                      className={typeFilterOptionsSelected.length ? 'active' : ''}
                      disableElevation
                      ref={this.anchorRefFilter1}
                      aria-controls={openFilter1 ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleFilterToggle1}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {(this.props.users.languageStateForRedux?.dropdowns?.attendees?.allType) + ` (${this.props.data.filterCnt?.role?.All ? this.props.data.filterCnt.role.All : 0})`}
                    </Box>
                    <Popper open={openFilter1} placement="bottom-start" className='filter-options' transition anchorEl={this.anchorRefFilter1.current} role={undefined} disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250} >
                          <Box className='option-list' sx={{ backgroundColor: theme.palette.background.light, borderRadius: '4px', marginTop: '8px', overflowY: 'scroll', maxHeight: 195, }}>
                            <ClickAwayListener onClickAway={this.filterHandleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>

                                <MenuItem className='checkbox-item'>
                                  <FormControlLabel
                                    control={
                                      <Checkbox className="checkbox" sx={{}} color="default" name="all" onChange={(e) => this.handleFilterChange(e, 'type')} checked={typeFilterOptionsSelectedAll} />
                                    }
                                    label={(this.props.users.languageStateForRedux?.common?.all) + ` (${Object.keys(this.props.data.filterCnt).length ? (this.props.data.filterCnt?.role?.All) : 0})`}
                                  />
                                </MenuItem>
                                {typeFilterOptionsList.map((option) => {
                                  return (
                                    <MenuItem key={option} className='checkbox-item'>
                                      <FormControlLabel
                                        control={
                                          <Checkbox className="checkbox" color="default" name={option} onChange={(e) => this.handleFilterChange(e, 'type')} checked={typeFilterOptionsSelected.indexOf(option) > -1 ? true : false} />
                                        }
                                        label={this.optionsList(option) + ` (${Object.keys(this.props.data.filterCnt).length ? (this.props.data.filterCnt?.role[option] ? this.props.data.filterCnt?.role[option] : 0) : 0})`}
                                      />
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: '#000056 !important',
                          color: theme.palette.primary.contrastText,
                        }
                      }}
                      size='small'
                      className={statusFilterOptionsSelected.length ? 'active' : ''}
                      disableElevation
                      ref={this.anchorRefFilter2}
                      aria-controls={openFilter2 ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleFilterToggle2}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {(this.props.users.languageStateForRedux?.dropdowns?.attendees?.allStatus) + ` (${this.props.data.filterCnt?.acceptSt?.All ? this.props.data.filterCnt.acceptSt.All : 0})`}
                    </Box>
                    <Popper open={openFilter2} placement="bottom-start" className='filter-options' transition anchorEl={this.anchorRefFilter2.current} role={undefined} disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250} >
                          <Box className='option-list' sx={{ backgroundColor: theme.palette.background.light, borderRadius: '4px', marginTop: '8px', overflowY: 'scroll', maxHeight: 195, }}>
                            <ClickAwayListener onClickAway={this.filterHandleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>

                                <MenuItem className='checkbox-item'>
                                  <FormControlLabel
                                    control={
                                      <Checkbox className="checkbox" sx={{}} color="default" name="all" onChange={(e) => this.handleFilterChange(e, 'status')} checked={statusFilterOptionsSelectedAll} />
                                    }
                                    label={(this.props.users.languageStateForRedux?.common?.all) + ` (${Object.keys(this.props.data.filterCnt).length ? (this.props.data.filterCnt?.acceptSt?.All) : 0})`}
                                  />
                                </MenuItem>
                                {statusFilterOptionsList.map((option) => {
                                  return (
                                    <MenuItem key={option} className='checkbox-item'>
                                      <FormControlLabel
                                        control={
                                          <Checkbox className="checkbox" color="default" name={option} onChange={(e) => this.handleFilterChange(e, 'status')} checked={statusFilterOptionsSelected.indexOf(option) > -1 ? true : false} />
                                        }
                                        label={this.optionsList(option) + ` (${Object.keys(this.props.data.filterCnt).length ? (this.props.data.filterCnt?.acceptSt[option] ? this.props.data.filterCnt?.acceptSt[option] : 0) : 0})`}
                                      />
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: '#000056 !important',
                          color: theme.palette.primary.contrastText,
                        }
                      }}
                      size='small'
                      className={this.state.manager.length ? 'active' : ''}
                      disableElevation
                      ref={this.anchorRefFilter3}
                      aria-controls={openFilter3 ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleFilterToggle3}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {this.props.users.languageStateForRedux?.settingsSidebar?.users?.allManager + ` (${Object.keys(this.props.data?.filterByManager).length ? (this.props.data?.filterByManager?.totalManager) : 0})`}
                    </Box>
                    <Popper open={openFilter3} placement="bottom-start" className='filter-options' transition anchorEl={this.anchorRefFilter3.current} role={undefined} disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250} >
                          <Box className='option-list' sx={{ backgroundColor: theme.palette.background.light, borderRadius: '4px', marginTop: '8px', overflowY: 'scroll', maxHeight: 195, }}>
                            <ClickAwayListener onClickAway={this.filterHandleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                <MenuItem className='checkbox-item'>
                                  <FormControlLabel
                                    control={
                                      <Checkbox className="checkbox" color="default" onChange={(e) => this.filterByManager(e, "all")}
                                        checked={this.state.manager.length === this.state.allFilterData.length ? true : false}
                                      />
                                    }
                                    label={this.props.users.languageStateForRedux?.common?.all + ` (${Object.keys(this.props.data?.filterByManager).length ? (this.props.data?.filterByManager?.totalManager) : 0})`}
                                  />
                                </MenuItem>
                                <MenuItem className='checkbox-item'>
                                  <FormControlLabel
                                    control={
                                      <Checkbox className="checkbox" color="default" onChange={(e) => this.filterByManager(e, "unAssign")}
                                        checked={this.state.manager.indexOf(null) > -1 ? true : false}
                                      />
                                    }
                                    label={this.props.users.languageStateForRedux?.settingsSidebar?.users?.noManagerAssigned + ` (${this.state.unassignedCount})`}
                                  />
                                </MenuItem>
                                {this.props.data?.filterByManager?.assignedManager?.map((option) => {
                                  return (
                                    <MenuItem className='checkbox-item'>
                                      <FormControlLabel
                                        control={
                                          <Checkbox className="checkbox" color="default" name={option.email} value={option.email} onChange={(e) => this.filterByManager(e, 'assign',)}
                                            checked={this.state.manager.indexOf(option.email) > -1 ? true : false}
                                          />
                                        }
                                        label={`${option.email} (${option.managerCount})`}
                                      />
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>

                  <Grid >
                    <Box variant='contained'
                      className={this.checkBoxIdsArr4.length && this.checkBoxIdsArr4.length > 0 ? 'active' : ''}
                      component={Button}
                      sx={{
                        padding: '7px 10px !important',
                        minWidth: 'auto',
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&.active': {
                          backgroundColor: '#000056 !important',
                          color: theme.palette.primary.contrastText,
                        }
                      }}

                      onClick={this.handlePermissionFilter}
                    >
                      {this.checkBoxIdsArr4.length && this.checkBoxIdsArr4.length > 0 ?
                        <img alt="" src={filterIconWhite} />
                        :
                        <img alt="" src={filterIcon} />
                      }
                    </Box>
                  </Grid>
                  <Grid >
                    {this.checkBoxIdsArr4.length && this.checkBoxIdsArr4.length > 0 ?
                      <Button variant='text' color='secondary' size='small' startIcon={<ClearIcon />}
                        style={{
                          textTransform: 'capitalize',
                          fontWeight: '400',
                          fontSize: '14px'
                        }}
                        onClick={() => this.clearFilter()}

                      >Clear filter</Button>
                      : ''
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid >
              <Grid container alignItems="center" spacing={1.25} className="team-dash-right">
                <Grid className="">
                  <form onSubmit={this.handleSubmit}>
                    <TextField
                      name="searchText"
                      variant='standard'
                      placeholder={this.props.users.languageStateForRedux?.common?.search}
                      className="search"
                      size="small"
                      value={searchText}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <button className='d-none' type="submit">
                      <img src={SearchIconBar} alt="" />
                    </button>
                  </form>
                </Grid>
                <Grid >
                  <Box sx={{ position: 'relative' }}>

                    <Button
                      variant="outlined"
                      color="primary"
                      size='small'
                      disableElevation
                      ref={this.anchorRefAction}
                      aria-controls={openAction ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleToggleAction}
                      endIcon={<ArrowDropDownIcon />}
                      disabled={
                        Array.isArray(this.checkBoxIdsArr) &&
                          this.checkBoxIdsArr?.length > 0
                          ? false
                          : true
                      }
                    >
                      {this.props.users.languageStateForRedux?.common?.action}
                    </Button>
                    <Popper
                      open={openAction}
                      style={{ zIndex: '4' }}
                      placement="bottom-start"
                      transition
                      anchorEl={this.anchorRefAction.current}
                      role={undefined}
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            sx={{
                              backgroundColor: theme.palette.common.white,
                              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                              borderRadius: '4px',
                              marginTop: '8px',
                            }}
                          >
                            <ClickAwayListener onClickAway={this.handleClose}>
                              <MenuList
                                autoFocusItem={openAction}
                                id="menu-list-grow"
                                onKeyDown={this.handleListKeyDownForAction}
                              >
                                <>
                                  <MenuItem
                                    disabled={
                                      Array.isArray(this.checkBoxIdsArr) &&
                                        this.checkBoxIdsArr?.length > 0
                                        ? false
                                        : true
                                    }
                                    onClick={() => this.handleClickOpenAssignManagerModel()}
                                  >
                                    {this.props.users.languageStateForRedux?.settingsSidebar?.users?.assignManager}
                                  </MenuItem>
                                  <MenuItem
                                    disabled={
                                      this.checkDisabled()
                                    }
                                    onClick={() => this.handleClickOpenUnAssignManagerModel()}
                                  >
                                    {this.props.users.languageStateForRedux?.settingsSidebar?.users?.unassignManager}
                                  </MenuItem>
                                </>
                              </MenuList>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Box>
                </Grid>

                <Grid >
                  <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    disableElevation
                    onClick={this.onInviteUserModal}
                  >
                    {this.props.users.languageStateForRedux?.settingsSidebar?.users?.invite}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <TableContainer className="list-table" style={{ maxHeight: "calc(100vh - 300px)" }}>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="checkbox sticky-cell" style={{ backgroundColor: '#ffffff', minWidth: 60 }}>
                  <Checkbox
                    color="primary"
                    p={0}
                    onChange={(e) => {
                      this.handleSelectAllUsers(e);
                    }}
                    checked={this.state.checkedData}
                  />
                </TableCell>
                <TableCell className={'email ' + (sort_by == 'email' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} onClick={() => this.sorting("email")}>{this.props.users.languageStateForRedux?.column_names?.teams?.email}</TableCell>
                <TableCell className={'text ' + (sort_by == 'role' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} onClick={() => this.sorting("role")}>{this.props.users.languageStateForRedux?.column_names?.attendees?.type}</TableCell>
                <TableCell className={'email ' + (sort_by == 'manager' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} onClick={() => this.sorting("manager")}>{this.props.users.languageStateForRedux?.settingsSidebar?.users?.manager}</TableCell>
                <TableCell className={'status ' + (sort_by == 'acceptSt' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} onClick={() => this.sorting("acceptSt")}>{this.props.users.languageStateForRedux?.common?.status}</TableCell>
                <TableCell className={'date-time ' + (sort_by == 'createdAt' ? (sortOrder == 'asc' ? 'sort-asc' : 'sort-des') : '')} onClick={() => this.sorting("createdAt")}>{this.props.users.languageStateForRedux?.settingsSidebar?.users?.joinedOn}</TableCell>
                <TableCell className='options'>{this.props.users.languageStateForRedux?.common?.action}</TableCell>
              </TableRow>
            </TableHead>
            {Array.isArray(this.props.data.users) && !this.state.loading && this.props.data.users.length > 0 ? (
              <TableBody >
                {this.props.data.users &&
                  this.props.data.users.map((data, index) => {
                    return (
                      <TableRow>
                        <TableCell className="checkbox sticky-cell" style={{ zIndex: '0', minWidth: 60 }}>
                          <Checkbox
                            name={data._id}
                            color="primary"
                            p={0}
                            onChange={(e) =>
                              this.selectCheckBox(e, data)
                            }
                            checked={
                              this.checkBoxIdsArr.indexOf(
                                data._id,
                              ) !== -1
                                ? true
                                : false
                            }
                          />
                        </TableCell>
                        <TableCell className="email" title={data.email}>{data.email}</TableCell>
                        <TableCell className="text">
                          <Stack
                            direction="row"
                            alignItems={'center'}
                            spacing={1}
                          >
                            <span className='long-text' style={{ maxWidth: "80%", }}>{this.optionsList(data.role)}</span>
                            {data.permissions && data.permissions.length > 0 &&
                              <div className="detail-popup" onClick={() => this.openEditUserPermissionModal(data)}>
                                <span className="count">
                                  +{data.permissions.length}
                                </span>
                              </div>
                            }
                          </Stack>
                        </TableCell>
                        <TableCell className="email" >{data.manager ? data.manager : ''}</TableCell>
                        <TableCell className="status">
                          <span className={data.acceptSt === 'Active' ? 'table-status success' : data.acceptSt === 'Disabled' ? 'table-status danger' : 'table-status light'}>
                            {this.optionsList(data.acceptSt)}
                          </span>
                        </TableCell>
                        <TableCell className="date-time">{moment(data.createdAt).format("MMM DD YYYY, h:mm A")}</TableCell>
                        <TableCell className=" options">
                          {' '}
                          <div className='options-button'>
                            <img alt="" src={MoreIcon} />
                            {' '}
                          </div>
                          <div className="options-list" style={{ width: '205px' }}>
                            <div onClick={() => this.openChangeRoleConfirmationBox(data)}>
                              <img alt='star' src={StarIcon} /><p>{data.role === 'Co-Admin' ? this.props.users.languageStateForRedux?.settingsSidebar?.users?.makeMember : this.props.users.languageStateForRedux?.settingsSidebar?.users?.makeCoadmin}</p>
                            </div>
                            {(data.acceptSt === 'Active' || data.acceptSt === 'Disabled') &&
                              <div onClick={() => this.openChangeStatusConfirmationBox(data)}>
                                <img alt='star' src={InfoIcon} /><p>{data.acceptSt === 'Active' ? this.props.users.languageStateForRedux?.settingsSidebar?.users?.disableUser : this.props.users.languageStateForRedux?.settingsSidebar?.users?.activeUser}</p>
                              </div>
                            }
                            {(data.role === 'Member') &&
                              <div onClick={() => this.openEditUserPermissionModal(data)}>
                                <img width={16} style={{ marginRight: '10px', marginLeft: '1px', }} alt='Audiance' src={EditIcon} /><p>{this.props.users.languageStateForRedux?.settingsSidebar?.users?.editPermission}</p>
                              </div>
                            }
                            {(data.acceptSt !== 2 && data.acceptSt !== 3) && (data.acceptSt === "Pending Invite") &&
                              <div onClick={() => { this.onResendInviteModal(data.email, data.role); }}>
                                <img alt='star' src={InviteIcon} /><p> {this.props.users.languageStateForRedux?.settingsSidebar?.users?.resendInvite}</p>
                              </div>
                            }
                            <hr />
                            <div onClick={() => this.openDeleteConfirmationBox(data)} style={{ color: theme.palette.secondary.main, }}>
                              <img alt='star' src={DeleteIcon} /><p> {this.props.users.languageStateForRedux?.common?.delete}</p>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (!this.state.loading && this.props.data.users?.length === 0 &&
              (<TableBody>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    There are no records to display
                  </TableCell>
                </TableRow>
              </TableBody>)
            )}
          </Table>
        </TableContainer>
        {Array.isArray(this.props.data.users) && this.props.data.users.length ? (
          <Grid container alignItems="center" justifyContent="space-between" className="tablePagination">
            <Grid >
              <Box component="span" className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {this.props.data.totalResults}</Box>
            </Grid>
            <Grid >
              <Grid container alignItems="center" >
                <Grid >
                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                  <select value={itemCount} onChange={this.selectItemCount}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                </Grid>
                <Grid >
                  <Pagination
                    activePage={page_no}
                    itemsCountPerPage={this.state.itemCount}
                    totalItemsCount={this.props.data.totalResults}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    prevPageText={<NavigateBeforeIcon style={{ fontSize: 18 }} />}
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <NotificationContainer />
        {loading || this.props.users.isLoading ? <Loader /> : null}

        <Modal open={open} onClose={this.onCloseModal} center>
          <AddUserModal page_no={page_no} />
        </Modal>

        <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
          <UpdateUserModal page_no={page_no} singleData={singleData} />
        </Modal>

        <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
          <UpdateUserModal page_no={page_no} singleData={singleData} />
        </Modal>

        <Modal
          open={this.state.ResendInviteModal} onClose={this.onCloseResendInviteModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid container className='modal-wrapper' >
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.common?.confirmation}</Box>
              <Box component="p" className="small-subtitle">{this.props.users.languageStateForRedux?.settingsSidebar?.users?.inviteAgain}</Box>
            </Grid>
            <Grid size={{ xs: 12, }} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <><Button variant="contained" color="primary" disableElevation onClick={this.handleOnResendUser} >{this.props.users.languageStateForRedux?.buttons?.attendees?.yesSend}</Button>
                      <Button variant="outlined" disableElevation onClick={this.onCloseResendInviteModal}>{this.props.users.languageStateForRedux?.common?.cancel}</Button></>
                  }

                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
          <UpdateUserModal page_no={page_no} singleData={singleData} />
        </Modal>

        <Modal
          open={this.state.openDeleteConfirmation} onClose={this.closeDeleteConfirmation}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5} >
            <Box component={Grid} mt={-1} size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
            </Box>
            <Grid size={{ xs: 12, }} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <><Button variant="contained" color="secondary" disableElevation onClick={() => this.deleteUser()} >{this.props.users.languageStateForRedux?.common?.delete}</Button>
                      <Button variant="outlined" disableElevation onClick={this.closeDeleteConfirmation}>{this.props.users.languageStateForRedux?.common?.cancel}</Button></>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.openChangeRoleConfirmation} onClose={this.closeChangeRoleConfirmation}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid container className='modal-wrapper' >
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className='subtitle'>
                {this.props.users.languageStateForRedux?.common?.confirmation}
              </Box>
              <p className='small-subtitle'>{this.state.changeRoleType == 'Co-Admin'
                ? (this.props.users.languageStateForRedux?.settingsSidebar?.users?.descMsg2)
                : (this.props.users.languageStateForRedux?.settingsSidebar?.users?.changeRole) + ' ' + this.props.users.languageStateForRedux?.settingsSidebar?.users?.member + ' ?'}</p>
            </Grid>
            <Grid size={{ xs: 12, }} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <><Button variant="contained" color="primary" disableElevation onClick={() => this.changeUserRole()} >{this.props.users.languageStateForRedux?.buttons?.common?.yes}</Button>
                      <Button variant="outlined" disableElevation onClick={this.closeChangeRoleConfirmation}>{this.props.users.languageStateForRedux?.common?.cancel}</Button></>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={this.state.openChangeStatusConfirmation} onClose={this.closeChangeStatusConfirmation}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid container className='modal-wrapper' >
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.common?.confirmation}!</Box>
              <Box component="p" className="small-subtitle">{this.props.users.languageStateForRedux?.settingsSidebar?.users?.changeStatus} {this.state.changeStatusType === 'Disable' ? this.props.users.languageStateForRedux?.actions?.gamification?.disable : this.props.users.languageStateForRedux?.settingsSidebar?.users?.activate} ?</Box>
            </Grid>
            <Grid size={{ xs: 12, }} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <><Button variant="contained" color="primary" disableElevation onClick={() => this.changeUserStatus()} >{this.props.users.languageStateForRedux?.buttons?.common?.yes}</Button>
                      <Button variant="outlined" disableElevation onClick={this.closeChangeStatusConfirmation}>{this.props.users.languageStateForRedux?.common?.cancel}</Button></>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={this.state.openAssignManagerModel}
          onClose={this.handleCloseAssignManagerModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {!this.state.unAssign ? this.props.users.languageStateForRedux?.settingsSidebar?.users?.assignManager : this.props.users.languageStateForRedux?.settingsSidebar?.users?.unassignManager}
              </Box>
              <p className="small-subtitle" >
                {!this.state.unAssign ? this.props.users.languageStateForRedux?.settingsSidebar?.users?.assignManagerDesc : this.props.users.languageStateForRedux?.settingsSidebar?.users?.unassignManagerDesc}
              </p>
            </Grid>
            {!this.state.unAssign &&
              <Grid size={{ xs: 12, }} pb={3}>

                <Box
                  style={{
                    position: 'relative',
                  }}
                >
                  <OutlinedInput
                    sx={{
                      marginTop: '2px',
                      padding: '3px 0px',
                    }}
                    notched={false}
                    size="medium"
                    fullWidth
                    placeholder={this.props.users.languageStateForRedux?.settingsSidebar?.users?.searchManager}
                    name=""
                    value={this.state.email}
                    className=""

                    ref={this.anchorRef}
                    aria-controls={this.state.SearchDropdown ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleSearchDropdown}
                    onChange={(e) =>
                      this.handleSearchEnabled(e)
                    }
                    onKeyDown={this.handleKeyDown}
                    inputProps={{
                      maxLength: 63,
                    }}

                    endAdornment={
                      <InputAdornment position="end">
                        {this.state.email &&
                          <IconButton
                            aria-label="clear search"
                            onClick={this.handleClearSearchDropdown}
                            edge="end"
                            size='small'
                          >
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        }
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                  <Popper
                    open={this.state.SearchDropdown}
                    style={{ right: '0 !important', zIndex: 1099 }}
                    className='modal-searchable-dropdown'
                    placement="bottom-start"
                    transition
                    anchorEl={this.anchorRef.current}
                    role={undefined}
                    disablePortal={false}
                    modifiers={{
                      flip: {
                        enabled: true,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window',
                      },
                    }}

                  >
                    {({ TransitionProps, placement }) => (
                      <Fade {...TransitionProps} timeout={250}>
                        <Box
                          sx={{
                            maxHeight: '40vh',
                            overflow: 'scroll',
                            backgroundColor: theme.palette.common.white,
                            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                            borderRadius: '4px',
                          }}
                        >
                          <ClickAwayListener onClickAway={this.handleSearchDropdownClose}>
                            <Box id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                              <>
                                {Array.isArray(this.state.allUserList) && this.state.allUserList.length > 0 ?
                                  (
                                    <MenuList>
                                      {Array.isArray(this.state.allUserList) && this.state.allUserList.length > 0 &&
                                        this.state.allUserList.map((user) => {
                                          return (
                                            <MenuItem className="search-item"
                                              onClick={() =>
                                                this.handleSelectResult(user)
                                              }>
                                              <Box display={'flex'} alignItems={'center'}>
                                                {user.email}
                                              </Box>
                                            </MenuItem>
                                          );
                                        }
                                        )
                                      }
                                    </MenuList>
                                  )
                                  :
                                  (
                                    <MenuList>
                                      <MenuItem disabled style={{ justifyContent: 'center' }}>
                                        {this.props.users.languageStateForRedux?.common?.noRecords}
                                      </MenuItem>
                                    </MenuList>
                                  )
                                }
                              </>
                            </Box>
                          </ClickAwayListener>
                        </Box>
                      </Fade>
                    )}
                  </Popper>
                </Box>
                <p className="error_mesage">{this.state.errors.invalidManager}</p>
              </Grid>
            }
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handelAssignManager}
                    disabled={!this.state.unAssign ? (this.state.email.length ? false : true) : false
                    }
                  >
                    {!this.state.unAssign ? this.props.users.languageStateForRedux?.buttons?.attendees?.assign : this.props.users.languageStateForRedux?.buttons?.common?.yes}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseAssignManagerModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={InviteUserModal}
          onClose={this.onCloseInviteUserModal}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end" width={"100%"}>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 5,
                xl: 4
              }}
              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid >
                    <h3 className="subtitle">{this.state.isEdit ? "Edit User" : "Invite New User"}</h3>
                  </Grid>
                  <Grid >
                    <CloseIcon
                      onClick={this.onCloseInviteUserModal}
                      className="modal-close-button"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                component="div"
                py={1}
                className="full-screen_modal__form_body"
              >
                <Box px={3} pt={2} pb={4}>
                  <Box
                    component={Grid}
                    rowSpacing={2}
                    columnSpacing={2}
                    container
                    className="inputField"
                  >
                    <Grid size={{ xs: 12, }} className='inputField'>
                      <Box component="label" display="flex" alignItems="center">
                        <MailOutlineIcon />
                        <Box component="span" pl={1}>
                          Invite with email <span className="required">*</span>
                        </Box>
                      </Box>
                      <input
                        type="text"
                        class="form-control small"
                        name="title"
                        placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalPlaceholder}
                        onChange={this.handleEmailAssignment}
                        required
                        value={this.state.invitedEmail}
                        disabled={this.state.isEdit ? true : false}
                      />
                      <p className="error_mesage">{this.state.errors.invitedEmail}</p>
                    </Grid>

                    <Grid size={{ xs: 12, }} className='inputField'>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="top"
                        >
                          <Box>
                            <FormControlLabel
                              value="top"
                              control={<Radio color="primary" />}
                              label="Co-Admin"
                              checked={this.state.roleAssign === 3}
                              onClick={() => { this.handleRoleAssignement(3); }}
                            />
                          </Box>
                          <Box>
                            <FormControlLabel
                              value="start"
                              control={<Radio color="primary" />}
                              label="Member"
                              checked={this.state.roleAssign === 2}
                              onClick={() => { this.handleRoleAssignement(2); }}

                            />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {this.state.roleAssign === 2 &&
                      <>
                        <Grid size={{ xs: 12, }} sx={{ paddingTop: '0 !important' }}>
                          <Box className="inputField">
                            <Box component={'label'}> Permissions</Box>
                          </Box>
                        </Grid>
                        <Grid
                          size={{ xs: 12, }}
                          sx={{
                            maxHeight: 'calc(100vh - 390px)',
                            overflow: 'auto',
                            paddingTop: '0 !important'
                          }}
                        >
                          <Box mt={1} className="drawer-accordion">
                            {this.props.permissions &&
                              this.props.permissions.permissions &&
                              Object.keys(this.props.permissions.permissions).map((permission, index) => {
                                return (
                                  <Accordion
                                    style={{ margin: '0' }}
                                    className="box-shadow-none"
                                    defaultExpanded={0}
                                  >
                                    <AccordionSummary
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className="custom-accordion-summary"
                                    >
                                      <Stack direction={'row'} spacing={1}>
                                        <Box component={'img'} alt="" src={downArrow} />
                                        <Box>{permission} ({this.props.permissions.permissions[permission].length})</Box>
                                      </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails className="custom-accordion-details">
                                      <TableContainer className="roles-table">
                                        <Table>
                                          <TableBody>
                                            {
                                              this.props.permissions.permissions[permission].map((permData, index) => {
                                                return (
                                                  <TableRow key={'permission' + index}>
                                                    <TableCell align="left">
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            color="primary"
                                                            style={{
                                                              padding: 0,
                                                              marginRight: '8px',
                                                            }}
                                                            onChange={(e) =>
                                                              this.selectPermission(e, permData)
                                                            }
                                                            checked={this.checkBoxIdsArr3.indexOf(permData.permission_id) > -1 ? true : false}
                                                          />
                                                        }
                                                        label={permData.permissionLabel}
                                                        style={{
                                                          padding: 0,
                                                          margin: 0,
                                                        }}
                                                      />
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              })
                                            }
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              })
                            }
                          </Box>
                        </Grid>
                      </>
                    }
                  </Box>
                </Box>
              </Box>
              <Box className="full-screen_modal__form_footer">
                <Grid size={{ xs: 12, }}>
                  <Stack spacing={1}>
                    <Button variant="contained" fullWidth color="primary"
                      onClick={this.handleOnInviteUser}
                    >
                      {this.state.isEdit ? "Save" : 'Send Invite'}
                    </Button>

                    <Button variant="text" fullWidth color="primary"
                      onClick={this.onCloseInviteUserModal}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={openPermissionFilter}
          onClose={this.onCloseInviteUserModal}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end" width={"100%"}>
            <Grid
              size={{
                xs: 6,
                md: 4,
                lg: 3,
                xl: 3
              }}

              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid >
                    <h3 className="subtitle">Permission filters</h3>
                  </Grid>
                  <Grid >
                    <CloseIcon
                      onClick={this.handlePermissionFilterClose}
                      className="modal-close-button"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                component="div"
                py={1}
                className="full-screen_modal__form_body"
              >
                <Box pb={2}>
                  <Box
                    component={Grid}
                    rowSpacing={2}
                    columnSpacing={2}
                    container
                  >
                    <Grid
                      size={{ xs: 12, }}
                      sx={{
                        maxHeight: 'calc(100vh - 120px)',
                        overflow: 'auto',
                        paddingTop: '0 !important',
                      }}
                    >
                      <Box className="">
                        {this.props.permissions &&
                          this.props.permissions.permissions &&
                          Object.keys(this.props.permissions.permissions).map((permission, index) => {
                            return (
                              <Accordion
                                style={{ margin: '0' }}
                                className="box-shadow-none"
                                defaultExpanded={0}
                              >
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="custom-accordion-summary"
                                >
                                  <Stack direction={'row'} spacing={1}>
                                    <Box component={'img'} alt="" src={downArrow} />
                                    <Box>{permission} ({this.props.permissions.permissions[permission].length})</Box>

                                  </Stack>
                                </AccordionSummary>
                                <AccordionDetails className="custom-accordion-details">
                                  <TableContainer className="roles-table">
                                    <Table>
                                      <TableBody>
                                        <TableRow key={permission}>
                                          <TableCell align="left">
                                            <FormGroup>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    style={{
                                                      padding: 0,
                                                      marginRight: '8px',
                                                    }}
                                                    onChange={(e) =>
                                                      this.selectCheckBoxForPermission(e, this.props.permissions.permissions[permission], "multiple", permission)
                                                    }
                                                    checked={this.state.permissionCheckboxes[permission] === true ? true : false}
                                                    color="primary" />
                                                }
                                                label={"Select All"}
                                                style={{
                                                  padding: 0,
                                                  margin: 0,
                                                }} />
                                            </FormGroup>
                                          </TableCell>
                                        </TableRow>
                                        {
                                          this.props.permissions.permissions[permission].map((permData, index) => {
                                            return (
                                              <>
                                                <TableRow key={'permission' + index}>

                                                  <TableCell align="left">
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          style={{
                                                            padding: 0,
                                                            marginRight: '8px',
                                                          }}
                                                          checked={this.checkBoxIdsArr4.includes(permData.permission_id)}
                                                          color="primary" />
                                                      }
                                                      label={permData.permissionLabel}
                                                      onChange={(e) =>
                                                        this.selectCheckBoxForPermission(e, permData, "single", permission)
                                                      }
                                                      style={{
                                                        padding: 0,
                                                        margin: 0,
                                                      }} />
                                                  </TableCell>
                                                </TableRow></>
                                            );
                                          })
                                        }
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        }
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Modal>

      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.Users,
  permissions: state.Permission,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      allUserList: Actions.allUserListRequest,
      assignManager: Actions.assignManagerRequest,
      getUsersList: Actions.getUsersListRequest,
      searchUserRequest: Actions.searchUserRequest,
      deleteUserRequest: Actions.deleteUserRequest,
      inviteUserRequest: Actions.inviteUserRequest,
      editUserPermissionRequest: Actions.editUserPermissionRequest,
      changeUserRoleRequest: Actions.changeUserRoleRequest,
      changeUserStatusRequest: Actions.changeUserStatusRequest,
      getPermissionList: Actions.permissionRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(Users)


//@ts-nocheck
import React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Modal } from 'react-responsive-modal';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MessageWindow, { Message } from 'Pages/AIChatbot/Tabs/ChatWidget/MessageWindow';

type Props = {
  open: boolean;
  handleClose: () => void;
  messages: Message[];
};
function ChatHistory({ open, handleClose, messages }: Props) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      classNames={{
        overlay: 'full-screen_overlay',
        modal:
          'full-screen_modal opportunity-chat-history modal-default-close-none',
      }}
      center
    >
      <Grid container justifyContent="end">
        <Grid
          size={{xs: 12,md:6,
            lg:5,
            xl:4}}
          
          className="full-screen_modal__form"
        >
          <Box component="div" className="full-screen_modal__form_header">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid >
                <Box className="secondary">AI Chat History</Box>
                {/* <Box className='paragraph'>
                  AI Agent - Blue Print Studio
                </Box> */}
              </Grid>
              <Grid >
                <Grid container>
                  <Box component={Grid} ml={1}>
                    <CloseIcon
                      onClick={handleClose}
                      className="modal-close-button"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="div"
            py={1}
            px={2}
            // sx={{ maxHeight: 'calc(100vh - 150px)' }}
            className="full-screen_modal__form_body ai-chat-dashboard"
          >
            <Box className={'chat-logs'}>
              <MessageWindow
                messages={messages}
                chatId={'6667d75035bd2805e4b9ab83'}
                handleLoadMoreForMessages={() => { }}
                showLoadMoreButtonForMessages={false}
                hideFeedbackUI={true}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <MessageWindow
        messages={messages}
        chatId={'6667d75035bd2805e4b9ab83'}
        handleLoadMoreForMessages={() => {}}
        showLoadMoreButtonForMessages={false}
      /> */}
    </Modal>
  );
}

export default ChatHistory;

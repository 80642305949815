import React, { Component } from 'react'
import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import { EventsAllAPI } from 'ReduxStore/API'
import { Modal } from 'react-responsive-modal'
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CloseIcon from '@mui/icons-material/Close';
import ImportIcon from '../../assets/images/icons/importIcon.svg';
import CopyIcon from '../../assets/images/icons/copyIcon.svg';
import ExportIcon from '../../assets/images/icons/export.svg'
import { generateCsv, mkConfig } from 'export-to-csv';
// import MenuItem from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};
class GroupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      importOpen: this.props.importOpen ? this.props.importOpen : false,
      page_no: this.props.page_no ? this.props.page_no : false,
      csvfile: '',
      groupName: '',
      firstNext: '0',
      fields: [
        { selectType: null, event: null, audience: null, eventType: null },
      ],
      excludeFields: [
        { selectType: null, event: null, audience: null, eventType: null },
      ],
      events: [],
      openValidation: false,
      order: 'desc',
      sort_by: 'updatedAt',
    }
  }

  componentDidMount() {
    this.allEventsApiCall()
    this.groupRequestForDropDown()
  }
  onOpenGroupModal = () => {
    this.setState({ groupOpen: true })
  }

  onCloseGroupModal = () => {
    this.setState({ groupOpen: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.contacts.importGroupContactValidation === true &&
      prevProps.contacts.importGroupContactValidation === false &&
      this.props.contacts.message !== prevProps.contacts.message) {
      this.setState({
        loading: false,
      })
    }

    if (
      this.props.groups.groupCheckSuccess === true && prevProps.groups.groupCheckSuccess === false &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      this.setState({ firstNext: '1', loading: false })
    }
    if (
      this.props.groups.groupCheckError === true && prevProps.groups.groupCheckError === false &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('error', this.props.groups.message)
      this.setState({
        loading: false
      })
    }

    if (
      this.props.contacts.groupSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({ 
        loading: false ,
        groupName:''
      })
      this.props.checkIfAudienceCreated()
    }

    if (
      this.props.contacts.groupError &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValidGroup = () => {
    const { groupName, csvfile } = this.state
    let error = {}
    let formIsValid = true

    if (groupName === '' || groupName.trim().length === 0) {
      formIsValid = false
      error['groupName'] = this.props.users.languageStateForRedux?.validations?.enterAudienceName
    }
   
    this.setState({ errors: error })
    return formIsValid
  }

  isValidGroupCsv = () => {
    const { groupName, csvfile } = this.state
    let error = {}
    let formIsValid = true

    if (groupName === '' || groupName.trim().length === 0) {
      formIsValid = false
      error['groupName'] = this.props.users.languageStateForRedux?.validations?.enterAudienceName
    }
    if (csvfile === '') {
      formIsValid = false
      error['csvfile'] = '*Please select csvfile.'
    }
    
    this.setState({ errors: error })
    return formIsValid
  }

  handleGroupSubmit = (e) => {
    e.preventDefault()
    if (this.isValidGroup()) {
      const { groupName, csvfile, page_no, fields, excludeFields } = this.state
      if (csvfile !== '') {
        const formData = new FormData()
        formData.append('groupName', groupName)
        formData.append('page_no', 1)
        formData.append('csvfile', csvfile)
        this.props.groupContactRequest(formData)
      } else {        
        const requestbody = { groupName, page_no: 1, fields, excludeFields }
        this.props.groupContactRequest(requestbody)
        //after call copy contacts api show progress bat immediately
        this.props.showProgressPopup()
      }

      this.setState({ groupName: '', groupOpen: false })
    }
  }

  handleGroupSubmitWithCsv = (e) => {
    e.preventDefault()
    if (this.isValidGroupCsv()) {
      const { groupName, csvfile, page_no, fields, excludeFields } = this.state
      if (csvfile !== '') {
        const formData = new FormData()
        formData.append('groupName', groupName)
        formData.append('page_no', page_no)
        formData.append('csvfile', csvfile)
        this.props.groupContactRequest(formData)
      }

      this.setState({ groupOpen: false })
      this.handleLoading()
    }
  }

  handleNext = (e) => {
    const { groupName } = this.state
    const requestbody = { groupName: groupName.replace(/\s{2,}/g, ' ').trim() }
    e.preventDefault()
    if (this.isValidGroup()) {
      this.props.groupCheckRequest(requestbody)
      this.setState({
        loading: true
      })
    }
  }

  handlePrevious = (e) => {
    e.preventDefault()
    this.setState({ firstNext: '0', errorMessage: '' })
  }
  handlePreviousSecond = () => {
    this.setState({ firstNext: '1' })
  }

  onCSVChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0]

      if (!imageFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.')
        return false
      }
      this.setState({ csvfile: event.target.files[0] })
    }
  }

  contactType = (contactType) => {
    if (contactType === 'copy') {
      this.setState({ contactType: contactType, firstNext: '2' })
    } else {
      this.setState({ contactType: contactType, firstNext: '3' })
    }
  }

  showErrorMessage = () => {
    this.setState({
      errorMessage: "There are no Project & Audience created yet"
    })
  }

  handleChangeDrop = (i, event) => {
    let data = this.state.fields
    if (event.target.name === 'eventType') {
      data[i].eventType = event.target.value
    } else if (event.target.name === 'project') {
      data[i].event = event.target.value
    } else if (event.target.name === 'audience') {
      data[i].audience = event.target.value
    } else if (event.target.name === 'selectType') {
      data[i].selectType = event.target.value
    }
    this.setState({ fields: data })
  }

  handleAdd = () => {
    let values = this.state.fields
    values.push({
      eventType: null,
      audience: null,
      selectType: null,
      event: null,
    })
    this.setState({
      fields: values,
    })
  }

  handleRemove = (i) => {
    const values = this.state.fields
    values.splice(i, 1)
    this.setState({
      fields: values,
    })
  }

  excludeHandleChangeDrop = (i, event) => {
    let data = this.state.excludeFields
    if (event.target.name === 'eventType') {
      data[i].eventType = event.target.value
    } else if (event.target.name === 'project') {
      data[i].event = event.target.value
    } else if (event.target.name === 'audience') {
      data[i].audience = event.target.value
    } else if (event.target.name === 'selectType') {
      data[i].selectType = event.target.value
    }

    this.setState({ excludeFields: data })
  }

  excludeHandleAdd = () => {
    let values = this.state.excludeFields
    values.push({
      eventType: null,
      audience: null,
      selectType: null,
      event: null,
    })
    this.setState({
      excludeFields: values,
    })
  }

  excludeHandleRemove = (i) => {
    const values = this.state.excludeFields
    values.splice(i, 1)
    this.setState({
      excludeFields: values,
    })
  }

  allEventsApiCall = () => {
    EventsAllAPI()
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({ events: result.data.events })
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  createGroupOnly = () => {
    const { groupName } = this.state
    const requestbody = { groupName: groupName.replace(/\s{2,}/g, ' ').trim(),lngCode:this.props.users.newLangState }
    this.props.createGroupOnly(requestbody)
    this.setState({
      loading: true
    })
  }

  handleClickReteyValidation = () => {
    this.setState({
      openValidation: false,
      loading: false
    })
  };

  handleClickCloseValidation = () => {
    this.setState({
      openValidation: false,
      groupOpen: false,
    })
  };

  sampleCsv = () => {
    var data = [
      {
        email: 'value',
      },
    ]
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Sample Csv',
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: 'sample',
    }

    const csvExporter = new generateCsv(options)
    if (data.length > 0) {
      csvExporter.generateCsv(data)
    }
  }

  groupRequestForDropDown = () => { 
    try {
      const { sort_by, order } = this.state
      const requestBody = {
        page_no: 1,
        sort_by,
        order,
        permission: 'View Audience',
      }
      this.props.groupRequest(requestBody)
    } catch (error) {
      createNotification('error', 'Something went wrong in audience listing')
    }
  }

  render() {
    const {
      errors,
      loading,
      groupName,
      contactType,
      firstNext,
      fields,
      events,
      errorMessage,
      excludeFields,
    } = this.state
    return (
      <ThemeProvider theme={theme}>
        {firstNext && firstNext == '0' ? (
          <Grid component='form' onSubmit={this.handleNext} container className='modal-wrapper' >
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.audienceTab?.addAudience}</Box>
              <Box className='small-subtitle'>
              {this.props.users.languageStateForRedux?.audienceTab?.addAudienceModalDesc}
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>

              <Box component="div" className='inputField' pb={4}>
                <label>{this.props.users.languageStateForRedux?.audienceTab?.audienceName}<span className='required'>*</span></label>
                <input
                  type="text"
                  class="form-control small"
                  name="groupName"
                  value={groupName}
                  onChange={this.handleChange}
                />
                <span className="error_mesage"> {errors.groupName} </span>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button type="submit" variant="contained" color="primary" disableElevation > {this.props.users.languageStateForRedux?.audienceTab?.next}
                    <i class="fa fa-chevron-right ml-2"></i></Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        ) : null}



        {firstNext && firstNext == '1' ? (

          <Grid component='form' container className='modal-wrapper' >
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.audienceTab?.addAudienceContacts}</Box>
              <Box className='small-subtitle'>
              {this.props.users.languageStateForRedux?.audienceTab?.addAudienceModalDesc2}
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>

              <Box component="div" className='inputField' pb={4}>
                <label>{this.props.users.languageStateForRedux?.audienceTab?.selectType}</label>
                <Grid container spacing={2.5}>
                  <Grid size={{xs: 6,}}>

                    <Stack pt={2.5} pb={3.5} sx={{
                      background: '#F5F4FF',
                      borderRadius: '4px', cursor: 'pointer',
                    }} spacing={2} alignItems={'center'} onClick={() => this.contactType('import')}>
                      <img width={'64'} height={'64'} alt='' src={ImportIcon} />
                      <p className='paragraph'>{this.props.users.languageStateForRedux?.common?.import}</p>
                    </Stack>

                  </Grid>
                  <Grid size={{xs: 6,}}>

                    <Stack pt={2.5} pb={3.5} sx={{
                      background: '#F5F4FF',
                      borderRadius: '4px', cursor: 'pointer',
                    }} spacing={2} alignItems={'center'} onClick={() => { (this.props?.groups?.groups?.length > 0) && (events?.events?.length > 0) ? this.showErrorMessage() : this.contactType('copy') }}>
                      <img width={'64'} height={'64'} alt='' src={CopyIcon} />
                      <p className='paragraph'>{this.props.users.languageStateForRedux?.buttons?.accessCodes?.copy}</p>
                    </Stack>

                  </Grid>
                  <Box mt={2} textAlign={'center'} width={'100%'} className="required">
                    {errorMessage && errorMessage}
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}} >
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Button startIcon={<KeyboardBackspaceIcon />} variant="outlined" color="primary" disableElevation onClick={this.handlePrevious}> {this.props.users.languageStateForRedux?.audienceTab?.previousButton}
                </Button>
                <Button variant="contained" color="primary" onClick={this.createGroupOnly}> {this.props.users.languageStateForRedux?.audienceTab?.skipAndCreateAudience}</Button>
              </Stack>
            </Grid>
          </Grid>
        ) : null
        }
        {
          contactType === 'import' && firstNext && firstNext == '3' ? (
            <form onSubmit={this.handleGroupSubmitWithCsv} container className='modal-wrapper'>
              <Grid container >
                <Grid size={{xs: 12,}} pb={4}>
                  <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.audienceTab?.importContacts}</Box>
                  <p className="small-subtitle">
                  {this.props.users.languageStateForRedux?.audienceTab?.importContactDesc}
                  </p>
                </Grid>
                <Grid size={{xs: 12,}}>
                  <Box component="div" className='inputField' pb={4}>
                    <label> {this.props.users.languageStateForRedux?.actions?.accessCodes?.select} <span className="required">*</span></label>
                    <input
                      className='form-control small'
                      type="file"
                      id="csvfile"
                      name="csvfile"
                      onChange={this.onCSVChange}
                      accept=".csv"
                    />
                    <span className="error_mesage"> {errors.csvfile} </span>

                    <Button
                      onClick={this.sampleCsv}
                      variant="text"
                      color="primary"
                      startIcon={<img alt="" src={ExportIcon} />}
                    >
                      {this.props.users.languageStateForRedux?.actions?.accessCodes?.download}
                    </Button>
                  </Box>
                </Grid>
                <Grid size={{xs: 12,}} >
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Button startIcon={<KeyboardBackspaceIcon />} variant="outlined" color="primary" disableElevation onClick={this.handlePreviousSecond}> {this.props.users.languageStateForRedux?.audienceTab?.previousButton}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" > {this.props.users.languageStateForRedux?.common?.import}</Button>
                  </Stack>
                </Grid>
              </Grid >
            </form>
          ) : null
        }

        {
          firstNext && firstNext == '2' ? (
            <>
              <form onSubmit={this.handleGroupSubmit} className='modal-wrapper' >
                <Grid spacing={3} container >
                  <Grid size={{xs: 12,}} >
                    <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.audienceTab?.copyContacts}</Box>
                    <Box className='small-subtitle'>
                      {this.props.users.languageStateForRedux?.audienceTab?.copyContactModalDesc}
                    </Box>
                  </Grid>
                  <Grid size={{xs: 12,}}>

                    <Box sx={{
                      background: '#F5F4FF',
                      borderRadius: '4px',
                    }}
                      component="div" className='inputField' p={2} pb={4}>
                      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mb={1}>
                        <Box component={'label'} mb={0}>{this.props.users.languageStateForRedux?.audienceTab?.include}</Box>
                        <Button onClick={() => this.handleAdd()} size='small' variant="text" color='primary' startIcon={<span>+</span>}>
                        {this.props.users.languageStateForRedux?.audienceTab?.add}
                        </Button>
                      </Stack>

                      {fields.map((field, idx) => {
                        return (
                          <Grid container className="selectbar_audience" idx={idx} key={`${field}-${idx}`}>
                            <Grid size={{xs: "grow",}}>
                            
                              <FormControl fullWidth size="small">
                                <Select
                                  name="selectType"
                                  className='form-control select small'
                                  style={{ padding: '0px', marginBottom: '8px' }}
                                  value={field.selectType || ''}
                                  onChange={(e) => this.handleChangeDrop(idx, e)}
                                  MenuProps={MenuProps}
                                  displayEmpty
                                >

                                  <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.common?.select}</MenuItem>
                                  {events &&
                                    events?.length > 0 &&
                                    <MenuItem value="project">{this.props.users.languageStateForRedux?.common?.project}</MenuItem>
                                  }
                                  {this.props?.groups?.groups?.length > 0 &&
                                    <MenuItem value="audience">{this.props.users.languageStateForRedux?.overviewTab?.audience}</MenuItem>
                                  }
                                </Select>
                              </FormControl>

                              {field.selectType && field.selectType === 'project' && (
                                <FormControl fullWidth size="small">
                                  <Select
                                    className='form-control select small'
                                    style={{ padding: '0px', marginBottom: '8px', }}
                                    name="project"
                                    onChange={(e) => this.handleChangeDrop(idx, e)}
                                    value={field.event || ''}
                                    MenuProps={MenuProps}
                                    displayEmpty

                                  >
                                    <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.audienceTab?.selectProject}</MenuItem>                                    
                                    { events && 
                                       events.length > 0 &&
                                      events
                                      .filter(event=> {
                                        return (!fields.some(f => f.event === event._id)) || (fields.findIndex(f => f.event == event._id) == idx)
                                      } )
                                      .map((event) => {
                                        return (
                                          <MenuItem value={event._id} key={event._id}>
                                            {event.eventName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                  <Select
                                    className='form-control select small'
                                    style={{ padding: '0px', marginBottom: '8px', }}
                                    name="eventType"
                                    onChange={(e) => this.handleChangeDrop(idx, e)}
                                    value={field.eventType || ''}
                                    MenuProps={MenuProps}
                                    displayEmpty

                                  >
                                    <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.audienceTab?.attendeeStatus}</MenuItem>
                                    <MenuItem value="all">{this.props.users.languageStateForRedux?.common?.all}</MenuItem>
                                    <MenuItem value="checkedIn">{this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}</MenuItem>
                                    <MenuItem value="notCheckedIn">
                                    {this.props.users.languageStateForRedux?.column_names?.attendees?.notChecked}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              {field.selectType && field.selectType === 'audience' && (
                                <FormControl fullWidth size="small">
                                  <Select
                                    style={{ padding: '0px', marginBottom: '8px', }}
                                    className='form-control select small'
                                    name="audience"
                                    onChange={(e) => this.handleChangeDrop(idx, e)}
                                    value={field.audience || ''}
                                    MenuProps={MenuProps}
                                    displayEmpty

                                  >
                                    <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.audienceTab?.chooseAudience}</MenuItem>
                                    {this.props.groups.groups &&
                                      this.props.groups.groups
                                      .filter(group=> {
                                        return (!fields.some(f => f.audience === group._id)) || (fields.findIndex(f => f.audience == group._id) == idx)
                                      } )
                                      .map((group) => {
                                        return (
                                          <MenuItem value={group._id} key={group._id}>
                                            {group.groupName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid  pl={2}>
                              <Button style={{ marginTop: '6px', padding: '6px', minWidth: '31px', minHeight: '31px', }} onClick={() => this.handleRemove(idx)} variant='contained' color='secondary'>
                                <CloseIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      })}

                    </Box>
                  </Grid>
                  <Grid size={{xs: 12,}}>

                    <Box sx={{
                      background: '#F5F4FF',
                      borderRadius: '4px',
                    }}
                      component="div" className='inputField' p={2} pb={4}>
                      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mb={1}>
                        <Box component={'label'} mb={0}>{this.props.users.languageStateForRedux?.audienceTab?.exclude}</Box>
                        <Button onClick={() => this.excludeHandleAdd()} size='small' variant="text" color='primary' startIcon={<span>+</span>}>
                        {this.props.users.languageStateForRedux?.audienceTab?.add}
                        </Button>
                      </Stack>

                      {excludeFields.map((field, idx) => {
                        return (
                          <Grid container className="selectbar_audience"
                            key={`${field}-${idx}`}>
                            <Grid size={{xs: "grow",}}>
                              <FormControl fullWidth size="small">
                                <Select
                                  style={{ padding: '0px', marginBottom: '8px', }}
                                  className='form-control select small'
                                  name="selectType"
                                  onChange={(e) => this.excludeHandleChangeDrop(idx, e)}
                                  value={field.selectType || ''}
                                  displayEmpty
                                  MenuProps={MenuProps}
                                >
                                  <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.common?.select}</MenuItem>
                                  {events &&
                                    events?.length > 0 &&
                                    <MenuItem value="project">{this.props.users.languageStateForRedux?.common?.project}</MenuItem>
                                  }
                                  {this.props?.groups?.groups?.length > 0 &&
                                    <MenuItem value="audience">{this.props.users.languageStateForRedux?.overviewTab?.audience}</MenuItem>
                                  }
                                </Select>
                              </FormControl>
                              {field.selectType && field.selectType === 'project' && (
                                <FormControl fullWidth size="small">
                                  <Select
                                    style={{padding: '0px', marginBottom: '8px', }}
                                    className='form-control select small'
                                    name="project"
                                    onChange={(e) =>
                                      this.excludeHandleChangeDrop(idx, e)
                                    }
                                    value={field.event || ''}
                                    displayEmpty
                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.audienceTab?.selectProject}</MenuItem>
                                    { events && 
                                       events.length > 0 &&
                                      events
                                      .filter(event=> {
                                        return (!fields.some(f => f.event === event._id)) || (fields.findIndex(f => f.event == event._id) == idx)
                                      } )
                                      .map((event) => {
                                        return (
                                          <MenuItem value={event._id}>
                                            {event.eventName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                  <Select
                                    style={{ padding: '0px', marginBottom: '8px', }}
                                    className='form-control select small'
                                    name="eventType"
                                    onChange={(e) =>
                                      this.excludeHandleChangeDrop(idx, e)
                                    }
                                    value={field.eventType || ''}
                                    displayEmpty
                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem value="" disabled hidden>Attendees Status</MenuItem>
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="checkedIn">CHECKED IN</MenuItem>
                                    <MenuItem value="notCheckedIn">
                                      NOT CHECKED IN
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              {field.selectType && field.selectType === 'audience' && (
                                <FormControl fullWidth size="small">
                                  <Select
                                    style={{ padding: '0px', marginBottom: '8px', }}
                                    className='form-control select small'
                                    name="audience"
                                    onChange={(e) =>
                                      this.excludeHandleChangeDrop(idx, e)
                                    }
                                    value={field.audience || ''}
                                    displayEmpty
                                    MenuProps={MenuProps}
                                  >
                                    <MenuItem value="" disabled hidden>{this.props.users.languageStateForRedux?.audienceTab?.chooseAudience}</MenuItem>
                                    {this.props.groups.groups &&
                                      this.props.groups.groups
                                      .filter(group=> {
                                        return (!fields.some(f => f.audience === group._id)) || (fields.findIndex(f => f.audience == group._id) == idx)
                                      } )
                                      .map((group) => {
                                        return (
                                          <MenuItem value={group._id}>
                                            {group.groupName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                </FormControl>
                              )}
                            </Grid>
                            <Grid  pl={2}>
                              <Button style={{ marginTop: '6px', padding: '6px', minWidth: '31px', minHeight: '31px', }} onClick={() => this.excludeHandleRemove(idx)} variant='contained' color='secondary'>
                                <CloseIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      })}

                    </Box>
                  </Grid>
                  <Grid size={{xs: 12,}} >
                    <Stack spacing={1} direction="row" justifyContent="space-between">
                      <Button startIcon={<KeyboardBackspaceIcon />} variant="outlined" color="primary" onClick={this.handlePreviousSecond}> {this.props.users.languageStateForRedux?.audienceTab?.previousButton}
                      </Button>
                      <Button type="submit" variant="contained" color="primary" > {this.props.users.languageStateForRedux?.audienceTab?.copyContacts}</Button>
                    </Stack>
                  </Grid>
                </Grid >
              </form >
            </>
          ) : null
        }
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </ThemeProvider >
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  users: state.Users
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      groupContactRequest: Actions.groupContactRequest,
      groupRequest: Actions.groupRequest,
      groupCheckRequest: Actions.groupCheckRequest,
      createGroupOnly: Actions.createGroupRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(GroupModal)

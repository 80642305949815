import React, { Component } from 'react';
// import '../../sass/main.scss';
import Button from '@mui/material/Button';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification, redirectTo } from '../../helpers';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import EditingBack from '../../assets/images/backPage.png';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gapiClient from 'gapi-client';
import moment1 from 'moment-timezone';
import UpdateEventForm from './updateEvent';
import UpdateEventRegistration from './updateEventRegistration';

class UpdateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      title: 'Update Event',
      pageTitle: 'Update Event',
      errors: {},
      loading: false,
      _id: 0,
      eventId: 0,
      eventType: '',
    };
  }

  async componentDidMount() {
    if ( this.props.params.eventId !== '') {
      this.setState({ eventId:  this.props.params.eventId });
      this.props.eventRequest( this.props.params.eventId);
      this.handleLoading();
    }

    //await redirectTo(this.props.navigate,'contact',this.props.location.pathname)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.events) {
      if (
        this.props.events.success === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        let st = prevState.loading ? this.setState({ loading: false }) : null;
        const events = this.props.events.event;
        this.setState({
          eventType: events.eventType,
          _id: events._id,
          timerClock: events.timerClock,
        });
      }
    }
  }

  handleChangeLocation = (location) => {
    this.setState({ location: location });
  };
  handleSelect = (location) => {
    this.setState({ location });

    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch((error) => console.error('Error', error));
  };

  addEventToCalendars = (eventData, date) => {
    let startTime = moment(`${eventData.startDate}:00.000Z`)
      .add('8', 'hours')
      .format();
    let endTime = moment(`${eventData.endDate}:00.000Z`)
      .add('8', 'hours')
      .add('30', 'minutes')
      .format();
    var gapi = window.gapi;
    
    var CLIENT_ID =
      '903336222688-uc69d0bdho6rfe2p2ogv4fb70t62t0db.apps.googleusercontent.com';
    var API_KEY = 'AIzaSyCwfhH0AzwGKXvw6qbiiVpvzcZmpoW3-VI';
    var SCOPES = 'https://www.googleapis.com/auth/calendar';
    var DISCOVERY_DOCS = [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    ];

    gapi.load('client:auth2', () => {
       gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      
      var lp = gapi.client.load('calendar', 'V3');
     
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: `${eventData.title} - ${moment(`${eventData.startDate}`, [
              'HH:mm',
            ]).format('hh:mm A')} PST`,
            // location: "illumeet square",
            description: `${eventData.google_meet}`,
            start: {
              dateTime: startTime,
              timeZone: moment1.tz.guess(),
            },
            end: {
              dateTime: endTime,
              timeZone: moment1.tz.guess(),
            },
            // recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
            reminders: {
              useDefault: false,
              overrides: [
                { method: 'email', minutes: 24 * 60 },
                { method: 'popup', minutes: 10 },
              ],
            },
          }; 
          var request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
          });

          request.execute((event) => { 
            if (event.status === 'confirmed') {
              createNotification('success', 'Event added to google calendar.');
            } else {
              createNotification(
                'danger',
                'Unable to add event to calendar. Please try again later.',
              );
            }
            window.open(event.htmlLink);
          });
        });
    });
  };

  render() {
    const { loading, pageTitle, eventId, eventType } = this.state;
    let memberPage = '/my-events';
    if (this.props.location.pathname.includes('member')) {
      memberPage = '/member/my-events';
    }

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <div className="editingDashboard">
          <div className="editingHeader">
            <div className="editingHeading">
              <h3>
                <Link to={memberPage}>
                  <img src={EditingBack} alt="" />
                </Link>
                Events
              </h3>
            </div>
            <div className="saveTemplateSubmit">
              <div className="mobile-none">
                <Button
                  className="saveButton mobile-None"
                  onClick={() =>
                    redirectTo(
                      this.props.navigate,
                      `project-type-edit/${eventId}`,
                      this.props.location.pathname,
                    )
                  }
                >
                  Continue
                </Button>
              </div>
              <a href className="MobileSaveButton desktop-None">
                <i className="fa fa-check"></i>
              </a>
            </div>
          </div>
          <div className="editDBSteppers">
            <div className="projectStepRow mb-5">
              <div className="projectSteps">
                <ul className="projectStepColl fourStepColl">
                  <li>
                    <span class="projectProcess singleDot"></span>
                    Event Details
                  </li>
                  <li>
                    <span className="projectProcess"></span>
                    Design
                  </li>
                  {/* <li >
                                <span className="projectProcess"></span>
                                Recipients
                            </li>
                            <li>
                                <span className="projectProcess"></span>
                                Review
                            </li>*/}
                </ul>
              </div>
            </div>
          </div>
          {this.props.events.event &&
            Array.isArray(this.props.events.event) === false &&
            Object.keys(this.props.events.event).length > 0 ? (
            eventType === 'event' ? (
              <UpdateEventForm navigate={navigate} location={location} />
            ) : (
              <UpdateEventRegistration navigate={navigate} location={location} />
            )
          ) : null}
        </div>
        {loading ? <Loader /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(UpdateEvent);

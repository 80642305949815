import React, { Component } from 'react';
import LineChart from './lineChart'
import { getProjectIdFromUrl } from '../../../helpers/common';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import Button from '@mui/material/Button'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { createNotification } from '../../../helpers';
export class OverviewCampaignChart extends Component {
  constructor(props) {
    super(props)
    this.anchorRef1 = React.createRef();

    this.state = {
      projectId: '',
      totalSentArray: [],
      deliveredArray: [],
      rejectArray: [],
      clickArray: [],
      openArray: [],
      campaignsFilterOptionsList: [],
      campaignsFilterOptionsSelected: [],
      open1: false,
      totalEmail: 0,
      totalSendEmail: 0,
      deliverRate: 0,
      openEmail: 0,
      openRate: 0,
      clickEmail: 0,
      clickRate: 0,
      reject: 0,
      rejectRate: 0
    }
  }


  handleToggle1 = () => {
    this.setState({
      open1: !this.state.open1
    });
  };

  handleClose = (event) => {
    this.setState({
      open1: false,
    });
  };


  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
      });
      this.handleLoading()
    }
  }

  async componentDidMount() {
    const projectId = getProjectIdFromUrl()
    this.setState({
      projectId
    }, () => {
      this.reportData()
    })

    this.props.listAllCampaignsRequest({
      eventId: projectId,
      requestFrom: 'viewProject'
    })
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.events.emailDataReportsSuccess === true && prevProps.events.emailDataReportsSuccess === false && this.props.events.message !== prevProps.events.message) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      const emailReports = this.props.events.reports.mandrill

      if (Object.keys(emailReports).length > 0) {
        const total =
          parseInt(emailReports.sent) +
          parseInt(emailReports.hard_bounces) +
          parseInt(emailReports.soft_bounces) +
          parseInt(emailReports.rejects) +
          parseInt(emailReports.failed) +
          parseInt(emailReports.spam) +
          parseInt(emailReports.que) +
          parseInt(emailReports.unsub) +
          parseInt(emailReports.deferral)
        
        let rejectRate =
          emailReports.rejects !== 0 ? (emailReports.rejects * 100) / total : 0
        let openRate =
          emailReports.opens !== 0 ? (emailReports.opens * 100) / total : 0
        let clickRate =
          emailReports.clicks !== 0 ? (emailReports.clicks * 100) / total : 0
        let deliverRate =
          emailReports.sent !== 0 ? (emailReports.sent * 100) / total : 0        

        this.setState({
          totalEmail: total,
          totalSendEmail: emailReports.sent,
          reject: emailReports.rejects,
          rejectRate: rejectRate.toFixed(2),
          openRate: openRate.toFixed(2),
          clickRate: clickRate.toFixed(2),
          deliverRate: deliverRate.toFixed(2),
          openEmail: emailReports.opens,
          clickEmail: emailReports.clicks
        })

        const total_last_7_days =
          parseInt(emailReports.stats.last_7_days.sent) +
          parseInt(emailReports.stats.last_7_days.hard_bounces) +
          parseInt(emailReports.stats.last_7_days.soft_bounces) +
          parseInt(emailReports.stats.last_7_days.rejects) +
          parseInt(emailReports.stats.last_7_days.failed) +
          parseInt(emailReports.stats.last_7_days.spam) +
          parseInt(emailReports.stats.last_7_days.que) +
          parseInt(emailReports.stats.last_7_days.unsub) +
          parseInt(emailReports.stats.last_7_days.deferral);

        const total_last_30_days =
          parseInt(emailReports.stats.last_30_days.sent) +
          parseInt(emailReports.stats.last_30_days.hard_bounces) +
          parseInt(emailReports.stats.last_30_days.soft_bounces) +
          parseInt(emailReports.stats.last_30_days.rejects) +
          parseInt(emailReports.stats.last_30_days.failed) +
          parseInt(emailReports.stats.last_30_days.spam) +
          parseInt(emailReports.stats.last_30_days.que) +
          parseInt(emailReports.stats.last_30_days.unsub) +
          parseInt(emailReports.stats.last_30_days.deferral);

        const total_last_60_days =
          parseInt(emailReports.stats.last_60_days.sent) +
          parseInt(emailReports.stats.last_60_days.hard_bounces) +
          parseInt(emailReports.stats.last_60_days.soft_bounces) +
          parseInt(emailReports.stats.last_60_days.rejects) +
          parseInt(emailReports.stats.last_60_days.failed) +
          parseInt(emailReports.stats.last_60_days.spam) +
          parseInt(emailReports.stats.last_60_days.que) +
          parseInt(emailReports.stats.last_60_days.unsub) +
          parseInt(emailReports.stats.last_60_days.deferral);

        const total_last_90_days =
          parseInt(emailReports.stats.last_90_days.sent) +
          parseInt(emailReports.stats.last_90_days.hard_bounces) +
          parseInt(emailReports.stats.last_90_days.soft_bounces) +
          parseInt(emailReports.stats.last_90_days.rejects) +
          parseInt(emailReports.stats.last_90_days.failed) +
          parseInt(emailReports.stats.last_90_days.spam) +
          parseInt(emailReports.stats.last_90_days.que) +
          parseInt(emailReports.stats.last_90_days.unsub) +
          parseInt(emailReports.stats.last_90_days.deferral);
        
        let totalSentArray = [
          total,
          total_last_7_days,
          total_last_30_days,
          total_last_60_days,
          total_last_90_days,
        ]

        let deliveredArray = [
          emailReports.stats.today.sent,
          emailReports.stats.last_7_days.sent,
          emailReports.stats.last_30_days.sent,
          emailReports.stats.last_60_days.sent,
          emailReports.stats.last_90_days.sent,
        ]

        let rejectArray = [
          emailReports.stats.today.rejects,
          emailReports.stats.last_7_days.rejects,
          emailReports.stats.last_30_days.rejects,
          emailReports.stats.last_60_days.rejects,
          emailReports.stats.last_90_days.rejects,
        ]

        let openArray = [
          emailReports.stats.today.opens,
          emailReports.stats.last_7_days.opens,
          emailReports.stats.last_30_days.opens,
          emailReports.stats.last_60_days.opens,
          emailReports.stats.last_90_days.opens,
        ]

        let clickArray = [
          emailReports.stats.today.clicks,
          emailReports.stats.last_7_days.clicks,
          emailReports.stats.last_30_days.clicks,
          emailReports.stats.last_60_days.clicks,
          emailReports.stats.last_90_days.clicks,
        ]

        this.setState({
          totalSentArray: totalSentArray,
          deliveredArray: deliveredArray,
          rejectArray: rejectArray,
          openArray: openArray,
          clickArray: clickArray
        })
      }
    }

    if (this.props.campaigns.listAllCampaignsSuccess && !prevProps.campaigns.listAllCampaignsSuccess &&
      this.props.campaigns.message !== prevProps.campaigns.message) {
      this.setState({
        campaignsFilterOptionsList: this.props.campaigns.allCampaigns
      })
    }
  }

  reportData = () => {
    let requestbody = {
      eventId: this.state.projectId,
      emailType: 'campaigns',
      permission : "View Project"
    }
    if (this.state.campaignsFilterOptionsSelected.length) {      
      requestbody['templateId'] = this.state.campaignsFilterOptionsSelected
    }
    this.props.reportDataRequest(requestbody)
  }

  handleFilterChange = (event) => {
    event.preventDefault()
    try {
        let campaignsFilterOptionsSelectedArray = [...this.state.campaignsFilterOptionsSelected];
        
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            campaignsFilterOptionsSelectedArray = [...this.state.campaignsFilterOptionsList.map(el => el._id)]
          } else {
            campaignsFilterOptionsSelectedArray = []
          }
        } else {
          let filterIndex = campaignsFilterOptionsSelectedArray.indexOf(event.target.name)
          if (filterIndex > -1) {
            campaignsFilterOptionsSelectedArray.splice(filterIndex, 1)
          } else {
            campaignsFilterOptionsSelectedArray.push(event.target.name)
          }
        }

        this.setState({           
          campaignsFilterOptionsSelected: [...campaignsFilterOptionsSelectedArray]        
        }, () => this.reportData())
    } catch (error) {
        createNotification("error", "something went wrong")
    }
  }

  render() {
    
    const { 
      totalSentArray,
      deliveredArray,
      rejectArray,
      clickArray, 
      openArray, 
      open1, 
      campaignsFilterOptionsList,
      campaignsFilterOptionsSelected,
      totalEmail,
      totalSendEmail,
      deliverRate,
      openEmail,
      openRate,
      clickEmail,
      clickRate,
      reject,
      rejectRate
    } = this.state;
    const campaignsFilterOptionsSelectedAll = campaignsFilterOptionsSelected.length === campaignsFilterOptionsList.length ? true : false;
    return (
      <ThemeProvider theme={theme}>
        <Grid size={{xs: 12,}} className="dashboard-table-card">
          <Box
            className=""
            sx={{
              backgroundColor: '#ffffff',
              border: '1px solid #EDECF5',
              borderRadius: '8px',
            }}
          >
            <Stack
              direction={'row'}
              px={3}
              py={1}
              alignItems="center"
              justifyContent={'space-between'}
              className="group-info-header"
            >

              <span className="paragraph bold">
              {this.props.users.languageStateForRedux?.overviewTab?.campaignsChart}
              </span>

              <Box component="div">
                <Button
                  //fullWidth
                  variant="text"
                  style={{
                    padding: '10px 15px',
                    borderRadius: '7px',
                    justifyContent: 'space-between',
                    textTransform: 'capitalize',
                    width: 'max-content',
                  }}
                  size="small"
                  disableElevation
                  ref={this.anchorRef1}
                  aria-controls={open1 ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleToggle1}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {this.props.users.languageStateForRedux?.overviewTab?.allCampaigns}
                </Button>
                <Popper
                  open={open1}
                  style={{ zIndex: '3' }}
                  placement="bottom-end"
                  transition
                  anchorEl={this.anchorRef1.current}
                  role={undefined}
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250}>
                      <Box
                        sx={{
                          maxHeight: '130px',
                          overflow: 'scroll',
                          backgroundColor: theme.palette.common.white,
                          boxShadow:
                            '0px 6px 20px rgba(0, 0, 0, 0.2)',
                          borderRadius: '4px',
                          marginTop: '8px',
                        }}
                      >
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList
                            autoFocusItem={this.open1}
                            id="menu-list-grow"
                            onKeyDown={this.handleListKeyDown}
                          >
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{
                                      padding: 0,
                                      marginRight: '5px',
                                    }}
                                    color="primary"
                                    name="all" //////////////////////////////////////////////////////////////////
                                    onChange={(e) => this.handleFilterChange(e)} 
                                    checked={campaignsFilterOptionsSelectedAll}
                                  />
                                }
                                label={this.props.users.languageStateForRedux?.overviewTab?.allCampaigns}
                                style={{ padding: 0, margin: 0 }}
                              />
                            </MenuItem>
                            {campaignsFilterOptionsList.map((option) => {
                                return (
                                  <MenuItem key={option?._id} className="checkbox-item">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{
                                            padding: 0,
                                            marginRight: '5px',
                                          }}
                                          color="primary"
                                          name={option?._id}
                                          onChange={(e) => this.handleFilterChange(e)}
                                          checked={campaignsFilterOptionsSelected.indexOf(option?._id) > -1 ? true : false}
                                        />
                                      }
                                      label={option?.campaignName}
                                      style={{ padding: 0, margin: 0 }}
                                    />
                                  </MenuItem>
                                )
                            })}                            
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Box>
            </Stack>
            <Box
              sx={{ borderTop: '1px solid #eeeeee' }}
            // py={1}
            // px={1}
            >
              <Grid
                container
                px={2}
                py={1}
              >
                <Grid >
                  <Box
                    sx={{ borderRight: '1px solid #eeeeee' }}
                    px={2}
                    my={1}
                  >
                    <Box className='subsecondary bold'>{totalEmail}</Box>
                    <Box className='small-subtitle'>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.sentMail}</Box>
                  </Box>
                </Grid>
                <Grid >
                  <Box
                    sx={{ borderRight: '1px solid #eeeeee' }}
                    px={2}
                    my={1}
                  >
                    <Box className='subsecondary bold'>{totalSendEmail} 
                    <span className='small-subtitle'> ({deliverRate}%)</span></Box>
                    <Box className='small-subtitle'>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.deliveredMail}</Box>
                  </Box>
                </Grid>
                <Grid >
                  <Box
                    sx={{ borderRight: '1px solid #eeeeee' }}
                    px={2}
                    my={1}
                  >
                    <Box className='subsecondary bold'>{openEmail} 
                    <span className='small-subtitle'> ({openRate}%)</span></Box>
                    <Box className='small-subtitle'>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.openedRates}</Box>
                  </Box>
                </Grid>
                <Grid >
                  <Box
                    sx={{ borderRight: '1px solid #eeeeee' }}
                    px={2}
                    my={1}
                  >
                    <Box className='subsecondary bold'>{clickEmail}
                    <span className='small-subtitle'> ({clickRate}%)</span></Box>
                    <Box className='small-subtitle'>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.clickRates}</Box>
                  </Box>
                </Grid>
                <Grid >
                  <Box
                    sx={{}}
                    px={2}
                    my={1}
                  >
                    <Box className='subsecondary bold' style={{ color: theme.palette.secondary.main }}>{reject}
                    <span className='small-subtitle' style={{ color: theme.palette.secondary.main }}> ({rejectRate}%)</span></Box>
                    <Box className='small-subtitle'>{this.props.users.languageStateForRedux?.overviewTab?.campaignsChartParams?.rejectedEmail}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{ borderTop: '1px solid #eeeeee' }}
              py={1}
              px={1}
            >
              <LineChart
                totalSentArray={totalSentArray}
                deliveredArray={deliveredArray}
                rejectArray={rejectArray}
                openArray={openArray}
                clickArray={clickArray}
              />
            </Box>
          </Box>
        </Grid>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  campaigns: state.Campaigns,
  events: state.Events,
  users: state.Users,
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      listAllCampaignsRequest: Actions.listAllCampaignsRequest,
      reportDataRequest: Actions.emailDataReportsRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(OverviewCampaignChart);


//@ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, Radio, RadioGroup } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';//@ts-nocheck
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  FormGroup,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import {
  Account,
  AccountsState,
  ContactCf,
  CustomFields,
  Source,
} from 'ReduxStore/Reducers/opportunity/accounts.reducer';
import { FetchAccountsQueryParams } from './Accounts';
import {
  CustomField,
  CustomFields_State,
} from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  Contact,
  ContactState,
} from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import { ProjectsList } from 'Pages/AIChatbot/Tabs/General/GeneralDashboard';
import { EventsAllAPIWithPagination, getActiveUsersForCompanyAPI } from 'ReduxStore/API';
import { Actions } from 'ReduxStore/Actions';
import { isValidEmail, isValidURL, validateSocialMediaUrl } from 'helpers/common';
import CustomSearchableDropdownForContactAndAccountDataTypeCF from 'Components/CustomDropdowns/CustomSearchableDropdownForContactAndAccountDataTypeCF';
import CreateOrEditContact from './CreateOrEditContact';
import ViewContactOrAccountDetails from './ViewContactOrAccountDetails';
import OpportunityContactsAPI from 'ReduxStore/API/opportunity/opportunityContactsAPI';
import OpportunityAccountsCustomFieldsAPI from 'ReduxStore/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';
import OpportunityAccountsAPI from 'ReduxStore/API/opportunity/opportunityAccountsAPI';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import moment from 'moment';
import { OpportunityState } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import DropdownForListDataTypeCF from '../Common/DropdownForListDataTypeCF';
import InputFieldForPhoneDataTypeCF from '../Common/InputFieldForPhoneDataTypeCF';
import { createNotification } from 'helpers';
import StateDropdown from '../CommonComponents/StateDropdown';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State,
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import CustomSearchableMultiSelectedDropdownWithLoadMoreOPM from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMoreOPM';
import ReactDatePicker from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type Props = {
  usedFor: 'Account' | 'Opportunity';
  queryParams: FetchAccountsQueryParams;
  toBeEdited: Account;
  resetAddNew: () => void;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  bodyParamsForfiltering: any;
};

type CustomFieldsValidationError = {
  [key: string]: boolean | string;
};

type CustomFieldsObj = {
  [key: string]: string | string[] | ContactCf;
};

type GetProjectsParams = {
  page_no: number;
  limit: number;
  searchText: string;
};

const limitForEventsList: number = 20;

let changesMadeInCreateOrEditContact = false;

const AddOrEditAccount = ({
  usedFor,
  queryParams,
  toBeEdited,
  resetAddNew,
  setShowAddNew,
  bodyParamsForfiltering,
}: Props) => {
  const firstRender1 = useRef(true);
  const firstRender2 = useRef(true);
  const firstRender3 = useRef(true);
  const firstRender4 = useRef(true);
  const childRefs = useRef<any>([]);
  const haveToCallCreateOrUpdateContactFunctionInChild = useRef(false);
  const savingContactAlso = useRef(false);
  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const accountState = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const contactState = useSelector(
    (state: any) => state.OpportunityContacts,
  ) as ContactState;

  const opportunityState = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const { activeUsersForCompany } = useSelector((state: any) => state.Users);

  const {
    opportunityStates: opportunityDynamicStates,
    fetchAllOpportunityStateSuccess,
  } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const dispatch = useDispatch();

  // State variables
  const [name, setName] = useState<string>('');
  const [source, setSource] = useState<Source>('manuallyAdded');
  const [customFieldsObj, setCustomFieldsObj] = useState<CustomFieldsObj>({});
  const [idBeingEdited, setIdBeingEdited] = useState<string>('');
  const [customFieldsValidationError, setCustomFieldsValidationError] =
    useState<CustomFieldsValidationError>({} as CustomFieldsValidationError);

  //for searchableDropdownWithLoadMore
  const [projectsList, setProjectsList] = useState<ProjectsList>([]);
  const [searchTextForContactDropdown, setSearchTextForContactDropdown] =
    useState<string>('');
  const [searchTextForProjectDropdown, setSearchTextForProjectDropdown] =
    useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const [contactsSearchLists, setContactsSearchLists] = useState<{
    [key: string]: Contact;
  } | null>(null);
  const [selectedContactAsCFValue, setSelectedContactAsCFValue] = useState<{
    [key: string]: Contact;
  }>({});
  const [
    previouslySelectedContactAsCFValue,
    setPreviouslySelectedContactAsCFValue,
  ] = useState<{
    [key: string]: Contact;
  }>({});
  const [activeCfIdForContactDropdown, setActiveCfIdForContactDropdown] =
    useState<string>('');
  const [dynamicContactOpenState, setdynamicContactOpenState] = useState<{
    [key: string]: boolean;
  }>({});
  const [customFieldsFromContacts, setCustomFieldsFromContacts] = useState<
    CustomField[]
  >({} as CustomField[]);
  const [listOfSelectedProjectId, setListOfSelectedProjectId] = useState<
    string[]
  >([]);
  const [errors, setErrors] = useState<
    CustomFieldsValidationError
  >({});
  const [validationRequired, setValidationRequired] = useState<boolean[]>([]);
  const [
    openAddNewContactConfirmationPopup,
    setOpenAddNewContactConfirmationPopup,
  ] = useState<boolean>(false);
  const [cfIdForAddNewContact, setCfIdForAddNewContact] = useState<string>('');
  const [contactConfirmationText, setContactConfirmationText] =
    useState<string>('');
  const [
    openChangeSelectedContactConfPopup,
    setOpenChangeSelectedContactConfPopup,
  ] = useState<boolean>(false);
  const [
    optionForHandleContactCFChangeForSearchableDropdown,
    setOptionForHandleContactCFChangeForSearchableDropdown,
  ] = useState<any>({});
  const [
    cfidForHandleContactCFChangeForSearchableDropdown,
    setCfidForHandleContactCFChangeForSearchableDropdown,
  ] = useState<string>('');
  const [usersForUserCfDataType, setUsersForUserCfDataType] = useState([]);
  const [searchTextForUsers, setSearchTextForUsers] = useState('');
  const [searchTextForUsersDebouncedValue, setSearchTextForUsersDebouncedValue] = useState('');
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>(
    [],
  );
  const [selectedDynamicState, setSelectedDynamicState] = useState<string>('');

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
    }
    let defaultState = opportunityDynamicStates.find(
      (state) => state.isDefault == true,
    );
    if (!toBeEdited && defaultState && defaultState._id) {
      setSelectedDynamicState(JSON.stringify({ _id: defaultState._id, name: defaultState.name }));
    }

  }, [fetchAllOpportunityStateSuccess]);


  useEffect(() => {
    setCustomFieldsObj(settingCustomFieldObjWithUndeletedCustomFields());
    fetchAllOpportunityCustomField();
    getAllOpportunityGroups();
    return () => {
      dispatch(Actions.setIsSavingAsDraft(false));
    };
  }, []);

  useEffect(() => {
    let timeout: any;
    if (firstRender4.current) {
      firstRender4.current = false;
    } else {
      if (name.length) {
        timeout = setTimeout(() => {
          checkIfNameAlreadyExists();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [name]);

  const getAllOpportunityGroups = () => {
    dispatch(Actions.getAllOpportunityGroupsRequest());
  };

  const checkIfNameAlreadyExists = async () => {
    let opportunityAccountsAPI = new OpportunityAccountsAPI();
    const bodyParams = {
      name: name.trim(),
      id: idBeingEdited || ""
    };
    let res = await opportunityAccountsAPI.checkIfNameAlreadyExists(bodyParams);
    if (res.data) {
      setErrors(prev => ({ ...prev, name: "Name already exists" }));
    } else {
      setErrors(prev => ({ ...prev, name: "" }));
    }
  };

  useEffect(() => {
    let timeout: any;
    if (firstRender3.current) {
      firstRender3.current = false;
    } else {
      if (searchTextForContactDropdown.length > 2) {
        timeout = setTimeout(() => {
          fetchAllContacts();
        }, 500);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [searchTextForContactDropdown]);

  useEffect(() => {
    if (firstRender1.current) {
      firstRender1.current = false;
      setShowAddNew(true);
    } else if (accountState.updateOpportunityAccountSuccess) {
      setShowAddNew(false);
    }
  }, [accountState.updateOpportunityAccountSuccess]);

  // useEffect(() => {
  //   if (firstRender2.current) {
  //     firstRender2.current = false;
  //     setShowAddNew(true);
  //   } else if (accountState.createOpportunityAccountSuccess) {
  //     setShowAddNew(false);
  //   }
  // }, [accountState.createOpportunityAccountSuccess]);



  // Set account details when accountToBeEdited changes
  useEffect(() => {
    if (toBeEdited) {
      const { state } = toBeEdited;
      if (Object.keys(toBeEdited).length) {
        setName(toBeEdited.name);
        if (toBeEdited.customFields) {
          settingCustomFieldObjWithExistingCustomFieldsForAGivenContact(
            (toBeEdited.customFields as {
              [key: string]: string | string[];
            }),
          );
        }
        setIdBeingEdited(toBeEdited._id);
        if (toBeEdited.projectIds) {
          setListOfSelectedProjectId(toBeEdited.projectIds);
        }
        if (toBeEdited.source) {
          setSource(toBeEdited.source);
        }

        if (Array.isArray(state) && state.length > 0 && state[0].name !== "Draft") {
          setSelectedDynamicState(JSON.stringify(state[0]));
        } else {
          let defaultState = opportunityDynamicStates.find(
            (state) => state.isDefault == true,
          );

          if (defaultState && defaultState._id) {
            setSelectedDynamicState(JSON.stringify({ _id: defaultState._id, name: defaultState.name }));
          }
        }
      }
    }
  }, [toBeEdited, customFields]);

  useEffect(() => {
    const params = {
      page_no: pageNo,
      limit: limitForEventsList,
      searchText: searchTextForProjectDropdown,
    };
    getProjectList(params);
  }, [pageNo]);

  useEffect(() => {
    setPageNo(1);
    const params = {
      page_no: 1,
      limit: limitForEventsList,
      searchText: searchTextForProjectDropdown,
    };
    const timeout = setTimeout(() => {
      getProjectList(params);
    }, 400);
    return () => clearTimeout(timeout);
  }, [searchTextForProjectDropdown]);

  const fetchAllOpportunityCustomField = async () => {
    const opportunityAccountsCustomFieldsAPI =
      new OpportunityAccountsCustomFieldsAPI();
    let from = 'createAccount';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = "editAccount";
    }
    try {
      const data = {
        customFieldType: 'contacts',
        from: from
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setCustomFieldsFromContacts(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllContacts = async () => {
    let from = 'createAccount';
    if (toBeEdited && Object?.keys(toBeEdited).length) {
      from = "editAccount";
    }
    const data = {
      queryParams: {
        pageNo: 1,
        searchOnlyName: true,
        from: from
      },
      bodyParams: { filterArray: [], searchText: searchTextForContactDropdown },
    };
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchAllOpportunityContacts(data);
    setContactsSearchLists((prev) => ({
      ...prev,
      [activeCfIdForContactDropdown]: res.data.contacts,
    }));
  };

  const clearContactsSearchList = () => {
    setContactsSearchLists(null);
  };

  const settingCustomFieldObjWithExistingCustomFieldsForAGivenContact = (
    existingCFInContact: CustomFieldsObj,
  ) => {
    let commonKeysObj: CustomFieldsObj = {};
    customFields.forEach((cf) => {
      if (Object.keys(existingCFInContact).includes(cf._id as string)) {
        if (cf.fieldData.fieldType === 'contact') {
          fetchContactByIdToSetInSelectedContactAsCFValue2(
            existingCFInContact[cf._id as string] as unknown as string,
            cf._id as string,
          );
        }
        return (commonKeysObj[cf._id as string] =
          existingCFInContact[cf._id as string]);
      }

      else {
        if (cf.fieldData.fieldType === 'boolean') {
          commonKeysObj[cf._id as string] = '';
        } else {
          commonKeysObj[cf._id as string] = '';
        }
      }
    });
    setCustomFieldsObj(commonKeysObj);
  };

  const fetchContactByIdToSetInSelectedContactAsCFValue2 = async (
    contactId: any,
    cfId: string,
  ) => {
    if (contactId) {
      setSelectedContactAsCFValue((prev) => ({
        ...prev,
        [cfId as string]: contactId,
      }));
    }
  };

  const getProjectList = async (params: GetProjectsParams) => {
    try {
      const result: any = await EventsAllAPIWithPagination(params);
      const projects: ProjectsList = result.data.events;
      if (projects.length < result.data.total) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = () => {
    setPageNo((prev) => prev + 1);
  };

  const handleSearchListInSearchableDropdownForProjects = (text: any) => {
    setSearchTextForProjectDropdown(text);
  };
  // for CustomSearchableDropdownWithLoadMore ends

  const settingCustomFieldObjWithUndeletedCustomFields = () => {
    const obj: CustomFieldsObj = {};
    customFields.forEach((cf) => {
      if (cf.fieldData.fieldType === 'boolean') {
        return (obj[cf._id as string] = '');
      } else {
        return (obj[cf._id as string] = '');
      }
    });
    return obj;
  };

  const handleClose = () => {
    setShowAddNew(false);
    resetAddNew();
  };

  const handleCustomFieldsObj = (
    e: any,
    id: string,
    fieldType?: string,
    isMultiSelect?: boolean,
  ) => {
    const setingCFObj = (value: any,) => {
      let obj = {
        ...customFieldsObj,
      };
      if (fieldType === 'user') {
        let prevArr = (obj[id as string] as string[]);
        if (!prevArr) {
          prevArr = [];
        }
        if (prevArr.includes(value)) {
          prevArr = prevArr.filter(el => el !== value);
          obj[id as string] = [...prevArr];
        } else {
          obj[id as string] = [...prevArr, value];
        }
      } else {
        obj[id as string] = value;
      }
      setCustomFieldsObj(obj);
    };
    if (fieldType === 'number') {
      const regex = /^[0-9-]*$/;
      if (regex.test(e.target.value)) {
        setingCFObj(e.target.value);
      }
    } else if (fieldType === 'list' && !isMultiSelect) {
      setingCFObj([e.target.value]);
    } else if (fieldType === 'phone') {
      setingCFObj(e);
    } else if (fieldType === 'user') {
      setingCFObj(e);
    } else {
      setingCFObj(e.target.value);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const convertCustomFieldsObjToTheFormatAccordingToModel = (customFieldsObj: CustomFields) => {
    let copiedCustomFieldsObj: CustomFields = { ...customFieldsObj };
    for (const key in copiedCustomFieldsObj) {
      if (Object.prototype.hasOwnProperty.call(copiedCustomFieldsObj, key)) {
        const element = copiedCustomFieldsObj[key];

        if (element !== null && !Array.isArray(element) && typeof element === "object" && Object.keys(element).length) {
          copiedCustomFieldsObj[key] = element._id as string;
        }
      }
    }
    return copiedCustomFieldsObj;
  };

  const handleAddOrUpdate = () => {
    // checking if any create or edit contact box is open
    for (const [key, element] of Object.entries(dynamicContactOpenState)) {
      if (element === true) {
        haveToCallCreateOrUpdateContactFunctionInChild.current = true;
        break; // Break out of the loop
      }
    }
    // if any create or edit contact box is open then first contact will be saved
    // haveToCallCreateOrUpdateContactFunctionInChild.current ? saveContactInTheChildComp() : saveNewAccountOrUpdate()
    haveToCallCreateOrUpdateContactFunctionInChild.current
      ? checkValidationInChildContactComp()
      : saveNewAccountOrUpdate();
  };

  const checkValidationInChildContactComp = () => {
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handlevalidation) {
        if (!childRef.handlevalidation()) {
          setValidationRequired((prev) => {
            let arr = [...prev];
            arr.push(true);
            return arr;
          });
        } else {
          setValidationRequired((prev) => {
            let arr = [...prev];
            arr.push(false);
            return arr;
          });
        }
      }
    });
  };

  useEffect(() => {
    if (
      validationRequired.length &&
      validationRequired.length === Object.keys(dynamicContactOpenState).length
    ) {
      const isAccountFormValid = isValid();
      const isValidationRequiredInContactForms = validationRequired.includes(true);
      if (!isValidationRequiredInContactForms && isAccountFormValid) {
        saveContactInTheChildComp();
        setValidationRequired([]);
      } else {
        setValidationRequired([]);
        opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
      }
    }
  }, [validationRequired]);

  const saveContactInTheChildComp = () => {
    setValidationRequired([]);
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.handleAddOrUpdate) {
        childRef.handleAddOrUpdate();
      }
    });
  };

  const saveContactBeforeUnlinking = () => {
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfIdForAddNewContact) {
        if (childRef.saveContactBeforeUnlinking) {
          childRef.saveContactBeforeUnlinking(true, true);
          addNewContact(cfIdForAddNewContact, true);
        }
      }
    });
  };

  const handleOpenChangeSelectedContactConfPopup = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
  ) => {

    if (selectedContactAsCFValue?.[cfId as string]) {
      if (selectedContactAsCFValue?.[cfId as string]._id !== option._id) {
        setOpenChangeSelectedContactConfPopup(true);
        setOptionForHandleContactCFChangeForSearchableDropdown(option);
        setCfidForHandleContactCFChangeForSearchableDropdown(cfId as string);
      }
    } else {
      handleContactCFChangeForSearchableDropdown(option, cfId, isSaving);
    }
  };

  const handleContactCFChangeForSearchableDropdown = (
    option: any,
    cfId: string | undefined,
    isSaving?: boolean,
    contactInfoChanged?: boolean,
  ) => {
    if (option?._id) {
      setSelectedContactAsCFValue({
        ...selectedContactAsCFValue,
        [cfId as string]: option,
      });
    }
    if (cfId) {
      let contactDetailsToAppend: any = "";
      if (option) {
        if (!contactInfoChanged) {
          //contactDetailsToAppend = option._id;
          contactDetailsToAppend = option;
        } else {
          contactDetailsToAppend = { data: option, type: "contact" };
        }
      }
      let cfObj = { [cfId]: contactDetailsToAppend };
      setCustomFieldsObj((prev) => ({ ...prev, ...cfObj }));
    }

    if (isSaving) {
      savingContactAlso.current = true;
      setdynamicContactOpenState((prev) => {
        delete prev[cfId as string];
        return { ...prev };
      });
    }

    openChangeSelectedContactConfPopup &&
      setOpenChangeSelectedContactConfPopup(false);
  };

  useEffect(() => {
    if (
      savingContactAlso.current &&
      Object.keys(dynamicContactOpenState).length === 0
    ) {
      saveNewAccountOrUpdate();
      savingContactAlso.current = false;
    }
  }, [dynamicContactOpenState]);

  useEffect(() => {
    if (opportunityState.isSavingAsDraft) {
      handleAddOrUpdate();
    }
  }, [opportunityState.isSavingAsDraft]);

  const fetchAllTheActiveUsers = async () => {
    try {
      const data = {
        searchText: searchTextForUsers
      };
      const response: any = await getActiveUsersForCompanyAPI(data);
      if (response.data.success === true) {
        setUsersForUserCfDataType(response.data.users);
      } else {
        setUsersForUserCfDataType([]);
        createNotification('error', 'Something went wrong');
      }
    } catch (error) {
      setUsersForUserCfDataType([]);
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchAllTheActiveUsers();
  }, [searchTextForUsersDebouncedValue]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchTextForUsersDebouncedValue(searchTextForUsers);
    }, 500);
    return () => { clearTimeout(timerId); };
  }, [searchTextForUsers]);
  const saveNewAccountOrUpdate = () => {
    let areAccountsFieldsValid = true;
    if (opportunityState.isSavingAsDraft) {
      if (childRefs.current.length) {
        childRefs.current.forEach((childRef: any) => {
          if (childRef && !childRef.isValidForDraft) {
            opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
          }
        });
      } else if (
        !childRefs.current.length
      ) {
        areAccountsFieldsValid = isValid();
        opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
      }
    } else {
      areAccountsFieldsValid = isValid();
    }

    if (areAccountsFieldsValid) {
      type BodyParams = {
        name: string;
        customFields: CustomFields;
        _id?: string;
        projectIds: string[];
        source: Source;
        status: string;
        state?: string;
      };
      const bodyParams: BodyParams = {
        name: name.trim(),
        customFields: customFieldsObj,
        projectIds: listOfSelectedProjectId,
        source: source,
        status: "new"
      };
      if (opportunityState.isSavingAsDraft) {
        bodyParams['status'] = 'draft';
      }
      if (selectedDynamicState || opportunityState.isSavingAsDraft) {
        let draftState = dynamicStates.find(
          (state) => state.stateType === 'Draft',
        );
        bodyParams['state'] = opportunityState.isSavingAsDraft
          ? { _id: draftState?._id, name: draftState?.name }
          : JSON.parse(selectedDynamicState);
      }
      if (idBeingEdited) {
        bodyParams._id = idBeingEdited;
      }

      if (opportunityState.isSavingAsDraft) {
        setIsSavingAsDraftAsFalse();
        bodyParams.status = "draft";
      }
      let data = {
        bodyParams: {
          ...bodyParams, // Spread the existing bodyParams
          ...bodyParamsForfiltering, // Spread bodyParamsForfiltering to merge into bodyParams
        },
        queryParams: queryParams,
      };
      idBeingEdited
        ? dispatch(Actions.updateOpportunityAccountRequest(data))
        : dispatch(Actions.createOpportunityAccountRequest(data));
      resetAddNew();
    }
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: CustomFieldsValidationError = {};
    try {
      if (!name || name.trim() === '') {
        errorsObj.name = 'Please enter the account name';
      }

      if (!opportunityState.isSavingAsDraft) {
        const nonDraftStates = dynamicStates.filter(state => state.stateType !== 'Draft');
        if (nonDraftStates.length === 0) {
          errorsObj.dynamicState = 'Please add states under Settings before proceeding';
        } else if (!selectedDynamicState || selectedDynamicState.trim() === '') {
          errorsObj.dynamicState = 'Please select the state';
        } else {
          delete errorsObj.dynamicState;
        }
        customFields.forEach((cf: CustomField) => {
          for (const key in customFieldsObj) {
            if (key === cf._id) {
              if (cf.isMandatory) {
                if (cf.fieldData.fieldType === 'contact') {
                  if (!customFieldsObj[key]) {
                    errorsObj[key] = true;
                    childRefs.current.forEach((childRef: any) => {
                      if (childRef && childRef.cfId === key) {
                        if (
                          childRef &&
                          childRef.handleValidationCfIsMandatory
                        ) {
                          if (childRef.handleValidationCfIsMandatory()) {
                            delete errorsObj[key];
                          }
                        }
                      }
                    });
                  } else {
                    childRefs.current.forEach((childRef: any) => {
                      if (childRef && childRef.cfId === key) {
                        if (
                          childRef &&
                          childRef.handleValidationCfIsMandatory
                        ) {
                          if (!childRef.handleValidationCfIsMandatory()) {
                            errorsObj[key] = true;
                          }
                        }
                      }
                    });
                  }
                } else if (cf.fieldData.fieldType === 'date') {
                  const dateValue = customFieldsObj?.[cf._id as string];
                  if (!dateValue || !moment(dateValue as string).isValid()) {
                    // If dateValue is missing or it's an invalid date, mark the error
                    errorsObj[key] = true;
                  }
                } else if (
                  Array.isArray(customFieldsObj[key]) &&
                  !(customFieldsObj[key] as string[]).length
                ) {
                  errorsObj[key] = true;
                } else if (!customFieldsObj[key]) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'email') {
                if (
                  customFieldsObj[key] &&
                  !isValidEmail(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'url') {
                if (
                  customFieldsObj[key] &&
                  !isValidURL(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'facebook' || cf.fieldData.fieldType === 'linkedin' ||
                cf.fieldData.fieldType === 'x' || cf.fieldData.fieldType === 'pinterest' ||
                cf.fieldData.fieldType === 'instagram'
              ) {
                if (
                  customFieldsObj[key] &&
                  !validateSocialMediaUrl(customFieldsObj[key] as string, cf.fieldData.fieldType)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'number') {
                const numbersAndHyphensRegex = /^[0-9-]*$/;
                if (
                  !numbersAndHyphensRegex.test(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'date') {
                if (customFieldsObj?.[cf._id as string] && 
                  !moment(customFieldsObj?.[cf._id as string] as string).isValid()) {
                errorsObj[key] = true;
              }
              }
            }
          }
        });
      }
      setErrors((prev) => ({ ...prev, ...errorsObj }));
    } catch (error) {
      console.log(error);
    }

    if (Object.keys(errorsObj).length) {
      setCustomFieldsValidationError(errorsObj);
      isValid = false;
    } else if (errors.name) {
      isValid = false;
    } else {
      setCustomFieldsValidationError({});
    }
    !isValid && opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();

    return isValid;
  };

  const setIsSavingAsDraftAsFalse = () => {
    dispatch(Actions.setIsSavingAsDraft(false));
  };

  const errorStatement = (cf: CustomField) => {
    if (customFieldsValidationError?.[cf._id as string]) {
      if (cf.fieldData.fieldType === 'email') {
        return 'Please enter a valid email';
      }
      if (cf.fieldData.fieldType === 'url') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'x') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'instagram') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'facebook') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'pinterest') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'linkedin') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'number') {
        return 'Please enter a valid number';
      }
      if (cf.fieldData.fieldType === 'date') {
        return 'Please enter a valid date';
      }
      if (cf.fieldData.fieldType === 'boolean') {
        return 'Please choose the value';
      }
      if (cf.fieldData.fieldType === 'list') {
        return 'Please select a value';
      }
      if (cf.fieldData.fieldType === 'contact') {
        return 'Please fill the mandatory fields';
      } else {
        return 'Please enter a value';
      }
    } else return '';
  };

  const handleSearchListInSearchableDropdownForContactTypeCF = (
    cfId: string,
    text: any,
  ) => {
    if (text) {
      setActiveCfIdForContactDropdown(cfId);
      setSearchTextForContactDropdown(text);
    } else {
    }
  };

  const clearSelectedContactAndCloseViewContactDetailBox = (cfId: string) => {
    const obj = { ...selectedContactAsCFValue };
    delete obj[cfId];
    setSelectedContactAsCFValue(obj);

    const openState = { ...dynamicContactOpenState };
    openState[cfId] = false;
    setdynamicContactOpenState({
      ...openState,
    });

    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...customFieldsObj, ...cfObj });
  };

  const handleOpenAddNewContactConfirmationPopup = (cfId: string) => {
    setCfIdForAddNewContact(cfId);
    let isUnlinkingExistingContact = false;
    let isContactFormEmpty = false;
    let isContactSelected = selectedContactAsCFValue?.[cfId]?._id;
    childRefs.current.forEach((childRef: any) => {
      if (childRef && childRef.cfId === cfId) {
        changesMadeInCreateOrEditContact = childRef.checkIfFormHasChanged();
        isUnlinkingExistingContact = childRef.idBeingEdited;
        isContactFormEmpty = childRef.isFormEmpty;
      }
    });
    if (isContactFormEmpty) {
      return;
    }
    if (changesMadeInCreateOrEditContact) {
      setContactConfirmationText(
        'You have made some changes, do you want to save the contact before unlinking?',
      );
    } else if (
      !changesMadeInCreateOrEditContact &&
      isUnlinkingExistingContact
    ) {
      setContactConfirmationText(
        'Are you sure you want to unlink this contact?',
      );
    } else if (
      !changesMadeInCreateOrEditContact &&
      !isUnlinkingExistingContact &&
      !isContactSelected
    ) {
      setContactConfirmationText(
        'Are you sure you want to unlink this contact?',
      );
      addNewContact(cfId, true);
      return;
    } else {
      setContactConfirmationText(
        'Are you sure you want to unlink this contact?',
      );
    }
    setOpenAddNewContactConfirmationPopup(true);
  };

  const closeAddNewContactConfirmationPopup = () => {
    setOpenAddNewContactConfirmationPopup(false);
    changesMadeInCreateOrEditContact = false;
  };
  const closeChangeSelectedContactConfPopup = () => {
    setOpenChangeSelectedContactConfPopup(false);
    changesMadeInCreateOrEditContact = false;
  };

  const handleDynamicStateChange = (e: SelectChangeEvent<String>) => {
    setSelectedDynamicState(e.target.value);
  };

  const addNewContact = (cfId: string, addingNew?: boolean) => {
    openAddNewContactConfirmationPopup && closeAddNewContactConfirmationPopup();
    if (addingNew) {
      childRefs.current.forEach((childRef: any) => {
        if (childRef && childRef.cfId === cfId) {
          childRef.resetForm();
        }
      });
      if (selectedContactAsCFValue[cfId]) {
        setPreviouslySelectedContactAsCFValue((prev) => ({
          ...prev,
          [cfId]: selectedContactAsCFValue[cfId],
        }));
        const obj = { ...selectedContactAsCFValue };
        delete obj[cfId];
        setSelectedContactAsCFValue(obj);
      }
      setCustomFieldsObj(prev => {
        const prevCopy = { ...prev };
        prevCopy[cfId] = "";
        return { ...prevCopy };
      });
    }
    const openState = { ...dynamicContactOpenState };
    openState[cfId] = true;
    for (let key in openState) {
      if (key === cfId) {
        openState[key] = true;
      } else {
        openState[key] = true;
      }
    }
    setdynamicContactOpenState({
      ...openState,
    });
  };

  const closeAddNewPopup = (cfId: string) => {
    const openState = { ...dynamicContactOpenState };
    delete openState[cfId];
    setdynamicContactOpenState(openState);
  };

  const setPreviousContactAsCFValueOnClosingEditBox = (cfId: string) => {
    setSelectedContactAsCFValue({
      ...selectedContactAsCFValue,
      [cfId as string]: previouslySelectedContactAsCFValue[cfId],
    });
    let cfObj = {
      ...customFieldsObj,
      [cfId]: '',
    };
    setCustomFieldsObj({ ...cfObj });
    closeAddNewPopup(cfId);
  };

  const handleProjectCheckbox = (option: any, checked: boolean) => {
    let arr: string[] = [...listOfSelectedProjectId];
    if (checked) {
      arr.push(option);
    } else {
      arr = arr.filter((el) => el._id !== option._id);
    }
    setListOfSelectedProjectId(arr);
  };

  const handleClearAll = (checked: boolean) => {
    let arr: string[] = [...listOfSelectedProjectId];
    if (checked) {
      setListOfSelectedProjectId([]);
    }
  };

  const handleClearAllUsers = (checked: boolean, cfId: string) => {
    let obj = { ...customFieldsObj };
    if (checked) {
      obj[cfId] = [];
      setCustomFieldsObj({ ...obj });
    }
  };

  return (

    <Box className="opportunity-account-tab">
      <Grid container spacing={2} pb={3} className="top-filter-area" sx={{ position: 'sticky', top: 0, zIndex: 1, bgcolor: '#f6f6fc' }}>
        <Grid >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton size="small" onClick={handleClose}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <Box>Back</Box>
          </Stack>
        </Grid>
        <Grid >
          <Grid container spacing={1.25} className="team-dash-right">
            <Grid >
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid >
              <Button
                onClick={() => dispatch(Actions.setIsSavingAsDraft(true))}
                variant="outlined"
                color="primary"
                disableElevation
              >
                Save As Draft
              </Button>
            </Grid>
            <Grid >
              <Button
                onClick={() => {
                  opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
                  handleAddOrUpdate();
                }}
                variant="contained"
                color="primary"
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid size={{ xs: 12, md: 10, xl: 8 }} >
          <Box px={3} pt={2} pb={4}>
            <Box
              component={Grid}
              //   @ts-ignore
              rowSpacing={2}
              columnSpacing={2}
              container
              className="inputField"
            >
              <Grid size={{ xs: 12, sm: 6 }} >
                <label>
                  {usedFor} Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder="Please enter the account name"
                  onChange={handleNameChange}
                  value={name}
                />
                <span className="error_mesage required">{errors?.name}</span>
              </Grid>
              <StateDropdown
                label="State"
                name="dynamicState"
                dynamicStates={dynamicStates}
                selectedState={selectedDynamicState}
                handleStateChange={handleDynamicStateChange}
                errors={errors}
              />
              <Grid size={{ xs: 12, sm: 6 }} >
                <label>Projects</label>
                <CustomSearchableMultiSelectedDropdownWithLoadMoreOPM
                  options={projectsList}
                  handleChange={handleProjectCheckbox}
                  handleClearAll={handleClearAll}
                  handleAllCheckbox={() => { }}
                  handleSearchListInSearchableDropdown={
                    handleSearchListInSearchableDropdownForProjects
                  }
                  listOfSelectedIds={listOfSelectedProjectId}
                  dropdownFor="projectsChatWidgetList"
                  handlePageChange={handlePageChange}
                  disabled={disabledButton}
                />
              </Grid>

              {usedFor === 'Opportunity' && (
                <Grid size={{ xs: 12, sm: 6 }} >
                  <label>Assign to</label>
                  <FormControl size="small" fullWidth>
                    <Select
                      type="text"
                      className="form-control select small"
                      placeholder="select"
                      // value={fieldType}
                      name="fieldType"
                      // onChange={this.handleChange}
                      style={{ padding: '4px 0px' }}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem value="" disabled hidden>
                        Select
                      </MenuItem>
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="date">Date</MenuItem>
                      <MenuItem value="url">URL</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="dropdown">List</MenuItem>
                      <MenuItem value="checkbox">Boolean</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {usedFor === 'Opportunity' && (
                <Grid size={{ xs: 12, sm: 6 }}>
                  <label>Status</label>
                  <FormControl size="small" fullWidth>
                    <Select
                      type="text"
                      className="form-control select small"
                      placeholder="select"
                      // value={fieldType}
                      name="fieldType"
                      // onChange={this.handleChange}
                      style={{ padding: '4px 0px' }}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      <MenuItem value="" disabled hidden>
                        Select
                      </MenuItem>
                      <MenuItem value="text">Text</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="date">Date</MenuItem>
                      <MenuItem value="url">URL</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="dropdown">List</MenuItem>
                      <MenuItem value="checkbox">Boolean</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {Array.isArray(customFields) &&
                customFields.map((cf) => {
                  return (
                    <Grid
                      size={{
                        xs: 12, sm:
                          cf.fieldData.fieldType === 'contact' ||
                            cf.fieldData.fieldType === 'account' ||
                            cf.fieldData.fieldType === 'longText'
                            ? 12
                            : 6
                      }}
                      
                      key={cf._id}
                    >
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Box>
                          <label>
                            {cf.fieldData.fieldName}
                            {cf.isMandatory && (
                              <span className="required">*</span>
                            )}
                          </label>
                        </Box>
                        {(cf.fieldData.fieldType === 'contact' ||
                          cf.fieldData.fieldType === 'account') && (
                            <Button
                              variant="text"
                              color="primary"
                              onClick={() =>
                                handleOpenAddNewContactConfirmationPopup(
                                  cf._id as string,
                                )
                              }
                            >
                              Add New
                            </Button>
                          )}
                      </Stack>

                      {cf.fieldData.fieldType === 'list' ? (
                        <DropdownForListDataTypeCF
                          cf={cf}
                          customFieldsObj={customFieldsObj}
                          handleCustomFieldsObj={handleCustomFieldsObj}
                        />
                      ) : cf.fieldData.fieldType === 'boolean' ? (
                        <FormControl fullWidth component="fieldset">
                          <RadioGroup
                            // @ts-ignore
                            aria-label={cf.fieldData.fieldName}
                            name={cf.fieldData.fieldName}
                            row
                            onChange={(e) =>
                              handleCustomFieldsObj(e, cf._id as string)
                            }
                            value={
                              customFieldsObj?.[cf._id as string]?.toString() ||
                              ''
                            }
                          >
                            <FormControlLabel
                              value={'true'}
                              control={<Radio color="primary" />}
                              label="True"
                            />
                            <FormControlLabel
                              value={'false'}
                              control={<Radio color="primary" />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : cf.fieldData.fieldType === 'date' ? (
                        <>
                          <div className="date-picker-with-icon form-control">
                            <ReactDatePicker
                              selected={
                                customFieldsObj?.[cf._id] && !isNaN(new Date(customFieldsObj[cf._id]).getTime())
                                  ? new Date(customFieldsObj[cf._id])
                                  : null
                              }
                              onChange={(date) =>
                                handleCustomFieldsObj(
                                  { target: { value: date ? moment(date).format('YYYY-MM-DD') : null } },
                                  cf._id
                                )
                              }
                              id={`date-picker-${[cf._id]}`}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              className="form-control small"
                            />
                            <CalendarTodayIcon fontSize='small' style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => document.querySelector(`#date-picker-${[cf._id]}`).focus()} />
                          </div>
                          {customFieldsObj?.[cf._id as string] &&
                            moment(
                              customFieldsObj?.[cf._id as string] as string,
                            ).format('YYYY-MM-DD') === 'Invalid date' && (
                              <>{customFieldsObj?.[cf._id as string]}</>
                            )}
                        </>
                      ) : cf.fieldData.fieldType === 'contact' ? (
                        <>
                          <CustomSearchableDropdownForContactAndAccountDataTypeCF
                            options={
                              contactsSearchLists &&
                              contactsSearchLists[cf._id as string]
                            }
                            value={selectedContactAsCFValue}
                            handleChange={(option: any) =>
                              handleOpenChangeSelectedContactConfPopup(
                                option,
                                cf._id,
                              )
                            }
                            cfId={cf._id}
                            handleSearchListInSearchableDropdown={(
                              text: string,
                            ) =>
                              handleSearchListInSearchableDropdownForContactTypeCF(
                                cf._id as string,
                                text,
                              )
                            }
                            clearSearchList={clearContactsSearchList}
                          />
                          {dynamicContactOpenState[cf._id as string] && (
                            <CreateOrEditContact
                              ref={(ref) => {
                                let arr = [];
                                for (let key in dynamicContactOpenState) {
                                  if (dynamicContactOpenState[key] === true) {
                                    arr.push(key);
                                  }
                                }
                                arr.map((el, i) => {
                                  if (el === cf._id) {
                                    return (childRefs.current[i] = ref);
                                  }
                                });
                              }}
                              // @ts-ignore
                              closeAddNewPopup={() =>
                                closeAddNewPopup(cf?._id as string)
                              }
                              cfId={cf._id as string}
                              handleContactCFChangeForSearchableDropdown={
                                handleContactCFChangeForSearchableDropdown
                              }
                              contactToBeEdited={
                                (selectedContactAsCFValue !== null &&
                                  Object.keys(selectedContactAsCFValue)
                                    .length &&
                                  selectedContactAsCFValue[cf._id as string]) ||
                                undefined
                              }
                              onClose={() =>
                                setPreviousContactAsCFValueOnClosingEditBox(
                                  cf._id as string,
                                )
                              }
                              addNewContact={addNewContact}
                              toBeEdited={toBeEdited}
                              callFrom={'account'}
                            />
                          )}
                          {selectedContactAsCFValue?.[cf._id as string] &&
                            !dynamicContactOpenState[cf._id as string] && (
                              <ViewContactOrAccountDetails
                                selected={
                                  selectedContactAsCFValue[cf?._id as string]
                                }
                                addNew={() => addNewContact(cf._id as string)}
                                clearSelectedContactAndCloseViewContactDetailBox={() =>
                                  clearSelectedContactAndCloseViewContactDetailBox(
                                    cf._id as string,
                                  )
                                }
                                usedFor="contact"
                                customFields={customFieldsFromContacts}
                                parentItem={'account'}
                              />
                            )}
                        </>
                      ) : cf.fieldData.fieldType === 'number' ? (
                        <input
                          type="text"
                          className="form-control small"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(
                              e,
                              cf._id as string,
                              cf.fieldData.fieldType,
                            )
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                        />
                      ) : cf.fieldData.fieldType === 'phone' ? (
                        <InputFieldForPhoneDataTypeCF
                          handleCustomFieldsObj={handleCustomFieldsObj}
                          cf={cf}
                          customFieldsObj={customFieldsObj}
                        />
                      ) : cf.fieldData.fieldType === 'user' ? (
                        <CustomSearchableMultiSelectedDropdownWithLoadMoreOPM
                          options={usersForUserCfDataType}
                          handleChange={(option: {
                            _id: string;
                            email: string;
                          }) =>
                            handleCustomFieldsObj(
                              option,
                              cf._id as string,
                              cf.fieldData.fieldType,
                            )
                          }
                          handleClearAll={(checked: boolean) =>
                            handleClearAllUsers(checked, cf._id as string)
                          }
                          handleAllCheckbox={() => { }}
                          handleSearchListInSearchableDropdown={(text: string) => {
                            setSearchTextForUsers(text);
                          }}
                          listOfSelectedIds={
                            customFieldsObj?.[cf._id as string] || []
                          }
                          dropdownFor="users"
                          handlePageChange={() => { }}
                          disabled={false}
                        />
                      ) : cf.fieldData.fieldType === 'longText' ? (
                        <textarea
                          className="form-control"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                          rows={4}
                        ></textarea>
                      ) : (
                        <input
                          type={
                            cf.fieldData.fieldType === 'email'
                              ? 'email'
                              : 'text'
                          }
                          className="form-control small"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                        />
                      )}
                      <span className="error_mesage required">
                        {errorStatement(cf as CustomField)}
                      </span>
                    </Grid>
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>;
      {/* {(accountState.isLoading ||
        contactState.isLoading ||
        isCustomFieldsLoading) && <Loader />} */}
      <ConfirmationAlertPopUp
        openModal={openAddNewContactConfirmationPopup}
        closeModalFunc={() => {
          if (changesMadeInCreateOrEditContact) {
            addNewContact(cfIdForAddNewContact, true);
          } else {
            closeAddNewContactConfirmationPopup();
          }
        }}
        title={'Unlink!'}
        text={contactConfirmationText}
        confirmationButtonText={
          changesMadeInCreateOrEditContact ? 'Save Contact And Unlink' : 'Yes'
        }
        confirmationButtonColor="secondary"
        closeButtonText={
          changesMadeInCreateOrEditContact ? 'Unlink Without Saving' : 'Cancel'
        }
        functionality={() => {
          if (changesMadeInCreateOrEditContact) {
            saveContactBeforeUnlinking();
          } else {
            addNewContact(cfIdForAddNewContact, true);
          }
        }}
      />
      <ConfirmationAlertPopUp
        openModal={openChangeSelectedContactConfPopup}
        closeModalFunc={closeChangeSelectedContactConfPopup}
        title={'Change Selected Contact'}
        text={'Are you sure you want to change the linked contact?'}
        confirmationButtonText={'Yes'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => {
          handleContactCFChangeForSearchableDropdown(
            optionForHandleContactCFChangeForSearchableDropdown,
            cfidForHandleContactCFChangeForSearchableDropdown,
          );
        }}
      />
    </Box >
  );
};

export default AddOrEditAccount;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import '../../sass/main.scss'
import illumeetLogo from '../../assets/images/loginLogo.png'
import { Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Shape1 from '../../assets/images/Path_421.png'
import Shape2 from '../../assets/images/Path_422.png'
import Shape3 from '../../assets/images/Path_423.png'
import Shape4 from '../../assets/images/Path_424.png'
import Shape5 from '../../assets/images/Path_425.png'
import { Helmet } from 'react-helmet'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      emailToken: '',
      errors: {},
      loading: false,
    }
  }

  componentDidMount() {
    if ( this.props.params.token !== '') { 
      this.setState({ emailToken:  this.props.params.token })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const login = this.props.login
    
    if (
      login &&
      login.resetSuccess &&
      this.props.login.resetMsg !== prevProps.login.resetMsg
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      createNotification('success', login.resetMsg, '')
      this.props.navigate('/')
    } else if (login && login.resetError && this.state.loading) {
      createNotification('error', login.resetMsg, '')
      this.handleLoading()
    }
  }

  handleLoading = () => {
  
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isLoginValid = () => { 
    const { confirmPassword, password } = this.state
    let error = {}
    let formIsValid = true
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

    if (password === '' || password.trim().length === 0) {
      formIsValid = false
      error['password'] = '*Please enter password.'
    }
    /* if (password && passwordPattern.test(password) === false) {
            formIsValid = false;
            error['password'] = "*Minimum eight characters, at least one uppercase letter, one lowercase letter and one number.";
        }*/
    if (confirmPassword === '' || confirmPassword.trim().length === 0) {
      formIsValid = false
      error['confirmPassword'] = '*Please enter confirm password.'
    }

    if (password !== confirmPassword) {
      formIsValid = false
      error['confirmPassword'] =
        '*password and confirm password is not matched.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  handleSubmit = (e) => {
    e.preventDefault() 
    if (this.isLoginValid()) {
      const { emailToken, password } = this.state

      const requestbody = { emailToken, password }
      this.props.resetPassword(requestbody)
      this.handleLoading()
      // this.props.startLoading();
    }
  }

  render() {
    const { password, errors, loading, confirmPassword } = this.state
    return (
      <div className="main_page">
        <Helmet>
          <title>IllumeetXP | Reset Password</title>
        </Helmet>
        <div className="registerPage">
          <div className="leftSidebar">
            <div className="registerSidebarBrand">
              <img src={illumeetLogo} alt="Illumeet Logo" />
              <div className="brandingText">
                <h1>
                  One platform. <br />
                  One process. <br />
                  All your events.
                </h1>
              </div>
            </div>
            <div className="signupShapes">
              <span className="shape-1">
                <img src={Shape1} alt="" />
              </span>
              <span className="shape-2">
                <img src={Shape2} alt="" />
              </span>
              <span className="shape-3">
                <img src={Shape3} alt="" />
              </span>
              <span className="shape-4">
                <img src={Shape4} alt="" />
              </span>
              <span className="shape-5">
                <img src={Shape5} alt="" />
              </span>
            </div>
          </div>
          <div className="registerFormMain">
            <div className="registerFormInner">
              <div className="pageHeading text-center">
                <h1>Reset Password</h1>
                {loading ? <Loader /> : null}
              </div>
              <div className="formSection LoginForm">
                <form onSubmit={this.handleSubmit}>
                  <div className="field-group fullWidthInput">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    <p className="error_mesage"> {errors.password} </p>
                  </div>
                  <div className="field-group fullWidthInput">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    <p className="error_mesage"> {errors.confirmPassword} </p>
                  </div>

                  <div className="field-group fullWidthInput">
                    <div className="submitForm">
                      <Button type="submit" className="themeBlueButton">
                        Reset Password
                      </Button>
                    </div>
                  </div>

                  <div className="field-group fullWidthInput loginHere">
                    <p>
                      Have an account ? &nbsp;
                      <a onClick={() => this.props.navigate('/')}>Login</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <NotificationContainer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,
  // loading: state.Loader.loaderOne,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,
      resetPassword: Actions.resetPasswordRequest,
      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ResetPassword)

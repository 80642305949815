import React, { Component } from 'react'; 
// import '../../sass/main.scss';
import Button from '@mui/material/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import moment1 from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification,redirectTo } from '../../helpers';
import { NotificationContainer } from "react-notifications"; 
import TimePicker from 'react-time-picker';
import {sampleEvent,emailTemplateJson} from "./sampleEvent"
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import gapiClient from 'gapi-client';
import { GetTimezoneAPI } from 'ReduxStore/API'

class XpEventPlatform extends Component {
    constructor(props){
        super(props);
        this.state= {
            activeStep: 0,
            Questionvalue:0,
            Tabvalue:0,
            theme:'',
            title: 'Create Event',
            pageTitle:"Create Event",
            initialDate:new Date(),
            startDate:new Date(),
            openDate:new Date(),
            time:moment().format('HH:mm'),
            endTime:moment().format('HH:mm'),
            currentTime:moment().format('HH:mm'),
            openTime:moment().format('HH:mm'),
            eventName:'',
            eventDomain:'',
            location:'',
            errors: {},
            loading: false,
            lat:0,
			      lng:0,
            domainSuccess:'',
            Regionlat:0,
            Regionlng:0,
            messageBwOpenTime:'',
            timerClock:false,
            eventType:'event',
            replyToEmail:'',
            timezone:'',
            emailSenderName:''
        }
    }
    
    
     onChangeDate = (date) => {
       this.setState({startDate: date});
     };
     onChangeEndDate = (date) => {
        this.setState({endDate: date});
       };

     onChangeOpenDate = (date) => {
      this.setState({openDate: date});
     };


    onTimeChange = (time) => {    
      this.setState({time:time})
    };
    
    onEndTimeChange = (time) => {    
        this.setState({endTime:time})
    };  
    onOpenTimeChange = (time) => {    
          this.setState({openTime:time})
    };     


    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleLoading = () => { 
        let loading = this.state.loading;
        this.setState({
            loading: !loading
        })
    }

   
    isValid = () => { 
        const { eventName, eventDomain,location, startDate, endDate, time,currentTime ,endTime,openDate,openTime,messageBwOpenTime,replyToEmail,timezone,emailSenderName} = this.state;
        let error = {};
        let formIsValid = true;
        let timeRegex = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
        let domain = /^[a-zA-Z0-9]+$/
        const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        
        const currentDa =  moment().format('MM/DD/YYYY')
        const cDate = currentDa +' '+'00:00:00'
        const currentDateOnly =  moment(cDate).format('X')
        const currentDate =  moment().format('X')
       
        const startDate1 =  moment(startDate).format('MM/DD/YYYY')
        const startD = startDate1 +' '+time
        const startDOnly = startDate1 +' '+'00:00:00'
        const startDateSeconds= moment(startD).format('X')
        const startDateOnly= moment(startDOnly).format('X')
        
        const endDate1 =  moment(endDate).format('MM/DD/YYYY')
        const endD = endDate1 +' '+endTime
        const endDOnly = endDate1 +' '+'00:00:00'
        const endDateSeconds= moment(endD).format('X')
        const endDateOnly= moment(endDOnly).format('X')

        const openDate1 =  moment(openDate).format('MM/DD/YYYY')
        const openD = openDate1 +' '+openTime
        const openDOnly = openDate1 +' '+'00:00:00'
        const openDateSeconds= moment(openD).format('X')
        const openDateOnly= moment(openDOnly).format('X')
        
        if(startDateOnly < currentDateOnly)
        {
          formIsValid = false;
          error['startDate'] = "*Please enter start date  greater than current date ";
        }else if(startDateSeconds < currentDate){
            formIsValid = false;
            error['time'] = "*Please enter start time greater than current time.";
        }

        if(startDateOnly > endDateOnly)
        {
          formIsValid = false;
          error['startDate'] = "*Please enter start date  less than end date ";
        }else if(startDateSeconds > endDateSeconds){
          formIsValid = false;
          error['endTime'] = "*Please enter end time less than start time.";
        }
        
        if(openDateOnly > startDateOnly || openDateOnly > endDateOnly)
        {
          formIsValid = false;
          error['openDate'] = "*Please enter opening date  less than start date and end date";
         }else if(openDateSeconds > startDateSeconds || openDateSeconds > endDateSeconds){
          formIsValid = false;
          error['opentime'] = "*Please enter opening time less than start time.";
        }

        if (replyToEmail === '' || replyToEmail.trim().length === 0) {
          formIsValid = false;
          error['replyToEmail'] = "*Please enter reply to email.";
        }
        if(replyToEmail && regexTest.test(replyToEmail) === false) {
          formIsValid = false;
          error['replyToEmail'] = "*Please enter a valid email address.";
        }
        if (eventName === '' || eventName.trim().length === 0) {
            formIsValid = false;
            error['eventName'] = "*Please enter event name.";
        }
        if (eventName &&  eventName.trim().length >25) {
          formIsValid = false;
          error['eventName'] = "*Only 30 Character is allowed.";
         }
        if (eventDomain === '' || eventDomain.trim().length === 0) {
            formIsValid = false;
            error['eventDomain'] = "*Please enter event domain.";
        }
        if (eventDomain && domain.test(eventDomain) === false) {
          formIsValid = false;
          error['eventDomain'] = "*Please enter valid domain";
        }
       
        if (location === '' || location.trim().length === 0) {
            formIsValid = false;
            error['location'] = "*Please enter location.";
        }
        if (startDate === '' || startDate === null) {
            formIsValid = false;
            error['startDate'] = "*Please select start date.";
        }
        if (endDate === '' || endDate === null) {
            formIsValid = false;
            error['endDate'] = "*Please select end date.";
        }
        if (openDate === '' || openDate === null) {
          formIsValid = false;
          error['openDate'] = "*Please select open date.";
        }
       
        if (time === '' || time.trim().length === 0) {
            formIsValid = false;
            error['time'] = "*Please select time.";
        }
        if (time && timeRegex.test(time) === false) {
          formIsValid = false;
          error['time'] = "*Please select valid time";
        }
        if (openTime === '' || openTime.trim().length === 0) {
          formIsValid = false;
          error['time'] = "*Please select opening.";
        }
        if (openTime && timeRegex.test(openTime) === false) {
          formIsValid = false;
          error['openTime'] = "*Please select valid opening time";
        }
         if (messageBwOpenTime &&  messageBwOpenTime.trim().length > 200) {
          formIsValid = false;
          error['messageBwOpenTime'] = "*Only 200 Character is allowed";
         }
         if (openDate === '') {
          formIsValid = false;
          error['openDate'] = "*Please select open date.";
        }
        
        if (endTime && timeRegex.test(endTime) === false) {
          formIsValid = false;
          error['endTime'] = "*Please select valid end time";
        }
        if (timezone === '' || timezone.trim().length === 0) {
          formIsValid = false;
          error['timezone'] = "*Please select timezone.";
        }
        if (emailSenderName === '' || emailSenderName.trim().length === 0) {
          formIsValid = false;
          error['emailSenderName'] = "*Please enter email sender name.";
        }
       if (emailSenderName &&  emailSenderName.trim().length >20) {
        formIsValid = false;
        error['emailSenderName'] = "*Only 20 Character is allowed.";
       }
      
        this.setState({ errors: error });
        return formIsValid;
    }

    handleSubmit = (e) => {
        e.preventDefault();
       
        if (this.isValid()) {
            const { eventName, eventDomain,location, startDate, endDate, time ,lat,lng,endTime,openDate,openTime,messageBwOpenTime,timerClock,eventType,replyToEmail,timezone,emailSenderName} = this.state;
            const emailTemplate = sampleEvent
            const requestbody = {
                eventName,
                eventDomain: eventDomain.toLowerCase(),
                location,
                startDate,
                endDate,
                time,
                endTime,
                //emailTemplate,
                //emailTemplateJson,
                lat,
                lng,
                openDate,
                openTime,
                messageBwOpenTime,
                timerClock,
                eventType,
                replyToEmail,
                timezone,
                emailSenderName
            };
            this.props.createEvent(requestbody);
            this.handleLoading();
            // this.props.startLoading();
        }
    }

    async componentDidMount() {
      navigator.geolocation.getCurrentPosition(function(position) {
       
        if(position.coords.latitude)
        {
         
        }
      });

      GetTimezoneAPI().then((result) => {
        if (result.data.status === 200) {
            this.setState({timezones:result.data.timezone})
        } else {
            createNotification('error', result.data.message);
        }
       }).catch(console.log);
    }

    componentDidUpdate(prevProps, prevState) {
    
       
        if(this.props.events)
        {
        if (this.props.events.success === true && this.props.events.message !== prevProps.events.message) {
            
            createNotification('success', this.props.events.message); 
            const startDate1 =  moment(this.props.events.event.startDate).format('MM/DD/YYYY')
            const startD = startDate1 + ' '+this.props.events.event.time

            const endDate1 =  moment(this.props.events.event.endDate).format('MM/DD/YYYY')
            const endDa = endDate1 + ' '+this.props.events.event.time 
            setTimeout(() => {
                 redirectTo(this.props.navigate,`project-type/${this.props.events.event._id}`,this.props.location.pathname)
              }, 3000); 
            
        }
      
        if (this.props.events.error && this.props.events.error !== prevProps.events.error) {
            createNotification('error', this.props.events.message);
            this.handleLoading();
        }

        if (this.props.events.domainSuccess === true && this.props.events.message !== prevProps.events.message) {
          this.setState({ domainSuccess: this.props.events.message,errors:{} });
        }
        if (this.props.events.domainError && this.props.events.domainError !== prevProps.events.domainError) {
          let error = {};
          error['eventDomain'] = this.props.events.message;
          this.setState({ errors: error,domainSuccess:'' });
       }
       }
    }

    handleChangeLocation =(location)=>{
     
        this.setState({location:location})
    }
    handleSelect = location => {
        this.setState({ location });
       
    geocodeByAddress(location)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
           
            this.setState({lat:latLng.lat,lng:latLng.lng})
            })
        .catch(error => console.error('Error', error));
    };

    addEventToCalendars = (eventData, date) =>{
      
        let startTime = moment(`${eventData.startDate}:00.000Z`)
          .add("8", "hours")
          .format();
        let endTime = moment(`${eventData.endDate}:00.000Z`)
          .add("8", "hours").add("30","minutes")
          .format(); 
    
        var gapi = window.gapi;  
        var CLIENT_ID = "711204863226-kg8eoih74guqc93bm4rpfchnpe37p3hh.apps.googleusercontent.com"
        var API_KEY = "yBmczulIMZE2ShABqGi5vPOt";
        var SCOPES = "https://www.googleapis.com/auth/calendar";
        var DISCOVERY_DOCS = [
          "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
        ];
    
        gapi.load("client:auth2", () => {
        
          gapiClient.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
          }); 
          gapi.auth2
            .getAuthInstance()
            .signIn()
            .then(() => {
              var event = {
                summary: `${eventData.title} - ${moment(`${eventData.startDate}`, [
                  "HH:mm",
                ]).format("hh:mm A")} PST`,
                // location: "illumeet square",
                description: `${eventData.google_meet}`,
                start: {
                  dateTime: startTime,
                  timeZone: moment1.tz.guess(),
                },
                end: {
                  dateTime: endTime,
                  timeZone: moment1.tz.guess(),
                },
                // recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
                reminders: {
                  useDefault: false,
                  overrides: [
                    { method: "email", minutes: 24 * 60 },
                    { method: "popup", minutes: 10 },
                  ],
                },
              };
              
              var request = gapiClient.calendar.events.insert({
                calendarId: "primary",
                resource: event,
              });
    
              request.execute((event) => {
               
                if (event.status == "confirmed") {
                    createNotification("success", "Event added to google calendar.");
                } else {
                    createNotification(
                    "danger",
                    "Unable to add event to calendar. Please try again later."
                  );
                }
                 window.open(event.htmlLink);
              });
            });
        });
      }

    checkDomain = (e)=>{
     
      if(e.target.value.length > 0)
      {
        this.props.checkEventDomain({eventDomain:e.target.value})
      }else{
        this.setState({errors:{},domainSuccess:''})
      }
    } 
    render(){ 
      const {startDate , endDate,time,eventDomain,eventName,location,errors,loading,initialDate,domainSuccess,endTime,messageBwOpenTime,openTime,openDate,timerClock,replyToEmail,timezones,emailSenderName} = this.state 
      const eventData = {start_time:'10:00',end_time:'03:00',google_meet:'test test',title:"event title"}
       const startDate1 =  moment(startDate).format('MM/DD/YYYY')
      const startD = startDate1 +' '+time 
        return(
             <div className="CPTabsDetails"> 
                    <div className="rsvpEventfieldBox">
                        <div className="formSection p-0">
                            <form onSubmit={this.handleSubmit}>
                                <div className="completeRegisterboxes">
                                    <div className="eventFullInput CRCircularBox">
                                        <div class="field-group inputField">
                                            <label>Event Name</label>
                                            <input type="text" class="form-control" name="eventName" value={eventName} maxLength="25" onChange={this.handleChange}/>
                                            <p className="error_mesage"> {errors.eventName} </p>
                                        </div>
                                        <div class="field-group inputField">
                                            <label>Event Domain Name</label>
                                            <div className="editableDomainName">
                                              <input type="text" class="form-control emailLoweCase" name="eventDomain" value={eventDomain} onChange={this.handleChange} onKeyUp={this.checkDomain}/> <span>.illumeetxp.com</span> 
                                             </div>
                                            <p className="error_mesage"> {errors.eventDomain} </p>
                                          {domainSuccess !==''? <p className="success_message"> {domainSuccess} </p>:null}
                                        </div>
                                       
                                       
                                        
                                        <div class="field-group inputField">
                                            <label>Location</label>
                                          {/*  <input type="text" class="form-control" name="location" value={location} onChange={this.handleChange}/>*/}
                                            <PlacesAutocomplete
                                                    value={location}
                                                    onChange={this.handleChangeLocation}
                                                    onSelect={this.handleSelect}
                                                >{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                    <div className="loc1">
                                                      <input
                                                        {...getInputProps({
                                                          placeholder: 'Search Places ...',
                                                          className: 'location-search-input '
                                                        })}
                                                      />
                                                      <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                          const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                          // inline style for demonstration purpose
                                                          const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                          return (
                                                            <div
                                                              {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                              })}
                                                            >
                                                              <span>{suggestion.description}</span>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    </div>
                                                  )}
                                            </PlacesAutocomplete>
                                            <p className="error_mesage"> {errors.location} </p>
                                        </div>

                                        <div class="field-group inputField">
                                            <label>Opening Date </label>
                                            <div class="formDate formIcon">
                                            <DatePicker
                                                selected={openDate}
                                                onChange={this.onChangeOpenDate}
                                                startDate={openDate}
                                                minDate={initialDate}
                                                shouldCloseOnSelect={true}
                                                />
                                              <p className="error_mesage"> {errors.openDate} </p>  
                                            </div>
                                        </div>
                                        
                                        

                                        <div class="field-group inputField ">
                                            <label>Opening Time</label>
                                            <div class="formTime formIcon">
                                            
                                            <TimePicker
                                              onChange={this.onOpenTimeChange}
                                              value={openTime}
                                              format='h:m a'
                                             // minTime={new Date()}
                                              disableClock={true}
                                            />
                                             <p className="error_mesage"> {errors.openTime} </p>    
                                            </div>
                                            
                                        </div>
                                        <div class="field-group inputField timeClock">
                                            <label>Clock Timer</label>
                                            <div class="formTime formIcon">
                                            
                                            <select class="form-control" name="timerClock" value={timerClock} onChange={this.handleChange}>
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                            </select>
                                             <p className="error_mesage"> {errors.timerClock} </p>    
                                            </div>
                                        </div>
                                        <div className="field_group75Both">
                                          <div class="field-group inputField">
                                              <label>Start Date </label>
                                              <div class="formDate formIcon">
                                              <DatePicker
                                                  selected={startDate}
                                                  onChange={this.onChangeDate}
                                                  startDate={startDate}
                                                  minDate={initialDate}
                                                  shouldCloseOnSelect={true}
                                                  />
                                                <p className="error_mesage"> {errors.startDate} </p>  
                                              </div>
                                          </div> 
                                          <div class="field-group inputField ">
                                              <label>Start Time</label>
                                              <div class="formTime formIcon">
                                                <TimePicker
                                                onChange={this.onTimeChange}
                                                value={time}
                                                format='h:m a'
                                                //minTime={new Date()}
                                                disableClock={true}
                                                />
                                                <p className="error_mesage"> {errors.time} </p>    
                                              </div>
                                              
                                          </div>
                                          <div class="field-group inputField">
                                            <label>End Date</label>
                                           
                                            <div class="formDate formIcon">
                                            <DatePicker
                                                  selected={endDate}
                                                  onChange={this.onChangeEndDate}
                                                  minDate={startDate} 
                                                  shouldCloseOnSelect={true}
                                                  />
                                                <p className="error_mesage"> {errors.endDate} </p>  
                                            </div>
                                          </div>
                                          <div class="field-group inputField ">
                                              <label>End Time</label>
                                              <div class="formTime formIcon">
                                              
                                              <TimePicker
                                              onChange={this.onEndTimeChange}
                                              value={endTime}
                                              format='h:m a'
                                              //minTime={new Date(startD)}
                                              disableClock={true}
                                              />
                                              <p className="error_mesage"> {errors.endTime} </p>    
                                              </div>
                                          </div>
                                          <div class="field-group inputField">
                                            <label>Reply to email</label>
                                            <input type="text" class="form-control" name="replyToEmail" value={replyToEmail} maxLength="40" onChange={this.handleChange}/>
                                            <p className="error_mesage"> {errors.replyToEmail} </p>
                                          </div> 
                                          <div class="field-group inputField">
                                            <label>Email Sender Name</label>
                                            <input type="text" class="form-control" name="emailSenderName" value={emailSenderName} maxLength="40" onChange={this.handleChange}/>
                                            <p className="error_mesage"> {errors.emailSenderName} </p>
                                           </div>
                                          <div class="field-group inputField timeClock">
                                              <label>Select Time Zone</label>
                                              <div class="formTime formIcon">
                                              <select class="form-control" name="timezone"  onChange={this.handleChange}>
                                              <option value={false}>Select timezone</option>
                                                {timezones && timezones.length> 0 && timezones.map((data)=>
                                                <option value={data.timezone}>{data.timezone}</option>
                                                )}    
                                              </select>
                                              <p className="error_mesage"> {errors.timezone} </p>    
                                              </div>
                                          </div> 
                                        </div>
                                        
                                          <div className='field_group25Both'>
                                          <div class="field-group inputField field_group75">
                                              <label>Message between opening time and start time</label>
                                              <textarea type="text" class="form-control" name="messageBwOpenTime"  maxLength="200" onChange={this.handleChange} >{messageBwOpenTime}</textarea>
                                          </div>
                                        </div>
                                        
                                         
                                    </div>
                                </div>
                                <div className="submitForm justify-content-center">
                                    <Button variant="contained" className="themeBlueButton" color="primary" type="submit">
                                    Create Event
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <NotificationContainer />
                        {loading ?
                <Loader />
            :null}
                </div>
        )
    }
}


const mapStateToProps = state => ({
    events: state.Events
 });
 
 const mapActionsToProps = dispatch =>
     bindActionCreators(
         {
             createEvent: Actions.eventsRequest,
             checkEventDomain: Actions.checkEventDomainRequest,
         },
         dispatch
     );
 export default connect(mapStateToProps, mapActionsToProps)(XpEventPlatform);
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import GridItem from './GridItem';
import Grid from '@mui/material/Grid2';
import {
  AIChatbotState,
  ChatBot,
  Color,
  MessageStyle,
  Settings,
} from 'ReduxStore/Reducers/aiChatbot.reducer';
import ChatInterface from '../ChatInterface';
import settings from '../../../../General/TabsGeneral/Settings/Settings';
import ColorTilePicker from 'Components/ColorPicker/ColorTilePicker';
import { font } from 'pdfkit';
import { size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
};
const ChatInputStyle = ({
  chatInput,
  bubbleButtonColor,
}: {
  chatInput: MessageStyle;
  bubbleButtonColor?: Color;
}) => {
  const dispatch = useDispatch();
  const chatBot = useSelector(
    (store: any) => store.AIChatbot.chatBot,
  ) as ChatBot;
  return (
    <>
      <Box component="div" className="inputField" mt={3}>
        <label>Chat input Padding (px)</label>
      </Box>
      <Grid container spacing={1}>
        <Grid size={{xs: 12,}}></Grid>
        <GridItem
          label={'Top'}
          value={chatInput?.padding?.top}
          path={['chatInput', 'padding', 'top']}
        />
        <GridItem
          label={'Right'}
          value={chatInput.padding?.right}
          path={['chatInput', 'padding', 'right']}
        />{' '}
        <GridItem
          label={'Bottom'}
          value={chatInput.padding?.bottom}
          path={['chatInput', 'padding', 'bottom']}
        />
        <GridItem
          label={'Left'}
          value={chatInput.padding?.left}
          path={['chatInput', 'padding', 'left']}
        />
      </Grid>
      {/* <Box component="div" className="inputField" mb={3}>
        <label>Chat input font Size1</label>
      </Box>
      <Grid container spacing={1}>
        <GridItem
          label={'Font Size'}
          value={chatInput.font?.size}
          path={['chatInput', 'font', 'size']}
          xs={8}
        />
      </Grid> */}
      <Box component="div" className="inputField" mt={3}>
        <label>Chat input Style</label>
      </Box>
      <Grid container spacing={1}>
      <Grid size={{xs: 3,}}>
        <Box component="div" className="inputField" mb={3}>
        <label>Font Size</label>
        <input
          type="number"
          className="form-control small"
          placeholder="Enter number"
          min={0}
          value={chatInput.font?.size}
          onChange={(e) =>
            dispatch(
              Actions.updateChatbot({
                ...chatBot,
                settings: {
                  ...chatBot.settings,
                  chatInterface: {
                    ...chatBot.settings.chatInterface,
                    chatInput: {
                      ...chatBot.settings.chatInterface?.chatInput,
                      font: {
                        ...chatBot.settings.chatInterface?.chatInput?.font,
                        size: Number(e.target.value || 0),
                      },
                    },
                  },
                },
              }),
            )
          }
        />
      </Box>
        </Grid>
        <GridItem
          label={'Border Size'}
          value={chatInput.border?.size}
          path={['chatInput', 'border', 'size']}
        />
        <Grid size={{xs: 3,}}>
          <Box component="div" className="inputField" mb={3}>
            <label>Border Style</label>
            <FormControl fullWidth size="small">
              <Select
                name="selectBorderState"
                className="form-control select small"
                style={{
                  padding: '0px',
                  marginBottom: '8px',
                }}
                MenuProps={MenuProps}
                value={chatInput.border?.state}
                // defaultValue={chatInterface.bubbleButtonAlignment}
                onChange={(e: any) => {
                  dispatch(
                    Actions.updateChatbot({
                      ...chatBot,
                      settings: {
                        ...chatBot.settings,
                        chatInterface: {
                          ...chatBot.settings.chatInterface,
                          chatInput: {
                            ...chatBot.settings.chatInterface?.chatInput,
                            border: {
                              ...chatBot.settings.chatInterface?.chatInput
                                ?.border,
                              state: (e.target.value),
                            },
                          },
                        },
                      },
                    }),
                  );
                }}
              >
                <MenuItem value="solid" selected>
                  Solid
                </MenuItem>

                <MenuItem value="dashed">Dashed</MenuItem>
                <MenuItem value="dotted">Dotted</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid size={{xs: 3,}}>
          <Box component="div" className="inputField" mb={3}>
            <label>Border Color</label>
            <Box pt={0.5}>
              <ColorTilePicker
                color={chatInput.border?.color}
                handleChangeFromParent={(color: Color) => {
                  dispatch(
                    Actions.updateChatbot({
                      ...chatBot,
                      settings: {
                        ...chatBot.settings,
                        chatInterface: {
                          ...chatBot.settings.chatInterface,
                          chatInput: {
                            ...chatBot.settings.chatInterface?.chatInput,
                            border: {
                              ...chatBot.settings.chatInterface?.chatInput
                                ?.border,
                              color: color,
                            },
                          },
                        },
                      },
                    }),
                  );
                }}
              />
            </Box>
          </Box>
        </Grid>
        
      </Grid>
      
      <Box component="div" className="inputField" mt={3}>
        <label>Chat input Radius (px)</label>
      </Box>
      <Grid container spacing={1}>
        <Grid size={{xs: 12,}}></Grid>
        <GridItem
          label={'Top Left'}
          value={chatInput?.borderRadius?.topLeft}
          path={['chatInput', 'borderRadius', 'topLeft']}
        />
        <GridItem
          label={'Top Right'}
          value={chatInput.borderRadius?.topRight}
          path={['chatInput', 'borderRadius', 'topRight']}
        />{' '}
        <GridItem
          label={'Bottom Right'}
          value={chatInput.borderRadius?.bottomRight}
          path={['chatInput', 'borderRadius', 'bottomRight']}
        />
        <GridItem
          label={'Bottom Left'}
          value={chatInput.borderRadius?.bottomLeft}
          path={['chatInput', 'borderRadius', 'bottomLeft']}
        />
      </Grid>
    </>
  );
};
export { ChatInputStyle };

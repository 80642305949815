import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions'; 
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme';
import getUnLayerEmailConfig from './config';
import { Modal } from 'react-responsive-modal';
import { NotificationContainer } from 'react-notifications';
import * as qs from 'qs';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid2';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import ShowHistory from '../../../assets/images/icons/show-history.svg';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getProjectIdFromUrl } from '../../../helpers/common';
import { get, cloneDeep } from 'lodash';
import { createNotification } from '../../../helpers';
import Loader from '../../../Components/Loader/loader';
import { languageCode } from '../../../helpers/common';
import { UserData } from '../../../helpers/common';
import { GetToolCustomFieldsAPI, getCustomToolTemplates, listAudienceCustomFieldsAPI } from 'ReduxStore/API';
import axios from 'axios';
import { permissions, FILE_SECTION, FILE_TYPE } from '../../../constants/constants';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp'; 
import withCustomRouter from '../../../Common/withCustomRouter'; 

const styles = {
  pink: {
    backgroundColor: theme.palette.secondary.main,
    width: '56px',
    height: '56px',
    borderRadius: 8,
  },
};


const URL = process.env.REACT_APP_API_URL;
const section = FILE_SECTION.images; 
class CampaignsEditor extends Component {
  constructor(props) {
    super(props);
    this.chooseUnlayerImage = React.createRef(null);
    this.anchorRef = React.createRef();
    this.state = {
      templateDetails: {},
      open: false,
      eventId: '',
      templateName: '',
      templateId: "",
      campaignName1: "",
      campaignUrl: "",
      html: null,
      json: {},
      formType: '',
      isUpdatePage: false,
      eventDomain: '',
      campaignType: '',
      openSaveAsMyTemplateModal: false,
      myTemplateName: '',
      isUpdate: false,
      loading: false,
      isExit: false,
      savedAtLeastOnce: false,
      fromSelectTemplatePage: false,
      emailIdForTestEmail: '',
      campaignId: '',
      openSendTestMailModal: false,
      errors: {},
      customFileds: null,
      exitBackButton: false,
      campaignNameMyTemp: '',
      showUpdateSuccessMessage: false,
      customFiledsForAudience: null,
      uploadFromFiles: false,
      buttonState: false,
      parentForPopup: "unlayerProjectPages",
      displayS3Image: false,
      parentId: "",
      pageEditorImage: "",
      pageEditorImageForEdit: ""
    };
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  async componentDidMount() {
    const { eid, tid, cn, purl } = this.props.params; 
    const users = UserData();
    this.props.handleLang(languageCode(users.language));
    if (this.props.location?.state?.fromSelectTemplatePage) {
      this.setState({
        fromSelectTemplatePage: this.props.location?.state?.fromSelectTemplatePage
      });
    }


    const eventId = getProjectIdFromUrl();
    this.setState({
      eventId
    });
    const templateId = tid;
    const campaignName1 = cn;
    let templateDetails = this.props.location && this.props.location.state ? this.props.location.state.template : null;

    const cid = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    });


    const paramsTools = { type: 'registrationform', eventId, fields: 'id,html,templateName,type,icon,toolCategory' };
    await getCustomToolTemplates(paramsTools).then((result) => {
      if (result?.data?.success) {
        this.state.toolTemplates = get(result, 'data.templates', []).map(
          (form) => ({
            ...form,
            html: form?.html
              ?.replace('100vh;', `auto;`)
              .replace('min-height: auto', 'min-height: auto!important'),
          }),
        );
      }
    });



    const param = { projectId: eventId };
    await GetToolCustomFieldsAPI(param).then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFileds = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });

    await listAudienceCustomFieldsAPI().then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFiledsForAudience = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });

    this.setState({
      templateDetails,
      templateId,
      campaignName1,
      fromEditEmailButton: cid.fromEditEmailButton === 'true' ? true : false
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    var qrCodeTypeDynamic = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Attendees", 'value': "attendee" },
      { 'label': "Sessions", 'value': "sessions" },
      { 'label': "Custom", 'value': "custom" }
    ];

    var qrCodeSessionOption = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Title", 'value': "{{{sessionTitleQrCode}}}" },
      { 'label': "Location", 'value': "{{{sessionLocationQrCode}}}" },
      { 'label': "Description", 'value': "{{{sessionDescriptionQrCode}}}" },
      { 'label': "Tracks", 'value': "{{{sessionTracksQrCode}}}" },
    ];

    var QrCodeSizeDropdown = [
      { 'label': "100X100", 'value': "100" },
      { 'label': "200X200", 'value': "200" },
      { 'label': "300X300", 'value': "300" },
      { 'label': "400X400", 'value': "400" },
    ];

    const setState = this.setState.bind(this);
    window.scrollTo(0, 0);
    window.unlayer.init(getUnLayerEmailConfig(apiUrl, eventId, this.state.customFileds, this.state.toolTemplates, qrCodeTypeDynamic, qrCodeSessionOption, QrCodeSizeDropdown, this.state.customFiledsForAudience));

    /* callback event for images module in unlayer start 1 */
    window.unlayer.registerCallback('selectImage', (data, done) => {
      this.handleOpenFileModulePopup();
      document.body.addEventListener('click', function (event) {
        // Ensure the clicked element has the class 'driveImages'
        if (event.target.classList.contains('driveImages')) {
          const src = event.target.src; // Get the src of the clicked image          
          done({ url: src });
        }
      });
    });
    /* callback event for images module in unlayer end  1*/

    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design;
        const html = data.html;
        setState({ json, html, updates });
      });
    });

    if (cid?.cid) {
      const users = await UserData();
      const res = await axios.get(
        `${URL}/campaigns/getCampaign/${cid.cid}`,
        {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      this.setState({
        html: res.data.campaign[0].html,
        json: res.data.campaign[0].json,
      });

      let templates = res.data.campaign[0] || {};
      const oldTemplates = cloneDeep(templates);
      const { json } = templates;
      this.setState({
        ...templates,
        showLoader: false,
        oldTemplates,
      });
      if (Object.keys(json).length > 0) {
        window.unlayer.loadDesign(json);
      } else {
        window.unlayer.loadDesign();
      }

      this.setState({
        campaignId: cid.cid
      });
    } else {
      this.setState({
        html: this.state.templateDetails.html,
        json: this.state.templateDetails.json,
      });
    }

    if (!cid.cid) {
      if (templateId !== 'blank') {
        let templates = this.props.location.state.template || {};
        const oldTemplates = cloneDeep(templates);
        const { json } = templates;
        this.setState({
          ...templates,
          showLoader: false,
          oldTemplates,
        });
        if (Object.keys(json).length > 0) {
          window.unlayer.loadDesign(json);
        } else {
          window.unlayer.loadDesign();
        }
      } else if (templateId === 'blank') {
        let json = {};
        window.unlayer.loadDesign();
      }
    }

    this.setState({
      eventId
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.updates === undefined && this.state.updates?.type === 'design:migrated') {
      this.setState({ pageHasChanged: false });
    }

    if (prevState.updates?.item?.id !== this.state.updates?.item?.id) {
      this.setState({ pageHasChanged: true });
    }

    if (this.props.campaigns.createCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      if (this.props.campaigns?.createCampaignData?.campaignType !== "UserDefined") {
        this.setState({
          campaignId: this.props.campaigns?.createCampaignData?._id
        });
        if (Object.keys(this.props.campaigns?.createCampaignData).length > 0) {
          this.setState({
            isUpdate: true,
          });
        }
        if (this.state.isExit) {
          if (this.props.location.fromEditCampaignComponent) {
            this.props.navigate({
              pathname: `/project-dashboard/campaigns/${this.state.eventId}`,
              search: `?cid=${this.props?.campaigns?.campaignDetails[0]._id}`,
              state: { isEditCampaign: true }
            });
          } else { 
            this.props.navigate(`/project-dashboard/campaigns/${this.state.eventId}`, {
              state: { isEditCampaign: true },
              search: `?cid=${this.props.campaigns.createCampaignData._id}`
            });
          }
        } else {
          this.props.navigate({
            pathname: `/project-dashboard/campaign-template-edior/tid/${this.state.templateId}/cn/${this.state.campaignName1}/eid/${this.state.eventId}`,
            search: `?cid=${this.props.campaigns.createCampaignData._id}`,
            state: { template: this.state.templateDetails }
          });

        }
        if (this.state.openSaveAsMyTemplateModal) {
          this.setState({
            openSaveAsMyTemplateModal: false
          });
        }

        if (this.state.loading) {
          this.setState({
            loading: false,
          });
        }

        this.setState({
          savedAtLeastOnce: true
        });
      } else {
        createNotification("success", this.props.campaigns.message);
        if (this.state.loading) {
          this.setState({
            loading: false,
          });
        }
        if (this.state.openSaveAsMyTemplateModal) {
          this.setState({
            openSaveAsMyTemplateModal: false
          });
        }
      }
    }

    if (this.props.campaigns.updateCampaignSuccess && !prevProps.campaigns.updateCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
      if (this.state.showUpdateSuccessMessage) {
        createNotification("success", this.props.campaigns.message);
        this.setState({
          showUpdateSuccessMessage: false
        });
      }

      if (this.state.isExit) {

    
        if (this.props.location && this.props.location.state  && this.props.location.fromEditCampaignComponent) {
          this.props.navigate({
            pathname: `/project-dashboard/campaigns/${this.state.eventId}`,
            search: `?cid=${this.state.campaignId}`,
            state: { isEditCampaign: true }
          });
        } else {
          this.props.navigate({
            pathname: `/project-dashboard/campaigns/${this.state.eventId}`,
            search: `?cid=${this.props.campaigns.createCampaignData._id}`,
            state: { isEditCampaign: true }
          });
        }
      }
      if (this.state.loading) {
        this.setState({
          loading: false,
        });
      }
      if (this.state.openSaveAsMyTemplateModal) {
        this.setState({
          openSaveAsMyTemplateModal: false
        });
      }
    }

    if (this.props.campaigns.sendTestEmailSuccess && !prevProps.campaigns.sendTestEmailSuccess) {
      createNotification("success", this.props.campaigns.message);
      this.setState({
        openSendTestMailModal: false
      });
    }
    if (this.props.campaigns.error && this.props.campaigns.message !== prevProps.campaigns.message) {
      if (this.props.campaigns?.message?.length) {
        createNotification("error", this.props.campaigns.message);
      }

      if (this.state.loading) {
        this.setState({
          loading: false,
        });
      }
    }
  }

  sendTestEmail = () => {
    const { emailIdForTestEmail, eventId, campaignId } = this.state;
    const requestBody = {
      email: emailIdForTestEmail,
      eventId,
      campaignId,
      lngCode: this.props.users.newLangState
    };
    if (this.isValid()) {
      this.props.sendTestEmail(requestBody);
    }
  };

  isValid = () => {
    let error = {};
    let formIsValid = true;

    const { emailIdForTestEmail } = this.state;

    if (emailIdForTestEmail === "" || emailIdForTestEmail === undefined) {
      formIsValid = false;
      error['emailIdForTestEmail'] = this.props.users.languageStateForRedux?.validations?.emailAdd;
    } else {
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if ((regex.test(emailIdForTestEmail) === false)) {
        formIsValid = false;
        error['emailIdForTestEmail'] = this.props.users.languageStateForRedux?.validations?.emailAdd;
      }
    }

    this.setState({
      errors: error
    });
    return formIsValid;
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        open: false
      });
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  saveCampaign = async (isExit, campaignType) => {
    const { campaignName1, html, json, eventId, campaignNameMyTemp } = this.state;
    if (json === undefined || Object.keys(json).length === 0 || html === undefined || html === "html") {
      createNotification("error", "Content cannot be blank");
    } else {
      this.setState({
        isExit,
      }, () => {
        let body = {
          projectId: eventId,
          html,
          json,
          lngCode: this.props.users.newLangState,
          campaignType: campaignType,
          status: "Draft",
        };
        if (campaignType !== "UserDefined") {
          body = {
            ...body,
            campaignName: campaignName1.trim(),
          };
        } else {
          body = {
            ...body,
            campaignName: campaignNameMyTemp.trim()
          };
        }


        const cid = qs.parse(get(this.props, 'location.search'), {
          ignoreQueryPrefix: true,
        });

        if (cid.cid) {
          if (campaignType === "UserDefined") {
            this.props.createCampaign(body);
          } else {
            this.setState({
              showUpdateSuccessMessage: true
            }, () => this.props.updateCampaign({ html, json, id: cid.cid, eventId, lngCode: this.props.users.newLangState }));
          }
          this.handleLoading();
        } else {
          this.props.createCampaign(body);
          this.handleLoading();
        }
      });
    }
    this.setState({
      pageHasChanged: false
    });
  };

  handleCloseSaveAsMyTemplateModal = () => {
    this.setState({
      openSaveAsMyTemplateModal: false,
    });
  };
  handleOpenSaveAsMyTemplateModal = () => {
    this.setState({
      openSaveAsMyTemplateModal: true,
    });
  };

  handlecampaignNameChange = (e) => {
    this.setState({
      campaignNameMyTemp: e.target.value
    });
  };

  handleCloseSendTestMailModal = () => {
    this.setState({
      openSendTestMailModal: false,
    });
  };

  handleOpenSendTestMailModal = () => {
    this.setState({
      openSendTestMailModal: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleOpenBackButtonModal = () => {
    if (this.state.pageHasChanged) {
      this.setState({
        exitBackButton: true
      });
    } else {
      this.handleBackButton('discard');
    }
  };

  handleBackButton = (exitStyle) => {
    if (exitStyle === 'saveAndExit') {
      this.saveCampaign(true, "Project");
    } else if (exitStyle === 'discard') {
      if (this.props.location.search.includes('cid')) {
        this.props.navigate({
          pathname: `/project-dashboard/campaigns/${this.state.eventId}`,
          search: `?cid=${this.state.campaignId}`,
          state: { isEditCampaign: true }
        });
      } else {
        this.props.navigate(`/project-dashboard/campaigns/${this.state.eventId}`);

      }
    }
  };

  handleCloseBackButtonModal = () => {
    this.setState({
      exitBackButton: false
    });
  };

  previewCampaign = () => {
    let params = this.props.location;
    let templateId = new URLSearchParams(params.search).get("cid");
    window.open(`/project-dashboard/preview-page/${this.state.eventId}?pagepath=${templateId}&sentFrom=campaignEditorPage&lngCode=${this.props.users.newLangState}`, '_blank');
  };

  /* callback event for images module in unlayer start 2 */
  handleOpenFileModulePopup = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };
  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  handleChoosedFile = () => {
    this.setState(
      {
        uploadFromFiles: false,
        openEditAttendeeModal: false,
      }
    );
  };

  chooseUnlayerImageClick = (id) => {
    this.setState({
      parentId: id
    });
    this.chooseUnlayerImage.current.click();
  };

  onUnlayerImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|gif|png)$/i)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5242880) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }

      if (method === 'add') {
        this.setState({ pageEditorImage: event.target.files[0] });

        let formData = new FormData();
        formData.append('imageFile', event.target.files[0]);
        formData.append('section', section);
        formData.append('type', FILE_TYPE.file);
        formData.append('parent', this.state.parentId);
        this.props.addImagesRequest((formData));

      } else {
        this.setState({ pageEditorImageForEdit: event.target.files[0] });
      }
    }
  };
  /* callback event for images module in unlayer end 2  */

  render() {
    const { campaignNameMyTemp } = this.state;

    const isCampaignSaved = this.props.location.search.includes('cid');
    return (
      <ThemeProvider theme={theme}>
        <div className="unlayer-header">
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid >
              <Grid container alignItems="center">
                <Button className='back-button' variant="text" onClick={this.handleOpenBackButtonModal}>
                  <img alt="" src={BackArrow} />
                </Button>
                {this.state.fromEditEmailButton ? <h3 className='page-title'>{this.props.location?.state?.campaignName} </h3> : <h3 className='page-title'>{this.state.campaignName1} </h3>}
              </Grid>
            </Grid>
            <Grid className='buttons'>
              <Stack spacing={1} direction="row">
                {isCampaignSaved && <Button variant="outlined" disableElevation onClick={this.handleOpenSendTestMailModal}>{this.props.users.languageStateForRedux?.unlayer?.testEmail}</Button>}
                {isCampaignSaved && <Button variant="outlined" disableElevation onClick={() => this.previewCampaign(false, "Project")}> {this.props.users.languageStateForRedux?.unlayer?.preview}</Button>}
                <Button variant="contained" disableElevation onClick={() => this.saveCampaign(false, "Project")}> {this.props.users.languageStateForRedux?.unlayer?.save}</Button>
                <div>
                  <Button
                    variant="contained"
                    style={{
                      width: '130px',
                      justifyContent: 'space-between',
                      minWidth: 'max-content'
                    }}
                    disableElevation
                    ref={this.anchorRef}
                    aria-controls={this.state.open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {this.props.users.languageStateForRedux?.unlayer?.saveAs}
                    {/* Save as */}
                  </Button>
                  <Popper open={this.state.open} sx={{ top: 10, }} placement="bottom-end" transition anchorEl={this.anchorRef.current} role={undefined} disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Fade {...TransitionProps} timeout={250} >
                        <Box sx={{ backgroundColor: theme.palette.common.white, boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: '8px', }}>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList autoFocusItem={this.state.open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                              <MenuItem
                                onClick={() => this.saveCampaign(true, "Project")}
                              >
                                {this.props.users.languageStateForRedux?.unlayer?.saveNext}
                              </MenuItem>
                              <MenuItem
                                onClick={this.handleOpenSaveAsMyTemplateModal}
                              >
                                {this.props.users.languageStateForRedux?.unlayer?.saveAsTemplate}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Box>
                      </Fade>
                    )}
                  </Popper>
                </div>
              </Stack>
            </Grid>
          </Grid>
          { /* callback event for images module in unlayer start -3 */}
          <UploadFromFilesPopUp
            uploadFromFiles={this.state.uploadFromFiles}
            chooseUnlayerImageClick={this.chooseUnlayerImageClick}
            chooseUnlayerImage={this.chooseUnlayerImage}
            handleCloseUploadFromFileModule={this.handleCloseFileModulePopup}
            onUnlayerImageChange={this.onUnlayerImageChange}
            handleChoosedFile={this.handleChoosedFile}
            parentForPopup={this.state.parentForPopup}
            buttonState={this.state.buttonState}
          />

          { /* callback event for images module in unlayer end -3 */}
          <Modal
            open={this.state.openSaveAsMyTemplateModal} onClose={this.handleCloseSaveAsMyTemplateModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid component='form' container className='modal-wrapper' >
              <Grid size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.formfields?.managePages?.saveAsMyTemp}</Box>
                <p className='small-subtitle'>{this.props.users.languageStateForRedux?.formfields?.managePages?.saveTempDesc}</p>
              </Grid>
              <Grid size={{xs: 12,}} pb={4}>
                <Box component="div" className='inputField'>
                  <label>{this.props.users.languageStateForRedux?.formfields?.managePages?.tempName}<span className='required'></span></label>
                  <input type="text" class="form-control small" placeholder="" value={campaignNameMyTemp} onChange={this.handlecampaignNameChange} />
                </Box>
              </Grid>
              <Grid size={{xs: 12,}} >
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button variant="contained" color="primary" disabled={campaignNameMyTemp.trim() ? false : true} disableElevation onClick={() => this.saveCampaign(false, "UserDefined")}>{this.props.users.languageStateForRedux?.common?.save}</Button>
                    <Button variant="outlined" onClick={this.handleCloseSaveAsMyTemplateModal}>{this.props.users.languageStateForRedux?.common?.cancel}</Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
          <Modal
            open={this.state.openSendTestMailModal} onClose={this.handleCloseSendTestMailModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid component='form' container className='modal-wrapper' >
              <Grid size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.unlayer?.sendMail}</Box>
                <p className='small-subtitle'>{this.props.users.languageStateForRedux?.formfields?.campaigns?.testEmailDesc}</p>
              </Grid>
              <Grid size={{xs: 12,}} pb={4}>
                <Box component="div" className='inputField'>
                  <label>{this.props.users.languageStateForRedux?.column_names?.teams?.email}<span className='required'>*</span></label>
                  <input type="text" class="form-control small" placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalPlaceholder} name="emailIdForTestEmail" value={this.state.emailIdForTestEmail} onChange={this.handleChange} />
                  <span className="error_mesage required"> {this.state.errors.emailIdForTestEmail} </span>
                </Box>
              </Grid>
              <Grid size={{xs: 12,}} >
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button variant="contained" color="primary" disableElevation onClick={this.sendTestEmail}>{this.props.users.languageStateForRedux?.unlayer?.sendMail}</Button>
                    <Button variant="outlined" onClick={this.handleCloseSendTestMailModal}>{this.props.users.languageStateForRedux?.common?.cancel}</Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
          <Modal
            open={this.state.exitBackButton} onClose={this.handleCloseBackButtonModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  Confirmation
                </Box>
                <p className='small-subtitle'>Do you want to exit without saving?</p>
              </Box>
              <Grid size={{xs: 12,}} >
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button variant="contained" color="primary" disableElevation onClick={() => this.handleBackButton('saveAndExit')}>Save and exit</Button>
                    <Button variant="outlined" disableElevation onClick={() => this.handleBackButton('discard')}>Discard changes</Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {/* input for open files */}
          <input
            type="file"
            id="pageEditorImage"
            name="pageEditorImage"
            onChange={(e) => this.onUnlayerImageChange(e, 'add')}
            accept="image/*"
            ref={this.chooseUnlayerImage}
            style={{ display: "none" }}
          />
          {/* input for open files */}
        </div>
        {
          <div className="editingDashboard">
            <div id="emailEditor" style={{ height: 'calc(100vh - 60px)' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.Campaigns,
  events: state.Events,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      createCampaign: Actions.createCampaignRequest,
      updateCampaign: Actions.updateCampaignRequest,
      getCampaign: Actions.getCampaignRequest,
      sendTestEmail: Actions.sendTestEmailRequest,
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux,
      addImagesRequest: Actions.addImagesRequest
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(CampaignsEditor))


 
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateUserRoute = ({ element, ...rest }) => {
  const userRole = localStorage.getItem('user_role'); 
  // If the user role is valid, return the passed element, otherwise redirect to home
  return userRole && userRole !== 'undefined' ? element : <Navigate to="/" replace />;
};

 


export default PrivateUserRoute;

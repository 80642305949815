import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
// import '../sass/main.scss';
import { ThemeProvider } from '@mui/material';
import { theme } from '../theme/theme';

export default function FilterDropDown({
  dropdownArray,
  filterBy,
  setFilterBy,
  filtering,
  filterCount,
  totalCount
}) {
  
  const anchorRef1 = React.useRef(null);
  const [open1, setOpen1] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [totalSelectedCount, setTotalSelectedCount] = React.useState(0);

  useEffect(() => {
    setTotalSelectedCount(totalCount);
  }, [totalCount]);

  const handleToggle1 = () => {
    setOpen1((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen1(false);
  };
  const handleSelectAllFilter = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setTotalSelectedCount(`${filterCount?.[`${filtering}All`]}`);
      setFilterBy(dropdownArray.map((status) => status._id));
    } else {
      setTotalSelectedCount(0);
      setFilterBy([]);
    }
  };

  const handleAddToFilterBy = (e, _id) => {
    const checked = e.target.checked;
    if (checked) {
      const numberToAdd = filterCount[`${filtering}Count`][_id] || 0;
      setTotalSelectedCount((prev) => parseInt(prev) + parseInt(numberToAdd));
      setFilterBy((prev) => [...prev, _id]);
    } else {
      const numberToSubtract = filterCount[`${filtering}Count`][_id] || 0;
      setTotalSelectedCount((prev) => parseInt(prev) - parseInt(numberToSubtract));
      let arr = [...filterBy];
      arr = arr.filter((v) => v !== _id);
      setFilterBy(arr);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSettings(false);
    }
  }

  const getLabelForSelectedFilters = () => {
    //audience changed as contact groups
    let label = filtering === "audience" ? "groups": filtering;

    if(filterCount?.[`${filtering}All`] === undefined){    // If filtercount is undefined for the particular field
      return `All ${label} (0)`
    } else if (filterBy.length === 0) {
      return `All ${label} (${Object.keys(filterCount).length ? filterCount?.[`${filtering}All`] : 0})`;
    } else if (filterBy.length === 1) {
      const selectedObj = dropdownArray.find((el) => el._id === filterBy[0]);
      return (
        (filtering === 'projects' ? selectedObj?.eventName : (selectedObj && selectedObj.name? selectedObj.name:`All ${filtering}`) ) +
        `(${totalSelectedCount})`
      );
    } else if (dropdownArray.length === filterBy.length) {
      return `All ${label} (${filterCount?.[`${filtering}All`]})`;
    } else {
      return `${filterBy.length} ${label} selected (${totalSelectedCount})`;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid >
        <Box
          component={Button}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.background.light,
            color: theme.palette.common.black,
            textTransform: 'capitalize',
            fontWeight: '400',

            '&:hover, &.active': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.primary.contrastText,
            },
          }}
          className={filterBy.length && 'active'}
          size="small"
          disableElevation
          ref={anchorRef1}
          aria-controls={open1 ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle1}
          endIcon={<ArrowDropDownIcon />}
          disabled={filterCount?.[`${filtering}All`] === undefined || filterCount?.[`${filtering}All`] === 0}
        >
          { getLabelForSelectedFilters() }
        </Box>
        <Popper
          open={open1}
          placement="bottom-start"
          transition
          anchorEl={anchorRef1.current}
          role={undefined}
          disablePortal
          style={{ zIndex: 3 }}
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Box
                className="option-list"
                style={{
                  backgroundColor: theme.palette.background.light,
                  borderRadius: 4,
                  marginTop: 8,
                  overflowY: 'scroll',
                  maxHeight: 195,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={openSettings}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem className="checkbox-item">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ padding: 0, marginRight: '5px' }}
                            color="default"
                            onChange={(e) => handleSelectAllFilter(e)}
                            checked={filterBy.length === dropdownArray.length}
                          />
                        }
                        label={`All (${filterCount?.[`${filtering}All`] || Object.keys(filterCount).length})`}
                        style={{ padding: 0 }}
                      />
                    </MenuItem>
                    {dropdownArray.map((el) => {
                      return (
                        <MenuItem className="checkbox-item" key={el._id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{ padding: 0, marginRight: '5px' }}
                                color="default"
                                onChange={(e) => handleAddToFilterBy(e, el._id)}
                                checked={filterBy.includes(el._id)}
                              />
                            }
                            label={
                              filtering === 'projects'
                                ? `${el.eventName} (${
                                    filterCount?.[`${filtering}Count`]?.[
                                      el._id
                                    ] || 0
                                  })`
                                : `${el.name} (${
                                    filterCount?.[`${filtering}Count`]?.[
                                      el._id
                                    ] || 0
                                  })`
                            }
                            style={{ padding: 0 }}
                          />
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
      </Grid>
    </ThemeProvider>
  );
}

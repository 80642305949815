declare global {
  interface Window {
    myFunction?: () => void;
  }
}
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';

import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import moment from 'moment';
//@ts-ignore
import DocIcon from '../../../src/assets/images/icons/Doc-icon.svg';
import ClearIcon from '@mui/icons-material/Clear';
//@ts-ignore
import AddFile from '../../../src/assets/images/icons/attach_file_add.svg';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  xpAccountIntegrationDownloadAllAttachment,
  xpAccountIntegrationDownloadAttachment,
  xpAccountIntegrationGmailArchiveEmail,
  xpAccountIntegrationGmailArchiveThread,
  xpAccountIntegrationGmailDeleteEmail,
  xpAccountIntegrationGmailDeleteThread,
  xpAccountIntegrationGmailMarkEmailAsRead,
  xpAccountIntegrationGmailMarkEmailAsUnread,
  xpAccountIntegrationGmailMarkThreadAsRead,
  xpAccountIntegrationGmailMarkThreadAsUnread,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import ReactQuill from 'react-quill';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
interface AutoResizeIframeProps {
  getHtmlOfContent: () => string;
}

type GmailViewProps = {
  thread: any;
  closeGmailContent: () => void;
  totalCount: number | undefined;
  tempStart: number;
  handlePreviousMail: () => void;
  handleNextMail: () => void;
  gmailId: string;
  getGmailData: () => void;
  modules: any;
};

const GmailView = ({
  thread,
  closeGmailContent,
  totalCount,
  tempStart,
  handlePreviousMail,
  handleNextMail,
  gmailId,
  getGmailData,
  modules
}: GmailViewProps) => {
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [editorText, setEditorText] = useState('');
  const [fileNames, setFileNames] = useState<File[]>([]);
  const [showReplyCompose, setShowReplyCompose] = useState<boolean>(false);

  const [showMore, setShowMore] = useState(false);
  const [gmail, setGmail] = useState([]);

  console.log("===========gmail", thread)

  useEffect(() => {
    // getAttachmentIds();
  }, [gmail]);

  useEffect(() => {
    setGmail(thread)
    thread.some(message => message.labelIds.includes('UNREAD')) && markThreadAsRead();
  }, [])

  // const getAttachmentIds = () => {
  //   if(Array.isArray(gmail) && gmail.length && gmail[0]){
  //     const { payload } = gmail[0];
  //     const { parts } = payload;
  //     let attachmentIds = [];
  //     if (
  //       payload.mimeType === 'multipart/mixed' &&
  //       Array.isArray(parts) &&
  //       parts.length > 1
  //     ) {
  //       attachmentIds = parts.map((part, i) => {
  //         if (i > 0) {
  //           return {
  //             attachmentId: part.body.attachmentId,
  //             filename: part.filename,
  //           };
  //         }
  //       });
  
  //       setAttachmentIds(attachmentIds.filter((el) => el != undefined));
  //     }
  //   }
    
  // };

  const markEmailAsRead = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkEmailAsRead(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markEmailAsUnread = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
      if (res.data.success) {
        getGmailData();
        closeGmailContent();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markThreadAsRead = async () => {
    try {
      const data = {
        threadId: thread[0]?.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkThreadAsRead(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };
  const markThreadAsUnread = async () => {
    try {
      const data = {
        threadId: thread[0]?.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkThreadAsUnread(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const archiveThread = async () => {
    try {
      const data = {
        threadId: gmail[0]?.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailArchiveThread(data);
      if (res.data.success) {
        getGmailData();
        closeGmailContent();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const deleteEmail = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailDeleteEmail(data);
      if (res.data.success) {
        setGmail(prev => prev.filter(obj => obj.id !== id))
        // closeGmailContent();
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const deleteThread = async () => {
    try {
      const data = {
        threadId: gmail[0]?.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailDeleteThread(data);
      if (res.data.success) {
        closeGmailContent();
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  // console.log("gmailView==========gmail", gmail)
  const getHtmlOfContent = (mail) => {
    // console.log("=============mail", mail)
    if (
      mail.payload.mimeType === 'multipart/mixed' ||
      mail.payload.mimeType === 'multipart/alternative'
    ) {
      if (mail?.payload?.parts.length > 1) {
        if (mail.payload.parts[0]?.mimeType === 'multipart/alternative') {
          if (mail?.payload?.parts[0]?.parts?.length > 1) {
            if (mail?.payload?.parts[0]?.parts[0]?.mimeType === 'text/html') {
              console.log(
                '==========1111',
                Buffer.from(
                  mail?.payload?.parts[0]?.parts[0]?.body?.data,
                  'base64',
                ).toString('ascii'),
              );
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[0]?.body?.data,
                'base64',
              ).toString('ascii');
            } else if (
              mail?.payload?.parts[0]?.parts[1]?.mimeType === 'text/html'
            ) {
              console.log(
                '==============22222',
                Buffer.from(
                  mail?.payload?.parts[0]?.parts[1]?.body?.data,
                  'base64',
                ).toString('ascii'),
              );
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[1]?.body?.data,
                'base64',
              ).toString('ascii');
            }
          }
        } else {
          if (mail.payload?.parts[0]?.mimeType === 'text/html') {
            return Buffer.from(
              mail.payload?.parts[0]?.body?.data,
              'base64',
            ).toString('ascii');
          } else if (mail?.payload?.parts[1]?.mimeType === 'text/html') {
            return Buffer.from(
              mail?.payload?.parts[1]?.body?.data,
              'base64',
            ).toString('ascii');
          }
        }
      } else if (mail?.payload?.parts.length) {
        return Buffer.from(
          mail?.payload?.parts[0]?.body?.data,
          'base64',
        ).toString('ascii');
      } else {
        return '';
      }
    } else if (mail.payload.mimeType === 'text/html') {
      return Buffer.from(mail.payload?.body?.data, 'base64').toString('ascii');
    }
  };

  const downloadAttachment = async (messageId, filename, attachmentId) => {
    try {
      const data = {
        messageId,
        gmailId,
        filename,
        attachmentId,
      };
      await xpAccountIntegrationDownloadAttachment(data);
    } catch (error) {
      createNotification('error', error.message);
    }
  };

  // const downloadAllAttachments = async () => {
  //   try {
  //     const data = {
  //       messageId: gmail[0]?.id,
  //       gmailId,
  //       attachmentIds,
  //     };
  //     await xpAccountIntegrationDownloadAllAttachment(data);
  //   } catch (error) {
  //     createNotification('error', error.message);
  //   }
  // };

  console.log('==========gmail', gmail);

  const renderAttachments = (gmail) => {
    const { payload } = gmail;
    const { parts } = payload;

    if (
      payload.mimeType === 'multipart/mixed' &&
      Array.isArray(parts) &&
      parts.length > 1
    ) {
      return parts.map((part, i) => {
        if (i > 0) {
          return (
            <Box className="attachment-file">
              <Box display={'flex'} alignItems={'center'}>
                <Box className="file-icon">
                  <img src={DocIcon} alt="file-icon" />
                </Box>
                <Box>
                  <Box className="file-name">{part.filename}</Box>
                  <Box className="file-size">{part.body.size}</Box>
                </Box>
              </Box>
              <FileDownloadIcon
                onClick={() =>
                  downloadAttachment(
                    gmail.id,
                    part.filename,
                    part.body.attachmentId,
                  )
                }
                style={{ fontSize: '18px' }}
                className="options-button"
              />
            </Box>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  const splitHtmlAtGmailQuote = (htmlString) => {
    // Regular expression to find the first occurrence of a div with the class "gmail_quote"
    const regex = /<div[^>]*class=["'][^"']*gmail_quote[^"']*["'][^>]*>/;

    // Find the position of the first match
    const match = htmlString.match(regex);

    if (match && match.index !== undefined) {
      // Get the starting index of the matched <div> tag
      const splitIndex = match.index;

      // Split the string into two parts
      const part1 = htmlString.slice(0, splitIndex);
      const part2 = htmlString.slice(splitIndex);

      return { part1, part2 };
    } else {
      // If no match is found, return the original string as part1 and an empty string as part2
      return { part1: htmlString, part2: "" };
    }
  };

  const handleEditorText = (val: string) => {
    setEditorText(val);
  };

  const handleAttachment = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', ''); // Enable multiple file selection
    input.click();

    input.onchange = () => {
      const files = Array.from(input.files || []); // Convert the FileList to an array
      if (files.length > 0) {
        console.log('Selected files:', files);
        setFileNames([...fileNames, ...files]);
      }
    };
  };

  const handleDelete = (fileToDelete: File) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((file) => file.name !== fileToDelete.name)
    );
  };

  const handleMailStatus = async (status: string) => {
    // if (isValid(status)) {
    //     const allRecipients = recipients.split(",").map((mail) => mail.trim());
    //     const allCcMails = cC.split(",").map((mail) => mail.trim());
    //     const allBCcMails = bcC.split(",").map((mail) => mail.trim());
    //     const formattedRecipients = allRecipients.join(',');
    //     const formattedCcMails = allCcMails.join(',');
    //     const formattedBCcMails = allBCcMails.join(',');
    //     const formData = new FormData();
    //     formData.append('from', gmailId);
    //     formData.append('to', formattedRecipients);
    //     formData.append('cC', formattedCcMails);
    //     formData.append('bcC', formattedBCcMails);
    //     formData.append('subject', subject);
    //     formData.append('htmlContent', editorText);           

    //     fileNames.forEach((file: File) => {
    //         console.log("file in loop", file);
    //         formData.append('attachments', file);
    //     })

    //     if (status === 'send') {
    //         setIsLoading(true);
    //         let response = {};
    //         if(messageIdForDraft){
    //             formData.append('gmailId', gmailId);
    //             formData.append('draftId', draftId);
    //             removedAttachments.forEach(el => {
    //                 formData.append('removedAttachments', el);
    //             })
    //             response = await xpAccountIntegrationMailSendDraft(formData);
    //         } else {
    //             response = await xpAccountIntegrationMailSend(formData);
    //         }
    //         setIsLoading(false);
    //         if (response?.data?.status == 200) {
    //             createNotification('success', response?.data?.message);
    //             setRecipients('');
    //             setSubject('');
    //             setPayload({});
    //             setCc('');
    //             setBcc('');
    //             setEditorText('');
    //             setFileNames([]);
    //         }

    //     } else {
    //         setIsLoading(true);
    //         console.log("===========messageIdForDraft", messageIdForDraft)
    //         console.log("===========draftId", draftId)
    //         let response = {};
    //         if(messageIdForDraft){
    //             formData.append('messageId', messageIdForDraft);
    //             formData.append('draftId', draftId);
    //             formData.append('gmailId', gmailId);
    //             removedAttachments.forEach(el => {
    //                 formData.append('removedAttachments', el);
    //             })
    //             response = await xpAccountIntegrationMailUpdateDraft(formData);
    //         } else {
    //             response = await xpAccountIntegrationMailSaveAsDraft(formData);
    //         }
    //         setIsLoading(false);
    //         if (response?.data?.status == 200) {
    //             createNotification('success', response?.data?.message);
    //             setRecipients('');
    //             setSubject('');
    //             setPayload({});
    //             setCc('');
    //             setBcc('');
    //             setEditorText('');
    //             setFileNames([]);
    //         }
    //     }
    //     setIsLoading(false);
    // }
  };

  

  // -------------- shadow DOM

  const EmailContent = ({ getHtmlOfContent }) => {
    const shadowRef = useRef(null);

    useEffect(() => {
      if (!shadowRef.current) return;

      // Attach Shadow DOM
      const shadowRoot = shadowRef.current.attachShadow({ mode: "open" });

      // Create a container for the email content
      const contentContainer = document.createElement("div");
      contentContainer.style.all = "initial"; // Reset styles to avoid conflicts

      // Insert the email content (use getHtmlOfContent to fetch your email HTML)
      contentContainer.innerHTML = getHtmlOfContent() || "";

      // Append the container to the Shadow DOM
      shadowRoot.appendChild(contentContainer);

      // Clean up if necessary when the component unmounts
      return () => {
        shadowRoot.innerHTML = "";
      };
    }, [getHtmlOfContent]);

    return (
      // Ref to hold the Shadow DOM root
      <div ref={shadowRef} style={{ width: "100%", height: "auto", border: "none" }} />
    );
  };

  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showReplyCompose && boxRef.current) {
      // Scroll to the bottom when the box becomes visible
      boxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showReplyCompose]); // Runs every time `isBoxVisible` changes


  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  console.log('===========attachmentIds', attachmentIds);
  return (
    <Box >
      <Box
        pb={1}
        mt={-5}
        mb={2}
        sx={{ borderBottom: '1px solid #EDECF5' }}
        className="top-filter-area"
      >
        <Grid container justifyContent={'space-between'} spacing={1} width={'100%'} alignItems="center">
          <Grid>
            <Grid container spacing={1} width={'100%'} alignItems="center">
              <Grid>
                <IconButton
                  size="medium"
                  title="Back"
                  onClick={closeGmailContent}
                >
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton
                  size="medium"
                  title="Archive"
                  onClick={() => archiveThread()}
                >
                  <ArchiveOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton
                  title="Mark as unread"
                  onClick={() => markThreadAsUnread()}
                >
                  <MarkEmailUnreadOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton
                  size="medium"
                  title="Delete"
                  onClick={() => deleteThread()}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container spacing={1} className="team-dash-right">
              <Grid>
                <span>
                  {tempStart} of {totalCount}
                </span>
              </Grid>
              <Grid>
                <IconButton
                  onClick={handlePreviousMail}
                  size="small"
                  title="Previous"
                >
                  <NavigateBeforeOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton onClick={handleNextMail} size="small" title="Next">
                  <NavigateNextOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className="subject subtitle" mb={2}>
        {gmail?.[0]?.payload?.headers.find((g: any) => g.name === 'Subject').value}
      </Box>

      {Array.isArray(gmail) &&
        gmail.length > 0 &&
        gmail.map((mail) => {
          console.log('==========>>>>mail==========', mail);
          return (
            <Box className="email-container" key={mail.id}  mb={2} sx={{ borderBottom: '1px solid #EDECF5' }}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} className="header" pb={2}>
                <Box>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <PersonOutlineOutlinedIcon />
                    <Box>
                      <span className="bold">Sender Name </span>
                      <span>&lt;</span>
                      {
                        mail.payload.headers.find((g: any) => g.name === 'From')
                          .value
                      }
                      <span>&gt;</span>
                    </Box>
                  </Stack>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <WatchLaterOutlinedIcon />
                    <Box>
                      {moment(
                        mail.payload.headers.find((g: any) => g.name === 'Date')
                          .value,
                      ).format('dddd, MMMM DD, YYYY h:mm:ss A')}
                    </Box>
                  </Stack>
                </Box>
                <Grid container spacing={1}>
                  <Grid>
                    <IconButton
                      title="Mark as unread"
                      onClick={() => markEmailAsUnread(mail.id)}
                    >
                      <MarkEmailUnreadOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid>
                    <IconButton
                      size="medium"
                      title="Delete"
                      onClick={() => deleteEmail(mail.id)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Stack>

              <Box
                className="email-body"
                pb={0}
                mb={2}
              >
                {/* <button onClick={() => deleteEmail(mail.id)}>Delete</button>
                <Button onClick={() => markEmailAsUnread(mail.id)}>Mark As Unread</Button> */}
                {/* <AutoResizeIframe
                  getHtmlOfContent={() => splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part1}
                /> */}
                <EmailContent getHtmlOfContent={() => splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part1} />
                {splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part2.length !== 0 &&

                  <button onClick={toggleShowMore} style={{ backgroundColor: '#edecf5', border: 'none', borderRadius: '10px', cursor:'pointer', marginBottom: '10px' }}>
                    <MoreHorizIcon style={{fontSize: '16px',}} />
                  </button>
                }
                {/* <AutoResizeIframe
                  getHtmlOfContent={() => splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part2}
                /> */}
                {showMore &&
                  <EmailContent
                    getHtmlOfContent={() => splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part2}
                  />
                }
                {/* <iframe style={{width:'100%', border: 'none'}}
                  srcDoc={splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part1}
                />
                <iframe style={{width:'100%', border: 'none'}}
                  srcDoc={splitHtmlAtGmailQuote(getHtmlOfContent(mail)).part2}
                /> */}
                {/* {ReactHtmlParser(getHtmlOfContent() || '')} */}
              </Box>
              {
              // attachmentIds.length > 0 && 
              (
                <Box className="attachment-container" mt={1}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className="attachment-header"
                  >
                    <Box className="paragraph bold">
                      {attachmentIds.length} Attachments
                    </Box>
                    {/* <Box className="paragraph bold">
                      <Tooltip
                        placement="bottom-end"
                        arrow
                        title="Download Attachments"
                      >
                        <IconButton size="small">
                          <FileDownloadIcon
                            color="primary"
                            onClick={() => downloadAllAttachments()}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box> */}
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={'row'}
                    mt={1}
                    className="attachments"
                  >
                    {renderAttachments(mail)}
                  </Stack>
                </Box>
              )}
            </Box>
          );
        })}
      {!showReplyCompose ? <Box className='reply'>
        <Button variant={'outlined'} color={'primary'} size={"small"} startIcon={<ReplyIcon />} onClick={() => setShowReplyCompose(true)}>
          Reply
        </Button>
      </Box> :
        <Box ref={boxRef}>
          <ReactQuill
            theme="snow"
            value={editorText}
            onChange={handleEditorText}
            modules={modules}
          />
          <Stack direction={'row'} mt={2} justifyContent={'space-between'}>
            <Box className={'attachment-container'}>
              <Grid
                container
                spacing={1}
                className="attachments"
              >
                {fileNames?.map((file) => {
                  const imageSizeInBytes = file?.size;
                  let convertedFileSizeKbMb;
                  if (imageSizeInBytes > 1024 * 1024) {
                    // Convert to MB if file size is larger than 1024 KB
                    convertedFileSizeKbMb = `${(imageSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
                  } else {
                    convertedFileSizeKbMb = `${(imageSizeInBytes / 1024).toFixed(2)} KB`;
                  }
                  return (
                    <Grid>
                      <Box className="attachment-file">
                        <Box display={'flex'} alignItems={'center'}>
                          <Box className="file-icon">
                            <img src={DocIcon} alt="file-icon" />
                          </Box>
                          <Box>
                            <Box className="file-name">{file.name}</Box>
                            <Box className="file-size">Size: {convertedFileSizeKbMb}</Box>
                          </Box>
                        </Box>
                        <Box ml={1} className="options">
                          <ClearIcon onClick={() => handleDelete(file)} style={{ fontSize: '18px' }} />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
                <Grid>
                  <Box className="attachment-file upload" onClick={handleAttachment}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box className="file-icon" p={1}>
                        <img src={AddFile} alt="add-file" />
                      </Box>
                      <Box>
                        <Box className="file-name secondary-text">
                          Attach file
                        </Box>
                        {/* <Box className="file-size">10 Mb Max.</Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={1}>
              <Grid>
                <Button onClick={() => setShowReplyCompose(false)} variant="outlined" color="primary" size="small">Discard</Button>
              </Grid>
              <Grid>
                <Button onClick={() => handleMailStatus("send")} variant="contained" color="primary" size="small" endIcon={<SendIcon />}>Send</Button>
              </Grid>
            </Grid>
          </Stack>
        </Box>}
    </Box>
  );
};

const AutoResizeIframe: React.FC<AutoResizeIframeProps> = ({
  getHtmlOfContent,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', adjustHeight);

      // Trigger adjustment after srcDoc updates
      const adjustOnSrcDocChange = () => {
        adjustHeight();
      };

      adjustOnSrcDocChange(); // Initial adjustment

      return () => {
        iframe.removeEventListener('load', adjustHeight);
      };
    }
  }, [getHtmlOfContent]);


  const adjustHeight = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      try {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        if (iframeDocument) {
          const height =
            iframeDocument.documentElement.scrollHeight ||
            iframeDocument.body.scrollHeight;
          iframe.style.height = `${height}px`;
        }
      } catch (error) {
        console.error('Error adjusting iframe height:', error);
      }
    }
  };

  return (
    <>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', border: 'none' }}
        srcDoc={getHtmlOfContent() || ''}
      />

      {/* <button onClick={toggleQuote}>...</button> */}
    </>
  );
};

export default GmailView;

import Modal from "react-responsive-modal";
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const RenameFolderModal = (props) => {
    return (
        <Modal
            open={props.renameModal}
            onClose={props.handleCloseRenameFolder}
            classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
            }}
            center
        >
            <Grid component="form" container className="modal-wrapper">
                <Grid size={{xs: 12,}} pb={2}>
                    <Box mt={-1} className="subsecondary">
                        {props.isFolderSelected ? "Rename Folder" : "Rename file name"}
                    </Box>
                </Grid>
                <Grid size={{xs: 12,}} pb={3}>
                    <Box component="div" className="inputField">
                        <label>
                            {props.isFolderSelected ? "Folder Name" : "File Name"}<span className="required">*</span>
                        </label>
                        <input 
                        type="text" 
                        class="form-control small" 
                        placeholder="" 
                        value={ props.isFolderSelected ? props.folderToRename.name:props.fileToRename.name} 
                        onChange={
                                props.isFolderSelected
                                ? (e) => props.handleUpdateFolderName(e.target.value)
                                : (e) => props.handleUpdateFileName(e.target.value)
                            } 
                        />
                    </Box>
                </Grid>
                <Grid size={{xs: 12,}}>
                    <Grid container justifyContent="end">
                        <Stack spacing={1} direction="row" fullWidth>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={props.handleCloseRenameFolder}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={
                                    props.isFolderSelected
                                        ? () => props.handleUpdateFolderSubmit(props.folderToRename.name, props.folderToRename._id)
                                        : () => props.handleUpdateFileSubmit(props.fileToRename.name, props.fileToRename._id)
                                }
                            >
                                Update
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default RenameFolderModal;
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EditingBack from '../../assets/images/backPage.png';
import desktopBlack from '../../assets/images/desktop-black.png';
import desktopBlue from '../../assets/images/desktop-blue.png';
import tabletBlack from '../../assets/images/tablet-black.png';
import tabletBlue from '../../assets/images/tablet-blue.png';
import mobileblack from '../../assets/images/mobile-black.png';
import mobileblue from '../../assets/images/mobile-blue.png';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { Button } from '@mui/material'; // Import css

const PageToolBar = ({
  editingTitle = 'Back',
  saveBtnLabel = 'Save',
  backupPath = '#',
  showIcons = false,
  onSaveFn,
  updateActiveDevice,
  onPreviewClick,
  selectedView,
  proutes,
  showPreviewOption = true,
  showChangeConfirmation = true,
  backTo,
  makeActive,
  showSaveBtn = true,
  activeBtnLabel = 'Save & Active',
  showActiveBtn = false,
  activeBtnDisabled = false,
  showAddToLib = false,
  addToLibBtnLabel = 'Save as Template',
  saveTemplateToLib,
  saveTempDisabled = false,
}) => {
  const [activeDevice, setActiveDevice] = useState('tablet');
  const navigate = useNavigate();

  const backConfirm = () => {
    if (showChangeConfirmation) {
      confirmAlert({
        title: 'Confirmation',
        message:
          'Please confirm that you have saved all the changes before leaving this edit feature.',
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: 'Continue',
            onClick: () => {
              navigate(backTo || proutes.proutes);
            },
          },
        ],
      });
    } else {
      navigate(backTo || proutes.proutes);
    }
  };

  window.onpopstate = function (event) {
    navigate(proutes.proutes);
  };

  return (
    <div className="pageEditingToolBarRoot">
      <div className="editingHeader">
        <div className="editingHeading">
          <h3>
            <Link>
              <img src={EditingBack} alt="" onClick={backConfirm} />
            </Link>
            {editingTitle}
          </h3>
        </div>
        {showIcons && (
          <div className="renposive_selector mobile-None">
            <ul>
              <li
                className={`pageresponsiveView ${
                  activeDevice === 'desktop' ? 'active' : ''
                }`}
                onClick={() => {
                  setActiveDevice('desktop');
                  if (typeof updateActiveDevice === 'function') {
                    updateActiveDevice('desktop');
                  }
                }}
              >
                <a>
                  <img className="black_icon" src={desktopBlack} />
                  <img className="blue_icon" src={desktopBlue} />
                </a>
              </li>
              <li
                className={`pageresponsiveView ${
                  activeDevice === 'tablet' ? 'active' : ''
                }`}
                onClick={() => {
                  setActiveDevice('tablet');
                  if (typeof updateActiveDevice === 'function') {
                    updateActiveDevice('tablet');
                  }
                }}
              >
                <a>
                  <img className="blue_icon" src={tabletBlack} />
                  <img className="black_icon" src={tabletBlue} />
                </a>
              </li>
              <li
                className={`pageresponsiveView ${
                  activeDevice === 'mobile' ? 'active' : ''
                }`}
                onClick={() => {
                  setActiveDevice('mobile');
                  if (typeof updateActiveDevice === 'function') {
                    updateActiveDevice('mobile');
                  }
                }}
              >
                <a>
                  <img className="black_icon" src={mobileblack} />
                  <img className="blue_icon" src={mobileblue} />
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="editingSubmitView">
          {showPreviewOption && (
            <select
              className="outlineButton mobile-None"
              value={selectedView}
              onChange={onPreviewClick}
            >
              <option value="guest">Preview as Guest</option>
              <option value="desktop">Preview Desktop</option>
              <option value="tablet">Preview Tab</option>
              <option value="mobile">Preview Mobile</option>
            </select>
          )}
          {showSaveBtn && (
            <Button className="saveButton mobile-None" onClick={onSaveFn}>
              {saveBtnLabel}
            </Button>
          )}
          {showActiveBtn && (
            <Button
              disabled={activeBtnDisabled}
              className="saveButton mobile-None"
              onClick={() => {
                if (typeof makeActive === 'function') {
                  makeActive(true);
                }
              }}
            >
              {activeBtnLabel}
            </Button>
          )}

          {showAddToLib && (
            <Button
              disabled={saveTempDisabled}
              className="saveButton mobile-None"
              style={{ filter: saveTempDisabled ? 'blur(0.5px)' : '' }}
              onClick={() => {
                if (typeof saveTemplateToLib === 'function') {
                  saveTemplateToLib(true);
                }
              }}
            >
              {addToLibBtnLabel}
            </Button>
          )}
          <a className="MobileSaveButton desktop-None">
            <i className="fa fa-check"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

PageToolBar.propTypes = {
  showPreviewFn: PropTypes.func.isRequired,
  onSaveFn: PropTypes.func.isRequired,
  showIcons: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    proutes: state.Proutes,
  };
};

export default connect(mapStateToProps)(PageToolBar);

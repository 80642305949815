import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'; // Import hooks from v6 
// Create a wrapper component to pass hooks into the class component
function ImportStatusModalWrapper(props) {
  const navigate = useNavigate();  
  const location = useLocation();  

  // Pass navigate and location as props to the class component
  return (
    <ImportStatusModal 
      navigate={navigate}
      location={location}
      {...props} 
    />
  );
}

class ImportStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
    };
  }

  // Use navigate prop instead of history
  handleRedirect = () => { 
    this.props.navigate('importlogs', {
      state: { from: this.props.location.pathname }
    });
  };

  render() {
    const { errors, loading } = this.state;

    return (
      <div>
        <h2>Import Status</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form>
              <div className="field-group inputField fullWidthInput">
                <div className="importContactss">
                  <label> Imported contacts: &nbsp;</label>
                  <p>{this.props.imported || 0} </p>
                </div>
              </div>
              <div className="field-group inputField fullWidthInput">
                <div className="importContactss">
                  <label> Rejected contacts: &nbsp;</label>
                  <p>{this.props.rejected || 0} </p>
                </div>
              </div>
              {this.props.rejected && this.props.rejected > 0 ? (
                <div className="editProfileBtn submitForm justify-content-center mt-4">
                  <Button
                    type="button"
                    className="themeBlueButton"
                    onClick={this.handleRedirect} // Call the updated redirect function
                  >
                    View Rejected Report
                  </Button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

// Export the wrapper component that includes the necessary hooks for v6
export default ImportStatusModalWrapper;

import React, { Component } from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { Button } from '@mui/material'
import SaveTheDate from '../../assets/images/savethedate.jpg'
import { createNotification, redirectTo } from '../../helpers'
import { get } from 'lodash'
import { getEventTemplates } from 'ReduxStore/API'
import ReactHtmlParser from 'react-html-parser'
import Loader from '../../Components/Loader/loader'

class ChoosePageDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: 0,
      loading: false,
      selectedTemplate: 0,
    }
  }

  componentDidMount() {
    const eventId = get(this.props, 'match.params.eventId')
    const type = this.props.type
    const params = { type: type.toLowerCase(), eventId, defaultTemplate: true }
    getEventTemplates(params).then((result) => {
      if (result.data.success) {
        const templates = result.data.templates || {}
        const editRoute =
          type === 'Registration'
            ? `edit-event-form/eid/${eventId}/tid`
            : type === 'Login'
            ? `edit-login-page/eid/${eventId}/tid`
            : `edit-event-form/eid/${eventId}/tid`

        this.setState({ templates, showLoader: false, editRoute })
      }
    })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  editTemplate = () => {
    let { editRoute, selectedTemplate } = this.state
    if (selectedTemplate !== 0) {
      redirectTo(
        this.props.navigate,
        `${editRoute}/${selectedTemplate}`,
        this.props.location.pathname,
      )
    } else {
      createNotification('error', 'Please select template first.')
    }
  }

  selectTemplate = (templateId) => {
    this.setState({ selectedTemplate: templateId })
  }

  render() {
    const {
      title,
      pageTitle,
      eventId,
      selectedTemplate,
      templates,
    } = this.state
    const { type } = this.props
    return (
      <DashboardLayout title={`Form`} pageTitle={`Form design`}>
        {!templates ? (
          <Loader />
        ) : (
          <div className="dashboardMiddleArea">
            <div className="createProjectAccount projectStepRow mt-0 mobileBaseMargin20px">
              <div className="projectCreateSection">
                <div className="CreatePresentation">
                  <div className="accountTypeTitle">
                    <h1>Choose form design:</h1>
                  </div>
                  <div className="edittemplateCards">
                    <div className="editTcardList">
                      {type !== 'Login' && (
                        <div
                          className={
                            selectedTemplate !== 0 &&
                            selectedTemplate === 'blank'
                              ? 'editTemplateBox EDTActive'
                              : 'editTemplateBox'
                          }
                          key="followUp"
                        >
                          <div
                            className="editTemplateInnerB CRCircularBox"
                            onClick={() => this.selectTemplate('blank')}
                          >
                            <h3>Blank</h3>
                            <div className="editEmailCampaignBlank">
                              <div className="EcbInnerBox">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                            {/* <>{ ReactHtmlParser(ReactHtmlParser(blankHtml)) }</>  */}
                          </div>
                          <h3
                            className="selectTText"
                            onClick={() => this.selectTemplate('blank')}
                          >
                            {''}
                          </h3>
                        </div>
                      )}
                      {templates.map((template) => {
                        return (
                          <div
                            className={
                              selectedTemplate === template._id
                                ? 'editTemplateBox EDTActive'
                                : 'editTemplateBox'
                            }
                            key="saveTheDate"
                            onClick={() => this.selectTemplate(template._id)}
                          >
                            <div className="editTemplateInnerB CRCircularBox">
                              <h3>{template.templateName}</h3>
                              <div className="EditEmailCampaignImg">
                                {/*<img src={SaveTheDate} alt="Email Campaign"/>*/}
                                {ReactHtmlParser(template.html || '')}
                              </div>
                            </div>
                            <h3
                              className="selectTText"
                              onClick={() => this.selectTemplate(template._id)}
                            >
                              {''}
                            </h3>
                          </div>
                        )
                      })}
                    </div>
                    <div className="submitForm mt-3 justify-content-center">
                      <Button
                        type="button"
                        className="themeBlueButton"
                        onClick={this.editTemplate}
                      >
                        Use Template
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ChoosePageDesign)

import { Box, } from '@mui/material';
import GridItem from './GridItem';
import { MessageStyle } from 'ReduxStore/Reducers/aiChatbot.reducer';
import Grid from '@mui/material/Grid2';

const MessageCustomStyle = ({ message }: { message?: MessageStyle }) => {
  if (!message) return null;
  return (
    <>
      <Box component="div" className="inputField" mt={3}>
        <label>Message Padding (px)</label>
      </Box>

      <Grid container spacing={1}>
        <Grid size={{xs: 12,}}></Grid>
        <GridItem
          label={'Top'}
          value={message?.padding?.top}
          path={['agentMessage', 'padding', 'top']}
        />
        <GridItem
          label={'Right'}
          value={message.padding?.right}
          path={['agentMessage', 'padding', 'right']}
        />{' '}
        <GridItem
          label={'Bottom'}
          value={message.padding?.bottom}
          path={['agentMessage', 'padding', 'bottom']}
        />
        <GridItem
          label={'Left'}
          value={message.padding?.left}
          path={['agentMessage', 'padding', 'left']}
        />
      </Grid>

      <Box component="div" className="inputField" mt={3}>
        <label>Message Font (px)</label>
      </Box>
      <Grid container spacing={1}>
        <GridItem
          label={'Font Size'}
          value={message.font?.size}
          path={['agentMessage', 'font', 'size']}
          // xs={4}
        />
         <GridItem
          label={'Message Spacing'}
          value={message.spacing}
          path={['agentMessage', 'spacing']}
          // xs={4}
        />
        <GridItem
          label={'Time Font Size'}
          value={message.timeFont?.size}
          path={['agentMessage', 'timeFont', 'size']}
          // xs={4}
        />
       
      </Grid>
    </>
  );
};

const MessageRadius = ({
  message,
  messageType,
}: {
  message?: MessageStyle;
  messageType: string;
}) => {
  if (!message) return null;
  return (
    <>
      <Grid container spacing={1}>
        <Grid size={{xs: 12,}}></Grid>
        <GridItem
          label={'Top Left'}
          value={message?.borderRadius?.topLeft}
          path={[messageType, 'borderRadius', 'topLeft']}
        />
        <GridItem
          label={'Top Right'}
          value={message.borderRadius?.topRight}
          path={[messageType, 'borderRadius', 'topRight']}
        />{' '}
        <GridItem
          label={'Bottom Right'}
          value={message.borderRadius?.bottomRight}
          path={[messageType, 'borderRadius', 'bottomRight']}
        />
        <GridItem
          label={'Bottom Left'}
          value={message.borderRadius?.bottomLeft}
          path={[messageType, 'borderRadius', 'bottomLeft']}
        />
      </Grid>
    </>
  );
};
export { MessageCustomStyle, MessageRadius };

//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { Button, Tooltip } from '@mui/material';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { rgbToHex2 } from 'helpers/common';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { theme } from '../../../../theme/theme';
import AddNewState from './AddOrUpdateState';
import { useDispatch, useSelector } from 'react-redux';
import {
  OpportunityState,
  OpportunityStates,
  OpportunityStates_State
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { Actions } from 'ReduxStore/Actions';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import Loader from 'Components/Loader/loader';
import { useLocation } from 'react-router';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import info from '../../../../assets/images/icons/informationIcon.svg';
// Props for ManageCustomFields component
type ManageStatesProps = {
  handleCloseManageStates: () => void;
  showManageStates: boolean;
  from: string;
};

// Component for managing custom fields
export default function ManageStates({
  handleCloseManageStates,
  showManageStates,
  from,
}: ManageStatesProps) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { opportunityStates: opportunityStatesFromRedux, loading } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;
  const [openAddNewOpportunityStateModal, setOpenAddNewOpportunityStateModal] = useState(false);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState(false);
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const [opportunityStateToEdit, setOpportunityStateToEdit] = useState<
    OpportunityState | undefined
  >({} as OpportunityState);

  const [opportunityStates, setOpportunityStates] = useState<OpportunityStates>([] as OpportunityStates);

  useEffect(() => {
    let states = opportunityStatesFromRedux.filter(state => state.stateType !== 'Draft');
    setOpportunityStates(states);
  }, [opportunityStatesFromRedux]);

  const handleCloseAddNewOpportunityStateModal = () => {
    setOpenAddNewOpportunityStateModal(false);
    resetAddNewOpportunityStateModal();
  };

  const handleOpenAddNewOpportunityStateModal = (
    isBeingEdited: boolean = false,
    opportunityState?: OpportunityState,
  ) => {
    setOpenAddNewOpportunityStateModal(true);
    if (isBeingEdited) {
      setIsBeingEdited(isBeingEdited);
      setOpportunityStateToEdit(opportunityState);
    }
  };

  const handleDeleteOpportunityState = () => {
    const currentTab = location.pathname.split('/').at(-1);
    const queryParams = {

      opportunityStateType: currentTab
    };
    let data = {
      bodyParams: {
        _id: idToDelete
      },
      queryParams: queryParams
    };
    dispatch(Actions.deleteOpportunityStateRequest(data));
    closeDeleteConfirmationModel();
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
    setIdToDelete('');
  };

  const handleOpenDeleteConfirmationModal = (id: string | undefined) => {
    setIdToDelete(id);
    setOpenDeleteConfirmationModel(true);
  };

  const resetAddNewOpportunityStateModal = () => {
    setOpportunityStateToEdit({} as OpportunityState);
    setIsBeingEdited(false);
  };


  const onDragEnd = (result: DropResult) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    // Reorder the list
    const movedItems = reorder(
      opportunityStates, // Array to reorder
      result.source.index, // Source index
      result.destination.index // Destination index
    );
    setOpportunityStates(movedItems);
    updateOpportunityStatesOrder(movedItems);
  };

  const updateOpportunityStatesOrder = (items: OpportunityStates) => {
    const currentTab = location.pathname.split('/').at(-1);
    const queryParams = {
      opportunityStateType: currentTab
    };

    let opportunityStates = items.map(state => {
      return {
        _id: state._id,
        orderIndex: state.orderIndex,
      };
    });

    let data = {
      bodyParams: {
        opportunityStates
      },
      queryParams: queryParams
    };
    dispatch(Actions.updateOpportunityStatesOrderRequest(data));
  };

  // Reorders the list when an item is dragged and dropped
  const reorder = (list: OpportunityState[], startIndex: number, endIndex: number): OpportunityState[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log('result-- ', result);
    return result.map((obj, i) => ({ ...obj, orderIndex: i }));
  };

  // Styles for the draggable item
  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
    background: isDragging ? "#fafafa" : "white",
    border: isDragging ? "1px dashed #5141e7" : "none",
    ...draggableStyle
  });

  return (
    <ThemeProvider theme={theme}>
      <>{console.log("opportunityStates", opportunityStates)}</>
      <Modal
        open={showManageStates}
        onClose={handleCloseManageStates}
        classNames={{
          overlay: 'full-screen_overlay',
          modal: 'full-screen_modal manage-custom-fields',
        }}
        center
      >
        <Grid container justifyContent="end" width={"100%"}>
          <Grid
            size={{
              xs: 12, md: 6,
              lg: 5,
              xl: 4
            }}

            className="full-screen_modal__form"
          >
            <Box component="div" className="full-screen_modal__form_header">
              <h3 className="subtitle">Manage {from === "opportunity" ? "Opportunities" : from === "accounts" ? "Accounts" : "Contacts"} Custom States</h3>
            </Box>
            <Box
              component="div"
              px={3}
              py={2}
              className="full-screen_modal__form_body"
            >
              <Grid container justifyContent="between" alignItems="center">
                <Grid size={{xs: "grow",}}>
                  <Box component="p" className="paragraph">
                    Custom States ({opportunityStates.length})
                  </Box>
                </Grid>
                <Grid >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => handleOpenAddNewOpportunityStateModal()}
                  >
                    Add New State
                  </Button>
                </Grid>
              </Grid>

              <TableContainer
                className="custom-fields-table"
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell className="tableHeadCell long-text" >
                        State Label
                      </TableCell>
                      <TableCell className="tableHeadCell" align="left" style={{ maxWidth: '150px' }}>
                        Type
                      </TableCell>
                      <TableCell className="tableHeadCell" align="left" style={{ maxWidth: '100px' }}>
                      </TableCell>
                      <TableCell className="tableHeadCell" align="right" style={{ maxWidth: '90px' }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided: any, snapshot: any) => (
                        <TableBody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {Array.isArray(opportunityStates) &&
                            opportunityStates.length > 0 ? (
                            opportunityStates.map((state, index) => (
                              <Draggable
                                key={state._id}
                                draggableId={"q-" + state._id}
                                index={index}
                              >
                                {(provided: any, snapshot: { isDragging: boolean; }) => (
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}

                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    className={`${(snapshot.isDragging ? 'dragging' : '')}`}
                                  >
                                    <TableCell className={`tableBodyCell`} component="th" scope="row" >
                                      <Stack direction="row" spacing={1.5} alignItems={'center'}>
                                        <Box sx={{ marginLeft: '-12px !important' }} {...provided.dragHandleProps}>
                                          <DragIndicatorIcon fontSize='inherit' />
                                        </Box>
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                          <Box component={'span'} className='table-status'
                                            sx={{ color: `${state?.textColor ? rgbToHex2(state?.textColor) : "#000056"} !important`, bgcolor: `${state?.textColor ? rgbToHex2(state?.backgroundColor) : "#ffffff00"}` }}
                                          >
                                            {state.name}
                                          </Box>
                                          {state.description &&
                                            <Tooltip title={state.description} arrow placement='bottom-start' >
                                              <img src={info} alt='description' />
                                            </Tooltip>
                                          }
                                        </Stack>
                                      </Stack>
                                    </TableCell>
                                    <TableCell className={`tableBodyCell`} >
                                      {state.stateType}
                                    </TableCell>
                                    <TableCell className={`tableBodyCell`} >
                                      {state.isDefault &&
                                        "Default"
                                      }
                                    </TableCell>
                                    <TableCell className={`tableBodyCell`} align="right">
                                      <Box
                                        className="action-button"
                                        component="span"
                                        mr={1.5}
                                      >
                                        <EditIcon
                                          style={{ fontSize: 18, color: '#777777' }}
                                          onClick={() => handleOpenAddNewOpportunityStateModal(true, state)}
                                        />
                                      </Box>
                                      <Box
                                        className="action-button"
                                        component="span"
                                        onClick={() =>
                                          handleOpenDeleteConfirmationModal(
                                            state?._id,
                                          )
                                        }
                                      >
                                        <DeleteIcon
                                          style={{ fontSize: 18, color: '#777777' }}
                                        />
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3} className="tableBodyCell" align="center">
                                There are no records to display
                              </TableCell>
                            </TableRow>
                          )}
                          {provided.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      {openAddNewOpportunityStateModal && (
        <AddNewState
          handleCloseAddNewOpportunityStateModal={handleCloseAddNewOpportunityStateModal}
          openAddNewOpportunityStateModal={openAddNewOpportunityStateModal}
          isBeingEdited={isBeingEdited}
          opportunityStateToEdit={opportunityStateToEdit as OpportunityState}
          from={from}
        />
      )}
      {openDeleteConfirmationModel && (
        <ConfirmationAlertPopUp
          openModal={openDeleteConfirmationModel}
          closeModalFunc={closeDeleteConfirmationModel}
          title={'Delete Confirmation!'}
          text={'Are you sure you want to delete it?'}
          confirmationButtonText={'Delete'}
          confirmationButtonColor="secondary"
          closeButtonText={'Cancel'}
          functionality={handleDeleteOpportunityState}
        />
      )}
      {loading && <Loader />}
    </ThemeProvider>
  );
}
import { Badge} from '@mui/material';
import { withStyles } from '@mui/styles';


const styles = () => ({
    root: {
       
    },

    badge: {
        top: '50%',
        right: '-25px',
        borderRadius: 4,
        width: 34,
        height: 34,
        transform: 'translate(50%, -50%)',
    }

});
const SquareBadge = ({ color, badgeContent, children, classes }) => {
    return (
        <Badge
            classes={{ root: classes.root, badge: classes.badge }}
            color={color}
            badgeContent={badgeContent}
            >
            {children}
        </Badge>
    );
};

export default withStyles(styles)(SquareBadge);
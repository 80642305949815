//@ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../theme/theme';
import '../../sass/main.scss';
import DashboardLayout from 'Layouts/DashboardLayout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Box } from '@mui/material';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import GmailView from './gmailView';
import Loader from 'Components/Loader/loader';
import { xpAccountIntegrationGmailGetGmailId, xpAccountIntegrationGmailGetList } from 'ReduxStore/API';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import { useSelector } from 'react-redux';
import Compose from './Compose';
import 'react-quill/dist/quill.snow.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import EmailList from './EmailList';
import { createNotification } from 'helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

export interface GmailData {
  success?: boolean;
  message?: string;
  data?: { _id: string, email: string, message: { [key: string]: string; }; }[];
  totalMailCount?: number;
}

const Emails = () => {
  const navigate = useNavigate();
  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [payloadForDraft, setPayloadForDraft] = useState({});
  const [messageIdForDraft, setMessageIdForDraft] = useState({});
  const [draftId, setDraftId] = useState("");
  const [editorText, setEditorText] = useState('');
  const [viewCompose, setViewCompose] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tempStart, setTempStart] = useState<number>(0);
  const [gmailData, setGmailData] = useState<GmailData>({});
  const [selectedGmailToView, setSelectedGmailToView] = useState<any>(null);
  const [isGmailInView, setIsGmailInView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gmailId, setGmailId] = useState("");
  const [selectedEmailIndex, setSelectedEmailIndex] = useState<number | null>(null);

  const [cc, setCC] = useState<boolean>(false);
  const [bcc, setBcc] = useState<boolean>(false);

  useEffect(() => {
    getGmailDetails();
  }, []);

  const getGmailDetails = async () => {
    try {
      const result: any = await xpAccountIntegrationGmailGetGmailId();
      if (result.status === 200 && result.data.success) {
        setGmailId(result.data.details.appData.user.email);
      }
    } catch (error) {
      console.log("error");
      if (error instanceof Error) {
        createNotification("error", error.message);
      } else {
        createNotification("error", "An unexpected error occurred.");
      }
    }
  };

  const handleOpenCC = () => {
    setCC(true);
  };
  const handleCloseCC = () => {
    setCC(false);
  };
  const handleOpenBCC = () => {
    setBcc(true);
  };
  const handleCloseBCC = () => {
    setBcc(false);
  };

  // Define toolbar modules with alignment and custom attachment handler
  const modules: QuillToolbarModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }], // Text alignment
        ['link', 'image'], // Default image handler
        ['attachment'], // Custom attachment button
        ['clean'], // Removes formatting
      ],
    },
  };

  const {
    message: xpUsersWebSocketMessage
  } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  // variables for pagination started
  const [limit, setLimit] = useState<number>(10);
  const [page_no, setPage_no] = useState<number>(1);
  const [mailType, setMailType] = useState<'INBOX' | 'DRAFT' | 'SENT' | 'TRASH'>('INBOX');

  useEffect(() => {
    getGmailData();
  }, [mailType]);

  const getGmailData = async (limit = 10, page_no = 1, showLoader = true) => {
    if (showLoader) {
      setIsLoading(true);
    }
    const queryParams = {
      page_no: page_no,
      limit: limit,
      type: mailType,
    };
    const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
      queryParams,
    );
    setIsLoading(false);
    if (gmailDataResult?.data) {
      setGmailData(gmailDataResult.data);
    }
  };


  const viewGmailContent = (gmail: any, i: number) => {
    setSelectedEmailIndex(i);  // Track the selected email index
    setSelectedGmailToView(gmail);
    setIsGmailInView(true);
    // Adjust tempStart based on pagination
    setTempStart(page_no > 1 ? (page_no - 1) * limit + i + 1 : i + 1);
  };

  const viewDraftMailsInCompose = (gmail: any) => {
    setViewCompose(true);
    console.log("draft===========gmail", gmail)
    const subjectDetail = gmail?.payload?.headers.find(detail => detail.name === 'Subject');
    const sendTo = gmail?.payload?.headers.find(detail => detail.name === 'To');
    const { payload } = gmail;
    console.log('=========payload', payload)
    setPayloadForDraft(payload);
    setMessageIdForDraft(gmail.id);
    setDraftId(gmail.draftId);
    setSubject(subjectDetail?.value);
    setRecipients(sendTo?.value);
    setEditorText(gmail?.snippet);
  };



  const handlePreviousMail = () => {
    if (selectedEmailIndex !== null && gmailData.data && selectedEmailIndex > 0) {
      const previousIndex = selectedEmailIndex - 1;
      setSelectedEmailIndex(previousIndex);
      viewGmailContent(gmailData.data[previousIndex].messages, previousIndex);
    }
  };

  const handleNextMail = () => {
    if (
      selectedEmailIndex !== null && gmailData.data &&
      selectedEmailIndex < gmailData.data.length - 1
    ) {
      const nextIndex = selectedEmailIndex + 1;
      setSelectedEmailIndex(nextIndex);
      viewGmailContent(gmailData.data[nextIndex].messages, nextIndex);
    }
  };


  const closeGmailContent = () => {
    setSelectedGmailToView(null);
    setIsGmailInView(false);
  };

  useEffect(() => {
    console.log('xpUsersWebSocketMessage', xpUsersWebSocketMessage);
    if (xpUsersWebSocketMessage.message == 'Gmail inbox list is updated') {
      if (page_no === 1) {
        getGmailData(limit, page_no, false);
      }
    }
  }, [xpUsersWebSocketMessage]);

  const handleNavigation = (path: string) => {
    setViewCompose(false);
    setIsGmailInView(false);
    setSubject("");
    setRecipients("");
    setEditorText("");
    navigate(path);
  };

  const handleBackFromCompose = () => {
    setViewCompose(false);
  };

  return (
    <>
      <DashboardLayout title="Emails" pageTitle="Emails">
        <ThemeProvider theme={theme}>
          <div className="dashboard-containor">
            <div className="container-with-sidebar">
              <h3 className="heading"> Emails </h3>

              <Tabs defaultIndex={1}>
                <div className="project-sidebar">
                  <TabList>
                    <Tab value="1" onClick={() => handleNavigation('/emails')}>
                      <Box py={1} pl={'6px'}>
                        <EditOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Compose
                      </a>
                    </Tab>
                    <Tab value="2" onClick={() => {
                      setMailType("INBOX");
                      handleNavigation('/emails');
                    }}>
                      <Box py={1} pl={'6px'}>
                        <InboxOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Inbox
                      </a>
                    </Tab>
                    <Tab value="3" onClick={() => {
                      setMailType("DRAFT");
                      handleNavigation('/emails');
                    }}>
                      <Box py={1} pl={'6px'}>
                        <DraftsOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Drafts
                      </a>
                    </Tab>
                    <Tab value="4" onClick={() => {
                      setMailType("SENT");
                      handleNavigation('/emails');
                    }}>
                      <Box py={1} pl={'6px'}>
                        <SendOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Sent
                      </a>
                    </Tab>
                    <Tab value="5" onClick={() => {
                      setMailType("TRASH");
                      handleNavigation('/emails');
                    }}>
                      <Box py={1} pl={'6px'}>
                        <DeleteOutlineOutlinedIcon />
                      </Box>{' '}
                      <a>
                        Trash
                      </a>
                    </Tab>

                  </TabList>
                </div>

                {gmailData.success ? (
                  <div className="container-sidebar-right">
                    {(!isGmailInView && !viewCompose) ? (
                      <>
                        <TabPanel value="1">
                          <Compose
                            gmailId={gmailId}
                            recipients={recipients}
                            subject={subject}
                            editorText={editorText}
                            cc={cc}
                            bcc={bcc}
                            modules={modules}
                            handleOpenCC={handleOpenCC}
                            handleCloseCC={handleCloseCC}
                            handleOpenBCC={handleOpenBCC}
                            handleCloseBCC={handleCloseBCC}
                            setRecipients={setRecipients}
                            setSubject={setSubject}
                            setEditorText={setEditorText}
                            payload={payloadForDraft}
                            setPayload={setPayloadForDraft}
                            messageIdForDraft={null}
                            draftId={null}
                          />
                        </TabPanel>
                        <TabPanel value="2">
                          <EmailList
                            limit={limit}
                            setLimit={setLimit}
                            page_no={page_no}
                            setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            mailType={mailType}
                            viewDraftMailsInCompose={viewDraftMailsInCompose}

                          />
                        </TabPanel>
                        <TabPanel value="3">
                          <EmailList
                            limit={limit}
                            setLimit={setLimit}
                            page_no={page_no}
                            setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            mailType={mailType}
                            viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                        <TabPanel value="4">
                          <EmailList
                            limit={limit}
                            setLimit={setLimit}
                            page_no={page_no}
                            setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            mailType={mailType}
                            viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                        <TabPanel value="5">
                          <EmailList
                            limit={limit}
                            setLimit={setLimit}
                            page_no={page_no}
                            setPage_no={setPage_no}
                            gmailData={gmailData}
                            getGmailData={getGmailData}
                            viewGmailContent={viewGmailContent}
                            gmailId={gmailId}
                            mailType={mailType}
                            viewDraftMailsInCompose={viewDraftMailsInCompose}
                          />
                        </TabPanel>
                      </>
                    ) : isGmailInView ? (
                      <>
                        <GmailView
                          thread={selectedGmailToView}
                          closeGmailContent={closeGmailContent}
                          totalCount={gmailData?.totalMailCount}
                          tempStart={tempStart}
                          handlePreviousMail={handlePreviousMail}
                          handleNextMail={handleNextMail}
                          gmailId={gmailId}
                          getGmailData={getGmailData}
                          modules={modules}
                        />
                      </>
                    ) : viewCompose ? <>
                      <Compose
                        gmailId={gmailId}
                        recipients={recipients}
                        subject={subject}
                        editorText={editorText}
                        cc={cc}
                        bcc={bcc}
                        modules={modules}
                        handleOpenCC={handleOpenCC}
                        handleCloseCC={handleCloseCC}
                        handleOpenBCC={handleOpenBCC}
                        handleCloseBCC={handleCloseBCC}
                        setRecipients={setRecipients}
                        setSubject={setSubject}
                        setEditorText={setEditorText}
                        viewCompose={viewCompose}
                        handleBackFromCompose = {handleBackFromCompose}
                        payload={payloadForDraft}
                        setPayload={setPayloadForDraft}
                        messageIdForDraft={messageIdForDraft}
                        draftId={draftId}
                      />
                    </> : <></>}
                  </div>
                ) : (
                  <>{gmailData.message}</>
                )}
              </Tabs>
            </div>
          </div>
        </ThemeProvider>
      </DashboardLayout>
      {isLoading && <Loader />}
    </>
  );
};

export default Emails;

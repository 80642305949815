import { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import axios from "axios";
import { createNotification } from "helpers";
const URL = process.env.REACT_APP_API_URL
import withCustomRouter from '../../Common/withCustomRouter'; 
/**
 * @class AutoLogin
 * @description -Used to wix AutoLogin
*/
class AutoLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hash: '',
      userData: {},
      errors: {},
      loading: false,
    }
  }

  /**
  * @function componentDidMount
  * @description - this is the default function and called at first
  * 
  */
  componentDidMount() {
    const params =  this.props.params;
    const userData = {
      hash: params.hash
    }
    this.setAutoLogin(userData);
  }

  /**
   * @function setAutoLogin
   * @description -function is called to get user details and login by hash passed from wix
   * 
  */
  async setAutoLogin(userData) {
    const loginResponse = await this.props.autoLogin(userData)
    this.handleLoading()
  }

  /**
   * @function handleLoading
   * @description -function is handling loading 
   * @return {boolean}
  */
  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  /**
   * @function componentDidUpdate
   * @description -It is default function of react and called when component get updated 
   * @return {states}
  */
  async componentDidUpdate(prevProps, prevState) {
    if(this.props.autoLoginState.success && !prevProps.autoLoginState.success){ 
      await this.getServicePlanId()
    }
  }

  getServicePlanId = async () => {
    try {
      const users = await JSON.parse(localStorage.getItem('user_details'))
      console.log("in getServicePlanId users~~~~~~",users)
      const res = await axios.get(`${URL}/addServicePlanInIllumeetXP`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      }).catch(err => { 
        throw new Error(err.message)
      }) 
      let servicePlanId
      if (res.status === 200) {
          servicePlanId = res.data.servicePlanId
          let user_details = JSON.parse(localStorage.getItem('user_details'))
          user_details.servicePlanId = servicePlanId
          localStorage.setItem("user_details", JSON.stringify(user_details))
          this.props.navigate('/my-events')
      } else {
        createNotification("error", "Something went wrong")
      }
      
    } catch (error) { 
      console.log("error~~~~",error)
      createNotification("error", error)
    }
  }

  render() {
    const { loading } = this.state
    return (
      <div className="container">
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

/**
 * @function mapStateToProps
 * @description -It is default function of react used to map states to props 
 * @return {props}
*/
const mapStateToProps = (state) => ({
  autoLoginState: state.AutoLogin,
})


/**
 * @function mapActionsToProps
 * @description -It is default function of react used to map action to props
 * @return {props}
*/
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      autoLogin: Actions.autoLoginRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(AutoLogin))

import React, { useEffect } from 'react';
import { theme } from '../../theme/theme';
import {
    IconButton, 
    Box,
    Popper,
    Fade,
    ClickAwayListener,
    MenuList,
    MenuItem,
    FormControlLabel,
    Checkbox,

} from '@mui/material';
import { withStyles } from '@mui/styles';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack } from '@mui/material';
import closeIcon from '../../assets/images/icons/close.svg'

const styles = {
    selectBox: {
        background: '#EDECF5',
        borderRadius: '4px',
        padding: '1px 8px',
        color: '#000056',
        fontWeight: '400',
        fontSize: '14px',
        margin: '2px 5px 2px 0',
    },

    multiSelect: {
        padding: '8px 15px',
        border: '1px solid #CCCED5',
        borderRadius: '7px',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'capitalize',
        flexDirection: 'row',
    },
}


const CustomMultiSelect = ({ label, placeholder, options, getSelectedOptionsFromCustomSelect, value, index }) => {
   
    const [open, setOpen] = React.useState(false);
    const [selectedOptions, setSelectedOptions] = React.useState(value);
    const [homePage, setHomePage] = React.useState([]);
    const anchorRef = React.useRef(null);

    useEffect(() => {  // this is specifically for early access
        let homePageSelected = options.filter(obj => obj.isHomePage === true)
        setHomePage(homePageSelected)
        let homePageAlreadyExist = false
        selectedOptions.forEach(el => homePageAlreadyExist = el.isHomePage && true)
        !homePageAlreadyExist &&  setSelectedOptions([...selectedOptions, ...homePageSelected])
    }, [])

    useEffect(() => {
        getSelectedOptionsFromCustomSelect(selectedOptions, index)
    }, [selectedOptions])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleCheckbox = (e, option) => {
        if (!option.isHomePage) { // early access condition
            let arr = selectedOptions
            if (e.target.checked) {
                let arr2 = [...arr, option]
                setSelectedOptions(arr2)
            } else {
                let arr2 = arr.filter(opt => opt.label !== option.label)
                setSelectedOptions(arr2)
            }
        }
    }

    const removeFromSelectedOption = (toRemove) => {
        if (!toRemove.isHomePage) { // early access condition
            let arr = selectedOptions
            let arr2 = arr.filter(obj => obj.value !== toRemove.value)
            setSelectedOptions(arr2)
        }
    }

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedOptions(options)
        } else {
            setSelectedOptions(homePage) // early access condition // otherwise it should be set to blank array
        }
    }

    return (
        <Box component="div" style={{ position: 'relative', }} className='inputField' >
            <label>{label}</label>
            <Stack
                fullWidth
                style={styles.multiSelect}
                size='large'
                aria-haspopup="true"
                ref={anchorRef}
            >
                <Box display={'flex'} flexWrap={'wrap'}>
                    {/* selected menu */}
                    {  
                        selectedOptions?.length > 0 ? selectedOptions?.map((selected, i) => {
                            return (
                                <Stack
                                    style={styles.selectBox}
                                    direction={'row'}
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                >
                                    <Box>
                                        {selected.label}
                                    </Box>

                                    <Box>
                                        <IconButton style={{ padding: '7px', marginLeft: '5px', }} color="secondary" >
                                            <img alt='remove' width='10' src={closeIcon} onClick={() => removeFromSelectedOption(selected)} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            )
                        }) :
                            placeholder
                    }

                    {/* selected menu end*/}


                </Box>


                {/* open dropdown button */}

                <IconButton size="small" color="primary"

                    aria-controls={open ? 'menu-list-grow' : undefined}
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </IconButton>

            </Stack >

            <Popper
                open={open}
                style={{ right: 0 }} placement="bottom-start" transition
                anchorEl={anchorRef.current}
                role={undefined} disablePortal>
                {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250} >
                        <Box sx={{ maxHeight: '230px', overflow: 'scroll', backgroundColor: theme.palette.common.white, boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: '8px', }}>
                            <ClickAwayListener
                                onClickAway={handleClose}
                            >
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >

                                    <MenuItem className='checkbox-item'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox style={{ padding: 0, marginRight: '8px', }} color="primary" />
                                            }
                                            label='Select All'
                                            style={{ padding: 0, margin: 0, }}
                                            onClick={(e) => handleSelectAll(e)}
                                            checked={selectedOptions.length === options.length}

                                        />
                                    </MenuItem>
                                    {Array.isArray(options) && options.length > 0 && options.map(option => {
                                        return (
                                            <MenuItem className='checkbox-item'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox style={{ padding: 0, marginRight: '8px', }} color="primary" />
                                                    }
                                                    label={option.label}
                                                    style={{ padding: 0, margin: 0, }}
                                                    onClick={(e) => handleCheckbox(e, option)}
                                                    checked={selectedOptions.some(obj => obj.value === option.value) || option.isHomePage ? true : false} // second cond for early access
                                                    disabled={option.isHomePage}  // early access condition 
                                                />
                                            </MenuItem>

                                        )
                                    })}

                                </MenuList>
                            </ClickAwayListener>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </Box >
    );
};

export default withStyles(styles)(CustomMultiSelect);
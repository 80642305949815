import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
// import '../sass/main.scss';
import { ThemeProvider } from '@mui/material';
import { theme } from '../theme/theme';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, Tooltip } from '@mui/material';


export default function CustomFieldFilterDropDown({
  dropdownArray,
  filterBy,
  setFilterBy,
}) {
  const anchorRef1 = React.useRef(null);
  const [open1, setOpen1] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);

  const handleToggle1 = () => {
    setOpen1((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const handleSelectAllFilter = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setFilterBy(dropdownArray.map((status) => status._id));
    } else {
      setFilterBy([]);
    }
  };

  const handleAddToFilterBy = (e, _id) => {
    const checked = e.target.checked;
    if (checked) {
      setFilterBy((prev) => [...prev, _id]);
    } else {
      let arr = [...filterBy];
      arr = arr.filter((v) => v !== _id);
      setFilterBy(arr);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSettings(false);
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <Grid >
        
        <Box
          component={IconButton} 
          size='small'
          onClick={handleToggle1}
          ref={anchorRef1}
          className={filterBy.length && 'active'}
          sx={{
            backgroundColor: theme.palette.background.light,
            color: theme.palette.common.black,
            textTransform: 'capitalize',
            fontWeight: '400',

            '&:hover, &.active': {
              backgroundColor: '#5d2be3',
              color: theme.palette.primary.contrastText,
            },
          }}
          disabled={dropdownArray.length == 0 ? true : false}
        >
          <Tooltip title="Filter by Custom Fields" placement='left' arrow >
            <FilterListIcon />
          </Tooltip>
        </Box>
        <Popper
          open={open1}
          placement="bottom-start"
          transition
          anchorEl={anchorRef1.current}
          role={undefined}
          disablePortal
          style={{ zIndex: 3 }}
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Box
                className="option-list"
                style={{
                  backgroundColor: theme.palette.background.light,
                  borderRadius: 4,
                  marginTop: 10,
                  overflowY: 'scroll',
                  maxHeight: 230,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={openSettings}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem className="checkbox-item">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ padding: 0, marginRight: '5px' }}
                            color="primary"
                            onChange={(e) => handleSelectAllFilter(e)}
                            checked={filterBy.length === dropdownArray.length}
                          />
                        }
                        label={'All'}
                        style={{ padding: 0 }}
                      />
                    </MenuItem>
                    {dropdownArray.map((el) => {
                      return (
                        <MenuItem className="checkbox-item"
                          key={el._id}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{ padding: 0, marginRight: '5px' }}
                                color="primary"
                                onChange={(e) => handleAddToFilterBy(e, el._id)}
                                checked={filterBy.includes(el._id)}
                              />
                            }
                            label={el.fieldData.fieldName}
                            style={{ padding: 0 }}
                          />
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
      </Grid>
    </ThemeProvider>
  );
}

import React, { Component, createRef } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import editCircle from '../../assets/images/sidebar-icons/edit-circle.svg';
import downArrow from '../../assets/images/acor-down-arrow.svg';
import closeIcon from '../../assets/images/close-icon.svg';
import Drawer from '@mui/material/Drawer';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { connect } from 'react-redux';
import { createNotification } from '../../helpers';
import Loader from '../../Components/Loader/loader';
import { NotificationContainer } from "react-notifications";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { theme } from '../../theme/theme';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/material';
import config from '../../config/config';
import { FormControlLabel, ThemeProvider } from '@mui/material';
class Roles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      rightNew: false,
      roles: [],
      title: 'Roles & Permissions',
      pageTitle: 'Roles & Permissions',
      loading: false,
      role_name: "",
      permissions: [2],
      openDeleteConfirmation: false,
      selectedRole: null,
      errors: {},
      disable : []
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.roles.getRolessuccess !==
      prevProps.roles.getRolessuccess &&
      this.props.roles.getRolessuccess == true
     ) {
      this.setState({
        loading:false
      })
    }
    if (
      this.props.permissionsnames.success !==
      prevProps.permissionsnames.success &&
      this.props.permissionsnames.success == true
     ) {
      this.setState({
        loading:false
      })
    }

    if (
      this.props.permissionsnames &&
      this.props.permissionsnames.permissions
    ) if (
        this.props.roles.error &&
        this.props.roles.error === true && prevProps.roles.error === false &&
        this.props.roles.message !== prevProps.roles.message
      ) {
        createNotification('error', this.props.roles.message);
        this.setState({
          roles: { error: false },
          openDeleteConfirmation: false,
          loading:false
        })
      }

    if (this.props.roles.createRoleSuccess === true && prevProps.roles.createRoleSuccess === false && this.props.roles.message !== prevProps.roles.message) {
      this.setState({
        roles: { success: false },
      })
      createNotification('success', this.props.roles.message);
      this.setState({
        right: false,
        role_name: "",
        permissions: [],
        loading:false
      });
      this.props.getRoles();
      this.props.getPermissionList();
    }

    if (this.props.roles.editRoleSuccess === true && prevProps.roles.editRoleSuccess === false && this.props.roles.message !== prevProps.roles.message) {
      this.setState({
        roles: { success: false }
      })
      createNotification('success', this.props.roles.message);
      this.setState({
        rightNew: false,
        selectedRole: null,
        loading:false
      });
    }

    if (this.props.roles.deleteRoleSuccess === true && prevProps.roles.deleteRoleSuccess === false && this.props.roles.message !== prevProps.roles.message) {
      this.setState({
        openDeleteConfirmation: false,
        selectedRole: null,
        loading:false
      });
      createNotification('success', this.props.roles.message)

      if (
        this.state.errors &&
        Object.keys(this.state.errors).length > 0) {
        Object.keys(this.state.errors).forEach((item, key) => {
          createNotification('error', this.state.errors[item]);
          this.setState({ errors: {} });
        });
      }

    }
  }

  componentDidMount() {
    if (
      this.props.roles.roles &&
      Array.isArray(this.props.roles.roles) &&
      this.props.roles.roles.length > 0
    ) {
    } else {
      this.props.getRoles();
      this.props.getPermissionList({});
      this.setState({
        loading:true
      })
    }
    if (
      this.props.permissionsnames.permissions &&
      Array.isArray(this.props.permissionsnames.permissions) &&
      this.props.permissionsnames.permissions.length > 0
    ) {
      createNotification('success', 'Roles & Permission Listing succcessfull');
    } else {
      this.props.getPermissionList();
      this.setState({
        loading:true
      })
    }
  }

  handleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    })
  }

  handlePermissionAdd = (event) => {
    let { permissions } = this.state;
    if (event.target.checked) {
      if (permissions.indexOf(parseInt(event.target.id)) == -1) {
        permissions.push(parseInt(event.target.id));
      }

      //check edit if create permission is checked for unlayer purpose
      if (parseInt(event.target.id) == config.CREATE_PAGE_ID || parseInt(event.target.id) == config.EDIT_PAGE_ID) {
        if (permissions.indexOf(config.CREATE_PAGE_ID) == -1) {
          permissions.push(config.CREATE_PAGE_ID);
        }
        if (permissions.indexOf(config.EDIT_PAGE_ID) == -1) {
          permissions.push(config.EDIT_PAGE_ID);
        }
      }
      if (parseInt(event.target.id) == config.CREATE_CAMPAIGN_ID || parseInt(event.target.id) == config.EDIT_CAMPAIGN_ID) {
        if (permissions.indexOf(config.CREATE_CAMPAIGN_ID) == -1) {
          permissions.push(config.CREATE_CAMPAIGN_ID);
        }
        if (permissions.indexOf(config.EDIT_CAMPAIGN_ID) == -1) {
          permissions.push(config.EDIT_CAMPAIGN_ID);
        }
      }
    } else {
      let index = permissions.indexOf(parseInt(event.target.id));
      if (index > -1) {
        permissions.splice(index, 1)
      }

      //uncheck edit if create permission is unchecked for unlayer purpose
      if (parseInt(event.target.id) == config.CREATE_PAGE_ID || parseInt(event.target.id) == config.EDIT_PAGE_ID) {
        let index = permissions.indexOf(config.CREATE_PAGE_ID);
        if (index > -1) {
          permissions.splice(index, 1)
        }
        index = permissions.indexOf(config.EDIT_PAGE_ID);
        if (index > -1) {
          permissions.splice(index, 1)
        }
      }
      if (parseInt(event.target.id) == config.CREATE_CAMPAIGN_ID || parseInt(event.target.id) == config.EDIT_CAMPAIGN_ID) {
        let index = permissions.indexOf(config.CREATE_CAMPAIGN_ID);
        if (index > -1) {
          permissions.splice(index, 1)
        }
        index = permissions.indexOf(config.EDIT_CAMPAIGN_ID);
        if (index > -1) {
          permissions.splice(index, 1)
        }
      }
    }
    permissions.filter(n => {
      return n > 0
    });
    this.setState({
      permissions: permissions
    }
    ,()=>this.isDisabledPermission(event,this.state.permissions)
    )
  }

  handlePermissionEdit = (event) => {
    let { selectedRole } = this.state;
    let lv_permissions = [...selectedRole.permissions];
    if (event.target.checked) {
      if (lv_permissions.indexOf(parseInt(event.target.id)) == -1) {
        lv_permissions.push(parseInt(event.target.id));
      }

      //check edit if create permission is checked for unlayer purpose
      if (parseInt(event.target.id) == config.CREATE_PAGE_ID || parseInt(event.target.id) == config.EDIT_PAGE_ID) {
        if (lv_permissions.indexOf(config.CREATE_PAGE_ID) == -1) {
          lv_permissions.push(config.CREATE_PAGE_ID);
        }
        if (lv_permissions.indexOf(config.EDIT_PAGE_ID) == -1) {
          lv_permissions.push(config.EDIT_PAGE_ID);
        }
      }
      if (parseInt(event.target.id) == config.CREATE_CAMPAIGN_ID || parseInt(event.target.id) == config.EDIT_CAMPAIGN_ID) {
        if (lv_permissions.indexOf(config.CREATE_CAMPAIGN_ID) == -1) {
          lv_permissions.push(config.CREATE_CAMPAIGN_ID);
        }
        if (lv_permissions.indexOf(config.EDIT_CAMPAIGN_ID) == -1) {
          lv_permissions.push(config.EDIT_CAMPAIGN_ID);
        }
      }
    } else {
      let index = lv_permissions.indexOf(parseInt(event.target.id));
      if (index > -1) {
        lv_permissions.splice(index, 1)
      }

      //uncheck edit if create permission is unchecked for unlayer purpose
      if (parseInt(event.target.id) == config.CREATE_PAGE_ID || parseInt(event.target.id) == config.EDIT_PAGE_ID) {
        let index = lv_permissions.indexOf(config.CREATE_PAGE_ID);
        if (index > -1) {
          lv_permissions.splice(index, 1)
        }
        index = lv_permissions.indexOf(config.EDIT_PAGE_ID);
        if (index > -1) {
          lv_permissions.splice(index, 1)
        }
      }
      if (parseInt(event.target.id) == config.CREATE_CAMPAIGN_ID || parseInt(event.target.id) == config.EDIT_CAMPAIGN_ID) {
        let index = lv_permissions.indexOf(config.CREATE_CAMPAIGN_ID);
        if (index > -1) {
          lv_permissions.splice(index, 1)
        }
        index = lv_permissions.indexOf(config.EDIT_CAMPAIGN_ID);
        if (index > -1) {
          lv_permissions.splice(index, 1)
        }
      }
    }
    lv_permissions.filter(n => {
      return n > 0
    });

    selectedRole.permissions = lv_permissions
    this.setState({
      selectedRole,
    }
    ,()=>this.isDisabledPermission(event,this.state.selectedRole?.permissions)
    )
  }

  handleChange = (event, newValue) => {
    this.setValue(newValue);
  };

  handleRoleCreate = () => {
    if (this.isValid('add')) {
      let { role_name, permissions } = this.state
      role_name = role_name.trim()
      this.props.createRole({ role_name, permissions,  lngCode: this.props.users.newLangState })
      this.handleLoading()
    }
  }

  handleRoleEdit = () => {
    if (this.isValid('edit')) {
      const { selectedRole } = this.state
      const requestbody = { role_name: selectedRole.role_name, permissions: selectedRole.permissions , lngCode: this.props.users.newLangState }
      this.props.editRole({ _id: selectedRole._id, payload: requestbody })
      this.handleLoading()
    }
  }

  handleRoleName(event) {
    if (event.target.value.length > 0) {
      this.setState({
        role_name: event.target.value
      })
    } else {
      this.setState({
        role_name: ''
      })
    }
  }

  handleEditRoleName(event) {
    this.setState({
      selectedRole: {
        ...this.state.selectedRole,
        role_name: event.target.value
      }
    })
  }

  isValid = (formType) => {
    let error = {}
    let formIsValid = true
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (formType == 'add') {
      const { role_name, permissions } = this.state
      if (role_name === '' || role_name.trim().length === 0) {
        formIsValid = false
        error['email'] = '*Please enter Role Name.'
      }
      if (permissions.length === 0) {
        formIsValid = false
        error['permissions'] = '*Please select at least one permission.'
      }
    } else if (formType == 'edit') {
      const { selectedRole } = this.state
      if (selectedRole.role_name === '' || selectedRole?.role_name.trim().length === 0) {
        formIsValid = false
        error['email'] = '*Please enter Role Name.'
      }
      if (selectedRole?.permissions?.length === 0) {
        formIsValid = false
        error['permissions'] = '*Please select at least one permission.'
      }
    }
    this.setState({ errors: error })
    return formIsValid
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    let e ={
      target :{
        checked :true
      }
    };
    if(open){
    this.setState({ ...this.state, [anchor]: open },
      ()=>this.isDisabledPermission(e,this.state.permissions)
      );
    }else{
      this.setState({ ...this.state, [anchor]: open ,disable :[],errors:{}})
    }
  };

  toggleEditDrawer = (anchor, open, role = null) => (event) => {
    

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (role) {
      this.setState({
        selectedRole: { ...role }
      });
    }
    let e ={
      target :{
        checked :true
      }
    };
    if(open){
      this.state.selectedRole?.permissions.push(2);
      this.setState({ [anchor]: open },
        ()=>this.isDisabledPermission(e,this.state.selectedRole.permissions)
        );
    }else{
      this.setState({ [anchor]: open,disable :[],errors:{} })
    }
  }

  handlePermissionLevel(permissionCnt, lngObj) {
    let perCnt = permissionCnt[1] > 0 ? parseInt(permissionCnt[0] / permissionCnt[1] * 100) : 0
    if (perCnt >= 0 && perCnt <= 30) {
      return (
        <React.Fragment>
          <p> {lngObj?.low} </p>
          <div className="level-count bg-danger"></div>
        </React.Fragment>
      )
    }
    if (perCnt >= 31 && perCnt <= 60) {
      return (
        <React.Fragment>
          <p> {lngObj?.avg} </p>
          <div className="level-count bg-warning"></div>
          <div className="level-count bg-warning"></div>
        </React.Fragment>
      )
    }
    if (perCnt >= 61 && perCnt <= 80) {
      return (
        <React.Fragment>
          <p> {lngObj?.avg} </p>
          <div className="level-count bg-warning"></div>
          <div className="level-count bg-warning"></div>
          <div className="level-count bg-warning"></div>
        </React.Fragment>
      )
    }
    if (perCnt >= 81) {
      return (
        <React.Fragment>
          <p> {lngObj?.high} </p>
          <div className="level-count bg-green"></div>
          <div className="level-count bg-green"></div>
          <div className="level-count bg-green"></div>
          <div className="level-count bg-green"></div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <p>{lngObj?.low} </p>
        <div className="level-count bg-danger"></div>
      </React.Fragment>
    )
  }

  openDeleteConfirmationBox = (role) => {
    this.setState({
      openDeleteConfirmation: true,
      selectedRole: { ...role }
    })
  }

  closeDeleteConfirmation = () => {
    this.setState({
      openDeleteConfirmation: false,
      selectedRole: null
    })
  }

  deleteRole = () => {
    if (this.state.selectedRole) {
      this.props.deleteRole({ _id: this.state.selectedRole._id, role_id: this.state.selectedRole.role_id, lngCode: this.props.users.newLangState })
      this.handleLoading()
    }
  }

  optionsList = (val) => {
    let temp;
    switch (val) {
      case 'Projects': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.projects;   
      return temp;
      case 'Pages': temp = this.props.users.languageStateForRedux?.overviewTab?.pages;   
      return temp;
      case 'Campaigns': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsCard;   
      return temp;
      case 'Agenda': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.agenda;   
      return temp;
      case 'Notifications': temp = this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.notifications;   
      return temp;
      case 'Teams': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.teams;   
      return temp;
      case 'Attendees': temp = this.props.users.languageStateForRedux?.overviewTab?.attendees;   
      return temp;
      case 'Integration': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.integration;   
      return temp;
      case 'Gamification': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.gamification;   
      return temp;
      case 'Reports': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.reports;   
      return temp; 
      case 'AccessCodes': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.accessCodes;   
      return temp; 
      case 'Meeting': temp = this.props.users.languageStateForRedux?.formfields?.meetings?.meeting;   
      return temp;   

      case 'View Project': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewProject;
      return temp;
      case 'Edit Project': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editProject;
      return temp;
      case 'Publish/Unpublish Project': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.publishUnpublishProject;
      return temp;

      case 'Create Page': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createPage;
      return temp;
      case 'View Pages': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewPages;
      return temp;
      case 'Edit Page': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editPage;
      return temp;
      case 'Delete Page': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deletePage;
      return temp;
      case 'Edit Page Settings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editPageSettings;
      return temp;

      case 'Create Campaign': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createCampaign;
      return temp;
      case 'View Campaigns': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewCampaigns;
      return temp;
      case 'Edit Campaign': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editCampaign;
      return temp;
      case 'Delete Campaign': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteCampaign;
      return temp;

      case 'Create Session': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createSession;
      return temp;
      case 'View Sessions': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewSessions;
      return temp;
      case 'Edit Session': temp = this.props.users.languageStateForRedux?.actions?.agenda?.edit;
      return temp;
      case 'Delete Session': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteSession;
      return temp;

      case 'Create Notification': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createNotification;
      return temp;
      case 'View Notifications': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewNotifications;
      return temp;
      case 'Edit Notification': temp = this.props.users.languageStateForRedux?.actions?.notifications?.edit;
      return temp;
      case 'Delete Notification': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteNotification;
      return temp;

      case 'Edit Members': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editMembers;
      return temp;
      case 'View Members': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewMembers;
      return temp;

      case 'Create Attendee': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createAttendee;
      return temp;
      case 'View Attendees': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewAttendees;
      return temp;
      case 'Edit Attendee': temp = this.props.users.languageStateForRedux?.formfields?.attendees?.edit;
      return temp;
      case 'Delete Attendee': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteAttendee;
      return temp;
      case 'CheckIn & CheckOut Attendee': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.checkInOutAttendee;
      return temp;
      case 'Import Attendee': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.importAttendee;
      return temp;
      case 'Export Attendees': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.exportAttendees;
      return temp;
      case 'View Outbound Emails Reports': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewOutboundEmailReports;
      return temp;
      case 'Edit Custom Fields': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editCustomFields;
      return temp;
      case 'Edit Groups': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editGroups;
      return temp;
      case 'Edit Transactional Email Templates': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editTransEmailTemplates;
      return temp;
      case 'Reset Password': temp = this.props.users.languageStateForRedux?.actions?.attendees?.resetPass;
      return temp;
      case 'Add Kiosk User': temp = this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalHeading;
      return temp;
      case 'View Badge': temp = this.props.users.languageStateForRedux?.actions?.attendees?.viewBadge;
      return temp;

      case 'Webhooks': temp = this.props.users.languageStateForRedux?.actions?.integration?.webhooks;
      return temp;
      case 'App Integrations': temp = this.props.users.languageStateForRedux?.actions?.integration?.appInt;
      return temp;
      case 'Helpdesk - Admin': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.helpdeskAdmin;
      return temp;
      case 'Helpdesk - Agent': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.helpdeskAgent;
      return temp;

      case 'View Gamification': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewGamification;
      return temp;
      case 'Edit Gamification': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editGamification;
      return temp;
      case 'Export Gamification': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.exportGamification;
      return temp;
      case 'Add Manual Points': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.addManualPoints;
      return temp;

      case 'View Live Dashboard': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewLiveDashboard;
      return temp;

      case 'Create AccessCodes': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createAccessCodes;
      return temp;
      case 'View AccessCodes': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewAccessCodes;
      return temp;
      case 'Edit AccessCodes': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editAccessCodes;
      return temp;
      case 'Delete AccessCodes': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteAccessCodes;
      return temp;

      case 'Create Meeting For All': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.meetingForAll;
      return temp;
      case 'Create Meeting For My Team': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.meetingForMyTeam;
      return temp;
      case 'Create Meeting For My Own': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.meetingForOwn;
      return temp;
      case 'Edit All Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editAllMeetings;
      return temp;
      case 'Edit My Team Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editMyTeamMeetings;
      return temp;
      case 'Edit My Own Meeting': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editOwnMeeting;
      return temp;
      case 'View All Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewAllMeeting;
      return temp;
      case 'View My Team Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewTeamMeetings;
      return temp;
      case 'View My Own Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewOwnMeetings;
      return temp;
      case 'Delete All Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteAllMeetings;
      return temp;
      case 'Delete My Team Meetings': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteTeamMeetings;
      return temp;
      case 'Delete My Own Meeting': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteOwnMeeting;
      return temp;
      case 'Meeting Setting': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.meetingSetting;
      return temp;

      case 'AI Agent': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.aiAgent;
      return temp;
      case 'Create Chat Widget': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createChatWidget;
      return temp;
      case 'View Chat Widget': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewChatWidget;
      return temp;
      case 'Edit Chat Widget': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editChatWidget;
      return temp;
      case 'Delete Chat Widget': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteChatWidget;
      return temp;
      case 'Embed On Side': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.embedOnSide;
      return temp;

      case 'Opportunity Management': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.opportunity;
      return temp;
      case 'Create Account': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createAccount;
      return temp;
      case 'View Account': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewAccount;
      return temp;
      case 'Edit Account': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editAccount;
      return temp;
      case 'Delete Account': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteAccount;
      return temp;
      case 'Account Custom Fields': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.accountCustomFields;
      return temp;
      case 'Create Opportunity': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createOpportunity;
      return temp;
      case 'View Opportunity': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewOpportunity;
      return temp;
      case 'Edit Opportunity': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editOpportuninty;
      return temp;
      case 'Delete Opportunity': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteOpportunity;
      return temp;
      case 'Opportunity Custom Fields': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.opportunityCustomFields;
      return temp;
      case 'Create Contacts': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createContacts;
      return temp;
      case 'View Contacts': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewContacts;
      return temp;
      case 'Edit Contacts': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editContacts;
      return temp;
      case 'Delete Contacts': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteContacts;
      return temp;
      case 'Contacts Custom Fields': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.contactsCustomFields;
      return temp;
      case 'Assign Opportunity': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.assignOpportunity;
      return temp;
      case 'Change Opportunity Status': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.changeOpportunityStatus;
      return temp;
      case 'Drive': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.drive;
      return temp;
      case 'Create Drive Object': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.createDriveObject;
      return temp;
      case 'Edit Drive Object': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editDriveObject;
      return temp;
      case 'Delete Drive Object': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.deleteDriveObject;
      return temp;
      case 'View Drive Object': temp = this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.viewDriveObject;
      return temp;
      default: temp = val;
      break;
    }
  }

  isDisabledPermission = (event,selectedRole) => {
    let { disable} = this.state;
    if (selectedRole) {
      if (event.target.checked) {
        selectedRole.includes(2)
        disable.push(2);
      if(selectedRole.includes(64)) {
        disable.push(65, 66);
          selectedRole = selectedRole.filter(item => ![65, 66].includes(item));
      } 
      if(selectedRole.includes(65)) {
        disable.push(66);
          selectedRole = selectedRole.filter(item => ![66].includes(item));
      } 
      if(selectedRole.includes(67)) {
        disable.push(68, 69);
          selectedRole = selectedRole.filter(item => ![68, 69].includes(item));
      } 
      if(selectedRole.includes(68)) {
        disable.push(69);
          selectedRole = selectedRole.filter(item => ![69].includes(item));
      } 
      if(selectedRole.includes(70)) {
        disable.push(71, 72);
        selectedRole = selectedRole.filter(item => ![71, 72].includes(item));
      } 
      if(selectedRole.includes(71)) {
        disable.push(72);
          selectedRole = selectedRole.filter(item => ![72].includes(item));
      } 
      if(selectedRole.includes(73)) {
        disable.push(74, 75);
        selectedRole = selectedRole.filter(item => ![74, 75].includes(item));
      } 
      if(selectedRole.includes(74)) {
        disable.push(75);
        selectedRole = selectedRole.filter(item => ![75].includes(item));
      } 

      this.setState({
        disable: disable,
        selectedRole: {
          ...this.state.selectedRole,
          permissions: selectedRole
        }
      });
    }else {
      if( parseInt(event.target.id) === 64 || parseInt(event.target.id) === 65 || parseInt(event.target.id) === 66){
        disable= disable.filter(item => ![65, 66,64].includes(item));
      }

      if( parseInt(event.target.id) === 67 || parseInt(event.target.id) === 68 || parseInt(event.target.id) === 69){
        disable= disable.filter(item => ![67, 68,69].includes(item));
      }

      if( parseInt(event.target.id) === 70 || parseInt(event.target.id) === 71 || parseInt(event.target.id) === 72){
        disable= disable.filter(item => ![70, 71,72].includes(item));
      }

      if( parseInt(event.target.id) === 73 || parseInt(event.target.id) === 74 || parseInt(event.target.id) === 75){
        disable= disable.filter(item => ![73, 74,75].includes(item));
      }
     
      this.setState({
        disable: disable,
      });
    }
    }
  }

  render() {
    const {
      loading,
      permissions,
      errors
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box pb={3} pt={2.5} mb={1} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
          <Grid container spacing={2} width={'100%'}>
            <Grid size={{xs: "grow",}}>
              <Box component="h4" className='subsecondary' sx={{ marginRight: 50, marginBottom: 0, }}>{this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.mainHead}</Box>
            </Grid>
            <Grid >
              <div>
                {['right'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={this.toggleDrawer(anchor, true)} variant="outlined" color="primary">{this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.addNewRole}</Button>
                    <Drawer anchor={anchor} open={this.state[anchor]} onClose={this.toggleDrawer(anchor, false)} className="drawer-right">

                      <div className="myproject-accordion-outer">

                        <div className="drawer-heading">
                          <h2> {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.addRole} </h2>
                          <img alt='' onClick={this.toggleDrawer(anchor, false)} src={closeIcon} />
                        </div>

                        <div className="drawer-input">
                          <form className='inputField'>
                            <label> {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.roleName}<span> *</span> </label>
                            <TextField onChange={this.handleRoleName.bind(this)} placeholder={this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.enterRoleName} variant="outlined" size="small" />
                            <p className="error_mesage"> {errors['email']} </p>
                          </form>
                        </div>

                        <div className="drawer-accordion">
                          <Box className='inputField'>
                            <Box component={'label'} > {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.permissions}</Box>
                          </Box>
                          {
                            this.props.permissionsnames &&
                            this.props.permissionsnames.permissions &&
                            Object.keys(this.props.permissionsnames.permissions).map((permission, index) => {
                              return (
                                <Accordion style={{ margin: '0' }} key={'permkey' + index} className="box-shadow-none" defaultExpanded={index === 0}>
                                  <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="custom-accordion-summary"
                                  >
                                    <Typography> <img alt='' src={downArrow} /> {this.optionsList(permission)} ( {this.props.permissionsnames.permissions[permission].length} )</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className="custom-accordion-details">
                                    <TableContainer className="roles-table">
                                      <Table>
                                        <TableBody>
                                          {
                                            this.props.permissionsnames.permissions[permission].map((permData, index) => {
                                              return (
                                                <TableRow key={'permission' + index}>
                                                  <TableCell align="left">
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          style={{
                                                            padding: 0,
                                                            marginRight: '8px',
                                                          }}
                                                          id={`${permData.permission_id}`}
                                                          onClick={this.handlePermissionAdd.bind(this)}
                                                          color="primary"
                                                          checked={permissions.find(id => id == permData.permission_id) || false}
                                                          disabled={this.state.disable.indexOf(permData.permission_id) > -1 ? true :false}
                                                        />
                                                      }
                                                      label={this.optionsList(permData.permissionLabel)}
                                                      style={{ padding: 0, margin: 0 }}
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            })
                                          }
                                        </TableBody>
                                      </Table>

                                    </TableContainer>
                                  </AccordionDetails>
                                </Accordion>
                              )
                            })
                          }
                        </div>
                        <p className="error_mesage"> {errors['permissions']} </p>

                        <div className="drawer-footer">
                          <Button variant="contained" color="primary" onClick={this.handleRoleCreate}>
                          {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.addRole}
                          </Button>
                        </div>

                      </div>

                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </Grid>

          </Grid>
        </Box>
        <Box sx={{ overflow: 'auto', maxHeight: 'calc( 100vh - 205px )', position: 'reletive', }}>

          {['rightNew'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer anchor={'right'} open={this.state[anchor]} onClose={this.toggleEditDrawer(anchor, false)} className="drawer-right">
                <div className="myproject-accordion-outer">

                  <div className="drawer-heading">
                    <h2> {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.editRole} </h2>
                    <img onClick={this.toggleEditDrawer(anchor, false)} src={closeIcon} alt='close' />
                  </div>

                  <div className="drawer-input">
                    <form className='inputField'>
                      <label> {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.roleName}<span> *</span> </label>
                      <TextField onChange={this.handleEditRoleName.bind(this)} value={this.state.selectedRole?.role_name} variant="outlined" size="small" />
                      <p className="error_mesage"> {errors['email']} </p>
                    </form>
                  </div>

                  <div className="drawer-accordion">
                    <Box className='inputField'>
                      <Box component={'label'} > {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.permissions} </Box>
                    </Box>
                    {
                      this.props.permissionsnames &&
                      this.props.permissionsnames.permissions &&
                      Object.keys(this.props.permissionsnames.permissions).map((permission, index) => {
                        return (
                          <Accordion style={{ margin: '0' }} key={'permkey' + index} className="box-shadow-none" defaultExpanded={index === 0}>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="custom-accordion-summary"
                            >
                              <Typography> <img alt='' src={downArrow} /> {this.optionsList(permission)} ( {this.props.permissionsnames.permissions[permission].length} )</Typography> 
                            </AccordionSummary>
                            <AccordionDetails className="custom-accordion-details">
                              <TableContainer className="roles-table">
                                <Table aria-label="simple table">
                                  <TableBody>
                                    {
                                      this.props.permissionsnames.permissions[permission].map((permData, index) => {
                                        return (
                                          <TableRow key={'permission' + index}>
                                            <TableCell align="left">
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    style={{
                                                      padding: 0,
                                                      marginRight: '8px',
                                                    }}
                                                    id={`${permData.permission_id}`}
                                                    onClick={(e) => this.handlePermissionEdit(e)}
                                                    color="primary"
                                                    checked={(this.state.selectedRole?.permissions && this.state.selectedRole?.permissions.indexOf(permData.permission_id) > -1) ? true : false}
                                                    disabled={this.state.disable.indexOf(permData.permission_id) > -1 ? true :false}

                                                  />
                                                }
                                                 label={this.optionsList(permData.permissionLabel)} 
                                                style={{ padding: 0, margin: 0 }}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })
                                    }
                                  </TableBody>
                                </Table>

                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })
                    }
                  </div>

                  <div className="drawer-footer">
                    <Button variant="contained" color="primary" onClick={this.handleRoleEdit}>
                    {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.updateChanges}
                    </Button>
                  </div>

                </div>

              </Drawer>
            </React.Fragment>
          ))}

          <Box className="myproject-slider-outer">
            <Card className="slider-card slider-card-left-heading">
              <p className='subtitle'> {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.permissions} </p>
            </Card>
            {
              this.props.roles.roles &&
              Array.isArray(this.props.roles.roles) &&
              this.props.roles.roles.map((role, index) => {
                return (
                  <Card key={'EditRole' + index} className="slider-card">
                    <CardContent>
                      <div className="card-top-heading">
                        {<p>{role.usersCount ? role.usersCount : 0} {this.props.users.languageStateForRedux?.buttons?.teams?.users}</p>}
                        <Box className='options'>
                          <IconButton className='options-button' size='small'>
                            <MoreHorizIcon style={{ color: theme.palette.primary.dark, }} />
                          </IconButton>
                          <Box className="options-list">
                            <div onClick={this.toggleEditDrawer('rightNew', true, role)}>
                              {this.props.users.languageStateForRedux?.common?.edit}
                            </div>
                            <hr />
                            <Box sx={{color: `${theme.palette.secondary.main} !important`}} onClick={() => this.openDeleteConfirmationBox(role)}>
                            {this.props.users.languageStateForRedux?.common?.delete}
                            </Box>
                          </Box>
                        </Box>
                      </div>
                      <div className="card-middle-heading">
                        <h5> {role.role_name} </h5>
                      </div>
                      <div className="card-end-heading">
                        {this.handlePermissionLevel([role.permissions.length, 22],this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions)}
                      </div>
                    </CardContent>
                  </Card>
                );
              })
            }
          </Box>

          <div className="myproject-accordion-outer">
            {
              this.props.roles.roles &&
              Array.isArray(this.props.roles.roles) &&
              this.props.roles.roles.length > 0 &&
              this.props.permissionsnames &&
              this.props.permissionsnames.permissions &&
              Object.keys(this.props.permissionsnames.permissions).map((permission, index) => {
                return (
                  <Accordion key={'permkey' + index} className="box-shadow-none" defaultExpanded={index === 0}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="custom-accordion-summary"
                    >
                      <Typography> <img alt='' src={downArrow} /> {this.optionsList(permission)} ( {this.props.permissionsnames.permissions[permission].length} )</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="custom-accordion-details">
                      <TableContainer className="roles-table">
                        <Table key={'permission' + index} aria-label="simple table">
                          <TableBody>
                            {
                              this.props.permissionsnames.permissions[permission].map((permData, index) => {
                                return (
                                  <TableRow>
                                    <TableCell style={{ position: 'sticky', left: 0, backgroundColor: '#ffffff', zIndex: '1', }} align="left">{this.optionsList(permData.permissionLabel)}</TableCell>
                                    {
                                      this.props.roles.roles &&
                                      Array.isArray(this.props.roles.roles) &&
                                      this.props.roles.roles.map((role, index) => {
                                        return (
                                          <React.Fragment>
                                            <TableCell align="left">
                                              <Checkbox
                                                checked={role.permissions.includes(permData.permission_id) ? true : false}
                                                color="primary"
                                                disableRipple="true"
                                              />
                                            </TableCell>
                                          </React.Fragment>
                                        );
                                      })
                                    }
                                  </TableRow>
                                )
                              })
                            }
                          </TableBody>
                        </Table>

                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }


          </div>

          <Modal
            open={this.state.openDeleteConfirmation} onClose={this.closeDeleteConfirmation}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  {this.props.users.languageStateForRedux?.common?.confirmDelete}
                </Box>
                <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
              </Box>
              <Grid size={{xs: 12,}} >
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <><Button variant="contained" color="secondary" disableElevation onClick={() => this.deleteRole()} >{this.props.users.languageStateForRedux?.common?.delete}</Button>
                        <Button variant="outlined" disableElevation onClick={this.closeDeleteConfirmation}>{this.props.users.languageStateForRedux?.common?.cancel}</Button></>
                    }

                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <NotificationContainer />
          {loading  || this.props.users.isLoading ? <Loader /> : null}
        </Box>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  roles: state.Roles,
  permissionsnames: state.Permission,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getRoles: Actions.getRolesRequest,
      getPermissionList: Actions.permissionRequest,
      createRole: Actions.createRoleRequest,
      editRole: Actions.editRoleRequest,
      deleteRole: Actions.deleteRoleRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(Roles)

import React, { Component } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer } from 'react-notifications';
import 'react-form-builder2/dist/app.css';
// import '../../../sass/main.scss';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import axios from 'axios';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Modal } from 'react-responsive-modal';
import Button from '@mui/material/Button';
import { theme } from '../../../theme/theme';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { createNotification } from '../../../helpers';
import { ThemeProvider } from '@mui/material';
import ShowPass from '../../../../src/assets/images/icons/show-password.svg';
import HidePass from '../../../../src/assets/images/icons/hide-password.svg';
import Loader from '../../../Components/Loader/loader';
import { addEventGA, UserData } from '../../../helpers/common';

import {
  trackingId,
  ACCESS_CODE_EXPERING_PERIOD,
} from '../../../constants/constants';
import { get } from 'lodash';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import { ReactComponent as ProfileUserIcon } from '../../../../src/assets/images/icons/profileUserIcon-60-white.svg';
import PageNotFound from '../../../Components/PageNotFound';
import { Actions } from 'ReduxStore/Actions';
import moment, { duration } from 'moment';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import ConfirmationAlertPopUpPrint from 'Common/ConfirmationAlertPopUpPrint';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import PrintPreviewSuccessFailPopup from 'Common/PrintPreviewSuccessFailPopup';


import {
  getEventTemplatesNew,
  getEventForPagesAPI,
  GetAttendeeDetail,
  addGamificationPoinstYT,
} from 'ReduxStore/API';
import publicIP from 'react-native-public-ip';
import { Helmet } from 'react-helmet';
import informationIcon from '../../../assets/images/icons/informationIcon.svg';
import CustomTooltip from '../../../Components/CustomHTMLTooltip';
import { getPageScript } from './script/pageScript';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import PrintContextConsumer from 'react-to-print';
import getReturnValues from '../../../../src/Pages/ProjectDashboard/Attendees/BadgePrint/BadgePrintCss';
import { BadgeContent } from '../../../../src/Pages/ProjectDashboard/Attendees/BadgePrint/BadgeContent';
import userImageIcon from '../../../../src/assets/images/icons/userImageIcon.svg';

import withCustomRouter from '../../../Common/withCustomRouter';  

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

const [eventDomain] = window.location.hostname.split('.');
const isUrlValid = (userInput) => {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );
  if (res == null) return false;
  else return true;
};

const styles = {
  userAvatar: {
    // backgroundColor: '#EDEBFF',
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
  },
};
class Page extends React.Component {
  state = {
    html: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      templates: {},
      eventDetails: null,
      isPublished: false,
      openCreatePasswordForm: false,
      ShowPassword: false,
      ShowConfirmPassword: false,
      openForgotPasswordForm: false,
      loading: true,
      notificationPopup: false,
      openEarlyAccess: false,
      eventStartDate: '',
      PopupClass: 'notification-popup ql-editor',
      attendeeData: JSON.parse(localStorage.getItem('attendee_details')),
      showProfileButton: false,
      MyProfileUrl: '',
      searchEnginesIndex: false,
      isPrivatePage: false,
      profilePic: '',
      isProfileVisible: true,
      profilePosition: '',
      favIcon: '',
      pathlocation: '',
      errors: {},
      isAccessCode: false,
      widgetTimerStartDateTime: 0,
      customJsAdded: false,
      customeJs: '',
      pageAccessRestrictionMessage: false,
      eventExpiredMessage: false,
      openSuccess: false,
      redirectionPath: '',
      dynmoMessage: '',
      pintBadge: {},
      badgePrintSettings: {},
      attendeesDetail: {},
      openPrintBadgeModal: false,
      openBadgeConfirmationModal: false,
      openEarlyAccessMessage: '',
      kioskMessage: '',
      kioskConfirmationToggle: false,
      kioskAutoCheckInToggle: false,
      kioskPrintBadgeToggle: false,
      printPreviewSuccessModal: false,
      notificationMessageAws: {},
    };
  }

  handleClosePrintBadgeModal = () => {
    this.setState({
      openPrintBadgeModal: false,
      errors: '',
      cropPic: '',
      badgePrintSettings: {}
    });
  };

  logOut = () => {
    let store = ['user_role', 'attendee_details'];
    store.forEach((item) => localStorage.removeItem(item));
    localStorage.removeItem('importProgress');
    //createNotification('success', 'You logged out successfully');
    window.location.href = '/login';
    return false;
  };


  verifyGoogleUserTokenValid = () => {
    let token = localStorage.getItem('googleSignIn_token');
    if (token) {
      this.props.checkGoogleSignInValidToken({ token });
    }
  };

  async componentDidMount(prevProps, prevState) {
    // this.verifyGoogleUserTokenValid();
    const path = window.location.pathname;
    let attendeesDetail = JSON.parse(window.localStorage.getItem('attendee_details'));

    this.setState({
      attendeesDetail
    });
    let currentPageData = this.props.allPagesPath.find(pathData => `/${pathData.path}` === path);
    if (currentPageData) {
      if (currentPageData.isPrivate) {
        if (!attendeesDetail) {
          let loginPageData = this.props.allPagesPath.find(path => path.isLoginPage === true);
          this.props.navigate(loginPageData.path);
        }
      }
    }

    const lv_params = {
      type: 'Project',
      eventDomain,
      eventId: this.props.eventId,
      path: path === '/' ? 'None' : path.substring(1),
    };

    if (attendeesDetail?.isSystemUser == true) {
      if (path == "/logout" || path == "/kiosk") {
        if (path == "/logout") {
          this.logOut();
        }
      } else {
        window.location.href = "/kiosk";
      }
    }
    let query = window.location.search !== '' ? window.location.search : '';
    await getEventTemplatesNew(lv_params, query)
      .then(async (result) => {
        // frontend attendees
        if (this.props.isPublished) {
          if (result.data.rdirect === 'login_page') {
            if (
              result.data.message !==
              'Please Login into an event, You are not logged in.'
            ) {
              createNotification(
                'error',
                result.data ? result.data.message : 'Something went wrong!',
              );
            }
            localStorage.clear();
            let loginPageData = this.props.allPagesPath.find(path => path.isLoginPage === true);
            if (loginPageData && loginPageData.path) {
              window.location.href = loginPageData.path;
            }
          } else if (result.data.rdirect === 'home_page') {
            if (result.data.path) {
              window.location.href = result.data.path;
            }
          } else if (result.data.rdirect === 'logout_login_page') {
            createNotification(
              'success',
              result.data ? result.data.message : 'Something went wrong!',
            );
            localStorage.clear();
            if (result.data.path) {
              window.location.href = result.data.path;
            }
          } else if (result.data.status === 401) {
            if (result.data.message === 'You are not authorized to access this page!') {
              this.setState({ pageAccessRestrictionMessage: true });
            } else if (result.data.message === 'Event has Expired!') {
              this.setState({ eventExpiredMessage: true });
            }
          } else if (result.data?.status === 409) {
            if (result.data.error && result.data.error === 'Your Event has not started yet') {
              /**
               * if the event has early access but the current time is less than the early access datetime 
               * then show popup message
               * */
              let popupMessage;

              /**if the error response is come from varnish, it send only utc time
               * 
               * we need to convert the utc time into event's timezone 
               * 
               * else if its come from backend server directly show the message
               */
              if (result.data.earlyAccessTime && result.data.timezone) {
                popupMessage = "Please come back at " + moment(result.data.earlyAccessTime).tz(result.data.timezone).format('dddd, MMM, Do YYYY, h:mm A') + " to gain entry. For the best user experience, please use the chrome browser. Thank you!";
              } else {
                popupMessage = result.data.message;
              }

              this.setState({
                openEarlyAccess: true,
                openEarlyAccessMessage: popupMessage
              });
            }
          } else if (result.data?.status === 403 && result.data?.rdirect !== 'kiosk') {
            createNotification(
              'error',
              result.data && result.data?.message != ''
                ? result.data.message
                : 'Access code expired!',
            );
            setTimeout(() => {
              window.location.href = '/profile/access-code';
              return false;
            }, 200);
            return false;
          } else if (result.data?.rdirect === 'kiosk' && result.data?.status === 403) {
            this.setState({ loading: false });
            window.location.href = '/pageNotFound';
            return false;
          } else {
            if (result.data.success) {
              const templates = get(result, 'data.templates');
              if (templates) {
                this.setState({
                  templates,
                  eventDetails: result.data.eventData,
                });
              }
              if (result.data.eventData) {
                this.setState({ eventDetails: result.data.eventData });
                this.setState({
                  isProfileVisible: result.data.eventData?.isProfileVisible,
                  profilePosition: result.data.eventData?.profilePosition,
                });
              }
              if (result.data.isPublished) {
                this.setState({ isPublished: result.data.isPublished });
              }
              if (result.data.templates && result.data.templates.isPrivate) {
                this.setState({
                  isPrivatePage: result.data.templates.isPrivate,
                });
              }

              //add page script with custom js 
              if (result.data.templates.kioskConfirmationToggle) {
                this.setState({
                  kioskConfirmationToggle: result.data.templates.kioskConfirmationToggle,
                });
              }
              if (result.data.templates.kioskAutoCheckInToggle) {
                this.setState({
                  kioskAutoCheckInToggle: result.data.templates.kioskAutoCheckInToggle,
                });
              }
              if (result.data.templates.kioskPrintBadgeToggle) {
                this.setState({
                  kioskPrintBadgeToggle: result.data.templates.kioskPrintBadgeToggle,
                });
              }

              if (result.data.templates.kioskNoPrintPopupTitle) {
                this.setState({
                  kioskNoPrintPopupTitle: result.data.templates.kioskNoPrintPopupTitle,
                });
              }

              if (result.data.templates.printPreviewMessage) {
                this.setState({
                  printPreviewMessage: result.data.templates.printPreviewMessage,
                });
              }

              if (result.data.templates.kioskNoPrintMessage) {
                this.setState({
                  kioskNoPrintMessage: result.data.templates.kioskNoPrintMessage,
                });
              }

              if (result.data.templates.kioskPopupTitle) {
                this.setState({
                  kioskPopupTitle: result.data.templates.kioskPopupTitle,
                });
              }
              if (result.data.templates.printPreviewPopupTitle) {
                this.setState({
                  printPreviewPopupTitle: result.data.templates.printPreviewPopupTitle,
                });
              }

              if (result.data.templates.kioskMessage) {
                this.setState({
                  kioskMessage: result.data.templates.kioskMessage,
                });
              }

              let customeJs = '';
              if (result.data.templates.customeJs) {
                customeJs = result.data.templates.customeJs;
              }
              let lv_page_script = getPageScript(customeJs, result.data.eventData._id, result.data.templates.pageName, this.state.attendeeData);
              this.setState({ customeJs: lv_page_script });
            }
          }
        } else if (result.data?.status === 403 && result.data?.rdirect !== 'kiosk') {
          createNotification(
            'error',
            result.data && result.data?.message != ''
              ? result.data.message
              : 'Access code expired!',
          );
          setTimeout(() => {
            window.location.href = '/profile/access-code';
            return false;
          }, 200);
          return false;
        } else {
          <Route path="*" element={<PageNotFound />} />;
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });

    let domainUrl = window.location.href;
    let domain = new URL(domainUrl);
    this.setState({
      pathlocation: window?.location?.pathname,
    });
    const params = {
      type: 'Project',
      eventDomain,
      path: path === '/' ? '' : path.substring(1),
    };

    const favId = document.getElementById('favicon');
    if (favId) {
      favId.href = this.state.eventDetails?.faviconImage ? `${IMAGE_URL}/favIcons/${this.state.eventDetails.faviconImage}?v=2` : "?" + Date.now();

    }

    // Disable iframe for first 4 seonds for gamification points ytct and vimeoct
    const collection = document.getElementsByTagName("iframe");
    if (collection) {
      for (let i = 0; i < collection.length; i++) {
        collection[i].style.pointerEvents = "none";
      }
      for (let j = 0; j < collection.length; j++) {
        collection[j].onload = () => {
          this.setState({ loading: true });
          setTimeout(() => {
            this.setState({ loading: false });
            collection[j].style.pointerEvents = "auto";
          }, 3500);
        };
        setTimeout(() => {
          this.setState({ loading: false });
        }, 4000);
      }
    }

    /* count Down timer widget function start here */
    setTimeout(async () => {
      var StartWidgetCountDown = document.getElementById('StartWidgetCountDown');
      var timerStartDate = document.getElementById('timerStartDate');
      if (StartWidgetCountDown && StartWidgetCountDown.value !== 'false') {
        var timerTimeType = '';
        var timerTimeTypeHtml = document.getElementById('timerTimeType');
        if (timerTimeTypeHtml) {
          timerTimeType = timerTimeTypeHtml.value;
        }

        if (timerTimeType == 'AccessCodeTimer') {
          /* Api to get access code for login attendees */
          var attendee = JSON.parse(localStorage.getItem('attendee_details'));
          let timerTypejson = {
            timerTimeType: timerTimeType,
          };
          if (attendee?.token) {
            var URL = process.env.REACT_APP_API_URL;
            await axios
              .post(`${URL}/getAttendeesAccesscodes`, timerTypejson, {
                headers: {
                  'Content-Type': 'application/json',
                  token: 'bearer ' + attendee.token,
                },
              })
              .then((dataRes) => {
                if (
                  dataRes.data.status === 200 &&
                  dataRes.data.attendeedetails.expiryDate != null
                ) {
                  if (
                    dataRes.data.attendeedetails.isAcessCodeLifeTime == true
                  ) {
                    //  no need of timer in lifetime
                  } else {
                    var customWidgetDatetimer =
                      dataRes.data.attendeedetails.expiryDate;
                    let customWidgetDate = new Date(
                      customWidgetDatetimer,
                    ).getTime();
                    this.setState({
                      widgetTimerStartDateTime: customWidgetDate,
                    });
                    this.countDownWidgetTimerFun();
                  }
                }
              })
              .catch((err) => {
                return err;
              });
          }
          /* Api to get access code for login attendees */
        } else if (timerTimeType == "AgendaStartDate") {
          var attendee = JSON.parse(localStorage.getItem('attendee_details'));
          let agendaId = {
            _id: timerStartDate.value,
          };
          if (attendee?.token) {
            var URL = process.env.REACT_APP_API_URL;
            await axios.post(`${URL}/agenda/getSessionStartDateById`, agendaId, {
              headers: {
                'Content-Type': 'application/json',
                token: 'bearer ' + attendee.token,
              },
            }).then((dataRes) => {
              if (dataRes.data.status === 200 && dataRes.data.session !== null) {
                if (dataRes.data.session.dateTime) {
                  var customWidgetDatetimer = dataRes.data.session.dateTime;
                  let customWidgetDate = new Date(customWidgetDatetimer).getTime();
                  this.setState({
                    widgetTimerStartDateTime: customWidgetDate,
                  });
                  this.countDownWidgetTimerFun();
                }
              }
            })
              .catch((err) => {
                return err;
              });
          }
        } else {
          if (
            timerStartDate &&
            timerStartDate.value !== 'undefined' &&
            timerStartDate.value !== ''
          ) {
            let setCount = timerStartDate.value;
            let customWidgetDate = new Date(setCount).getTime();
            this.setState({ widgetTimerStartDateTime: customWidgetDate });
            this.countDownWidgetTimerFun();
          }
        }
      }
    }, 0);
    /* count Down timer widget function end here */
    setTimeout(async () => {
      this.setState({
        MyProfileUrl: `${domain.protocol}//${domain.host}/profile/update-profile`,
      });
      if (this.state.attendeeData && this.state.attendeeData !== null) { 
        this.setState({ showProfileButton: true });
        if (domain.pathname == '/login') { 
          let homePage = this.props.allPagesPath.find(path => path.isHomePage === true);

          //if there is not homepage then we handle it with any other page as homepage
          if (!homePage) {
            homePage = this.props.allPagesPath.find(
              path => !path.isLoginPage && path.path !== "kiosk"
            );
          }  
          window.location.href = `${domain.protocol}//${domain.host}/${homePage.path}`;
        }
        let getData = { id: this.state.attendeeData.id, eventDomain, eventId: this.props.eventId }; 
        if (this.state.eventDetails?.isProfileVisible) {
          await GetAttendeeDetail(getData).then((res) => {
            this.setState({ profilePic: res?.data?.attendee?.profileImage });
          });
        }
      } else {
        this.setState({ showProfileButton: false });
        if (domain.pathname !== '/login' && this.state.templates.isPrivate === true) {
          this.props.navigate(`${domain.protocol}//${domain.host}/login`);
        }
      }

      this.setState({ searchEnginesIndex: this.props.searchEnginesIndex });

      if (this.props.location && this.props.location.search !== '') {
        const query = {};
        const searchParam = this.props.location.search.replace('?', '').split('&');
        for (var i = 0; i < searchParam.length; i++) {
          let ele = searchParam[i].split('=');
          query[ele[0]] = ele[1];
        }
        if (query['earlyAccess']) {
          var decodedMsg = Buffer.from(query['earlyAccess'], 'base64').toString(
            'ascii',
          );
          this.setState({
            openEarlyAccess: true,
            openEarlyAccessMessage: decodedMsg
          });
        }
        if (query['ref'] === 'password_reset') {
          let showMyPopUp = document.getElementById('showMyPopUp');
          if (showMyPopUp) {
            setTimeout(() => {
              let loginFormData = document.getElementById('toolLoginForm');
              let allLoginFields = new FormData(loginFormData);
              allLoginFields.append('email', query['email']);
              let createP = document.getElementById('createPasswordEmail');
              let cemail = document.getElementById('cemail');
              let cprojectId = document.getElementById('cprojectId');
              if (createP) {
                createP.value = query['email'];
                cemail.value = query['email'];
                cprojectId.value = allLoginFields.get('projectId');
              }
            }, 200);
            showMyPopUp.click();
          }
        }
      }

    }, 0);

    /* Registration form code start here */
    let formData = document.getElementById('toolForm');
    if (formData) {
      var accessCodeDataDiv = document.getElementById('accessCodeDataDiv');
      accessCodeDataDiv.style.display = 'none';
      var isCheckboxMand = document.getElementById('isCheckBoxMandatory');
      if (isCheckboxMand) {
        if (isCheckboxMand.value === 'true') {
          const checkboxes = formData.querySelectorAll('input[type=checkbox]');
          const checkboxLength = checkboxes.length;
          const firstCheckbox = checkboxLength > 0 ? checkboxes[0] : null;
          function init() {
            if (firstCheckbox) {
              for (let i = 0; i < checkboxLength; i++) {
                checkboxes[i].addEventListener('change', checkValidity);
              }
              checkValidity();
            }
          }
          init();
          function isChecked() {
            for (let i = 0; i < checkboxLength; i++) {
              if (checkboxes[i].checked) return true;
            }
            return false;
          }
          function checkValidity() {
            const errorMessage = !isChecked()
              ? 'At least one checkbox must be selected.'
              : '';
            firstCheckbox.setCustomValidity(errorMessage);
          }
        }
      }

      var accessCodeVal = document.getElementById('accessCodeVal');

      if (this.state.eventDetails?.isAccessCode == true) {
        accessCodeDataDiv.style.display = 'flex';
        document.getElementById('accesscodeInput').required = true;
        // replaceAccessMessage.innerHTML = document.getElementById("hideAccessMessage").value
      } else {
        accessCodeDataDiv.style.display = 'none';
        document.getElementById('accesscodeInput').required = false;
      }

      setTimeout(() => {
        let acssCodeWrapper = document.getElementById('access-code-wrapper');

        if (acssCodeWrapper != null || acssCodeWrapper != undefined) {
          document.getElementById(
            'access-code-wrapper',
          ).innerHTML = `<input class="input" type="hidden" id="accessCodeVal" name="accessCodeVal" value="${this.state.eventDetails?.isAccessCode}">`;
        } else {
          var textfield = document.createElement('input');
          textfield.type = 'hidden';
          textfield.name = 'accessCodeVal';
          textfield.id = 'accessCodeVal';
          textfield.value = `${this.state.eventDetails?.isAccessCode}`;
          document.getElementById('toolForm').appendChild(textfield);
        }
      }, 0);

      formData.addEventListener('submit', (e) => {
        e.preventDefault();
        let loaderToggelReg = document.getElementById('loaderToggel');
        if (loaderToggelReg) {
          loaderToggelReg.click();
        }
        /* code for save checkbox values if there are checkboxes on registration form */
        var checkedValue = [];
        var inputElements = document.getElementsByClassName('toolCheckBoxes');
        if (inputElements != null) {
          for (var i = 0; inputElements[i]; ++i) {
            if (inputElements[i].checked) {
              checkedValue.push(inputElements[i].value);
            }
          }
          if (checkedValue.length > 0) {
            document.getElementById('toolCheckboxValue').value =
              checkedValue.join(',');
          }
        }
        /* code for save checkbox values if there are checkboxes on registration form 'end here' */
        let allFields = new FormData(formData);
        /* Code to add kiosk flag in form data if system user loggedin start here*/
        if (this.state.attendeeData && this.state.attendeeData !== null) {
          if (this.state.attendeeData.isSystemUser) {
            allFields.append('fromPathName', window?.location?.pathname);
            allFields.append('fromIsSystemUser', this.state?.attendeeData?.isSystemUser);
          }
        } else {
          allFields.append('fromPathName', null);
          allFields.append('fromIsSystemUser', false);
        }
        /* Code to add kiosk flag in form data if system user loggedin end here*/

        let object = {};
        allFields.forEach(function (value, key) {
          if (value === '') return;
          object[key] = value;
        });
        if (this.state.kioskAutoCheckInToggle) {
          object['kioskAutoCheckInToggle'] = true;
        }
        this.setState({
          attendeeDataForBadgeAlreadyPrintedApi: object
        });
        let formDatajson = JSON.stringify(object);
        const URL = process.env.REACT_APP_API_URL;
        axios.post(`${URL}/attendees/saveAttendees`, formDatajson, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((data) => {
            if (loaderToggelReg) {
              loaderToggelReg.click();
            }
            if (data.data.status === 200) {
              //Google analytics login event
              let projectId = allFields.get('projectId');
              formData.reset();
              if (window.location.pathname == "/kiosk") {
                if (data.data.message == "Attendee created successfully") {
                  let nowDateTime = new Date().getTime();
                  let eventstartDateTime = new Date(data.data.eventStartdate).getTime();
                  var DynamicMessage = '';
                  DynamicMessage = 'Print you Badge on next page!';

                  if (this.state.kioskPrintBadgeToggle == true) {
                    if (this.state.kioskConfirmationToggle == true) {
                      this.setState({
                        openSuccess: true,
                        openPrintBadgeModal: false,
                        openBadgeConfirmationModal: true,
                        printPreviewSuccessModal: false,
                        dynmoMessage: "Print your badge!",
                        fromIsSystemUser: JSON.parse(data.data.fromIsSystemUser),
                        pintBadge: data.data.page,
                        badgePrintSettings: data.data.pageSettings
                      }, () => {
                        setTimeout(() => {
                          this.setState({
                            openBadgeConfirmationModal: false,
                          });
                        }, 10000);
                      });


                    } else {
                      this.setState({
                        openSuccess: true,
                        openPrintBadgeModal: false,
                        openBadgeConfirmationModal: false,
                        printPreviewSuccessModal: false,
                        dynmoMessage: "Print your badge!",
                        fromIsSystemUser: JSON.parse(data.data.fromIsSystemUser),
                        pintBadge: data.data.page,
                        badgePrintSettings: data.data.pageSettings
                      });
                      this.handleYesPrintBadge();
                    }
                  }
                  else {
                    this.setState({
                      openSuccess: true,
                      openPrintBadgeModal: false,
                      openBadgeConfirmationModal: true,
                      printPreviewSuccessModal: true,
                      dynmoMessage: "Success",
                      fromIsSystemUser: JSON.parse(data.data.fromIsSystemUser),
                    }, () => {
                      setTimeout(() => {
                        this.setState({
                          openBadgeConfirmationModal: false,
                        });
                      }, 3000);
                    });
                  }

                }

              } else {
                if (data.data.message == "Attendee created successfully") {
                  let nowDateTime = new Date().getTime();
                  let eventstartDateTime = new Date(data.data.eventStartdate).getTime();
                  var DynamicMessage = '';
                  if (nowDateTime > eventstartDateTime) {
                    DynamicMessage = 'The event already started, please login into an event';
                  } else {
                    DynamicMessage =
                      `Please login into an event on <strong> ${moment.tz(data.data.eventStartdate, data.data.timezone).format("dddd, MMM, Do YYYY, h:mm A (z)")} </strong>`;
                  }

                  this.setState({
                    redirectionPath: data.data.path,
                    openSuccess: true,
                    dynmoMessage: DynamicMessage
                  });
                }

                createNotification(
                  'success',
                  data.data ? data.data.message : 'Registered Successfully.',
                );
                window.location.href = data.data.path;
              }

            } else {
              createNotification(
                'error',
                data.data ? data.data.error : 'Something went wrong!',
              );
            }
          })
          .catch((err) => {
            if (loaderToggelReg) {
              loaderToggelReg.click();
            }
            return err;
          });
      });
    }
    /* Registration form code end here */

    /* Look up widget starts here */

    setTimeout(() => {
      var lookUpCtDiv = document.getElementById("lookUpCtDiv");
      var lookUpForm = document.getElementById('lookUpForm');
      var lookUpInput = document.getElementById('lookUpInput');
      var lookupHiddenCustomFields = document.getElementById("lookupHiddenCustomFields");
      let lookUpBadgeAlreadyPrintedMessage = "";
      let hiddenSearchInputValues = [];
      let selectedCFs = [];
      let searchFieldsToDisplay = [];

      if (lookUpCtDiv) {
        lookUpBadgeAlreadyPrintedMessage = lookUpCtDiv.getAttribute("data-lookUpBadgeAlreadyPrintedMessage");
        window.addEventListener('beforeunload', function (event) {
          event.preventDefault();
          event.returnValue = 'Are you sure you want to leave this page?';
        });
      }

      let searchTypeEmail = false;

      if (lookupHiddenCustomFields) {
        var cfInputElements = lookupHiddenCustomFields.querySelectorAll("input");
        cfInputElements.forEach(function (input, i) {
          let inputField = document.getElementById('searchOptions-' + i);
          const fieldToShow = inputField.getAttribute("data-showfield");
          if (fieldToShow === "true") {
            searchFieldsToDisplay.push(input.value);
          }
          hiddenSearchInputValues.push(input.value);
        });

        searchTypeEmail = hiddenSearchInputValues.includes('email');
        selectedCFs = hiddenSearchInputValues.filter(el => el !== "email");
      }

      var textSearched = '';
      if (lookUpInput) {
        lookUpInput.addEventListener('input', () => {
          textSearched = lookUpInput.value;
        });
      }

      if (lookUpForm) {
        const isPartialSearchAllowed = lookUpCtDiv.getAttribute("data-partialSearch");
        const isProfileImageAllowed = lookUpCtDiv.getAttribute("data-showProfileImage");
        const lookUpErrorMessage = lookUpCtDiv.getAttribute("data-lookUpErrorMessage");
        lookUpForm.addEventListener('submit', async (event) => {
          event.preventDefault();
          this.setState({
            loading: true
          });
          try {
            const body = {
              textSearched: textSearched,
              projectId: this.state.eventDetails._id,
              selectedCFs: selectedCFs,
              searchTypeEmail: searchTypeEmail
            };
            var URL = process.env.REACT_APP_API_URL;
            const res = await axios.post(`${URL}/attendees/searchAttendeesForLookUpWidget?isPartialSearchAllowed=${isPartialSearchAllowed}`, body, {     // To search attendee with email
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (res.status === 200) {
              if (res.data.success) {
                let searchResult = res.data.result;
                // If a list of attendee email or no result found is already present, we need to remove that in order to search new email
                const lookUpDropdownToRemove = document.getElementById('lookUpDropdown');
                const errorMessageDivToRemove = document.getElementById('errorMessage');
                const badgeAlreadyPrintedMessageDivToRemove = document.getElementById('badgeAlreadyPrintedMessage');
                if (lookUpDropdownToRemove) {
                  lookUpDropdownToRemove.remove();
                }
                if (errorMessageDivToRemove) {
                  errorMessageDivToRemove.remove();
                }

                if (badgeAlreadyPrintedMessageDivToRemove) {
                  badgeAlreadyPrintedMessageDivToRemove.remove();
                }

                if (searchResult.length > 0) {
                  lookUpForm.insertAdjacentHTML('afterend', '<div id="lookUpDropdown" class="search-result"></div>');   // Inserting a div which will contain p tag which will have the resulting emails
                  var lookUpDropdownDiv = document.getElementById('lookUpDropdown');

                  lookUpDropdownDiv.innerHTML += `<label>Keyword '${textSearched}' matches with these results:</label>`;

                  searchResult.forEach(el => {                   // because there should be as many p tags as the number of search results
                    let arrayOfResults = [];

                    for (i = 0; i < searchFieldsToDisplay.length; i++) {            // looping through all the selected fields(in unlayer editor) that we want to display in the front end results 
                      if (searchFieldsToDisplay[i] !== 'email') {
                        if (el.attendeesData[searchFieldsToDisplay[i]] !== undefined) {
                          arrayOfResults.push(`${el.attendeesData[searchFieldsToDisplay[i]]}`);
                        }
                      } else if (searchTypeEmail) {
                        arrayOfResults.push(`${el.email}`);
                      }
                    }

                    let strg = '';
                    for (let i = 0; i < arrayOfResults.length; i++) {
                      if (i > 0) {
                        strg += ` • ${arrayOfResults[i]} `;
                      } else {
                        strg += `<b data-id=${el.email} data-lookUpClickedText="lookUpClickedText">${arrayOfResults[i]}</b>`;
                      }
                    }
                    if (isProfileImageAllowed === "true") {           // If the enable profile image toggle is on we will either show the profile image or placeholder
                      lookUpDropdownDiv.innerHTML += `<p data-lookUpClickedText="lookUpClickedText" data-id=${el.email}><span data-id=${el.email} data-lookUpClickedText="lookUpClickedText">${strg}</span>
                      <img style="${el.profileImage ? "border-radius: 50%; float: right" : "border-radius: 50%; float: right; background-color: grey"}"  width="25" alt="profile" 
                      src="${el.profileImage ? `${IMAGE_URL}/profilePictures/${el.profileImage}` : `${userImageIcon}`}"/></p>`;
                    } else {                                        // If toggle enable profile image is off we wont show any image or placeholder
                      lookUpDropdownDiv.innerHTML += `<p data-lookUpClickedText="lookUpClickedText" data-id=${el.email}><span data-id=${el.email} data-lookUpClickedText="lookUpClickedText">${strg}</span></p>`;
                    }
                  });
                  this.setState({
                    loading: false
                  });
                } else {
                  // If the there is no records matching the given email
                  lookUpForm.insertAdjacentHTML('afterend', '<div id="errorMessage"></div>');
                  var errorMessageDiv = document.getElementById('errorMessage');

                  errorMessageDiv.innerHTML += `<p style="color: #e03e2d; border-radius: 5px; padding: 5px 10px; margin-top: 10px; border: 1px solid #e03e2d;">${lookUpErrorMessage}</p>`;
                  this.setState({
                    loading: false
                  });
                  setTimeout(() => {
                    errorMessageDiv.remove();
                  }, 5000);
                }
              }
            }
          } catch (error) {
            createNotification("error", "Something went wrong");
            this.setState({
              loading: false
            });
          }
        });
      }

      document.addEventListener('click', async (event) => {
        try {
          const clickedElement = event.target;
          var lookUpDropdown = document.getElementById('lookUpDropdown');
          let clickedElementInTheList = clickedElement.getAttribute('data-lookUpClickedText');

          if (lookUpDropdown) {
            if (clickedElementInTheList !== "lookUpClickedText") {            // to remove the result or no record found if clicked somewhere else on the screen starts
              lookUpDropdown.remove();
            } else if (clickedElementInTheList === 'lookUpClickedText') {       // If user clicks on any email from the drop down
              lookUpDropdown.remove();
              this.setState({
                loading: true
              });
              let selectedEmail = clickedElement.getAttribute('data-id');
              var URL = process.env.REACT_APP_API_URL;
              const body = {
                projectId: this.state.eventDetails._id,
                email: selectedEmail,
              };

              const res = await axios.post(`${URL}/attendees/printBadgeForLookupWidget`, body, {
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              if (res.data.success) {
                this.setState({
                  openSuccess: true,
                  openPrintBadgeModal: false,
                  openBadgeConfirmationModal: false,
                  dynmoMessage: "Print your badge!",
                  pintBadge: res.data.page,
                  badgePrintSettings: res.data.pageSettings
                });
                this.handleYesPrintBadge();
              } else {
                if (res.data.badgeAlreadyPrinted) {
                  // If badge is already printed for the given email
                  lookUpForm.insertAdjacentHTML('afterend', '<div id="badgeAlreadyPrintedMessage"></div>');
                  var badgeAlreadyPrintedMessageDiv = document.getElementById('badgeAlreadyPrintedMessage');
                  if (badgeAlreadyPrintedMessageDiv) {
                    badgeAlreadyPrintedMessageDiv.innerHTML += `<p style="color: #e03e2d; border-radius: 5px; padding: 5px 10px; margin-top: 10px; border: 1px solid #e03e2d;">${lookUpBadgeAlreadyPrintedMessage}</p>`;
                    setTimeout(() => {
                      badgeAlreadyPrintedMessageDiv.remove();
                    }, 5000);
                  }
                } else {
                  createNotification('error', "Something went wrong!");
                }
                this.setState({
                  loading: false
                });
              }
            }
          }
          const errorMessageDiv = document.getElementById('errorMessage');
          if (errorMessageDiv) {                                            // to remove no record found if clicked somewhere else on the screen ends
            if (clickedElement.parentNode !== errorMessageDiv) {
              errorMessageDiv.remove();
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }, 0);

    /* Look up widget ends here */

    /* Login form code will start here */
    setTimeout(async () => {
      let loginFormData = document.getElementById('toolLoginForm');
      if (loginFormData) {
        let isSSOEnabled = false;

        if (this.state?.eventDetails && this.state?.eventDetails?.isSSOEnabled) {
          isSSOEnabled = this.state?.eventDetails?.isSSOEnabled;

        }
        const googleSignInButton = document.getElementById('googleSignInBackend');
        const separator = document.getElementById('separator');
        if (isSSOEnabled) {
          if (separator) {
            separator.style.display = 'block';
          }

          googleSignInButton.style.display = 'block';
          googleSignInButton.onclick = async () => {
            var URL = process.env.REACT_APP_API_URL;
            const reqBody = {
              "projectId": this.state.eventDetails._id,
              loginWindow: window.location.href
            };
            const res = await axios.post(`${URL}/integration/google/googleConnectForSignIn`, reqBody, {     // To open the google sign in window 
              headers: {
                'Content-Type': 'application/json',
              },
            });
            let title = 'Connect to Google Workspace Account';
            let width = 500;
            let height = 500;
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2.5;
            const googlePopUp = window.open(res.data.url, title, `width=${width},height=${height},left=${left},top=${top}`);

            const handleMessage = (event) => {
              if (event.source === googlePopUp) {
                const responseData = event.data;
                window.removeEventListener('message', handleMessage);
                googlePopUp.close();
                if (responseData.success) {
                  let googleSignIn_token = responseData?.token?.id_token;
                  let googleSignIn_token_exp = responseData?.token?.expiry_date;
                  handleSubmitLoginForm(null, true, responseData.email, googleSignIn_token, googleSignIn_token_exp); // second argument is for SSO Login
                } else {
                  createNotification('error', 'You are not a part of the Google Workspace account. Please check with the support team');
                }
              }

            };
            window.addEventListener('message', handleMessage);
          };
        } else {
          if (googleSignInButton) {
            googleSignInButton.style.display = 'none';
            if (separator) {
              separator.style.display = 'none';
            }
          }
        }
        /* trying google sign in end*/

        var passwordShowHide = document.getElementById('passwordShowHide');
        var hideIconloginPassword = document.getElementById(
          'hideIconloginPassword',
        );
        var showIconloginPassword = document.getElementById(
          'showIconloginPassword',
        );
        var showAccessCodeField = document.getElementById('showAccessCodeField');
        var replaceAccessMessage = document.getElementById('replaceAccessMessage');
        var accessCodeDataDiv = document.getElementById('accessCodeDataDiv');
        var accessCodeValLogin = document.getElementById('accessCodeValLogin');
        var accessToggleSwitch = document.getElementById('accessToggleSwitch');
        if (showIconloginPassword) {
          showIconloginPassword.style.display = 'none';
        }

        if (passwordShowHide) {
          passwordShowHide.type = 'password';
        }
        if (hideIconloginPassword) {
          hideIconloginPassword.addEventListener('click', function () {
            // close eye icon
            hideIconloginPasswordFun('hide'); // on click of close eye icon = hideIconloginPassword
          });
        }

        if (showIconloginPassword) {
          showIconloginPassword.addEventListener('click', function () {
            // open eye icon
            showIconloginPasswordFun('show');
          });
        }

        if (showIconloginPassword) {
          showIconloginPassword.addEventListener('click', function () {
            // open eye icon
            showIconloginPasswordFun('show');
          });
        }

        /*  setTimeout(() => { 
           if (this.state?.eventDetails?.isAccessCode == true) {
             if (showAccessCodeField) {
               showAccessCodeField.style.display = "flex";
             }
             accessCodeValLogin.value = this.state.eventDetails?.isAccessCode;
           } else {
             if (showAccessCodeField) {
               showAccessCodeField.style.display = "none";
             }
             accessCodeValLogin.value = this.state.eventDetails?.isAccessCode;
           }
         }, 10) */

        function hideIconloginPasswordFun(params) {
          if (showIconloginPassword) {
            showIconloginPassword.style.display = 'block';
          }
          if (hideIconloginPassword) {
            passwordShowHide.type = 'text';
            hideIconloginPassword.style.display = 'none';
          }
        }

        function showIconloginPasswordFun(params) {
          if (showIconloginPassword) {
            showIconloginPassword.style.display = 'none';
            passwordShowHide.type = 'password';
          }
          if (hideIconloginPassword) {
            hideIconloginPassword.style.display = 'block';
          }
        }

        let hProjectId = document.getElementById('projectId');
        if (hProjectId) {
          await getEventForPagesAPI(hProjectId.value).then((eventResult) => {
            if (eventResult?.data?.status === 200) {
              this.setState({
                eventStartDate: get(eventResult, 'data.event.startDate'),
              });
            }
          });
        }

        setTimeout(() => {
          var Startnow = document.getElementById('StartCountDown');
          var SetCountDown = document.getElementById('SetCountDown');
          if (Startnow && Startnow?.value !== 'false') {
            if (
              SetCountDown &&
              SetCountDown.value !== 'undefined' &&
              SetCountDown.value !== ''
            ) {
              // alert(SetCountDown.value);
              let setCount = SetCountDown.value;
              let customDate = new Date(setCount).getTime();
              this.countDown(customDate);
            } else {
              if (this.state.eventStartDate) {
                let statDateIs = new Date(this.state.eventStartDate).getTime();
                this.countDown(statDateIs);
              }
            }
          }
        }, 0);

        const params = this.props.location;
        // const tmessage = new URLSearchParams(params.search).get('LoginError')
        const tmessage = '';
        // if (decodeURIComponent(tmessage) === 'Please Login into an Event') {
        // createNotification('error', tmessage)
        // }
        setTimeout(() => {
          let acssCodeWrapper = document.getElementById('access-code-wrapper');

          if (acssCodeWrapper != null || acssCodeWrapper != undefined) {
            document.getElementById(
              'access-code-wrapper',
            ).innerHTML = `<input class="input" type="hidden" id="accessCodeValLogin" name="accessCodeValLogin" value="${this.state.eventDetails?.isAccessCode}">`;
          } else {
            var textfield = document.createElement('input');
            textfield.type = 'hidden';
            textfield.name = 'accessCodeValLogin';
            textfield.id = 'accessCodeValLogin';
            textfield.value = `${this.state.eventDetails?.isAccessCode}`;
            document.getElementById('toolLoginForm').appendChild(textfield);
          }
        }, 0);

        const handleSubmitLoginForm = async (e, SSOLogin = false, googleUserEmail, googleSignIn_token, googleSignIn_token_exp) => {
          if (!SSOLogin) {
            e?.preventDefault();
          }

          let loaderToggel = document.getElementById('loaderToggel');
          if (loaderToggel) {
            loaderToggel.click();
          }

          var allLoginFields = new FormData(loginFormData);

          let objectLogin = {};
          try {
            objectLogin['ip'] = await publicIP();
          } catch (err) {
            objectLogin['ip'] = '';
          }

          allLoginFields.forEach(function (value, key) {
            if (value === '') return;
            objectLogin[key] = value;
          });
          if (SSOLogin) {
            objectLogin['SSOLogin'] = SSOLogin;
            objectLogin['email'] = googleUserEmail;
            objectLogin['googleSignIn_token_exp'] = googleSignIn_token_exp;
          }
          let loginFormDatajson = JSON.stringify(objectLogin);

          const URL = process.env.REACT_APP_API_URL;
          axios
            .post(`${URL}/users/attendeesLogin`, loginFormDatajson, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((data) => {
              if (SSOLogin) {
                localStorage.setItem('googleSignIn_token', googleSignIn_token);
              }
              let allLoginFields = new FormData(loginFormData);
              if (loaderToggel) {
                loaderToggel.click();
              }
              if (data.data.status === 390) {

                /* show popup and show email on email field */
                let showMyPopUp = document.getElementById('showMyPopUp');
                if (showMyPopUp) {
                  showMyPopUp.click();
                }
                setTimeout(() => {
                  let loginFormData = document.getElementById('toolLoginForm');
                  let allLoginFields = new FormData(loginFormData);
                  let createP = document.getElementById('createPasswordEmail');
                  let cemail = document.getElementById('cemail');
                  let cprojectId = document.getElementById('cprojectId');
                  if (createP) {
                    createP.value = allLoginFields.get('email');
                    cemail.value = allLoginFields.get('email');
                    cprojectId.value = allLoginFields.get('projectId');
                  }
                }, 200);
                /* show popup and show email on email field */

                createNotification(
                  'success',
                  data.data ? data.data.message : 'Create new password!',
                );
              } else if (data.data.status === 200) {
                loginFormData.reset();
                if (
                  data.data.pages &&
                  (data.data.pages.path !== '' || data.data.pages.path !== null)
                ) {
                  localStorage.setItem(
                    'attendee_details',
                    JSON.stringify(data.data.user),
                  );
                } else {
                  createNotification('error', 'Landing page not exits!');
                }
                window.location.href = data.data.redirectTo;
              } else if (data.data.status === 409) {
                if (data.data.data.path) {
                  var decodedMsg = Buffer.from(
                    data.data.message,
                    'base64',
                  ).toString('ascii');
                  this.setState({
                    openEarlyAccess: true,
                    openEarlyAccessMessage: decodedMsg
                  });
                }
                //if event not yet accessible
                if (allLoginFields.get('projectId')) {
                  addEventGA(allLoginFields.get('projectId'), 'FailedToLogin');
                }
              } else if (data.data.status === 403 && data.data?.rdirect !== 'kiosk') {
                if (data.data.status === 403 && data.data.message === "There are no meeting available") {
                  this.props.showCommonPopup({
                    showCommonPopup: true,
                    title: data.data.message,
                    description: data.data.description
                  });
                } else if (data.data.status === 403 && data.data.message === "The meeting has not yet started") {
                  this.props.showCommonPopup({
                    showCommonPopup: true,
                    title: data.data.message,
                    description: data.data.description
                  });
                } else {
                  loginFormData.reset();
                  if (
                    data.data.pages &&
                    (data.data.pages.path !== '' || data.data.pages.path !== null)
                  ) {
                    localStorage.setItem(
                      'attendee_details',
                      JSON.stringify(data.data.user),
                    );
                  } else {
                    createNotification('error', 'Landing page not exits!');
                  }
                  createNotification(
                    data.data.redirectTo == '/profile/access-code' ? 'error' : 'success',
                    data.data.redirectTo == '/profile/access-code' ? data.data.message : 'Login Successfully.',
                  );
                  setTimeout(() => {
                    window.location.href = data.data.redirectTo;
                  }, 10);
                }
              } else {
                createNotification(
                  'error',
                  data.data && data.data.message != ''
                    ? data.data.message
                    : 'Something went wrong!',
                );
                //if incorrect login & others
                if (allLoginFields.get('projectId')) {
                  addEventGA(allLoginFields.get('projectId'), 'FailedToLogin');
                }
              }
            })
            .catch((err) => {
              //Google analytics login event
              let allLoginFields = new FormData(loginFormData);
              if (allLoginFields.get('projectId')) {
                addEventGA(allLoginFields.get('projectId'), 'FailedToLogin');
              }

              if (loaderToggel) {
                loaderToggel.click();
              }
              createNotification(
                'error',
                err.message ? err.message : 'Something went wrong!',
              );
            });
        };

        loginFormData.addEventListener('submit', async function (e) {
          handleSubmitLoginForm(e);
        });
      }
    }, 0);

    const fpEle = document.getElementById('forgetPassword');
    if (fpEle) {
      fpEle.addEventListener('click', (e) => {
        document.getElementById('showFpPopUp').click();
      });
    }



    /* code for add to calender */
    setTimeout(async () => {
      const addToCallendarDiv = document.getElementById('addtoCalendarDiv');
      if (addToCallendarDiv) {
        const calendarTimeTypeHtml = document.getElementById('calendarTimeType');
        let calendarTimeType = "";
        if (calendarTimeTypeHtml) {
          calendarTimeType = calendarTimeTypeHtml.value;
        }
        if (calendarTimeType && calendarTimeType === "PojectStartDate") {

        } else if (calendarTimeType && calendarTimeType === "AgendaStartDate") {
          let geSessionId = document.getElementById('calendarStartDateTime');
          let calendarTimezone = document.getElementById('calendarTimezone');

          let attendee = JSON.parse(localStorage.getItem('attendee_details'));

          if (attendee?.token) {
            let agendaParams = {
              _id: geSessionId?.value,
              timezone: calendarTimezone?.value
            };
            let URL = process.env.REACT_APP_API_URL;
            await axios.post(`${URL}/agenda/getSessionStartDateById`, agendaParams, {
              headers: {
                'Content-Type': 'application/json',
                token: 'bearer ' + attendee.token,
              },
            }).then((dataRes) => {
              if (dataRes.data.status === 200 && dataRes.data.session !== null) {
                if (dataRes.data.session.dateTime) {
                  let agendaStartDateTime = dataRes?.data?.session?.dateTime;
                  let modifiedDate = moment(agendaStartDateTime).format('YYYY-MM-DD h:mm A');
                  let agendEndDate = dataRes?.data?.agendEndDate;
                  let repCalendarStart = document.getElementById('repCalendarStart');
                  let repCalendarEnd = document.getElementById('repCalendarEnd');
                  let repCalendarTitle = document.getElementById('repCalendarTitle');
                  let repCalendarDescription = document.getElementById('repCalendarDescription');
                  let repCalendarLocation = document.getElementById('repCalendarLocation');
                  if (repCalendarStart) {
                    repCalendarStart.innerHTML = modifiedDate;
                  }
                  if (repCalendarEnd) {
                    repCalendarEnd.innerHTML = agendEndDate;
                  }
                  if (repCalendarTitle) {
                    repCalendarTitle.innerHTML = dataRes?.data?.session?.title;
                  }
                  if (repCalendarDescription) {
                    repCalendarDescription.innerHTML = dataRes?.data?.session?.description;
                  }
                  if (repCalendarLocation) {
                    repCalendarLocation.innerHTML = dataRes?.data?.session?.location;
                  }
                }
              }
            });
          }
        } else if (calendarTimeType && calendarTimeType === "CustomDateTime") {

        }

      }
    }, 0);
    /* code for add to calender */
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.googleSignIn?.checkGoogleSignInValidTokenSuccess && !prevProps.googleSignIn?.checkGoogleSignInValidTokenSuccess) {
      let domainUrl = window.location.href;
      let domain = new URL(domainUrl);
      let firstPartOfUrl = `${domain.protocol}//${domain.host}`;

      if (window.location.href !== `${firstPartOfUrl}/logout` && window.location.href !== `${firstPartOfUrl}/` && window.location.href !== `${firstPartOfUrl}/login`) {
        if (!this.props.googleSignIn?.isValidGoogleSignInToken) {
          this.logOut();
        }
      }
    }

    /* Code to catch and show notifications by aws websocket */
    if (this.props.notifications?.awsNotificationData && prevProps.notifications?.awsNotificationData !== this.props.notifications?.awsNotificationData) {
      let Notificationvalues = JSON.parse(this.props.notifications?.awsNotificationData?.notificationData);
      const CurrentPath = window.location.pathname;
      const splitPath = CurrentPath.split('/');
      const finalPath = splitPath[1];
      this.setState({ notificationMessageAws: Notificationvalues }, () =>
        setTimeout(() => {
          if (this.state.notificationMessageAws?.message) {
            if (this.state.notificationMessageAws.sendToPagesOption === "All" && this.state.notificationMessageAws === "All") {
              if (finalPath !== 'kiosk') {
                this.notificationEffects();
              }
            } else if ((this.state.notificationMessageAws.sendToPagesOption === "All" || this.state.notificationMessageAws.sendToPagesOption === "Pages") && this.state.notificationMessageAws.sendToGroupsOption === "All") {
              if (this.state.notificationMessageAws.onPages.indexOf(finalPath) > -1) {
                if (finalPath !== 'kiosk') {
                  this.notificationEffects();
                }
              }
            }

            else if ((this.state.notificationMessageAws.sendToPagesOption === "All" || this.state.notificationMessageAws.sendToPagesOption === "Pages") && this.state.notificationMessageAws.sendToGroupsOption === "Groups") {
              /* No need to check for pages just need to check if attendee is login or not */
              const loginAttendeesDetails = window.localStorage.getItem('attendee_details');
              const savedPerson = JSON.parse(loginAttendeesDetails);
              const attendeeGroup = savedPerson?.groups ? savedPerson?.groups : "";
              if (this.containsAnyGroup(this.state.notificationMessageAws.toGroups, attendeeGroup)) {
                if (finalPath !== 'kiosk') {
                  this.notificationEffects();
                }
              }
            }

            let notificationBtn = document.getElementById('notificationBtn');
            if (notificationBtn) {
              notificationBtn.onclick = () => {
                if (this.state.notificationMessageAws.buttonAction === "closeNotification") {
                } else if (this.state.notificationMessageAws.buttonAction === "refreshPage") {
                  window.location.reload();
                } else if (this.state.notificationMessageAws.buttonAction === "redirectToUrl") {
                  window.location.href = this.state.notificationMessageAws.externalURL;
                }
              };
            }
          }
        }, 0)
      );

    }
    /* Code to catch and show notifications by aws websocket */

    if (
      this.props.accessCode.newAccessCodeExpiryTime?.expirydate != null &&
      prevProps.accessCode.newAccessCodeExpiryTime?.expirydate != this.props.accessCode.newAccessCodeExpiryTime?.expirydate &&
      this.props.accessCode.newAccessCodeExpiryTime.message != "agenda updated"
    ) {
      let customWidgetDatetimernn = this.props.accessCode.newAccessCodeExpiryTime.expirydate;
      var customWidgetDateE = new Date(customWidgetDatetimernn).getTime();
      if (this.props.accessCode.newAccessCodeExpiryTime.message == 'New access code assigned') {
        this.setState({ widgetTimerStartDateTime: customWidgetDateE });
        this.countDownWidgetTimerFun();
      } else {
        this.setState({ widgetTimerStartDateTime: customWidgetDateE });
        this.countDownWidgetTimerFun();
      }
    }
    var agendaId = '';
    var timerStartDateHtmlne = document.getElementById('timerStartDate');
    if (timerStartDateHtmlne && this.props.accessCode.newAccessCodeExpiryTime?.expirydate != null && prevProps.accessCode.newAccessCodeExpiryTime?.expirydate != this.props.accessCode.newAccessCodeExpiryTime?.expirydate && (this.props.accessCode.newAccessCodeExpiryTime.message == 'agenda deleted' || this.props.accessCode.newAccessCodeExpiryTime.message == 'agenda updated')) {
      agendaId = timerStartDateHtmlne.value;
      customWidgetDateE = this.props.accessCode.newAccessCodeExpiryTime?.expirydate;
      if (this.props.accessCode.newAccessCodeExpiryTime.agendaId == agendaId) {
        this.setState({ widgetTimerStartDateTime: customWidgetDateE });
        this.countDownWidgetTimerFun();
      }
    }



  }

  afterBadgePrint = () => {
    this.setState({ handleOpenPrintPreviewModal: true });
    setTimeout(() => {
      this.setState({
        handleOpenPrintPreviewModal: false,
      });
    }, 5000);
  };

  handleOkPrintPeview = () => {
    this.setState({
      handleOpenPrintPreviewModal: false,
    });
  };

  notificationEffects = () => {
    this.setState({
      notificationPopup: true
    });
    let notificationPopupId = document.getElementById('notificationPopupId');
    if (notificationPopupId) {
      notificationPopupId.classList.remove('center');
      notificationPopupId.classList.remove('top-left');
      notificationPopupId.classList.remove('bottom-left');
      notificationPopupId.classList.remove('top-right');
      notificationPopupId.classList.remove('bottom-right');
      notificationPopupId.classList.add(this.state.notificationMessageAws.position);
      notificationPopupId.style.borderColor = this.state.notificationMessageAws.color;
    }
    let notificationBtn = document.getElementById('notificationBtn');
    if (notificationBtn) {
      notificationBtn.style.backgroundColor = this.state.notificationMessageAws.color;
    }
  };

  /* Login form code will end here */
  /* Login form countdown start here */
  countDown = (eventStartDate) => {
    var StartCountDown = document.getElementById('StartCountDown');
    if (StartCountDown && StartCountDown?.value !== 'undefined' && StartCountDown?.value !== 'false') {
      // The data/time we want to countdown to
      setTimeout(() => {
        var CountDownBackgroundColor = document.getElementById(
          'CountDownBackgroundColor',
        );
        var backgroundColor = '';
        var fontColor = '';
        if (CountDownBackgroundColor && !CountDownBackgroundColor.value == '') {
          if (CountDownBackgroundColor.value) {
            backgroundColor = CountDownBackgroundColor.value;
          } else {
            backgroundColor = '#281374';
          }
        }

        var CountDownTextColor = document.getElementById('CountDownTextColor');
        if (CountDownTextColor && !CountDownTextColor.value == '') {
          if (CountDownTextColor.value) {
            fontColor = CountDownTextColor.value;
          } else {
            fontColor = '#ffffff';
          }
        }
        var CountDownAlign = '';
        var bgcolor = backgroundColor;
        var mainbody = document.getElementById('product-card-body');
        if (mainbody) {
          if (!document.getElementById('coming-soon-left-top')) {
            mainbody.insertAdjacentHTML(
              'afterbegin',
              ' <div class="coming-soon-left-top" id="coming-soon-left-top" style="text-align:' +
              CountDownAlign +
              '"> <div class="lauch-div" id="demo"></div></div>',
            );
          }
        }
        var timerHtml =
          '<div  class="date-list" style="background-color:' +
          bgcolor +
          '; color: ' +
          fontColor +
          '" id="days"></div><div class="date-list" style="background-color:' +
          bgcolor +
          '; color: ' +
          fontColor +
          '" id="hours"></div><div  class="date-list" style="background-color:' +
          bgcolor +
          '; color: ' +
          fontColor +
          '" id="mins"></div><div  class="date-list" style="background-color:' +
          bgcolor +
          '; color: ' +
          fontColor +
          '" id="secs"></div>';
        var timerHtmlVar = document.getElementById('demo');

        var countDownDate = new Date(eventStartDate).getTime();
        // Run myfunc every second
        var myfunc = setInterval(function () {
          var now = new Date().getTime();
          var timeleft = countDownDate - now;
          // Calculating the days, hours, minutes and seconds left
          var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
          var hours = Math.floor(
            (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

          var demoBody = document.getElementById('demo');
          if (demoBody) {
            if (timerHtmlVar) {
              timerHtmlVar.innerHTML = timerHtml;
            }
          }
          // Result is output to the specific element
          var timerdays = document.getElementById('days');
          if (timerdays) {
            timerdays.innerHTML =
              days + " <span style='display: block'>DAYS</span> ";
            document.getElementById('hours').innerHTML =
              hours + "<span style='display: block'>  HRS</span>  ";
            document.getElementById('mins').innerHTML =
              minutes + "<span style='display: block'>  MINS</span>  ";
            document.getElementById('secs').innerHTML =
              seconds + "<span style='display: block'>  SECS</span>  ";
          }
          // Display the message when countdown is over
          if (timeleft < 0) {
            clearInterval(myfunc);
            document.getElementById('days').innerHTML = '';
            document.getElementById('hours').innerHTML = '';
            document.getElementById('mins').innerHTML = '';
            document.getElementById('secs').innerHTML = '';
            var TimerBody1 = document.getElementById('coming-soon-left-top');
            if (TimerBody1) {
              TimerBody1.remove();
            }
          }
        }, 1000);
      }, 0);
    } else {
      var TimerBody = document.getElementById('coming-soon-left-top');
      if (TimerBody) {
        TimerBody.remove();
      }
    }
  };

  containsAnyGroup = (source, target) => {
    var result = source.filter(function (item) { return target.indexOf(item) > -1; });
    return (result.length > 0);
  };
  /* Login form countdown end here */
  countDownWidgetTimerFun = () => {
    var StartCountDown = document.getElementById('StartWidgetCountDown');
    if (StartCountDown && StartCountDown?.value !== 'false') {
      // The data/time we want to countdown to
      setTimeout(() => {
        var CountDownBackgroundColor = document.getElementById(
          'CountDownWidgetBackgroundColor',
        );
        var backgroundColor = '';
        var fontColor = '';
        if (CountDownBackgroundColor && !CountDownBackgroundColor.value == '') {
          if (CountDownBackgroundColor.value) {
            backgroundColor = CountDownBackgroundColor.value;
          } else {
            backgroundColor = '#281374';
          }
        }

        var CountDownTextColor = document.getElementById(
          'CountDownWidgetTextColor',
        );
        if (CountDownTextColor && !CountDownTextColor.value == '') {
          if (CountDownTextColor.value) {
            fontColor = CountDownTextColor.value;
          } else {
            fontColor = '#ffffff';
          }
        }

        var timerTimeType = '';
        var timerTimeTypeHtml = document.getElementById('timerTimeType');
        if (timerTimeTypeHtml) {
          timerTimeType = timerTimeTypeHtml.value;
        }

        var timerStartDate = '';
        var timerStartDateHtml = document.getElementById('timerStartDate');
        if (timerStartDateHtml) {
          timerStartDate = timerStartDateHtml.value;
        }

        var CountDownWidgetDaysLabel = '';
        var CountDownWidgetDaysLabelHtml = document.getElementById(
          'CountDownWidgetDaysLabel',
        );
        if (CountDownWidgetDaysLabelHtml) {
          CountDownWidgetDaysLabel = CountDownWidgetDaysLabelHtml.value;
        }

        var CountDownWidgetHrsLabel = '';
        var CountDownWidgetHrsLabelHtml = document.getElementById(
          'CountDownWidgetHrsLabel',
        );
        if (CountDownWidgetHrsLabelHtml) {
          CountDownWidgetHrsLabel = CountDownWidgetHrsLabelHtml.value;
        }
        var CountDownWidgetMinsLabel = '';
        var CountDownWidgetMinsLabelHtml = document.getElementById(
          'CountDownWidgetMinsLabel',
        );
        if (CountDownWidgetMinsLabelHtml) {
          CountDownWidgetMinsLabel = CountDownWidgetMinsLabelHtml.value;
        }

        var CountDownWidgetSecsLabel = '';
        var CountDownWidgetSecsLabellHtml = document.getElementById(
          'CountDownWidgetSecsLabel',
        );
        if (CountDownWidgetSecsLabellHtml) {
          CountDownWidgetSecsLabel = CountDownWidgetSecsLabellHtml.value;
        }

        var StartWidgetCountDown = '';
        var StartWidgetCountDownHtml = document.getElementById(
          'StartWidgetCountDown',
        );
        if (StartWidgetCountDownHtml) {
          StartWidgetCountDown = StartWidgetCountDownHtml.value;
        }

        var cdctBorderTopStyle = '';
        var cdctBorderTopStyleHtml =
          document.getElementById('cdctBorderTopStyle');
        if (cdctBorderTopStyleHtml) {
          cdctBorderTopStyle = cdctBorderTopStyleHtml.value;
        }

        var cdctBorderTopColor = '';
        var cdctBorderTopColorHtml =
          document.getElementById('cdctBorderTopColor');
        if (cdctBorderTopColorHtml) {
          cdctBorderTopColor = cdctBorderTopColorHtml.value;
        }

        var cdctBorderTopWidth = '';
        var cdctBorderTopWidthHtml =
          document.getElementById('cdctBorderTopWidth');
        if (cdctBorderTopWidthHtml) {
          cdctBorderTopWidth = cdctBorderTopWidthHtml.value;
        }

        var cdctBorderLeftStyle = '';
        var cdctBorderLeftStyleHtml = document.getElementById(
          'cdctBorderLeftStyle',
        );
        if (cdctBorderLeftStyleHtml) {
          cdctBorderLeftStyle = cdctBorderLeftStyleHtml.value;
        }
        var cdctBorderLeftColor = '';
        var cdctBorderLeftColorHtml = document.getElementById(
          'cdctBorderLeftColor',
        );
        if (cdctBorderLeftColorHtml) {
          cdctBorderLeftColor = cdctBorderLeftColorHtml.value;
        }
        var cdctBorderLeftWidth = '';
        var cdctBorderLeftWidthHtml = document.getElementById(
          'cdctBorderLeftWidth',
        );
        if (cdctBorderLeftWidthHtml) {
          cdctBorderLeftWidth = cdctBorderLeftWidthHtml.value;
        }
        var cdctBorderRightStyle = '';
        var cdctBorderRightStyleHtml = document.getElementById(
          'cdctBorderRightStyle',
        );
        if (cdctBorderRightStyleHtml) {
          cdctBorderRightStyle = cdctBorderRightStyleHtml.value;
        }

        var cdctBorderRightColor = '';
        var cdctBorderRightColorHtml = document.getElementById(
          'cdctBorderRightColor',
        );
        if (cdctBorderRightColorHtml) {
          cdctBorderRightColor = cdctBorderRightColorHtml.value;
        }

        var cdctBorderRightWidth = '';
        var cdctBorderRightWidthHtml = document.getElementById(
          'cdctBorderRightWidth',
        );
        if (cdctBorderRightWidthHtml) {
          cdctBorderRightWidth = cdctBorderRightWidthHtml.value;
        }

        var cdctBorderBottomStyle = '';
        var cdctBorderBottomStyleHtml = document.getElementById(
          'cdctBorderBottomStyle',
        );
        if (cdctBorderBottomStyleHtml) {
          cdctBorderBottomStyle = cdctBorderBottomStyleHtml.value;
        }

        var cdctBorderBottomColor = '';
        var cdctBorderBottomColorHtml = document.getElementById(
          'cdctBorderBottomColor',
        );
        if (cdctBorderBottomStyleHtml) {
          cdctBorderBottomColor = cdctBorderBottomColorHtml.value;
        }

        var cdctBorderBottomWidth = '';
        var cdctBorderBottomWidthHtml = document.getElementById(
          'cdctBorderBottomWidth',
        );
        if (cdctBorderBottomWidthHtml) {
          cdctBorderBottomWidth = cdctBorderBottomWidthHtml.value;
        }

        var cdtwctRoundedBorderAll = '';
        var cdtwctRoundedBorderAllHtml = document.getElementById(
          'cdtwctRoundedBorderAll',
        );
        if (cdtwctRoundedBorderAllHtml) {
          cdtwctRoundedBorderAll = cdtwctRoundedBorderAllHtml.value;
        }
        var cdtwctRoundedBorderTopLeft = '';
        var cdtwctRoundedBorderTopLeftHtml = document.getElementById(
          'cdtwctRoundedBorderTopLeft',
        );
        if (cdtwctRoundedBorderTopLeftHtml) {
          cdtwctRoundedBorderTopLeft = cdtwctRoundedBorderTopLeftHtml.value;
        }

        var cdtwctRoundedBorderTopRight = '';
        var cdtwctRoundedBorderTopRightHtml = document.getElementById(
          'cdtwctRoundedBorderTopRight',
        );
        if (cdtwctRoundedBorderTopRightHtml) {
          cdtwctRoundedBorderTopRight = cdtwctRoundedBorderTopRightHtml.value;
        }

        var cdtwctRoundedBorderBottomleft = '';
        var cdtwctRoundedBorderBottomleftHtml = document.getElementById(
          'cdtwctRoundedBorderBottomleft',
        );
        if (cdtwctRoundedBorderBottomleftHtml) {
          cdtwctRoundedBorderBottomleft =
            cdtwctRoundedBorderBottomleftHtml.value;
        }

        var cdtwctRoundedBorderBottomRight = '';
        var cdtwctRoundedBorderBottomRightHtml = document.getElementById(
          'cdtwctRoundedBorderBottomRight',
        );
        if (cdtwctRoundedBorderBottomRightHtml) {
          cdtwctRoundedBorderBottomRight =
            cdtwctRoundedBorderBottomRightHtml.value;
        }
        var projectId = '';
        var projectIdHtml = document.getElementById('projectId');
        if (projectIdHtml) {
          projectId = projectIdHtml.value;
        }

        var cdctBorderTopColor = '';
        var cdctBorderTopColorHtml =
          document.getElementById('cdctBorderTopColor');
        if (cdctBorderTopColorHtml) {
          cdctBorderTopColor = cdctBorderTopColorHtml.value;
        }

        var cdctBorderTopWidth = '';
        var cdctBorderTopWidthHtml =
          document.getElementById('cdctBorderTopWidth');
        if (cdctBorderTopWidthHtml) {
          cdctBorderTopWidth = cdctBorderTopWidthHtml.value;
        }

        var cdctBorderLeftStyle = '';
        var cdctBorderLeftStyleHtml = document.getElementById(
          'cdctBorderLeftStyle',
        );
        if (cdctBorderLeftStyleHtml) {
          cdctBorderLeftStyle = cdctBorderLeftStyleHtml.value;
        }
        var cdctBorderLeftColor = '';
        var cdctBorderLeftColorHtml = document.getElementById(
          'cdctBorderLeftColor',
        );
        if (cdctBorderLeftColorHtml) {
          cdctBorderLeftColor = cdctBorderLeftColorHtml.value;
        }
        var cdctBorderLeftWidth = '';
        var cdctBorderLeftWidthHtml = document.getElementById(
          'cdctBorderLeftWidth',
        );
        if (cdctBorderLeftWidthHtml) {
          cdctBorderLeftWidth = cdctBorderLeftWidthHtml.value;
        }
        var cdctBorderRightStyle = '';
        var cdctBorderRightStyleHtml = document.getElementById(
          'cdctBorderRightStyle',
        );
        if (cdctBorderRightStyleHtml) {
          cdctBorderRightStyle = cdctBorderRightStyleHtml.value;
        }

        var cdctBorderRightColor = '';
        var cdctBorderRightColorHtml = document.getElementById(
          'cdctBorderRightColor',
        );
        if (cdctBorderRightColorHtml) {
          cdctBorderRightColor = cdctBorderRightColorHtml.value;
        }

        var cdctBorderRightWidth = '';
        var cdctBorderRightWidthHtml = document.getElementById(
          'cdctBorderRightWidth',
        );
        if (cdctBorderRightWidthHtml) {
          cdctBorderRightWidth = cdctBorderRightWidthHtml.value;
        }

        var cdctBorderBottomStyle = '';
        var cdctBorderBottomStyleHtml = document.getElementById(
          'cdctBorderBottomStyle',
        );
        if (cdctBorderBottomStyleHtml) {
          cdctBorderBottomStyle = cdctBorderBottomStyleHtml.value;
        }

        var cdctBorderBottomColor = '';
        var cdctBorderBottomColorHtml = document.getElementById(
          'cdctBorderBottomColor',
        );
        if (cdctBorderBottomStyleHtml) {
          cdctBorderBottomColor = cdctBorderBottomColorHtml.value;
        }

        var cdctBorderBottomWidth = '';
        var cdctBorderBottomWidthHtml = document.getElementById(
          'cdctBorderBottomWidth',
        );
        if (cdctBorderBottomWidthHtml) {
          cdctBorderBottomWidth = cdctBorderBottomWidthHtml.value;
        }

        var cdtwctRoundedBorderAll = '';
        var cdtwctRoundedBorderAllHtml = document.getElementById(
          'cdtwctRoundedBorderAll',
        );
        if (cdtwctRoundedBorderAllHtml) {
          cdtwctRoundedBorderAll = cdtwctRoundedBorderAllHtml.value;
        }
        var cdtwctRoundedBorderTopLeft = '';
        var cdtwctRoundedBorderTopLeftHtml = document.getElementById(
          'cdtwctRoundedBorderTopLeft',
        );
        if (cdtwctRoundedBorderTopLeftHtml) {
          cdtwctRoundedBorderTopLeft = cdtwctRoundedBorderTopLeftHtml.value;
        }

        var cdtwctRoundedBorderTopRight = '';
        var cdtwctRoundedBorderTopRightHtml = document.getElementById(
          'cdtwctRoundedBorderTopRight',
        );
        if (cdtwctRoundedBorderTopRightHtml) {
          cdtwctRoundedBorderTopRight = cdtwctRoundedBorderTopRightHtml.value;
        }

        var cdtwctRoundedBorderBottomleft = '';
        var cdtwctRoundedBorderBottomleftHtml = document.getElementById(
          'cdtwctRoundedBorderBottomleft',
        );
        if (cdtwctRoundedBorderBottomleftHtml) {
          cdtwctRoundedBorderBottomleft =
            cdtwctRoundedBorderBottomleftHtml.value;
        }

        var cdtwctRoundedBorderBottomRight = '';
        var cdtwctRoundedBorderBottomRightHtml = document.getElementById(
          'cdtwctRoundedBorderBottomRight',
        );
        if (cdtwctRoundedBorderBottomRightHtml) {
          cdtwctRoundedBorderBottomRight =
            cdtwctRoundedBorderBottomRightHtml.value;
        }
        var projectId = '';
        var projectIdHtml = document.getElementById('projectId');
        if (projectIdHtml) {
          projectId = projectIdHtml.value;
        }

        var CountDownAlign = '';
        var bgcolor = backgroundColor;
        var mainbodyTimerWidget = document.getElementById(
          'product-card-body-timer',
        );
        if (mainbodyTimerWidget) {
          if (!document.getElementById('coming-soon-left-top-widget')) {
            mainbodyTimerWidget.insertAdjacentHTML(
              'afterbegin',
              ' <div class="coming-soon-left-top-widget" id="coming-soon-left-top-widget" style="text-align:' +
              CountDownAlign +
              '"> <div class="lauch-div" id="countdown-timer-custom-tool-demo"></div></div>',
            );
          }
        }
        var timerHtmlWidget = `<div style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor}; border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;" class="date-list " id="daysTimer"></div>
        
        
        <div style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor};border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;"class="date-list" id="hoursTimer"></div>
        
        <div class="date-list" style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor};border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;" id="minsTimer"></div> 
        
        <div style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor};border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;" class="date-list" id="secsTimer"></div>`;

        var timerHtmlWidgetVar = document.getElementById(
          'countdown-timer-custom-tool-demo',
        );
        var daysTimerLabelHtml = document.getElementById(
          'CountDownWidgetDaysLabel',
        );
        var hrsTimerLabelHtml = document.getElementById(
          'CountDownWidgetHrsLabel',
        );
        var minsTimerLabelHtml = document.getElementById(
          'CountDownWidgetMinsLabel',
        );
        var secsTimerLabelHtml = document.getElementById(
          'CountDownWidgetSecsLabel',
        );
        if (daysTimerLabelHtml) {
          var daysTimerLabel = daysTimerLabelHtml.value
            ? daysTimerLabelHtml.value
            : '';
        }
        if (hrsTimerLabelHtml) {
          var hrsTimerLabel = hrsTimerLabelHtml.value
            ? hrsTimerLabelHtml.value
            : '';
        }
        if (minsTimerLabelHtml) {
          var minsTimerLabel = minsTimerLabelHtml.value
            ? minsTimerLabelHtml.value
            : '';
        }
        if (secsTimerLabelHtml) {
          var secsTimerLabel = secsTimerLabelHtml.value
            ? secsTimerLabelHtml.value
            : '';
        }
        var myfunctInterval = setInterval(() => {
          let nowTime = new Date().getTime();
          let timeleft = this.state.widgetTimerStartDateTime - nowTime;
          let lifetimeValue = parseInt(ACCESS_CODE_EXPERING_PERIOD * 60 * 1000);
          if (timeleft > 0) {
            if (parseInt(timeleft) < lifetimeValue) {
              if (timerHtmlWidgetVar) {
                timerHtmlWidgetVar.innerHTML = timerHtmlWidget;
              }
              // Calculating the days, hours, minutes and seconds left
              var daysTimer =
                timeleft > 0 ? Math.floor(timeleft / (1000 * 60 * 60 * 24)) : 0;
              var hoursTimer =
                timeleft > 0
                  ? Math.floor(
                    (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                  )
                  : 0;
              var minutesTimer =
                timeleft > 0
                  ? Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
                  : 0;
              var secondsTimer =
                timeleft > 0 ? Math.floor((timeleft % (1000 * 60)) / 1000) : 0;

              // Result is output to the specific element
              var timerDays = document.getElementById('daysTimer');

              if (timerDays) {
                timerDays.innerHTML =
                  daysTimer +
                  " <span style='display: block'>" +
                  daysTimerLabel +
                  '</span> ';
              }
              var timerHours = document.getElementById('hoursTimer');
              if (timerHours) {
                timerHours.innerHTML =
                  hoursTimer +
                  "<span style='display: block'>  " +
                  hrsTimerLabel +
                  '</span>';
              }

              var timerMins = document.getElementById('minsTimer');
              if (timerMins) {
                timerMins.innerHTML =
                  minutesTimer +
                  "<span style='display: block'>  " +
                  minsTimerLabel +
                  '</span>';
              }

              var timerSecs = document.getElementById('secsTimer');
              if (timerSecs) {
                timerSecs.innerHTML =
                  secondsTimer +
                  "<span style='display: block'>  " +
                  secsTimerLabel +
                  '</span>';
              }
            } else {
              //if we are assigning lifetime we need to remove timer as we need
              var TimerBody = document.getElementById(
                'coming-soon-left-top-widget',
              );
              if (TimerBody) {
                TimerBody.remove();
              }
            }
          } else {
            var TimerBody = document.getElementById(
              'coming-soon-left-top-widget',
            );
            if (TimerBody) {
              TimerBody.remove();
              if (timerTimeType == "AccessCodeTimer") {
                window.location.href = '/profile/access-code';  //need to redirect as access code part
              }
              //localStorage.clear();
            }
          }
          // Display the message when counter is over
          /*  if (timeleft < 0) {
            // Calculating the days, hours, minutes and seconds left
            var daysTimer =
              timeleft > 0 ? Math.floor(timeleft / (1000 * 60 * 60 * 24)) : 0
            var hoursTimer =
              timeleft > 0
                ? Math.floor(
                    (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                  )
                : 0
            var minutesTimer =
              timeleft > 0
                ? Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
                : 0
            var secondsTimer =
              timeleft > 0 ? Math.floor((timeleft % (1000 * 60)) / 1000) : 0

            // Result is output to the specific element
            var timerDays = document.getElementById('daysTimer')
            if (timerDays) {
              timerDays.innerHTML =
                daysTimer +
                " <span style='display: block'>" +
                daysTimerLabel +
                '</span> '
            }
            var timerHours = document.getElementById('hoursTimer')
            if (timerHours) {
              timerHours.innerHTML =
                hoursTimer +
                "<span style='display: block'> " +
                hrsTimerLabel +
                '</span>'
            }
            var timerMins = document.getElementById('minsTimer')
            if (timerMins) {
              timerMins.innerHTML =
                minutesTimer +
                "<span style='display: block'> " +
                minsTimerLabel +
                '</span>'
            }
            var timerSecs = document.getElementById('secsTimer')
            if (timerSecs) {
              timerSecs.innerHTML =
                secondsTimer +
                "<span style='display: block'>   " +
                secsTimerLabel +
                '</span>'
            }
          } */
        }, 1000);
      }, 0);
    } else {
      var TimerBody = document.getElementById('coming-soon-left-top-widget');
      if (TimerBody) {
        TimerBody.remove();
      }
    }
  };

  togglelodder = () => {
    this.setState({ loading: !this.state.loading });
  };

  notificationHide = () => {
    this.setState({ notificationPopup: false });
  };
  notificationShow = () => {
    // this.setState({ notificationPopup: true });
  };

  toggleShowPassword = () => {
    this.setState({ ShowPassword: !this.state.ShowPassword });
  };
  toggleShowConfirmPassword = () => {
    this.setState({ ShowConfirmPassword: !this.state.ShowConfirmPassword });
  };

  handleClickOpenCreatePasswordForm = () => {
    this.setState({
      openCreatePasswordForm: true,
    });
  };

  handleClickOpenForgotPasswordForm = () => {
    this.setState({
      openForgotPasswordForm: true,
    });
  };

  handleClickCloseCreatePasswordForm = () => {
    this.setState({
      openCreatePasswordForm: false,
    });
  };

  handleClickCloseForgotPasswordForm = () => {
    this.setState({
      openForgotPasswordForm: false,
    });
  };

  handleClickOpenSuccess = () => {
    this.setState({ openSuccess: true });
  };

  handleClickCloseSuccess = () => {
    this.setState({ openSuccess: false });
    window.location.href = this.state.redirectionPath;
  };
  handleClickCloseSuccessPrintBadge = () => {
    this.setState({ openSuccess: false });
  };


  handleOpenConfirmationModal = () => {
    this.setState({
      openBadgeConfirmationModal: true,

    });
  };

  handleOpenPrintPreviewModal = () => {
    this.setState({
      printPreviewSuccessModal: true,
    });
  };

  closePrintPreviewModal = () => {
    this.setState({
      printPreviewSuccessModal: false,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      openBadgeConfirmationModal: false,
    });
  };


  handleYesPrintBadge = () => {
    try {
      this.setState({
        openPrintBadgeModal: true,
        openBadgeConfirmationModal: false,
      });
      setTimeout(() => {
        this.setState({
          openPrintBadgeModal: false
        });

        let handlePrintHtml = document.getElementById("handlePrintBtn");
        if (handlePrintHtml) {
          handlePrintHtml.click();
        }
      }, 0);
      this.setState({
        loading: false
      });

      this.changeBadgeAlreadyPrintedToTrue();

    } catch (error) {
      return error;
    }
  };




  /* After the badge is printed we have to change badgeAlreadyPrinted flag to true*/
  changeBadgeAlreadyPrintedToTrue = async () => {
    try {
      const { email, projectId } = this.state.attendeeDataForBadgeAlreadyPrintedApi;
      let body = {
        email: email,
        projectId: projectId
      };
      var URL = process.env.REACT_APP_API_URL;
      await axios.post(`${URL}/attendees/badgePrinted`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log(error);
    }

  };

  /* creage password submit form */
  saveCreatePassword = () => {
    let error = {};
    let isValid = true;
    let createPasswordForm = document.getElementById('createPasswordForm');
    if (createPasswordForm) {
      var creagePasswordFields = new FormData(createPasswordForm);
      const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

      if (
        creagePasswordFields.get('password').trim() === '' ||
        creagePasswordFields.get('password').trim().length === 0 ||
        creagePasswordFields.get('password') === undefined
      ) {
        error['password'] = 'Password cannot be blank.';
        isValid = false;
      }

      if (
        creagePasswordFields.get('confirm-password').trim() === '' ||
        creagePasswordFields.get('confirm-password').trim().length === 0 ||
        creagePasswordFields.get('confirm-password') === undefined
      ) {
        error['confirm-password'] = 'Password cannot be blank.';
        isValid = false;
      }
      this.setState({
        errors: error,
      });

      if (!isValid) {
        return;
      }

      if (
        creagePasswordFields.get('cemail') &&
        regexTest.test(creagePasswordFields.get('cemail')) === false
      ) {
        error['cemail'] = 'Please enter a valid email address.';
        isValid = false;
        return false;
      }
      if (
        creagePasswordFields.get('password') !==
        creagePasswordFields.get('confirm-password')
      ) {
        error['confirm-password'] = 'Password not matched with new password';
        this.setState({
          errors: error,
        });
        return false;
      } else if (
        creagePasswordFields.get('password') === '' ||
        creagePasswordFields.get('confirm-password') === ''
      ) {
        createNotification(
          'error',
          'Password and Confirm Password should not empty!',
        );
        return false;
      }

      const pass = creagePasswordFields.get('password');
      if (
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/.test(pass) ===
        false
      ) {
        error['password'] = 'Please enter password as mentioned in the rules';
        this.setState({
          errors: error,
        });
        return false;
      }

      let objectCreatePassword = {};
      creagePasswordFields.forEach(function (value, key) {
        if (value === '') return;
        objectCreatePassword[key] = value;
      });
      let createPasswordFormJson = JSON.stringify(objectCreatePassword);
      const URL = process.env.REACT_APP_API_URL;
      axios
        .post(`${URL}/attendees/creageNewPassword`, createPasswordFormJson, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((data) => {
          if (data.data.status === 200) {
            createNotification(
              'success',
              data.data
                ? data.data.message
                : 'Password created Successfully, Now you can use this password for Login.',
            );
            setTimeout(() => {
              this.handleClickCloseCreatePasswordForm();
            }, 300);
          } else {
            createNotification('error', 'Something went wrong!');
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  /* creage password submit form */
  sendLink = () => {
    let forgotPaswordForm = document.getElementById('forgotPaswordForm');
    if (forgotPaswordForm) {
      let loginFormData = document.getElementById('toolLoginForm');
      let allLoginFields = new FormData(loginFormData);
      let fpProjectId = document.getElementById('fpprojectId');
      fpProjectId.value = allLoginFields.get('projectId');

      var forgotPaswordFields = new FormData(forgotPaswordForm);
      if (forgotPaswordFields.get('forgotPasswordEmail') === '') {
        createNotification('error', 'Email is required');
        return false;
      }
      const regexTest = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (
        forgotPaswordFields.get('forgotPasswordEmail') &&
        regexTest.test(forgotPaswordFields.get('forgotPasswordEmail')) === false
      ) {
        createNotification('error', 'Please enter a valid email address.');
        return false;
      }

      let objectForgotPassword = {};
      forgotPaswordFields.forEach(function (value, key) {
        if (value === '') return;
        objectForgotPassword[key] = value;
      });
      let forgotPasswordFormJson = JSON.stringify(objectForgotPassword);
      const URL = process.env.REACT_APP_API_URL;
      axios
        .post(
          `${URL}/attendees/sendResetPasswordLink`,
          forgotPasswordFormJson,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((data) => {
          if (data.data.status === 200) {
            createNotification(
              'success',
              data.data
                ? data.data.message
                : 'Password creaged Successfully, Now you can use this password for Login.',
            );
            this.handleClickCloseForgotPasswordForm();
          } else {
            createNotification('error', data.data.error);
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  handleClickCloseEarlyAccess = () => {
    this.setState({
      openEarlyAccess: false,
    });
  };

  openMyProfile = () => {
    this.props.navigate('/profile/update-profile', {
      state: { pathlocation: this.state.pathlocation },
      replace: true,
    });
  }; 

  callYTApi = async (params) => {
    await addGamificationPoinstYT(params).then((res) => { });
  };

  closePageAccessRestrictionMessage = () => {
    this.setState({
      pageAccessRestrictionMessage: false,
    });
    let homePage = this.props.allPagesPath.find(path => path.isHomePage === true);

    //if there is not homepage then we handle it with any other page as homepage
    if (!homePage) {
      homePage = this.props.allPagesPath.find(
        path => !path.isLoginPage && path.path !== "kiosk"
      );
    }
    //if there is not homepage then we handle it with any other page as homepage
    
    this.props.navigate({ pathname: homePage.path });
  };

  closeEventExpiredMessage = () => {
    this.setState({
      eventExpiredMessage: false,
    });
  };

  closeCommonPopup = () => {
    this.props.showCommonPopup({
      showCommonPopup: false,
      title: "",
      description: ""
    });
    if (this.props.events.commonPopupDetails?.title === 'Meeting Ended') {
      window.location.href = '/login';
    }
  };

  render() {
    let { html, customCss, pageTitle, pageDesc } = this.state.templates;
    let track = 0;
    const {
      loading,
      notificationPopup,
      showProfileButton,
      searchEnginesIndex,
      isProfileVisible,
      favIcon,
      customeJs,
      attendeeData,
      isPrivatePage,
      badgePrintSettings,
      attendeesDetail,
      openEarlyAccessMessage
    } = this.state;
    var addIndexOnPage = false;
    if (searchEnginesIndex == true) {
      addIndexOnPage = false;
    } else {
      addIndexOnPage = true;
    }

    const parseOptions = {
      replace: (node) => {
        //for adding customejs & customcss in the html head
        if (track === 0) {
          if (!this.state.customJsAdded) {
            if (customCss && isUrlValid(customCss)) {
              var link = document.createElement('link');
              link.rel = 'stylesheet';
              link.type = 'text/css';
              link.href = customCss;
              document.head.appendChild(link);
            }
            if (customeJs && isUrlValid(customeJs)) {
              var script = document.createElement('script');
              script.src = customeJs;
              document.head.appendChild(script);
            }
          }
        }
        track = 1;

        /*modifications in the html nodes-start*/
        if (!node.attribs) {
          return;
        }

        /*
        *change <a> tag in html into <Link> tag of react-router 
        *
        *to prevent full page reload when navigate to other pages
        *
        *if full page reloaded then the websocket connection will be lost
        *
        * we have limited WS connections per sec in aws
        */
        if (node.type === 'tag' && node.name === 'a') {
          if (node.attribs.href) {
            let isprojectPage = this.props.allPagesPath.find(
              (path) => path.path === node.attribs.href,
            );
            if (isprojectPage) {
              if (node.attribs.class) {
                node.attribs.className = node.attribs.class;
                delete node.attribs.class;
              }
              if (node.attribs.style) {
                delete node.attribs.style;
              }
              return (
                <Link to={node.attribs.href} {...node.attribs}>
                  {domToReact(node.children, parseOptions)}
                </Link>
              );
            }
          }
        }

        /*
        *to secure private page images, we are verifying jwt token sending
        *
        *via img req in aws cloudfront function
        *
        *so we need to send jwt token if page visibility is private  
        */
        if (isPrivatePage === true && attendeeData.token) {
          //for normal img
          if (node.type === "tag" && node.name === "img") {
            if (node.attribs?.class == "outputQrcodeclass") { } else {

              node.attribs.src = `${node.attribs.src}?jwt=${attendeeData.token}`;
              const props = attributesToProps(node.attribs);
              return <img {...props} />;
            }
          }

          //for css background img
          if (node.type === "tag" && node.name === "div"
            && node.attribs.class && node.attribs.class === "u_row"
            && node.attribs.style && node.attribs.style.includes("background-image")) {

            let styleArr = node.attribs.style.split(';');
            let modifiedStyleArr = styleArr.filter(s => s != "").map(s => {
              if (s.includes("background-image")) {
                //getting the image url using suvstr & add jwt token with the url
                let imgUrl = s.substring(s.indexOf("'") + 1, s.indexOf("'", s.indexOf("'") + 1));
                let imgurlWithToken = `${imgUrl}?jwt=${attendeeData.token}`;
                return `background-image: url('${imgurlWithToken}')`;
              } else {
                return s;
              }
            });
            node.attribs.style = modifiedStyleArr.join(";");

            const props = attributesToProps(node.attribs);
            return <div {...props}>
              {domToReact(node.children, parseOptions)}
            </div>;
          }
        }
      }
    };

    return (
      <ThemeProvider theme={theme}>
        <Button
          id="notificationModel"
          style={{ display: 'none', visibility: 'hidden' }}
          onClick={this.notificationShow}
        >
          Open Modal
        </Button>
        {notificationPopup && (
          <div className="page-notification">
            <div className="notification-wraper">
              <div id="notificationPopupId" className={this.state.PopupClass}>
                <div id="notificationContent1"></div>
                <Box component="div" textAlign={this.state.notificationMessageAws?.position} className="paragaph" dangerouslySetInnerHTML={{ __html: this.state.notificationMessageAws?.message }}></Box>
                <div className="text-center">
                  <button
                    id="notificationBtn"
                    onClick={this.notificationHide}
                  >{this.state.notificationMessageAws?.buttonText}</button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="eventLoginClock" style={{ marginTop: '10px' }}>
          <div id="player"></div>
          <Helmet
            encodeSpecialCharacters={false}
            onChangeClientState={(newState, addedTags, removedTags) => {
              if (!this.state.customJsAdded) {
                this.setState({ customJsAdded: true });
              }
            }}
          >
            <title>{pageTitle ? pageTitle : ''}</title>
            {/* <link rel="icon" href={favIcon} /> */}
            <meta
              name="title"
              content={pageTitle ? pageTitle : 'Illumeet test title'}
            />
            <meta
              name="description"
              content={pageDesc ? pageDesc : 'Illumeet test desc'}
            />
            <meta http-equiv="cache-control" content="no-cache" />
            <meta http-equiv="cache-control" content="max-age=3600" />
            <meta http-equiv="cache-control" content="public" />
            {addIndexOnPage && (
              <meta name="robots" content="noindex,nofollow,noarchive" />
            )}

            <style>{customCss ? customCss : ''}</style>
            <script>{customeJs ? customeJs : ''}</script>
            <script src="https://accounts.google.com/gsi/client" async defer></script>

          </Helmet>
          <NotificationContainer /> 
          {isProfileVisible && showProfileButton && attendeesDetail?.isSystemUser == false && (
            <Box
              onClick={this.openMyProfile}
              className={`user-profile ${this.state.profilePosition}`}
            >
              <Avatar style={styles.userAvatar}>
                {this.state.profilePic ? (
                  <img
                    alt=""
                    width={'60'}
                    src={
                      IMAGE_URL + '/profilePictures/' + this.state.profilePic
                    }
                  />
                ) : (
                  <ProfileUserIcon />
                )}

              </Avatar>
            </Box>
          )}
          {parse(html !== undefined ? html : '', parseOptions)}
          {loading ? <Loader /> : null}
        </div>

        <Button
          id="showMyPopUp"
          style={{ Display: 'none', visibility: 'hidden' }}
          onClick={this.handleClickOpenCreatePasswordForm}
        >
          Open Modal
        </Button>
        <Button
          id="loaderToggel"
          style={{ Display: 'none', visibility: 'hidden' }}
          onClick={this.togglelodder}
        >
          toggle Loader
        </Button>
        {/* <!-- The Modal --> */}
        <Modal
          open={this.state.openCreatePasswordForm}
          onClose={this.handleClickCloseCreatePasswordForm}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="" px={1.5}>
            <Grid size={{xs: 12,}} mt={-1} pb={3}>
              <Box component="p" mb={0.5} className="subtitle">
                Create Password
              </Box>
              <Box component="p" mb={0} className="small-subtitle">
                Type and confirm a secure new password for the account
              </Box>
            </Grid>
            <Grid component="form" id="createPasswordForm" size={{xs: 12,}} pb={4}>
              <Box component="div" className="inputField" pb={4}>
                <label className="bold">Email</label>

                <TextField
                  fullWidth
                  type="email"
                  id="createPasswordEmail"
                  name="email"
                  placeholder="example@gmail.com"
                  label=""
                  variant="outlined"
                  disabled
                />
                <span className="error_mesage "> </span>

                <input type="hidden" name="cemail" id="cemail" />
                <input type="hidden" name="cprojectId" id="cprojectId" />
              </Box>
              <Box component="div" className="inputField" pb={4}>
                <label>
                  New Password <span className="required">*</span>
                  <CustomTooltip
                    title={
                      <Box
                        sx={{
                          background: '#EDEBFF',
                          borderRadius: '12px',
                          width: '100%',
                        }}
                        px={4}
                        py={2}
                      >
                        <h3 className="subsecondary">Rules for passwords</h3>
                        <Box my={2} className="paragraph">
                          To Create a new password, you have to meet all of the
                          following requirements:
                        </Box>

                        <ul className="paragraph">
                          <li>At least six (6) digits</li>
                          <li>
                            Passwords must include at least one digit (e.g.,
                            0-9).
                          </li>
                          <li>
                            Passwords must contain at least one character (e.g.,
                            a-z and A-Z).
                          </li>
                          <li>
                            Uses uppercase and lowercase letters, numbers and
                            special symbols.{' '}
                          </li>
                        </ul>
                      </Box>
                    }
                    placement="right"
                    arrow
                  >
                    <Box
                      component="img"
                      sx={{ width: '20px' }}
                      ml={1}
                      alt="information Icon"
                      src={informationIcon}
                    />
                  </CustomTooltip>
                </label>
                <OutlinedInput
                  name="password"
                  fullWidth
                  notched={false}
                  id="password"
                  type={this.state.ShowPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.toggleShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.ShowPassword ? (
                          <img
                            alt="Show"
                            height="16"
                            width="16"
                            src={ShowPass}
                          />
                        ) : (
                          <img
                            alt="Hide"
                            height="16"
                            width="16"
                            src={HidePass}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
                <span className="error_mesage ">
                  {' '}
                  {this.state.errors['password']}{' '}
                </span>
              </Box>
              <Box component="div" className="inputField" pb={1}>
                <label>
                  Confirm Password <span className="required">*</span>
                </label>
                <OutlinedInput
                  name="confirm-password"
                  fullWidth
                  notched={false}
                  id="confirm-password"
                  type={this.state.ShowConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.toggleShowConfirmPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.ShowConfirmPassword ? (
                          <img
                            alt="Show"
                            height="16"
                            width="16"
                            src={ShowPass}
                          />
                        ) : (
                          <img
                            alt="Hide"
                            height="16"
                            width="16"
                            src={HidePass}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
                <span className="error_mesage ">
                  {' '}
                  {this.state.errors['confirm-password']}{' '}
                </span>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Button
                fullWidth
                variant="contained"
                onClick={this.saveCreatePassword}
                color="primary"
                size="large"
              >
                Change
              </Button>
            </Grid>
          </Grid>
        </Modal>

        {/* Forget Password Modal */}
        <Button
          id="showFpPopUp"
          style={{ display: 'none', visibility: 'hidden' }}
          onClick={this.handleClickOpenForgotPasswordForm}
        >
          FP Open Modal
        </Button>

        {/* <!-- The Modal --> */}
        <Modal
          open={this.state.openForgotPasswordForm}
          onClose={this.handleClickCloseForgotPasswordForm}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={2}>
              <Box component="p" mb={0.5} className="subtitle">
                Forget Password
              </Box>
              <Box component="p" mb={0} className="small-subtitle">
                Please enter your registered Email address. We Send you reset
                password link
              </Box>
            </Grid>
            <Grid
              component="form"
              id="forgotPaswordForm"
              size={{xs: 12,}}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Box component="div" className="inputField" pb={4}>
                <label className="bold">
                  Email <span className="required">*</span>
                </label>

                <TextField
                  fullWidth
                  type="email"
                  id="forgotPasswordEmail"
                  name="forgotPasswordEmail"
                  placeholder="example@gmail.com"
                  label=""
                  variant="outlined"
                  required={true}
                />
                <span className="error_mesage" id="fp_email">
                  {' '}
                </span>
                <input type="hidden" name="fpprojectId" id="fpprojectId" />
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Button
                fullWidth
                variant="contained"
                onClick={this.sendLink}
                color="primary"
                size="large"
              >
                Send Link
              </Button>
            </Grid>
          </Grid>
        </Modal>

        {/* Early Access Modal */}
        <Modal
          open={this.state.openEarlyAccess}
          onClose={this.handleClickCloseEarlyAccess}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container px={1.5} >

            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" style={{ fontSize: '18px' }} mb={1} className="subtitle">
                Your Event has not started yet.
              </Box>
              <p className="small-subtitle" style={{ fontSize: '14px' }} id="earlyAccessMsg">
                {openEarlyAccessMessage}
              </p>
            </Box>

            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Button
                  variant="contained"
                  onClick={this.handleClickCloseEarlyAccess}
                  color="primary"
                  style={{ fontSize: '14px' }}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        {/* for page access restriction msg */}
        <Modal
          open={this.state.pageAccessRestrictionMessage}
          onClose={this.closePageAccessRestrictionMessage}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={2}>
              <Box component="p" mb={0.5} className="subtitle">
                Permission Denied
              </Box>
              <Box
                component="p"
                mb={0.5}
                className="small-subtitle"
                id="earlyAccessMsg"
              >
                Sorry, You are not authorized to access this page!
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Button
                variant="contained"
                onClick={this.closePageAccessRestrictionMessage}
                color="primary"
                size="small"
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Modal>

        {/* for event expired msg */}
        <Modal
          open={this.state.eventExpiredMessage}
          onClose={this.closeEventExpiredMessage}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={2}>
              <Box component="p" mb={0.5} className="subtitle">
                Event Expired
              </Box>
              <Box
                component="p"
                mb={0.5}
                className="small-subtitle"
                id="earlyAccessMsg"
              >
                Event has been expired, Please contact to the Support Team if you have any assistance.
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Button
                variant="contained"
                onClick={this.closeEventExpiredMessage}
                color="primary"
                size="small"
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Modal>

        {this.state?.pintBadge && (
          <Modal
            open={this.state.openPrintBadgeModal}
            onClose={this.handleClosePrintBadgeModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid component="div" container className="modal-wrapper">
              <Grid size={{xs: 12,}} pb={3}>
                <Box>
                  <Box component="p" mb={1} className="subtitle">
                    Attendee Badge
                  </Box>
                  <p className="small-subtitle">
                    Click on print button to print this badge.
                  </p>
                </Box>
                {/*  <ReactToPrint
                  content={() => this.badgePrintComponentRef}
                  pageStyle={getReturnValues(badgePrintSettings)}
                  copyStyles={false}
                  onAfterPrint={this.afterBadgePrint}
                >
                   <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        id="handlePrintBtn"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handlePrint}
                        startIcon={<PrintIcon />}
                      >
                        Print
                      </Button>
                    )}
                  </PrintContextConsumer> 
                </ReactToPrint>
                */}
              </Grid>
              <Grid size={{xs: 12,}} className='bagde-modal' id='bagde-modal' component="div" textAlign="center">
                <BadgeContent ref={el => (this.badgePrintComponentRef = el)} badgeOutput={this.state.pintBadge} />
              </Grid>
              <Grid size={{xs: 12,}} pt={2}>
                <Grid container justifyContent="end">

                </Grid>
              </Grid>
            </Grid>
          </Modal>
        )
        }

        <ConfirmationAlertPopUpPrint
          openModal={this.state.openBadgeConfirmationModal}
          closeModalFunc={this.closeConfirmationModal}
          title={this.state.kioskPrintBadgeToggle ? this.state.kioskPopupTitle : this.state.kioskNoPrintPopupTitle}
          text={this.state.kioskPrintBadgeToggle ? this.state.kioskMessage : this.state.kioskNoPrintMessage}
          showButton={this.state.kioskPrintBadgeToggle}  // decided whether button has to be shown or not
          confirmationButtonText="Print"
          confirmationButtonColor="primary"
          functionality={this.state.kioskPrintBadgeToggle ? this.handleYesPrintBadge : () => { }}
        />
        <PrintPreviewSuccessFailPopup
          openModal={this.state.handleOpenPrintPreviewModal}
          closeModalFunc={this.closePrintPreviewModal}
          title={this.state.printPreviewPopupTitle ? this.state.printPreviewPopupTitle : "Success!!"}
          text={this.state.printPreviewMessage ? this.state.printPreviewMessage : "Printing successfully accomplished!"}
          showButton={this.state.kioskPrintBadgeToggle}  // decided whether button has to be shown or not
          confirmationButtonText="Ok"
          confirmationButtonColor="primary"
          functionality={this.state.kioskPrintBadgeToggle ? this.handleOkPrintPeview : () => { }}
        />

        {this.state?.pintBadge == undefined && (
          <SuccessAlertPopUp
            openModal={this.state.openSuccess}
            closeModalFunc={this.handleClickCloseSuccess}
            title="Registration Success!"
            description={'<span style="font-weight: 500; font-family: "Roboto", sans-serif;color: #000056" >Great Job! You have successfully registered into an event.'}
            buttonColor="primary"
            buttonFunc={this.handleClickCloseSuccess}
            buttonText="Done"
          />
        )
        }

        {/* popup for common message */}
        <Modal
          open={this.props.events.showCommonPopup}
          onClose={this.closeCommonPopup}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container px={1.5}>
            <Grid size={{xs: 12,}} mt={-1} pb={3}>
              <Box component="p" mb={0.5} className="subtitle">
                {this.props.events.commonPopupDetails?.title}
              </Box>
              <Box
                component="p"
                mb={0.5}
                className="small-subtitle"
                id="earlyAccessMsg"
              >
                {this.props.events.commonPopupDetails?.description}
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
            <Grid container justifyContent="end">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.closeCommonPopup}
              >
                Ok
              </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  accessCode: state.AccessCode,
  notifications: state.Notifications,
  googleSignIn: state.GoogleSignIn,
  events: state.Events
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      setNewAccessCodeExpiryTime: Actions.setNewAccessCodeExpiryTime,
      setAwsNotificationMessage: Actions.setAwsNotificationMessage,
      checkGoogleSignInValidToken: Actions.checkGoogleSignInValidTokenRequest,
      showCommonPopup: Actions.showCommonPopup
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(Page));

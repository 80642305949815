import { Constants } from 'ReduxStore/Actions';
import { createReducer } from "reduxsauce";

export type ContactState = {
    isLoading: boolean,
    contacts: Contact[],
    fetchAllOpportunityContactsSuccess: boolean,
    error: boolean,
    loading: boolean,
    getSmartGroupsContactsSuccess:boolean,
    fetchOpportunityContactSuccess: boolean,
    openedContact: Contact,
    message: string,
    createOpportunityContactSuccess: boolean,
    updateOpportunityContactSuccess: boolean,
    totalCount: number,
    deleteOpportunityContactSuccess: boolean;
    filterCount: FilterCount,
    updateMultipleOpportunityContactsSuccess: boolean,
    assignOrUnassignOpportunityContactsSuccess:boolean;
    addUpdateIncludeSubGroupContactSuccess:boolean;
};

export type Contact = {
    state: any;
    uniqueEijentId: number;
    phone: any;
    // audience: any;
    _id: string,
    customFields: CustomFields,
    updatedAt: Date,
    source: Source,
    account: Account,
    firstName: string,
    middleName: string,
    lastName: string,
    fullName: string,
    email: string,
    accountAndContactDataType: AccountAndContactDataType[],
    opportunityAndContactDataType: OpportunityAndContactDataType[],
    audience:AudienceObject[],
    status: "draft" | "",
    customFieldsWithProperValues: CustomFields,
    audienceIds: string[],
    audienceNames?: string[]
};

export type AudienceObject = {
    
        audienceName: string,
        _id: string,
}


export type AccountAndContactDataType = {
    account: {
        name: string,
        _id: string,
    },
    contactDataTypeCf: {
        fieldName: string,
        _id: string;
    };
};

export type OpportunityAndContactDataType = {
    opportunity: {
        name: string,
        _id: string,
    },
    contactDataTypeCf: {
        fieldName: string,
        _id: string;
    };
};

export type Account = {
    name: string,
    _id: string;
};

export type Source = 'aiAgent' | 'manuallyAdded' | 'imported';

export type CustomFields = {
    [key: string]: string | string[] | ContactCf;
};

export type ContactCf = { _id?: string, firstName: string, middleName?: string, lastName?: string; };

export type FilterCount = {
    projectsCount: ProjectsCount,
    projectsAll: number,
    sourceCount: SourceCount,
    statusCount: StatusCount,
    statusAll: number,
    accountsCount: AccountsCount,
    accountsAll: number,
    audienceAll:number,
    audienceCount:AudienceCount,
    audience:AudienceFilterObject[],
    lastImportedAll: number,
}

export type AudienceFilterObject = {
    audienceName: string,
    _id: string,
    audienceCount:number,
}

export type ProjectsCount = {
    [key: string]: number;
};
export type AccountsCount = {
    [key: string]: number
}
export type AudienceCount = {
    [key: string]: number
}


export type SourceCount = {
    aiAgent: number,
    manuallyAdded: number;
};
export type StatusCount = {
    draft: number,
    new: number;
};

const INITIAL_STATE: ContactState = {
    isLoading: false,
    contacts: [],
    fetchAllOpportunityContactsSuccess: false,
    error: false,
    loading: false,
    getSmartGroupsContactsSuccess:false,
    fetchOpportunityContactSuccess: false,
    openedContact: {} as Contact,
    message: '',
    createOpportunityContactSuccess: false,
    updateOpportunityContactSuccess: false,
    totalCount: 0,
    deleteOpportunityContactSuccess: false,
    filterCount: {} as FilterCount,
    updateMultipleOpportunityContactsSuccess: false,
    assignOrUnassignOpportunityContactsSuccess:false,
    addUpdateIncludeSubGroupContactSuccess:false,
};

const fetchAllOpportunityContactsRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchAllOpportunityContactsSuccess: false,
        error: false,
        contacts: [],
        loading: true,
        message: '',
        totalCount: 0
    };
};

const fetchAllOpportunityContactsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityContactsSuccess: true,
        error: false,
        contacts: action.contacts,
        loading: false,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const fetchAllOpportunityContactsError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchAllOpportunityContactsSuccess: false,
        error: true,
        loading: false,
        contacts: [],
        message: action.message,
        totalCount: 0
    };
};

const fetchOpportunityContactRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        fetchOpportunityContactSuccess: false,
        error: false,
        openedContact: {} as Contact,
        loading: true,
        message: '',
    };
};
const fetchOpportunityContactSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchOpportunityContactSuccess: true,
        error: false,
        openedContact: action.contact,
        loading: false,
    };
};
const fetchOpportunityContactError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        fetchOpportunityContactSuccess: false,
        error: true,
        openedContact: {} as Contact,
        loading: false,
        message: action.message
    };
};

const createOpportunityContactRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        createOpportunityContactSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0,
        openedContact: {}
    };
};
const createOpportunityContactSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityContactSuccess: true,
        error: false,
        loading: false,
        openedContact: action.contact,
        contacts: action.contacts,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const createOpportunityContactError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        createOpportunityContactSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0
    };
};
const updateOpportunityContactRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateOpportunityContactSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0,
        openedContact: {}
    };
};
const updateOpportunityContactSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityContactSuccess: true,
        error: false,
        loading: false,
        openedContact: action.contact,
        contacts: action.contacts,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const updateOpportunityContactError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateOpportunityContactSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0
    };
};
const deleteOpportunityContactRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        deleteOpportunityContactSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0
    };
};
const deleteOpportunityContactSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityContactSuccess: true,
        error: false,
        loading: false,
        contacts: action.contacts,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const deleteOpportunityContactError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        deleteOpportunityContactSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0
    };
};

const updateMultipleOpportunityContactsRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        updateMultipleOpportunityContactsSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0
    };
};

const updateMultipleOpportunityContactsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateMultipleOpportunityContactsSuccess: true,
        error: false,
        loading: false,
        contacts: action.contacts,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const updateMultipleOpportunityContactsError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        updateMultipleOpportunityContactsSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0
    };
};

const assignOrUnassignOpportunityContactsRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        assignOrUnassignOpportunityContactsSuccess: false,
        error: false,
        loading: true,
        message: '',
        totalCount: 0
    };
};

const assignOrUnassignOpportunityContactsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        assignOrUnassignOpportunityContactsSuccess: true,
        error: false,
        loading: false,
        contacts: action.contacts,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const assignOrUnassignOpportunityContactsError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        assignOrUnassignOpportunityContactsSuccess: false,
        error: true,
        loading: false,
        message: action.message,
        totalCount: 0
    };
};

const getSmartGroupsContactsRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        getSmartGroupsContactsSuccess: false,
        error: false,
        contacts: [],
        loading: true,
        message: '',
        totalCount: 0
    };
};

const getSmartGroupsContactsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        getSmartGroupsContactsSuccess: true,
        error: false,
        contacts: action.contacts,
        loading: false,
        totalCount: action.totalCount,
        filterCount: action.filterCount
    };
};

const getSmartGroupsContactsError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        getSmartGroupsContactsSuccess: false,
        error: true,
        loading: false,
        contacts: [],
        message: action.message,
        totalCount: 0
    };
};

const addUpdateIncludeSubGroupContactRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: true,
        addUpdateIncludeSubGroupContactSuccess: false,
        error: false,
        loading: true,
        message: '',
    };
};
const addUpdateIncludeSubGroupContactSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        addUpdateIncludeSubGroupContactSuccess: true,
        error: false,
        loading: false,
    };
};

const addUpdateIncludeSubGroupContactError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        addUpdateIncludeSubGroupContactSuccess: false,
        error: true,
        loading: false,
        message: action.message,
    };
};

export const HANDLERS = {
    [Constants.ADD_UPDATE_INCLUDE_SUB_GROUP_CONTACT_REQUEST]: addUpdateIncludeSubGroupContactRequest,
    [Constants.ADD_UPDATE_INCLUDE_SUB_GROUP_CONTACT_SUCCESS]: addUpdateIncludeSubGroupContactSuccess,
    [Constants.ADD_UPDATE_INCLUDE_SUB_GROUP_CONTACT_ERROR]: addUpdateIncludeSubGroupContactError,
    [Constants.GET_SMART_GROUPS_CONTACTS_REQUEST]: getSmartGroupsContactsRequest,
    [Constants.GET_SMART_GROUPS_CONTACTS_SUCCESS]: getSmartGroupsContactsSuccess,
    [Constants.GET_SMART_GROUPS_CONTACTS_ERROR]: getSmartGroupsContactsError,
    [Constants.FETCH_ALL_OPPORTUNITY_CONTACTS_REQUEST]: fetchAllOpportunityContactsRequest,
    [Constants.FETCH_ALL_OPPORTUNITY_CONTACTS_SUCCESS]: fetchAllOpportunityContactsSuccess,
    [Constants.FETCH_ALL_OPPORTUNITY_CONTACTS_ERROR]: fetchAllOpportunityContactsError,
    [Constants.FETCH_OPPORTUNITY_CONTACT_REQUEST]: fetchOpportunityContactRequest,
    [Constants.FETCH_OPPORTUNITY_CONTACT_SUCCESS]: fetchOpportunityContactSuccess,
    [Constants.FETCH_OPPORTUNITY_CONTACT_ERROR]: fetchOpportunityContactError,
    [Constants.CREATE_OPPORTUNITY_CONTACT_REQUEST]: createOpportunityContactRequest,
    [Constants.CREATE_OPPORTUNITY_CONTACT_SUCCESS]: createOpportunityContactSuccess,
    [Constants.CREATE_OPPORTUNITY_CONTACT_ERROR]: createOpportunityContactError,
    [Constants.UPDATE_OPPORTUNITY_CONTACT_REQUEST]: updateOpportunityContactRequest,
    [Constants.UPDATE_OPPORTUNITY_CONTACT_SUCCESS]: updateOpportunityContactSuccess,
    [Constants.UPDATE_OPPORTUNITY_CONTACT_ERROR]: updateOpportunityContactError,
    [Constants.DELETE_OPPORTUNITY_CONTACT_REQUEST]: deleteOpportunityContactRequest,
    [Constants.DELETE_OPPORTUNITY_CONTACT_SUCCESS]: deleteOpportunityContactSuccess,
    [Constants.DELETE_OPPORTUNITY_CONTACT_ERROR]: deleteOpportunityContactError,
    [Constants.UPDATE_MULTIPLE_OPPORTUNITY_CONTACTS_REQUEST]: updateMultipleOpportunityContactsRequest,
    [Constants.UPDATE_MULTIPLE_OPPORTUNITY_CONTACTS_SUCCESS]: updateMultipleOpportunityContactsSuccess,
    [Constants.UPDATE_MULTIPLE_OPPORTUNITY_CONTACTS_ERROR]: updateMultipleOpportunityContactsError,
    [Constants.ASSIGN_OR_UNASSIGN_OPPORTUNITY_CONTACTS_REQUEST]: assignOrUnassignOpportunityContactsRequest,
    [Constants.ASSIGN_OR_UNASSIGN_OPPORTUNITY_CONTACTS_SUCCESS]: assignOrUnassignOpportunityContactsSuccess,
    [Constants.ASSIGN_OR_UNASSIGN_OPPORTUNITY_CONTACTS_ERROR]: assignOrUnassignOpportunityContactsError,
};


export default createReducer(INITIAL_STATE, HANDLERS);

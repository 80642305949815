import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme';  
import axios from 'axios';
import { createNotification } from '../../../helpers';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../Components/Loader/loader'
import { permissions } from '../../../constants/constants'
import withCustomRouter from '../../../Common/withCustomRouter'; 

const URL = process.env.REACT_APP_API_URL 
 
export class TransactionalEmailPreview extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            eventId: "",
            preview:{},
        }
    }    

    UserData = async () => {
        const currentPath = window.location.pathname
        if (currentPath.includes('member')) {
            return JSON.parse(localStorage.getItem('member_details'))
        } else {
            return JSON.parse(localStorage.getItem('user_details'))
        }
    } 

    async getTransacionalEmailPerview(eventId, templateId){
        const users = await this.UserData()
        try {
            const response = await axios.get(`${URL}/getPageDetails?pageId=${templateId}`, {
                headers: {
                    token: `bearer ${users.token}`,
                    'Content-Type': 'application/json',
                    'check-grant': `${eventId}-${permissions.viewCampaigns}`
                }
            }); 
            if (response.data && response.data.success == true) {                  
                return response?.data 
            } else {
                throw Error(response.data.error)
            }
        } catch (err) {
            createNotification("error", err.message);
        }
    }  

    async componentDidMount() { 
        let eventId =  this.props.params.eventId; 
        let params = this.props.location;
        let templateId = new URLSearchParams(params.search).get("templateId")  
        let transactionalDetails = await this.getTransacionalEmailPerview(eventId, templateId); 
     
        this.setState({
            eventId : eventId,
            preview: transactionalDetails?.page[0]
        });
    } 

    render() {
        const { preview } = this.state; 
        return (  
            <ThemeProvider theme={theme}>
               {preview.html ?  
                    <div style={{pointerEvents:'none'}}>
                        {ReactHtmlParser(preview.html)}
                    </div>
                :
                    <Loader />
               }
            </ThemeProvider>
        )
    }
}

export default withCustomRouter(TransactionalEmailPreview);


import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityStatesAPI from 'ReduxStore/API/opportunity/opportunityStatesAPI';
import { removeStorage } from 'helpers/common';

function* createOpportunityState(action) {
    const opportunityStatesAPI = new OpportunityStatesAPI();
    const response = yield call(opportunityStatesAPI.createOpportunityState, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.createOpportunityStateSuccess(
                    response.data.opportunityStates
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.createOpportunityStateError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.createOpportunityStateError(response.data.message));
        }
    } else {
        yield put(Actions.createOpportunityStateError(response.data.message));
    }
}

function* updateOpportunityState(action) {
    const opportunityStatesAPI = new OpportunityStatesAPI();
    const response = yield call(opportunityStatesAPI.updateOpportunityState, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.updateOpportunityStateSuccess(
                    response.data.opportunityStates,
                    response.data.message,
                    response.data.updatedOpportunityState,
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.updateOpportunityStateError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.updateOpportunityStateError(response.data.message));
        }
    } else {
        yield put(Actions.updateOpportunityStateError(response.data.message));
    }
}

function* deleteOpportunityState(action) {
    const opportunityStatesAPI = new OpportunityStatesAPI();
    const response = yield call(opportunityStatesAPI.deleteOpportunityState, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.deleteOpportunityStateSuccess(
                    response.data.opportunityStates
                )
            );
        } else if (response.data.status === 403) {
            yield put(Actions.deleteOpportunityStateError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.deleteOpportunityStateError(response.data.message));
        }
    } else {
        yield put(Actions.deleteOpportunityStateError(response.data.message));
    }
}

function* fetchAllOpportunityStates(action) {
    const opportunityStatesAPI = new OpportunityStatesAPI();
    const response = yield call(opportunityStatesAPI.fetchAllOpportunityStates, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.fetchAllOpportunityStateSuccess(
                    response.data.opportunityStates,
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.fetchAllOpportunityStateError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.fetchAllOpportunityStateError(response.data.message));
        }
    } else {
        yield put(Actions.fetchAllOpportunityStateError(response.data.message));
    }
}

function* updateOpportunityStatesOrder(action) {
    const opportunityStatesAPI = new OpportunityStatesAPI();
    const response = yield call(opportunityStatesAPI.updateOpportunityStatesOrder, action.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(
                Actions.updateOpportunityStatesOrderSuccess(
                    response.data.opportunityStates
                ),
            );
        } else if (response.data.status === 403) {
            yield put(Actions.updateOpportunityStatesOrderError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.updateOpportunityStatesOrderError(response.data.message));
        }
    } else {
        yield put(Actions.updateOpportunityStatesOrderError(response.data.message));
    }
}

const opportunityStatesSaga = {
    deleteOpportunityState,
    updateOpportunityState,
    createOpportunityState,
    fetchAllOpportunityStates,
    updateOpportunityStatesOrder
};

export default opportunityStatesSaga;
//@ts-nocheck
import { Button, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Application, Application_state,  } from 'ReduxStore/Reducers/integrations/applications/application.reducer';
import { Actions } from 'ReduxStore/Actions';
import { useParams } from 'react-router';
import { Modal } from 'react-responsive-modal';
import { Cached, ChevronRight, FileCopyOutlined,} from '@mui/icons-material';
import IllumeetXpIcon from '../../../../assets/images/icons/app-icon-illumeetXp.svg';
import ZapierAppIcon from '../../../../assets/images/icons/app-icon-zapier.svg';
import ZapierIcon from '../../../../assets/images/icons/Zapier-icon.svg';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { createNotification } from 'helpers';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import CustomSwitch from 'Components/customSwitch';
import Loader from 'Components/Loader/loader';

export default function Applications() {
  const {loading, applications} = useSelector((store: any) => store.Applications) as Application_state
  const dispatch = useDispatch()
  const params: { eventId: string} = useParams()

  useEffect(() => {
    checkApplicationIntegrationEnabled()
  }, [])

  const checkApplicationIntegrationEnabled = () => {
    try {
        const queryParams = {
            projectId: params.eventId
        }
        dispatch(Actions.checkApplicationIntegrationEnabledRequest(queryParams))     
    } catch (error) {
        createNotification("error", "Something went wrong")
    }
  }

  const handleToggle = (e: any, appName: Application['name']) => {
    const bodyParams = {
      projectId: params.eventId,
      appName,
      activeStatus: e.target.checked
    }
    dispatch(Actions.enableDisableApplicationIntegrationRequest(bodyParams))
  }

  return (
    <div>
      {loading ? <Loader /> : null}
      <Box>
        <Box mb={3} className="subsecondary">
          Applications
        </Box>
        <Grid container spacing={3}>
          {
            applications.map(application => {
                return (
                    <Grid key={application.name}>
                    <Box className="integrated-apps">
                      <Stack
                        direction={'row'}
                        // justifyContent="space-between"
                        justifyContent="center"
                        alignItems={'start'}
                        mb={1.5}
                      >
                        <Box mt={1.5}>
                          <img className="app-icon" alt="app icon" src={application.icon}/>
                        </Box>
                        <Box position="absolute" right="12px">
                          <CustomSwitch
                            color={'primary'}
                            name={undefined}
                            onChange={(e: any) => handleToggle(e, application.name)}
                            checked={application.activeStatus}
                          />
                        </Box>
                      </Stack>
                      <Box>
                        <Box mb={1.5} className="app-title">
                          {application.name}
                        </Box>
                        <Box
                          mb={1.5}
                          className="app-detail"
                        >
                            {application.description}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )
            })
          }
        </Grid>
      </Box>    
    </div>
  );
}


import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import '../../../sass/main.scss'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const EditingSection = ({
  editorLayoutConfig,
  uiConfig,
  activeLayout,
  handleLayoutChange,
}) => {
  const layout = editorLayoutConfig[activeLayout]
  const activeLayoutValues = uiConfig[activeLayout] || {}

  return (
    <div className="registerEditor">
      <div className="asideTabs">
        <TabPanel>
          <form>
            <div className="branding_form_wrap">
              {layout.map(({ type, component: Component, getProps }) => {
                const defaultProps = getProps()
                const value = activeLayoutValues[defaultProps['name']]
                let props = {
                  ...defaultProps,
                  value,
                  onChange: (e) => {
                    const name = e.target.name
                    const value = e.target.value
                    handleLayoutChange(name, value)
                  },
                }
                if (
                  type === 'ColorChanger' ||
                  type === 'ImageChanger' ||
                  type === 'UploadLogo'
                ) {
                  props = {
                    ...props,
                    onChange: (name, value) => handleLayoutChange(name, value),
                  }
                }
                return <Component {...props} />
              })}
            </div>
          </form>
        </TabPanel>
      </div>
      <div className="mobileSlideSidebar openSidebarIcon desktop-None">
        <i className="fas fa-chevron-left"></i>
      </div>
    </div>
  )
}

export default EditingSection

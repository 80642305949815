import { Box } from '@mui/material';
import settings from 'Pages/AIChatbot/Tabs/General/TabsGeneral/Settings/Settings';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { AIChatbotState, Settings } from 'ReduxStore/Reducers/aiChatbot.reducer';
import Grid from '@mui/material/Grid2';

function setNestedValue<T>(state: T, path: (string | number)[], value: any): T {
  // If the path is empty, return the state as is
  if (path.length === 0) return state;

  // Clone the state to avoid direct mutation
  const newState = { ...state };

  // The key to update
  const key = path[0];

  // If we're at the end of the path, set the value
  if (path.length === 1) {
    (newState as any)[key] = value;
  } else {
    // Recur deeper into the object, creating nested objects if necessary
    (newState as any)[key] = setNestedValue(
      (state as any)[key] || {},
      path.slice(1),
      value,
    );
  }

  return newState;
}

const GridItem = ({
  label,
  value,
  path,
  xs = 3,
}: {
  label: string;
  value: any;
  path: string[];
  xs?: number;
}) => {
  const dispatch = useDispatch();
  const { chatBot } = useSelector(
    (store: any) => store.AIChatbot,
  ) as AIChatbotState;
  return (
    <>
      {/* @ts-ignore */}
      <Grid>
        <Box component="div" className="inputField" mb={3}>
          <label>{label}</label>
          <input
            type="number"
            className="form-control small"
            placeholder="Enter number"
            value={value}
            min={0}
            max={30}
            onChange={(e) => {
              const updated = setNestedValue(
                chatBot.settings.chatInterface,
                path,
                Number(e.target.value || 0),
              );
              dispatch(
                Actions.updateChatbot({
                  ...chatBot,
                  settings: {
                    ...chatBot.settings,
                    chatInterface: updated,
                  },
                }),
              );
              //   setState((state: Settings) => {
              //     //console.log('S T A T E', state);
              //     const updated = setNestedValue(
              //       state.chatInterface,
              //       path,
              //       Number(e.target.value || 0),
              //     );
              //     return {
              //       ...state,
              //       chatInterface: updated,
              //     };
              //   });
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default GridItem;

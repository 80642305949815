import React, { Component } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { theme } from '../../theme/theme'

const styles = {
  customTabList: {
  },
  customTab: {
    minHeight: 42,
    padding: '12px 16px',
    color: '#FFFFFF',
    textTransform: 'capitalize',
  },
  customTabPanel: {
    padding: 0,
  },

  userAvatar: {
    // backgroundColor: '#FF4170',
    color: '#ffffff',
    border: '1px solid #ffffff',
    width: '80px',
    height: '80px',
  },
}
export class ProfileImageAndName extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       firstNameIntial: '',
       lastNameIntial: '',
    }
  }
  componentDidMount(){
    const { firstName, lastName } = this.props.userDetails
    this.setState({
      firstNameIntial: firstName.split('')[0].toUpperCase(),  
      lastNameIntial: lastName.split('')[0].toUpperCase(),

    })
  }
  
  render() {
    const { firstName, lastName, email } = this.props.userDetails
    const {firstNameIntial, lastNameIntial} = this.state
    return (
      <Stack alignItems={'center'}>
        <Box className="user-profile-pic" mb={2}>
          <Avatar style={styles.userAvatar}>
            {`${firstNameIntial}${lastNameIntial}`}
          </Avatar>
          <Box className="edit-profile">
            <IconButton
              size="small"
              style={{
                backgroundColor: '#ffffff',
                padding: '5px',
              }}
              aria-label="edit"
            >
              <EditIcon style={{ fontSize: '18px' }} color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Box className="subsecondary" color={'#ffffff'} mb={1}>
          {firstName}{' '}{lastName}
        </Box>
        <Box className="small-subtitle" color={'#ffffff'} mb={1.8125}>
          {email}
        </Box>
      </Stack>
    )
  }
}

export default ProfileImageAndName
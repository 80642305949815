import React, { Component } from 'react' 
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import SearchIconBar from '../../assets/images/searchIco.png'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import Pagination from 'react-js-pagination'
import AddMemberModal from './addMemberModal'
import UpdateMemberModal from './updateMemberModal'

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      totalResults: 1,
      updateOpen: false,
      singleData: {},
      contacts: [],
      list: [],
      showDelete: false,
    }
  }

  componentDidMount() {
    if (this.props) {
      this.props.listMemberRequest(this.state.page_no)
      this.handleLoading()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.members.success === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null 
      this.setState({ open: false })
      this.props.listMemberRequest(this.state.page_no)
    }

    if (
      this.props.members.listSuccess === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null 
      this.setState({ totalResults: this.props.members.totalResults })
    }
    if (
      this.props.members.listError === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('error', this.props.members.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
      
    }

    if (
      this.props.members.deleteSuccess === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('success', this.props.members.message, '')
      this.props.listMemberRequest(this.state.page_no)
    }

    if (
      this.props.members.deleteError === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('error', this.props.members.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
      
    }

    if (
      this.props.members.updateSuccess === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      this.setState({ updateOpen: false })
      this.props.listMemberRequest(this.state.page_no)
    }
    if (
      this.props.members.searchError === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('error', this.props.members.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
       
    }
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handlePageChange = (pageNumber) => { 
    this.setState({ page_no: pageNumber })
    this.props.listMemberRequest(pageNumber)
    this.handleLoading()
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  onCloseUpdateModal = () => {
    this.setState({ updateOpen: false })
  }
  openUpdateModal = (data) => {
    this.setState({ updateOpen: true, singleData: data })
  }

  deleteMember = (id) => {
    this.props.deleteMemberRequest(id)
    this.handleLoading()
  }

  handleSubmit = (e) => { 
    e.preventDefault() 
    if (
      this.state.searchText !== '' &&
      this.state.searchText.trim().length > 0
    ) {
      const { searchText, page_no } = this.state
      const requestbody = { searchText, page_no } 
      this.props.searchMemberRequest(requestbody)
      this.handleLoading()
    } else {
      this.props.listMemberRequest(this.state.page_no)
      this.handleLoading()
    }
  }

  render() {
    const {
      open,
      loading,
      page_no,
      totalResults,
      updateOpen,
      singleData,
      errors,
      searchText,
    } = this.state

    return (
      <div className="dashboardMiddleArea">
        <div className="filterSearchBar">
          <div className="filterControler SearchGrid">
            <div className="eventControlSearch mr-2">
              <form onSubmit={this.handleSubmit}>
                <div className="filterSerchSubmit">
                  <button type="submit">
                    <img src={SearchIconBar} alt="" />
                  </button>
                  <input
                    type="text"
                    placeholder="Search......"
                    name="searchText"
                    value={searchText}
                    onChange={this.handleChange}
                  />
                </div>
                <p className="error_mesage"> {errors.searchText} </p>
              </form>
            </div>
          </div>
          <div className="filterControler filterImport mobile-None buttonGroups">
            <Button
              type="button"
              className="themeBlueButton"
              onClick={this.onOpenModal}
            >
              Add Member
            </Button>
          </div>
        </div>
        <div className="myEventTable manageTeam_table contactsTable">
          <div className="eventTableInner contactTableInner">
            <table className="table contactTableInner">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="tableBodyC">
                {this.props.members.members &&
                  this.props.members.members.map((data, index) => {
                    return (
                      <tr>
                        <td>{data.firstName}</td>
                        <td>{data.lastName}</td>
                        <td className="userStatus">{data.email}</td>
                        <td className="userStatus">
                          {data.acceptSt === 2
                            ? 'Accepted'
                            : data.acceptSt === 3
                            ? 'Decline'
                            : 'Pending'}
                        </td>
                        <td className="tableDotMore">
                          <button className="tableDots">
                            <span className="tableDotsExpo"></span>
                            <span className="tableDotsExpo"></span>
                            <span className="tableDotsExpo"></span>
                          </button>
                          <div className="contacteditPopup">
                            <p>
                              {' '}
                              <span onClick={() => this.openUpdateModal(data)}>
                                Edit
                              </span>
                            </p>
                            <p>
                              <span
                                onClick={() => {
                                  if (window.confirm('Delete the member?')) {
                                    this.deleteMember(data._id)
                                  }
                                }}
                              >
                                Delete
                              </span>
                            </p>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>

          {totalResults > 10 ? (
            <div className="tablePagination">
              <Pagination
                activePage={page_no}
                itemsCountPerPage={10}
                totalItemsCount={totalResults}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
                prevPageText="Prev"
                nextPageText="Next"
              />
            </div>
          ) : null}
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}

        <Modal open={open} onClose={this.onCloseModal} center>
          <AddMemberModal page_no={page_no} />
        </Modal>

        <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
          <UpdateMemberModal page_no={page_no} singleData={singleData} />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  members: state.Members,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listMemberRequest: Actions.listMemberRequest,
      deleteMemberRequest: Actions.deleteMemberRequest,
      searchMemberRequest: Actions.searchMemberRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Team)

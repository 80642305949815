import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Modal from "react-responsive-modal";
import sheetIcon from '../../../../src/assets/images/icons/xls-icon.svg';
import DocsIcon from '../../../../src/assets/images/icons/Doc-icon.svg';
import pdfIcon from '../../../../src/assets/images/icons/pdf-icon.svg';
import imgIcon from '../../../../src/assets/images/icons/Image-icon.svg';
import VideoIcon from '../../../../src/assets/images/icons/Video-icon.svg';
import CustomDropzone from "./CustomDropZone";
import { createNotification } from 'helpers';
import { useSelector } from "react-redux";

const UploadFolderModal = (props) => {
    const dropzoneDeleteRef = useRef();
    const addImagesSuccess = useSelector(store => store.Files.addImagesSuccess);
    const addDocumentSuccess = useSelector(store => store.Files.addDocumentSuccess);
    const uploadVideoSuccess = useSelector(store => store.Files.uploadVideoSuccess);

    const {
        message: xpUsersWebSocketMessage
    } = useSelector(
        (state) => state.XpUsersWebSocket,
    );

    const [progressMap, setProgressMap] = useState({});
    const [showProgressBar, setShowProgressBar] = useState(false);
    const latestProgressMapRef = useRef({});    

    useEffect(() => {
        if (xpUsersWebSocketMessage.message && xpUsersWebSocketMessage.message == "fileProgress") {
            const { fileId, progress } = xpUsersWebSocketMessage?.data;

            // Only update if the new progress is higher than the previous one
            if (progress > (latestProgressMapRef.current[fileId] || 0)) {
                setProgressMap(prev => {
                    const newProgressMap = { ...prev, [fileId]: progress };
                    latestProgressMapRef.current = newProgressMap;
                    return newProgressMap;
                });
            }
        }
    }, [xpUsersWebSocketMessage]);


    useEffect(() => {
      if (addImagesSuccess || addDocumentSuccess || uploadVideoSuccess) {
        props.handleCloseUploadFile();
        setProgressMap({});
        setShowProgressBar(false);
      }
    }, [addImagesSuccess, addDocumentSuccess, uploadVideoSuccess]);


    return (
        <Modal
            open={props.uploadFile}
            onClose={() => {
                setProgressMap({});
                setShowProgressBar(false);
                props.handleCloseUploadFile();
            }}
            classNames={{
                overlay: '',
                modal: 'Invite-User-Modal',
            }}
            center
        >
            <Grid component="form" container className="modal-wrapper">
                <Grid size={{xs: 12,}} pb={2}>
                    <Box mt={-1} className="subsecondary">
                        Upload Files
                    </Box>
                </Grid>
                <Grid size={{xs: 12,}} pb={3}>
                    <Box className="upload-files-modal">

                        <CustomDropzone
                            onAdd={(files) => {
                                if ((files.length + props.uploadedFiles.length) > 5) {
                                    createNotification('error', 'You have exceeded the maximum limit. Please select up to 5 files only');
                                } else {
                                    props.handleSelectedFiles(files);
                                }
                            }}
                            onDelete={(fileToDelete) => {
                                props.handleRemoveFromDropzone(fileToDelete);
                            }}
                            dropzoneDeleteRef={dropzoneDeleteRef}
                            dropzoneText={
                                <>
                                    <span className="paragraph">
                                        Drag & drop files here, or Browse
                                    </span>
                                    <div className="small-subtitle light">
                                        {props.smallTextOne} ({props.smallTextTwo})
                                    </div>
                                    <div className="small-subtitle light">
                                        {props.smallTextThree}
                                    </div>
                                </>
                            }
                            dropzoneClass="dropzone"
                            acceptedFiles={props.extensionNames}
                            showAlerts={['error']}
                            alertSnackbarProps={{
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            }} 
                            
                        />      
                        {props.showFiles &&
                            <Box className="file-preview">
                                {props.uploadedFiles?.length ?
                                    <Box class="section-divider">
                                        <span className="attached-file">Uploaded files</span>
                                    </Box>
                                    :
                                    ''
                                }
                                <Stack direction={'column'} spacing={2}>
                                    {props.uploadedFiles?.map((file, index) => {
                                        const fileExtension = file?.file?.name?.split(".")[1];
                                        const imageSizeInBytes = file?.file?.size;
                                        let convertedFileSizeKbMb;
                                        if (imageSizeInBytes > 1024 * 1024) {
                                            // Convert to MB if file size is larger than 1024 KB
                                            convertedFileSizeKbMb = `${(imageSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
                                        } else {
                                            convertedFileSizeKbMb = `${(imageSizeInBytes / 1024).toFixed(2)} KB`;
                                        }
                                        const icon = fileExtension === "pdf" ? pdfIcon : fileExtension === "doc" ? DocsIcon : fileExtension === "docx" ? DocsIcon : fileExtension === "txt" ? DocsIcon : fileExtension === "pptx" ? pdfIcon : fileExtension === "mp4" ? VideoIcon : sheetIcon;
                                        return (
                                            <Box className='preview-chip'>
                                                <Stack
                                                    direction={'row'}
                                                    alignItems={'center'}
                                                    spacing={1}
                                                    ml={-1}
                                                    pr={1}
                                                    sx={{ width: '100%', maxWidth: 'calc(100% - 24px)' }}
                                                >
                                                    <Box>
                                                        <img src={props.handleUploadImage ? imgIcon : icon} />
                                                    </Box>

                                                    <Box sx={{ width: '100%', maxWidth: 'calc(100% - 44px)' }}>
                                                        <Box className="file-name long-text">{file?.file?.name}</Box>
                                                        <Box className="info">Size: {convertedFileSizeKbMb} </Box>
                                                        {
                                                            showProgressBar &&
                                                            progressMap && <Box className="info">
                                                                <LinearProgress fullWidth variant="determinate" value={progressMap?.[file?.file?.name] ? progressMap?.[file?.file?.name] : 0} />
                                                                {progressMap?.[file?.file?.name] ? progressMap?.[file?.file?.name] : 0} %
                                                            </Box>
                                                        }

                                                    </Box>

                                                </Stack>
                                                <DeleteOutlineIcon onClick={() => {
                                                    dropzoneDeleteRef.current.props.onDelete(props.uploadedFiles?.[index]?.file, index);
                                                }}
                                                    className="delete-icon"
                                                />
                                            </Box>
                                        ); 
                                    })}
                                </Stack>
                            </Box>
                        }
                    </Box>    
                </Grid>
                <Grid size={{xs: 12,}}>
                    <Grid container justifyContent="end">
                        <Stack spacing={1} direction="row" fullWidth>
                            <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    setProgressMap({});
                                    setShowProgressBar(false);
                                    props.handleCloseUploadFile();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary" disableElevation
                                onClick={() => {
                                    setShowProgressBar(true);
                                    if (props.handleUploadImage) {  
                                        props.handleUploadImage();
                                    } else if (props.handleUploadVideo) {
                                        props.handleUploadVideo();
                                    } else {
                                        props.handleUploadDocument();
                                    }
                                }} 
                            >
                                Upload
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );

};

export default UploadFolderModal; 
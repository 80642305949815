import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';
import Button from '@mui/material/Button';
// import '../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CurrentPlanIcon from '../../../src/assets/images/icons/CurrentPlanIcon.svg';
import TotalContactsIcon from '../../../src/assets/images/icons/TotalContactsIcon.svg';
import TotalProjectsIcon from '../../../src/assets/images/icons/TotalProjectIcon.svg';
import Loader from '../../Components/Loader/loader';
import {
    Link,
} from '@mui/material';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import YourPlanListing from './YourPlanListing';

class YourPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            servicePlanId: '',
            modules: {},
            loading: false,
            servicePlanName: '',
            page_no: 1,
            limit: 10,
            searchText: "",
            pricingPlanName: "",
        };
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('user_details'));
        let servicePlanId = userDetails.servicePlanId;
        this.setState({
            servicePlanId
        }, () => {
            this.getBillingDataRequest();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.servicePlan?.getBillingDataSuccess && !prevProps.servicePlan?.getBillingDataSuccess) {
            let modules = this.props.servicePlan?.billingDataModules;
            let arr = [];
            for (const module in modules) {
                let obj = {
                    name: module,
                    value: modules[module]
                };
                arr.push(obj);
            }
            this.setState({
                modules: arr,
                features: this.props.servicePlan?.billingDataFeatures,
                servicePlanName: this.props.servicePlan?.servicePlanName,
                pricingPlanName: this.props.servicePlan?.pricingPlanName,
                loading: false
            });
        }
    }

    getBillingDataRequest = () => {
        const reqParams = {
            servicePlanId: this.state.servicePlanId
        };
        this.props.getBillingDataRequest(reqParams);
        this.setState({
            loading: true
        });
    };

    navigateToUpgradePage = () => {
        this.props.navigate('https://www.illumeetxp.com/upgrade');
    };

    optionsList = (val) => {
        let temp;
        switch (val) {
            case 'Total projects': temp = this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.totalProjects;
                return temp;

            case 'Total contacts': temp = this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.totalContacts;
                return temp;

            default: temp = val;
                break;
        }
    };

    render() {
        const { features, pricingPlanName } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Box className="billing-page">
                    <Box
                        pb={3}
                        pt={2.5}
                        className="top-filter-area"
                    >
                        <Grid container spacing={2}>
                            <Grid size={{md: 'grow',}}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid >
                                        <Box
                                            component="h4"
                                            className="subsecondary"
                                            sx={{ marginBottom: 0 }}
                                        >
                                            {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.planAndPricing}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        {/* Plan details */}
                        <Box className='plan-details'>
                            <Grid container justifyContent={'center'} spacing={2}>
                                <Grid size={{xs: 12,}} md>
                                    <Box
                                        p={2}
                                        className=""
                                        sx={{
                                            border: '1px solid #EAEAEA',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Grid
                                            container
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            direction="row"
                                            spacing={2}
                                        >
                                            <Grid >
                                                <Grid container alignItems="center" spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                                                    <Grid >
                                                        <Box
                                                            component={Stack}
                                                            direction="row"
                                                            pr={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                                                            sx={{ borderRight: '1px solid #EAEAEA' }}
                                                        >
                                                            <Box
                                                                mr={1.5}
                                                                component={'img'}
                                                                alt=""
                                                                src={CurrentPlanIcon}
                                                            />

                                                            <Stack className="">
                                                                <Box className="paragraph primary-text bold">
                                                                    {pricingPlanName}
                                                                </Box>
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={2}
                                                                    justifyContent={'space-between'}
                                                                >
                                                                    <Box className="small-subtitle primary-text ">
                                                                        {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.currentPlan}
                                                                    </Box>
                                                                    <Link className='plan-link' onClick={this.navigateToUpgradePage}>
                                                                        {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.viewPlan}
                                                                    </Link>
                                                                </Stack>
                                                            </Stack>
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        Array.isArray(features) && features.length && features.map((el, i) => {
                                                            return (
                                                                <Grid key={i}>
                                                                    <Box component={Stack} direction="row" pr={4}>
                                                                        <Box
                                                                            mr={1.5}
                                                                            component={'img'}
                                                                            alt=""
                                                                            src={el.icon === 'TotalProjectsIcon' ? TotalProjectsIcon : TotalContactsIcon}
                                                                        />

                                                                        <Stack className="">
                                                                            <Box className="paragraph primary-text bold">
                                                                                {`${el.count}/${el.countAllowed}`}
                                                                            </Box>{' '}
                                                                            <Box className="small-subtitle primary-text">
                                                                                {this.optionsList(el.label)}
                                                                            </Box>
                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid >
                                                <Stack direction={'row'} spacing={1}>
                                                    <Button color="primary" variant="outlined">
                                                        {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.billing}
                                                    </Button>
                                                    <Button color="primary" variant="contained" onClick={this.navigateToUpgradePage}>
                                                        {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.upgrade}
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* Plan details end */}
                        <YourPlanListing navigate={this.props.navigate} location={this.props.location} />
                    </Box>
                </Box>
                {this.state.loading || this.props.users.isLoading ? <Loader /> : null}
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    servicePlan: state.ServicePlan,
    users: state.Users,
});

const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            getBillingDataRequest: Actions.getBillingDataRequest,
            getProjectsBillingDataRequest: Actions.getProjectsBillingDataRequest
        },
        dispatch,
    );

export default connect(mapStateToProps, mapActionsToProps)(YourPlan);

//@ts-nocheck
import { CustomFields } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
  
  
type OpportunityDynamicCustomField = {
    [key: string]: {
      name: string;
      value: string;
    };
  }
  
  const getUnLayerOpportunityConfig = (
    customFields: CustomFields,
    opportunityDynamicCustomField: OpportunityDynamicCustomField = {}
  ) => {

    opportunityDynamicCustomField['opportunityName'] = {
        name: "opportunityName",
        value: "{{{opportunityName}}}"

      }
      opportunityDynamicCustomField['opportunityStatus'] = {
        name: "opportunityStatus",
        value: "{{{opportunityStatus}}}"
        
      }
    // custom fields for audience contact
    if (customFields && customFields.length > 0) {
      for (let x = 0; x < customFields.length; x++) {
        opportunityDynamicCustomField[customFields[x]?.fieldData?.fieldName] = {
          name: customFields[x].fieldData?.fieldName ,
          value: "{{{" + customFields[x]?.fieldData?.fieldName + "}}}"
        }
      }
    }
    return {
      id: 'opportunityEditor',
      mergeTags: {
        customeField: {
          name: "Opportunity",
          mergeTags: opportunityDynamicCustomField,
        },
      },
    };
  }
  
  export default getUnLayerOpportunityConfig;
  
import React, { Component } from 'react'
import '../../sass/main.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Button from '@mui/material/Button'
import { redirectTo } from '../../helpers'

class CompleteRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountType: 'individual',
      paymentType: 'OneTime',
      errors: {},
      paymentMethod: 'paypal',
      cardHolderName: '',
      cardNumber: '',
      expireOn: '',
      cvv: '',
      email: '',
      name: '',
      companyName: '',
      companyEmail: '',
      spamMoniter: '',
      amountPaid: 100,
      loading: false,
      title: 'Complete Registration',
      pageTitle: 'Complete Registration',
    }
  }

  componentDidMount() {
    if (this.props.location.pathname.includes('member')) {
      var storage = localStorage.getItem('member_details')
    } else {
      var storage = localStorage.getItem('user_details')
    }
    if (storage && storage !== 'undefined') {
      const user = JSON.parse(storage) 
      this.setState({
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) { 
    if (this.props.profile) {
      if (
        this.props.profile.success === true &&
        this.props.profile.message !== prevProps.profile.message
      ) {
        createNotification('success', this.props.profile.message) 
        let st = prevState.loading ? this.setState({ loading: false }) : null

        setTimeout(() => {
          redirectTo(
            this.props.navigate,
            'create-event',
            this.props.location.pathname,
          )
        }, 5000)
      }

      if (this.props.profile.error !== prevProps.profile.error) {
        createNotification('error', this.props.profile.error)
        this.handleLoading()
      }
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleAccountType = (acType) => { 
    this.setState({ accountType: acType })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isValid = () => { 
    const {
      email,
      name,
      companyEmail,
      companyName,
      paymentMethod,
      cardHolderName,
      cardNumber,
      expireOn,
      cvv,
      spamMoniter,
      accountType,
    } = this.state
    let error = {}
    let formIsValid = true
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const regexCardNumber = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
    const regexCvv = /^[0-9]{3}$/
    const regexExp = /^(0[1-9]|1[0-2])\/([0-9]{2})$/

    if (accountType === 'individual') {
      if (email === '' || email.trim().length === 0) {
        formIsValid = false
        error['email'] = '*Please enter email.'
      }
      if (email && regexTest.test(email) === false) { 
        formIsValid = false
        error['email'] = '*Please enter a valid email address.'
      }

      if (name === '' || name.trim().length === 0) {
        formIsValid = false
        error['name'] = '*Please enter name.'
      }
    } else {
      if (companyEmail === '' || companyEmail.trim().length === 0) {
        formIsValid = false
        error['companyEmail'] = '*Please enter email.'
      }
      if (companyEmail && regexTest.test(companyEmail) === false) { 
        formIsValid = false
        error['companyEmail'] = '*Please enter a valid email address.'
      }
      if (companyName === '' || companyName.trim().length === 0) {
        formIsValid = false
        error['companyName'] = '*Please enter company name.'
      }
    }

    if (paymentMethod === 'card') {
      if (cardHolderName === '' || cardHolderName.trim().length === 0) {
        formIsValid = false
        error['cardHolderName'] = '*Please enter cardholder name.'
      }
      if (cardNumber === '' || cardNumber.trim().length === 0) {
        formIsValid = false
        error['cardNumber'] = '*Please enter card number.'
      }
      if (cardNumber && regexCardNumber.test(cardNumber) === false) {
       
        formIsValid = false
        error['cardNumber'] = '*Please enter a valid card number.'
      }
      if (expireOn === '' || expireOn.trim().length === 0) {
        formIsValid = false
        error['expireOn'] = '*Please enter expiry date.'
      }

      if (expireOn && regexExp.test(expireOn) === false) {
       
        formIsValid = false
        error['expireOn'] = '*Please enter a valid expiry date.'
      }
      if (cvv === '' || cvv.trim().length === 0) {
        formIsValid = false
        error['cvv'] = '*Please enter cvv.'
      }

      if (cvv && regexCvv.test(cvv) === false) {
      
        formIsValid = false
        error['cvv'] = '*Please enter a valid cvv.'
      }
    }

    if (spamMoniter === '') {
      formIsValid = false
      error['spamMoniter'] = '*Please select spam Moniter.'
    }

    this.setState({ errors: error })
    return formIsValid
  }

  onKeyPress(event) {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    if (/\+|-/.test(keyValue)) event.preventDefault()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    
    if (this.isValid()) {
      const {
        email,
        name,
        companyEmail,
        companyName,
        paymentMethod,
        cardHolderName,
        cardNumber,
        expireOn,
        cvv,
        spamMoniter,
        accountType,
        amountPaid,
        paymentType,
      } = this.state

      const requestbody = {
        email,
        name,
        companyEmail,
        companyName,
        paymentMethod,
        cardHolderName,
        cardNumber,
        expireOn,
        cvv,
        spamMoniter,
        accountType,
        amountPaid,
        paymentType,
      }
      this.props.updateProfile(requestbody)
      this.handleLoading()
      // this.props.startLoading();
    }
  }

  render() {
    const {
      paymentType,
      accountType,
      paymentMethod,
      cardHolderName,
      cardNumber,
      expireOn,
      cvv,
      name,
      email,
      companyEmail,
      companyName,
      errors,
      spamMoniter,
      loading,
      title,
      pageTitle,
    } = this.state
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="createProjectAccount">
            <div className="projectStepRow mt-2">
              <div className="stepsButtonAlternate">
                <button
                  type="button"
                  className={
                    this.state.accountType === 'individual' ? 'active' : ''
                  }
                  onClick={() => this.handleAccountType('individual')}
                >
                  Individual
                </button>
                <button
                  type="button"
                  className={
                    this.state.accountType === 'company' ? 'active' : ''
                  }
                  onClick={() => this.handleAccountType('company')}
                >
                  Company
                </button>
              </div>

              <div className="main_steppers">
                <div className="projectCreateSection">
                  <div className="CreatePresentation">
                    <div className="accountTypeTitle">
                      <h1>Pick your account type</h1>
                    </div>
                    <div className="createPersentSteps">
                      <div className="CPTabs"></div>
                      <div className="CPTabsDetails">
                        <div className="rsvpEventfieldBox">
                          <div className="formSection p-0">
                            <form onSubmit={this.handleSubmit}>
                              <div className="completeRegisterboxes">
                                <div className="induvidualPayColl CRCircularBox">
                                  <h3>PAYMENT DETAIL</h3>
                                  <div className="induvidualItemPayment">
                                    <div className="induvidualItem paymentColumn">
                                      <div className="field-group inputField">
                                        <label htmlFor="sel1">Item</label>
                                        <div className="selectButton">
                                          <select
                                            className="form-control"
                                            name="spamMoniter"
                                            value={spamMoniter}
                                            onChange={this.handleChange}
                                          >
                                            <option value="">
                                              Spam Monitoring
                                            </option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                          </select>
                                          <p className="error_mesage">
                                            {' '}
                                            {errors.spamMoniter}{' '}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="induvidualPayment paymentColumn">
                                      <div className="field-group inputField">
                                        <label htmlFor="sel1">
                                          Payment Type
                                        </label>
                                        <div className="paymentRadio">
                                          <label className="customRadioBtn">
                                            <input
                                              type="radio"
                                              checked={
                                                paymentType === 'OneTime'
                                              }
                                              value="OneTime"
                                              name="paymentType"
                                              onChange={this.handleChange}
                                            />
                                            One Time
                                            <span className="radiobtn"></span>
                                          </label>
                                          <label className="customRadioBtn">
                                            <input
                                              type="radio"
                                              name="paymentType"
                                              value="Reccuring"
                                              checked={
                                                paymentType === 'Reccuring'
                                              }
                                              onChange={this.handleChange}
                                            />
                                            Reccuring
                                            <span className="radiobtn"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {accountType === 'individual' ? (
                                  <div className="induvidualPayColl yourInformationCol CRCircularBox">
                                    <h3>YOUR INFORMATION</h3>
                                    <div className="field-group-flex m-0">
                                      <div className="field-group inputField">
                                        <label>Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          value={name}
                                          onChange={this.handleChange}
                                        />
                                        <p className="error_mesage">
                                          {' '}
                                          {errors.name}{' '}
                                        </p>
                                      </div>
                                      <div className="field-group inputField">
                                        <label>Email</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="email"
                                          value={email}
                                          onChange={this.handleChange}
                                        />
                                        <p className="error_mesage">
                                          {' '}
                                          {errors.email}{' '}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="induvidualPayColl CRCircularBox">
                                    <h3>Company INFOMATION</h3>
                                    <div className="field-group-flex m-0">
                                      <div className="field-group inputField">
                                        <label>Company Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="companyName"
                                          value={companyName}
                                          onChange={this.handleChange}
                                        />
                                        <p className="error_mesage">
                                          {' '}
                                          {errors.companyName}{' '}
                                        </p>
                                      </div>
                                      <div className="field-group inputField">
                                        <label>Company Email</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="companyEmail"
                                          value={companyEmail}
                                          onChange={this.handleChange}
                                        />
                                        <p className="error_mesage">
                                          {' '}
                                          {errors.companyEmail}{' '}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="completeRegisterboxes">
                                <div className="paymentMethod CRCircularBox">
                                  <div className="papymentCardPaypal">
                                    <div className="buttonPaypalCard">
                                      <div className="form-group">
                                        <label
                                          className={
                                            paymentMethod === 'paypal'
                                              ? 'customRadioBtn active mr-2'
                                              : 'customRadioBtn mr-2'
                                          }
                                        >
                                          <input
                                            type="radio"
                                            value="paypal"
                                            name="paymentMethod"
                                            checked={paymentMethod === 'paypal'}
                                            onChange={this.handleChange}
                                            className={
                                              paymentMethod === 'paypal'
                                                ? 'active'
                                                : ''
                                            }
                                          />
                                          Paypal
                                          <span className="radiobtn"></span>
                                        </label>
                                        <label
                                          className={
                                            paymentMethod === 'card'
                                              ? 'customRadioBtn active'
                                              : 'customRadioBtn'
                                          }
                                        >
                                          <input
                                            type="radio"
                                            value="card"
                                            name="paymentMethod"
                                            checked={paymentMethod === 'card'}
                                            onChange={this.handleChange}
                                          />
                                          Bank Card
                                          <span className="radiobtn"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="submittedPayment">
                                      <h3>Submitted Payment</h3>
                                      <div className="submittedAmt">
                                        549<sub>.00 USD</sub>
                                      </div>
                                    </div>
                                  </div>
                                  {paymentMethod === 'card' ? (
                                    <div className="papymentCardDetail">
                                      <div className="papymentCardField">
                                        <div className="field-group inputField cardNumber">
                                          <label>Card Number</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="cardNumber"
                                            type="number"
                                            value={cardNumber}
                                            onChange={this.handleChange}
                                          />
                                          <p className="error_mesage">
                                            {' '}
                                            {errors.cardNumber}{' '}
                                          </p>
                                        </div>
                                        <div className="field-group inputField cardName">
                                          <label>Card Holder Name</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="cardHolderName"
                                            value={cardHolderName}
                                            onChange={this.handleChange}
                                            maxLength={16}
                                            minLength={16}
                                          />
                                          <p className="error_mesage">
                                            {' '}
                                            {errors.cardHolderName}{' '}
                                          </p>
                                        </div>
                                        <div className="field-group inputField cardExpire">
                                          <label>Expires On</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="expireOn"
                                            value={expireOn}
                                            placeholder="MM/YY"
                                            onChange={this.handleChange}
                                            maxLength={5}
                                            minLength={5}
                                          />
                                          <p className="error_mesage">
                                            {' '}
                                            {errors.expireOn}{' '}
                                          </p>
                                        </div>
                                        <div className="field-group inputField cardCVCNumber">
                                          <label>CVV</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="cvv"
                                            value={cvv}
                                            maxLength={3}
                                            minLength={3}
                                            onChange={this.handleChange}
                                            type="number"
                                          />
                                          <p className="error_mesage">
                                            {' '}
                                            {errors.cvv}{' '}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="submitForm justify-content-center">
                                <Button
                                  variant="contained"
                                  className="themeBlueButton"
                                  color="primary"
                                  type="submit"
                                >
                                  Create My Account
                                </Button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.Profile,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProfile: Actions.profileRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(CompleteRegistration)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'

class ImportModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      importOpen: this.props.importOpen ? this.props.importOpen : false,
      csvImport: '',
      page_no: this.props.page_no ? this.props.page_no : 1,
    }
  }

  onOpenImportModal = () => {
    this.setState({ groupOpen: true })
  }

  onCloseImportModal = () => {
    this.setState({ groupOpen: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;

    if (
      this.props.contacts.importSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }

    if (
      this.props.contacts.importError &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '')
      this.setState({ csvImport: null })
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => {
    
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValidGroup = () => {
   
    const { csvImport } = this.state
    let error = {}
    let formIsValid = true

    if (csvImport === '') {
      formIsValid = false
      error['csvImport'] = '*Please select csvfile.'
    }
   
    this.setState({ errors: error })
    return formIsValid
  }

  handleGroupSubmit = (e) => {
    e.preventDefault() 
    if (this.isValidGroup()) { 
      const { csvImport } = this.state
      const formData = new FormData() 
      formData.append('page_no', this.props.page_no)
      formData.append('csvImport', csvImport)
      formData.append('permission', 'Create Audience')
      this.props.importContactRequest(formData)
      this.setState({ groupOpen: false })
      this.handleLoading() 
    }
  }

  onCSVChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0]

      if (!imageFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.')
        // this.setState({ invalidImage: 'Please select valid image.' });
        return false
      }
      this.setState({ csvImport: event.target.files[0] })
    }
  }

  render() {
    const { errors, loading } = this.state
    
    return (
      <div>
        <h2>Import Contacts</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form onSubmit={this.handleGroupSubmit}>
              <div class="field-group inputField fullWidthInput">
                <label>Select csv file to import contacts data</label>
                <input
                  type="file"
                  id="csvImport"
                  name="csvImport"
                  onChange={this.onCSVChange}
                  accept=".csv"
                />
                <p className="error_mesage"> {errors.csvImport} </p>
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  Import Contacts
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      importContactRequest: Actions.importContactRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ImportModal)

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Button, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import { theme } from '../../theme/theme';
export enum Modes {
  Floating = 'Floating',
  Inline = 'Inline',
}
type Props = {
  agentId: string;
  open: boolean;
  handleClose: () => void;
};

function EmbedCodePopUp({ open, agentId, handleClose }: Props) {
  const [isCopied, setIsCopied] = useState(false);
  const [widgetMode, setWidgetMode] = useState<Modes>(Modes.Floating);
  const [modalContent, setModalContent] = useState<string>('');
  const copyToClipboard = () => {
    navigator.clipboard.writeText(modalContent);
    setIsCopied(true);
  };
  useEffect(() => {
    onModeChange(widgetMode);
  }, [widgetMode]);
  function onModeChange(mode: Modes): void {
    let code = '';
    const url = `${process.env.REACT_APP_URL}/widget`;

    switch (mode) {
      case Modes.Floating:
        code = `<iframe
    style="width: 370px; height: -webkit-fill-available; position: absolute; right: 0px; bottom: 10px; border: 0;"
    type="text/html"
    srcdoc='
      <html>
        <head>
         <link rel="stylesheet" href="${url}/widget.css" />
        </head>
        <body>
          <div id="illumeet-widget"></div>
          <script>
          window.config = {
            agentId: "${agentId}",
            mode:"${Modes.Floating}"
          };
          </script>
          <script src="${url}/widget.js"></script>
        </body>
      </html>'
    width="370"
    noscroll
    allowtransparency="true"
    frameborder="0"
  ></iframe>`;
        break;
      case Modes.Inline:
        code = `<iframe type="text/html"
    srcdoc='
      <html>
        <head>
         <link rel="stylesheet" href="${url}/widget.css" />
        </head>
        <body>
          <div id="illumeet-widget"></div>
          <script>
          window.config = {
            agentId: "${agentId}",
              mode:"${Modes.Inline}"
          };
          </script>
          <script src="${url}/widget.js"></script>
        </body>
      </html>'
    width="100%"
    height="500px"
    noscroll
    allowtransparency="true"
    frameborder="0"
  ></iframe>`;
        break;
      default:
        break;
    }
    setIsCopied(false);
    setWidgetMode(mode);
    setModalContent(code);
  }
  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={handleClose}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid size={{xs: 12,}} pb={1.5}>
            <Box mb={1} className="subsecondary" >
              Embedded Code
            </Box>
            <Box mb={1} className="small-subtitle">
              To add chatbot anywhere on your website, add this iframe to your
              html code
            </Box>
            <Box component="div" className="inputField">
              <Box>
                <label>Widget Style</label>
              </Box>
              <RadioGroup
                row
                style={{ flexWrap: 'nowrap' }}
                aria-label="early-access"
                name="early-access"
                defaultValue="all"
              >
                <Box
                  component={FormControlLabel}
                  m={0}
                  id="Floating"
                  value={Modes.Floating}
                  name="widgetMode"
                  onChange={() => onModeChange(Modes.Floating)}

                  control={
                    <Radio
                      checked={widgetMode === Modes.Floating}
                      color="primary"
                    />
                  }
                  label={Modes.Floating}
                />
                <Box
                  component={FormControlLabel}
                  m={0}
                  id="Inline"
                  value={Modes.Inline}
                  name="widgetMode"
                  onChange={() => onModeChange(Modes.Inline)}

                  control={
                    <Radio
                      checked={widgetMode === Modes.Inline}
                      color="primary"
                    />
                  }
                  label={Modes.Inline}
                />
              </RadioGroup>
            </Box>
          </Grid>
          <Grid size={{xs: 12,}} pb={1.5}>
            <Box component="div" className="inputField">
              <pre>
                <textarea
                  className="form-control code-interface"
                  rows={10}
                  readOnly
                  value={modalContent}
                />
              </pre>
            </Box>
          </Grid>
          <Grid size={{xs: 12,}}>
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={copyToClipboard}
              >
                {isCopied ? 'Code Copied' : 'Copy Code'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
}

export default EmbedCodePopUp;

import React, { Component } from 'react';
import { Stack } from '@mui/material';
import { getProjectIdFromUrl } from '../../../helpers/common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from 'Components/Loader/loader';
import Grid from '@mui/material/Grid2';

export class OverviewSummaryCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            summaryDetails: [{}],
            loading:false
        }
    }

    async componentDidMount() {
        this.getEventSummaryDetails()
    }

    componentDidUpdate(prevProps, prevState) {  
        if (this.props.events.getEventSummaryDetailsSuccess === true && prevProps.events.getEventSummaryDetailsSuccess === false && this.props.events.message !== prevProps.events.message) {
            if(this.props.events.summaryDetails){
                this.setState({summaryDetails: this.props.events.summaryDetails,loading:false})
            }
        }
    }

    getEventSummaryDetails = () => {
        const projectId = getProjectIdFromUrl()
        let requestbody = {
            eventId: projectId,
            permission: 'View Project',
            overview:true
        }
        this.props.getEventSummaryDetailsRequest(requestbody)
        this.setState({
            loading:true
        })
    }

    render() {   
        const {      
            summaryDetails,
            loading             
        } = this.state;    

        return (  
            <div>           
            <Grid container spacing={1}>
                <Grid size={{xs: 6, md:6, xl:4}} className="tabpanel_block2a">
                    <div>
                    <h5>{summaryDetails[0].campaignContactsCount ? summaryDetails[0].campaignContactsCount: '0'}</h5>
                    <p>{this.props.users.languageStateForRedux?.overviewTab.recipientsIn}{summaryDetails[0].campaignsCount ? summaryDetails[0].campaignsCount: '0'} {this.props.users.languageStateForRedux?.overviewTab?.campaignsCard}</p>
                    </div>
                </Grid>
                <Grid size={{xs: 6, md:6, xl:4}} className="tabpanel_block2a">
                    <div>
                    <h5>{summaryDetails[0].openEmailsCount ? summaryDetails[0].openEmailsCount: '0'}</h5>
                    <p>{this.props.users.languageStateForRedux?.overviewTab?.openedEmail}</p>
                    </div>
                </Grid>
                <Grid size={{xs: 6, md:6, xl:4}} className="tabpanel_block2a">
                    <div>
                    <h5>{summaryDetails[0].attendeesCount ? summaryDetails[0].attendeesCount: '0'}</h5>
                    <p>{this.props.users.languageStateForRedux?.overviewTab?.attendees}</p>
                    </div>
                </Grid>
                <Grid size={{xs: 6, md:6, xl:4}} className="tabpanel_block2a">
                    <div>
                    <h5>{summaryDetails[0].conversionRate ? 
                            Number.isInteger(summaryDetails[0].conversionRate) ? 
                                summaryDetails[0].conversionRate:
                                summaryDetails[0].conversionRate.toFixed(2)
                        :'0'}%</h5>
                    <p>{this.props.users.languageStateForRedux?.overviewTab?.convRate}</p>
                    </div>
                </Grid>
                <Grid size={{xs: 6, md:6, xl:4}} className="tabpanel_block2a">
                    <div>
                    <h5>{summaryDetails[0].checkInAttendeesCount ? summaryDetails[0].checkInAttendeesCount: '0'}</h5>
                    <p>{this.props.users.languageStateForRedux?.overviewTab?.checkAttendee}</p>
                    </div>
                </Grid>
                <Grid size={{xs: 6, md:6, xl:4}} className="tabpanel_block2a">
                    <div>
                    <h5>{summaryDetails[0].attendeesCount>0?
                            Number.isInteger(((summaryDetails[0].checkInAttendeesCount/summaryDetails[0].attendeesCount)*100)) ?
                                ((summaryDetails[0].checkInAttendeesCount/summaryDetails[0].attendeesCount)*100):
                                ((summaryDetails[0].checkInAttendeesCount/summaryDetails[0].attendeesCount)*100).toFixed(2)
                        : 0}%                        
                    </h5>
                    <p>{this.props.users.languageStateForRedux?.overviewTab?.rate}</p>
                    </div>
                </Grid>
            </Grid> 
            {loading ? <Loader/> : null} 
            </div> 
        )
    }
}

const mapStateToProps = state => ({
    events: state.Events,
    users: state.Users,
  });
  
  const mapActionsToProps = dispatch =>
    bindActionCreators(
      {
        getEventSummaryDetailsRequest: Actions.getEventSummaryDetailsRequest
      },
      dispatch
    );
  export default connect(mapStateToProps, mapActionsToProps)(OverviewSummaryCards);


import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import { Button } from '@mui/material';
import { ValidationResponse } from './ImportContacts';
import ErrorsListModalInImportWizard from './ErrorsListModalInImportWizard';
import { useLocation } from 'react-router';
import OpportunityContactsAPI from 'ReduxStore/API/opportunity/opportunityContactsAPI';
import { createNotification } from 'helpers';
import { ImportWizardContext } from 'Contexts/ImportWizardContextProvider';
import { UserData } from 'ReduxStore/API';
import ProgressBar from 'Common/ProgressBar';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import OpportunityAccountsAPI from 'ReduxStore/API/opportunity/opportunityAccountsAPI';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';
import { OpportunityContactsContext } from 'Contexts/OpportunityContactsProvider';
import { FetchContactsQueryParams } from '../Contacts/Contacts';
import { Actions } from 'ReduxStore/Actions';
import { useDispatch } from 'react-redux';
import { OpportunityAccountsContext } from 'Contexts/OpportunityAccountsProvider';
import { FetchAccountsQueryParams } from '../Accounts/Accounts';
import { OpportunityOpportunitiesContext } from 'Contexts/OpportunityOpportunitiesProvider';
import Loader from 'Components/Loader/loader';
const REACT_APP_BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

type Props = {
  validationResponse: ValidationResponse;
  keyFieldForContacts: string;
  keyFieldForAccounts: string;
  keyFieldForOpportunities: string;
  skipExistingContacts: boolean;
  skipExistingAccounts: boolean;
  skipExistingOpportunities: boolean;
};

function ImportSummaryModal({
  validationResponse,
  keyFieldForContacts,
  keyFieldForAccounts,
  keyFieldForOpportunities,
  skipExistingContacts,
  skipExistingAccounts,
  skipExistingOpportunities,
}: Props) {
  const dispatch = useDispatch();
  const location = useLocation()
  const currentPath = location.pathname.split('/').slice(-1)[0]
  const importWizardContext = useContext(ImportWizardContext);
  if (!importWizardContext) {
    throw new Error(
      'ImportWizardModal must be used within an ImportWizardContextProvider',
    );
  }

  const opportunityContactsContext = useContext(
    OpportunityContactsContext,
  );
  if (!opportunityContactsContext) {
    throw new Error(
      'Contacts must be used within an opportunityContactsContextProvider',
    );
  }

  const opportunityAccountsContext = useContext(
    OpportunityAccountsContext,
  );
  if (!opportunityAccountsContext) {
    throw new Error(
      'Accounts must be used within an opportunityAccountContextProvider',
    );
  }
  
  const opportunityOpportunitiesContext = useContext(
    OpportunityOpportunitiesContext,
  );
  if (!opportunityOpportunitiesContext) {
    throw new Error(
      'Opportunities must be used within an opportunityOpportunitiesContextProvider',
    );
  }

  const { openedModal, dispatchOpenModal, importFor } = importWizardContext;
  const { openImportSummary } = openedModal;
  const [isImportInProgress, setIsImportInProgress] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [recordsWithErrors, setRecordsWithErrors] = useState(0);
  const [uniqueData, setUniqueData] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecordsWithErrors(
      validationResponse.totalRows - validationResponse.readyForImportCount,
    );
  }, [validationResponse.totalRows, validationResponse.readyForImportCount]);

  useEffect(() => {
    if (progressPercentage === 100) {
      fetchAllData();
      setTimeout(() => {
        setShowProgress(false);
        setOpenSuccess(true);
        setProgressPercentage(0);
        setIsImportInProgress(false);
      }, 500);
    }
  }, [progressPercentage]);

  const fetchAllContacts = () => {
    const { pageNo, limit, sortBy, sortOrder, filterByStatus, filterBySource, filterByAudience, filterByLastImported, searchText } = opportunityContactsContext;
      
    const queryParams: FetchContactsQueryParams = {
      pageNo,
      limit,
      sortBy,
      sortOrder,
      from: 'viewContact',
    };
    const data = {
      queryParams,
      bodyParams: { filterArray: {
        filterByStatus,
        filterBySource,
        filterByAudience,
        filterByLastImported
      }, searchText },
    };
    dispatch(Actions.fetchAllOpportunityContactsRequest(data));
  }

  const fetchAllAccounts = () => {
    const { pageNo, limit, sortBy, sortOrder, filterByStatus, filterBySource, filterByCustomField, filterByLastImported, searchText } = opportunityAccountsContext;
    const queryParams: FetchAccountsQueryParams = {
      pageNo,
      limit,
      sortBy,
      sortOrder,
      searchText,
      from: 'viewAccount',
    };
    const bodyParamsForfiltering = {
      filterByCustomField,
      filterByStatus,
      filterByLastImported,
      filterBySource
    };
    const data = {
      queryParams,
      bodyParams: bodyParamsForfiltering,
    };
    dispatch(Actions.fetchAllOpportunityAccountsRequest(data));
  }

  const fetchAllOpportunities = () => {
    const { pageNo, limit, sortBy, sortOrder, filterByState, filterBySource, filterByProject, filterByCustomField, filterByLastImported, searchText } = opportunityOpportunitiesContext;
      const queryParams: FetchAccountsQueryParams = {
        pageNo,
        limit,
        sortBy,
        sortOrder,
        searchText,
        from: 'viewOpportunity',
      };
      const bodyParamsForfiltering = {
        filterByState,
        filterByProject,
        filterBySource,
        filterByCustomField,
        filterByLastImported,
      };
      const data = {
        queryParams,
        bodyParams: bodyParamsForfiltering,
      };
      dispatch(Actions.fetchAllOpportunityOpportunitiesRequest(data));
  }

  const fetchAllData = () => {
    if (importFor === 'Contacts') {
      fetchAllContacts()
    }
    if (importFor === 'Accounts') {
      fetchAllAccounts()
      fetchAllContacts()
    }
    if (importFor === 'Opportunities') {
      fetchAllOpportunities()
      fetchAllContacts()
      fetchAllAccounts()
    }
  };

  const handleClickImport = async () => {
    try {
      let res: any;
      setLoading(true)
      if (importFor === 'Contacts') {
        const opportunityContactsAPI = new OpportunityContactsAPI();
        const queryParams = {
          keyField: keyFieldForContacts,
          skipExisting: skipExistingContacts,
          idToAccessTheCorrectData: validationResponse.idToAccessTheCorrectDataOfContact,
        };

        res = await opportunityContactsAPI.importCsvDataAfterValidationIsDone(
          queryParams,
        );
      }
      if (importFor === 'Accounts') {
        const opportunityAccountsAPI = new OpportunityAccountsAPI();
        const queryParams = {
          keyFieldForAccounts: keyFieldForAccounts,
          keyFieldForContacts: keyFieldForContacts,
          skipExistingAccounts: skipExistingAccounts,
          skipExistingContacts: skipExistingContacts,
          idToAccessTheCorrectDataOfContact: validationResponse.idToAccessTheCorrectDataOfContact,
          idToAccessTheCorrectDataOfAccount: validationResponse.idToAccessTheCorrectDataOfAccount,
        };

        res = await opportunityAccountsAPI.importCsvDataAfterValidationIsDone(
          queryParams,
        );
      }
      if (importFor === 'Opportunities') {
        const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
        const queryParams = {
          keyFieldForOpportunities: keyFieldForOpportunities,
          keyFieldForAccounts: keyFieldForAccounts,
          keyFieldForContacts: keyFieldForContacts,
          skipExistingOpportunities: skipExistingOpportunities,
          skipExistingAccounts: skipExistingAccounts,
          skipExistingContacts: skipExistingContacts,
          idToAccessTheCorrectDataOfOpportunity: validationResponse.idToAccessTheCorrectDataOfOpportunity,
          idToAccessTheCorrectDataOfContact: validationResponse.idToAccessTheCorrectDataOfContact,
          idToAccessTheCorrectDataOfAccount: validationResponse.idToAccessTheCorrectDataOfAccount,
        };

        res =
          await opportunityOpportunitiesAPI.importCsvDataAfterValidationIsDone(
            queryParams,
          );
      }
      if (res.status === 200 && res.data.success) {
        getProgressCount(res?.data.importProgressId);
        setIsImportInProgress(true);

        // createNotification('success', 'Data Imported Successfully');
        setUniqueData(res?.data.uniqueData);
        setTotalRecords(res?.data.totalRecords);
        dispatchOpenModal('CLOSE_THE_WIZARD');
      }
    } catch (err) {
      console.log('===err', err);
      if (err instanceof Error) {
        createNotification('error', err.message);
      }
    } finally {
      setLoading(false)
    }  
  };
 
  const getProgressCount = async (importProgressId: string) => {
    const users: any = await UserData();
    const reqUrl = 'opportunityManagement/importProgress';

    const requestUrl = `${REACT_APP_BACKEND_API_URL}/${reqUrl}/${importProgressId}?token=bearer ${users.token}`;

    const eventSource = new EventSource(requestUrl);
    eventSource.onmessage = (event) => {
      //we will get error if any error occured in the import|copy progress
      if (event.data === 'error') {
        //closing eventSource connection
        eventSource.close();
        //hiding progress bar popup
        setShowProgress(false);
        setProgressPercentage(0);
        setIsImportInProgress(false);
      } else {
        const progress = parseInt(event.data);
        setProgressPercentage(progress);
        setShowProgress(true);
        //if the progress completed
        if (progress === 100) {
          //close progress popup & open successful messsage after some delay
          eventSource.close();
        }
      }
    };
  };

  const downloadErrorFIle = () => {
    if (validationResponse.csvData) {
      const url = window.URL.createObjectURL(
        new Blob([validationResponse.csvData]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Error.csv'); // or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const hideProgress = () => {
    setShowProgress(false);
  };

  const handleCloseImportSuccessPopup = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Modal
        open={openImportSummary}
        onClose={() => dispatchOpenModal('CLOSE_THE_WIZARD')}
        closeOnOverlayClick={false}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal wider',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid size={{xs: 12,}} pb={4}>
            <Box component="p" mb={1} className="subsecondary">
              Import Summary
            </Box>
            <Box className="small-subtitle">
              Review the summary of your import files
            </Box>
          </Grid>
        </Grid>

        <Grid spacing={3} container className="modal-wrapper">
          <Grid size={{xs: 12,}}>
            <Stack
              direction={'row'}
              pb={3}
              borderBottom={'1px solid #EDEBFF'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box className="paragraph" color={'#000'}>
                Total Errors
              </Box>
              <Box className="paragraph bold" px={1} color={'#000'}>
                {validationResponse.inValidItemsCount}
              </Box>
            </Stack>
          </Grid>
          <Grid size={{xs: 12,}}>
            <Stack
              direction={'row'}
              pb={3}
              borderBottom={'1px solid #EDEBFF'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box className="paragraph" color={'#000'}>
                Ready for import
              </Box>
              <Box
                py={0.3}
                px={1}
                sx={{
                  borderRadius: '20px  !important',
                  color: '#fff',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
                bgcolor={'#0addb4'}
              >
                {!validationResponse.inValidItemsCount ? validationResponse.readyForImportCount : 0}
              </Box>
            </Stack>
          </Grid>
          <Grid size={{xs: 12,}}>
            <Stack
              direction={'row'}
              pb={3}
              borderBottom={'1px solid #EDEBFF'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box className="paragraph" color={'#000'}>
                {importFor} With Errors
              </Box>
              <Stack
                direction={'row'}
                spacing={1}
                className="paragraph bold"
                color={'#000'}
              >
                {validationResponse.inValidItemsCount > 0 && (
                  <Box
                    py={0.5}
                    px={1}
                    sx={{
                      borderRadius: '20px  !important',
                      border: '1px solid #5141E7',
                      color: '#5141E7 !important',
                      cursor: 'pointer',
                      fontSize: '12px',
                    }}
                    className="table-status"
                    onClick={() => dispatchOpenModal('OPEN_ERROR_LIST_MODAL')}
                  >
                    View Errors
                  </Box>
                )}
                {validationResponse.inValidItemsCount > 0 && (
                  <Box
                    py={0.5}
                    px={1}
                    sx={{
                      borderRadius: '20px  !important',
                      border: '1px solid #5141E7',
                      color: '#5141E7 !important',
                      cursor: 'pointer',
                      fontSize: '12px',
                    }}
                    onClick={downloadErrorFIle}
                  >
                    Download Errors File
                  </Box>
                )}
                <Box
                  py={0.3}
                  px={1}
                  sx={{
                    borderRadius: '20px  !important',
                    color: '#fff',
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                  bgcolor={'#ff4170'}
                >
                  { validationResponse.recordsWithErrors || recordsWithErrors}
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Grid container mt={3}>
          <Grid size={{xs: 12,}}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button
                  variant="outlined"
                  color="primary"
                  disableElevation
                  onClick={() => dispatchOpenModal('OPEN_CONTACT_PAGE')}
                >
                  Go Back
                </Button>
                {(importFor === "Accounts" ? validationResponse.idToAccessTheCorrectDataOfAccount 
                : importFor === "Contacts" ? validationResponse.idToAccessTheCorrectDataOfContact 
                : validationResponse.idToAccessTheCorrectDataOfOpportunity)
                &&
                  validationResponse.inValidItemsCount === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={handleClickImport}
                    >
                      Import
                    </Button>
                  )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <ErrorsListModalInImportWizard validationResponse={validationResponse} />
      <Modal
        open={showProgress}
        onClose={hideProgress}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <ProgressBar
          progressPercentage={progressPercentage}
          import={isImportInProgress}
        />
      </Modal>
      <SuccessAlertPopUp
        openModal={openSuccess}
        closeModalFunc={handleCloseImportSuccessPopup}
        title="Import Successful!"
        description={`Great Job! You have successfully imported ${totalRecords} ${
          importFor === 'Accounts'
            ? 'accounts'
            : importFor === 'Contacts'
            ? 'contacts'
            : 'opportunities'
        }`}
        buttonColor="primary"
        buttonFunc={handleCloseImportSuccessPopup}
        buttonText="Done"
      />
      {loading && <Loader/>}
    </>
  );
}

export default ImportSummaryModal;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'

import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
class UpdateGroupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      groupName: this.props.data.groupName,
      page_no: this.props.page_no ? this.props.page_no : 1,
      group_id: this.props.group_id ? this.props.group_id : '',
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
  

    if (
      this.props.groups.success === true &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }

    if (
      this.props.groups.error &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      createNotification('error', this.props.groups.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValidGroup = () => {
    const { groupName, group_id } = this.state
    let error = {}
    let formIsValid = true

    if (group_id === '' || group_id.trim().length === 0) {
      formIsValid = false
      error['group_id'] = '*Please select audience.'
    }
    if (groupName === '' || groupName.trim().length === 0) {
      formIsValid = false
      error['groupName'] = '*Please enter audience.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  handleGroupSubmit = (e) => {
    e.preventDefault()
    if (this.isValidGroup()) {
      const { group_id, groupName } = this.state

      const requestbody = { 
        id: group_id, 
        groupName:groupName.replace(/\s{2,}/g, ' ').trim(),
        permission: 'Edit Audience',
        lngCode: this.props.users.newLangState
      }
      this.props.updateGroup(requestbody)
      this.handleLoading()
    }
  }

  render() {
    const { errors, loading, group_id, groupName } = this.state
    return (
      <ThemeProvider theme={theme}>

        <Grid component='form' onSubmit={this.handleGroupSubmit} container className='modal-wrapper' >
          <Grid size={{xs: 12,}} pb={4}>
            <Box component="p" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.audienceTab?.updateAudience}</Box>
            <Box className='small-subtitle'>
            {this.props.users.languageStateForRedux?.audienceTab?.updateAudienceModalDesc}
              </Box>
          </Grid>
        
          <Grid size={{xs: 12,}}>

            <Box component="div" className='inputField' pb={4}>
              <label>{this.props.users.languageStateForRedux?.audienceTab?.audienceName} <span className="required">*</span></label>
              <input
                type="text"
                className="form-control small"
                name="groupName"
                value={groupName}
                onChange={this.handleChange}
              />
              <span className="error_mesage"> {errors.groupName} </span>
            </Box>
          </Grid>
          <Grid size={{xs: 12,}} >
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row" fullWidth>
                <Button type="submit" variant="contained" color="primary" disableElevation>  {this.props.users.languageStateForRedux?.common?.save}
                 </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>


        <NotificationContainer />
        {loading ? <Loader /> : null}
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  users: state.Users
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGroup: Actions.updateGroupRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(UpdateGroupModal)

import React, { useEffect } from 'react';
import { theme } from '../../theme/theme';
import {
  Box,
  Popper,
  Fade,
  ClickAwayListener,
  MenuList,
  MenuItem,
  OutlinedInput,
  Button
} from '@mui/material';


import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack } from '@mui/material';
const CustomSearchableDropdownWithLoadMore = ({
  options,
  value,
  handleChange,
  handleSearchListInSearchableDropdown,
  handlePageChange,
  disabled,

}) => {
  const [selectedItem, setSelectedItem] = React.useState({});
  const [searchDropdown, setSearchDropdown] = React.useState(false);
  const [searchEnabled, setSearchEnabled] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const anchorRef = React.useRef(null);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  const handleSearchDropdown = (event) => {
    setSearchDropdown(true);
  };

  const handleSearchDropdownClose = (event) => {
    setSearchDropdown(false);
    setSearchEnabled(false);
    setSearchText('');
  };

  const handleClearSearchDropdown = () => {
    setSearchDropdown(false);
    setSearchEnabled(false);
    selectedItem.hasOwnProperty('name') ? setSelectedItem({ name: '' }) : setSelectedItem({ email: '' });
    setSearchText('');
    handleChange({});
  };

  const handleSearchEnabled = (e) => {
    setSearchDropdown(true);
    setSearchEnabled(true);
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handleSearchListInSearchableDropdown(searchText);
  }, [searchText]);


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setSearchDropdown(false);
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        marginTop: 4,
      },
    },
  };

  return (
    <>

      <Box
        style={{
          position: 'relative',
        }}
      >
        <OutlinedInput
          // sx={{
          //   marginTop: '2px',
          //   padding: '3px 0px',
          // }}
          notched={false}
          size="medium"
          fullWidth
          placeholder={'Search'}
          name=""
          value={searchEnabled ? searchText : (selectedItem.name ? selectedItem.name : (selectedItem.email ? selectedItem.email : ""))}
          className="multiselect-input"
          ref={anchorRef}
          aria-controls={searchDropdown ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleSearchDropdown}
          onChange={handleSearchEnabled}
          inputProps={{
            maxLength: 63,
          }}

          endAdornment={
            <InputAdornment position="end" className="controls">
              <IconButton
                aria-label="clear search"
                onClick={handleClearSearchDropdown}
                edge="end"
                size='small'
              >
                <CloseIcon fontSize='small' />
              </IconButton>

              <ArrowDropDownIcon />
            </InputAdornment>
          }
        />
        <Popper
          open={searchDropdown}
          style={{ zIndex: 1099, width: '100%' }}
          placement="bottom-start"
          transition
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Box
                sx={{
                  maxHeight: '40vh',
                  overflow: 'scroll',
                  backgroundColor: theme.palette.common.white,
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              >
                <ClickAwayListener onClickAway={handleSearchDropdownClose}>
                  <Box id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuList
                      MenuProps={MenuProps}
                    >
                      {Array.isArray(options) &&
                        options.length > 0 ?
                        options?.map((option) => {
                          return (
                            <MenuItem value={option._id} className="search-item" key={option._id}
                              onClick={() => {
                                setSearchEnabled(false);
                                handleSearchDropdownClose();
                                handleChange(option);
                              }}
                            >
                              <Box className='long-text' >
                                {option.eventName || option.name || option.email}
                              </Box>
                            </MenuItem>
                          );
                        })
                        :

                        <MenuItem className="" style={{ justifyContent: 'center' }}  >
                          <Box className='long-text'  >
                            No records
                          </Box>
                        </MenuItem>
                      }
                    </MenuList>
                    
                    {Array.isArray(options) &&
                      options.length > 0 &&
                      <Stack justifyContent={"center"} alignItems={"center"} pb={1} >
                        <Button variant="outlined" size="small" onClick={handlePageChange} disabled={disabled}>Load More</Button>
                      </Stack>
                    }
                  </Box>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}

        </Popper>
        {/*  } */}
      </Box>

    </>
  );
};

export default CustomSearchableDropdownWithLoadMore;

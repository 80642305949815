//@ts-nocheck
import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../theme/theme'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import MoreIcon from '../../../../src/assets/images/icons/more.svg';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import '../../../sass/main.scss';
import { Stack } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Loader from '../../../Components/Loader/loader';
import Checkbox from '@mui/material/Checkbox';
import { createNotification } from '../../../helpers';
import { generateRandomString } from '../../../helpers/common';
import { ACCESS_CODE_EXPERING_PERIOD } from '../../../constants/constants';
import Pagination from 'react-js-pagination';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getProjectIdFromUrl } from '../../../helpers/common';
import moment from 'moment';
import ExportIcon from '../../../assets/images/icons/export.svg';
import Importing from '../../../assets/images/importing.svg';
import SuccessPopup from '../../../assets/images/successPopup.svg';
//import { ExportToCsv } from 'export-to-csv';
import { generateCsv, mkConfig } from 'export-to-csv';
import InvalidIcon from '../../../assets/images/icons/invalid.svg';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import Exporting from '../../../assets/images/export.gif';

import {
  UserData,
} from '../../../helpers/common';
import ProgresBar from '../../../Common/ProgressBar';

class AccessCode extends Component {
  constructor(props) {
    super(props);
    this.anchorRef2 = React.createRef();
    this.anchorRef = React.createRef();
    this.anchorRefAction = React.createRef();

    this.checkBoxIdsArr = [];
    this.state = {
      page_no: 1,
      itemCount: 10,
      startCount: 1,
      endCount: 0,
      searchText: '',
      limit: 10,
      sortBy: 'expiredOn',
      order: 'desc',
      accessCodeList: [],
      filterByStatus: {},
      filterBySource: {},
      open1: false,
      open: false,
      open2: false,
      status: [],
      filterStatus: [],
      filterSource: [],
      source: [],
      statusForActiveClass: 'all',
      accessCode: '',
      expringPeriod: '',
      customExpringPeriod: '',
      isEdit: false,
      accessCodeId: '',
      loading: false,
      openDeleteAccesCodeModal: false,
      changeState: false,
      singleDelete: false,
      checkedData: false,
      isDeleteAccessCode: false,
      errors: {},
      copyText: false,
      openImportModal: false,
      openValidation: false,
      accessCodeCsv: '',
      openSuccess: false,
      openDisabledModal: false,
      allowed: {},
      progressPercentage: 0,
      showProgress: false,
      importAccessCodeId: 0,
      import: false,
      downloadExport: false
    };
  }

  componentDidMount = () => {
    const projectId = getProjectIdFromUrl();
    this.setState(
      {
        projectId,
      },
      () => {
        this.listAccessCode();
      },
    );
    this.props?.location?.state?.attendeeEmailForAccessCode &&
      this.setState({
        searchText: this.props?.location?.state?.attendeeEmailForAccessCode,
      });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.project?.project?.permissions) {
      let allowed = {};
      if (this.props.project.project.permissions.find(o => o.permission_name === 'View AccessCodes')) {
        allowed['View AccessCodes'] = true;
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'View Attendees')) {
        allowed['View Attendees'] = true;
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Create AccessCodes')) {
        allowed['Create AccessCodes'] = true;
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Edit AccessCodes')) {
        allowed['Edit AccessCodes'] = true;
      }
      if (this.props.project.project.permissions.find(o => o.permission_name === 'Delete AccessCodes')) {
        allowed['Delete AccessCodes'] = true;
      }
      this.state.allowed = allowed;
    }

    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      } else {
        this.setState({
          endCount: parseInt(this.props.AccessCode.totalResults),
        });
      }
    }

    if (
      this.props.AccessCode.exportAccessCodeSuccess !==
      prevProps.AccessCode.exportAccessCodeSuccess &&
      this.props.AccessCode.exportAccessCodeSuccess === true
    ) {
      this.setState({
        loading: false,
        downloadExport: false
      });
    }

    if (
      this.props.AccessCode.listAccessCodeSuccess !==
      prevProps.AccessCode.listAccessCodeSuccess &&
      this.props.AccessCode.listAccessCodeSuccess === true
    ) {
      this.setState(
        {
          accessCodeList: this.props.AccessCode.accessCode,
          filterByStatus: this.props.AccessCode.filterByStatus,
          filterBySource: this.props.AccessCode.filterBySource,
          totalResults: this.props.AccessCode.totalResults,
          loading: false,
          isExport: false,
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            });
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            });
          }
        },
      );
    }

    if (
      this.props.AccessCode.addAccessCodeSuccess !==
      prevProps.AccessCode.addAccessCodeSuccess &&
      this.props.AccessCode.addAccessCodeSuccess === true
    ) {
      createNotification('success', this.props.AccessCode.message);
      this.handleCloseAddModel();
      this.listAccessCode();
      this.setState({
        loading: false,
        isDeleteAccessCode: false,
        searchText: '',
      });
    }

    if (
      this.props.AccessCode.editAccessCodeSuccess !==
      prevProps.AccessCode.editAccessCodeSuccess &&
      this.props.AccessCode.editAccessCodeSuccess === true
    ) {
      createNotification('success', this.props.AccessCode.message);
      this.handleCloseAddModel();
      this.listAccessCode();
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.AccessCode.changeAccessCodeSuccess !==
      prevProps.AccessCode.changeAccessCodeSuccess &&
      this.props.AccessCode.changeAccessCodeSuccess === true
    ) {
      createNotification('success', this.props.AccessCode.message);
      this.listAccessCode();
      this.setState({
        loading: false,
        openDisabledModal: false,
        status: [],
      });
    }

    if (
      this.props.AccessCode.deleteAccessCodeSuccess !==
      prevProps.AccessCode.deleteAccessCodeSuccess &&
      this.props.AccessCode.deleteAccessCodeSuccess === true
    ) {
      createNotification('success', this.props.AccessCode.message);
      this.listAccessCode();
      this.handleCloseConfirmation();
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.AccessCode.importAccessCodeSuccess !==
      prevProps.AccessCode.importAccessCodeSuccess &&
      this.props.AccessCode.importAccessCodeSuccess === true
    ) {
      this.setState({
        openImportModal: false,
        // openImporting: false,
        openValidation: false,
        // openSuccess: true,
        accessCodeCsv: '',
        loading: false,
        importAccessCodeId: this.props.AccessCode?.importAccessCodeId
      });
      if (this.props.AccessCode?.importAccessCodeId) {
        let params = {
          projectId: this.state.projectId,
          importAccessCodeId: this.props.AccessCode?.importAccessCodeId
        };
        this.getProgressCountOfAccessCode(params);
        this.setState({
          showProgress: true,
          import: true
        });
      } else {
        this.listAccessCode();

      }

    }

    if (
      this.props.AccessCode.accessCodeDownloadCsvSuccess !==
      prevProps.AccessCode.accessCodeDownloadCsvSuccess &&
      this.props.AccessCode.accessCodeDownloadCsvSuccess === true
    ) {
      createNotification('success', this.props.AccessCode.message);
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.AccessCode.importAccessCodeValidation === true &&
      prevProps.AccessCode.importAccessCodeValidation === false &&
      this.props.AccessCode.message !== prevProps.AccessCode.message
    ) {
      this.setState({
        openImporting: false,
        // openImportModal: false,
        openValidation: true,
        loading: false,
      });
    }

    if (
      this.props.AccessCode.importAccessCodeError === true &&
      prevProps.AccessCode.importAccessCodeError === false &&
      this.props.AccessCode.message !== prevProps.AccessCode.message
    ) {
      this.setState({
        // openImporting: false,
        loading: false,
      });
    }

    if (
      this.props.AccessCode.error !== prevProps.AccessCode.error &&
      this.props.AccessCode.error === true
    ) {
      createNotification('error', this.props.AccessCode.message);
      this.setState({
        loading: false,
      });
    }
  };

  listAccessCode = () => {
    const {
      page_no,
      searchText,
      projectId,
      limit,
      sortBy,
      order,
      status,
      source,
    } = this.state;
    const body = {
      pageNumber: page_no,
      searchText: searchText.trim(),
      projectId,
      limit,
      sortBy,
      order,
      status,
      source,
    };
    this.props.listAccessCode(body);
    this.handleLoading();
  };

  exportAccessCodes = () => {
    const {
      page_no,
      searchText,
      projectId,
      limit,
      sortBy,
      order,
      status,
      source,
      isExport,
    } = this.state;
    const body = {
      page_no,
      searchText: searchText.trim(),
      projectId,
      limit,
      sortBy,
      order,
      status,
      source,
      isExport,
      accessCodeIds: this.checkBoxIdsArr,
    };
    this.props.exportAccessCode(body);
  };

  exportAccessCode = () => {
    this.setState(
      {
        isExport: true,
        downloadExport: (this.checkBoxIdsArr.length >= 0) && (this.props.AccessCode?.totalResults <= 1000) ? false : true,
        loading: this.checkBoxIdsArr.length > 0 || this.props.AccessCode?.totalResults <= 1000 ? true : false,
      },
      () => this.exportAccessCodes(),
    );
  };

  isValid = () => {
    try {
      const { accessCode, expringPeriod, customExpringPeriod } = this.state;
      let formIsValid = true;
      let error = {};
      const regex = /^[A-Za-z0-9 ]+$/;

      if (regex.test(accessCode) === false) {
        formIsValid = false;
        error['accessCode'] = `Please enter a valid accessCode`;
      }

      if (
        accessCode.trim().length === 0 ||
        accessCode === '' ||
        accessCode === undefined
      ) {
        formIsValid = false;
        error['accessCode'] = 'Please enter access code';
      }
      if (
        expringPeriod.length === 0 ||
        expringPeriod === '' ||
        expringPeriod === undefined
      ) {
        formIsValid = false;
        error['expringPeriod'] = 'Please enter expring period';
      }

      if (customExpringPeriod >= ACCESS_CODE_EXPERING_PERIOD) {
        formIsValid = false;
        error['customExpringPeriod'] = 'Enter reasonable value ';
      }

      if (expringPeriod === 'custom') {
        if (!Number.isInteger(Number(customExpringPeriod))) {
          formIsValid = false;
          error['customExpringPeriod'] = 'Enter expiring periods in hours and decimals are not allowed';
        } else if (
          customExpringPeriod === '' ||
          customExpringPeriod === null ||
          customExpringPeriod <= 0
        ) {
          formIsValid = false;
          error['customExpringPeriod'] =
            'Enter expiring periods in hours and decimals are not allowed';
        }
      }
      this.setState({
        errors: error,
      });
      return formIsValid;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  addAccessCode = (e) => {
    e.preventDefault();
    try {
      if (this.isValid()) {
        const { projectId, expringPeriod, accessCode, customExpringPeriod } =
          this.state;
        const body = {
          projectId,
          expringPeriod:
            expringPeriod === 'custom'
              ? customExpringPeriod.trim()
              : expringPeriod.trim(),
          customExpringPeriod:
            expringPeriod === 'custom'
              ? expringPeriod.trim()
              : customExpringPeriod.trim(),
          accessCode: accessCode.replace(/\s{2,}/g, ' ').trim(),
          lngCode: this.props.users.newLangState,
        };
        this.props.addAccessCode(body);
        this.setState({
          searchText: '',
        });
        this.handleLoading();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  editAccessCode = () => {
    try {
      if (this.isValid()) {
        this.setState({
          searchText: '',
        });
        const {
          projectId,
          expringPeriod,
          accessCode,
          customExpringPeriod,
          accessCodeId,
        } = this.state;
        const body = {
          projectId,
          expringPeriod:
            expringPeriod === 'custom' ? customExpringPeriod : expringPeriod,
          customExpringPeriod:
            expringPeriod === 'custom' ? expringPeriod : customExpringPeriod,
          accessCode: accessCode.replace(/\s{2,}/g, ' ').trim(),
          accessCodeId,
          lngCode: this.props.users.newLangState
        };
        this.props.editAccessCode(body);
        this.handleLoading();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.checkBoxIdsArr = [];
    this.setState(
      {
        page_no: 1,
        startCount: 1,
        isExport: false,
      },
      () => this.listAccessCode(),
    );
  };

  sorting = (value) => {
    if (this.state.sortBy === value) {
      if (this.state.order === 'asc') {
        this.setState(
          {
            order: 'desc',
          },
          () => this.listAccessCode(),
        );
      } else {
        this.setState(
          {
            order: 'asc',
          },
          () => this.listAccessCode(),
        );
      }
    } else {
      this.setState(
        {
          sortBy: value,
          order: 'asc',
        },
        () => {
          this.listAccessCode();
        },
      );
    }
  };

  filterByStatus = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      this.state.status.push(value);
      this.setState({
        status: this.state.status,
        statusForActiveClass: 'status',
      });
    } else {
      let check = this.state.status?.filter((event) => event !== value);
      this.setState({
        status: check,
      });
    }
    this.setState(
      {
        source: [],
        filterSource: [],
        page_no: 1,
        startCount: 1,
        searchText: '',
        open2: false,
      },
      () => this.listAccessCode(),
    );
  };

  filterBySource = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      this.state.source.push(value);
      this.setState({
        source: this.state.source,
        statusForActiveClass: 'source',
      });
    } else {
      let check = this.state.source?.filter((event) => event !== value);
      this.setState({
        source: check,
      });
    }
    this.setState(
      {
        status: [],
        filterStatus: [],
        page_no: 1,
        startCount: 1,
        searchText: '',
        open1: false,
      },
      () => this.listAccessCode(),
    );
  };

  getFilterCount = (countOf) => {
    const { filterByStatus } = this.state;
    if (filterByStatus && Object.keys(filterByStatus).length) {
      return filterByStatus[countOf] > 0 ? filterByStatus[countOf] : 0;
    }
  };

  getFilterCountSource = (countOf) => {
    const { filterBySource } = this.state;
    if (filterBySource && Object.keys(filterBySource).length) {
      return filterBySource[countOf] > 0 ? filterBySource[countOf] : 0;
    }
  };

  allData = (status) => {
    this.setState(
      {
        statusForActiveClass: status,
        status: [],
        startCount: 1,
        page_no: 1,
        filterStatus: [],
        source: [],
        searchText: '',
        filterSource: [],
      },
      () => this.listAccessCode(),
    );
  };

  handleToggle2 = (status) => {
    this.setState({
      open2: !this.state.open2,
    });
  };

  handleToggle = () => {
    this.setState({
      open1: !this.state.open1,
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({});
    }
  };

  handleClose = (event) => {
    this.setState({
      open1: false,
      open2: false,
      openAction: false,
    });
  };

  openAddAccessCodeModel = () => {
    this.setState({
      openAddModel: true,
    });

    this.setState({
      accessCode: generateRandomString(16),
    });
  };

  handleCloseAddModel = () => {
    this.setState({
      openAddModel: false,
      accessCode: '',
      customExpringPeriod: '',
      expringPeriod: '',
      isEdit: false,
      errors: {},
    });
  };

  openEditAccessCodeModel = (accessCode) => {
    this.setState({
      openAddModel: true,
      isEdit: true,
      accessCodeId: accessCode._id,
      expringPeriod:
        accessCode.expringPeriod &&
        (accessCode.customExpringPeriod === 'custom'
          ? accessCode.customExpringPeriod
          : accessCode.expringPeriod === ACCESS_CODE_EXPERING_PERIOD
            ? 'lifeTime'
            : accessCode.expringPeriod),
      customExpringPeriod:
        accessCode.customExpringPeriod &&
        (accessCode.customExpringPeriod === 'custom'
          ? accessCode.expringPeriod
          : null),
      accessCode: accessCode.accessCode,
    });
  };

  openDeleteAccesCodeModal = (id) => {
    this.setState({
      accessCodeId: id,
      openDeleteAccesCodeModal: true,
      singleDelete: true,
    });
  };

  handleDeleteConfirm = () => {
    try {
      const { accessCodeId, projectId, singleDelete } = this.state;
      let body = {
        accessCodeId,
        projectId: projectId,
        lngCode: this.props.users.newLangState
      };
      this.props.deleteAccessCode(body);
      this.setState({
        page_no: 1,
        startCount: 1,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  changeAccessCode = () => {
    try {
      const { projectId, status, id } = this.state;
      let body = {
        projectId,
        accessCodeId: id,
        status: status,
      };
      this.props.changeAccessCode(body);
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  openDeleteMultipleAccessCode = () => {
    try {
      this.setState(
        {
          isDeleteAccessCode: true,
        },
        () =>
          this.setState({
            openDeleteAccesCodeModal: true,
          }),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  selectCheckBox = (e, accessCode) => {
    try {
      if (accessCode) {
        const index = this.checkBoxIdsArr.indexOf(accessCode._id);
        if (index === -1) {
          this.checkBoxIdsArr.push(accessCode._id);
        } else {
          this.checkBoxIdsArr.splice(index, 1);
        }
        this.setState({
          changeState: !this.state.changeState,
        });
      }
      if (this.checkBoxIdsArr.length !== this.state.itemCount) {
        this.setState({
          checkedData: false,
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleSelectAllAccessCode = (e) => {
    this.setState({
      checkedData: e.target.checked,
    });
    if (e.target.checked === true) {
      this.props.AccessCode.accessCode.forEach((accessCode) => {
        if (this.checkBoxIdsArr.indexOf(accessCode._id) === -1) {
          this.checkBoxIdsArr.push(accessCode._id);
        }
      });
    }
    if (e.target.checked === false) {
      this.checkBoxIdsArr = [];
    }
  };

  handleCloseConfirmation = () => {
    this.checkBoxIdsArr = [];
    this.setState({
      openDeleteAccesCodeModal: false,
      singleDelete: false,
    });
  };

  handleToggleAction = () => {
    this.setState({
      openAction: !this.state.openAction,
    });
  };

  isDisabled = () => {
    try {
      return this.checkBoxIdsArr.length > 0 ? false : true;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
        searchText: '',
      },
      () => this.listAccessCode(),
    );
  };

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state;
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults: this.props.AccessCode?.totalResults,
      }),
      () => {
        this.listAccessCode();
      },
    );
  };

  copyAccessCode = (copyText, id, length) => {
    navigator.clipboard.writeText(copyText);
    for (let key in this.state) {
      if (key.includes('copyText')) {
        if (key !== `copyText${id}`) {
          this.setState({
            [key]: false,
          });
        }
      }
    }
    this.setState({
      [`copyText${id}`]: true,
    });
  };

  handleClickOpenImportModal = () => {
    this.setState({
      openImportModal: true,
      openValidation: false,
    });
  };

  handleImportClose = () => {
    this.setState({
      openImportModal: false,
      openSuccess: false,
      openValidation: false,
      accessCodeCsv: '',
    });
  };

  handleCloseOpenImportModal = () => {
    this.setState({
      openImportModal: false,
      accessCodeCsv: '',
    });
  };

  onAccessCodeFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const csvFile = event.target.files[0];

      if (!csvFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.');
        return false;
      }
      this.setState({ accessCodeCsv: event.target.files[0] });
    }
  };

  isValidCsv = () => {
    const { accessCodeCsv } = this.state;
    let error = {};
    let formIsValid = true;

    if (accessCodeCsv === '') {
      formIsValid = false;
      error['accessCodeFile'] = '*Please select csvfile.';
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  handleImportSubmit = (e) => {
    e.preventDefault();
    if (this.isValidCsv()) {
      const { accessCodeCsv } = this.state;
      if (accessCodeCsv !== '') {
        const projectId = getProjectIdFromUrl();
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('accessCodeCsv', accessCodeCsv);
        this.props.importAccessCode(formData);
        this.handleLoading();
      }
    }
  };

  downloadCsvFile(e) {
    e.preventDefault();
    this.sampleCsv();
  }

  sampleCsv = () => {
    let data = [
      {
        AccessCode: 'value',
        ExpiringPeriod: '2',
      },
      {
        AccessCode: 'value',
        ExpiringPeriod: 'lifeTime',
      },
    ];

    const options = {
      useKeysAsHeaders: true,
      title: 'AccessCode Csv',
      filename: 'accesscode_sample',
    };

    const csvExporter = new generateCsv(options);
    if (data.length > 0) {
      csvExporter.generateCsv(data);
    }
  };

  handleClickOpenDisabledModal = (id, status) => {
    this.setState({
      id: id,
      status: status,
      openDisabledModal: true,
    });
  };

  handleCloseDisabledModal = () => {
    this.setState({
      openDisabledModal: false,
    });
  };

  goToAttendees(email) {
    this.setState({
      email: email,
    });
    this.props.navigate({
      pathname: `/project-dashboard/attendees/${this.state.projectId}`,
      state: { attendeeEmail: email },
    });
  }

  getProgressCountOfAccessCode = (params) => {
    const users = UserData();
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
    const eventSource = new EventSource(`${BACKEND_API_URL}/accessCode/import-progress/${params.importAccessCodeId}/${params.projectId}?token=bearer ${users.token}`);
    eventSource.onmessage = event => {
      //we will get error if any error occured in the import|copy progress
      if (event.data === 'error') {
        //closing eventSource connection
        eventSource.close();
        createNotification('error', 'Error occured in the progress!');
        //hiding progress bar popup
        this.setState({
          showProgress: false,
          import: false,
          progressPercentage: 0
        });
      } else {
        const progress = parseInt(event.data);
        this.setState({
          progressPercentage: progress
        });

        //if the progress completed 
        if (progress === 100) {
          //close progress popup & open successful messsage after some delay
          this.listAccessCode();
          setTimeout(() => {
            this.setState({
              showProgress: false,
              import: false,
              openSuccess: true,
              progressPercentage: 0
            });
          }, 500);
          eventSource.close();
        }
      }
    };
  };

  hideProgress = () => {
    this.setState({
      showProgress: false
    });
  };

  statusAccessCode = (val) => {
    let temp;
    switch (val) {
      case 'active': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.active;   
      return temp;

      case 'assigned': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.assigned;   
      return temp;   

      case 'disabled': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.disabled;
      return temp;

      case 'expired': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.expired;
      return temp;

      case 'unused': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.unused;
      return temp;

      default: temp = val;
      break;
    }
  }

   render() {
    const {
      progressPercentage,
      showProgress,
      allowed,
      openSuccess,
      openValidation,
      startCount,
      endCount,
      itemCount,
      openAction,
      customExpringPeriod,
      expringPeriod,
      accessCode,
      accessCodeList,
      order,
      sortBy,
      searchText,
      filterByStatus,
      filterBySource,
      totalResults,
      open2,
      open,
      open1,
      statusForActiveClass,
      copyText,
      errors,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid >
            <Grid container spacing={1}>
              <Grid >
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize',
                    fontWeight: '400',

                    '&:hover, &.active': {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={statusForActiveClass === 'all' ? 'active' : null}
                  size="small"
                  disableElevation
                  aria-haspopup="true"
                  onClick={() => this.allData('all')}
                >
                  {this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.allAccessCode} ({filterByStatus && Object.keys(filterByStatus).length ? this.getFilterCount('allStatus') : '0'})
                </Box>
              </Grid>
              <Grid >
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize',
                    fontWeight: '400',

                    '&:hover, &.active': {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={
                    statusForActiveClass === 'status' ? 'active' : null
                  }
                  size="small"
                  disableElevation
                  ref={this.anchorRef2}
                  aria-controls={open2 ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={() => this.handleToggle2()}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {this.props.users.languageStateForRedux?.common?.status}
                </Box>
                <Popper
                  open={open2}
                  style={{ zIndex: '4' }}
                  placement="bottom-start"
                  transition
                  anchorEl={this.anchorRef2.current}
                  role={undefined}
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250}>
                      <Box
                        className="option-list"
                        sx={{
                          backgroundColor: theme.palette.background.light,
                          borderRadius: 4,
                          marginTop: 8,
                          overflowY: 'scroll',
                          maxHeight: 195,
                        }}
                      >
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={this.handleListKeyDown}
                          >
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="active"
                                    value={'active'}
                                    className="checkbox"
                                    onChange={(e) => this.filterByStatus(e)}
                                    checked={
                                      this.state.status.indexOf('active') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={(this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.active) + ` (${filterByStatus &&
                                    Object.keys(filterByStatus).length
                                    ? this.getFilterCount('active')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="disabled"
                                    value={'disabled'}
                                    className="checkbox"
                                    onChange={(e) => this.filterByStatus(e)}
                                    checked={
                                      this.state.status.indexOf('disabled') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={(this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.disabled) + ` (${filterByStatus &&
                                    Object.keys(filterByStatus).length
                                    ? this.getFilterCount('disabled')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="expired"
                                    value={'expired'}
                                    className="checkbox"
                                    onChange={(e) => this.filterByStatus(e)}
                                    checked={
                                      this.state.status.indexOf('expired') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={(this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.expired) + ` (${filterByStatus &&
                                    Object.keys(filterByStatus).length
                                    ? this.getFilterCount('expired')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="unused"
                                    value={'unused'}
                                    className="checkbox"
                                    onChange={(e) => this.filterByStatus(e)}
                                    checked={
                                      this.state.status.indexOf('unused') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={(this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.unused) + ` (${filterByStatus &&
                                    Object.keys(filterByStatus).length
                                    ? this.getFilterCount('unused')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="assigned"
                                    value={'assigned'}
                                    className="checkbox"
                                    onChange={(e) => this.filterByStatus(e)}
                                    checked={
                                      this.state.status.indexOf('assigned') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={(this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.assigned) + ` (${filterByStatus &&
                                    Object.keys(filterByStatus).length
                                    ? this.getFilterCount('assigned')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Grid>
              <Grid >
                <Box
                  component={Button}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.common.black,
                    textTransform: 'capitalize',
                    fontWeight: '400',

                    '&:hover, &.active': {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                  className={
                    statusForActiveClass === 'source' ? 'active' : null
                  }
                  size="small"
                  disableElevation
                  ref={this.anchorRef}
                  aria-controls={open1 ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={() => this.handleToggle()}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {this.props.users.languageStateForRedux?.common?.source}
                </Box>
                <Popper
                  open={open1}
                  style={{ zIndex: '4' }}
                  placement="bottom-start"
                  transition
                  anchorEl={this.anchorRef.current}
                  role={undefined}
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Fade {...TransitionProps} timeout={250}>
                      <Box
                        className="option-list"
                        sx={{
                          backgroundColor: theme.palette.background.light,
                          borderRadius: 4,
                          marginTop: 8,
                          overflowY: 'scroll',
                          maxHeight: 195,
                        }}
                      >
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={this.handleListKeyDown}
                          >
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="imported"
                                    value={'imported'}
                                    className="checkbox"
                                    onChange={(e) => this.filterBySource(e)}
                                    checked={
                                      this.state.source.indexOf('imported') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={(this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.imported) +` (${filterBySource &&
                                    Object.keys(filterBySource).length
                                    ? this.getFilterCountSource('imported')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                            <MenuItem className="checkbox-item">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="default"
                                    name="Manually Added"
                                    value={'manuallyAdded'}
                                    className="checkbox"
                                    onChange={(e) => this.filterBySource(e)}
                                    checked={
                                      this.state.source.indexOf('manuallyAdded') > -1
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={ (this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.manual) + ` (${filterBySource &&
                                    Object.keys(filterBySource).length
                                    ? this.getFilterCountSource('manuallyAdded')
                                    : '0'
                                  })`}
                              />
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>
          <Grid >
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid className="">
                <form onSubmit={this.handleSearchSubmit}>
                  <TextField
                    name="searchText"
                    variant='standard'
                    placeholder={this.props.users.languageStateForRedux?.common?.search}
                    className="search"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="" src={SearchIcon} />
                        </InputAdornment>
                      ),
                    }}
                    value={searchText}
                    onChange={this.handleChange}
                  />
                </form>
              </Grid>
              <Grid >
                <Box sx={{ position: 'relative' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    ref={this.anchorRefAction}
                    aria-controls={openAction ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggleAction}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    {this.props.users.languageStateForRedux?.dropdowns?.common?.actions}
                  </Button>
                  <Popper
                    open={openAction}
                    style={{ zIndex: '4' }}
                    placement="bottom-start"
                    transition
                    anchorEl={this.anchorRefAction.current}
                    role={undefined}
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Fade {...TransitionProps} timeout={250}>
                        <Box
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                            borderRadius: 4,
                            marginTop: 8,
                          }}
                        >
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList
                              autoFocusItem={openAction}
                              id="menu-list-grow"
                              onKeyDown={this.handleListKeyDown}
                            >
                              {allowed['Create AccessCodes'] && (
                                <MenuItem
                                  disabled={false}
                                  onClick={this.handleClickOpenImportModal}
                                >
                                  {this.props.users.languageStateForRedux?.common?.import}
                                </MenuItem>
                              )}
                              {allowed['View AccessCodes'] && (
                                <>
                                  <MenuItem
                                    disabled={
                                      Array.isArray(this.checkBoxIdsArr) &&
                                        this.checkBoxIdsArr?.length > 0
                                        ? false
                                        : true
                                    }
                                    onClick={() => this.exportAccessCode()}
                                  >
                                    {this.props.users.languageStateForRedux?.common?.export}
                                  </MenuItem>
                                  <MenuItem
                                    disabled={
                                      Array.isArray(accessCodeList) &&
                                        accessCodeList?.length > 0 &&
                                        this.checkBoxIdsArr?.length <= 0
                                        ? false
                                        : true
                                    }
                                    onClick={() => this.exportAccessCode()}
                                  >
                                    {this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.exportAll}
                                  </MenuItem>
                                </>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Box>
                      </Fade>
                    )}
                  </Popper>
                </Box>
              </Grid>

              {allowed['Create AccessCodes'] &&
                <Grid >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.openAddAccessCodeModel}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.accessCodes?.add}
                  </Button>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <TableContainer className="list-table">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell className="checkbox sticky-cell">
                  <Checkbox
                    color="primary"
                    p={0}
                    onChange={(e) => {
                      this.handleSelectAllAccessCode(e);
                    }}
                    checked={this.state.checkedData}
                  />
                </TableCell>
                <TableCell
                  className={
                    'long-text sticky-cell ' +
                    (sortBy === 'accessCode'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  style={{ left: '50px' }}
                  onClick={() => this.sorting('accessCode')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.accessCodes?.code}
                </TableCell>
                <TableCell
                  className="sticky-cell text "
                  style={{ left: '300px' }}
                >
                  <Button
                    className="copy"
                    variant="text"
                    style={{
                      padding: '0',
                      textTransform: 'capitalize',
                      color: '#5141E7',
                    }}
                  >
                    {' '}
                  </Button>
                </TableCell>
                <TableCell
                  className={
                    'status ' +
                    (sortBy === 'status'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('status')}
                >
                  {this.props.users.languageStateForRedux?.common?.status}
                </TableCell>
                <TableCell
                  className={
                    'email ' +
                    (sortBy === 'attendeesEmail'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('attendeesEmail')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.accessCodes?.attendeeEmail}
                </TableCell>

                <TableCell
                  className={
                    'date-time ' +
                    (sortBy === 'assignedOn'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('assignedOn')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.accessCodes?.assignedOn}
                </TableCell>

                <TableCell
                  className={
                    'short-text ' +
                    (sortBy === 'expringPeriod'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('expringPeriod')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.accessCodes?.expiringPd}
                </TableCell>
                <TableCell
                  className={
                    'text ' +
                    (sortBy === 'source'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('source')}
                >
                  {this.props.users.languageStateForRedux?.common?.source}
                </TableCell>
                <TableCell
                  className={
                    'date-time ' +
                    (sortBy === 'createdAt'
                      ? order === 'asc'
                        ? 'sort-asc'
                        : 'sort-des'
                      : '')
                  }
                  onClick={() => this.sorting('createdAt')}
                >
                  {this.props.users.languageStateForRedux?.column_names?.accessCodes?.createdOn}
                </TableCell>
                <TableCell className="options">{this.props.users.languageStateForRedux?.common?.action}</TableCell>
              </TableRow>
            </TableHead>

            {Array.isArray(accessCodeList) && accessCodeList?.length > 0 ? (
              <>
                <TableBody>
                  {Array.isArray(accessCodeList) &&
                    accessCodeList.length &&
                    accessCodeList.map((accessCode, i) => {
                      return (
                        <TableRow>
                          <TableCell className="checkbox sticky-cell">
                            <Checkbox
                              name={accessCode._id}
                              color="primary"
                              p={0}
                              onChange={(e) =>
                                this.selectCheckBox(e, accessCode)
                              }
                              checked={
                                this.checkBoxIdsArr.indexOf(accessCode._id) !==
                                  -1
                                  ? true
                                  : false
                              }
                            />
                          </TableCell>
                          <TableCell
                            className="long-text sticky-cell"
                            style={{ left: '50px' }}
                          >
                            {accessCode.accessCode ? accessCode.accessCode : ''}{' '}
                          </TableCell>
                          <TableCell
                            className="sticky-cell text"
                            style={{ left: '300px' }}
                            onClick={() =>
                              this.copyAccessCode(
                                accessCode.accessCode,
                                accessCode._id,
                                accessCodeList.length,
                              )
                            }
                          >
                            <Button
                              className="copy"
                              variant="text"
                              style={{
                                padding: '0',
                                textTransform: 'capitalize',
                                color: '#5141E7',
                                fontSize:
                                  this.state[`copyText${accessCode._id}`] &&
                                  '14px',
                              }}
                            >
                              {' '}
                              {this.state[`copyText${accessCode._id}`]
                                ? this.props.users.languageStateForRedux?.buttons?.accessCodes?.copied
                                : this.props.users.languageStateForRedux?.buttons?.accessCodes?.copy}{' '}
                            </Button>
                          </TableCell>

                          <TableCell className="status">
                            <span
                              className={
                                accessCode.status === 'expired'
                                  ? 'table-status light'
                                  : accessCode.status === 'disabled'
                                    ? 'table-status danger'
                                    : accessCode.status === 'assigned'
                                      ? 'table-status warning'
                                      : accessCode.status === 'active'
                                        ? 'table-status success'
                                        : 'table-status primary'
                              }
                            >
                             {this.statusAccessCode(accessCode.status)}
                            </span>
                          </TableCell>
                          <TableCell className="email">
                            {accessCode.attendeesEmail
                              ? accessCode.attendeesEmail
                              : ''}
                          </TableCell>
                          <TableCell className="date-time">
                            {accessCode.assignedOn
                              ? moment
                                .tz(
                                  accessCode.assignedOn,
                                  this.props.project?.project?.timezone,
                                )
                                .format('ddd, MMM, Do YYYY, h:mm A')
                              : ''}{' '}
                          </TableCell>

                          <TableCell className="short-text">
                            {accessCode.expringPeriod &&
                              accessCode.expringPeriod ===
                              ACCESS_CODE_EXPERING_PERIOD
                              ? this.props.users.languageStateForRedux?.column_names?.accessCodes?.lifetime
                              : accessCode.expringPeriod}{' '}
                            {accessCode.expringPeriod &&
                              accessCode.expringPeriod !==
                              ACCESS_CODE_EXPERING_PERIOD
                              ? this.props.users.languageStateForRedux?.column_names?.accessCodes?.hours
                              : ''}
                          </TableCell>
                          <TableCell
                            className="text"
                            style={{
                              color:
                               ( accessCode.source === 'manual' ||  accessCode.source === 'manuallyAdded') && '#00ACE2',
                            }}
                          >
                           {
                              accessCode.source ?
                                (accessCode.source === 'manual' || accessCode.source === 'manuallyAdded' ? "Manually Added" : "")
                                : ''
                            }
                          </TableCell>
                          <TableCell className="date-time">
                            {' '}
                            {accessCode.createdAt
                              ? moment
                                .tz(
                                  accessCode.createdAt,
                                  this.props.project?.project?.timezone,
                                )
                                .format('ddd, MMM, Do YYYY, h:mm A')
                              : ''}{' '}
                          </TableCell>
                          <TableCell className="options">
                            {' '}


                            <div className="options-button">
                              <img alt="" src={MoreIcon} />{' '}
                            </div>
                            {((accessCode.status === 'unused' && (allowed['Delete AccessCodes'] || allowed['Edit AccessCodes'])) ||
                              accessCode.status === 'assigned' ||
                              accessCode.status === 'active' ||
                              (accessCode.status === 'disabled' &&
                                accessCode.attendeesEmail) ||
                              accessCode.status === 'expired') && (
                                (allowed['Edit AccessCodes'] || allowed['Delete AccessCodes'] || allowed['View Attendees']) &&

                                <>
                                  {
                                    <div className="options-list">
                                      {accessCode.status === 'unused' && (

                                        allowed['Edit AccessCodes'] && (
                                          <div
                                            onClick={() =>
                                              this.openEditAccessCodeModel(accessCode)
                                            }
                                          >
                                            {this.props.users.languageStateForRedux?.common?.edit}
                                          </div>
                                        )
                                      )}
                                      {(accessCode.status === 'unused' ||
                                        accessCode.status === 'assigned') &&
                                        accessCode?.isdeleteAble === true && (
                                          allowed['Delete AccessCodes'] && (
                                            <div
                                              onClick={() =>
                                                this.openDeleteAccesCodeModal(
                                                  accessCode._id,
                                                )
                                              }
                                              style={{
                                                color: theme.palette.secondary.main,
                                              }}
                                            >
                                              {this.props.users.languageStateForRedux?.common?.delete}
                                            </div>
                                          )
                                        )}
                                      {accessCode.status === 'unused' && (
                                        allowed['Delete AccessCodes'] && (
                                          <div
                                            onClick={() =>
                                              this.openDeleteAccesCodeModal(
                                                accessCode._id,
                                              )
                                            }
                                          >
                                            {this.props.users.languageStateForRedux?.common?.delete}
                                          </div>
                                        )
                                      )}

                                      {accessCode.status !== 'expired' &&
                                        accessCode?.isdisableAble === true && (
                                          allowed['Edit AccessCodes'] && (
                                            <div
                                              onClick={() =>
                                                this.handleClickOpenDisabledModal(
                                                  accessCode._id,
                                                  'disabled',
                                                )
                                              }
                                            >
                                              {this.props.users.languageStateForRedux?.actions?.gamification?.disable}
                                            </div>
                                          )
                                        )}

                                      {accessCode.attendeesEmail && (
                                        allowed['View Attendees'] && (
                                          <div
                                            onClick={() =>
                                              this.goToAttendees(
                                                accessCode?.attendeesEmail,
                                              )
                                            }
                                          >
                                            {this.props.users.languageStateForRedux?.actions?.accessCodes?.viewAttendee}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  }
                                </>
                              )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </>
            ) : (!this.state.loading && accessCodeList?.length === 0 && this.props.AccessCode?.listAccessCodeSuccess &&
              (<TableBody>
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    {this.props.users.languageStateForRedux?.common?.noRecords}
                  </TableCell>
                </TableRow>
              </TableBody>)
            )}
          </Table>
        </TableContainer>
        {Array.isArray(accessCodeList) && accessCodeList?.length > 0 && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="tablePagination"
          >
            <Grid >
              <Box component="span" className="small-subtitle">
              {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to}  {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {' '}
                {this.props.AccessCode?.totalResults}
              </Box>
            </Grid>
            <Grid >
              <Grid container alignItems="center">
                <Grid >
                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                  <select value={itemCount} onChange={this.selectItemCount}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                </Grid>
                <Grid >
                  <Pagination
                    activePage={this.state.page_no}
                    itemsCountPerPage={this.state.itemCount}
                    totalItemsCount={this.props.AccessCode?.totalResults}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    prevPageText={
                      <NavigateBeforeIcon style={{ fontSize: 18 }} />
                    }
                    nextPageText={<NavigateNextIcon style={{ fontSize: 18 }} />}
                    itemClassFirst="first d-none"
                    itemClassLast="last d-none"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Modal
          open={this.state.openAddModel}
          onClose={this.handleCloseAddModel}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.state.isEdit === true
                  ? this.props.users.languageStateForRedux?.actions?.accessCodes?.edit
                  : this.props.users.languageStateForRedux?.actions?.accessCodes?.add}
              </Box>
              <Box className='small-subtitle'>
              {this.state.isEdit === true
                  ? this.props.users.languageStateForRedux?.actions?.accessCodes?.msg
                  : this.props.users.languageStateForRedux?.actions?.accessCodes?.addCodeMsg}
              </Box>
            </Grid>

            <Grid size={{xs: 12,}} pb={4}>
              <Box component="div" className="inputField" pb={4}>

                <Box component="label" display="flex" alignItems="center">
                  <Box component="span" pl={1}>
                  {this.props.users.languageStateForRedux?.actions?.accessCodes?.accessCode} <span className="required">*</span>
                  </Box>
                </Box>
                <input
                  type="text"
                  class="form-control small"
                  placeholder="Enter Access Code"
                  value={accessCode}
                  name="accessCode"
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.state.errors.accessCode}{' '}
                </span>
              </Box>
              <Box component="div" className="inputField" pb={1}>
                <label>
                {this.props.users.languageStateForRedux?.column_names?.accessCodes?.expiringPd} <span className="required">*</span>
                </label>
                <select
                  type="text"
                  class="form-control small"
                  value={expringPeriod}
                  name="expringPeriod"
                  onChange={this.handleChange}
                >
                  <option value="">Select</option>
                  <option value="2">2 Hrs</option>
                  <option value="12">12 Hrs</option>
                  <option value="24">24 Hrs</option>
                  <option value="48">48 Hrs</option>
                  <option value="72">72 Hrs</option>
                  <option value="lifeTime">LifeTime</option>
                  <option value="custom">Custom</option>
                </select>
                <span className="error_mesage required">
                  {' '}
                  {this.state.errors.expringPeriod}{' '}
                </span>
              </Box>
              {expringPeriod === 'custom' && (
                <Box component="div" className="inputField" pb={4}>
                  <Box component="label" display="flex" alignItems="center">
                    <Box component="span" pl={1}>
                      Expiring Periods (Hours)
                      {<span className="required">*</span>}
                    </Box>
                  </Box>
                  <input
                    type="number"
                    class="form-control small"
                    placeholder="Enter Expiring Periods"
                    value={customExpringPeriod}
                    name="customExpringPeriod"
                    onChange={this.handleChange}
                  />
                  <span className="error_mesage required">
                    {' '}
                    {this.state.errors.customExpringPeriod}{' '}
                  </span>
                </Box>
              )}
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    {' '}
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={
                        this.state.isEdit
                          ? this.editAccessCode
                          : this.addAccessCode
                      }
                    >
                      {this.props.users.languageStateForRedux?.buttons?.accessCodes?.save}{' '}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={this.handleCloseAddModel}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>{' '}
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={this.state.openDeleteAccesCodeModal}
          onClose={this.handleCloseConfirmation}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.common?.deleteMsg}
              </p>
            </Box>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      onClick={() => this.handleDeleteConfirm()}
                    >
                      {this.props.users.languageStateForRedux?.common?.delete}
                    </Button>
                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() => this.handleCloseConfirmation()}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={this.state.openImportModal}
          onClose={this.handleCloseOpenImportModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
              {this.props.users.languageStateForRedux?.actions?.accessCodes?.import}
              </Box>
              <p className="small-subtitle">
              {this.props.users.languageStateForRedux?.messages?.importAccessCode}
              </p>
            </Grid>
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="div" className="inputField" pb={1}>
                <label>{this.props.users.languageStateForRedux?.actions?.accessCodes?.select} <span className="required">*</span></label>
                <input
                  type="file"
                  class="form-control small"
                  placeholder=""
                  id="accessCodeFile"
                  name="accessCodeFile"
                  onChange={this.onAccessCodeFileChange}
                  accept=".csv"
                />
              </Box>
              <span className="error_mesage required">
                {' '}
                {errors.accessCodeFile}{' '}
              </span>
              <Box component="label" display="flex" alignItems="center">
                <img alt="" src={ExportIcon} />
                <Box
                  component="a"
                  href=""
                  className="secondary-text small-subtitle"
                  pl={1}
                  onClick={(e) => {
                    this.downloadCsvFile(e);
                  }}
                >
                  {this.props.users.languageStateForRedux?.actions?.accessCodes?.download}
                </Box>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleImportSubmit}
                  >
                    {this.props.users.languageStateForRedux?.common?.import}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseOpenImportModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={openValidation}
          onClose={this.handleImportClose}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Box
              component={Grid}
              mt={-4}
              size={{xs: 12,}}
              sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
              mb={4}
            >
              <Box component="p" mb={1} className="subtitle">
                Validation Result!
              </Box>
              <p className="small-subtitle">
                Please make changes in sheet and try again
              </p>
            </Box>
            <Grid size={{xs: 12,}} pb={4}>
              <Box
                component="div"
                sx={{
                  border: '1px solid rgba(0, 0, 86, 0.23)',
                  borderRadius: '4px',
                }}
              >
                <Box
                  component={Grid}
                  p={1.25}
                  sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                  container
                >
                  <Grid pl={2}><img alt="" src={InvalidIcon} /></Grid>
                  <Grid >
                    <Box
                      pl={1}
                      component={'strong'}
                      className=""
                      color={theme.palette.common.black}
                    >
                      Invalid AccessCode & Mandatory Missing ({this.props.AccessCode.rejectedAccessCodes})
                    </Box>
                  </Grid>
                </Box>
                <Box
                  className="custom-fields-table"
                  p={1.25}
                  sx={{ maxHeight: '200px' }}
                  component={TableContainer}
                >
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>Duplicate Access Code in file</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.AccessCode.duplicateAccessCodeInCsv}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>Invalid Access Code</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.AccessCode.invalidAccessCodeRejection}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>Already Exists Access Code</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.AccessCode.alreadyExistInDb}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleClickOpenImportModal}
                  >
                    Retry
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={this.handleImportClose}
                  >
                    close
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>


        <SuccessAlertPopUp
          openModal={openSuccess}
          closeModalFunc={this.handleImportClose}
          title="Import Successful!"
          description={`Great Job ! You have imported <strong> ${this.props.AccessCode?.uniqueAccesscodeList} </strong> access codes successfully.`}
          buttonColor="primary"
          buttonFunc={this.handleImportClose}
          buttonText="Done"
        />

        <Modal
          open={this.state.openDisabledModal}
          onClose={this.handleCloseDisabledModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
              {this.props.users.languageStateForRedux?.messages?.change}{' '}
                {this.state.status === 'disabled' ? this.props.users.languageStateForRedux?.common?.disable: 'Unused'}{' '}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.messages?.sure}
                {' '}
                {this.state.status === 'disabled' ? this.props.users.languageStateForRedux?.common?.disable : 'unused'}{' '}
                {this.props.users.languageStateForRedux?.formfields?.accessCodes?.descMsg}{' '}
              </p>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => this.changeAccessCode()}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.common?.yes}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseDisabledModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={showProgress}
          onClose={this.hideProgress}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
          closeOnOverlayClick={false}
        >
          <ProgresBar progressPercentage={progressPercentage} import={this.state.import} />
        </Modal>

        <Modal
          open={this.state.downloadExport}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal modal-default-close-none',

          }}
          center
        >
          <Grid
            component="form"
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid size={{xs: 12,}} pb={2.6}>
              <Box textAlign="center" mt={1.6} mb={3}>
                <img alt="" src={Exporting} width="92" height="92" />
              </Box>
              <Box
                component="p"
                textAlign="center"
                mb={3}
                className="subsecondary bold"
              >
                Please wait while we securely <br />
                export your data!
              </Box>
              <Box component="p" textAlign="center" className="paragraph">
                Your information is being processed and will be ready for<br />
                download shortly. Thank you for your patience!
              </Box>
            </Grid>
          </Grid>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.Projects,
  events: state.Events,
  AccessCode: state.AccessCode,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAccessCode: Actions.listAccessCodeRequest,
      exportAccessCode: Actions.exportAccessCodeRequest,
      addAccessCode: Actions.addAccessCodeRequest,
      editAccessCode: Actions.editAccessCodeRequest,
      deleteAccessCode: Actions.deleteAccessCodeRequest,
      changeAccessCode: Actions.changeAccessCodeRequest,
      importAccessCode: Actions.importAccessCodeRequest,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AccessCode);

import React, { useEffect } from 'react';
import { theme } from '../../theme/theme';
import {
  Box,
  Popper,
  Fade,
  ClickAwayListener,
  MenuList,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const CustomSearchableDropdownForContactAndAccountDataTypeCF = ({
  options,
  value,
  handleChange,
  handleSearchListInSearchableDropdown,
  cfId,
  clearSearchList
}) => {
  const [selectedItem, setSelectedItem] = React.useState({});
  const [searchDropdown, setSearchDropdown] = React.useState(false);
  const [searchEnabled, setSearchEnabled] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const anchorRef = React.useRef(null);
  useEffect(() => {
    setSelectedItem(value[cfId] || {});
  }, [value]);

  const handleSearchDropdown = (event) => {
    setSearchDropdown(true);
  };

  const handleSearchDropdownClose = (event) => {
    setSearchDropdown(false);
    setSearchEnabled(false);
  };

  const handleClearSearchDropdown = () => {
    setSearchDropdown(false);
    setSearchEnabled(false);

    setSelectedItem({ firstName: '' });
    setSearchText('');
    
    clearSearchList()
  };

  const handleSearchEnabled = (e) => {
    setSearchDropdown(true);
    setSearchEnabled(true);
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handleSearchListInSearchableDropdown(searchText);
  }, [searchText]);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setSearchDropdown(false);
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        marginTop: 4,
      },
    },
  };

  return (
    <>
      <Box
        style={{
          position: 'relative',
        }}
      >
        <OutlinedInput
          // sx={{
          //   marginTop: '2px',
          //   padding: '3px 0px',
          // }}
          notched={false}
          size="medium"
          fullWidth
          placeholder={'Search'}
          name=""
          value={
            searchEnabled
              ? searchText
              : selectedItem.firstName
                ? `${selectedItem.firstName} ${selectedItem.middleName || ''} ${selectedItem.lastName || ''
                }`
                : (selectedItem.name || '')
          }
          className="multiselect-input"
          ref={anchorRef}
          aria-controls={searchDropdown ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleSearchDropdown}
          onChange={handleSearchEnabled}
          inputProps={{
            maxLength: 63,
          }}
          // disabled={isDisabled()}

          endAdornment={
            <InputAdornment position="end" className="controls">
              <IconButton
                aria-label="clear search"
                onClick={handleClearSearchDropdown}
                edge="end"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>

              <ArrowDropDownIcon />
            </InputAdornment>
          }
        />
        {/* {!isDisabled() && */}
        <Popper
          open={searchDropdown}
          style={{ zIndex: 1099, width: '100%' }}
          placement="bottom-start"
          transition
          anchorEl={anchorRef.current}
          role={undefined}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Box
                sx={{
                  maxHeight: '40vh',
                  overflow: 'scroll',
                  backgroundColor: theme.palette.common.white,
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                }}
              >
                <ClickAwayListener onClickAway={handleSearchDropdownClose}>
                  <Box id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {Array.isArray(options) && options.length > 0 ? (
                      <MenuList MenuProps={MenuProps}>
                        {options?.map((option) => {
                          return (
                            <MenuItem
                              value={option._id}
                              className="search-item"
                              key={option._id}
                              onClick={() => {
                                setSearchEnabled(false);
                                handleSearchDropdownClose();
                                handleChange(option);
                              }}
                            >
                              <Box className="long-text">
                                {option.name || (`${option.firstName} ${option.middleName || ''
                                  } ${option.lastName || ''}`)}
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    )
                      :

                      (
                        <MenuItem className="" style={{ justifyContent: 'center' }}  >
                          <Box className='long-text'  >
                            No records
                          </Box>
                        </MenuItem>
                      )
                    }
                  </Box>
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
        {/*  } */}
      </Box>
    </>
  );
};

export default CustomSearchableDropdownForContactAndAccountDataTypeCF;

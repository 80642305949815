//@ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import GoogleGmailIcon from '../../../src/assets/images/icons/Gmail_icon.svg';
import GoogleWorkspace from '../../../src/assets/images/icons/Google_G_Logo.svg';
import Button from '@mui/material/Button'; 
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid';
import { disconnectGmailIntegration, getXpAccountIntegrationDetails, xpAccountIntegrationGmailConnect } from 'ReduxStore/API';
import { createNotification } from 'helpers';
import { useDispatch } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';

const GmailIntegration = () => {
    const dispatch = useDispatch()
    const [isGmailConnected, setIsGmailConnected] = useState<boolean>(false);
    const [emailId, setEmailId] = useState('');
    const [openDisconnectConfirmationModal, setOpenDisconnectConfirmationModal] = useState(false);
    const googlePopupRef = useRef<Window | null>(null);
    const [integrationId, setIntegrationId] = useState('');
    const [loader, setLoader] = useState(false); 

    useEffect(() => {
        getGmailIntegrationDetails();
    }, []);

    const getGmailIntegrationDetails = async () => {
        try {
          const integrationRes:any = await getXpAccountIntegrationDetails('gmail');
    
          //  isCalIntegrationSuccess = integrationRes?.data?.success;
          if (integrationRes?.data?.success) {
            if (Object.keys(integrationRes?.data?.integrations).length) {
              if (integrationRes?.data.integrations.appName === "gmail") {
                const gmailIntegrationObj: any = integrationRes.data.integrations;
                if (gmailIntegrationObj) {
                  if (Object.keys(gmailIntegrationObj).length > 0) {
                    setIntegrationId(gmailIntegrationObj._id || ''); // Fallback to an empty string if _id is undefined
                    setEmailId(gmailIntegrationObj.appData?.user?.email || ''); // Fallback to an empty string if email is undefined
                    setIsGmailConnected(true);
                  } else {
                    setIsGmailConnected(false);
                  }
                }
              }
            }
          } else {
            setIsGmailConnected(false);
          }
        } catch (error) {
          createNotification('error', 'Something went wrong');
        }
    };

    const handleGmailConnect = async () => {
        try {
            //  const popup = window.open(state.googleOAuthUrl, '_blank');
            const width = 600;
            const height = 800;
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2.5;
            
            googlePopupRef.current = window.open(
                undefined,
                'Google Sign In',
                `width=${width},height=${height},left=${left},top=${top}`,
            );
            const data = {
                integration: {
                  appName: 'gmail'
                },
            };
      
            const response: any = await xpAccountIntegrationGmailConnect(data);            
            if (response.data.url) {
                // Ensure googlePopupRef.current is not null before accessing it
                if (googlePopupRef.current) {
                    googlePopupRef.current.location.href = response.data.url;
                } else {
                    console.error('Popup reference is null');
                }
            }   
            
      
            const handleMessage = (event: MessageEvent) => {
                if (event.source === googlePopupRef.current) {
                    const responseData = event.data;
                    dispatch(Actions.setGmailConnectionState(true, responseData))
                    setEmailId(responseData.integrations.user.email);
                    setIsGmailConnected(true);
                    createNotification('success', responseData.message);
                    setIntegrationId(responseData.integrations._id);
                    window.removeEventListener('message', handleMessage);
                    googlePopupRef?.current?.close();
                }
            };
            //   Listen for messages from the OAuth window
            window.addEventListener('message', handleMessage);
           
      
        } catch (error) {
            console.log(error);
            // createNotification('error', 'Something went wrong');
            // Handle the error
        }
    };

    const handleOpenDisconnectConfirmationModal = () => {
        setOpenDisconnectConfirmationModal(true);
    };

    const handleCloseDisconnectConfirmationModal = () => {
        setOpenDisconnectConfirmationModal(false);
    };

    const handleGmailDisconnect = async () => {
        setLoader(true);
        const data = {
            integrationId: integrationId
        };
        const result: any = await disconnectGmailIntegration(data); 
        if (result && result.status == 200) {
            setLoader(false);
            setIsGmailConnected(false);
            setEmailId('');
            handleCloseDisconnectConfirmationModal();
            dispatch(Actions.setGmailConnectionState(false, {}))
            createNotification('success', result.data.message);
        }else{
            createNotification('error', result.response.data.message);
        }
    };

    return (
        <>
            <Box className="integrated-apps">
                <Stack
                    direction={'row'}
                    // justifyContent="space-between"
                    justifyContent="center"
                    alignItems={'start'}
                    mb={1.5}
                >
                    <Box mt={1.5}>
                        <Box
                            component={'img'}
                            className="app-icon"
                            alt="app icon"
                            src={GoogleGmailIcon}
                            width={48}
                            p={0.5}
                        />
                    </Box>
                </Stack>

                {isGmailConnected == true && (<Box mb={2}>
                    <Stack
                        py={1}
                        px={1.5}
                        sx={{
                            backgroundColor: '#f6f6fc',
                            borderRadius: '6px',
                        }}
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1}
                    >
                        <Box component={'img'} src={GoogleWorkspace} width={24} />
                        <Box className="small-subtitle">
                            {emailId}
                        </Box>
                    </Stack>
                </Box>)}
                <Box>
                    <Box mb={1.5} className="app-title">
                        Google Emails
                    </Box>
                    <Box mb={1.5} className="app-detail">
                        Synchronize with your Google <br />
                        Inbox to send or receive <br />
                        emails in your account.
                    </Box>
                </Box>
                <Box mt={1.5} mb={1.5} className="app-detail">
                    {isGmailConnected === false ? (
                        <Button variant="outlined" color="primary" size="small" onClick={handleGmailConnect}>
                            Connect
                        </Button>
                    ) :                     
                    (
                        <Button variant="outlined" color="secondary" size="small" onClick={handleOpenDisconnectConfirmationModal}>
                            Disconnect
                        </Button>
                    )}
                </Box>
            </Box>
            <Modal
                open={openDisconnectConfirmationModal}
                onClose={handleCloseDisconnectConfirmationModal}
                classNames={{
                    overlay: '',
                    modal: 'Invite-User-Modal',
                }}
                center
            >
                <Box component={Grid} container className="" px={1.5}>
                    <Box component={Grid} mt={-1} item xs={12} pb={3}>
                        <Box component="p" mb={1} className="subtitle">
                            {' '}
                            Disconnect Confirmation!
                        </Box>
                        <p className="small-subtitle">
                            Are you sure you want to disconnect it?
                        </p>
                    </Box>
                    <Grid item xs={12}>
                        <Grid container justifyContent="end">
                            <Stack spacing={1} direction="row" fullWidth>
                                {
                                    <>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disableElevationon
                                            onClick={() => handleGmailDisconnect()}
                                        >
                                            Disconnect
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            onClick={() => handleCloseDisconnectConfirmationModal()}
                                        >
                                            Cancel
                                        </Button>
                                    </>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default GmailIntegration;
import React, { Component } from 'react'
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Link,
} from '@mui/material'
import Box from '@mui/material/Box'
import CustomHTMLTooltip from '../../Components/CustomHTMLTooltip'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from 'Components/Loader/loader'

export class YourPlanListing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            projectsBillingData: [],
            total: 0,
            loading: false
        }

        this.tableHeadTitles = ["Pages", "Campaigns", "Sessions", "Notifications", "Attendees", "Custom Fields", "Challenges", "Team Members"]
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('user_details'))
        let servicePlanId = userDetails.servicePlanId
        this.setState({
            servicePlanId
        }, () => {
            this.getProjectsBillingDataRequest()
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.servicePlan?.getProjectsBillingDataSuccess && !prevProps.servicePlan?.getProjectsBillingDataSuccess) {
            this.setState({
                projectsBillingData: this.getSortedData(this.props.servicePlan?.projectsBillingData),
                total: this.props.servicePlan?.totalProjects,
                loading: false,
                servicePlanDetails: this.props.servicePlan?.servicePlanDetails
            })
        }

        if (this.props.servicePlan?.error && !prevProps.servicePlan?.error) {
            this.setState({
                loading: false,
            })
        }
    }

    getSortedData = (data) => {
        const servicePlanDetails = this.props.servicePlan.servicePlanDetails
        let arr = data.map(obj => {            // going through every project
            let num1 = (obj.agendaCount) / (servicePlanDetails.noOfXPSessions)
            let num2 = (obj.attendeesCount) / (servicePlanDetails.noOfXPAttendees)
            let num3 = (obj.campaignsCount) / (servicePlanDetails.noOfXPCampaigns)
            let num4 = (obj.customFieldCount) / (servicePlanDetails.noOfXPCustomFields)
            let num5 = (obj.gamificationCount) / (servicePlanDetails.noOfXPChallenges)
            let num6 = (obj.notificationCount) / (servicePlanDetails.noOfXPNotifications)
            let num7 = (obj.pagesCount) / (servicePlanDetails.noOfXPPages)
            let num8 = (obj.teamsCount) / (servicePlanDetails.noOfXPTeamMembers)
            let maxNum = Math.max(num1, num2, num3, num4, num5, num6, num7, num8)
            return {
                ...obj,
                maxNum,
                doesExceed: maxNum > 1
            }
        })
        let arr2 = arr.sort((a, b) => b.maxNum - a.maxNum)
        return arr2
    }

    getProjectsBillingDataRequest = () => {
        const { servicePlanId, searchText, page_no, limit } = this.state
        const reqParams = {
            servicePlanId,
            searchText: '',
            page_no: 1,
            limit: 100
        }
        this.props.getProjectsBillingDataRequest(reqParams)
        this.setState({ loading: true })
    }

    isExceeding = (count, servicePlanAllows) => {
        if (count > servicePlanAllows) {
            return (
                <CustomHTMLTooltip
                    interactive={true}
                    title={
                        <Box className="upgrade-tooltip">
                            <h5>{this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.limitExceeded}</h5>
                        </Box>
                    }
                    placement="bottom"
                    arrow={true}
                >
                    <span className="count danger">{count}</span>
                </CustomHTMLTooltip>
            )
        } else {
            return <span className="count">{count}</span>
        }
    }

    optionsList = (val) => {
        let temp;
        switch (val) {
            case 'Pages': temp = this.props.users.languageStateForRedux?.overviewTab?.pages;
                return temp;

            case 'Campaigns': temp = this.props.users.languageStateForRedux?.overviewTab?.campaignsCard;
                return temp;

            case 'Sessions': temp = this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.sessions;
                return temp;

            case 'Notifications': temp = this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.notifications;
                return temp;

            case 'Attendees': temp = this.props.users.languageStateForRedux?.overviewTab?.attendees;
                return temp;

            case 'Custom Fields': temp = this.props.users.languageStateForRedux?.dropdowns?.attendees?.custom;
                return temp;

            case 'Challenges': temp = this.props.users.languageStateForRedux?.column_names?.gamification?.challenges;
                return temp;

            case 'Team Members': temp = this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.totalContacts;
                return temp;

            default: temp = val;
                break;
        }
    }

    render() {
        const { projectsBillingData, servicePlanDetails } = this.state
        return (
            <Box mt={2}>
                {/* Included in your plan */}
                <TableContainer className="list-table">
                    <Table className="table" style={{ minHeight: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="long-text sticky-cell text-primary-light"
                                    style={{ left: '0px', backgroundColor: '#ffffff' }}
                                >
                                    {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.includedInPlan}
                                </TableCell>
                                {this.tableHeadTitles.map((el, i) => <TableCell className="short-text" key={i}> {this.optionsList(el)} </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className="long-text sticky-cell"
                                    style={{ left: '0px', backgroundColor: '#ffffff' }}
                                ></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPPages}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPCampaigns}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPSessions}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPNotifications}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPAttendees}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPCustomFields}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPChallenges}</span></TableCell>
                                <TableCell className="short-text bold"><span style={{ padding: '2px 6px' }}>{servicePlanDetails?.noOfXPTeamMembers}</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table
                        className="table project-usage"
                        style={{ minHeight: 'calc(100vh - 401px)', }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="long-text sticky-cell text-primary-light"
                                    style={{ left: '0px', backgroundColor: '#ffffff' }}
                                >
                                    {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.projectUsage}
                                </TableCell>
                                {this.tableHeadTitles.map((el, i) => <TableCell className="short-text" key={i}></TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(projectsBillingData) && projectsBillingData.length ? projectsBillingData.map((el, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell
                                                className={`long-text sticky-cell ${el.doesExceed && 'danger'}`}
                                                style={{ left: '0px', backgroundColor: '#ffffff' }}
                                            >
                                                {el.doesExceed ? (<CustomHTMLTooltip
                                                    interactive={true}
                                                    title={
                                                        <Box className="upgrade-tooltip">
                                                            <Box component={"h5"} mb={0}>{this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.limitExceeded}</Box>
                                                        </Box>
                                                    }
                                                    placement="bottom"
                                                    arrow
                                                >
                                                    <span>{el.eventName}</span>
                                                </CustomHTMLTooltip>) :
                                                    <span>{el.eventName}</span>
                                                }
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.pagesCount, servicePlanDetails?.noOfXPPages)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.campaignsCount, servicePlanDetails?.noOfXPCampaigns)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.agendaCount, servicePlanDetails?.noOfXPSessions)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.notificationCount, servicePlanDetails?.noOfXPNotifications)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.attendeesCount, servicePlanDetails?.noOfXPAttendees)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.customFieldCount, servicePlanDetails?.noOfXPCustomFields)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.gamificationCount, servicePlanDetails?.noOfXPChallenges)}
                                            </TableCell>
                                            <TableCell className="short-text">
                                                {this.isExceeding(el.teamsCount, servicePlanDetails?.noOfXPTeamMembers)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) :
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }} colSpan="9">
                                            There are no records to display
                                        </TableCell>
                                    </TableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.loading || this.props.users.isLoading ? <Loader /> : null}
                {/* Project Usage end */}
            </Box>
        )
    }
}


const mapStateToProps = (state) => ({
    servicePlan: state.ServicePlan,
    users: state.Users,
})

const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            getBillingDataRequest: Actions.getBillingDataRequest,
            getProjectsBillingDataRequest: Actions.getProjectsBillingDataRequest
        },
        dispatch,
    )

export default connect(mapStateToProps, mapActionsToProps)(YourPlanListing)

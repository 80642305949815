import React, { Component } from 'react'; 
// import '../../sass/main.scss';
import Button from '@mui/material/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification, redirectTo } from '../../helpers';
import { NotificationContainer } from "react-notifications";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import gapiClient from 'gapi-client';
import moment1 from 'moment-timezone';
import TimePicker from 'react-time-picker';
import { GetTimezoneAPI } from 'ReduxStore/API';

class UpdateEventRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      Questionvalue: 0,
      Tabvalue: 0,
      theme: '',
      time: moment().format('HH:mm'),
      endTime: moment().format('HH:mm'),
      currentTime: moment().format('HH:mm'),
      openTime: moment().format('HH:mm'),
      initialDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      openDate: new Date(),
      eventName: '',
      eventDomain: '',
      location: '',
      errors: {},
      loading: false,
      _id: 0,
      eventId: 0,
      lat: 0,
      lng: 0,
      oldTime: moment().format('HH:mm'),
      messageBwOpenTime: '',
      timerClock: 0,
      timeZone: '',
      timezones: [],
      emailSenderName: ''
    };
  }

  async componentDidMount() {
    if (this.props.events) {
      if (this.props.events.success) {
        const events = this.props.events.event;
        this.setState({
          eventName: events.eventName,
          eventDomain: events.eventDomain,
          location: events.location,
          time: events.time,
          endTime: events.endTime,
          startDate: new Date(events.startDate),
          endDate: new Date(events.endDate ? events.endDate : events.startDate),
          openDate: new Date(events.openDate ? events.openDate : events.startDate),
          _id: events._id,
          eventId: events._id,
          eventType: events.eventType,
          replyToEmail: events.replyToEmail,
          timezone: events.timezone,
          emailSenderName: events.emailSenderName
        });
      }

    }

    GetTimezoneAPI().then((result) => {
      if (result.data.status === 200) {
        this.setState({ timezones: result.data.timezone });
      } else {
        createNotification('error', result.data.message);
      }
    }).catch(console.log);  
  }

  onChangeDate = (date) => {
    this.setState({ startDate: date });
  };


  onTimeChange = (time) => {
    this.setState({ time: time });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading
    });
  };


  isValid = () => {
    const { eventName, location, startDate, time, replyToEmail, timezone, emailSenderName } = this.state;
    let error = {};
    let formIsValid = true;

    const regexTest = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const currentDa = moment().format('MM/DD/YYYY');
    const cDate = `${currentDa}00:00:00`;
    const currentDateOnly = moment(cDate).format('X');
    const currentDate = moment().format('X');

    const startDate1 = moment(startDate).format('MM/DD/YYYY');
    const startD = `${startDate1}${time}`;
    const startDOnly = `${startDate1}00:00:00`;
    const startDateSeconds = moment(startD).format('X');
    const startDateOnly = moment(startDOnly).format('X');

    if (startDateOnly < currentDateOnly) {
      formIsValid = false;
      error['startDate'] = "*Please enter start date  greater than current date ";
    } else if (startDateSeconds < currentDate) {
      formIsValid = false;
      error['time'] = "*Please enter start time greater than current time.";
    }

    if (replyToEmail === '' || replyToEmail.trim().length === 0) {
      formIsValid = false;
      error['replyToEmail'] = "*Please enter reply to email.";
    }
    if (replyToEmail && regexTest.test(replyToEmail) === false) {
      formIsValid = false;
      error['replyToEmail'] = "*Please enter a valid email address.";
    }

    if (eventName === '' || eventName.trim().length === 0) {
      formIsValid = false;
      error['eventName'] = "*Please enter project name.";
    }
    if (eventName && eventName.trim().length > 25) {
      formIsValid = false;
      error['eventName'] = "*Only 30 Character is allowed.";
    }

    if (location === '' || location.trim().length === 0) {
      formIsValid = false;
      error['location'] = "*Please enter location.";
    }
    if (startDate === '' || startDate === null) {
      formIsValid = false;
      error['startDate'] = "*Please select start date.";
    }

    if (time === '' || time.trim().length === 0) {
      formIsValid = false;
      error['time'] = "*Please select time.";
    }

    if (timezone === '' || timezone.trim().length === 0) {
      formIsValid = false;
      error['timezone'] = "*Please select timezone.";
    }

    if (emailSenderName === '' || emailSenderName.trim().length === 0) {
      formIsValid = false;
      error['emailSenderName'] = "*Please enter email sender name.";
    }
    if (emailSenderName && emailSenderName.trim().length > 20) {
      formIsValid = false;
      error['emailSenderName'] = "*Only 20 Character is allowed.";
    }


    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.handleLoading();
      const { eventName, location, startDate, time, _id, lat, lng, replyToEmail, timezone, emailSenderName } = this.state;
      const requestbody = { eventName, location, startDate, time, id: _id, lat, lng, replyToEmail, timezone, emailSenderName };
      this.props.updateEvent(requestbody);
    }
  };

  componentDidUpdate(prevProps, prevState) {

    if (this.props.events) {
      if (this.props.events.updateSuccess === true && this.props.events.message !== prevProps.events.message) {
        createNotification('success', this.props.events.message);
        setTimeout(() => {
          let st = (prevState.loading ? this.setState({ loading: false }) : null);
          redirectTo(this.props.navigate, `project-type-edit/${this.props.events.event._id}`, this.props.location.pathname);
        }, 3000);

      }

      if (this.props.events.error && this.props.events.error !== prevProps.events.error) {
        createNotification('error', this.props.events.message, '');
        this.handleLoading();
      }
    }
  }

  handleChangeLocation = (location) => {
    this.setState({ location: location });
  };
  handleSelect = location => {
    this.setState({ location });

    geocodeByAddress(location)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch(error => console.error('Error', error));
  };

  addEventToCalendars = (eventData, date) => {
    let startTime = moment(`${eventData.startDate}:00.000Z`)
      .add("8", "hours")
      .format();
    let endTime = moment(`${eventData.endDate}:00.000Z`)
      .add("8", "hours").add("30", "minutes")
      .format();
    var gapi = window.gapi;
    var CLIENT_ID = "903336222688-uc69d0bdho6rfe2p2ogv4fb70t62t0db.apps.googleusercontent.com";
    var API_KEY = "AIzaSyCwfhH0AzwGKXvw6qbiiVpvzcZmpoW3-VI";
    var SCOPES = "https://www.googleapis.com/auth/calendar";
    var DISCOVERY_DOCS = [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ];

    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

     
      var lp = gapi.client.load('calendar', 'V3'); 
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          var event = {
            summary: `${eventData.title} - ${moment(`${eventData.startDate}`, [
              "HH:mm",
            ]).format("hh:mm A")} PST`,
            // location: "illumeet square",
            description: `${eventData.google_meet}`,
            start: {
              dateTime: startTime,
              timeZone: moment1.tz.guess(),
            },
            end: {
              dateTime: endTime,
              timeZone: moment1.tz.guess(),
            },
            // recurrence: ["RRULE:FREQ=DAILY;COUNT=2"],
            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: 24 * 60 },
                { method: "popup", minutes: 10 },
              ],
            },
          };
         
          var request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          });

          request.execute((event) => { 
            if (event.status === "confirmed") {
              createNotification("success", "Event added to google calendar.");
            } else {
              createNotification(
                "danger",
                "Unable to add event to calendar. Please try again later."
              );
            }
            window.open(event.htmlLink);
          });
        });
    });
  };

  render() {
    const { startDate, time, eventDomain, eventName, location, errors, loading, initialDate, replyToEmail, timezones, timezone, emailSenderName } = this.state;



    return (
      <div className="recipientsPage">
        <div className="rsvpEventfieldBox eventDetailsStepsSec">
          <div className="formSection p-0">
            <form onSubmit={this.handleSubmit}>
              <div className="completeRegisterboxes">
                <div className="eventFullInput CRCircularBox">
                  <div className="field-group inputField">
                    <label>Project Name</label>
                    <input type="text" className="form-control" name="eventName" value={eventName} maxLength="25" onChange={this.handleChange} />
                    <p className="error_mesage"> {errors.eventName} </p>
                  </div>
                  <div class="field-group inputField">
                    <label>Domain Name</label>
                    <div className="editableDomainName">
                      <input type="text" class="form-control emailLoweCase" name="eventDomain" value={eventDomain} onChange={this.handleChange} readOnly /> <span>.illumeetxp.com</span>
                      <p className="error_mesage"> {errors.eventDomain} </p>
                    </div>
                  </div>

                  <div className="field-group inputField">
                    <label>Location</label>
                    <PlacesAutocomplete
                      value={location}
                      onChange={this.handleChangeLocation}
                      onSelect={this.handleSelect}
                    >{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="loc1">
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input '
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    </PlacesAutocomplete>
                    <p className="error_mesage"> {errors.location} </p>
                  </div>


                  <div className="field_group75Both">
                    <div className="field-group inputField">
                      <label>Date </label>
                      <div className="formDate formIcon">
                        <DatePicker
                          selected={startDate}
                          onChange={this.onChangeDate}
                          startDate={startDate}
                          minDate={initialDate}
                          shouldCloseOnSelect={true}
                        />
                        <p className="error_mesage"> {errors.startDate} </p>
                      </div>
                    </div>
                    <div className="field-group inputField ">
                      <label>Time</label>
                      <div className="formTime formIcon">

                        <TimePicker
                          onChange={this.onTimeChange}
                          value={time}
                          format='h:m a'
                          //minTime={new Date()}
                          disableClock={true}
                        />
                        <p className="error_mesage"> {errors.time} </p>
                      </div>
                    </div>

                  </div>


                  <div class="field-group inputField">
                    <label>Reply to email</label>
                    <input type="text" class="form-control" name="replyToEmail" value={replyToEmail} maxLength="40" onChange={this.handleChange} />
                    <p className="error_mesage"> {errors.replyToEmail} </p>
                  </div>
                  <div className="field_group75Both">
                    <div class="field-group inputField">
                      <label>Email Sender Name</label>
                      <input type="text" class="form-control" name="emailSenderName" value={emailSenderName} maxLength="40" onChange={this.handleChange} />
                      <p className="error_mesage"> {errors.emailSenderName} </p>
                    </div>

                    <div class="field-group inputField timeClock">
                      <label>Select Time Zone</label>
                      <div class="formTime formIcon">
                        <select class="form-control" name="timezone" onChange={this.handleChange} value={timezone}>
                          <option value={false}>Select timezone</option>
                          {timezones && timezones.length > 0 && timezones.map((data) =>
                            <option value={data.timezone}>{data.timezone}</option>
                          )}
                        </select>
                        <p className="error_mesage"> {errors.timezone} </p>
                      </div>
                    </div>
                  </div>



                </div>
                <div className="submitForm justify-content-center">
                  <Button variant="contained" className="themeBlueButton" color="primary" type="submit">
                    Update Event
                  </Button>
                </div>
              </div>


            </form>
          </div>

        </div>



        <NotificationContainer />
        {loading ?
          <Loader />
          : null}

      </div>

    );
  }
}


const mapStateToProps = state => ({
  events: state.Events
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      updateEvent: Actions.updateEventsRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(UpdateEventRegistration);
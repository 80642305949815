import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Loader from '../../../Components/Loader/loader';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
// import '../../../sass/main.scss';
import { createNotification } from '../../../helpers';
import axios from 'axios';
import Grid from '@mui/material/Grid2';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { pink } from '@mui/material/colors';
import { Stack } from '@mui/material';
import { theme } from '../../../theme/theme';
import { UserData } from '../../../helpers/common';
import { Modal } from 'react-responsive-modal';
import getUnLayerMeetingsConfig from './meetingsConfig';
import { getProjectIdFromUrl } from '../../../helpers/common';
import { permissions, FILE_SECTION, FILE_TYPE } from '../../../constants/constants';
import { languageCode } from '../../../helpers/common';
import { listAudienceCustomFieldsAPI, GetToolCustomFieldsAPI } from 'ReduxStore/API';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';
import withCustomRouter from '../../../Common/withCustomRouter';
// API URL from environment variables
const URL = process.env.REACT_APP_API_URL;
const section = FILE_SECTION.images;
const styles = {
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: '40px',
    height: '40px',
  },
};

/**
 * @class Editor
 * @extends Component
 * @description Renders the meeting editor component for creating and editing meeting templates.
 */
class Editor extends Component {
  constructor(props) {
    super(props);
    this.chooseUnlayerImage = React.createRef(null);
    this.anchorRef = React.createRef();
    this.state = {
      showLoader: false,
      templateName: '',
      eventId: '',
      templateId: '',
      pageName: '',
      pageUrl: '',
      html: null,
      json: {},
      formType: '',
      isUpdatePage: false,
      projectPageTid: '',
      eventDomain: '',
      toolTemplates: null,
      customFileds: null,
      open: false,
      testEmailModal: false,
      testEmail: '',
      exitBackButton: false,
      meetingTemplate: '',
      loading: false,
      saveAndExit: '',
      meetingId: '',
      customFiledsForAudience: null,
      meetingOrganizer: '',
      uploadFromFiles: false,
      buttonState: false,
      parentForPopup: "unlayerProjectPages",
      displayS3Image: false,
      parentId: "",
      pageEditorImage: "",
      pageEditorImageForEdit: ""
    };
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  getPageData = async (templateId) => {
    try {
      const response = await axios.get(
        `${URL}/getPageDetails?pageId=${templateId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response?.data?.page[0]?.isLoginPage) {
        this.state.formType = 'loginform';
      }
      return response;
    } catch (err) {
      return err;
    }
  };

  async componentDidMount() {
    const users = UserData();
    this.props.handleLang(languageCode(users.language));
    this.props.newLangStateForRedux(users.language);

    // Use props to get params, navigate, and location
    let meetingId = this.props.location?.state?.meetingId; // Use location from props
    const eventId = getProjectIdFromUrl();

    this.props.getProjectRequest(eventId);
    this.handleGetTemplate(meetingId, eventId);

    const param = { projectId: eventId };
    await GetToolCustomFieldsAPI(param).then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFileds = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });

    await listAudienceCustomFieldsAPI().then((fieldResult) => {
      if (fieldResult?.data?.status === 200) {
        this.state.customFiledsForAudience = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });

    this.setState({
      meetingId,
      eventId,
    }, () => this.renderTemplate());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.project.getProjectSuccess &&
      !prevProps.project.getProjectSuccess &&
      this.props.project.message !== prevProps.project.message
    ) {
      // Handle any updates
    }

    if (
      this.props.Meeting.getMeetingTemplateSuccess !==
      prevProps.Meeting.getMeetingTemplateSuccess &&
      this.props.Meeting.getMeetingTemplateSuccess === true
    ) {
      this.setState({
        meetingTemplate: this.props.Meeting.meetingTemplate,
        html: this.props.Meeting.meetingTemplate[0]?.html,
        json: this.props.Meeting.meetingTemplate[0]?.json,
        loading: false,
        meetingOrganizer: this.props.Meeting.meetingTemplate[0]?.meetingOrganizer
      });
    }

    if (
      this.props.Meeting.addMeetingSuccess !==
      prevProps.Meeting.addMeetingSuccess &&
      this.props.Meeting.addMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);

      this.setState({
        loading: false,
      }, () => {
        if (this.state.saveAndExit && this.state.saveAndExit.length) {
          setTimeout(() => {
            this.gobackFromEditor();
          }, 200);
        }
      });
    }

    if (
      this.props.Meeting.updateMeetingSuccess !==
      prevProps.Meeting.updateMeetingSuccess &&
      this.props.Meeting.updateMeetingSuccess === true
    ) {
      createNotification('success', this.props.Meeting.message);
      this.setState({
        loading: false,
      }, () => {
        if (this.state.saveAndExit && this.state.saveAndExit.length) {
          setTimeout(() => {
            this.gobackFromEditor();
          }, 200);
        }
      });
    }

    if (
      this.props.Meeting.error === true &&
      prevProps.Meeting.error === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      createNotification('error', this.props.Meeting.message);
      this.setState({
        loading: false,
      });
    }
  }

  handleGetTemplate = (meetingId, eventId) => {
    let params = {
      meetingType: 'Project',
      meetingId: meetingId,
      eventId: eventId,
      type: 'getTemplateforEditor',
    };
    this.props.getMeetingTemplate(params);
  };

  createMeeting = (saveAndExit) => {
    let { eventId, html, json, meetingId, meetingOrganizer } = this.state;

    let requestBody = {
      eventId,
      html,
      json,
      meetingId: meetingId,
      lngCode: this.props.users.newLangState,
      meetingOrganizer: meetingOrganizer
    };
    this.props.updateMeeting(requestBody);
    this.setState({
      loading: true,
      saveAndExit: saveAndExit,
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        open: false,
      });
    }
  };

  sendTestEmail = () => {
    this.setState({
      testEmailModal: true,
    });
  };

  handleCloseTestEmailModal = () => {
    this.setState({
      testEmailModal: false,
    });
  };

  handleChangeTestEmail = (e) => {
    this.setState({
      testEmail: e.target.value,
    });
  };

  sendTestTransactionEmail = () => {
    const testTransMailData = {
      eventId: this.state.eventId,
      email: this.state.testEmail,
      meetingId: this.state.meetingId,
    };
    try {
      if (this.state.testEmail == '') {
        throw Error('Test email id is required');
      }
      this.setState({
        loading: true,
      });
      const users = UserData();
      axios
        .post(`${URL}/meeting/sendTestEmailForMeeting`, testTransMailData, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
            'check-grant': `${this.state.eventId}-${permissions.editMeeting}`,
          },
        })
        .then((data) => {
          if (data.data.status == 200) {
            this.setState({
              loading: false,
            });
            createNotification(
              'success',
              data.data ? data.data.message : 'Test mail send successfully.',
            );
            this.handleCloseTestEmailModal();
          } else {
            createNotification('error', data.data.error);
          }
        })
        .catch((err) => {
          createNotification('error', 'Something went wrong');
        });
    } catch (err) {
      createNotification('error', err.message);
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  // Updated gobackFromEditor to use navigate instead of history
  gobackFromEditor = () => {
    const eventId = getProjectIdFromUrl();
    this.props.handleNavigationFromEditorToCreateMeeting(true);
    this.props.navigate(`/project-dashboard/meetings/${eventId}`, { state: { meetingId: this.state.meetingId, isEdit: true } });
  };

  handleCloseBackButtonModal = () => {
    this.setState({
      exitBackButton: false,
    });
  };

  renderTemplate = () => {
    window.scrollTo(0, 0);
    window.unlayer.init(getUnLayerMeetingsConfig(this.state.customFileds, this.state.customFiledsForAudience));

    window.unlayer.registerCallback('selectImage', (data, done) => {
      this.handleOpenFileModulePopup();
      document.body.addEventListener('click', function (event) {
        if (event.target.classList.contains('driveImages')) {
          const src = event.target.src;
          done({ url: src });
        }
      });
    });

    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design;
        const html = data.html;
        let formType = '';
        this.setState({ json, html, formType });
      }.bind(this));
    }.bind(this));

    if (Object.keys(this.state.json)?.length > 0) {
      window.unlayer.loadDesign(this.state?.json);
    } else {
      window.unlayer.loadDesign();
    }
  };

  handleOpenFileModulePopup = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };

  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };

  handleChoosedFile = () => {
    this.setState({
      uploadFromFiles: false,
      openEditAttendeeModal: false,
    });
  };

  chooseUnlayerImageClick = (id) => {
    this.setState({
      parentId: id
    });
    this.chooseUnlayerImage.current.click();
  };

  onUnlayerImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(jpg|jpeg|gif|png)$/i)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5242880) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }

      if (method === 'add') {
        this.setState({ pageEditorImage: event.target.files[0] });

        let formData = new FormData();
        formData.append('imageFile', event.target.files[0]);
        formData.append('section', section);
        formData.append('type', FILE_TYPE.file);
        formData.append('parent', this.state.parentId);
        this.props.addImagesRequest(formData);
      } else {
        this.setState({ pageEditorImageForEdit: event.target.files[0] });
      }
    }
  };

  render() {
    const { showLoader, templateName, meetingTemplate } = this.state;

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        <div className="editingDashboard">
          <div id="meetingsEditor" style={{ height: 'calc(100vh - 60px)' }}>
            {/* Your Unlayer editor will go here */}
          </div>
        </div>
        <NotificationContainer />
        <input
          type="file"
          id="pageEditorImage"
          name="pageEditorImage"
          onChange={(e) => this.onUnlayerImageChange(e, 'add')}
          accept="image/*"
          ref={this.chooseUnlayerImage}
          style={{ display: "none" }}
        />
        <Modal
          open={this.state.testEmailModal}
          onClose={this.handleCloseTestEmailModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.unlayer?.sendMail}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.messages?.enterValid}
              </p>
            </Grid>
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="div" className="inputField">
                <label>{this.props.users.languageStateForRedux?.column_names?.teams?.email}</label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterHere}
                  value={this.state.testEmail}
                  onChange={this.handleChangeTestEmail}
                />
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.sendTestTransactionEmail}
                  >
                    {this.props.users.languageStateForRedux?.unlayer.sendMail}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseTestEmailModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
  project: state.Projects,
  Meeting: state.Meeting,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getMeetingTemplate: Actions.getMeetingTemplateRequest,
      addMeeting: Actions.addMeetingRequest,
      updateMeeting: Actions.updateMeetingRequest,
      eventRequest: Actions.listSingleEventRequest,
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux,
      getProjectRequest: Actions.getProjectRequest,
      addImagesRequest: Actions.addImagesRequest,
    },
    dispatch,
  );

// Connects the component to Redux store and removes withRouter
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(Editor));

import React, { useEffect, useState } from 'react';
import { Box, IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import moment from 'moment';
import PaginationFunctional, { handleEndCountForPagination } from './PaginationFunctional';
import Loader from 'Components/Loader/loader';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function ViewLogs(props) {
    const dispatch = useDispatch();
    const googleWorkspaceState = useSelector((store) => store.GoogleWorkspace);
    const projectsState = useSelector((store) => store.Projects);
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    // variables for pagination started
    const [limit, setLimit] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [startCount, setStartCount] = useState(1);
    const [endCount, setEndCount] = useState(0);
    const [logsCount, setLogsCount] = useState(0);
    // variables for pagination ends

    useEffect(() => {
        getLogsList();
        setLoading(true);
    }, []);

    const getLogsList = (limitFromPagination, pageNumFromPagination) => {
        dispatch(Actions.googleWorkspaceGetLogsRequest({
            integrationId: props.integrationId,
            page_no: pageNumFromPagination || page_no,
            limit: limitFromPagination || limit,
        }));
    };

    useEffect(() => {

        if (googleWorkspaceState.googleWorkSpaceViewLogsSuccess && Array.isArray(googleWorkspaceState.googleWorkspaceLogs)) {
            let logsFromResp = googleWorkspaceState.googleWorkspaceLogs;
            setLogs(logsFromResp);
            setLogsCount(googleWorkspaceState.totalLogsCount);
            handleEndCountForPagination(googleWorkspaceState.totalLogsCount, limit, page_no, setEndCount);
            setLoading(false);
        }
    }, [googleWorkspaceState.googleWorkSpaceViewLogsSuccess, googleWorkspaceState.googleWorkspaceLogs]);

    useEffect(() => {
        if (googleWorkspaceState.googleWorkspaceGetLogsError) {
            setLoading(false);
        }
    }, [googleWorkspaceState.googleWorkspaceGetLogsError]);

    const handleCloseViewLog = () => {
        props.setGoogleDashboardViewLog(false);
    };
    return (
        <Box mt={1}>
            <Box>
                <Stack direction={'row'} alignItems="center">
                   
                    <Button
                        onClick={handleCloseViewLog}
                        className="back-button"
                        variant="outlined"
                        color="primary"
                        size='small'
                        startIcon={<KeyboardBackspaceIcon />}
                    >
                        Back
                    </Button>
                    <Box ml={2} className="paragraph bold">
                        Sync Logs ({startCount + ' to ' + endCount + ' of ' + logsCount})
                    </Box>
                </Stack>
            </Box>
            <TableContainer className="list-table">
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="email sticky-cell"
                                style={{
                                    left: '0px',
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                Email Address
                            </TableCell>
                            <TableCell className="date-time">
                                Sync Time
                            </TableCell>

                            <TableCell className="email">
                                Sync Admin
                            </TableCell>
                            <TableCell className="status">
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {loading ? <Loader />
                        :
                        <TableBody>
                            {Array.isArray(logs) && logs?.length &&
                                logs.map(log => {
                                    return (
                                        <TableRow>
                                            <TableCell
                                                className="email sticky-cell"
                                                style={{
                                                    left: '0px',
                                                    backgroundColor: '#ffffff',
                                                }}
                                            >
                                                {log.primaryEmail}
                                            </TableCell>
                                            <TableCell className="date-time">
                                                {moment.tz(log.updatedAt, projectsState?.project?.timezone,).format('MMM, DD YYYY, h:mm A')}
                                            </TableCell>


                                            <TableCell className="email">
                                                {log.syncAdmin}
                                            </TableCell>

                                            <TableCell className="status">
                                                <span className={`table-status ${!log.syncStatus ? 'danger' : 'success'}`}>
                                                    {log.syncStatus ? "SUCCESS" : "FAILED"}{' '}
                                                </span>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })
                            }
                        </TableBody>}
                </Table>
            </TableContainer>
            <PaginationFunctional
                startCount={startCount}
                setStartCount={setStartCount}
                endCount={endCount}
                limit={limit}
                setLimit={setLimit}
                page_no={page_no}
                setPage_no={setPage_no}
                totalItemsCount={logsCount}
                listItemsFn={getLogsList}
            />
        </Box>
    );
}

export default ViewLogs;
import React, { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
// import '../../../../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import GoogleWorkspace from '../../../../../../src/assets/images/icons/Google_G_Logo.svg';
import GoogleCalendar from '../../../../../../src/assets/images/icons/Google-Calendar.svg';
import { Modal } from 'react-responsive-modal';
import MicrosoftCalenderIcon from '../../../../../../src/assets/images/icons/Microsoft-Calender.svg';
import OfficeCalenderIcon from '../../../../../../src/assets/images/icons/Office-Calender.svg';
import { googleSignInPopupForCalendar, disconnectGoogleCalendar } from 'ReduxStore/API';
import { useParams } from 'react-router';
import { createNotification } from 'helpers';
import { UserData } from 'helpers/common';
import Loader from '../../../../../Components/Loader/loader'
/**
 * @function CalendarIntegration
 * @param {Object} props - Component props.
 * @param {Object} googleCalendarIntegration - Google Calendar integration details.
 * @param {Function} setGoogleCalendarIntegration - Function to set Google Calendar integration details.
 * @description Functional component for managing calendar integrations, specifically Google Calendar.
 */
export default function CalendarIntegration({ googleCalendarIntegration, setGoogleCalendarIntegration }) {
  const params = useParams();
  const googlePopupRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);
  const [integrationId, setIntegrationId] = useState('');
  const [emailId, setEmailId] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loader, setLoader] = useState(false)
  /**
 * @function useEffect
 * @description React Hook that runs after the component mounts.
 * Retrieves user data and updates the state based on Google Calendar integration details.
 */
  useEffect(() => {
    const user = UserData();
    if (Object.keys(googleCalendarIntegration).length > 0) {
      setIntegrationId(googleCalendarIntegration._id);
      setEmailId(googleCalendarIntegration?.appData?.user?.email);
      setIsCalendarConnected(true);
    } else {
      setIsCalendarConnected(false);
    }
  }, []);

  /**
 * @function handleClickOpen
 * @description Opens the configuration modal for selecting a calendar service.
 */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
 * @function handleClose
 * @description Closes the configuration modal and resets the selected calendar service.
 */
  const handleClose = () => {
    setOpen(false);
    setSelectedService('');
  };

  /**
 * @function handleSelectService
 * @param {string} service - Selected calendar service.
 * @description Sets the selected calendar service when configuring the calendar integration.
 */
  const handleSelectService = (service) => {
    setSelectedService(service);
  };

  /**
 * @function handleSignIn
 * @description Initiates the sign-in process for the selected calendar service.
 * Opens a popup window for Google Calendar sign-in and handles the response.
 */
  const handleSignIn = async () => {
    try {
      //  const popup = window.open(state.googleOAuthUrl, '_blank');
      const width = 600;
      const height = 800;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      if (selectedService == "google") {
        googlePopupRef.current = window.open(
          undefined,
          'Google Sign In',
          `width=${width},height=${height},left=${left},top=${top}`,
        ); 
        const data = {
          integration: {
            eventId: params.eventId,
            isActive: true,
            lngCode: 'en',
            name: 'Google Calendar',
            type: 'googleCalendar',
          },
        };

        const response = await googleSignInPopupForCalendar(data);
        if (response.data.success) {
          if (response.data.url) {
            googlePopupRef.current.location.href = response.data.url;
          }
        }

        const handleMessage = (event) => {
          if (event.source === googlePopupRef.current) {
            const responseData = event.data;
            setEmailId(responseData.integrations.user.email);
            setIsCalendarConnected(true);
            createNotification('success', responseData.message);
            setIntegrationId(responseData.integrations._id);
            window.removeEventListener('message', handleMessage);
            googlePopupRef?.current?.close();
            handleClose();
            // dispatch(Actions.getAppIntegrations(eventId));
          }
        };
        //   Listen for messages from the OAuth window
        window.addEventListener('message', handleMessage);
      } else {
        alert(selectedService +" service is not available yet! Please use Google for now!");
      }

    } catch (error) {
      console.log(error);
      createNotification('error', 'Something went wrong');
      // Handle the error
    }
  };

  /**
 * @function handleDisconnect
 * @description Disconnects the current calendar integration and updates the state.
 */
  const handleDisconnect = async () => { 
    setLoader(true)
     const data = {
      integrationId: integrationId
    };
    const response = await disconnectGoogleCalendar(data);
    if (response.data.success) {
      setLoader(false)
      setIsCalendarConnected(false);
      setGoogleCalendarIntegration({});
      setEmailId('');
      handleCloseConfirmationModal();
      createNotification('success', response.data.message);
    }  
  };

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true)
  };

  const handleCloseConfirmationModal = () => { 
    setOpenConfirmationModal(false)
  };

  

  return (
    <>
      <Box className="subsecondary" mb={1} mt={2}>
        Calendar Integration
      </Box>
      <Box className="paragraph text-primary-light">
        Effortlessly manage your meetings! Add, edit and delete events
        seamlessly within your authorized account. Calendar configuration made
        simple for your convenience
      </Box>
      <Box mt={3}>
        {/* if not configured */}
        {!isCalendarConnected && (
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Configure Calendar
          </Button>
        )}

        {/* if configured */}

        <Grid container spacing={3}>
          {/* Google Calender */}
          {isCalendarConnected && (
            <Grid >
              <Box className="integrated-apps">
                <Stack
                  direction={'row'}
                  // justifyContent="space-between"
                  justifyContent="center"
                  alignItems={'start'}
                  mb={1.5}
                >
                  <Box mt={1.5}>
                    <img
                      className="app-icon"
                      alt="app icon"
                      src={GoogleWorkspace}
                      width={40}
                    />
                  </Box>
                </Stack>
                <Box mb={2}>
                  <Stack
                    py={0.5}
                    px={1.5}
                    sx={{
                      backgroundColor: '#f6f6fc',
                      borderRadius: '6px',
                    }}
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                  >
                    <img src={GoogleCalendar} />
                    <Box className="small-subtitle">
                      {emailId}
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Box mb={1.5} className="app-title">
                    Google Calendar
                  </Box>
                  <Box mb={1.5} className="app-detail">
                    Synchronize with your Google <br />
                    Calendar to create/edit/delete <br />
                    events in your calendar.
                  </Box>
                </Box>
                <Box mt={1.5} mb={1.5} className="app-detail">
                  <Button variant="outlined" color="secondary" size="small" onClick={handleOpenConfirmationModal}>
                    Disconnect
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Box component={Grid} container className="meeting-calender-modal" px={1.5}>
          <Box component={Grid} mt={-2} size={{xs: 12,}} pb={5}>
            <Box component="p" mb={4} className="subsecondary">
              {' '}
              Select a Service
            </Box>

            <Grid container>
              <Grid size={{md:4}} >
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  className='calender-service'
                  onClick={() => handleSelectService('google')}
                >
                  <Box
                    p={3.25}
                    mb={3}
                    className={`service-icon ${selectedService === "google" ? 'active' : ''}`}
                  >
                    <img src={GoogleWorkspace} width={42} height={42} />
                  </Box>
                  <Box className="small-subtitle primary-text">Google</Box>
                </Stack>
              </Grid>
              <Grid size={{md:4}}>
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  className='calender-service'
                  onClick={() => handleSelectService('microsoft')}
                >
                  <Box
                    p={3.25}
                    mb={3}
                    className={`service-icon ${selectedService === "microsoft" ? 'active' : ''}`}
                  >
                    <img src={MicrosoftCalenderIcon} width={42} height={42} />
                  </Box>
                  <Box className="small-subtitle primary-text">Microsoft</Box>
                </Stack>
              </Grid>
              <Grid size={{md:4}}>
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  className='calender-service'
                  onClick={() => handleSelectService('office365')}
                >
                  <Box
                    p={3.25}
                    mb={3}
                    className={`service-icon ${selectedService === "office365" ? 'active' : ''}`}
                  >
                    <img src={OfficeCalenderIcon} width={42} height={42} />
                  </Box>
                  <Box className="small-subtitle primary-text">Office 365</Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid size={{xs: 12,}}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row" fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={handleSignIn}
                >
                  Next
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  disableElevation
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>

        {/* confirmation modal start here!*/} 
        <Modal
            open={openConfirmationModal}
            onClose={handleCloseConfirmationModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  Disconnect Confirmation!
                </Box>
                <p className="small-subtitle">
                  Are you sure you want to disconnect it?
                </p>
              </Box>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary" 
                          disableElevationon
                          onClick={() => handleDisconnect()}
                        >
                          Disconnect
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() => handleCloseConfirmationModal()}
                        >
                          Cancel
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
        </Modal>
        {/* confirmation modal end here!*/}
        {loader ? <Loader /> : null}
    </>
  );
}

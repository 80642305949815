import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import AIChatbotAPI from 'ReduxStore/API/aiChatbotAPI';
import { removeStorage } from '../../../helpers/common';


function* getAiAgentGeneralData(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.getAiAgentGeneralData, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.getAiAgentGeneralDataSuccess(response.data.message, response.data.chatbot));
        } else if (response.data.status === 403) {
            yield put(Actions.getAiAgentGeneralDataError(response.data.message));
            removeStorage(); 
        } else {
            yield put(Actions.getAiAgentGeneralDataError(response.data.message));
        }
    } else {
        yield put(Actions.getAiAgentGeneralDataError(response.data.message));
    }
}

function* createOrUpdateAiAgentGeneralData(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.createOrUpdateAiAgentGeneralData, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.createOrUpdateAiAgentGeneralDataSuccess(response.data.message, response.data.chatbot, response.data.chatbots, response.data.totalChatBotCount));
        } else if (response.data.status === 403) {
            yield put(Actions.createOrUpdateAiAgentGeneralDataError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.createOrUpdateAiAgentGeneralDataError(response.data.message));

        }
    } else {
        yield put(Actions.createOrUpdateAiAgentGeneralDataError(response.data.message));
    }
}

function* listChatbots(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.listChatbots, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.listAllChatbotsSuccess(response.data.message, response.data.chatbots, response.data.totalChatBotCount));
        } else if (response.data.status === 403) {
            yield put(Actions.listAllChatbotsError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.listAllChatbotsError(response.data.message));
        }
    } else {
        yield put(Actions.listAllChatbotsError(response.data.message));
    }
}
function* trainAgent(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.trainAgent, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.trainAgentSuccess(response.data.message));
        } else if (response.data.status === 403) {
            yield put(Actions.trainAgentError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.trainAgentError(response.data.message)); 
        }
    } else {
        yield put(Actions.trainAgentError(response.data.message));
    }
}
function* deleteChatbot(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.deleteChatbot, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.deleteChatbotSuccess(response.data.message, response.data.chatbots, response.data.totalChatBotCount));
        } else if (response.data.status === 403) {
            yield put(Actions.deleteChatbotError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.deleteChatbotError(response.data.message)); 
        }
    } else {
        yield put(Actions.deleteChatbotError(response.data.message));
    }
}
function* enableChatbot(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.enableChatbot, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.enableChatbotSuccess(response.data.message, response.data.chatbots, response.data.totalChatBotCount));
        } else if (response.data.status === 403) {
            yield put(Actions.enableChatbotError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.enableChatbotError(response.data.message));
        }
    } else {
        yield put(Actions.enableChatbotError(response.data.message));
    }
}
function* setChatbotAsDefault(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.setChatbotAsDefault, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.setChatbotAsDefaultSuccess(response.data.message, response.data.chatbots, response.data.totalChatBotCount));
        } else if (response.data.status === 403) {
            yield put(Actions.setChatbotAsDefaultError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.setChatbotAsDefaultError(response.data.message));
        }
    } else {
        yield put(Actions.setChatbotAsDefaultError(response.data.message));
    }
}
function* saveSettingsForAiChatbot(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.saveSettingsForAiChatbot, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.saveSettingsForAiChatbotSuccess(response.data.message, response.data.settings));
        } else if (response.data.status === 403) {
            yield put(Actions.saveSettingsForAiChatbotError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.saveSettingsForAiChatbotError(response.data.message));
        }
    } else {
        yield put(Actions.saveSettingsForAiChatbotError(response.data.message));
    }
}

function* replicateChatWidget(actions) {
    const aiChatbotApi = new AIChatbotAPI();
    const response = yield call(aiChatbotApi.replicateChatWidget, actions.data);
    if (response.status === 200) {
        if (response.data.success) {
            yield put(Actions.replicateChatWidgetSuccess(response.data.message, response.data.chatbots, response.data.totalChatBotCount));
        } else if (response.data.status === 403) {
            yield put(Actions.replicateChatWidgetError(response.data.message));
            removeStorage();
        } else {
            yield put(Actions.replicateChatWidgetError(response.data.message));
        }
    } else {
        yield put(Actions.replicateChatWidgetError(response.data.message));
    }
}

const aiChatbotSaga = {
    getAiAgentGeneralData,
    createOrUpdateAiAgentGeneralData,
    listChatbots,
    trainAgent,
    deleteChatbot,
    enableChatbot,
    setChatbotAsDefault,
    saveSettingsForAiChatbot,
    replicateChatWidget
};
export default aiChatbotSaga;
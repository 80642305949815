import { TableCell, TableRow } from '@mui/material';
import { turnCamelCaseToNormalText } from 'helpers';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomField } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { useDispatch } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';

type Props = {
  customField: CustomField;
  handleOrderIndexChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    customField: CustomField,
  ) => void;
  handleOpenAddNewFieldModal: (
    isBeingEdited?: boolean,
    customField?: CustomField,
  ) => void;
  handleOpenDeleteConfirmationModal: (id: string | undefined) => void;
};
const IndividualCustomFieldsForManageCustomFields = ({
  customField,
  handleOrderIndexChange,
  handleOpenAddNewFieldModal,
  handleOpenDeleteConfirmationModal,
}: Props) => {
  const location = useLocation();
  const currentTab = location.pathname.split('/').at(-1);
  const [prevOrderIndex, setPrevOrderIndex] = useState<number>(
    null as unknown as number,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setPrevOrderIndex(customField.orderIndex as number);
  }, []);

  const updateCustomField = (
    e: React.FocusEvent<HTMLInputElement, Element>,
    customField: CustomField,
  ) => {
    if (prevOrderIndex !== parseInt(e.target.value)) {
      const data = {
        bodyParams: {
          ...customField,
          customFieldType: currentTab,
          orderIndex: parseInt(e.target.value),
        },
        queryParams: {
          customFieldType: currentTab,
          hideMessage: true,
        },
      };
      dispatch(Actions.updateOpportunityCustomFieldRequest(data));
    }
    setPrevOrderIndex(parseInt(e.target.value));
  };

  return (
    <TableRow key={customField._id}>
      <TableCell
        className="tableBodyCell long-text"
        style={{ maxWidth: '240px' }}
        scope="row"
      >
        {customField.fieldData.fieldName}
      </TableCell>
      <TableCell className="tableBodyCell" align="left">
        {turnCamelCaseToNormalText(customField.fieldData.fieldType)}
      </TableCell>
      <TableCell className="tableBodyCell" align="left">
        {customField.isMandatory ? 'Yes' : 'No'}
      </TableCell>
      <TableCell className="tableBodyCell" align="left">
        <input
          type="number"
          className="list-order"
          value={customField.orderIndex}
          onChange={(e) => handleOrderIndexChange(e, customField)}
          onBlur={(e) => updateCustomField(e, customField)}
        />
      </TableCell>
      <TableCell className="tableBodyCell" align="left">
        <Box className="action-button" component="span" mr={1}>
          <EditIcon
            onClick={() => handleOpenAddNewFieldModal(true, customField)}
            style={{ fontSize: 18, color: '#777777' }}
          />
        </Box>
        <Box
          className="action-button"
          component="span"
          onClick={() => handleOpenDeleteConfirmationModal(customField?._id)}
        >
          <DeleteIcon style={{ fontSize: 18, color: '#777777' }} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default IndividualCustomFieldsForManageCustomFields;

import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme'; 
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { connect } from 'react-redux'; 
import { Stack, Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { createNotification, redirectTo } from '../../../helpers';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../Components/Loader/loader'
import { permissions } from '../../../constants/constants'
import withCustomRouter from '../../../Common/withCustomRouter'; 

const URL = process.env.REACT_APP_API_URL 
 
export class CampaignPreview extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            eventId: "",
            preview:{},
        }
    }    

    UserData = async () => {
        const currentPath = window.location.pathname
        if (currentPath.includes('member')) {
            return JSON.parse(localStorage.getItem('member_details'))
        } else {
            return JSON.parse(localStorage.getItem('user_details'))
        }
    } 
    async getCampaignPerview(eventId, campaignId){
        const users = await this.UserData()
        try {
            const response = await axios.get(`${URL}/campaigns/getCampaign/${campaignId}`, {
                headers: {
                    token: `bearer ${users.token}`,
                    'Content-Type': 'application/json',
                }
            }); 
            if (response.data && response.data.status == 200) {                  
                return response?.data 
            } else {
                throw Error(response.data.error)
            }
        } catch (err) {
            createNotification("error", err.message);
        }
    }  

    async componentDidMount() { 
        let eventId =  this.props.params.eventId; 
        let params = this.props.location;
        let campaignId = new URLSearchParams(params.search).get("cid")  
        let campaignDetails = await this.getCampaignPerview(eventId, campaignId); 
        this.setState({
            eventId : eventId,
            preview: campaignDetails?.campaign[0]
        });
    } 

    render() {
        const { preview} = this.state; 
        return (  
            <ThemeProvider theme={theme}>
                {preview?.html ?  
                    <div style={{pointerEvents:'none'}}>
                        {ReactHtmlParser(preview?.html)}
                    </div>
                :
                    <Loader />
               }
            </ThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
});

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getCampaign: Actions.getCampaignRequest,
        },
        dispatch
    );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(CampaignPreview));


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { ContextSettings, ExampleSettings } from 'ReduxStore/Reducers/aiChatbot.reducer';
import { ChangeEvent } from 'react';
import { ErrorType } from '../../GeneralDashboard';

type settingsProps = {
  contextSettings: ContextSettings;
  exampleSettings: ExampleSettings;
  onContextSettingsInputChange: (name: string, value: number) => void;
  onExampleSettingsInputChange: (name: string, value: number) => void;
  errors: ErrorType;
};

const settings = ({
  contextSettings,
  exampleSettings,
  onContextSettingsInputChange, 
  onExampleSettingsInputChange,
  errors
}: settingsProps) => {

  const handleContextSettingsInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onContextSettingsInputChange(name, parseInt(value));
  };

  const handleExampleSettingsInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onExampleSettingsInputChange(name, parseInt(value));
  };

  return (
    <Box className="tab-content">
      <Box className="tab-title">Context Settings</Box>
      <Box>
        <Grid
          container
          spacing={1}
          style={{
            position: 'relative',
          }}
          pb={3.6}
          className="inputField"
        >

          <Grid size={{xs: 4,}} >
            <label>Documents Size</label>
            <input
              type="number"
              name="documentSize"
              className="form-control small"
              placeholder="Add a value"
              min={500}
              max={2000}
              onChange={handleContextSettingsInputChange}
              value={contextSettings?.documentSize}
            />
            <Box
              mt={0.3}
              sx={{
                fontSize: '10px',
              }}
            >
              Min 500 - Max 2000
            </Box>
            <span className="error_mesage"> {errors.contextSettingsDocumentSize} </span>
          </Grid>

          <Grid size={{xs: 4,}}>
            <label>No of Documents</label>
            <input
              type="number"
              name="documentCount"
              className="form-control small"
              placeholder="Add a value"
              min={1}
              max={10}
              onChange={handleContextSettingsInputChange}
              value={contextSettings?.documentCount}
            />
            <Box
              mt={0.3}
              sx={{
                fontSize: '10px',
              }}
            >
              Min 1 - Max 10
            </Box>
            <span className="error_mesage"> {errors.contextSettingsDocumentCount} </span>
          </Grid>
          <Grid size={{xs: 4,}}>
            <label>Overlap</label>
            <input
              type="number"
              name="overlap"
              className="form-control small"
              placeholder="Add a value"
              min={150}
              max={500}
              onChange={handleContextSettingsInputChange}
              value={contextSettings?.overlap}
            />
            <Box
              mt={0.3}
              sx={{
                fontSize: '10px',
              }}
            >
              Min 150 - Max 500
            </Box>
            <span className="error_mesage"> {errors.contextSettingsOverlap} </span>
          </Grid>
          <Grid size={{xs: 12,}}>
            <Box my={2} className="tab-title">Example Settings</Box>
          </Grid>
          <Grid size={{xs: 4,}}>
            <label>Documents Size</label>
            <input
              type="number"
              name="documentSize"
              className="form-control small"
              placeholder="Add a value"
              min={500}
              max={2000}
              onChange={handleExampleSettingsInputChange}
              value={exampleSettings?.documentSize}
            />
            <Box
              mt={0.3}
              sx={{
                fontSize: '10px',
              }}
            >
              Min 500 - Max 2000
            </Box>
            <span className="error_mesage"> {errors.exampleSettingsDocumentSize} </span>
          </Grid>
          <Grid size={{xs: 4,}}>
            <label>No of Documents</label>
            <input
              type="number"
              name="documentCount"
              className="form-control small"
              placeholder="Add a value"
              min={1}
              max={10}
              onChange={handleExampleSettingsInputChange}
              value={exampleSettings?.documentCount}
            />
            <Box
              mt={0.3}
              sx={{
                fontSize: '10px',
              }}
            >
              Min 1 - Max 10
            </Box>
            <span className="error_mesage"> {errors.exampleSettingsDocumentCount} </span>
          </Grid>
          <Grid size={{xs: 4,}}>
            <label>Overlap</label>
            <input
              type="number"
              name="overlap"
              className="form-control small"
              placeholder="Add a value"
              min={150}
              max={500}
              onChange={handleExampleSettingsInputChange}
              value={exampleSettings?.overlap}
            />
            <Box
              mt={0.3}
              sx={{
                fontSize: '10px',
              }}
            >
              Min 150 - Max 500
            </Box>
            <span className="error_mesage"> {errors.exampleSettingsOverlap} </span>
          </Grid>

        </Grid>
      </Box>

    </Box>
  );
};

export default settings;

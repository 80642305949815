import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateMemberRoute = ({ element }) => {
  const memberRole = localStorage.getItem('member_role');

  // Conditionally return either the element or redirect to "/"
  return memberRole && memberRole !== 'undefined' && memberRole === '2' ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateMemberRoute;

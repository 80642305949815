import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { get, cloneDeep } from 'lodash';
import { NotificationContainer } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  updateEventTemplateById,
  createTemplate,
  getEventTemplates,
  createTemplateToLib,
  getTemplateLibById,
  getTemplateLib,
  CheckEventPathAPI,
  getCustomToolTemplates,
  GetCustomFieldsAPI,
  GetToolCustomFieldsAPI,
  getEventAPI
} from 'ReduxStore/API';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import PageToolBar from '../../../Components/PageToolBar';
import Loader from '../../../Components/Loader/loader';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
// import '../../../sass/main.scss';
import { createNotification, redirectTo } from '../../../helpers';
import getUnLayerTransEmailConfig from './bconfig';
import * as qs from 'qs';
import axios from 'axios';
import Grid from '@mui/material/Grid2';
import BackArrow from '../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../assets/images/icons/pageIconSmall.svg';
import Settings from '../../../assets/images/icons/settings.svg';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { pink } from '@mui/material/colors';
import { Stack } from '@mui/material';
import { theme } from '../../../theme/theme';
import { isValidEmail, UserData } from '../../../helpers/common';
import { accessType } from '../../../constants/constants';
import { Modal } from 'react-responsive-modal';
import { Buffer } from 'buffer'; // Import Buffer from the buffer package
import withCustomRouter from '../../../Common/withCustomRouter';

const pageAccessType = Buffer.from(accessType).toString('base64');

const URL = process.env.REACT_APP_API_URL;

const styles = {
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: '40px',
    height: '40px'
  },
};

let allowRendering = true;
class ProjectEditorPage extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.state = {
      showLoader: false,
      templateName: '',
      eventId: "",
      templateId: "",
      pageName: "",
      pageUrl: "",
      html: null,
      json: {},
      formType: '',
      isUpdatePage: false,
      projectPageTid: '',
      eventDomain: '',
      toolTemplates: null,
      customFileds: null,
      open: false,
      testEmailModal: false,
      testEmail: "",
      exitBackButton: false,
      loading: false,
      fromPreview: false,
      pageCreatedAlready: false,
      allowed: {},
    };
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  getPageData = async (templateId) => {
    try {
      const response = await axios.get(`${URL}/getPageDetails?pageId=${templateId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.page[0]?.isLoginPage) {
        this.state.formType = "loginform";
      }
      if (this.state.isUpdatePage) {
        this.state.pageCreatedAlready = true;
      } else {
        if (
          response?.data?.pageFromSuperAdmin === false && //checking default templates
          response?.data?.page &&
          response?.data?.page[0]?.eventId !== null // checking user defined templates
        ) {
          this.state.pageCreatedAlready = true;
        }
      }
      return response;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  async componentDidMount() {
    const { pid, tid, pn, purl } = this.props.params;
    if (this.props.location?.state && this.props.location?.state?.hasRenderedByBadges) {
      let allowed = {};
      allowed['Edit Page Settings'] = true;
      this.setState({
        hasRenderedByBadges: this.props.location.state.hasRenderedByBadges,
        allowed: allowed
      });
    }

    let eventId = pid;
    const templateId = tid;
    const pageName = pn;
    const pageUrl = purl;
    await getEventAPI(this.props.params.pid).then((eventResult) => {
      if (eventResult?.data?.status == 200) {
        this.setState({
          eventDomain: get(eventResult, 'data.event.eventDomain'),
          passwordType: get(eventResult, 'data.event.passwordType'),
          eventId,
          pageName,
          pageUrl,
          templateId
        }, () => {
          const eventDomain = get(eventResult, 'data.event.eventDomain');
          const passwordType = get(eventResult, 'data.event.passwordType');
        });
      }
    });

    const updatePath = window.location.pathname.split('/');
    const updatePathTrue = updatePath.includes('update-badges-template-page');
    this.setState({
      isUpdatePage: updatePathTrue,
    });
    const apiUrl = process.env.REACT_APP_API_URL;
    const params = { type: 'registrationform', eventId, fields: 'id,html,templateName,type,icon,toolCategory' };
    await getCustomToolTemplates(params).then((result) => {
      if (result?.data?.success) {
        this.state.toolTemplates = get(result, 'data.templates', []).map((form) => ({
          ...form,
          html: form?.html?.replace("100vh;", `auto;`).replace('min-height: auto', 'min-height: auto!important')
        }));
      }
    });
    const param = { projectId: eventId };
    await GetToolCustomFieldsAPI(param).then((fieldResult) => {
      if (fieldResult?.data?.status == 200) {
        this.state.customFileds = get(fieldResult, 'data.customFields', []).map((fields) => ({
          ...fields,
        }));
      }
    });
    this.setState({
      eventId,
      templateId,
      pageName,
      pageUrl,
    });

    const response = await axios.get(`${URL}/getPageDetails?pageId=${templateId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (Array.isArray(response.data.page)) {
      this.setState({
        html: response.data.page[0].html,
        json: response.data.page[0].json
      });
    }

    var qrCodeTypeDynamic = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Attendees", 'value': "attendee" },
      // { 'label': "Sessions", 'value': "sessions" },
      { 'label': "Custom", 'value': "custom" }
    ];

    var qrCodeSessionOption = [
      { 'label': "Choose One", 'value': "" },
      { 'label': "Title", 'value': "{{{sessionTitleQrCode}}}" },
      { 'label': "Location", 'value': "{{{sessionLocationQrCode}}}" },
      { 'label': "Description", 'value': "{{{sessionDescriptionQrCode}}}" },
      { 'label': "Tracks", 'value': "{{{sessionTracksQrCode}}}" },
    ];

    var QrCodeSizeDropdown = [
      { 'label': "100X100", 'value': "100" },
      { 'label': "200X200", 'value': "200" },
      { 'label': "300X300", 'value': "300" },
      { 'label': "400X400", 'value': "400" },
    ];

    const formEnabled = false;
    const setState = this.setState.bind(this);
    window.scrollTo(0, 0);
    window.unlayer.init(getUnLayerTransEmailConfig(apiUrl, eventId, this.state.toolTemplates, this.state.customFileds, this.state.passwordType, qrCodeTypeDynamic, qrCodeSessionOption, QrCodeSizeDropdown));
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design;
        const html = data.html;
        let formType = '';
        if (updates?.item?.values?.formType != undefined) {
          formType = updates?.item?.values?.formType;
        }
        setState({ json, html, formType });
      });
    });





    if (templateId !== 'blank') {
      this.getPageData(templateId).then((result) => {
        if (result.data.success) {
          const templates = result.data.page || {};
          const oldTemplates = cloneDeep(templates);
          const { json } = templates;

          this.setState({
            ...templates,
            showLoader: false,
            oldTemplates,
          });
          if (Object.keys(this.state.json).length > 0) {
            window.unlayer.loadDesign(this.state.json);
          } else {
            window.unlayer.loadDesign();
          }
        }
      });
    } else {
      getTemplateLib({})
        .then(async (result) => {
          if (
            result.data &&
            result.data.templates &&
            result.data.templates.length
          ) {
            let temp = result.data.templates.filter(
            );
            if (temp.length) {
              let templates = {};
              templates['json'] = temp[0].json;
              templates['html'] = temp[0].html;
              window.unlayer.loadDesign();
              this.setState({ ...templates });
            }
          }
        })
        .catch(console.log);

      if (formEnabled && templateId === 'blank') {
        let eventPageState = JSON.parse(localStorage.getItem('eventPageState'));
        this.setState(
          {
            ...eventPageState,
          },
          () => {
            window.unlayer.loadDesign(this.state.json);
            localStorage.removeItem('eventPageState');
          },
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let allowed = {};
    allowed['Edit Page Settings'] = true;
    if (!prevState[0] && this.state[0]) {
      this.setState({ pageHasChanged: false, allowed: allowed });
    } else if (this.state.json !== prevState.json && this.state.html !== prevState.html) {
      this.setState({ pageHasChanged: true });
    }
  }

  saveLayout = () => {
    let {
      json,
      html,
      pageName,
      pageUrl,
    } = this.state;

    const templateId = get(this.props, 'match.params.tid');
    const eventId = get(this.props, 'match.params.pid');
    let type = get(this.props, 'type', '').toLowerCase();

    if (templateId !== 'blank') {
      const data = { json, html, pageName, pageUrl };
      updateEventTemplateById(templateId, data)
        .then((result) => {
          if (result.data.success) {
            createNotification('success', result.data.message);
            const newTemplate = get(result, 'data.template', {});
            const oldTemplates = cloneDeep(newTemplate);
            this.setState({
              ...newTemplate,
              oldTemplates,
            });
          } else {
            createNotification('error', result.data.message);
          }
        })
        .catch(console.log);
    } else {
      const data = { json, html, type, pageName, pageUrl };
      createTemplate(eventId, data)
        .then((result) => {
          if (result.data.success) {
            const templateId = result.data.template._id;
            redirectTo(
              this.props.navigate,
              `event-page/eid/${eventId}/tid/${templateId}`,
              this.props.location.pathname,
            );
            createNotification('success', result.data.message);
          } else {
            createNotification('error', result.data.message);
          }
        })
        .catch(console.log);
    }
  };

  saveConfirmation = (isActive) => {
    this.setState(
      ({ templateName = '' }) => ({ templateName: templateName.trim() }),
      () => {
        const {
          html,
          templateName,
          oldTemplates,
          defaultTemplate,
          selectedRegTemplate,
          path,
          landingPage,
          registrationPage,
          saveTempDisabled,
        } = this.state;
        const oldHtml = get(oldTemplates, 'html', '');
        const oldTemplateName = get(oldTemplates, 'templateName', '');
        const templateId = get(this.props, 'match.params.tid');
        if (
          templateName &&
          (path || landingPage) &&
          html &&
          (html !== oldHtml ||
            (templateName && templateName !== oldTemplateName) ||
            selectedRegTemplate !== oldTemplates.oldTemplates)
        ) {
          this.saveLayout(isActive);
        } else {
          const message = !templateName.trim()
            ? `Can't save template with blank name`
            : 'No changes found';
          createNotification('error', message);
        }
      },
    );
  };

  handleRegTemplate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.getAgenda);
  };

  handleCheckBox = (name, value) => {
    this.setState({ [name]: value });
  };

  saveTemplateToLib = () => {
    let { json, html, templateNameLib } = this.state;
    const data = {
      json,
      html,
      templateName: templateNameLib,
      type: 'event-page',
    };
    if (html && json && templateNameLib) {
      createTemplateToLib(data)
        .then((result) => {
          if (result.data.success) {
            this.setState({ saveTempDisabled: true });
            createNotification('success', result.data.message);
          } else {
            createNotification('error', result.data.message);
          }
        })
        .catch(console.log);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert-body inputField">
              <h1>Template Name</h1>
              <input
                type="text"
                className="form-control"
                name="templateNameLib"
                placeholder={'Please enter template name'}
                value={this.state.templateNameLib}
                maxLength="25"
                onChange={this.onChange}
              />
              <div className="react-confirm-alert-button-group">
                <button onClick={() => onClose()}>Cancel</button>
                <button
                  onClick={() => {
                    if (this.state.templateNameLib) {
                      this.saveTemplateToLib();
                      onClose();
                    } else {
                      createNotification(
                        'error',
                        'Please enter the template name first.',
                      );
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };


  savePageAndExit = async () => {
    const { pageName, pageUrl, html, json, eventId } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == "loginform") {
      isLoginPage = true;
    }
    const body = {
      eventId,
      pageName,
      pageUrl,
      html,
      json,
      isLoginPage,
      pageType: 'TransactionEmail'
    };
    const users = await this.UserData();
    const response = await axios.post(`${URL}/createPage`, body, {
      headers: {
        'token': `bearer ${users.token}`,
        'Content-Type': 'application/json',
      }
    });
    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification("success", response.data.message);
        if (this.state.hasRenderedByBadges) {
          window.location.href = `/project-dashboard/attendees/${eventId}`;
        } else {
          window.location.href = `/project-dashboard/manage-pages/${eventId}`;
        }
      }
    }
  };
  savePage = async () => {
    const { pageName, pageUrl, html, json, eventId } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == "loginform") {
      isLoginPage = true;
    }
    const body = {
      eventId,
      pageName,
      pageUrl,
      html,
      json,
      isLoginPage,
      pageType: 'TransactionEmail'
    };
    const users = await this.UserData();
    const response = await axios.post(`${URL}/createPage`, body, {
      headers: {
        'token': `bearer ${users.token}`,
        'Content-Type': 'application/json',
      }
    });
    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification("success", response.data.message);
      }
    }
    this.setState({
      pageHasChanged: false
    });
  };

  updatePageAndExit = async () => {
    const { pageName, pageUrl, html, json, eventId } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == "loginform") {
      isLoginPage = true;
    }
    const body = {
      eventId,
      pageName,
      pageUrl,
      html,
      json,
      pageId: this.state.templateId,
      isLoginPage,
      pageType: 'Badges',
      requestCheck: "editTransactionalEmailTemplates",
    };
    const users = await this.UserData();
    const response = await axios.patch(`${URL}/updatePage/${this.state.templateId}`, body, {
      headers: {
        'token': `bearer ${users.token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification("success", response.data.message);
        if (this.state.hasRenderedByBadges) {
          this.props.navigate({ pathname: `/project-dashboard/attendees/${eventId}`, state: { hasRenderedByBadges: false } });
        } else {
          window.location.href = `/project-dashboard/attendees/${eventId}`;
        }

      } else if (response.data.message === "Page Domain already exists.") {
        createNotification("error", response.data.message);
      }
    } else if (response.status === 404) {
      createNotification("error", "Something went wrong");
    }
  };

  updatePage = async () => {
    const { pageName, pageUrl, html, json, eventId } = this.state;
    let isLoginPage = false;
    if (this.state.formType != '' && this.state.formType == "loginform") {
      isLoginPage = true;
    }
    const body = {
      eventId,
      pageName,
      pageUrl,
      html,
      json,
      pageId: this.state.templateId,
      isLoginPage,
      pageType: 'Badges',
      requestCheck: "editTransactionalEmailTemplates",
    };
    const users = await this.UserData();
    const response = await axios.patch(`${URL}/updatePage/${this.state.templateId}`, body, {
      headers: {
        'token': `bearer ${users.token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      if (response.data.status === 200) {
        createNotification("success", response.data.message);
      } else if (response.data.message === "Page Domain already exists.") {
        createNotification("error", response.data.message);
      }
    } else if (response.status === 404) {
      createNotification("error", "Something went wrong");
    }
    this.setState({
      pageHasChanged: false
    });
  };

  handleCloseSettingsConfirmationModal = () => {
    this.setState({
      openSettingsPage: false,
    });
  };

  openSettings = () => {
    if (this.state.pageHasChanged) {
      this.setState({
        openSettingsPage: true,
        settingsPage: true,
      });
    } else {
      const { eventId, templateId, pageName, pageUrl } = this.state;
      if (this.state.pageCreatedAlready) {
        this.props.navigate({
          pathname: `/project-badge/update-badge/settings/pid/${eventId}/tid/${templateId}/pn/${pageName}/purl/${pageUrl}`,
        });
      } else {
        this.savePage('Project', true);
      }
    }
  };

  openPreview = () => {
    this.setState({ fromPreview: true }, () => {
      if (this.state.pageHasChanged) {
        this.setState({
          openSettingsPage: true,
          settingsPage: false,
        });
      } else {
        const { eventId, templateId, pageName, pageUrl } = this.state;
        if (this.state.pageCreatedAlready) {
          window.open(
            `/project-dashboard/preview-page/${this.state.eventId}?pagepath=${this.state.pageUrl}&pageName=${pageName}&pageTemplateId=${templateId}&sentFrom=projectBadgesEditor`,
            '_blank',
          );
          this.setState({
            fromPreview: false,
          });
        } else {
          this.savePage('Project', true);
        }
      }
    });
  };

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({
        open: false
      });
    }
  };

  sendTestEmail = () => {
    this.setState({
      testEmailModal: true
    });
  };

  handleCloseTestEmailModal = () => {
    this.setState({
      testEmailModal: false
    });
  };

  handleChangeTestEmail = (e) => {
    this.setState({
      testEmail: e.target.value
    });
  };

  sendTestTransactionEmail = () => {
    const testTransMailData = {
      testSubject: get(this.props, 'match.params.pn'),
      projectId: get(this.props, 'match.params.pid'),
      pageId: get(this.props, 'match.params.tid'),
      testEmailId: this.state.testEmail,
    };
    try {
      if (this.state.testEmail == "" || !isValidEmail(this.state.testEmail)) {
        this.setState({
          errors: {
            email: "Enter a valid email address"
          }
        });
        return;
      }
      const users = UserData();
      const URL = process.env.REACT_APP_API_URL;
      this.setState({
        loading: true
      });
      axios.post(`${URL}/attendees/testTransactionEmail`,
        testTransMailData,
        {
          headers: {
            'token': `bearer ${users.token}`,
            'Content-Type': 'application/json',
            'check-grant': `${testTransMailData.projectId}-Edit Transactional Email Templates`
          }
        }).then((data) => {
          if (data.data.status == 200) {
            createNotification("success", data.data ? data.data.message : "Test mail send successfully.");
            this.handleCloseTestEmailModal();
          } else {
            createNotification("error", data.data.error);
          }
          this.setState({
            loading: false
          });
        }).catch((err) => {
          createNotification("error", "Something went wrong");
        });
    } catch (err) {
      createNotification("error", err.message);
    }

  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  handleOpenBackButtonModal = () => {
    if (this.state.pageHasChanged) {
      this.setState({
        exitBackButton: true
      });
    } else {
      this.handleBackButton('discard');
    }
  };

  handleCloseBackButtonModal = () => {
    this.setState({
      exitBackButton: false
    });
  };

  handleBackButton = (exitStyle) => {
    const { isUpdatePage, hasRenderedByBadges, pageName, pageUrl } = this.state;
    const eventId = get(this.props, 'match.params.pid');
    if (exitStyle === 'saveAndExit') {
      this.updatePageAndExit("Project");
    } else if (exitStyle === 'discard') {
      this.props.navigate({ pathname: `/project-dashboard/attendees/${eventId}`, state: { hasRenderedByBadges: false } });
    }
  };

  render() {
    const {
      view,
      showLoader,
      templateName,
      isActive,
      path = '',
      landingPage,
      registrationPage,
      saveTempDisabled,
      errors = {},
      pathSuccessMessage,
      event,
      landingPageExist,
      isUpdatePage,
      hasRenderedByBadges,
      testEmailModal,
      testEmail,
      allowed,
    } = this.state;
    const { type, events } = this.props;
    const templateId = get(this.props, 'match.params.tid');
    const eventId = get(this.props, 'match.params.pid');
    const backTo = `/project-dashboard/${eventId}`;

    return (
      <div className="editingPageWrapper">
        <div className="unlayer-header">
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid >
              <Grid container alignItems="center">
                <Button className='back-button' variant="text" onClick={this.handleOpenBackButtonModal}>
                  <img alt="" src={BackArrow} />
                </Button>
                <Avatar style={styles.pink} >
                  <img alt="" src={PageIcon} />
                </Avatar>
                <h3 className='page-title'>{this.state.pageName} </h3>
              </Grid>
            </Grid>
            <Grid className='buttons'>
              <Stack spacing={1} direction="row">

                {allowed['Edit Page Settings'] && (
                  <>
                    <Box display={{ xs: 'block', lg: 'none' }}>
                      <Button onClick={this.openSettings} variant="text">
                        <img alt="" src={Settings} />
                      </Button>
                    </Box>
                    <Box display={{ xs: 'none', lg: 'block' }}>
                      <Button
                        onClick={this.openSettings}
                        startIcon={<img alt="" src={Settings} />}
                        variant="text"
                      >
                        Settings
                      </Button>
                    </Box>
                  </>
                )}
                <Button variant="outlined" onClick={() => this.openPreview()}>
                  <Box display={{ xs: 'none', lg: 'block' }}>PREVIEW</Box>
                  <Box display={{ xs: 'block', lg: 'none' }}>
                    <VisibilityOutlinedIcon />
                  </Box>
                </Button>
                <Button variant="contained" onClick={() => this.updatePage()}>Save</Button>
                <Button variant="contained" onClick={() => this.updatePageAndExit()}>SAVE & EXIT</Button>
              </Stack>
            </Grid>

          </Grid>
          {/* Save Drop Down */}

        </div>
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            <div id="pageEditor" style={{ height: 'calc(100vh - 60px)' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
        <Modal
          open={testEmailModal} onClose={this.handleCloseTestEmailModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component='form' container className='modal-wrapper' >
            <Grid size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className='subtitle'>Send Test Email</Box>
              <p className='small-subtitle'>Please enter a valid email address. Will send test email for review.</p>
            </Grid>
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="div" className='inputField'>
                <label>Email</label>
                <input type="text" class="form-control small" placeholder="Enter here" value={testEmail} onChange={this.handleChangeTestEmail} />
                <span className="error_mesage"> {errors.email} </span>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button variant="contained" color="primary" disableElevation onClick={this.sendTestTransactionEmail} style={{ backgroundColor: "#5b33f1" }}>Send Test Email</Button>
                  <Button variant="outlined" onClick={this.handleCloseTestEmailModal}>Cancel</Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={this.state.exitBackButton} onClose={this.handleCloseBackButtonModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                Confirmation
              </Box>
              <p className="small-subtitle">Do you want to exit without saving?</p>
            </Box>
            <Grid size={{xs: 12,}} >
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button variant="contained" color="primary" disableElevation onClick={() => this.handleBackButton('saveAndExit')}>Save and exit</Button>
                  <Button variant="outlined" disableElevation onClick={() => this.handleBackButton('discard')}>Discard changes</Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.Events,
    project: state.Projects,

  };
};

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
      getProjectRequest: Actions.getProjectRequest,

    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(ProjectEditorPage))




import React, { Component } from 'react';
import { ThemeProvider } from '@mui/styles';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import { theme } from '../../theme/theme';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
// import '../../sass/main.scss';
import { Button } from '@mui/material';
import 'react-responsive-modal/styles.css'; 

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const customStyles = {
  option: (provided, state, styles) => ({
    ...provided,
    ...styles,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 48,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (styles) => ({
    ...styles,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    top: '-235px',
    overflow: 'hidden',
    maxHeight: 220,
    minHeight: 220,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};
class AssignGroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: false,
      open: this.props.assignGroupOpen ? this.props.assignGroupOpen : false,
      page_no: this.props.page_no ? this.props.page_no : 1,
      group_id: [],
      showGroup: true,
      groupStatus: 'assign',
      groupName: '',
      arrayOfAudience: [],
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'groupStatus' && event.target.value === 'add') {
      this.setState({ showGroup: false });
    } else if (
      event.target.name === 'groupStatus' &&
      event.target.value === 'assign'
    ) {
      this.setState({ showGroup: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ open: false, group_id: '', groupName: '' });
    }

    if (
      this.props.contacts.updateError &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  isValid = () => {
    const { group_id, groupName, groupStatus, arrayOfAudience } = this.state;
    let error = {};
    let formIsValid = true;

    if (groupStatus === 'assign') {
      if (arrayOfAudience.length === 0) {
        formIsValid = false;
        error['group_id'] = this.props.users.languageStateForRedux?.audienceTab?.selectAudience;
      }
    } else {
      if (groupName === '' || groupName.trim().length === 0) {
        formIsValid = false;
        error['groupName'] = this.props.users.languageStateForRedux?.validations?.enterAudienceName;
      }
    }

    this.setState({ errors: error });
    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      const { page_no, group_id, groupStatus, groupName, arrayOfAudience } =
        this.state;
      const { ids, currentGroup } = this.props;
      arrayOfAudience.map((data) => {
        group_id.push(data.value);
      });
      const requestbody = {
        page_no,
        group_id,
        groupStatus,
        groupName,
        ids,
        currentGroup,
      };
      this.props.assignGroupRequest(requestbody);

      this.handleLoading();
    }
  };

  handleChangeMultiselect = (selected) => {
    this.setState({
      arrayOfAudience: selected,
    });
  };

  showRestOfGroup = (group) => {
    const { currentGroup } = this.props;
    return group.filter((grp) => grp.value !== currentGroup);
  };

  render() {
    const { errors, loading, groupName, groupStatus, showGroup } = this.state;
    let options = [];
    {
      this.props.groups &&
        this.props.groups.map((data, index) => {
          options.push({ value: data._id, label: data.groupName });
        });
    }
    return (
      <ThemeProvider theme={theme}>
        <div className="formSection">
          <Grid
            component="form"
            onSubmit={this.handleSubmit}
            container
            className="modal-wrapper"
            sx={{ maxHeight: 'auto' }}
          >
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.copyAudience}
              </Box>
              <Box className="small-subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.copyModalDesc}
              </Box>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Box component="div" className="inputField" pb={4}>
                <Box>
                  <label>{this.props.users.languageStateForRedux?.audienceTab?.selectToUpdate}</label>
                </Box>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="assign"
                    value="assign"
                    name="groupStatus"
                    checked={groupStatus === 'assign'}
                    onChange={this.handleChange}
                  />
                  <label class="custom-control-label" for="assign">
                    {this.props.users.languageStateForRedux?.audienceTab?.assign}
                  </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="Add"
                    value="add"
                    name="groupStatus"
                    checked={groupStatus === 'add'}
                    onChange={this.handleChange}
                  />
                  <label class="custom-control-label" for="Add">
                    {this.props.users.languageStateForRedux?.audienceTab?.add}
                  </label>
                </div>
              </Box>
              {showGroup ? (
                <Box component="div" className="" pb={4}>
                  <label>{this.props.users.languageStateForRedux?.audienceTab?.audienceName}</label>
                  {this.showRestOfGroup(options) &&
                    this.showRestOfGroup(options).length > 0 && (
                      <ReactSelect
                        styles={customStyles}
                        className="custom-multi-select"
                        options={this.showRestOfGroup(options)}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={this.handleChangeMultiselect}
                        allowSelectAll={true}
                        value={this.state.arrayOfAudience}
                        placeholder={this.props.users.languageStateForRedux?.common?.select}
                      />
                    )}
                  <span className="error_mesage"> {errors.group_id} </span>
                </Box>
              ) : (
                <Box component="div" className="inputField" pb={4}>
                  <label>{this.props.users.languageStateForRedux?.audienceTab?.audienceName}</label>

                  <input
                    type="text"
                    class="form-control small"
                    name="groupName"
                    value={groupName}
                    placeholder={this.props.users.languageStateForRedux?.audienceTab?.enterAudienceName}
                    onChange={this.handleChange}
                  />
                  <span className="error_mesage"> {errors.groupName} </span>
                </Box>
              )}
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    {' '}
                    {this.props.users.languageStateForRedux?.audienceTab?.assignAudience}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      contactRequest: Actions.contactRequest,
      assignGroupRequest: Actions.assignGroupRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(AssignGroupModal);

import IntegrationCreators, {
  Constants as IntegrationConstants,
} from './integration.action';
import AccessCodeCreators, {
  Constants as AccessCodeConstants,
} from './accessCode.action';
import MeetingCreators, {
  Constants as MeetingConstants,
} from './meeting.action';
import GamificationCreators, {
  Constants as GamificationConstants,
} from './gamification.action';
import CampaignsCreators, {
  Constants as CampaignsConstants,
} from './campaigns.action';
import AttendeesCreators, {
  Constants as AttendeesConstants,
} from './attendees.action';
import ProjectNotificationsCreators, {
  Constants as ProjectNotificationsConstants,
} from './projectNotifications.action';
import LoginCreators, { Constants as LoginConstants } from './login.action';
import AgendaCreators, { Constants as AgendaConstants } from './agenda.action';
import RegisterCreators, {
  Constants as RegisterConstants,
} from './register.action';
import LoaderCreators, { Constants as LoaderConstants } from './loader.action';

import ProfileCreators, {
  Constants as ProfileConstants,
} from './profile.action';

import AutoLoginCreators, {
  Constants as AutoLoginConstants,
} from './autologin.action';

import AdminAutoLoginCreators, {
  Constants as AdminAutoLoginConstants,
} from './adminautologin.action';

import EventsCreators, { Constants as EventsConstants } from './events.action';
import ContactsCreators, {
  Constants as ContactsConstants,
} from './contacts.action';
import GroupsCreators, { Constants as GroupsConstants } from './groups.action';

import ProjectsCreators, {
  Constants as ProjectsConstants,
} from './projects.action';

import MembersCreators, {
  Constants as MembersConstants,
} from './members.action';

import UsersCreators, { Constants as UsersConstants } from './users.action';

import NotificationsCreators, {
  Constants as NotificationsConstants,
} from './notifications.action';
import ProutesCreators, {
  Constants as ProutesConstants,
} from './proutes.action';
import RolesCreators, { Constants as RolesConstants } from './roles.action';
import PermissionsCreators, {
  Constants as PermissionsConstants,
} from './permission.action';

import ReportCreators, { Constants as ReportConstants } from './report.action';

import ServicePlanCreators, {
  Constants as ServicePlanConstants,
} from './servicePlan.action';
import {
  AppIntegrationConstants,
  AppIntegrationCreators,
} from './integrations/appsActions';
import {
  VanityDomainConstants,
  VanityDomainCreators,
} from './vanityDomainAction';

import GoogleWorkspaceCreators, {
  Constants as GoogleWorkspaceConstants,
} from './googleWorkspace.action';

import GoogleSignInCreators, {
  Constants as GoogleSignInConstants,
} from './googleSignIn.action';

import AIChatbotCreators, {
  Constants as AIChatbotConstants,
} from './aiChatbot.action';

import ApplicationCreators, {
  Constants as ApplicationConstants,
} from './integrations/applications/applications.action';

import OpportunityAccountsCreators, {
  Constants as OpportunityAccountsConstants
} from './opportunity/accounts.action'

import OpportunityAudienceCreators, {
  Constants as OpportunityAudienceConstants
} from './opportunity/audience.action'

import OpportunityCustomFieldsCreators, {
  Constants as OpportunityCustomFieldsConstants} from './opportunity/opportunityCustomFields.action'

import OpportunityStatesCreators, {
  Constants as OpportunityStatesConstants} from './opportunity/opportunityStates.action'

import OpportunityCommonCreators, {
  Constants as OpportunityCommonConstants} from './opportunity/opportunityCommon.action'

import OpportunityOpportunitiesCreators, {
  Constants as OpportunityOpportunitiesConstants} from './opportunity/opportunities.action'

import FileCreators, {
    Constants as FileConstants
  } from './files.action'
  
import OpportunityContactsCreators, {
  Constants as OpportunityContactsConstants} from './opportunity/contacts.action'

import ApiKeyCreators, {
    Constants as ApiKeyConstants} from './apiKey.action'

import XpUsersWebSocketCreators, {
  Constants as XpUsersWebSocketConstants} from './xpUsersWebSocket.action'

  import XpGmailIntegration, {
    Constants as XpGmailIntegrationConstants,
  } from './../Actions/integrations/xpGmailIntegration.action';


export const Constants = {
  ...GamificationConstants,
  ...CampaignsConstants,
  ...AttendeesConstants,
  ...ProjectNotificationsConstants,
  ...LoginConstants,
  ...RegisterConstants,
  ...LoaderConstants,
  ...ProfileConstants,
  ...EventsConstants,
  ...ProjectsConstants,
  ...ContactsConstants,
  ...GroupsConstants,
  ...MembersConstants,
  ...UsersConstants,
  ...NotificationsConstants,
  ...ProutesConstants,
  ...AutoLoginConstants,
  ...AdminAutoLoginConstants,
  ...RolesConstants,
  ...PermissionsConstants,
  ...AgendaConstants,
  ...ReportConstants,
  ...IntegrationConstants,
  ...AccessCodeConstants,
  ...ServicePlanConstants,
  ...AppIntegrationConstants,
  ...MeetingConstants,
  ...VanityDomainConstants,
  ...GoogleWorkspaceConstants,
  ...GoogleSignInConstants,
  ...AIChatbotConstants,
  ...ApplicationConstants,
  ...OpportunityAccountsConstants,
  ...OpportunityCustomFieldsConstants,
  ...OpportunityStatesConstants,
  ...OpportunityCommonConstants,
  ...OpportunityOpportunitiesConstants,
  ...FileConstants,
  ...OpportunityContactsConstants,
  ...ApiKeyConstants,
  ...OpportunityAudienceConstants,
  ...XpUsersWebSocketConstants,
  ...XpGmailIntegrationConstants
};

export const Actions = {
  ...GamificationCreators,
  ...CampaignsCreators,
  ...AttendeesCreators,
  ...ProjectNotificationsCreators,
  ...LoginCreators,
  ...RegisterCreators,
  ...LoaderCreators,
  ...ProfileCreators,
  ...EventsCreators,
  ...ProjectsCreators,
  ...ContactsCreators,
  ...GroupsCreators,
  ...MembersCreators,
  ...UsersCreators,
  ...NotificationsCreators,
  ...ProutesCreators,
  ...AutoLoginCreators,
  ...AdminAutoLoginCreators,
  ...RolesCreators,
  ...PermissionsCreators,
  ...AgendaCreators,
  ...ReportCreators,
  ...IntegrationCreators,
  ...AccessCodeCreators,
  ...ServicePlanCreators,
  ...AppIntegrationCreators,
  ...MeetingCreators,
  ...VanityDomainCreators,
  ...GoogleWorkspaceCreators,
  ...GoogleSignInCreators,
  ...AIChatbotCreators,
  ...ApplicationCreators,
  ...OpportunityAccountsCreators,
  ...OpportunityCustomFieldsCreators,
  ...OpportunityStatesCreators,
  ...OpportunityCommonCreators,
  ...OpportunityOpportunitiesCreators,
  ...FileCreators,
  ...OpportunityContactsCreators,
  ...ApiKeyCreators,
  ...OpportunityAudienceCreators,
  ...XpUsersWebSocketCreators,
  ...XpGmailIntegration,
};

import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import { useDropzone } from 'mui-file-dropzone';
import { createNotification } from '../../../helpers';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-responsive-modal/styles.css';
import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Button } from '@mui/material';
import { UserData } from '../../../helpers/common';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';

// Helper function to center aspect crop
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export default function ImgCrop(props) {
  const [uploadFromFiles, setUploadFromFiles] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [selectOnBrowse, setSelectOnBrowse] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [userId, setUserId] = useState(null);
  const [jwtToken, setJwtToken] = useState("");
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(undefined);
  const [profileImage, setProfileImage] = useState();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
  const IMAGE_URL2 = process.env.REACT_APP_FILE_MODULE_URL;

  const hasMounted = useRef(false); // Ref to track initial render

  useEffect(() => {
    const users = UserData();
    setUserId(users._id);
    setJwtToken(users.token);
    if (props) {
      let fileS3Name = props.s3File.fileNameInS3;
      const url = `${IMAGE_URL2}/${users?._id}/Images/${fileS3Name}?jwt=${users?.token}`;

      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      request.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function (e) {
          setImgSrc(e.target.result);
        };
      };
      request.send();
    }
    setTimeout(() => {
      if (props.uploadImage) {
        setCrop(undefined);
        const reader = new FileReader();

        reader.addEventListener('load', () => setImgSrc(reader.result || ''));
        reader.readAsDataURL(props.uploadImage);
      }
    }, 500);
  }, [props.uploadImage]);

  useEffect(() => {
    if (hasMounted.current) { // Only run if not the initial render
      const users = UserData();
      setUserId(users._id);
      setJwtToken(users.token);
      var request = new XMLHttpRequest();
      request.open('GET', imageUrl, true);
      request.responseType = 'blob';
      request.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function (e) {
          setImgSrc(e.target.result);
        };
      };
      request.send();
    } else {
      hasMounted.current = true; // Set the ref to true after the initial render
    }
  }, [imageUrl]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const uploadFromDrive = () => {
    setUploadFromFiles(true);
    setButtonState(false);
  };

  const handleCloseFileModulePopup = () => {
    setUploadFromFiles(false);
    setButtonState(true);
  };

  const handleChoosedFile = (file) => {
    props.handleFileChosen(file);
    setSelectOnBrowse(true);
    setUploadFromFiles(false);
    setImageUrl(IMAGE_URL2 + '/' + userId + '/Images' + '/' + file?.fileNameInS3 + '?jwt=' + jwtToken);
    convertCanvasToImage();
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const initialCrop = centerAspectCrop(width, height, 16 / 16);
    setCrop(initialCrop);
    setCompletedCrop(initialCrop); // Set the initial crop as completed crop
    setProfileImage(e.target);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        );
      }
      convertCanvasToImage();
    },
    100,
    [completedCrop],
  );

  function convertCanvasToImage() {
    let canvas = document.getElementById("canvas");
    let image = new Image();
    image.src = canvas?.toDataURL();
    const f = image.src.substring('data:image/'.length, image.src.indexOf(';base64'));
    const myFileName = "previewFile" + f;
    dataURLtoFile(image.src, `cropped.${f}`);
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl?.split(',');
    if (arr.length > 1) {
      let mime = arr[0]?.match(/:(.*?);/) !== null && arr[0]?.match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr?.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let croppedImage = new File([u8arr], filename, { type: mime });
      props.profilePic(croppedImage);
    }
  }

  function convertBase64(base64Data, filename) {
    var element = document.createElement('a');
    element.setAttribute('href', base64Data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const chooseProfileImageClick = () => {
    imgRef.current.click();
  };

  return (
    <Grid size={{xs: 12,}} pb={4}>
      <Grid container spacing={1} rowSpacing={3} alignItems='center' justifyContent={'center'}>
        <Grid size={{xs: 12, lg:"grow"}}>
          <Stack alignItems={'center'} justifyContent={'center'} spacing={3}>
            {Boolean(imgSrc) && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1 / 1}
              >
                <img
                  style={{
                    maxHeight: '200px',
                    maxWidth: '200px',
                  }}
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={(e) => onImageLoad(e)}
                />
              </ReactCrop>
            )}
          </Stack>
        </Grid>
        {Boolean(completedCrop) && (
          <Grid size={{xs: 12, lg:6}} >
            <Stack alignItems={'center'} justifyContent={'center'} spacing={3}>
              <canvas
                id="canvas"
                name="profileImage"
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'cover',
                  width: completedCrop.width,
                  height: completedCrop.height,
                  margin: "0px 28px"
                }}
              />
              <Box display={{ xs: 'block', lg: 'none' }} className="paraghraph bold text-primary-light">{props.langState.audienceTab?.preview}</Box>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Box display={{ xs: 'none', lg: 'flex' }} component={Grid} container mt={1} spacing={1} alignItems='start' justifyContent={'space-between'}>
        <Grid size={{xs: 6,}}>
          <Box>
            <Stack alignItems={'center'} justifyContent={'center'} spacing={1}>
              <span onClick={uploadFromDrive} class="btn btn-secondary btn-file" style={{ marginLeft: "20px", backgroundColor: "transparent", color: "#5141E7" }}>
                {props.langState.audienceTab?.browse}
              </span>
              <Box className="paraghraph text-primary-light">{props.langState.audienceTab?.maxImgSize}</Box>
            </Stack>
          </Box>
        </Grid>
        <Grid size={{xs: 6,}} display='flex' justifyContent={'center'}>
          <Box className="paraghraph bold text-primary-light">{props.langState.audienceTab?.preview}</Box>
        </Grid>
      </Box>
      <UploadFromFilesPopUp
        uploadFromFiles={uploadFromFiles}
        buttonState={buttonState}
        handleCloseUploadFromFileModule={handleCloseFileModulePopup}
        handleChoosedFile={handleChoosedFile}
      />
    </Grid>
  );
}
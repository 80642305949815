import React, { Component } from 'react'
import '../../sass/main.scss'
import EditingBack from '../../assets/images/backPage.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import footerLogo from '../../assets/images/footer__logo.png'
import { Button } from '@mui/material'
import { createNotification, redirectTo } from '../../helpers'
import EmailEditor from 'react-email-editor'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { NotificationContainer } from 'react-notifications'
import { emailTemplateJson } from '../CreateEvent/sampleEvent'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import PageToolBar from '../../Components/PageToolBar'
import { createOrUpdateRegForm } from 'ReduxStore/API'
import { getUnLayerConfig } from './config'

class EditTemplateDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      json: null,
      html: null,
    }
  }

  async componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_URL
    const {
      match: {
        params: { eventId },
      },
    } = this.props
    window.unlayer.init(getUnLayerConfig(apiUrl, eventId))
    const setState = this.setState.bind(this)
    const result = await createOrUpdateRegForm('get', eventId)
    const task_data = get(result, 'data.data.formdata')
    this.setState({ ...task_data[0] })
    window.unlayer.loadDesign(task_data[0].json)
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design
        const html = data.html
        setState(() => ({ json, html }))
      })
    })
  }

  onSaveFn = async () => {
    const {
      match: {
        params: { eventId },
      },
    } = this.props
    const { html, json } = this.state
    if (!html) {
      createNotification('error', 'There should be at least one field in form.')
      return
    }
    const result = await createOrUpdateRegForm('post', eventId, {
      formdata: { json, html },
    })
    if (result.data.success) {
      createNotification('success', result.data.message)
    } else {
      createNotification('error', result.data.message)
    }
  }

  onLoad = (templateJson) => {
    if (this.emailEditorRef) {
      this.emailEditorRef.editor.loadDesign(templateJson)
    } else {
      setTimeout(() => {
        if (
          this.emailEditorRef &&
          this.emailEditorRef.editor &&
          this.emailEditorRef.editor.loadDesign
        ) {
          this.emailEditorRef.editor.loadDesign(templateJson)
        } else {
          setTimeout(() => {
            if (
              this.emailEditorRef &&
              this.emailEditorRef.editor &&
              this.emailEditorRef.editor.loadDesign
            ) {
              this.emailEditorRef.editor.loadDesign(templateJson)
            }
          }, 3000)
        }
      }, 3000)
    }
  }

  render() {
    const {
      pageTitle,
      eventId,
      loading,
      sample,
      eventDomain,
      eventName,
      location,
      eventStartDate,
      eventEndDate,
    } = this.state || {}
    return (
      <div
        id="regFormEditor"
        className="editingPageWrapper"
        style={{ height: '100vh' }}
      >
        <PageToolBar
          onSaveFn={this.onSaveFn} 
          showPreviewOption={false}
        />
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateEvent: Actions.updateEventsRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(EditTemplateDesign)

import React, { Component } from 'react'
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link} from 'react-router-dom'
import TamplateThum3 from '../../../../assets/images/tamplate-thum3.png';
import { isValid } from 'date-fns';
import { createNotification } from '../../../../helpers';
import { connect } from 'react-redux'
import withCustomRouter from '../../../../Common/withCustomRouter'; 


class EditCampaignContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subjectLine: '',
            senderName: '',
            replyToEmail: '',
            errors: {},
        }
    }

    componentDidMount(prevProps, prevState) {

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.projects?.getProjectSuccess && !prevProps.projects?.getProjectSuccess) {
            this.setState({
                senderName: this.props.projects?.project?.emailSenderName,
                replyToEmail: this.props.projects?.project?.replyToEmail
            })
        }
        if (this.props?.campaignDetails !== prevProps.campaignDetails) {
            if (this.props?.campaignDetails?.senderName !== undefined && this.props?.campaignDetails?.senderName !== '') {
                this.setState({
                    subjectLine: this.props?.campaignDetails?.subjectLine,
                    senderName: this.props?.campaignDetails?.senderName,
                    replyToEmail: this.props?.campaignDetails?.replyToEmail,
                })
            }
        }

        if (this.state !== prevState) {
            this.props.getDataFromEditContent(this.state)
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        const { subjectLine, senderName, replyToEmail } = this.state
        return (
            <Grid size={{xs: 12, md:6, lg:4}}>
                <Box sx={{ border: '1px solid #EDECF5', borderRadius: '8px', backgroundColor: '#fff', height: '100%', }}>
                    <Box px={3} py={1.8} sx={{ borderBottom: '1px solid #EDECF5' }}>
                        <span className='paragraph bold'>{this.props.users.languageStateForRedux?.formfields?.campaigns?.campaignContent}</span>
                    </Box>
                    <Box component={'form'} px={3} py={4}>
                        <Box component={Grid} spacing={4} container className="inputField">
                            <Grid size={{xs: 12,}}>
                                <label>{this.props.users.languageStateForRedux?.formfields?.campaigns?.subLine}<span className='required'>*</span></label>
                                <input type="text" class="form-control small" placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterSub} name="subjectLine" value={subjectLine} onChange={this.handleChange} />
                                <span className="error_mesage required"> {this.props.errors.subjectLine} </span>
                            </Grid>
                            <Grid size={{xs: 12,}}>
                                <label>{this.props.users.languageStateForRedux?.formfields?.meetings?.senderName} <span className='required'>*</span></label>
                                <input type="text" class="form-control small" placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterName} name="senderName" value={senderName} onChange={this.handleChange} />
                                <span className="error_mesage required"> {this.props.errors.senderName} </span>
                            </Grid>
                            <Grid size={{xs: 12,}}>
                                <label>{this.props.users.languageStateForRedux?.formfields?.meetings?.replytoEmail} <span className='required'>*</span></label>
                                <input type="text" class="form-control small" placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterRepEmail} name="replyToEmail" value={replyToEmail} onChange={this.handleChange} />
                                <span className="error_mesage required"> {this.props.errors.replyToEmail} </span>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({

    projects: state.Projects,
    events: state.Events,
    users: state.Users,
})


export default connect(mapStateToProps, null)(withCustomRouter(EditCampaignContent))

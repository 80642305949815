import React, { useEffect, useState } from 'react';
import CustomSwitch from 'Components/customSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { getProjectIdFromUrl } from 'helpers/common';
import Loader from 'Components/Loader/loader';


function CustomSwitchForGoogleManagement({ groupId, showToGroup, setToShowGroupIds, toShowGroupIds, setToNotShowGroupIds, toNotShowGroupIds, attendeesGroupName, onChange, checked, ...props }) {
    const dispatch = useDispatch();
    const updateGoogleManagementListSuccess = useSelector(store => store.GoogleWorkspace.updateGoogleManagementListSuccess)
    const [switchCheck, setSwitchCheck] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setSwitchCheck(checked);
    }, [checked]);

    const handleChange = (e) => {
        onChange(e.target.checked);
        if (e.target.checked) {
            checkIfGroupNameAlreadyExistInManuallyAddedGroups();
        }
        setSwitchCheck(checked);
        const projectId = getProjectIdFromUrl();
        const data = {
            projectId,
            groupId,
            show: !checked
        };
        dispatch(Actions.updateGoogleManagementListRequest(data));
        setLoading(true);
    };

    useEffect(() => {
      if(updateGoogleManagementListSuccess){
        setLoading(false)
      }
    }, [updateGoogleManagementListSuccess])
    

    const checkIfGroupNameAlreadyExistInManuallyAddedGroups = () => {
        try {
            const projectId = getProjectIdFromUrl();
            const body = {
                projectId,
                attendeesGroupName,
                groupId
            };
            ;
            dispatch(Actions.checkWhetherGroupNameExistInIllumeetRequest(body));

        } catch (error) {
            return error;
        }
    };

    return (
        <>
            <CustomSwitch color={'primary'} onChange={handleChange} checked={switchCheck} />
            {loading && <Loader />}
        </>
    );
}

export default CustomSwitchForGoogleManagement;
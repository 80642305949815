//@ts-nocheck
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Box } from '@mui/material';
import { ContactState } from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { createNotification } from '../../../../helpers';
import { getSystemLevelPermissions } from '../../../../helpers/common';
import { getLinkedAccountDetails } from 'ReduxStore/API/index';
import { CustomFields } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import OpportunityAccountsCustomFieldsAPI from 'ReduxStore/API/opportunity/opportunityCustomFieldsAPI';
import { CustomField } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import Loader from 'Components/Loader/loader';
import { useNavigate, useLocation } from 'react-router-dom';




type Props = {
  contactId: string;
  reqFrom: string;
  handleTabChangeFromMainLayout: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;

};

function LinkedOpportunities({ contactId, handleTabChangeFromMainLayout, reqFrom }: Props) {
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const { isLoading: isContactsLoading, openedContact } = useSelector(
    (state: any) => state.OpportunityContacts,
  ) as ContactState;
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string>('opportunity.name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [allowed, setAllowed] = useState<any>({});
  const [linkedAccountDetails, setLinkedAccountDetails] = useState<any>([]);
  const [loading, setLoding] = useState<boolean>(false);
  const [opportunityCustomFields, setOpportunityCustomFields] =
    useState<CustomFields>({} as CustomFields);


  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoding(true);
        setAllowed(getSystemLevelPermissions()); // Set allowed permissions
        const res = await getLinkedAccountDetails(contactId, reqFrom); // Fetch linked account details
        if (res?.data?.linkedData) { // Check if the response contains linkedData
          setLinkedAccountDetails(res.data.linkedData); // Set the linked account details in state
          setLoding(false);
        }

        fetchAccountCustomField(); // Fetch account custom fields
      } catch (error) {
        console.error("Error fetching linked account details:", error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [contactId, reqFrom]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [sortBy, sortOrder]);

  const fetchAccountCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'opportunity',
        from: 'viewAccount',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setOpportunityCustomFields(res.data.customFields);

    } catch (error) {
      console.log(error);
    }
  };

  const fetchContact = async (contactId: string) => {
    const params = {
      id: contactId,
      sortOpportunityBy: sortBy,
      sortOpportunityOrder: sortOrder,
    };
    dispatch(Actions.fetchOpportunityContactRequest(params));
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Box>
      <TableContainer className="list-table">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell
                className={
                  'long-text sticky-cell ' +
                  (sortBy === 'opportunity.name'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('opportunity.name')}
              >
                Opportunity Name
              </TableCell>
              <TableCell
                className={
                  'text' +
                  (sortBy === 'contactDataTypeCf.fieldName'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('contactDataTypeCf.fieldName')}
              >
                Contact Type
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(linkedAccountDetails) &&
              linkedAccountDetails.length > 0 ?
              linkedAccountDetails.map((opportunity, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className="long-text sticky-cell link" onClick={(e) => {
                      if (allowed['viewOpportunity']) {
                        if (opportunity.uniqueEijentId) {
                          navigate(`/opportunity/opportunityview/${opportunity.uniqueEijentId}`);
                        }
                      } else {
                        createNotification('error', "Sorry! You don't have permission to view this opportunity");
                      }
                    }}>
                      {' '}
                      {opportunity.name}
                    </TableCell>

                    <TableCell className="text">
                      {opportunity.contactType}
                    </TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell colSpan={2} align='center'>
                  No Records
                </TableCell>
              </TableRow>

            }
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <Loader />}
    </Box>
  );
}

export default LinkedOpportunities;

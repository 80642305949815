//@ts-nocheck
import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { Modal } from 'react-responsive-modal';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { ReactComponent as OpportunitiesIcon } from 'assets/images/icons/import-opportunities.svg';
import { ReactComponent as ContactsIcon } from 'assets/images/icons/import-contacts.svg';
import { ReactComponent as AccountsIcon } from 'assets/images/icons/import-accounts.svg';
import ImportContacts from './ImportContacts';
import {
  ImportWizardContext,
  InitialStateType,
} from 'Contexts/ImportWizardContextProvider';

type WizardOptions =  'Opportunities' | 'Accounts' | 'Contacts';
const wizardOptions = ['Opportunities', 'Accounts', 'Contacts'];

const getIcon = (option: WizardOptions) => {
  switch (option) {
    case 'Opportunities':
      return <OpportunitiesIcon />;
    case 'Accounts':
      return <AccountsIcon />;
    case 'Contacts':
      return <ContactsIcon />;
    default:
      break;
  }
};



function ImportWizardModal() {
  const importWizardContext = useContext(ImportWizardContext);
  if (!importWizardContext) {
    throw new Error(
      'ImportWizardModal must be used within an ImportWizardContextProvider',
    );
  }
  const { openedModal, dispatchOpenModal, handleSetImportfor } = importWizardContext;
  const { openImportWizard } = openedModal;

  const handleClickOpenImportContacts = (option: WizardOptions) => {
    if (option === 'Contacts') {
      handleSetImportfor('Contacts');
      dispatchOpenModal('OPEN_CONTACT_PAGE');
    }
    if (option === 'Accounts') {
      handleSetImportfor('Accounts');
      dispatchOpenModal('OPEN_CONTACT_PAGE');
    }
    if (option === 'Opportunities') {
      handleSetImportfor('Opportunities');
      dispatchOpenModal('OPEN_CONTACT_PAGE');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={openImportWizard}
        onClose={() => dispatchOpenModal('CLOSE_THE_WIZARD')}
        closeOnOverlayClick={false}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal wider',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid size={{xs: 12,}} pb={4}>
            <Box component="p" mb={1} className="subsecondary">
              Import Setup
            </Box>
            <Box className="small-subtitle">
              Add smart group by applying condition and value
            </Box>
          </Grid>
        </Grid>

        <Grid spacing={1} container className="modal-wrapper" >
          {wizardOptions.map((option) => (
            <Grid size={{xs: 4,}} key={option}>
              <Stack
                spacing={2}
                alignItems={'center'}
                p={1.25}
                pb={2}
                borderRadius={'10px'}
                bgcolor={'#F5F5F5'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickOpenImportContacts(option as WizardOptions);
                }}
              >
                <Box width={'100%'} bgcolor={'#FFFFFF'} p={3} display={'flex'} justifyContent={'center'}>
                  {/* <img
                    src={getIcon(option as WizardOptions)}
                    alt="import contacts"
                  /> */}

                  {getIcon(option as WizardOptions)}
                </Box>
                <Box className={'subtitle'}>{option}</Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Modal>

      <ImportContacts />
    </ThemeProvider>
  );
}

export default ImportWizardModal;

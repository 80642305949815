//@ts-nocheck
import { createReducer } from 'reduxsauce';
import { Constants } from '../../Actions';
import TawkTo from 'assets/images/icons/app-tawkTo.svg';
import GoogleWorkspace from 'assets/images/icons/Google_G_Logo.svg';
export interface AppsState {
  apps: App[];
  success: boolean;
  message: string;
  loading: boolean;
  actionType: string;
  integrations: any[];
  integration: any;
  googleOAuthUrl: string;
}
export interface App {
  integrationId: string;
  name: string;
  type: string;
  icon: string;
  description: string;
  isActive: boolean;
}
const _apps: App[] = [
  {
    integrationId: '',
    name: 'tawkto',
    type: 'Tawk.To',
    icon: TawkTo,
    description: `  Message your customers, <br />
    they'll love you for it.`,
    isActive: false,
  },
  {
    integrationId: '',
    name: 'googleWorkspace',
    type: 'Google Workspace',
    icon: GoogleWorkspace,
    description: `  Sync users easily and keep them <br />
    updated straight from Google Workspace`,
    isActive: false,
  },
];
const INITIAL_STATE: AppsState = {
  apps: _apps,
  integrations: [],
  loading: false,
  integration: null,
  success: false,
  message: '',
  actionType: '',
  googleOAuthUrl: '',
};
//#region Get Properties
// const tawkToGetProperties = (state: AppsState = INITIAL_STATE): AppsState => {
//   return {
//     ...state,
//     success: false,
//     message: '',
//   }
// }

// const tawkToGetPropertiesSuccess = (
//   state: AppsState = INITIAL_STATE,
//   action: any,
// ): AppsState => {
//   return {
//     ...state,
//     success: true,
//     //   apps: action.payload.apps,
//     message: action.payload.message,
//   }
// }

// const tawkToGetPropertiesFailure = (
//   state: AppsState = INITIAL_STATE,
//   action: any,
// ): AppsState => {
//   return { ...state, success: false, message: action.error }
// }
//#endregion

//#region Create Property
const tawkToCreateProperty = (state: AppsState = INITIAL_STATE): AppsState => {
  return {
    ...state,
    success: false,
    loading: true,
    message: '',
  };
};
const tawkToCreatePropertySuccess = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  //console.log('REFUCES RESPOSNE', action)
  const { integration, message } = action;
  const apps = state.apps.map((a: App) => {
    if (a.type === integration.type) {
      a.isActive = integration.isActive;
      a.integrationId = integration._id;
    }
    return a;
  });
  return {
    ...state,
    success: true,
    loading: false,
    actionType: action.type,
    apps,
    message: message,
  };
};

const tawkToCreatePropertyFailure = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

//#region App Integrations
const getAppIntegrations = (state: AppsState = INITIAL_STATE): AppsState => {
  return {
    ...state,
    success: false,
    loading: true,
    googleOAuthUrl: '',
    message: '',
  };
};

const getAppIntegrationsSuccess = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  for (const app of state.apps) {
    const integ = action.integrations.find(
      (i: any) => i._id === app.integrationId || i.type === app.type,
    );
    if (integ) {
      app.isActive = integ.isActive;
      app.integrationId = integ._id;
    } else {
      app.isActive = false;
      app.integrationId = '';
    }
  }

  return {
    ...state,
    success: true,
    apps: state.apps,
    actionType: action.type,
    loading: false,
    message: action.message,
  };
};

const getAppIntegrationsFailure = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

//#region Google OAuth Url
const connectGoogleWorkspace = (
  state: AppsState = INITIAL_STATE,
): AppsState => {
  return {
    ...state,
    success: false,
    loading: true,
    message: '',
  };
};

const connectGoogleWorkspaceSuccess = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  return {
    ...state,
    success: true,
    loading: false,
    actionType: action.type,
    googleOAuthUrl: action.url,
  };
};

const connectGoogleWorkspaceFailure = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

//#region Google OAuth Permissions
const authorizeGoogleWorkspace = (
  state: AppsState = INITIAL_STATE,
): AppsState => {
  return {
    ...state,
    success: false,
    loading: true,
    message: '',
  };
};

const authorizeGoogleWorkspaceSuccess = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  return {
    ...state,
    success: true,
    loading: false,
    actionType: action.type,
    googleOAuthUrl: action.url,
  };
};

const authorizeGoogleWorkspaceFailure = (
  state: AppsState = INITIAL_STATE,
  action: any,
): AppsState => {
  return { ...state, success: false, loading: false, message: action.error };
};
//#endregion

const HANDLERS = {
  // [Constants.TAWK_TO_GET_PROPERTIES]: tawkToGetProperties,
  // [Constants.TAWK_TO_GET_PROPERTIES_SUCCESS]: tawkToGetPropertiesSuccess,
  // [Constants.TAWK_TO_GET_PROPERTIES_FAILURE]: tawkToGetPropertiesFailure,

  [Constants.GET_APP_INTEGRATIONS]: getAppIntegrations,
  [Constants.GET_APP_INTEGRATIONS_SUCCESS]: getAppIntegrationsSuccess,
  [Constants.GET_APP_INTEGRATIONS_FAILURE]: getAppIntegrationsFailure,

  [Constants.TAWK_TO_CREATE_PROPERTY]: tawkToCreateProperty,
  [Constants.TAWK_TO_CREATE_PROPERTY_SUCCESS]: tawkToCreatePropertySuccess,
  [Constants.TAWK_TO_CREATE_PROPERTY_FAILURE]: tawkToCreatePropertyFailure,

  [Constants.CONNECT_GOOGLE_WORKSPACE]: connectGoogleWorkspace,
  [Constants.CONNECT_GOOGLE_WORKSPACE_SUCCESS]: connectGoogleWorkspaceSuccess,
  [Constants.CONNECT_GOOGLE_WORKSPACE_FAILURE]: connectGoogleWorkspaceFailure,

  [Constants.AUTHORIZE_GOOGLE_WORKSPACE]: authorizeGoogleWorkspace,
  [Constants.AUTHORIZE_GOOGLE_WORKSPACE_SUCCESS]:
    authorizeGoogleWorkspaceSuccess,
  [Constants.AUTHORIZE_GOOGLE_WORKSPACE_FAILURE]:
    authorizeGoogleWorkspaceFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);

import { call, put } from 'redux-saga/effects';
import IntegrationAPI from 'ReduxStore/API/integrationAPI';
import { Actions } from '../../Actions';

function* getAppIntegrations(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.getAppIntegrations, action.eventId);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getAppIntegrationsSuccess(
          response.data.integrations,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.getAppIntegrationsFailure(response.data.message));
    }
  } else {
    yield put(Actions.getAppIntegrationsFailure(response.data.message));
  }
}


function* connectGoogleWorkspace(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.connectGoogleWorkspace, action.integration);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.connectGoogleWorkspaceSuccess(
          response.data.url,
        ),
      );
    } else {
      yield put(Actions.connectGoogleWorkspaceFailure(response.data.message));
    }
  } else {
    yield put(Actions.connectGoogleWorkspaceFailure(response.data.message));
  }
}

function* authorizeGoogleWorkspace(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.authorizeGoogleWorkspace, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.authorizeGoogleWorkspaceSuccess(
          response.data.url,
        ),
      );
    } else {
      yield put(Actions.authorizeGoogleWorkspaceFailure(response.data.message));
    }
  } else {
    yield put(Actions.authorizeGoogleWorkspaceFailure(response.data.message));
  }
}


function* getGoogleOrgUnits(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.getGoogleOrgUnits, action.params);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getGoogleOrgUnitsSuccess(
          response.data,
        ),
      );
    } else {
      yield put(Actions.getGoogleOrgUnitsFailure(response.data.message));
    }
  } else {
    yield put(Actions.getGoogleOrgUnitsFailure(response.data.message));
  }
}


function* getGroupsForGoogleWorkspaceRequest(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.getGoogleGroups, action.params);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getGroupsForGoogleWorkspaceSuccess(
          response.data.orgUnits,
        ),
      );
    } else {
      yield put(Actions.getGroupsForGoogleWorkspaceError(response.data.message));
    }
  } else {
    yield put(Actions.getGroupsForGoogleWorkspaceError(response.data.message));
  }
}


function* getOrgUnitsManually(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.manuallySyncGoogleOrgUnits, action.params);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getOrgUnitsManuallySuccess(
          response.data.orgUnits,
        ),
      );
    } else {
      yield put(Actions.getOrgUnitsManuallyFailure(response.data.message));
    }
  } else {
    yield put(Actions.getOrgUnitsManuallyFailure(response.data.message));
  }
}



function* saveGoogleWorksapceInfo(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.saveGoogleWorksapceInfo, action.data);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.saveGoogleWorkspaceInfoSuccess(
          response.data,
        ),
      );
    } else {
      yield put(Actions.saveGoogleWorkspaceInfoFailure(response.data.message));
    }
  } else {
    yield put(Actions.saveGoogleWorkspaceInfoFailure(response.data.message));
  }
}


function* syncUsers(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.syncUsers, action.params);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.syncUsersSuccess(
          response.data,
        ),
      );
    } else {
      yield put(Actions.syncUsersFailure(response.data.message));
    }
  } else {
    yield put(Actions.syncUsersFailure(response.data.message));
  }
}

function* getGoogleUsers(action) {
  const integrationApi = new IntegrationAPI();
  const response = yield call(integrationApi.getGoogleUsers, action.params);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.getGoogleUsersSuccess(
          response.data,
        ),
      );
    } else {
      yield put(Actions.getGoogleUsersFailure(response.data.message));
    }
  } else {
    yield put(Actions.getGoogleUsersFailure(response.data.message));
  }
}

const integrationSaga = {
  getAppIntegrations, connectGoogleWorkspace, authorizeGoogleWorkspace,
  getGoogleOrgUnits, saveGoogleWorksapceInfo,
  getOrgUnitsManually, getGroupsForGoogleWorkspaceRequest,
  syncUsers,
  getGoogleUsers
};
export default integrationSaga;

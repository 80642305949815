import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'

const INITIAL_STATE = {
    error: false,
    listError:false,
    message: null,
    totalResults:0,
    meetingList:[],
    listAttendeesNotAddedToMeetings:[],
    listMeetingSuccess:false,
    addMeetingSuccess:false,
    fromEditor:false,
    deleteMeetingSuccess:false,
    updateMeetingSuccess:false,
    listAttendeesNotAddedToMeetingSuccess:false,
    replicateMeetingSuccess:false,
    attendeeGroupsForMeetingSuccess:false,
    audienceForMeetingSuccess:false,
    searchForMeetingSuccess:false,
    getAudienceContactSuccess:false,
    attendeesGroups:[],
    audienceData:[],
    mergeArray:[],
    contact:[],
    totalAttendees:0,
    totalAudience:0,
    totalAttendeeGroups:0,
    getMeetingTemplateSuccess:false,
    meetingTemplate:[],
    eventData:{},
    checkMeetingTitleSuccess: false,
    checkMeetingTitleError:false,
    listAllMeetingsSuccess: false,
    allMeetings:[],
    checkDisabledAttendeeSuccess:false,
    checkDisabledAttendeeError:false,
    listAllUser:[],
    listAllUserSuccess:false,
    updateMeetingData:[],
    updateSlotsSuccess:false,
    slotsError:false,
    getSlots:[],

}

const listMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listMeetingSuccess: false,
        updateSlotsSuccess:false,
        error: false,
        listError:false,
        message: null,
        meetingList: [],
        totalResults:0,
    }
}

const listMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listMeetingSuccess: true,
        updateSlotsSuccess:false,
        error: false,
        listError:false,
        message: action.message,
        meetingList: action.meetingList,
        totalResults: action.totalResults,
        filterByStatus:action.filterByStatus,
    }
}
const listMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listMeetingSuccess: false,
        updateSlotsSuccess:false,
        error: true,
        listError:false,
        message: action.error,
    }
}


const listAttendeesNotAddedToMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAttendeesNotAddedToMeetingSuccess:false,
        listMeetingSuccess:false,
        error: false,
        listError:false,
        message: null,
        meetingList: [],
        totalAttendees:0,
    }
}

const listAttendeesNotAddedToMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAttendeesNotAddedToMeetingSuccess: true,
        listMeetingSuccess:false,
        error: false,
        listError:false,
        message: action.message,
        listAttendeesNotAddedToMeetings: action.data,
        totalAttendees:action.totalAttendees
    }
}
const listAttendeesNotAddedToMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        listAttendeesNotAddedToMeetingSuccess: false,
        listMeetingSuccess:false,
        listAttendeesNotAddedToMeetings:[],
        error: true,
        listError:false,
        message: action.error,
        totalAttendees:0
    }
}

const addMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addMeetingSuccess: false,
        listMeetingSuccess:false,
        error: false,
        message: null,
        meetingList: [],
    }
}

const addMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addMeetingSuccess: true,
        listMeetingSuccess:false,
        error: false,
        message: action.message,
        meetingData:action.meeting
    }
}
const addMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        addMeetingSuccess: false,
        listMeetingSuccess:false,
        error: true,
        message: action.error,
    }
}


const replicateMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateMeetingSuccess: false,
        error: false,
        message: null,
        meetingList: [],
    }
}

const replicateMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateMeetingSuccess: true,
        error: false,
        message: action.message,
    }
}
const replicateMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        replicateMeetingSuccess: false,
        error: true,
        message: action.error,
    }
}

const handleNavigationFromEditorToCreateMeeting =(state = INITIAL_STATE, action) => {
    return {
        ...state,
        fromEditor:action.data
    }
}

const deleteMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: null,
        deleteMeetingSuccess: false,
        error: false,
    }
}

const deleteMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.message,
        deleteMeetingSuccess: true,
        error: false,
    }
}

const deleteMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        message: action.error,
        deleteMeetingSuccess: false,
        error: true,
    }
}

const updateMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateMeetingSuccess:false,
        addMeetingSuccess: false,
        listMeetingSuccess:false,
        error: false,
        message: null,
        meetingList: [],
        updateMeetingData:[]
    }
}

const updateMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateMeetingSuccess:true,
        addMeetingSuccess: false,
        listMeetingSuccess:false,
        error: false,
        message: action.message,
        updateMeetingData: action.updateMeetingData,
    }
}
const updateMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        updateMeetingSuccess:false,
        addMeetingSuccess: false,
        listMeetingSuccess:false,
        error: true,
        message: action.error,
        updateMeetingData:[]
    }
}

const attendeeGroupsForMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        attendeeGroupsForMeetingSuccess:false,
        error: false,
        message: null,
        attendeesGroups:[],
        totalAttendeeGroups:0
    }
}

const attendeeGroupsForMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        attendeeGroupsForMeetingSuccess: true,
        error: false,
        message: action.message,
        attendeesGroups: action.attendeesGroups,
        totalAttendeeGroups:action.totalAttendeeGroups
    }
}

const attendeeGroupsForMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        attendeeGroupsForMeetingSuccess: false,
        error: true,
        message: action.error,
        attendeesGroups:[],
        totalAttendeeGroups:0
    }
}

const audienceForMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        audienceForMeetingSuccess:false,
        error: false,
        message: null,
        audienceData:[]
    }
}

const audienceForMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        audienceForMeetingSuccess: true,
        error: false,
        message: action.message,
        audienceData: action.audienceData,
        totalAudience:action.totalAudience
    }
}

const audienceForMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        audienceForMeetingSuccess: false,
        error: true,
        message: action.error,
        audienceData:[],
        totalAudience:0
    }
}

const searchForMeetingRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchForMeetingSuccess:false,
        error: false,
        message: null,
        mergeArray:[]
    }
}

const searchForMeetingSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchForMeetingSuccess: true,
        error: false,
        message: action.message,
        mergeArray: action.mergeArray,
    }
}

const searchForMeetingError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        searchForMeetingSuccess: false,
        error: false,
        message: action.error,
        mergeArray:[]
    }
}

const getAudienceContactRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAudienceContactSuccess:false,
        error: false,
        message: null,
        contact:[]
    }
}

const getAudienceContactSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAudienceContactSuccess: true,
        error: false,
        message: action.message,
        contact: action.contact,
    }
}

const getAudienceContactError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getAudienceContactSuccess: false,
        error: true,
        message: action.error,
        contact:[]
    }
}

const getMeetingTemplateRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getMeetingTemplateSuccess:false,
        error: false,
        message: null,
        meetingTemplate:[],
        eventData:{}
    }
}

const getMeetingTemplateSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getMeetingTemplateSuccess: true,
        error: false,
        meetingTemplate: action.meetingTemplate,
        eventData: action.eventData,
        message: action.message,
    }
}

const getMeetingTemplateError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        getMeetingTemplateSuccess: false,
        error: true,
        message: action.error,
        meetingTemplate:[]
    }
}


const checkMeetingTitleRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkMeetingTitleSuccess:false,
        checkMeetingTitleError:false,
        error: false,
        message: null,
    }
}

const checkMeetingTitleSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkMeetingTitleSuccess: true,
        checkMeetingTitleError:false,
        error: false,
        message: action.message,
    }
}

const checkMeetingTitleError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkMeetingTitleSuccess: false,
        checkMeetingTitleError:true,
        error: false,
        message: action.message,
    }
}

const listAllMeetingsRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: null,
        listAllMeetingsSuccess: false,
        allMeetings: []
    }
}

const listAllMeetingsSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: false,
        message: action.message,
        listAllMeetingsSuccess: true,
        allMeetings: action.meetings
    }
}

const listAllMeetingsError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        error: true,
        message: action.error,
        listAllMeetingsSuccess: false
    }
}

const checkDisabledAttendeeRequest = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkDisabledAttendeeSuccess:false,
        checkDisabledAttendeeError:false,
        error: false,
        message: null,
    }
}

const checkDisabledAttendeeSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkDisabledAttendeeSuccess: true,
        checkDisabledAttendeeError:false,
        error: false,
        message: action.message,
    }
}

const checkDisabledAttendeeError = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        checkDisabledAttendeeSuccess: false,
        checkDisabledAttendeeError:true,
        error: false,
        message: action.error,
    }
}

const listAllUserRequest = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      listAllUserSuccess: false,
      error: false,
    }
  }
  const listAllUserSuccess = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      listAllUserSuccess: true,
      error: false,
      listAllUser: action.listAllUser,
    }
  }
  
  const listAllUserError = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      listAllUserSuccess: false,
      error: true,
      message: action.error,
    }
  }

  const updateSlotsRequest = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      updateSlotsSuccess: false,
      slotsError: false,
    }
  }
  const updateSlotsSuccess = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      updateSlotsSuccess: true,
      message: action.message,
      slotsError: false,
    }
  }
  
  const updateSlotsError = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      updateSlotsSuccess: false,
      slotsError: true,
      message: action.error,
    }
  }

  const getSlotsRequest = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      getSlotsSuccess: false,
      updateSlotsSuccess: false,
      slotsError: false,
      getSlots:[]
    }
  }
  const getSlotsSuccess = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      getSlotsSuccess: true,
      updateSlotsSuccess: false,
      message: action.message,
      slotsError: false,
      getSlots:action.getSlots
    }
  }
  
  const getSlotsError = (state = INITIAL_STATE, action) => {
    return { 
      ...state, 
      getSlotsSuccess: false,
      slotsError: true,
      updateSlotsSuccess: false,
      message: action.error,
      getSlots:[]
    }
  }

const HANDLERS = {
    [Constants.GET_SLOTS_REQUEST]: getSlotsRequest,
    [Constants.GET_SLOTS_SUCCESS]: getSlotsSuccess,
    [Constants.GET_SLOTS_ERROR]: getSlotsError,
    [Constants.UPDATE_SLOTS_REQUEST]: updateSlotsRequest,
    [Constants.UPDATE_SLOTS_SUCCESS]: updateSlotsSuccess,
    [Constants.UPDATE_SLOTS_ERROR]: updateSlotsError,
    [Constants.LIST_ALL_USER_REQUEST]: listAllUserRequest,
    [Constants.LIST_ALL_USER_SUCCESS]: listAllUserSuccess,
    [Constants.LIST_ALL_USER_ERROR]: listAllUserError,
    [Constants.CHECK_DISABLED_ATTENDEE_SUCCESS]: checkDisabledAttendeeSuccess,
    [Constants.CHECK_DISABLED_ATTENDEE_ERROR]: checkDisabledAttendeeError,
    [Constants.CHECK_DISABLED_ATTENDEE_REQUEST]: checkDisabledAttendeeRequest,
    [Constants.CHECK_MEETING_TITLE_SUCCESS]: checkMeetingTitleSuccess,
    [Constants.CHECK_MEETING_TITLE_ERROR]: checkMeetingTitleError,
    [Constants.CHECK_MEETING_TITLE_REQUEST]: checkMeetingTitleRequest,
    [Constants.GET_MEETING_TEMPLATE_SUCCESS]: getMeetingTemplateSuccess,
    [Constants.GET_MEETING_TEMPLATE_ERROR]: getMeetingTemplateError,
    [Constants.GET_MEETING_TEMPLATE_REQUEST]: getMeetingTemplateRequest,
    [Constants.GET_AUDIENCE_CONTACT_SUCCESS]: getAudienceContactSuccess,
    [Constants.GET_AUDIENCE_CONTACT_ERROR]: getAudienceContactError,
    [Constants.GET_AUDIENCE_CONTACT_REQUEST]: getAudienceContactRequest,
    [Constants.SEARCH_FOR_MEETING_SUCCESS]: searchForMeetingSuccess,
    [Constants.SEARCH_FOR_MEETING_ERROR]: searchForMeetingError,
    [Constants.SEARCH_FOR_MEETING_REQUEST]: searchForMeetingRequest,
    [Constants.AUDIENCE_FOR_MEETING_SUCCESS]: audienceForMeetingSuccess,
    [Constants.AUDIENCE_FOR_MEETING_ERROR]: audienceForMeetingError,
    [Constants.AUDIENCE_FOR_MEETING_REQUEST]: audienceForMeetingRequest,
    [Constants.ATTENDEE_GROUPS_FOR_MEETING_SUCCESS]: attendeeGroupsForMeetingSuccess,
    [Constants.ATTENDEE_GROUPS_FOR_MEETING_ERROR]: attendeeGroupsForMeetingError,
    [Constants.ATTENDEE_GROUPS_FOR_MEETING_REQUEST]: attendeeGroupsForMeetingRequest,
    [Constants.REPLICATE_MEETING_REQUEST]: replicateMeetingRequest,
    [Constants.REPLICATE_MEETING_SUCCESS]: replicateMeetingSuccess,
    [Constants.REPLICATE_MEETING_ERROR]: replicateMeetingError,
    [Constants.LIST_ATTENDEES_NOT_ADDED_TO_MEETING_SUCCESS]: listAttendeesNotAddedToMeetingSuccess,
    [Constants.LIST_ATTENDEES_NOT_ADDED_TO_MEETING_ERROR]: listAttendeesNotAddedToMeetingError,
    [Constants.LIST_ATTENDEES_NOT_ADDED_TO_MEETING_REQUEST]: listAttendeesNotAddedToMeetingRequest,
    [Constants.DELETE_MEETING_REQUEST]: deleteMeetingRequest,
    [Constants.DELETE_MEETING_SUCCESS]: deleteMeetingSuccess,
    [Constants.DELETE_MEETING_ERROR]: deleteMeetingError,
    [Constants.HANDLE_NAVIGATION_FROM_EDITOR_TO_CREATE_MEETING]: handleNavigationFromEditorToCreateMeeting,
    [Constants.LIST_MEETING_REQUEST]: listMeetingRequest,
    [Constants.LIST_MEETING_SUCCESS]: listMeetingSuccess,
    [Constants.LIST_MEETING_ERROR]: listMeetingError,
    [Constants.ADD_MEETING_REQUEST]: addMeetingRequest,
    [Constants.ADD_MEETING_SUCCESS]: addMeetingSuccess,
    [Constants.ADD_MEETING_ERROR]: addMeetingError,
    [Constants.UPDATE_MEETING_REQUEST]: updateMeetingRequest,
    [Constants.UPDATE_MEETING_SUCCESS]: updateMeetingSuccess,
    [Constants.UPDATE_MEETING_ERROR]: updateMeetingError,
    [Constants.LIST_ALL_MEETINGS_REQUEST]: listAllMeetingsRequest,
    [Constants.LIST_ALL_MEETINGS_SUCCESS]: listAllMeetingsSuccess,
    [Constants.LIST_ALL_MEETINGS_ERROR]: listAllMeetingsError,
}

export default createReducer(INITIAL_STATE, HANDLERS)

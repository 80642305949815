//@ts-nocheck
import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import { Box, Grid } from '@mui/material';
import DocIcon from '../../../../../src/assets/images/icons/Doc-icon.svg';
import sheetIcon from '../../../../../src/assets/images/icons/xls-icon.svg';
import VideoIcon from '../../../../../src/assets/images/icons/Video-icon.svg';
import pdfIcon from '../../../../../src/assets/images/icons/pdf-icon.svg';
import imgIcon from '../../../../../src/assets/images/icons/Image-icon.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddFile from '../../../../../src/assets/images/icons/attach_file_add.svg';
import Avatar from '@mui/material/Avatar';
import { theme } from '../../../../theme/theme';
import Button from '@mui/material/Button';
import {
  OpportunityCommon_State,
  CommentsAndAttachments as CommentsAndAttachmentsType,
  Attachment,
  CommentSection,
  ViewType
} from 'ReduxStore/Reducers/opportunity/opportunityCommon.reducer';
import { ChangeEvent, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Actions } from 'ReduxStore/Actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'Components/Loader/loader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import CloseIcon from '@mui/icons-material/Close';
import AddOrEditCommentsAndAttachments from './AddOrEditCommentsAndAttachments';
import { downloadFile, UserData } from 'helpers/common';
import { Url } from 'constants/constants';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '../../../../assets/images/icons/search.svg';
import CustomHTMLTooltip from 'Components/CustomHTMLTooltip';
import { AttachFileOutlined } from '@mui/icons-material';
import Attachments from './Attachments';

const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: '24px',
    height: '24px',
    borderRadius: 16,
    fontSize: '10px',
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
    padding: '12px 16px',
  },
};

type Props = {
  id: string | undefined;
  type: ViewType;
  section: CommentSection;
};

function CommentsAndAttachments({ id, type, section }: Props) {
  const dispatch = useDispatch();

  const {
    userDetails: loggedInUser
  } = useSelector(
    (state: any) => state.Users,
  );

  const {
    commentsAndAttachments: commentsAndAttachmentsData,
    fetchCommentAndAttachmentSuccess,
    createCommentAndAttachmentSuccess,
    updateCommentAndAttachmentSuccess,
    deleteCommentAndAttachmentSuccess,
    removeAttachmentSuccess,
    loading,
  } = useSelector(
    (state: any) => {
      return state.OpportunityCommon
    },
  ) as OpportunityCommon_State;

  const {
    message: xpUsersWebSocketMessage
  } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  const [commentsAndAttachments, setCommentsAndAttachments] = useState<
    CommentsAndAttachmentsType[]
  >([]);

  const [deleteCommentConfirmPopup, setDeleteCommentConfirmPopup] =
    useState<boolean>(false);
  const [commentIdToDelete, setCommentIdToDelete] = useState<string>();

  const [deleteAttachmentConfirmPopup, setDeleteAttachmentConfirmPopup] =
    useState<boolean>(false);
  const [attachmentIdToDelete, setAttachmentIdToDelete] = useState<{
    commentId: string;
    attachmentId: string;
  }>({
    commentId: '',
    attachmentId: '',
  });

  const [commentAndAttachmentToEdit, setCommentAndAttachmentToEdit] = useState<
    CommentsAndAttachmentsType | undefined
  >();

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [firstRender, setFirstRender] = useState<boolean>(true)

  const [searchText, setSearchText] = useState<string>("");
  
  useEffect(() => {    
    setFirstRender(false)
    setShowLoader(true)
    setCommentsAndAttachments([])
    if (id) {
      fetchCommentsAndAttachments(id);
    }
  }, []);
  
  useEffect(() => {
    if (id && xpUsersWebSocketMessage.message && xpUsersWebSocketMessage.type && xpUsersWebSocketMessage.id) {
      if (
        (
          xpUsersWebSocketMessage.message == "comment or attachments added" ||
          xpUsersWebSocketMessage.message == "comment or attachments updated" ||
          xpUsersWebSocketMessage.message == "attachment removed" ||
          xpUsersWebSocketMessage.message == "comment deleted"
        ) && 
        xpUsersWebSocketMessage.type == type && 
        xpUsersWebSocketMessage.id == id
      ) {
        fetchCommentsAndAttachments(id);
      }      
    }    
  }, [xpUsersWebSocketMessage]);

  const fetchCommentsAndAttachments = async (id: string) => {
    dispatch(Actions.fetchCommentAndAttachmentRequest({ id, type, searchText }));
  };

  useEffect(() => {
    if (
      fetchCommentAndAttachmentSuccess ||
      createCommentAndAttachmentSuccess ||
      updateCommentAndAttachmentSuccess ||
      deleteCommentAndAttachmentSuccess ||
      removeAttachmentSuccess
    ) {
      setCommentsAndAttachments(commentsAndAttachmentsData);
    }
  }, [
    fetchCommentAndAttachmentSuccess,
    createCommentAndAttachmentSuccess,
    updateCommentAndAttachmentSuccess,
    deleteCommentAndAttachmentSuccess,
    removeAttachmentSuccess,
  ]);

  useEffect(() => {
    if(!firstRender && fetchCommentAndAttachmentSuccess){
      setShowLoader(false)
    }
  }, [fetchCommentAndAttachmentSuccess]);

  useEffect(() => {
    if (updateCommentAndAttachmentSuccess) {
      cancelEditCommentAndAttachment();
    }
  }, [updateCommentAndAttachmentSuccess]);

  const cancelEditCommentAndAttachment = () => {
    setCommentAndAttachmentToEdit(undefined);
  };

  useEffect(() => {
    if (deleteCommentAndAttachmentSuccess) {
      closeDeleteCommentConfirmPopup();
    }
  }, [deleteCommentAndAttachmentSuccess]);

  useEffect(() => {
    if (removeAttachmentSuccess) {
      closeDeleteAttachmentConfirmPopup();
    }
  }, [removeAttachmentSuccess]);

  const openDeleteCommentConfirmPopup = (id: string) => {
    setCommentIdToDelete(id);
    setDeleteCommentConfirmPopup(true);
  };

  const closeDeleteCommentConfirmPopup = () => {
    setCommentIdToDelete('');
    setDeleteCommentConfirmPopup(false);
  };

  const deleteCommentAndAttachment = () => {
    dispatch(
      Actions.deleteCommentAndAttachmentRequest({
        id,
        commentsAndAttachmentsId: commentIdToDelete,
        type,
      }),
    );
  };

  const openDeleteAttachmentConfirmPopup = (
    commentId: string,
    attachmentId: string,
  ) => {
    setAttachmentIdToDelete({ commentId, attachmentId });
    setDeleteAttachmentConfirmPopup(true);
  };

  const closeDeleteAttachmentConfirmPopup = () => {
    setAttachmentIdToDelete({ commentId: '', attachmentId: '' });
    setDeleteAttachmentConfirmPopup(false);
  };

  const deleteAttachment = () => {
    dispatch(
      Actions.removeAttachmentRequest({
        id,
        type,
        commentId: attachmentIdToDelete.commentId,
        attachmentId: attachmentIdToDelete.attachmentId,
      }),
    );
  };

  const handleEditCommentAndAttachment = (data: CommentsAndAttachmentsType) => {
    setCommentAndAttachmentToEdit(data);
  };  

  const getIcon = (fileType: string, fileExtension: string) => {
    switch (fileType) {
      case "image":
        return imgIcon;
        break;

      case "video":
        return VideoIcon;
        break;

      case "txt":
        return DocIcon;
        break;

      case "application":
        switch (fileExtension) {
          case "pdf":
            return pdfIcon;
            break;

          case "xlsx":
          case "xls":
          case "sheet":
            return sheetIcon;
            break;

          default:
            return DocIcon;
            break;
        }
        break;

      default:
        return DocIcon;
        break;
    }
  };
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  });

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (id) {
      fetchCommentsAndAttachments(id);
    }
  };

  const getIconForNewAttachment = (type: string) => {
    if (type) {
      let fileTypeData = type.split('/');
      if (fileTypeData?.length == 2) {
        return getIcon(fileTypeData[0], fileTypeData[1]);
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return (
    <>
    {/* {console.log("loaderRef", loaderRef.current)} */}
      <Stack spacing={0} className="comments" justifyContent={section == 'attachments' ? 'start' : 'space-between'}>
        <Box className="comment-header">
          <Stack p={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box className={'subtitle-regular '}>
              {section == 'comments' ?
                <>
                  <Box component={'span'} mr={1}>
                    <CommentOutlinedIcon />
                  </Box>
                  <span>
                    Comments
                  </span>
                </>
                :
                <>
                  <Box component={'span'} mr={1}>
                    <AttachFileOutlined />
                  </Box>
                  <span>
                    Attachments
                  </span>
                </>
              }
            </Box>
            <Box>
              <form
                onSubmit={handleSearchTextSubmit}
              >
                <TextField
                  name="searchText"
                  variant='standard'
                  placeholder={'Search'}
                  className="search"
                  size="small"
                  value={searchText}
                  onChange={handleSearchTextChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={SearchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <button
                  type="submit"
                  style={{ display: 'none' }}
                ></button>
              </form>
            </Box>
          </Stack>
        </Box>


        {section == 'attachments' ?
          <Attachments            
            id={id}
            type={type}
            commentsAndAttachments={commentsAndAttachments}
            getIcon={getIcon}
            getIconForNewAttachment={getIconForNewAttachment}
            openDeleteAttachmentConfirmPopup={openDeleteAttachmentConfirmPopup}
          />
        :
          <Box>
            {commentsAndAttachments.length > 0 && (
              <Stack spacing={0.2} className={'comments-list'} >
                {commentsAndAttachments.map((data: CommentsAndAttachmentsType) => (
                  <>
                    {commentAndAttachmentToEdit && (data._id == commentAndAttachmentToEdit._id) ?
                      <AddOrEditCommentsAndAttachments
                        id={id}
                        type={type}
                        isBeingEdited={true}
                        commentAndAttachmentData={commentAndAttachmentToEdit}
                        loggedInUser={loggedInUser}
                        getIcon={getIcon}
                        getIconForNewAttachment={getIconForNewAttachment}
                        cancelEditCommentAndAttachment={cancelEditCommentAndAttachment}
                      />
                      :
                      <Stack spacing={1} className="comment-box option-container">
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            <Stack
                              direction="row"
                              alignItems={'center'}
                              spacing={1}
                              className="paragraph bold"
                              mr={3}
                            >
                              <Avatar style={styles.avatar}>
                                {data.userData.firstName
                                  ?.charAt(0)
                                  .toUpperCase()
                                }
                                {data.userData.lastName
                                  ?.charAt(0)
                                  .toUpperCase()
                                }
                              </Avatar>
                              <Box className="small-subtitle primary-text bold" fontSize={12} textTransform={'capitalize'}>
                                {data.userData.firstName} {data.userData.lastName}
                              </Box>
                              {loggedInUser._id == data.userData._id &&
                                <span className="tag ">YOU</span>
                              }
                            </Stack>

                          </Box>
                          <Box display={'flex'}>
                            <Box className="small-subtitle" fontSize={12}>
                              {moment(data.dateTime).format('DD MMM, YYYY, hh:mm A')}
                            </Box>

                            {loggedInUser._id == data.userData._id &&
                              <Box
                                className="detail-popup"
                                ml={1}>
                                <CustomHTMLTooltip placement={'bottom-end'}
                                  interactive
                                  arrow={false}
                                  title={
                                    <Box
                                      className="tooltip-popup"
                                      width={150}
                                    >
                                      <Box onClick={() => handleEditCommentAndAttachment(data)}>                                        
                                        Edit                                        
                                      </Box>
                                      <Box style={{ color: '#FF4170' }} onClick={() => openDeleteCommentConfirmPopup(data._id)}>
                                        Delete
                                      </Box>
                                    </Box>
                                  }
                                >
                                  <MoreVertIcon
                                    style={{ fontSize: '18px' }}
                                    className="options-button"
                                  />
                                </CustomHTMLTooltip>
                              </Box>
                            }
                          </Box>
                        </Box>

                        <Box className="small-subtitle comment">{data.comment}</Box>

                        {data?.attachments && data.attachments.length > 0 && (
                          <Grid container spacing={0.5} className="attachments">
                            {data.attachments.map((attachment: Attachment) => (
                              <Grid size={{md:6,}} >
                                <Box className="attachment-file">
                                  <Box display={'flex'} alignItems={'center'}>
                                    <Box className="file-icon">
                                      <img src={getIcon(attachment.fileType, attachment.fileExtension)} alt="file-icon" />
                                    </Box>
                                    <Box>
                                      <Box className="file-name">
                                        {attachment.fileName}
                                      </Box>
                                      {/* <Box className="file-size">
                                      {attachment.fileSize}
                                    </Box> */}
                                    </Box>
                                  </Box>

                                  <Box className="detail-popup">
                                    <CustomHTMLTooltip placement={'bottom-end'} interactive arrow={false} title={
                                      <Box
                                        className="tooltip-popup"
                                        width={150}
                                      >
                                        <Box
                                          onClick={
                                            () => downloadFile(
                                              `${Url.opportunityAttachmentUrl}/${attachment.fileNameInS3}`,
                                              attachment.fileName
                                            )
                                          }
                                        >Download</Box>
                                        {loggedInUser._id == data.userData._id &&
                                          <Box
                                            style={{ color: '#FF4170' }}
                                            onClick={() =>
                                              openDeleteAttachmentConfirmPopup(
                                                data._id,
                                                attachment._id,
                                              )
                                            }
                                          >
                                            Delete
                                          </Box>
                                        }
                                      </Box>
                                    }>
                                      <MoreVertIcon
                                        style={{ fontSize: '18px' }}
                                        className="options-button"
                                      />
                                    </CustomHTMLTooltip>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Stack>
                    }
                    <div
                      style={{ float: 'left', clear: 'both' }}
                      ref={messagesEndRef}
                    />
                  </>
                ))}
              </Stack>
            )}

            <AddOrEditCommentsAndAttachments
              id={id}
              type={type}
              isBeingEdited={false}
              commentAndAttachmentData={undefined}
              loggedInUser={loggedInUser}
              getIcon={getIcon}
              getIconForNewAttachment={getIconForNewAttachment}
            />
          </Box>
        }
      </Stack >
      {deleteCommentConfirmPopup && (
        <ConfirmationAlertPopUp
          openModal={deleteCommentConfirmPopup}
          closeModalFunc={closeDeleteCommentConfirmPopup}
          title={'Delete Comment!'}
          text={
            "Are you sure you want to delete this comment and it's attachments?"
          }
          confirmationButtonText={'Delete'}
          confirmationButtonColor="secondary"
          closeButtonText={'Cancel'}
          functionality={deleteCommentAndAttachment}
        />
      )
      }
      {
        deleteAttachmentConfirmPopup && (
          <ConfirmationAlertPopUp          
            openModal={deleteAttachmentConfirmPopup}
            closeModalFunc={closeDeleteAttachmentConfirmPopup}
            title={'Delete Attachment!'}
            text={'Are you sure you want to delete this attachment?'}
            confirmationButtonText={'Delete'}
            confirmationButtonColor="secondary"
            closeButtonText={'Cancel'}
            functionality={deleteAttachment}
          />
        )
      }
      {(showLoader || loading) && <Loader />}
    </>
  );
}

export default CommentsAndAttachments;

import VanityDomainAPI from 'ReduxStore/API/VanityDomainAPI';
import { call, put } from 'redux-saga/effects';
import { Actions } from 'ReduxStore/Actions';

function* createVanityDomain(action) {
  const vanityApi = new VanityDomainAPI();
  const response = yield call(vanityApi.createVanityDomain, action.vanityDomain);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.createVanityDomainSuccess(
          response.data.vanityDomain,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.createVanityDomainFailure(response.data.message));
    }
  } else {
    yield put(Actions.createVanityDomainFailure(response.data.message));
  }
}


function* removeVanityDomain(action) {
  const vanityApi = new VanityDomainAPI();
  const response = yield call(vanityApi.removeVanityDomain, action.eventId);
  if (response.status === 200) {
    if (response.data.success) {
      yield put(
        Actions.removeVanityDomainSuccess(
          response.data.vanityDomain,
          response.data.message,
        ),
      );
    } else {
      yield put(Actions.removeVanityDomainFailure(response.data.message));
    }
  } else {
    yield put(Actions.removeVanityDomainFailure(response.data.message));
  }
}
const vanityDomainSaga = { createVanityDomain, removeVanityDomain };
export default vanityDomainSaga;

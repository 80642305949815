//@ts-nocheck
import _ from 'lodash';
import { createReducer } from 'reduxsauce';

import { Constants } from '../Actions';

export interface AIChatbotState {
  widgetPreviewMessages: any[];
  error: boolean;
  message: string | null;
  testAiChatbotSuccess: boolean;
  chatBot: ChatBot;
  getAiAgentGeneralDataSuccess: boolean;
  listAllChatbotsSuccess: boolean;
  chatbots: ChatBot[];
  loading: boolean;
  trainAgentSuccess: boolean;
  createOrUpdateAiAgentGeneralDataSuccess: boolean;
  enableChatbotSuccess: boolean;
  deleteChatbotSuccess: boolean;
  setChatbotAsDefaultSuccess: boolean;
  page_no: number;
  limit: number;
  totalChatBotCount: number;
  saveSettingsForAiChatbotSuccess: boolean;
  replicateChatWidgetSuccess: boolean;
}
export interface ChatBot {
  agent: Agent;
  projectIds: ProjectId[];
  FAQs: FAQ[];
  totalFAQs: number;
  isDefault: boolean;
  contextFiles: ContextFile[];
  context: string;
  vectorStorePath?: string;
  contextUrls: ContextUrl[];
  userId: any | string;
  _id?: string;
  isEnabled?: boolean;
  updatedAt: Date;
  settings: Settings;
  isTrained: boolean;
}

export type ProjectId = { _id: string; eventName: string } | string;
export interface FAQ {
  question: string;
  answer: string;
  _id?: string;
}

export interface ContextUrl {
  url: string;
  characterCount?: number;
}

// Define an interface for the Message document
export interface Agent {
  name: string; // User ID of the sender
  role?: string;
  company?: string; // Message text
}

export interface ContextFile {
  name: string;
  type: string;
  path: string;
  uploadDate: Date;
  keyInS3: string;
  characterCount: number;
}
interface ExampleLimit {
  active: boolean;
  total?: number;
  positive?: number;
  negative?: number;
}
export interface Settings {
  model: ModelType;
  opportunity: OpportunityType;
  chatInterface?: ChatInterfaceType;
  security: SecurityType;
  collectOpportunityData: Boolean;
}

export interface ExampleSettings {
  documentCount: number;
  documentSize: number;
  overlap: number;
  examplesLimit?: ExampleLimit;
}

export interface ContextSettings {
  documentCount: number;
  documentSize: number;
  overlap: number;
}

export interface ModelType {
  adminEmail?: string;
  model: string;
  lastTrainedAt: null | Date | string;
  basePrompt: string;
  fallbackSatrategy?: string;
  temperature: number;
  exampleSettings: ExampleSettings;
  contextSettings: ContextSettings;
}

export interface OpportunityType {
  customFieldsArray: CustomFieldsFrontEnd[];
  title: string;
}

export type CustomFieldsFrontEnd = {
  fieldId: string;
  fieldName: string;
  required: boolean;
  fieldType: string;
  fields?: CustomFieldsFrontEnd[];
  checked: boolean;
};

export type CustomFieldsList = CustomFieldsFrontEnd[];

export interface LeadsOption {
  name: string;
  type: string;
  isMandatory: boolean;
  checkboxValues?: string[];
  dropdownValues?: string[];
  checkboxString?: string;
  dropdownString?: string;
}

export interface Color {
  r: number;
  g: number;
  b: number;
  a: number;
}
export interface BorderRadius {
  topLeft: number;
  topRight: number;
  bottomRight: number;
  bottomLeft: number;
}
export interface Padding {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
export interface Border {
  size: number;
  state: string;
  color: Color;
}

export interface Font {
  size?: number;
  family?: string;
  weight?: number;
  style?: string;
}

export interface MessageStyle {
  borderRadius: BorderRadius;
  padding?: Padding;
  border?: Border;
  font?: Font;
  spacing?: number;
  timeFont?: Font;
}

export interface ComfirmationStyle {
  confirmationText: string;
  buttonTrue: string;
  buttonFalse: string;
  font?: Font;
  bgColor: Color;
  FontColor: Color;
  ButtonTextColor: Color;
  ButtonBgColor: Color;
  ButtonBorderRadius: BorderRadius;
  ButtonFont?: Font;
}
export interface EndChatSession {
  EndChatSessionText: string;
  StartChatUrlText: string;
  font?: Font;
}
export interface HeaderStyle {
  font: Font;
}

export interface ChatInterfaceType {
  initialMessages: string[];
  suggestedMessages: string[];
  theme: 'light' | 'dark';
  profilePic: ProfilePic;
  displayName: string;
  customCss: string;
  botMessageColor: Color;
  botMessageTextColor: Color;
  userMessageColor: Color;
  userMessageTextColor: Color;
  timeStampTextColor: Color;
  errorMessageTextColor: Color;
  chatIcon: ChatIcon;
  bubbleButtonColor: Color;
  displayNameColor: Color;
  chatWindowBgColor: Color;
  chatInputTextColor: Color;
  bubbleButtonAlignment: 'right' | 'left';
  autoShowInitialMessages: number;
  timeFontSize: number;
  showProfilePic: boolean;
  showChatIcon: boolean;
  chatWindow: MessageStyle;
  agentMessage?: MessageStyle;
  userMessage?: MessageStyle;
  chatInput?: MessageStyle;
  headerStyle?: HeaderStyle;
  comfirmationStyle?: ComfirmationStyle;
  endChatSession?: EndChatSession;
}

export interface SecurityType {
  visibility: 'Private' | 'Public';
  allowIFrameAndWidgetOnSpecificDomain: boolean;
  rateLimiting: RateLimiting;
  messageToShowLimitHit: string;
}

export interface RateLimiting {
  limitTo: number;
  messageInterval: number;
}

export interface ProfilePic {
  name: string;
  keyInS3: string;
}
export interface ChatIcon {
  name: string;
  keyInS3: string;
}

export const settingsInitialState: Settings = {
  collectOpportunityData: false,
  model: {
    adminEmail: '',
    lastTrainedAt: null,
    basePrompt: '',
    fallbackSatrategy: '',
    model: 'mistral',
    temperature: 66,
    exampleSettings: {
      documentCount: 1,
      documentSize: 500,
      overlap: 150,
      examplesLimit: {
        active: false,
        negative: 0,
        positive: 0,
        total: 0,
      },
    },
    contextSettings: {
      documentCount: 1,
      documentSize: 2000,
      overlap: 150,
    },
  },
  opportunity: { customFieldsArray: [], title: '' },
  chatInterface: {
    initialMessages: ['Hello, How can I assist you today'],
    suggestedMessages: [],
    theme: 'light',
    profilePic: {
      name: '',
      keyInS3: '',
    },
    displayName: 'AI Agent',
    customCss: `@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');

        .ai-chat-widget, .MuiInputBase-root {
          font-family: "Roboto", system-ui;
          font-weight: 400;
          font-style: normal;
        }`,
    userMessageColor: {
      r: 15,
      g: 65,
      b: 250,
      a: 1,
    },
    userMessageTextColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    timeStampTextColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    errorMessageTextColor: {
      r: 255,
      g: 0,
      b: 0,
      a: 1,
    },
    botMessageColor: {
      r: 135,
      g: 195,
      b: 231,
      a: 1,
    },
    botMessageTextColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    chatIcon: {
      name: '',
      keyInS3: '',
    },
    bubbleButtonColor: {
      r: 20,
      g: 33,
      b: 139,
      a: 1,
    },
    displayNameColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    chatWindowBgColor: {
      r: 20,
      g: 33,
      b: 139,
      a: 1,
    },
    chatInputTextColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
    bubbleButtonAlignment: 'right',
    autoShowInitialMessages: 3,
    showProfilePic: false,
    showChatIcon: false,
    chatWindow: {
      borderRadius: {
        topLeft: 30,
        topRight: 30,
        bottomRight: 30,
        bottomLeft: 30,
      },

      padding: {
        top: 30,
        right: 30,
        bottom: 10,
        left: 30,
      },
    },
    headerStyle: {
      font: {
        size: 16,
        weight: 500,
      },
    },
    agentMessage: {
      borderRadius: {
        topLeft: 30,
        topRight: 30,
        bottomRight: 30,
        bottomLeft: 0,
      },

      padding: {
        top: 16,
        right: 20,
        bottom: 16,
        left: 20,
      },
      font: {
        size: 16,
        weight: 400,
      },
      spacing: 25,
      timeFont: {
        size: 10,
      },
    },
    userMessage: {
      borderRadius: {
        topLeft: 30,
        topRight: 30,
        bottomRight: 0,
        bottomLeft: 30,
      },

      padding: {
        top: 16,
        right: 20,
        bottom: 16,
        left: 20,
      },
    },
    chatInput: {
      borderRadius: {
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
      },

      padding: {
        top: 9,
        right: 9,
        bottom: 9,
        left: 9,
      },

      border: {
        size: 1,
        state: 'solid',
        color: {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
      },
      font: {
        size: 18,
        weight: 400,
      },
    },
    timeFontSize: 14,
    comfirmationStyle: {
      confirmationText: 'Are you sure! You want to leave current chat?',
      buttonTrue: 'Yes',
      buttonFalse: 'No',
      font: {
        size: 16,
      },
      bgColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 0.7,
      },
      FontColor: {
        r: 33,
        g: 17,
        b: 136,
        a: 1,
      },
      ButtonBgColor: {
        r: 33,
        g: 17,
        b: 136,
        a: 1,
      },
      ButtonTextColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      ButtonBorderRadius: {
        topLeft: 25,
        topRight: 25,
        bottomRight: 25,
        bottomLeft: 25,
      },
      ButtonFont: {
        size: 13,
      },
    },
    endChatSession: {
      EndChatSessionText: `This chat has ended. To start a new conversation, click here.`,
      StartChatUrlText: `click here.`,
      font: {
        size: 13,
      },
    },
  },
  security: {
    visibility: 'Public',
    allowIFrameAndWidgetOnSpecificDomain: false,
    rateLimiting: {
      limitTo: 20,
      messageInterval: 25,
    },
    messageToShowLimitHit: '',
  },
};

export const chatBotInitialState = {
  agent: {
    name: '',
    company: '',
    role: '',
  },
  contextFiles: [],
  context: '',
  FAQs: [],
  totalFAQs: 0,
  contextUrls: [],
  projectIds: [],
  isDefault: false,
  userId: '',
  _id: '',
  isEnabled: false,
  updatedAt: new Date(),
  settings: settingsInitialState,
  isTrained: true,
};

export const INITIAL_STATE: AIChatbotState = {
  widgetPreviewMessages: [
    {
      sender: 'assistant',
      text: "Welcome to our AI Chat! feel free to ask your queries. I'm here to help!",
      id: 'abc',
    },
    {
      sender: 'user',
      text: 'How are you',
      id: 'abcd',
    },
  ],
  error: false,
  message: null,
  testAiChatbotSuccess: false,
  chatBot: chatBotInitialState,
  getAiAgentGeneralDataSuccess: false,
  listAllChatbotsSuccess: false,
  chatbots: [],
  loading: false,
  trainAgentSuccess: false,
  createOrUpdateAiAgentGeneralDataSuccess: false,
  enableChatbotSuccess: false,
  deleteChatbotSuccess: false,
  setChatbotAsDefaultSuccess: false,
  page_no: 1,
  limit: 10,
  totalChatBotCount: 0,
  saveSettingsForAiChatbotSuccess: false,
  replicateChatWidgetSuccess: false,
};

const updateChatbot = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
): AIChatbotState => {
  // console.log("R E D U C ER", action);
  return {
    ...state,
    chatBot: action.payload,
  };
};
const getAiAgentGeneralDataRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    getAiAgentGeneralDataSuccess: false,
    message: null,
    error: false,
    loading: true,
  };
};

const getAiAgentGeneralDataSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  // console.log("S T", state.chatBot)
  let resp = _.merge({}, state.chatBot, action.chatbot);
  return {
    ...state,
    getAiAgentGeneralDataSuccess: true,
    message: action.message,
    error: false,
    chatBot: { ...resp },
    loading: false,
  };
};

const getAiAgentGeneralDataError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    getAiAgentGeneralDataSuccess: false,
    message: action.message,
    error: true,
    chatBot: {},
    loading: false,
  };
};

const createOrUpdateAiAgentGeneralDataRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    createOrUpdateAiAgentGeneralDataSuccess: false,
    message: null,
    error: false,
    chatBot: {},
    loading: true,
  };
};

const createOrUpdateAiAgentGeneralDataSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    createOrUpdateAiAgentGeneralDataSuccess: true,
    message: action.message,
    error: false,
    chatBot: action.chatbot,
    loading: false,
    chatbots: action.chatbots,
    totalChatBotCount: action.totalChatBotCount,
  };
};

const createOrUpdateAiAgentGeneralDataError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    createOrUpdateAiAgentGeneralDataSuccess: false,
    message: action.message,
    error: true,
    chatBot: {},
    loading: false,
  };
};

const listAllChatbotsRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    listAllChatbotsSuccess: false,
    message: null,
    error: false,
    chatbots: [],
    loading: true,
  };
};

const listAllChatbotsSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    listAllChatbotsSuccess: true,
    message: action.message,
    error: false,
    chatbots: action.chatbots,
    totalChatBotCount: action.totalChatBotCount,
    loading: false,
  };
};

const listAllChatbotsError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    listAllChatbotsSuccess: false,
    message: action.message,
    error: true,
    chatbots: [],
    loading: false,
  };
};

const trainAgentRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    trainAgentSuccess: false,
    message: null,
    error: false,
    loading: true,
  };
};

const trainAgentSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    trainAgentSuccess: true,
    message: action.message,
    error: false,
    loading: false,
  };
};

const trainAgentsError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    trainAgentSuccess: false,
    message: action.message,
    error: true,
    loading: false,
  };
};

const deleteChatbotRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    deleteChatbotSuccess: false,
    message: null,
    error: false,
    loading: true,
  };
};
const deleteChatbotSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    deleteChatbotSuccess: true,
    message: action.message,
    error: false,
    loading: false,
    chatbots: action.chatbots,
    totalChatBotCount: action.totalChatBotCount,
  };
};
const deleteChatbotError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    deleteChatbotSuccess: false,
    message: action.message,
    error: true,
    loading: false,
  };
};

const enableChatbotRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    enableChatbotSuccess: false,
    message: null,
    error: false,
    loading: true,
  };
};
const enableChatbotSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    enableChatbotSuccess: true,
    message: action.message,
    error: false,
    loading: false,
    chatbots: action.chatbots,
  };
};
const enableChatbotError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    enableChatbotSuccess: false,
    message: action.message,
    error: true,
    loading: false,
  };
};
const setChatbotAsDefaultRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    setChatbotAsDefaultSuccess: false,
    message: null,
    error: false,
    loading: true,
  };
};
const setChatbotAsDefaultSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    setChatbotAsDefaultSuccess: true,
    message: action.message,
    error: false,
    loading: false,
    chatbots: action.chatbots,
    totalChatBotCount: action.totalChatBotCount,
  };
};
const setChatbotAsDefaultError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    setChatbotAsDefaultSuccess: false,
    message: action.message,
    error: true,
    loading: false,
  };
};

const setPageNo = (state: AIChatbotState = INITIAL_STATE, action: any) => {
  return {
    ...state,
    page_no: action.page_no,
  };
};
const setLimit = (state: AIChatbotState = INITIAL_STATE, action: any) => {
  return {
    ...state,
    limit: action.limit,
  };
};

const setChatBotToInitialState = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    chatBot: chatBotInitialState,
  };
};

const saveSettingsForAiChatbotRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    saveSettingsForAiChatbotSuccess: false,
    error: false,
    message: null,
    loading: true,
  };
};
const saveSettingsForAiChatbotSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  let settings = _.merge({}, state.chatBot.settings, action.settings);
  return {
    ...state,
    saveSettingsForAiChatbotSuccess: false,
    error: false,
    message: action.message,
    loading: false,
    chatBot: {
      ...state.chatBot,
      settings: settings,
    },
  };
};
const saveSettingsForAiChatbotError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    saveSettingsForAiChatbotSuccess: false,
    error: true,
    message: action.message,
    loading: false,
  };
};

const replicateChatWidgetRequest = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    replicateChatWidgetSuccess: false,
    message: null,
    error: false,
    chatbots: [],
    loading: true,
  };
};

const replicateChatWidgetSuccess = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    replicateChatWidgetSuccess: true,
    message: action.message,
    error: false,
    loading: false,
    chatbots: action.chatbots,
    totalChatBotCount: action.totalChatBotCount,
  };
};

const replicateChatWidgetError = (
  state: AIChatbotState = INITIAL_STATE,
  action: any,
) => {
  return {
    ...state,
    replicateChatWidgetSuccess: false,
    message: action.message,
    error: true,
    chatbots: [],
    loading: false,
  };
};

const setLoading = (state: AIChatbotState = INITIAL_STATE, action: any) => {
  return {
    ...state,
    loading: action.isLoading,
  };
};

const HANDLERS = {
  [Constants.UPDATE_CHATBOT]: updateChatbot,
  [Constants.GET_AI_AGENT_GENERAL_DATA_REQUEST]: getAiAgentGeneralDataRequest,
  [Constants.GET_AI_AGENT_GENERAL_DATA_SUCCESS]: getAiAgentGeneralDataSuccess,
  [Constants.GET_AI_AGENT_GENERAL_DATA_ERROR]: getAiAgentGeneralDataError,
  [Constants.CREATE_OR_UPDATE_AI_AGENT_GENERAL_DATA_REQUEST]:
    createOrUpdateAiAgentGeneralDataRequest,
  [Constants.CREATE_OR_UPDATE_AI_AGENT_GENERAL_DATA_SUCCESS]:
    createOrUpdateAiAgentGeneralDataSuccess,
  [Constants.CREATE_OR_UPDATE_AI_AGENT_GENERAL_DATA_ERROR]:
    createOrUpdateAiAgentGeneralDataError,
  [Constants.LIST_ALL_CHATBOTS_REQUEST]: listAllChatbotsRequest,
  [Constants.LIST_ALL_CHATBOTS_SUCCESS]: listAllChatbotsSuccess,
  [Constants.LIST_ALL_CHATBOTS_ERROR]: listAllChatbotsError,
  [Constants.TRAIN_AGENT_REQUEST]: trainAgentRequest,
  [Constants.TRAIN_AGENT_SUCCESS]: trainAgentSuccess,
  [Constants.TRAIN_AGENT_ERROR]: trainAgentsError,
  [Constants.DELETE_CHATBOT_REQUEST]: deleteChatbotRequest,
  [Constants.DELETE_CHATBOT_SUCCESS]: deleteChatbotSuccess,
  [Constants.DELETE_CHATBOT_ERROR]: deleteChatbotError,
  [Constants.ENABLE_CHATBOT_REQUEST]: enableChatbotRequest,
  [Constants.ENABLE_CHATBOT_SUCCESS]: enableChatbotSuccess,
  [Constants.ENABLE_CHATBOT_ERROR]: enableChatbotError,
  [Constants.SET_CHATBOT_AS_DEFAULT_REQUEST]: setChatbotAsDefaultRequest,
  [Constants.SET_CHATBOT_AS_DEFAULT_SUCCESS]: setChatbotAsDefaultSuccess,
  [Constants.SET_CHATBOT_AS_DEFAULT_ERROR]: setChatbotAsDefaultError,
  [Constants.SET_PAGE_NO]: setPageNo,
  [Constants.SET_LIMIT]: setLimit,
  [Constants.SET_CHAT_BOT_TO_INITIAL_STATE]: setChatBotToInitialState,
  [Constants.SAVE_SETTINGS_FOR_AI_CHATBOT_REQUEST]:
    saveSettingsForAiChatbotRequest,
  [Constants.SAVE_SETTINGS_FOR_AI_CHATBOT_SUCCESS]:
    saveSettingsForAiChatbotSuccess,
  [Constants.SAVE_SETTINGS_FOR_AI_CHATBOT_ERROR]: saveSettingsForAiChatbotError,
  [Constants.REPLICATE_CHAT_WIDGET_REQUEST]: replicateChatWidgetRequest,
  [Constants.REPLICATE_CHAT_WIDGET_SUCCESS]: replicateChatWidgetSuccess,
  [Constants.REPLICATE_CHAT_WIDGET_ERROR]: replicateChatWidgetError,
  [Constants.SET_LOADING]: setLoading,
};

export default createReducer(INITIAL_STATE, HANDLERS);

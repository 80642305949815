import React, { Component } from 'react'
import error from './error-404.png'

import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import errorImg from '../../../src/assets/images/404.svg';
import Button from '@mui/material/Button'; 
import { Modal } from 'react-responsive-modal';
import withCustomRouter from '../../Common/withCustomRouter';  

class NotFound extends Component{
    constructor(props){
        super(props);
        this.state = {
            openEarlyAccess: false
        }
    }

    componentDidMount(){
        const query = {};
        const searchParam = this.props.location.search.replace('?', '').split('&');
        for(var i=0; i<searchParam.length; i++){
            let ele = searchParam[i].split('=');
            query[ele[0]] = ele[1];
        }
        if(query['earlyAccess']){
            document.getElementById("showEarlyAccessPopUp").click();
            var decodedMsg = Buffer.from(query['earlyAccess'], 'base64').toString('ascii');
            setTimeout(()=>{
              var ele = document.getElementById('earlyAccessMsg');
              if(ele){
                document.getElementById('earlyAccessMsg').textContent = decodeURIComponent(decodedMsg);
              }
            }, 200);
          }
    }

    handleClickCloseEarlyAccess = () => {
        this.setState({
          openEarlyAccess: false,
        })
    }
    
    handleClickOpenEarlyAccess = () => {
        this.setState({
            openEarlyAccess: true,
        })
    }  

    handleBackinHistory = () => {
        try{
            window.history.go(-2);
        }catch(err){
        }
    }
    
 /*  <div className="logosytle">
    <img src={error} alt="404-notfound" />
  </div> */
    render(){
        return(
            <ThemeProvider theme={theme}>

            <Grid container alignItems="center" justifyContent={"center"}>
                <Grid size={{xs: 8,}}>
                    <Stack spacing={1} direction="column">
                        <Box style={{ textAlign: 'center'}}>
                            <img style={{maxWidth: '100%', }} width="600" alt='404 page not found' src={errorImg} />
                        </Box>
                        <Box sx={{ fontSize: '36px', color: theme.palette.primary.dark, textAlign: 'center' }} className="">Oops, Sorry we can’t find that page!</Box>
                        <Box sx={{ fontSize: '24px', color: theme.palette.primary.light, textAlign: 'center' }}>Either something went wrong or the page does not exist anymore.</Box>
                    </Stack>
                </Grid>
                {/* Early Access Modal */}
                <Button id="showEarlyAccessPopUp" style={{"display":"none","visibility":"hidden"}} onClick={this.handleClickOpenEarlyAccess}>FP Open Modal</Button>
                    {/* <!-- The Modal --> */}
                    <Modal
                    open={this.state.openEarlyAccess} onClose={this.handleClickCloseEarlyAccess}
                    classNames={{
                        overlay: '',
                        modal: 'Invite-User-Modal',
                    }}
                    center 
                    >
                    <Grid component='div' container className='modal-wrapper' >
                        <Grid size={{xs: 12,}} pb={2}>
                        <Box component="p" mb={0.5} className='subtitle'>Permission Denied.</Box>
                        <Box component="p" mb={0.5} className='small-subtitle' id='earlyAccessMsg'>
                        </Box>
                        </Grid>
                        <Grid size={{xs: 12,}} >
                        <Button variant="contained"  onClick={ this.handleBackinHistory } color="primary" size='small' >Back</Button>
                        </Grid>
                    </Grid>
                    </Modal>
            </Grid>

            </ThemeProvider>
        )
    }
}

export default withCustomRouter(NotFound)

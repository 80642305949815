import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../../theme/theme';
import Button from '@mui/material/Button';
// import '../../../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Actions } from 'ReduxStore/Actions';
import { getProjectIdFromUrl } from '../../../../helpers/common';
import Loader from '../../../../Components/Loader/loader';
import userImageIcon from '../../../../../src/assets/images/icons/userImageIcon.svg';
import { Modal } from 'react-responsive-modal';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'; 
import { Stack } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import AudienceFilterIcon from '@mui/icons-material/SupervisorAccountOutlined';
import GroupsFilterIcon from '@mui/icons-material/PeopleAltOutlined';
import AttendeesFilterIcon from '@mui/icons-material/PermIdentityOutlined';
import AddGuestIcon from '@mui/icons-material/GroupAddOutlined';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { createNotification } from '../../../../helpers';
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';

// Styling for the Avatar component
const styles = {
  avatar: {
    backgroundColor: '#5141E7',
    width: '48px',
    height: '48px',
  },
};

// API IMAGE_URL from environment variables
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

/**
 * @class MeetingRecipients
 * @extends Component
 * @description Parent component for creating and managing meetings.
 */
class MeetingRecipients extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef(); // Ref for an HTML element
    // State initialization and default values
    this.state = {
      totalAudience: 0,
      totalAttendees: 0,
      pageNoForGroup: 1,
      pageNoForAttendee: 1,
      pageNoForAudience: 1,
      loading: false,
      loadMoreArray: [],
      increaseLoadMoreBy: 20,
      loadMoreNumber: 1,
      selectedEmailArr: [],
      selectedEmail: [],
      selectAttendee: [],
      openRecipientTypeModal: false,
      listOfAudienceGroups: [],
      ChooseAudience: false,
      openAudience: true,
      openAttendees: false,
      openGroups: false,
      openSearchAdd: false,
      scrollNoForAttendeesNotAddedToMeeting: 1,
      searchTextForAttendeesNotAddedToMeeting: '',
      audienceGroupsList: [],
      attendeesGroupsList: [],
      excludeAttendees: false,
      excludeCheckedInAttendees: false,
      SelectGroups: false,
      listOfGroups: [],
      attendeesGroups: [],
      audienceData: [],
      openSearchAndAddModal: false,
      openAddedPeople: false,
      peopleSelectedToRemoveFromInvite: [],
      numOfAttendeesToShow: 12,
      scroll_no_forAdded: 1,
      listOfAttendeesOfMeeting: [],
      peopleSelectedToAddToInvite: [],
      listOfAttendeesNotAddedToMeeting: [],
      listOfAttendees: [],
      scroll_no_forNotAdded: 1,
      listOfAttendeesNotAddedToMeetingBackup: [],
      listOfAttendeesOfMeetingBackup: [],
      searchTextForAddedPeopleInInviteList: '',
      attendeesGroupsCountNumber: 0,
      attendeesCountNumber: 0,
      audienceCountNumber: 0,
      isSingleCheck: false,
      ChooseGuest: false,
      AudienceFilter: false,
      ClearFilter: false,
      GroupsFilter: false,
      AttendeesFilter: false,
      searchText: '',
      changeState: false,
      selectAllChecked: false,
      selectAllCheckedAttendees: false,
      limitForAudience: 100,
      limitForAttendee: 100,
      limitForGroup: 100,
      openAddDataPopup: false,
      dataForGetContact: '',
      mergeArray: [],
      disableLoadMorePoints: true,
      errors: {},
    };
  }


  /**
     * @function componentDidMount
     * @description Lifecycle method invoked after the component is mounted.
     * Fetches data and sets the initial component state.
     */
  componentDidMount = () => {
    const eventId = getProjectIdFromUrl();
    this.setState({
      eventId,
    });
  };

  /**
 * @function componentDidUpdate
 * @param {object} prevProps - Previous properties.
 * @param {object} prevState - Previous state.
 * @description Lifecycle method called after the component updates.
 *   Handles updates in Meeting-related actions and updates state accordingly.
 */
  componentDidUpdate = (prevProps, prevState) => {

    if (
      this.props.Meeting.checkDisabledAttendeeSuccess !==
      prevProps.Meeting.checkDisabledAttendeeSuccess &&
      this.props.Meeting.checkDisabledAttendeeSuccess === true
    ) {
      this.state.selectedEmail.push({ email: this.state.searchText });
      this.setState(
        {
          searchText: '',
        },
        () => this.removeDuplicateValue(),
      );
    }

    if (
      this.props.Meeting.checkDisabledAttendeeError !==
      prevProps.Meeting.checkDisabledAttendeeError &&
      this.props.Meeting.checkDisabledAttendeeError === true
    ) {
      createNotification('error', this.props.Meeting.message);
    }
    if (
      this.props.Meeting.getMeetingTemplateSuccess !==
      prevProps.Meeting.getMeetingTemplateSuccess &&
      this.props.Meeting.getMeetingTemplateSuccess === true
    ) {
      this.setState(
        {
          selectedEmail: this.props.Meeting.meetingTemplate[0]?.meetingEmails,
        },
        () => this.loadMore(),
      );
    }

    const { selectedEmail } = this.state;

    if (selectedEmail !== prevState.selectedEmail) {
      this.props.getDataFromMeetingQuickFilter(this.state.selectedEmail);
    }

    if (
      this.props.Meeting?.listAttendeesNotAddedToMeetingSuccess &&
      !prevProps.Meeting?.listAttendeesNotAddedToMeetingSuccess &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      this.setState(
        {
          listOfAttendeesNotAddedToMeeting:
            this.props.Meeting?.listAttendeesNotAddedToMeetings,

          totalAttendees: this.props.Meeting?.totalAttendees,
          loading: false,
        },
        () => this.removeSelectedEmail(),
      );
    }

    if (
      this.props.Meeting.attendeeGroupsForMeetingSuccess === true &&
      prevProps.Meeting.attendeeGroupsForMeetingSuccess === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      this.setState({
        attendeesGroups: this.props.Meeting.attendeesGroups,
        totalAttendeeGroups: this.props.Meeting.totalAttendeeGroups,
        loading: false,
      });
    }

    if (
      this.props.Meeting.getAudienceContactSuccess === true &&
      prevProps.Meeting.getAudienceContactSuccess === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      this.setState(
        {
          contact: this.props.Meeting.contact,
          loading: false,
          openAddDataPopup: false,
          ChooseGuest: false,
          GroupsFilter: false,
          AudienceFilter: false,
        },
        () => {
          this.setarrayValue();
        },
      );
    }

    if (
      this.props.Meeting.audienceForMeetingSuccess === true &&
      prevProps.Meeting.audienceForMeetingSuccess === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      this.setState({
        audienceData: this.props.Meeting.audienceData,
        totalAudience: this.props.Meeting.totalAudience,
        loading: false,
      });
    }

    if (
      this.props.Meeting.searchForMeetingSuccess === true &&
      prevProps.Meeting.searchForMeetingSuccess === false &&
      this.props.Meeting.message !== prevProps.Meeting.message
    ) {
      this.setState({
        mergeArray: this.props.Meeting.mergeArray,
        loading: false,
      });
    }
  };

  /**
 * @method listAudienceGroups
 * @description Fetches audience groups for a meeting based on specified filters.
 */
  listAudienceGroups = () => {
    this.setState({
      loading: true,
    });
    this.props.audienceForMeeting({
      limitForAudience: this.state.limitForAudience,
      page_no: this.state.pageNoForAudience,
      AudienceFilter: this.state.AudienceFilter,
      searchText: this.state.searchText,
    });
  };

  /**
 * @method listAttendeesOfNotAddedToMeeting
 * @description Fetches attendees not added to the meeting based on specified filters.
 */
  listAttendeesOfNotAddedToMeeting = () => {
    const {
      eventId,
      pageNoForAttendee,
      AttendeesFilter,
      searchTextForAttendeesNotAddedToMeeting,
      limitForAttendee,
    } = this.state;
    this.setState({
      loading: true,
    });
    const body = {
      projectId: eventId,
      page_no: pageNoForAttendee,
      AttendeesFilter: AttendeesFilter,
      searchText: this.state.searchText,
      limitForAttendee: limitForAttendee,
    };
    this.props.listAttendeesOfNotAddedToMeeting(body);
  };

  /**
 * @method listgroups
 * @description Fetches attendee groups for a meeting based on specified filters.
 */
  listgroups = () => {
    try {
      const { eventId, GroupsFilter, pageNoForGroup } = this.state;
      this.setState({
        loading: true,
      });
      const body = {
        projectId: eventId,
        page_no: pageNoForGroup,
        GroupsFilter: GroupsFilter,
        searchText: this.state.searchText,
        limitForGroup: this.state.limitForGroup,
      };
      if (eventId) {
        this.props.attendeeGroupsForMeeting(body);
      } else {
      }
    } catch (error) { }
  };

  /**
 * @method searchForMeeting
 * @description Performs a search for meetings based on the provided searchText.
 */
  searchForMeeting = () => {
    try {
      const { eventId } = this.state;
      this.setState({
        loading: true,
      });
      const body = {
        projectId: eventId,
        searchText: this.state.searchText.trim(),
      };
      if (eventId) {
        this.props.searchForMeeting(body);
      } else {
      }
    } catch (error) { }
  };

  /**
 * @method handleChooseGuest
 * @description Toggles the ChooseGuest state and triggers a search meeting API call.
 */
  handleChooseGuest = () => {
    this.setState(
      {
        ChooseGuest: !this.state.ChooseGuest,
      },
      () => this.callSearchMeetingApi(),
    );
  };

  /**
 * @method callSearchMeetingApi
 * @description Calls the search meeting API based on certain conditions.
 */
  callSearchMeetingApi = () => {
    let {
      GroupsFilter,
      AudienceFilter,
      AttendeesFilter,
      ChooseGuest,
      searchText,
    } = this.state;

    if (
      !GroupsFilter &&
      !AttendeesFilter &&
      !AudienceFilter &&
      ChooseGuest &&
      searchText.length === 0
    ) {
      this.setState({
        mergeArray: [],
      });
    }
  };


  /**
   * @method handleChooseGuestHide
   * @description Hides the ChooseGuest component.
   * @param {Event} event - The event object.
   */
  handleChooseGuestHide = (event) => {
    this.setState({
      ChooseGuest: false,
    });
  };


  /**
   * @method ApplyAudienceFilter
   * @description Applies the Audience filter and triggers the corresponding data fetch.
   */
  ApplyAudienceFilter = () => {
    this.setState(
      {
        GroupsFilter: false,
        AttendeesFilter: false,
        AudienceFilter: !this.state.AudienceFilter,
        limitForAttendee: 100,
        limitForAudience: 100,
        limitForGroup: 100,
      },
      () => {
        if (this.state.AudienceFilter) {
          this.listAudienceGroups();
        } else {
          this.setState({
            audienceData: [],
          });
        }
      },
    );
  };

  /**
 * @method ApplyGroupsFilter
 * @description Applies the Groups filter and triggers the corresponding data fetch.
 */
  ApplyGroupsFilter = () => {
    this.setState(
      {
        GroupsFilter: !this.state.GroupsFilter,
        AttendeesFilter: false,
        AudienceFilter: false,
        limitForAttendee: 100,
        limitForAudience: 100,
        limitForGroup: 100,
      },
      () => this.callGroup(),
    );
  };

  /**
 * @method callGroup
 * @description Calls the group data fetch based on certain conditions.
 */

  callGroup = () => {
    if (this.state.GroupsFilter) {
      this.listgroups();
    } else {
      this.setState({
        attendeesGroups: [],
      });
    }
  };

  /**
 * @method ApplyAttendeesFilte
 * @description Applies the Attendees filter and triggers the corresponding data fetch for attendees not added to the meeting.
 */
  ApplyAttendeesFilte = () => {
    this.setState(
      {
        GroupsFilter: false,
        AttendeesFilter: !this.state.AttendeesFilter,
        AudienceFilter: false,
        limitForAttendee: 100,
        limitForAudience: 100,
        limitForGroup: 100,
      },
      () => {
        if (this.state.AttendeesFilter) {
          this.listAttendeesOfNotAddedToMeeting();
        } else {
          this.setState({
            listOfAttendeesNotAddedToMeeting: [],
          });
        }
      },
    );
  };

  /**
 * @method handleCloseChooseAudience
 * @description Closes the ChooseAudience component.
 * @param {Event} event - The event object.
 */
  handleCloseChooseAudience = (event) => {
    this.setState({
      ChooseAudience: false,
    });
  };

  /**
 * @method handleListKeyDown
 * @description Handles the keydown event and closes ChooseAudience and SelectGroups if Tab key is pressed.
 * @param {Event} event - The keydown event object.
 */
  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        ChooseAudience: false,
        SelectGroups: false,
      });
    }
  }


  /**
   * @method isValid
   * @description Validates the searchText to ensure it is a valid email.
   * @returns {boolean} - Returns true if the email is valid, false otherwise.
   */
  isValid = () => {
    try {
      const { searchText } = this.state;
      let error = {};
      const regexForEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let formIsValid = true;

      if (!regexForEmail.test(searchText)) {
        formIsValid = false;
        error['searchText'] = 'Please enter a valid email';
      }
      this.setState({
        errors: error,
      });
      return formIsValid;
    } catch (error) { }
  };

  /**
 * @method handleOnKeyDown
 * @description Handles the keydown event and triggers checkDisabledAttendee API call on pressing Enter.
 * @param {Event} e - The keydown event object.
 */
  handleOnKeyDown = (e) => {
    let { searchText } = this.state;
    if (e.keyCode == 13 && e.key === 'Enter') {
      if (this.isValid()) {
        this.props.checkDisabledAttendee({
          projectId: this.state.eventId,
          email: searchText
        });
      }
    }
  };

  /**
 * @method getData
 * @description Gets data based on the selected filter (Attendees, Groups, Audience, or Search).
 */
  getData = () => {
    let { GroupsFilter, AttendeesFilter, searchText, AudienceFilter } =
      this.state;
    if (AttendeesFilter) {
      this.listAttendeesOfNotAddedToMeeting();
    } else if (GroupsFilter) {
      this.listgroups();
    } else if (AudienceFilter) {
      this.listAudienceGroups();
    } else {
      if (searchText.length > 2) {
        this.searchForMeeting();
      } else {
        this.setState({
          mergeArray: [],
        });
      }
    }
  };

  /**
 * @method handleChange
 * @description Handles the change event in the input field and updates the searchText, triggering data fetch.
 * @param {Event} e - The change event object.
 */
  handleChange = (e) => {
    this.setState(
      {
        ChooseGuest: true,
        searchText: e.target.value,
        limitForAttendee: 100,
        limitForGroup: 100,
        limitForAudience: 100,
      },
      () => {
        this.getData();
      },
    );
  };

  /**
 * @method getAudienceContact
 * @description Gets audience contact data based on the provided group data.
 * @param {Object} data - The group data.
 */
  getAudienceContact = (data) => {
    this.setState({
      loading: true,
    });
    this.props.getAudienceContact({
      group_id: data._id,
      type: data.type,
      projectId: this.state.eventId,
    });
  };


  /**
   * @method handleSelectResult
   * @description Handles the selection of a result and updates the selectedEmail state accordingly.
   * @param {Object} data - The selected data.
   */
  handleSelectResult = (data) => {
    if (data.type === 'attendee') {
      this.state.selectedEmail.push(data);
    } else {
      if (
        (data.type === 'attendeesGroup' && data.totalaGroupsAttendee > 20) ||
        (data.type === 'audience' && data.totalContacts > 20)
      ) {
        this.setState({
          openAddDataPopup: true,
          dataForGetContact: data,
        });
      } else {
        this.getAudienceContact(data);
      }
    }

    if (data.type === 'attendee') {
      this.setState(
        {
          ChooseGuest: false,
          GroupsFilter: false,
          AudienceFilter: false,
        },
        () => this.removeDuplicateValue(),
      );
    }
  };

  /**
 * @method handleSelectResultForSearch
 * @description Handles the selection of a result during search and updates the selectedEmail state accordingly.
 * @param {Object} data - The selected data.
 */
  handleSelectResultForSearch = (data) => {
    let start = Date(Date.now()).toString();
    if (data.type === 'attendee') {
      this.state.selectedEmail.push(data);
    } else if (data.type === 'audience') {
      this.state.selectedEmail.push(data);
    } else {
      this.state.selectedEmail.push(data);
    }
    let end = Date(Date.now()).toString();

    this.setState(
      {
        ChooseGuest: false,
        GroupsFilter: false,
        AudienceFilter: false,
      },
      () => this.removeDuplicateValue(),
    );
  };

  /**
 * @method handleSelectAdd
 * @description Handles the selection of 'Add' and updates the selectedEmail state with selected attendees.
 */
  handleSelectAdd = () => {
    Array.isArray(this.state.selectAttendee) &&
      this.state.selectAttendee.length > 0 &&
      this.state.selectAttendee.map((email) => {
        this.state.selectedEmail.push(email);
      });
    this.setState(
      {
        ChooseGuest: false,
        AttendeesFilter: false,
        selectAttendee: [],
        listOfAttendeesNotAddedToMeeting: [],
        selectAllCheckedAttendees: false,
      },
      () => this.removeDuplicateValue(),
    );
  };

  /**
 * @method handleRemove
 * @description Handles the removal of an email from the selectedEmail state.
 * @param {string} email - The email to be removed.
 */
  handleRemove = (email) => {
    if (
      Array.isArray(this.state.selectedEmail) &&
      this.state.selectedEmail.length > 0
    ) {
      let filteredValue = this.state.selectedEmail.filter(
        (value) => value != email,
      );
      let filteredValue1 = this.state.loadMoreArray.filter(
        (value) => value != email,
      );
      this.setState({
        filteredValue: '',
        selectedEmail: filteredValue,
        loadMoreArray: filteredValue1,
        loadMoreNumber: Math.ceil(filteredValue.length / 20)
      });
    }
  };

  /**
 * @method handleSelectAll
 * @description Handles the selection of 'Select All' and updates the selectedEmailArr state.
 * @param {Event} e - The change event object.
 */
  handleSelectAll = (e) => {
    this.setState({
      selectAllChecked: e.target.checked,
    });
    if (e.target.checked === true) {
      this.setState({
        selectedEmailArr: this.state.selectedEmail,
      });
    }
    if (e.target.checked === false) {
      this.setState({
        selectedEmailArr: [],
      });
    }
  };

  /**
 * @method handleSelectAllAttendees
 * @description Handles the selection of 'Select All Attendees' and updates the selectAttendee state.
 * @param {Event} e - The change event object.
 */
  handleSelectAllAttendees = (e) => {
    let { listOfAttendeesNotAddedToMeeting } = this.state;
    this.setState({
      selectAllCheckedAttendees: e.target.checked,
    });
    if (e.target.checked === true) {
      Array.isArray(listOfAttendeesNotAddedToMeeting) &&
        listOfAttendeesNotAddedToMeeting.length > 0 &&
        listOfAttendeesNotAddedToMeeting.map((data) => {
          this.state.selectAttendee.push(data.email);
        });
    }
    if (e.target.checked === false) {
      this.setState({
        selectAttendee: [],
      });
    }
  };


  /**
   * @method handleSelectAttendee
   * @description Handles the selection of an attendee and updates the selectAttendee state.
   * @param {Event} e - The change event object.
   * @param {Object} data - The attendee data.
   */
  handleSelectAttendee = (e, data) => {
    try {
      if (data.email) {
        const index = this.state.selectAttendee.indexOf(data.email);
        if (index === -1) {
          this.state.selectAttendee.push(data.email);
        } else {
          this.state.selectAttendee.splice(index, 1);
        }
        this.setState({
          changeState: !this.state.changeState,
        });
      }

      if (
        this.state.selectAttendee.length ===
        this.state.listOfAttendeesNotAddedToMeeting.length
      ) {
        this.setState({
          selectAllCheckedAttendees: true,
        });
      } else {
        this.setState({
          selectAllCheckedAttendees: false,
        });
      }
    } catch (error) { }
  };

  /**
 * @method handleSelectSingle
 * @description Handles the selection of a single email. Toggles the email's selection status, updates the state, and checks if all emails are selected.
 * @param {Event} e - The change event object.
 * @param {string} email - The email to be selected or deselected.
 */
  handleSelectSingle = (e, email) => {
    try {
      let { selectedEmailArr } = this.state;
      if (email) {
        const index = this.state.selectedEmailArr.indexOf(email);
        if (index === -1) {
          selectedEmailArr = selectedEmailArr.push(email);
        } else {
          selectedEmailArr.splice(index, 1);
        }
      }
      this.setState({
        changeState: !this.state.changeState,
      });
      if (
        this.state.selectedEmailArr.length === this.state.selectedEmail.length
      ) {
        this.setState({
          selectAllChecked: true,
        });
      } else {
        this.setState({
          selectAllChecked: false,
        });
      }
    } catch (error) { }
  };


  /**
   * @method removeDuplicateValue
   * @description Removes duplicate values from the selectedEmail state. Updates the state and triggers the loadMore method.
   */
  removeDuplicateValue = () => {
    const uniqueValue = this.state.selectedEmail.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.email === obj.email),
    );
    this.setState(
      {
        selectedEmail: uniqueValue,
        changeState: !this.state.changeState,
      },
      () => this.loadMore(),
    );
  };

  /**
 * @method loadMore
 * @description Loads more emails based on the loadMoreNumber state. Updates the loadMoreArray state and handles disabling loadMorePoints.
 */

  setLoadMoreNumber = () => {
    let loadMoreNumber = this.state.loadMoreNumber + 1;
    this.setState({
      loadMoreNumber: loadMoreNumber
    }, () => this.loadMore());
  };

  loadMore = () => {
    const { selectedEmail, loadMoreNumber, increaseLoadMoreBy } = this.state;
    const newLoadMoreNumber = selectedEmail.length && selectedEmail.length >= loadMoreNumber * increaseLoadMoreBy ? loadMoreNumber * increaseLoadMoreBy : selectedEmail.length;
    const loadMoreArray = [];
    for (let i = 0; i < newLoadMoreNumber; i++) {
      loadMoreArray.push(selectedEmail[i]);
    }
    this.setState({
      disableLoadMorePoints: loadMoreArray.length === selectedEmail.length,
      loadMoreArray: loadMoreArray
    }, () => {
      this.removeSelectedEmail();
    });
  };

  /**
 * @method setarrayValue
 * @description Sets the array value for contactData by concatenating it with the selectedEmail state after checking for valid array conditions.
 */
  setarrayValue = () => {
    let contactData = [];
    {
      Array.isArray(this.state.contact) &&
        this.state.contact.length > 0 &&
        this.setState(
          {
            selectedEmail: this.state.selectedEmail.concat(this.state.contact),
          },
          () => {
            this.removeDuplicateValue();
          },
        );
    }
  };

  /**
 * @method handleLoadMoreForAttendee
 * @description Handles loading more attendees for the attendee list not added to the meeting.
 */
  handleLoadMoreForAttendee = () => {
    this.setState(
      {
        pageNoForAttendee: this.state.pageNoForAttendee + 1,
        limitForAttendee: this.state.limitForAttendee + 100,
      },
      () => this.listAttendeesOfNotAddedToMeeting(),
    );
  };

  /**
 * @method handleLoadMoreForAudience
 * @description Handles loading more audience groups.
 */
  handleLoadMoreForAudience = () => {
    this.setState(
      {
        pageNoForAudience: this.state.pageNoForAudience + 1,
        limitForAudience: this.state.limitForAudience + 100,
      },
      () => this.listAudienceGroups(),
    );
  };

  /**
 * @method handleLoadMoreForAttendeeGroup
 * @description Handles loading more attendee groups.
 */
  handleLoadMoreForAttendeeGroup = () => {
    this.setState(
      {
        pageNoForGroup: this.state.pageNoForGroup + 1,
        limitForGroup: this.state.limitForGroup + 100,
      },
      () => this.attendeeGroupsForMeeting(),
    );
  };


  /**
   * @method closeAddDataPopup
   * @description Closes the AddDataPopup by updating the openAddDataPopup state to false.
   */
  closeAddDataPopup = () => {
    this.setState({
      openAddDataPopup: false,
    });
  };

  /**
 * @method removeSelectedEmail
 * @description Removes selected emails from the relevant state based on the current filter (Attendees, Groups, or Audience).
 */
  removeSelectedEmail = () => {
    let {
      listOfAttendeesNotAddedToMeeting,
      selectedEmail,
      audienceData,
      attendeesGroups,
      mergeArray,
    } = this.state;
    if (this.state.AttendeesFilter) {
      const filteedAttendee = listOfAttendeesNotAddedToMeeting.filter(
        (obj1) => !selectedEmail.some((obj2) => obj1.email === obj2.email),
      );
      this.setState({
        listOfAttendeesNotAddedToMeeting: filteedAttendee,
      });
    }
  };

  /**
   * @function render
   * @description Renders the CreateMeeting component UI.
   * @returns {JSX.Element} Rendered component.
   */
  render() {
    const {
      loadMoreArray,
      mergeArray,
      audienceData,
      attendeesGroups,
      ChooseGuest,
      AudienceFilter,
      GroupsFilter,
      AttendeesFilter,
      listOfAttendeesNotAddedToMeeting,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Grid size={{xs: 12, md:6, lg:4}}>
          <Box
            sx={{
              border: '1px solid #EDECF5',
              borderRadius: '8px',
              backgroundColor: '#fff',
              height: '100%',
            }}
          >
            <Box px={3} py={1.8} sx={{ borderBottom: '1px solid #EDECF5' }}>
              <span className="paragraph">
                {this.props.users.languageStateForRedux?.formfields?.meetings?.guests} ({this.state.selectedEmail?.length})
              </span>


              {/*  <span><button onClick={this.loadMore}>Reload</button></span> */}
            </Box>
            <Box>

            </Box>
            <Box component={'div'} px={3} pt={4} pb={1.5}>
              <Box
                component={Grid}
                spacing={4}
                container
                className="inputField"
              >
                <Grid size={{xs: 12,}} mt={-1}>
                  {/* search and select */}
                  <Box
                    style={{
                      position: 'relative',
                    }}
                  >
                    <span className="error_mesage required">
                      {' '}
                      {this.state.errors?.searchText}{' '}
                    </span>
                    <Box
                      component={OutlinedInput}
                      sx={{
                        marginTop: '2px',
                        padding: '3px 0px',
                      }}
                      notched={false}
                      size="medium"
                      fullWidth
                      placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.placeholder}
                      name="searchText"
                      className=""
                      ref={this.anchorRef}
                      aria-controls={ChooseGuest ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleChooseGuest}
                      onChange={this.handleChange}
                      autoComplete="off"
                      onKeyDown={this.handleOnKeyDown}
                      value={this.state.searchText}
                      inputProps={{ maxLength: 63 }}
                    />
                    <span className="error_mesage required">
                      {' '}
                      {this.props.errorsFromCreateMeeting.selectedEmail}{' '}
                    </span>

                    <Popper
                      open={ChooseGuest}
                      style={{ right: '0px', zIndex: 1 }}
                      placement="bottom-start"
                      transition
                      anchorEl={this.anchorRef.current}
                      role={undefined}
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            sx={{
                              backgroundColor: theme.palette.common.white,
                              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                              borderRadius: '4px',
                              marginTop: '8px',
                            }}
                          >
                            <ClickAwayListener
                              onClickAway={this.handleChooseGuestHide}
                            >
                              <Box
                                id="menu-list-grow"
                                onKeyDown={this.handleListKeyDown}
                              >
                                <Box className="search-filter">
                                  <Typography
                                    component={'p'}
                                    className="filter-title"
                                    mb={2}
                                  >
                                    {this.props.users.languageStateForRedux?.formfields?.meetings?.quickFilters}
                                  </Typography>
                                  <Grid container spacing={1}>
                                    <Grid >
                                      <Box
                                        className={
                                          AudienceFilter
                                            ? 'filter-items active'
                                            : 'filter-items'
                                        }
                                        onClick={this.ApplyAudienceFilter}
                                      >
                                        <AudienceFilterIcon className="icon" />
                                        <span>{this.props.users.languageStateForRedux?.overviewTab?.audience}</span>
                                      </Box>
                                    </Grid>
                                    <Grid >
                                      <Box
                                        className={
                                          GroupsFilter
                                            ? 'filter-items active'
                                            : 'filter-items'
                                        }
                                        onClick={this.ApplyGroupsFilter}
                                      >
                                        <GroupsFilterIcon className="icon" />
                                        <span>{this.props.users.languageStateForRedux?.overviewTab?.groups}</span>
                                      </Box>
                                    </Grid>
                                    <Grid >
                                      <Box
                                        className={
                                          AttendeesFilter
                                            ? 'filter-items active'
                                            : 'filter-items'
                                        }
                                        onClick={this.ApplyAttendeesFilte}
                                      >
                                        <AttendeesFilterIcon className="icon" />
                                        <span>{this.props.users.languageStateForRedux?.overviewTab?.attendees}</span>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <MenuList
                                  style={{
                                    maxHeight: '330px',
                                    overflow: 'scroll',
                                    paddingTop: '0',
                                  }}
                                >
                                  {GroupsFilter && (
                                    <>
                                      {Array.isArray(attendeesGroups) &&
                                        attendeesGroups.length > 0 ? (
                                        attendeesGroups.map((data) => {
                                          return (
                                            <MenuItem
                                              className="search-item"
                                              disabled={
                                                data.totalaGroupsAttendee === 0
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                this.handleSelectResult(data)
                                              }
                                            >
                                              <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                              >
                                                <Avatar className="avatar">
                                                  G
                                                </Avatar>
                                                <span>
                                                  {data.attendeesGroupName +
                                                    ' (' +
                                                    data.totalaGroupsAttendee +
                                                    ')'}
                                                </span>
                                              </Box>
                                            </MenuItem>
                                          );
                                        })
                                      ) : (
                                        <Box style={{ textAlign: 'center' }}>
                                          <span>
                                            {' '}
                                            {this.props.users.languageStateForRedux?.common?.noRecords}
                                          </span>
                                        </Box>
                                      )}
                                      {this.state.totalAttendeeGroups > 100 && (
                                        <Stack mt={3} alignItems={'center'}>
                                          <Button
                                            disableElevation
                                            variant="text"
                                            color="primary"
                                            style={{ maxWidth: '120px' }}
                                            onClick={
                                              this
                                                .handleLoadMoreForAttendeeGroup
                                            }
                                            disabled={
                                              this.state.totalAttendeeGroups ===
                                                this.state.attendeesGroups.length
                                                ? true
                                                : false
                                            }
                                          >
                                            Load More
                                          </Button>
                                        </Stack>
                                      )}
                                    </>
                                  )}

                                  {AudienceFilter && (
                                    <>
                                      {Array.isArray(audienceData) &&
                                        audienceData.length > 0 ? (
                                        audienceData.map((data) => {
                                          return (
                                            <MenuItem
                                              className="search-item"
                                              disabled={
                                                data.totalContacts === 0
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                this.handleSelectResult(data)
                                              }
                                            >
                                              <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                              >
                                                <Avatar className="avatar">
                                                  A
                                                </Avatar>
                                                <span>
                                                  {data.groupName +
                                                    ' (' +
                                                    data.totalContacts +
                                                    ')'}
                                                </span>
                                              </Box>
                                            </MenuItem>
                                          );
                                        })
                                      ) : (
                                        <Box style={{ textAlign: 'center' }}>
                                          <span>
                                            {' '}
                                            {this.props.users.languageStateForRedux?.common?.noRecords}
                                          </span>
                                        </Box>
                                      )}
                                      {this.state.totalAudience > 100 &&
                                        this.state.totalAudience !==
                                        this.state.audienceData.length && (
                                          <Stack mt={3} alignItems={'center'}>
                                            <Button
                                              disableElevation
                                              variant="text"
                                              color="primary"
                                              style={{ maxWidth: '120px' }}
                                              onClick={
                                                this.handleLoadMoreForAudience
                                              }
                                              disabled={
                                                this.state.totalAudience ===
                                                  this.state.audienceData.length
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Load More
                                            </Button>
                                          </Stack>
                                        )}
                                    </>
                                  )}

                                  {AttendeesFilter && (
                                    <>
                                      {Array.isArray(
                                        listOfAttendeesNotAddedToMeeting,
                                      ) &&
                                        listOfAttendeesNotAddedToMeeting.length >
                                        0 ? (
                                        listOfAttendeesNotAddedToMeeting.map(
                                          (data) => {
                                            return (
                                              <MenuItem
                                                className="search-item"
                                                onClick={() =>
                                                  this.handleSelectResult(data)
                                                }
                                              >
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                >
                                                  <Avatar className="avatar">
                                                    {data.profileImage ? (
                                                      <img
                                                        width="38"
                                                        alt="profile image"
                                                        src={
                                                          IMAGE_URL +
                                                          '/profilePictures/' +
                                                          data.profileImage
                                                        }
                                                      />
                                                    ) : (
                                                      <img
                                                        width="16"
                                                        alt="profile image"
                                                        src={userImageIcon}
                                                      />
                                                    )}
                                                  </Avatar>
                                                  <span>{data.email}</span>
                                                </Box>
                                              </MenuItem>
                                            );
                                          },
                                        )
                                      ) : (
                                        <Box style={{ textAlign: 'center' }}>
                                          <span>
                                            {' '}
                                            {this.props.users.languageStateForRedux?.common?.noRecords}
                                          </span>
                                        </Box>
                                      )}
                                      {this.state.totalAttendees > 100 && (
                                        <Stack mt={3} alignItems={'center'}>
                                          <Button
                                            disableElevation
                                            variant="text"
                                            color="primary"
                                            style={{ maxWidth: '120px' }}
                                            onClick={
                                              this.handleLoadMoreForAttendee
                                            }
                                            disabled={
                                              this.state.totalAttendees ===
                                                this.state
                                                  .listOfAttendeesNotAddedToMeeting
                                                  .length
                                                ? true
                                                : false
                                            }
                                          >
                                            Load More
                                          </Button>
                                        </Stack>
                                      )}
                                    </>
                                  )}

                                  {!AttendeesFilter &&
                                    !AudienceFilter &&
                                    !GroupsFilter && (
                                      <>
                                        {Array.isArray(mergeArray) &&
                                          mergeArray.length > 0 ? (
                                          <>
                                            {mergeArray.some(
                                              (obj) => obj.type === 'audience',
                                            ) && (
                                                <>
                                                  <Box
                                                    sx={{
                                                      backgroundColor: '#ffffff',
                                                      position: 'sticky',
                                                      top: '-1px',
                                                      zIndex: '1',
                                                    }}
                                                    py={1}
                                                    px={2}
                                                  >
                                                    <Box
                                                      className={'results-title'}
                                                      sx={{
                                                        maxWidth: 'max-content',
                                                      }}
                                                    >
                                                      <AudienceFilterIcon className="icon" />
                                                      <Box
                                                        ml={1}
                                                        component={'span'}
                                                      >
                                                        Audience
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </>
                                              )}
                                            {mergeArray.map((audience) => {
                                              return (
                                                audience.type ===
                                                'audience' && (
                                                  <MenuItem
                                                    className="search-item"
                                                    disabled={
                                                      audience.totalContacts ===
                                                        0
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() =>
                                                      this.handleSelectResult(
                                                        audience,
                                                      )
                                                    }
                                                  >
                                                    <Box
                                                      display={'flex'}
                                                      alignItems={'center'}
                                                    >
                                                      <Avatar className="avatar">
                                                        G
                                                      </Avatar>
                                                      <span>
                                                        {audience.type ===
                                                          'audience' &&
                                                          audience.groupName +
                                                          ' (' +
                                                          audience.totalContacts +
                                                          ')'}
                                                      </span>
                                                    </Box>
                                                  </MenuItem>
                                                )
                                              );
                                            })}

                                            {mergeArray.some(
                                              (obj) =>
                                                obj.type === 'attendeesGroup',
                                            ) && (
                                                <>
                                                  <Box
                                                    sx={{
                                                      backgroundColor: '#ffffff',
                                                      position: 'sticky',
                                                      top: '-1px',
                                                      zIndex: '1',
                                                    }}
                                                    py={1}
                                                    px={2}
                                                  >
                                                    <Box
                                                      className={'results-title'}
                                                      sx={{
                                                        maxWidth: 'max-content',
                                                      }}
                                                    >
                                                      <GroupsFilterIcon className="icon" />
                                                      <Box
                                                        ml={1}
                                                        component={'span'}
                                                      >
                                                        Groups
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </>
                                              )}
                                            {mergeArray.map((group) => {
                                              return (
                                                group.type ===
                                                'attendeesGroup' && (
                                                  <MenuItem
                                                    className="search-item"
                                                    disabled={
                                                      group.totalaGroupsAttendee ===
                                                        0
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() =>
                                                      this.handleSelectResult(
                                                        group,
                                                      )
                                                    }
                                                  >
                                                    <Box
                                                      display={'flex'}
                                                      alignItems={'center'}
                                                    >
                                                      <Avatar className="avatar">
                                                        G
                                                      </Avatar>
                                                      <span>
                                                        {group.type ===
                                                          'attendeesGroup' &&
                                                          group.attendeesGroupName +
                                                          ' (' +
                                                          group.totalaGroupsAttendee +
                                                          ')'}
                                                      </span>
                                                    </Box>
                                                  </MenuItem>
                                                )
                                              );
                                            })}
                                            {mergeArray.some(
                                              (obj) => obj.type === 'attendee',
                                            ) && (
                                                <>
                                                  <Box
                                                    sx={{
                                                      backgroundColor: '#ffffff',
                                                      position: 'sticky',
                                                      top: '-1px',
                                                      zIndex: '1',
                                                    }}
                                                    py={1}
                                                    px={2}
                                                  >
                                                    <Box
                                                      className={'results-title'}
                                                      sx={{
                                                        maxWidth: 'max-content',
                                                      }}
                                                    >
                                                      <AttendeesFilterIcon className="icon" />
                                                      <Box
                                                        ml={1}
                                                        component={'span'}
                                                      >
                                                        Attendees
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </>
                                              )}
                                            {mergeArray.map((attendee) => {
                                              return (
                                                attendee.type ===
                                                'attendee' && (
                                                  <MenuItem
                                                    className="search-item"
                                                    onClick={() =>
                                                      this.handleSelectResultForSearch(
                                                        attendee,
                                                      )
                                                    }
                                                  >
                                                    <Box
                                                      display={'flex'}
                                                      alignItems={'center'}
                                                    >
                                                      <Avatar className="avatar">
                                                        {attendee.profileImage ? (
                                                          <img
                                                            width="38"
                                                            alt="profile image"
                                                            src={
                                                              IMAGE_URL +
                                                              '/profilePictures/' +
                                                              attendee.profileImage
                                                            }
                                                          />
                                                        ) : (
                                                          <img
                                                            width="16"
                                                            alt="profile image"
                                                            src={userImageIcon}
                                                          />
                                                        )}
                                                      </Avatar>
                                                      <span>
                                                        {attendee.type ===
                                                          'attendee' &&
                                                          attendee.email}
                                                      </span>
                                                    </Box>
                                                  </MenuItem>
                                                )
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <Box style={{ textAlign: 'center' }}>
                                            <span>
                                              {' '}
                                              {this.props.users.languageStateForRedux?.common?.noRecords}
                                            </span>
                                          </Box>
                                        )}
                                      </>
                                    )}
                                </MenuList>
                              </Box>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </Box>

                  {/* selected-guest list start here*/}
                  <Box className="selected-guest">
                    {loadMoreArray && loadMoreArray.length ? (
                      <span className="paragraph">{this.props.users.languageStateForRedux?.formfields?.meetings?.selectedRecipients} </span>
                    ) : (
                      ''
                    )}
                    <Box sx={{ overflow: 'auto', maxHeight: '810px' }}>
                      {Array.isArray(loadMoreArray) &&
                        loadMoreArray.length > 0 ? (
                        loadMoreArray.map((data) => {
                          return (
                            <MenuItem className="search-item">
                              <Box className='recipents-email' display={'flex'} alignItems={'center'}>
                                <Avatar className="avatar">
                                  {data.profileImage ? (
                                    <img
                                      width="38"
                                      alt="profile image"
                                      src={
                                        IMAGE_URL +
                                        `${data.type === "audience" ? '/contactsImages/' : '/profilePictures/'}` +
                                        data.profileImage
                                      }
                                    />
                                  ) : (
                                    <img
                                      width="16"
                                      alt="profile image"
                                      src={userImageIcon}
                                    />
                                  )}
                                </Avatar>
                                <Tooltip
                                  placement="bottom"
                                  title={data.email}
                                  arrow
                                >

                                  <span className='email'>{data.email}</span>
                                </Tooltip>
                              </Box>
                              <Box className='recipents-status'>
                                {data?.responseStatus == "accepted" &&
                                  <Box component={'span'} className='small-subtitle table-status success'>
                                    Accepted
                                  </Box>
                                }
                                {data?.responseStatus == "declined" && <Box component={'span'} className='small-subtitle table-status danger'>
                                  Declined
                                </Box>
                                }
                                {/*  {data?.responseStatus == "needsAction" && <Box component={'span'} className='small-subtitle table-status light'>
                                  Need action
                                </Box>
                                } */}
                                {!data?.comment && data?.responseStatus == "tentative" &&
                                  <Box component={'span'} className='small-subtitle table-status light'>
                                    Tentative
                                  </Box>
                                }

                                {data?.comment && data?.responseStatus == "tentative" &&
                                  <Box component={'span'} className='small-subtitle table-status light'>

                                    Tentative
                                    <Box
                                      component={Tooltip}
                                      placement="bottom"
                                      title={data?.comment}
                                      arrow
                                      ml={0.5}
                                    >
                                      <AccessTimeIcon fontSize='small' />
                                    </Box>
                                  </Box>
                                }

                                <Tooltip
                                  placement="right"
                                  title="Remove"
                                  arrow
                                  onClick={() => this.handleRemove(data)}
                                >
                                  <CloseIcon className="icon" />
                                </Tooltip>
                              </Box>
                            </MenuItem>
                          );
                        })
                      ) : (
                        <>
                          {/* if no guests are selected */}
                          <Stack mt={4} spacing={1.5} alignItems={'center'}>
                            <AddGuestIcon className="small-subtitle" />
                            <Box className="small-subtitle">
                              {this.props.users.languageStateForRedux?.formfields?.meetings?.Msg}
                            </Box>
                          </Stack>
                        </>
                      )}
                    </Box>
                    {this.state.selectedEmail &&
                      this.state.selectedEmail.length > 20 && (
                        <Stack mt={3} alignItems={'center'}>
                          <Button
                            disableElevation
                            variant="text"
                            color="primary"
                            style={{ maxWidth: '120px' }}
                            onClick={this.setLoadMoreNumber}
                            disabled={
                              this.state.loadMoreArray.length === this.state.selectedEmail.length  ? true : false
                            }
                          >
                            Load More
                          </Button>
                        </Stack>
                      )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Modal
          open={this.state.openAddDataPopup}
          onClose={this.closeAddDataPopup}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                Confirmation
              </Box>
              <p className="small-subtitle">
                Are you trying to add a large number of recipients? Are you sure
                you want to do that?
              </p>
            </Box>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevationon
                      onClick={() =>
                        this.getAudienceContact(this.state.dataForGetContact)
                      }
                    >
                      ADD
                    </Button>

                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() => this.closeAddDataPopup()}
                    >
                      CANCEL
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {this.state.loading ? <Loader /> : null}
      </ThemeProvider>
    );
  }
}


/**
 * @function mapStateToProps
 * @description Maps the Redux state to component props.
 * @param {Object} state - Redux state object.
 * @returns {Object} Props derived from the Redux state.
 */
const mapStateToProps = (state) => ({
  campaigns: state.Campaigns,
  attendees: state.Attendees,
  projects: state.Projects,
  Meeting: state.Meeting,
  contacts: state.Contacts,
  groups: state.Groups,
  events: state.Events,
  users: state.Users,
});

/**
 * @function mapActionsToProps
 * @description Maps Redux actions to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} Props containing action functions.
 */
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAudienceGroups: Actions.listAudienceGroupsRequest,
      listAttendeesOfNotAddedToMeeting:
        Actions.listAttendeesNotAddedToMeetingRequest,
      attendeeGroupsForMeeting: Actions.attendeeGroupsForMeetingRequest,
      audienceForMeeting: Actions.audienceForMeetingRequest,
      searchForMeeting: Actions.searchForMeetingRequest,
      getAudienceContact: Actions.getAudienceContactRequest,
      checkDisabledAttendee: Actions.checkDisabledAttendeeRequest,
    },
    dispatch,
  ); 
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(MeetingRecipients);

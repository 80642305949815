//@ts-nocheck
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Box } from '@mui/material';
import { ContactState } from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { getSystemLevelPermissions, UserData } from '../../../../helpers/common';
import { createNotification } from '../../../../helpers';
import axios from 'axios';
import { CustomField, CustomFields, CustomFields_State } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import OpportunityAccountsCustomFieldsAPI from 'ReduxStore/API/opportunity/opportunityCustomFieldsAPI';
import { getLinkedAccountDetails } from 'ReduxStore/API';
import Loader from 'Components/Loader/loader';
 
import { useNavigate, useLocation } from 'react-router-dom';

type Props = {
  contactId: string;
  reqFrom: string;
  handleTabChangeFromMainLayout: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
};

function LinkedAccounts({ contactId, handleTabChangeFromMainLayout ,reqFrom}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const { isLoading: isContactsLoading, openedContact } = useSelector(
    (state: any) => state.OpportunityContacts,
  ) as ContactState;
  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [sortBy, setSortBy] = useState<string>('account.name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [allowed, setAllowed] = useState<any>({});
  const [linkedAccountDetails, setLinkedAccountDetails] = useState<any>([]);
  const [loading, setLoding] =  useState<boolean>(false);
  const [accountCustomFields, setaccountCustomFields] =
  useState<CustomFields>({} as CustomFields);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoding(true)
        setAllowed(getSystemLevelPermissions()); // Set allowed permissions
  
        const res = await getLinkedAccountDetails(contactId, reqFrom); // Fetch linked account details
        if (res?.data?.linkedData) { // Check if the response contains linkedData
          setLinkedAccountDetails(res.data.linkedData); // Set the linked account details in state
          setLoding(false)
        }
  
        fetchAccountCustomField(); // Fetch account custom fields
      } catch (error) {
        console.error("Error fetching linked account details:", error);
      }
    };
  
    fetchData(); // Call the async function inside useEffect
  }, [contactId, reqFrom]); // Add dependencies if needed
  

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [sortBy, sortOrder]);

  const fetchAccountCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'accounts',
        from: 'viewAccount',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setaccountCustomFields(res.data.customFields);

    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Box>
      <TableContainer className="list-table">
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell
                className={
                  'long-text sticky-cell ' +
                  (sortBy === 'account.name'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('account.name')}
              >
                Account Name
              </TableCell>
              <TableCell
                className={
                  'text' +
                  (sortBy === 'contactDataTypeCf.fieldName'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('contactDataTypeCf.fieldName')}
              >
                Contact Type
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(linkedAccountDetails) &&
              linkedAccountDetails.length > 0 ?
              linkedAccountDetails.map((account, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell className="long-text sticky-cell link" onClick={(e) =>{
                       if (allowed['viewAccount']){
                        if(account.uniqueEijentId){
                          navigate(`/opportunity/accountview/${account.uniqueEijentId}`);
                      }
                       }else{
                        createNotification('error', "Sorry! You don't have permission to view this account");
                       }
                    }}>
                      {' '}
                      {account.name}
                    </TableCell>

                    <TableCell className="text">
                    {account.contactType}
                    </TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell colSpan={2} align='center'>
                  No Records
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
     {loading &&  <Loader />}
    </Box>
  );
}

export default LinkedAccounts;

import React from 'react'
import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme'
import CalendarFilled from '../../assets/images/icons/Calendar-filled.svg'
import TimeCircle from '../../assets/images/icons/TimeCircle.svg'
import RefreshIcon from '../../assets/images/icons/refresh-puple.svg'
import { NotificationContainer } from 'react-notifications'
import GamificationIcon from '../../../src/assets/images/icons/GamificationColor.svg'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import TotalRegistered from '../../assets/images/icons/TotalRegistered.svg'
import CheckedIn from '../../assets/images/icons/Checked-In.svg'
import CheckedOut from '../../assets/images/icons/Checked-Out.svg'
import NotCheckedIn from '../../assets/images/icons/NotChecked-In.svg'
import HelpTickets from '../../assets/images/icons/Help-Tickets.svg'
import ChatIcon from '../../assets/images/icons/ChatIcon.svg'
import FailedLogin from '../../assets/images/icons/FailedLogin.svg'
import UpcomingSessions from '../../assets/images/icons/UpcomingSessions.svg'
import SearchIcon from '../../assets/images/icons/search.svg'
import InputAdornment from '@mui/material/InputAdornment'

import groupDetailIcon from '../../assets/images/icons/early-access-group.svg'
import AgendaCalendarIcon from '../../assets/images/icons/AgendaCalendar.svg'
import ActivePagesIcon from '../../assets/images/icons/ActivePages.svg'
// import TrafficSourcesIcon from '../../assets/images/icons/TrafficSources.svg';

import EventIcon from '../../assets/images/icons/EventTimeCircle.svg'
import ActiveAttendeesIcon from '../../assets/images/icons/ActiveAttendees.svg'
import LocationIcon from '../../assets/images/icons/LocationRed.svg'
import DevicesIcon from '../../assets/images/icons/DeviceGraph.svg'

import StartIcon from '../../assets/images/icons/startTimer.svg'
import LineIcon from '../../assets/images/icons/dashedLine.svg'
import EndIcon from '../../assets/images/icons/endTimer.svg'

import Button from '@mui/material/Button'

import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import Checkbox from '@mui/material/Checkbox'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
} from '@mui/material'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FormControlLabel from '@mui/material/FormControlLabel'

import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { connect } from 'react-redux' 
import Loader from '../../Components/Loader/loader'
import RoundBadge from '../../Components/CustomBadges/RoundBadge'

import DashboardLayout from '../../Layouts/DashboardLayout'

import { Chart } from 'react-google-charts'
import moment from 'moment'
import EventCoundownTimer from './EventCoundownTimer'
import CountdownTimer from './CountdownTimer/countdownTimer'
import CountdownTimerReverse from './CountdownTimer/countdownTimerReverse'
import { createNotification } from '../../helpers'
import OfficialTimeClock from './officialTimeClock'
import ShowCounter from './EventCoundownTimer/ShowCounter'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Pagination from 'react-js-pagination'

const styles = {
  avatar: {
    backgroundColor: '#EDEBFF',
    width: '48px',
    height: '48px',
  },
}
class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      open1: false,
      refresh: false,
      selectedEventId: '',
      selectedEventName: '',
      events: [],
      report: {},
      loading: true,
      statusFilterOptionsList: ['Completed', 'Upcoming'],
      statusFilterOptionsSelected: [],
      refreshIntervalId: '',
      refreshIntervalPeriod: null,
      eventValue: null,
      searchText: '',
      page_no: 1,
      limit: 5,
      sort_by: 'totalUsers',
      order: 'desc',
      isExport: false,
      challengesList: [],
      startCount: 1,
      endCount: 0,
      itemCount: 5,
    }
    this.anchorRef = React.createRef()
    this.anchorRef1 = React.createRef()
    this.anchorRefRefresh = React.createRef()
  }

  componentDidMount() {
    //get list of published events to show in the dropdown select
    this.props.getPublishedEvents()
    //get the live report of last published event
    this.props.getReport({})
    this.getReportChallenges()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        })
      } else {
        this.setState({
          endCount: parseInt(
            this.props.report?.totalResultsChallenges?.totalResults,
          ),
        })
      }
    }

    if (
      this.props.report?.getReportChallengesSuccess === true &&
      this.props.report?.challenges !== prevProps.report?.challenges
    ) {
      this.setState(
        {
          challengesList: this.props?.report?.challenges,
          totalResults:
            this.props.report?.totalResultsChallenges?.totalResults,
          isExport: false,
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            })
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            })
          }
        },
      )
    }

    if (
      this.props.report.getPublishedEventsSuccess &&
      !prevProps.report.getPublishedEventsSuccess &&
      this.props.report.message !== prevProps.report.message
    ) {
      if (this.props.report.events && this.props.report.events.length) {
        this.setState({
          events: this.props.report.events,
          eventsComplete: this.props.report.events,
          selectedEventId: this.props.report.events[0]._id,
          eventValue: this.props.report.events[0].eventName,
        })
      }
    }

    if (
      this.props.report.getReportSuccess &&
      !prevProps.report.getReportSuccess &&
      this.props.report.message !== prevProps.report.message
    ) {
      this.setState({
        report: this.props.report.report,
      })
      this.setState({ loading: false })
    }

    if (
      this.props.report.error &&
      !prevProps.report.error &&
      this.props.report.message !== prevProps.report.message
    ) {
      this.setState({ loading: false })
    }
  }

  /**
   * when we click refresh now
   */
  getReport = () => {    
    //clear if any interval is running when click refresh now     
    if (this.state.refreshIntervalId !== '') {
      clearInterval(this.state.refreshIntervalId)
    }

    //call apis to get latest report
    this.setState({
      loading: true,
      refreshIntervalPeriod: null,
      refresh: false,
    })
    const requestbody = {
      eventId: this.state.selectedEventId,
    }
    this.props.getReport(requestbody)
    this.getReportChallenges()
  }

  /**
   * This function will call by setInterval function
   * when we click refresh after x mins
   */
  getReportByInterval = () => {
    const requestbody = {
      eventId: this.state.selectedEventId,
    }
    this.props.getReport(requestbody)
    this.getReportChallenges()    
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }))
  }

  handleToggle1 = () => {
    this.setState({
      open1: !this.state.open1,
    })
  }

  handleToggleRefresh = () => {
    this.setState((prevState) => ({
      refresh: !prevState.refresh,
    }))
  }

  handleClose = (event) => {
    this.setState({
      open: false,
      open1: false,
      refresh: false,
      events: this.state.eventsComplete,
    })
  }

  handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      this.setState({
        open: false,
        open1: false,
        refresh: false,
        searchEvent: '',
        events: this.state.eventsComplete,
      })
    }
  }

  changeEvent = (event) => {
    this.setState(
      {
        selectedEventId: event._id,
        selectedEventName: event.eventName,
        open: false,
        events: this.state.eventsComplete,
      },
      () => {
        this.getReport()
      },
    )
  }

  handleAgendaFilter = (event) => {
    try {
      let statusFilterOptionsSelectedArray = [
        ...this.state.statusFilterOptionsSelected,
      ]

      if (event.target.name === 'all') {
        if (event.target.checked === true) {
          statusFilterOptionsSelectedArray = [
            ...this.state.statusFilterOptionsList,
          ]
        } else {
          statusFilterOptionsSelectedArray = []
        }
      } else {
        let filterIndex = statusFilterOptionsSelectedArray.indexOf(
          event.target.name,
        )
        if (filterIndex > -1) {
          statusFilterOptionsSelectedArray.splice(filterIndex, 1)
        } else {
          statusFilterOptionsSelectedArray.push(event.target.name)
        }
      }

      this.setState({
        statusFilterOptionsSelected: [...statusFilterOptionsSelectedArray],
      })
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  /**
   * when we click refresh after x mins
   * 
   * it will call the api in specific time interval to get latest report
   * 
   * @param {number} interval 
   */
  refreshByInterval = (interval) => {
    if (this.state.refreshIntervalId !== '') {
      clearInterval(this.state.refreshIntervalId)
    }

    this.getReport()
    let refreshIntervalId = setInterval(() => {
      this.getReportByInterval()
    }, interval * 60 * 1000)
    this.setState({
      refreshIntervalId,
      refreshIntervalPeriod: interval,
      refresh: false,
    })
  }

  componentWillUnmount() {
    if (this.state.refreshIntervalId !== '') {
      clearInterval(this.state.refreshIntervalId)
    }
  }

  handleSearchEvents = (e, value) => {
    this.setState(
      {
        eventValue: value,
      },
      () => {
        if (
          this.state.eventValue !== '' &&
          this.state.eventValue !== undefined &&
          this.state.eventValue !== null
        ) {
          let obj = {}
          obj['eventName'] = this.state.eventValue.label
          obj['_id'] = this.state.eventValue._id
          this.changeEvent(this.state.eventValue)
        }
      },
    )
  }

  getReportChallenges = () => {
    const {
      page_no,
      searchText,
      selectedEventId,
      limit,
      sort_by,
      order,
    } = this.state
    const body = {
      page_no,
      searchText,
      projectId: selectedEventId,
      limit,
      sort_by,
      order: order,
    }
    this.props.getReportChallenges(body)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()
    this.setState(
      {
        page_no: 1,
        isExport: false,
        startCount: 1
      },
      () => this.getReportChallenges(),
    )
  }

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults:
          this.props.report?.totalResultsChallenges?.totalResults,
      }),
      () => {
        this.getReportChallenges()
      },
    )
  }

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
      },
      () => this.getReportChallenges(),
    )
  }

  checkIfUpcomingAgendaExists = (type) => {
    if (type === "Completed") {
      return this.state.report?.agenda?.data?.some(agenda =>
        moment
          .utc(agenda.dateTime)
          .add({
            hours: agenda.duration.hours,
            minutes:
              agenda.duration.minutes,
          })
          .valueOf() <
        moment.utc().valueOf()
      )
    } else if (type === "Upcoming") {
      return this.state.report?.agenda?.data?.some(agenda => moment
        .utc(agenda.dateTime)
        .valueOf() >
        moment.utc().valueOf()
      )
    }
  }

  optionsList = (val) => {
    let temp;
    switch (val) {
      case 'Completed': temp = this.props.users.languageStateForRedux?.reports?.completed;
      return temp;
      case 'Upcoming': temp = this.props.users.languageStateForRedux?.dropdowns?.meetings?.upcoming;
      return temp;

      default: temp = val;
      break;
    }
  }

  render() {
    const {
      itemCount,
      endCount,
      startCount,
      challengesList,
      searchText,
      open,
      open1,
      refresh,
      refreshIntervalPeriod,
      selectedEventName,
      events,
      report,
      loading,
      statusFilterOptionsList,
      statusFilterOptionsSelected,
      eventValue,
    } = this.state

    let eventOptions =
      this.state.events.length > 0 &&
      events.map((event) => {
        return {
          label: event.eventName,
          _id: event._id,
        }
      })

    const statusFilterOptionsSelectedAll =
      statusFilterOptionsSelected.length === statusFilterOptionsList.length
        ? true
        : false

    let LocationsData = [['Country', 'Visitors']]

    LocationsData = [
      ...LocationsData,
      ...(report.realTimeData?.locations ? report.realTimeData.locations : []),
    ]

    const LocationsOptions = {
      colorAxis: { colors: ['#5141E7'] },
      backgroundColor: '#EEF1FB',
      datalessRegionColor: '#ffffff',
      defaultColor: '#f5f5f5',
    }

    let AttendeesData = [[this.props.users.languageStateForRedux?.reports?.last30mins, this.props.users.languageStateForRedux?.reports?.visitors]]

    AttendeesData = [
      ...AttendeesData,
      ...(report.realTimeData?.attendeesActiveChart?.length > 0
        ? report.realTimeData.attendeesActiveChart
        : [['', 0]]),
    ]

    let AttendeesDataBlank = [['Last 30 minutes', 'Visitors']]

    AttendeesDataBlank = [
      ...AttendeesDataBlank,
      ...[['  ', 0]],
    ]

    const AttendeesOptions = {}

    const PagesOptions = {
      legend: 'none',
      pieSliceText: 'none',
      pieStartAngle: 0,
      tooltip: { trigger: 'none' },
      pieHole: 0.7,
      slices: {
        0: { color: '#A358DF' },
        1: { color: '#EFEFF0' },
      },
    }

    const DesktopOptions = {
      legend: 'none',
      pieSliceText: 'none',
      pieStartAngle: 0,
      tooltip: { trigger: 'none' },
      pieHole: 0.8,
      slices: {
        0: { color: '#00D0A8' },
        1: { color: '#EFEFF0' },
      },
    }

    const MobileOptions = {
      legend: 'none',
      pieSliceText: 'none',
      pieStartAngle: 0,
      tooltip: { trigger: 'none' },
      pieHole: 0.8,
      slices: {
        0: { color: '#F29B35' },
        1: { color: '#EFEFF0' },
      },
    }

    const OtherOptions = {
      legend: 'none',
      pieSliceText: 'none',
      pieStartAngle: 0,
      tooltip: { trigger: 'none' },
      pieHole: 0.8,
      slices: {
        0: { color: '#FF4170' },
        1: { color: '#EFEFF0' },
      },
    }

    return (
      <DashboardLayout title="Settings" pageTitle="Settings">
        {this.props?.report?.getPublishedEventsSuccess && events?.length > 0 ? (
          <>
            <ThemeProvider theme={theme}>
              <Box
                className="dashboardMiddleArea"
                style={{ backgroundColor: '#f6f6fc' }}
              >
                <Box
                  component={Grid}
                  container
                  px={0}
                  pt={1}
                  mb={{ xs: 0, md: 4, }}
                  className="header-common"
                  spacing={2}
                  alignItems={'end'}
                >
                  <Grid size={{sm:12, md:6, lg:6}} >
                    <Grid container spacing={3}>
                      <Grid  >
                        <Box className="heading"> {this.props.users.languageStateForRedux?.reports?.liveDashboard} </Box>
                      </Grid>

                      <Grid  >
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={eventOptions}
                          style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField variant="outlined" {...params} />
                          )}
                          onChange={(e, value) =>
                            this.handleSearchEvents(e, value)
                          }
                          value={eventValue}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid size={{sm:12, md:6, lg:6}}>
                    <Stack alignItems={{ md: 'end' }} spacing={0.5}>
                      <Stack
                        direction={{ xs: 'column', sm: 'row', }}
                        alignItems={{ xs: "start", sm: "center", }}
                        sx={{ color: '#000056' }}
                      >
                        <Box component={Stack} alignItems="center" direction="row">
                          <img alt="" src={TimeCircle} />
                          <Box
                            ml={1.25}
                            mr={1.5}
                            sx={{ fontSize: '16px', fontWeight: '500' }}
                          >
                            {this.props.users.languageStateForRedux?.common?.time}
                          </Box>
                        </Box>
                        <Box>
                          {report.project && (
                            <>
                              <OfficialTimeClock
                                timezone={report.project.timezone}
                              />{' '}
                              {`(${moment()
                                .tz(report.project.timezone)
                                .format('z')})`}
                            </>
                          )}{' '}
                        </Box>
                      </Stack>
                      <Box sx={{ color: '#726F8E !important', fontSize: '10px' }}>
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          sx={{ color: '#000056' }}
                          spacing={1}
                        >
                          <Box>
                            {this.props.users.languageStateForRedux?.column_names?.attendees?.lastUpdated} :{' '}
                            {report.project &&
                              `${moment()
                                .tz(report.project.timezone)
                                .format('ddd, MMM, Do YYYY, h:mm A')}`}{' '}
                          </Box>
                          <Box
                            component="div"
                          >
                            <Button
                              fullWidth
                              variant="text"
                              style={{
                                padding: '0px 15px',
                                justifyContent: 'space-between',
                                textTransform: 'capitalize',
                                fontSize: '10px',
                              }}
                              size="small"
                              disableElevation
                              ref={this.anchorRefRefresh}
                              aria-controls={
                                refresh ? 'menu-list-grow' : undefined
                              }
                              aria-haspopup="true"
                              onClick={this.handleToggleRefresh}
                              endIcon={<ArrowDropDownIcon />}
                            >
                              <Stack
                                direction={'row'}
                                alignItems="center"
                                sx={{ fontSize: '14px', color: '#000056' }}
                              >
                                <Box mr={1.3} sx={{ fontWeight: '500' }}>
                                  <img alt="" src={RefreshIcon} />
                                </Box>
                                {this.props.users.languageStateForRedux?.dropdowns?.attendees?.refresh}{' '}
                                {refreshIntervalPeriod &&
                                  `After ${refreshIntervalPeriod} Minutes`}
                              </Stack>
                            </Button>
                            <Popper
                              open={refresh}
                              style={{
                                zIndex: '3',
                              }}
                              placement="bottom-end"
                              transition
                              anchorEl={this.anchorRefRefresh.current}
                              role={undefined}
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Fade {...TransitionProps} timeout={250}>
                                  <Box
                                    sx={{
                                      overflow: 'scroll',
                                      backgroundColor: theme.palette.common.white,
                                      boxShadow:
                                        '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                      borderRadius: '4px',
                                      marginTop: '8px',
                                    }}
                                  >
                                    <ClickAwayListener
                                      onClickAway={this.handleClose}
                                    >
                                      <MenuList
                                        autoFocusItem={refresh}
                                        id="menu-list-grow"
                                        onKeyDown={this.handleListKeyDown}
                                      >
                                        <MenuItem
                                          className="checkbox-item"
                                          onClick={this.getReport}
                                        >
                                          <Stack
                                            direction={'row'}
                                            alignItems="center"
                                            sx={{
                                              fontSize: '14px',
                                              color: '#000056',
                                            }}
                                          >
                                            <Box
                                              mr={1.3}
                                              sx={{ fontWeight: '500' }}
                                            >
                                              <img alt="" src={RefreshIcon} />
                                            </Box>
                                            {this.props.users.languageStateForRedux?.dropdowns?.attendees?.refreshNow}
                                          </Stack>
                                        </MenuItem>
                                        <MenuItem
                                          className="checkbox-item"
                                          onClick={() =>
                                            this.refreshByInterval(1)
                                          }
                                        >
                                          <Stack
                                            direction={'row'}
                                            alignItems="center"
                                            sx={{
                                              fontSize: '14px',
                                              color: '#000056',
                                            }}
                                          >
                                            <Box
                                              mr={1.3}
                                              sx={{ fontWeight: '500' }}
                                            >
                                              <img alt="" src={RefreshIcon} />
                                            </Box>
                                            {this.props.users.languageStateForRedux?.dropdowns?.attendees?.one}
                                          </Stack>
                                        </MenuItem>
                                        <MenuItem
                                          className="checkbox-item"
                                          onClick={() =>
                                            this.refreshByInterval(2)
                                          }
                                        >
                                          <Stack
                                            direction={'row'}
                                            alignItems="center"
                                            sx={{
                                              fontSize: '14px',
                                              color: '#000056',
                                            }}
                                          >
                                            <Box
                                              mr={1.3}
                                              sx={{ fontWeight: '500' }}
                                            >
                                              <img alt="" src={RefreshIcon} />
                                            </Box>
                                            {this.props.users.languageStateForRedux?.dropdowns?.attendees?.two}
                                          </Stack>
                                        </MenuItem>
                                        <MenuItem
                                          className="checkbox-item"
                                          onClick={() =>
                                            this.refreshByInterval(5)
                                          }
                                        >
                                          <Stack
                                            direction={'row'}
                                            alignItems="center"
                                            sx={{
                                              fontSize: '14px',
                                              color: '#000056',
                                            }}
                                          >
                                            <Box
                                              mr={1.3}
                                              sx={{ fontWeight: '500' }}
                                            >
                                              <img alt="" src={RefreshIcon} />
                                            </Box>
                                            {this.props.users.languageStateForRedux?.dropdowns?.attendees?.five}
                                          </Stack>
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Box>
                                </Fade>
                              )}
                            </Popper>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={3}>
                    <Grid size={{xs: 12, md:6, lg:7, xl:8}}>
                      <Grid container spacing={3}>
                        {/* Summary cards */}
                        <Grid size={{xs: 12, md:"6", lg:"4", xl:"3"}} >
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.totalRegistered}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    {report.realTimeData?.allEvents?.Register
                                      ? report.realTimeData.allEvents.Register
                                      : 0}
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#0091FF' }}>
                                      {report.realTimeData?.realTimeEvents
                                        ?.Register
                                        ? report.realTimeData.realTimeEvents
                                          .Register
                                        : 0}
                                    </span>{' '}
                                        {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img
                                    src={TotalRegistered}
                                    alt="TotalRegistered"
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    {report.realTimeData?.allEvents?.Login
                                      ? report.realTimeData.allEvents.Login
                                      : 0}
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#0ADDB4' }}>
                                      {report.realTimeData?.realTimeEvents?.Login
                                        ? report.realTimeData.realTimeEvents.Login
                                        : 0}
                                    </span>{' '}
                                        {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={CheckedIn} alt="TotalRegistered" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    {report.realTimeData?.allEvents?.Logout
                                      ? report.realTimeData.allEvents.Logout
                                      : 0}
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#130F26' }}>
                                      {report.realTimeData?.realTimeEvents?.Logout
                                        ? report.realTimeData.realTimeEvents
                                          ?.Logout
                                        : 0}
                                    </span>{' '}
                                        {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={CheckedOut} alt="Checked-Out" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.column_names?.attendees?.notChecked}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    {report.realTimeData?.allEvents?.NotCheckedIn
                                      ? report.realTimeData.allEvents.NotCheckedIn
                                      : 0}
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#A358DF' }}>
                                      {report.realTimeData?.realTimeEvents?.Login
                                        ? `-${report.realTimeData.realTimeEvents.Login}`
                                        : 0}
                                    </span>{' '}
                                        {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={NotCheckedIn} alt="Not Checked-In" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.helpTickets}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#F26135' }}>0</span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={HelpTickets} alt="Help Tickets" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.chat}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#5141E7' }}>0</span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={ChatIcon} alt="Chat" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.failedToLogin}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    {report.realTimeData?.allEvents?.FailedToLogin
                                      ? report.realTimeData.allEvents.FailedToLogin
                                      : 0}
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#FF4170' }}>
                                      {report.realTimeData?.realTimeEvents?.FailedToLogin
                                        ? `${report.realTimeData.realTimeEvents.FailedToLogin}`
                                        : 0}
                                    </span>{' '}
                                        {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={FailedLogin} alt="Failed to login" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.upcomingSessions}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    {report.agenda?.totalCount
                                      ? report.agenda.totalCount
                                      : 0}
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#0091FF' }}>
                                      {report.agenda?.upcoming
                                        ? report.agenda.upcoming
                                        : 0}
                                    </span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.next30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img
                                    src={UpcomingSessions}
                                    alt="Upcoming Sessions"
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        {/* Summary cards end*/}

                        {/* Active Attendees*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={ActiveAttendeesIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">
                                  {this.props.users.languageStateForRedux?.reports?.activeVisitors}
                                </span>
                              </Grid>
                            </Box>
                            <Box
                              sx={{ borderTop: '1px solid #eeeeee' }}
                              py={1}
                              px={1}
                            >
                              <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={AttendeesData}
                                options={AttendeesOptions}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        {/* Active Attendees end*/}

                        {/* General Early Access Details*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={groupDetailIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">
                                  {this.props.users.languageStateForRedux?.formfields?.overview?.headingOnHover}
                                </span>
                              </Grid>
                            </Box>
                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                    {this.props.users.languageStateForRedux?.formfields?.groups?.name}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.formfields?.overview?.timeOnHover}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.overviewTab?.pages}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.common?.status}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {report.project?.earlyAccess?.accessDetails &&
                                    report?.project?.earlyAccess?.accessDetails
                                      .length > 0 ? (
                                    report?.project?.earlyAccess?.accessDetails.map(
                                      (data) => (
                                        <TableRow>
                                          <TableCell
                                            className="tableBodyCell"
                                            scope="row"
                                          >
                                            {report.project.earlyAccess
                                              .accessType === 'groups'
                                              ? data.groupId.attendeesGroupName
                                              : 'All'}
                                          </TableCell>
                                          <TableCell
                                            className="tableBodyCell"
                                            align="left"
                                          >
                                            {data.accessDate !== null
                                              ? moment
                                                .tz(
                                                  data.accessDate,
                                                  report.project.timezone,
                                                )
                                                .format(
                                                  'ddd, MMM, Do YYYY, h:mm A',
                                                )
                                              : '--'}
                                          </TableCell>
                                          <TableCell
                                            className="tableBodyCell pages"
                                            align="left"
                                          >
                                            {`${data.accessPages[0].pageName}  `}

                                            {data.accessPages.length > 1 &&
                                              <span>
                                                <span className="count">
                                                  +{data.accessPages.length - 1}
                                                </span>

                                                <div className="page-info">
                                                  {data.accessPages &&
                                                    data.accessPages
                                                      .filter((page, i) => i !== 0)
                                                      .map((page) => (
                                                        <p className="small-subtitle primary-text">
                                                          {page.pageName}
                                                        </p>
                                                      ))}
                                                </div>
                                              </span>
                                            }
                                          </TableCell>
                                          <TableCell
                                            className="tableBodyCell"
                                            align="left"
                                          >
                                            {moment(data.accessDate) >
                                              moment().utc() ? (
                                              <>
                                                Early Access Starting In:
                                                <CountdownTimerReverse
                                                  targetDate={data.accessDate}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                {moment(
                                                  report.project.startDate,
                                                ) > moment.utc() ? (
                                                  <>
                                                    <strong>
                                                      Early Access Started Since:{' '}
                                                    </strong>
                                                    {moment
                                                      .tz(
                                                        moment(data.accessDate),
                                                        report.project.timezone,
                                                      )
                                                      .format(
                                                        'MMM DD, YYYY h:mm A',
                                                      )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <strong>
                                                      Early Access Ended Since:{' '}
                                                    </strong>
                                                    {moment
                                                      .tz(
                                                        moment(
                                                          report.project
                                                            .startDate,
                                                        ),
                                                        report.project.timezone,
                                                      )
                                                      .format(
                                                        'MMM DD, YYYY h:mm A',
                                                      )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ),
                                    )
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        colspan="4"
                                        style={{ textAlign: 'center' }}
                                      >
                                        {this.props.users.languageStateForRedux?.common?.noRecords}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* General Early Access Details end*/}

                        {/* Agenda*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Stack
                              direction={'row'}
                              px={3}
                              py={2}
                              alignItems="center"
                              justifyContent={'space-between'}
                              className="group-info-header"
                            >
                              <Box>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid  >
                                    <Box
                                      component={'img'}
                                      mt={0}
                                      alt="icon"
                                      src={AgendaCalendarIcon}
                                    />
                                  </Grid>
                                  <Grid  >
                                    <span className="paragraph bold">{this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.agenda}</span>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box component="div">
                                <Button
                                  fullWidth
                                  variant="text"
                                  style={{
                                    padding: '10px 15px',
                                    borderRadius: '7px',
                                    justifyContent: 'space-between',
                                    textTransform: 'capitalize',
                                  }}
                                  size="small"
                                  disableElevation
                                  ref={this.anchorRef1}
                                  aria-controls={
                                    open1 ? 'menu-list-grow' : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={this.handleToggle1}
                                  endIcon={<ArrowDropDownIcon />}
                                >
                                  {this.props.users.languageStateForRedux?.common?.all}
                                </Button>
                                <Popper
                                  open={open1}
                                  style={{ zIndex: '3' }}
                                  placement="bottom-end"
                                  transition
                                  anchorEl={this.anchorRef1.current}
                                  role={undefined}
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Fade {...TransitionProps} timeout={250}>
                                      <Box
                                        sx={{
                                          maxHeight: '130px',
                                          overflow: 'scroll',
                                          backgroundColor:
                                            theme.palette.common.white,
                                          boxShadow:
                                            '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                          borderRadius: '4px',
                                          marginTop: '8px',
                                        }}
                                      >
                                        <ClickAwayListener
                                          onClickAway={this.handleClose}
                                        >
                                          <MenuList
                                            autoFocusItem={open1}
                                            id="menu-list-grow"
                                            onKeyDown={this.handleListKeyDown}
                                          >
                                            <MenuItem className="checkbox-item">
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    name="all"
                                                    style={{
                                                      padding: 0,
                                                      marginRight: '5px',
                                                    }}
                                                    color="primary"
                                                    onChange={(e) =>
                                                      this.handleAgendaFilter(e)
                                                    }
                                                    checked={
                                                      statusFilterOptionsSelectedAll
                                                    }
                                                  />
                                                }
                                                label={this.props.users.languageStateForRedux?.common?.all}
                                                style={{ padding: 0, margin: 0 }}
                                              />
                                            </MenuItem>
                                            {statusFilterOptionsList.map(
                                              (option) => {
                                                return (
                                                  <MenuItem
                                                    key={option}
                                                    className="checkbox-item"
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          color="primary"
                                                          name={option}
                                                          onChange={(e) =>
                                                            this.handleAgendaFilter(
                                                              e,
                                                            )
                                                          }
                                                          checked={
                                                            statusFilterOptionsSelected.indexOf(
                                                              option,
                                                            ) > -1
                                                              ? true
                                                              : false
                                                          }
                                                          style={{
                                                            padding: 0,
                                                            marginRight: '5px',
                                                          }}
                                                          disabled={!this.checkIfUpcomingAgendaExists(option)}
                                                        />
                                                      }
                                                      label={this.optionsList(option)}
                                                      style={{
                                                        padding: 0,
                                                        margin: 0,
                                                      }}
                                                    />
                                                  </MenuItem>
                                                )
                                              },
                                            )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Box>
                                    </Fade>
                                  )}
                                </Popper>
                              </Box>
                            </Stack>
                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell long-text">
                                      {this.props.users.languageStateForRedux?.common?.name}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell date-time"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.column_names?.agenda?.dateTime}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="right"
                                    >
                                      Duration (h:m)
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.column_names?.agenda?.loc}
                                    </TableCell>

                                    <TableCell
                                      className="tableHeadCell long-text"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.common?.status}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {report?.agenda?.data &&
                                    report?.agenda?.data?.length > 0 ? (
                                    report.agenda.data
                                      .filter((agenda) => {
                                        if (
                                          statusFilterOptionsSelected.length === 1
                                        ) {
                                          if (
                                            statusFilterOptionsSelected[0] ===
                                            'Completed'
                                          ) {
                                            return (
                                              moment
                                                .utc(agenda.dateTime)
                                                .add({
                                                  hours: agenda.duration.hours,
                                                  minutes:
                                                    agenda.duration.minutes,
                                                })
                                                .valueOf() <
                                              moment.utc().valueOf()
                                            )
                                          } else if (
                                            statusFilterOptionsSelected[0] ===
                                            'Upcoming'
                                          ) {
                                            return (
                                              moment
                                                .utc(agenda.dateTime)
                                                .valueOf() >
                                              moment.utc().valueOf()
                                            )
                                          }
                                        } else {
                                          return agenda
                                        }
                                      })
                                      .map((agenda) => {
                                        let agendaStatus
                                        if (
                                          moment.utc(agenda.dateTime).valueOf() <
                                          moment.utc().valueOf()
                                        ) {
                                          if (
                                            moment
                                              .utc(agenda.dateTime)
                                              .add({
                                                hours: agenda.duration.hours,
                                                minutes: agenda.duration.minutes,
                                              })
                                              .valueOf() < moment.utc().valueOf()
                                          ) {
                                            agendaStatus = 'Completed Since'
                                          } else {
                                            agendaStatus = 'Expected To End In'
                                          }
                                        } else {
                                          agendaStatus = 'Starting In'
                                        }
                                        return (
                                          <TableRow
                                            className={
                                              agendaStatus === 'Completed Since'
                                                ? 'status_completed'
                                                : agendaStatus ===
                                                  'Expected To End In'
                                                  ? 'status_expected'
                                                  : 'status_starting'
                                            }
                                          >
                                            <TableCell
                                              className="tableBodyCell long-text"
                                              scope="row"
                                            >
                                              {agenda.title}
                                            </TableCell>
                                            <TableCell
                                              className="tableBodyCell date-time"
                                              align="left"
                                            >
                                              {agenda.dateTime !== null
                                                ? moment
                                                  .tz(
                                                    agenda.dateTime,
                                                    report.project.timezone,
                                                  )
                                                  .format(
                                                    'ddd, MMM, Do YYYY, h:mm A',
                                                  )
                                                : '--'}
                                            </TableCell>
                                            <TableCell
                                              className="tableBodyCell"
                                              align="right"
                                            >
                                              {agenda.duration.hours < 10
                                                ? '0' +
                                                agenda.duration.hours.toString()
                                                : agenda.duration.hours.toString()}
                                              :
                                              {agenda.duration.minutes < 10
                                                ? '0' +
                                                agenda.duration.minutes.toString()
                                                : agenda.duration.minutes.toString()}
                                            </TableCell>
                                            <TableCell
                                              className="tableBodyCell"
                                              align="left"
                                            >
                                              {agenda.location
                                                ? agenda.location
                                                : '--'}
                                            </TableCell>

                                            <TableCell
                                              className="tableBodyCell long-text"
                                              align="left"
                                            >
                                              {agendaStatus ===
                                                'Completed Since' ? (
                                                <>
                                                  Completed Since:{' '}
                                                  <CountdownTimer
                                                    targetDate={moment
                                                      .utc(agenda.dateTime)
                                                      .add({
                                                        hours:
                                                          agenda.duration.hours,
                                                        minutes:
                                                          agenda.duration.minutes,
                                                      })}
                                                  />
                                                </>
                                              ) : agendaStatus ===
                                                'Expected To End In' ? (
                                                <>
                                                  Expected To End In:{' '}
                                                  <CountdownTimerReverse
                                                    targetDate={moment
                                                      .utc(agenda.dateTime)
                                                      .add({
                                                        hours:
                                                          agenda.duration.hours,
                                                        minutes:
                                                          agenda.duration.minutes,
                                                      })}
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  Starting In:{' '}
                                                  <CountdownTimerReverse
                                                    targetDate={agenda.dateTime}
                                                  />
                                                </>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        colspan="6"
                                        style={{ textAlign: 'center' }}
                                      >
                                        {this.props.users.languageStateForRedux?.common?.noRecords}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Agenda end*/}

                        {/* Locations*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={LocationIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">{this.props.users.languageStateForRedux?.reports?.locations}</span>
                              </Grid>
                            </Box>
                            <Box
                              sx={{ borderTop: '1px solid #eeeeee' }}
                              py={1}
                              px={1}
                            >
                              <Chart
                                chartEvents={[
                                  {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => {
                                      const chart = chartWrapper.getChart()
                                      const selection = chart.getSelection()
                                      if (selection.length === 0) return
                                      const region =
                                        LocationsData[selection[0].row + 1]
                                    },
                                  },
                                ]}
                                chartType="GeoChart"
                                width="100%"
                                height="500px"
                                data={LocationsData}
                                options={LocationsOptions}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        {/* Locations end*/}
                      </Grid>
                    </Grid>
                    <Grid size={{xs: 12, md:6, lg:5, xl:4}}>
                      <Grid container spacing={3}>
                        {/* Event*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={EventIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">{this.props.users.languageStateForRedux?.reports?.event}</span>
                              </Grid>
                            </Box>

                            {report.project && (
                              <Box sx={{ borderTop: '1px solid #eeeeee' }} pt={1}>
                                <Box
                                  component={Grid}
                                  container
                                  rowSpacing={2}
                                  px={3}
                                  py={2}
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid
                                    size={{xs: 12, sm:7, md:12, lg:7}}
                                    display={'flex'}
                                    direction={'column'}
                                    justifyContent={{
                                      xs: 'center',
                                      sm: 'start',
                                      md: 'center',
                                      lg: 'start',
                                    }}
                                  >
                                    <>
                                      {moment
                                        .utc(report.project.endDate)
                                        .valueOf() > moment.utc().valueOf() ? (
                                        <>
                                          {moment
                                            .utc(report.project.startDate)
                                            .valueOf() >
                                            moment.utc().valueOf() ? (
                                            <>
                                              <Box
                                                className="pargraph text-primary-light bold"
                                                mb={1}
                                              >
                                                {this.props.users.languageStateForRedux?.reports?.startingIn}
                                              </Box>
                                              <EventCoundownTimer
                                                targetDate={
                                                  report.project.startDate
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <Box
                                                className="pargraph text-primary-light bold"
                                                mb={1}
                                              >
                                                {this.props.users.languageStateForRedux?.reports?.endingIn}
                                              </Box>
                                              <EventCoundownTimer
                                                targetDate={
                                                  report.project.endDate
                                                }
                                              />
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Box
                                            className="pargraph text-primary-light bold"
                                            mb={1}
                                            textAlign={{ xs: "center", sm: "left" }}
                                          >
                                            {this.props.users.languageStateForRedux?.reports?.ended}
                                          </Box>
                                          <ShowCounter
                                            days={0}
                                            hours={0}
                                            minutes={0}
                                            seconds={0}
                                          />
                                        </>
                                      )}
                                    </>
                                  </Grid>

                                  <Grid size={{xs: 12, sm:5, md:12, lg:5}}>
                                    <Box
                                      component={Grid}
                                      container
                                      spacing={1}
                                      justifyContent={{
                                        xs: 'center',
                                        sm: 'end',
                                        md: 'center',
                                        lg: 'end',
                                      }}
                                      className=""
                                    >
                                      <Grid  >
                                        <Stack
                                          direction={'column'}
                                          justifyContent="space-between"
                                          alignItems="center"
                                          sx={{
                                            fontSize: '14px',
                                            color: '#000056',
                                          }}
                                        >
                                          <Box
                                            component={'h5'}
                                            className="text-primary-light"
                                            mb={8.5}
                                          >
                                            {moment
                                              .tz(
                                                report.project.startDate,
                                                report.project.timezone,
                                              )
                                              .format('MMM, Do')}
                                          </Box>
                                          <Box
                                            component={'h5'}
                                            className="text-primary-light"
                                          >
                                            {moment
                                              .tz(
                                                report.project.endDate,
                                                report.project.timezone,
                                              )
                                              .format('MMM, Do')}
                                          </Box>
                                        </Stack>
                                      </Grid>
                                      <Grid  >
                                        <Stack
                                          direction={'column'}
                                          justifyContent="space-between"
                                          alignItems="center"
                                          sx={{
                                            fontSize: '14px',
                                            color: '#000056',
                                          }}
                                        >
                                          <Box
                                            component={'img'}
                                            mx={1}
                                            mt={0}
                                            alt="icon"
                                            src={StartIcon}
                                          />
                                          <Box
                                            component={'img'}
                                            mx={1}
                                            my={1}
                                            alt="icon"
                                            src={LineIcon}
                                          />
                                          <Box
                                            component={'img'}
                                            mx={1}
                                            mt={0}
                                            alt="icon"
                                            src={EndIcon}
                                          />
                                        </Stack>
                                      </Grid>
                                      <Grid  >
                                        <Box mb={5.5} mt={-1}>
                                          <Box
                                            component={'h5'}
                                            className="text-primary-light"
                                            mb={0}
                                          >
                                            {this.props.users.languageStateForRedux?.reports?.starts}
                                          </Box>
                                          <Box
                                            component={'p'}
                                            className="paragraph bold"
                                            mb={0}
                                          >
                                            {moment
                                              .tz(
                                                report.project.startDate,
                                                report.project.timezone,
                                              )
                                              .format('h:mm A')}
                                          </Box>
                                        </Box>

                                        <Box>
                                          <Box
                                            component={'h5'}
                                            className="text-primary-light"
                                            mb={0}
                                          >
                                            {this.props.users.languageStateForRedux?.reports?.ends}
                                          </Box>
                                          <Box
                                            component={'p'}
                                            className="paragraph bold"
                                            mb={0}
                                          >
                                            {moment
                                              .tz(
                                                report.project.endDate,
                                                report.project.timezone,
                                              )
                                              .format('h:mm A')}
                                          </Box>
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Grid>

                                  <Grid size={{xs: 12,}}>
                                    <Box
                                      sx={{
                                        fontSize: '12px',
                                        background: '#EDEBFF',
                                        borderRadius: '4px',
                                        textAlign: 'center',
                                        color: 'rgba(40, 19, 116, 0.8)',
                                      }}
                                      py={1}
                                    >
                                      {report.project?.earlyAccess?.accessDetails
                                        .length > 0 ? (
                                        <>
                                          {Math.min.apply(
                                            null,
                                            report.project.earlyAccess.accessDetails.map(
                                              (data) => moment(data.accessDate),
                                            ),
                                          ) > moment.utc() ? (
                                            <>
                                              <strong>
                                                Early Access Starting From:{' '}
                                              </strong>
                                              {moment
                                                .tz(
                                                  Math.min.apply(
                                                    null,
                                                    report.project.earlyAccess.accessDetails.map(
                                                      (data) =>
                                                        new Date(data.accessDate),
                                                    ),
                                                  ),
                                                  report.project.timezone,
                                                )
                                                .format('MMM DD, YYYY h:mm A')}
                                            </>
                                          ) : (
                                            <>
                                              {moment(report.project.startDate) >
                                                moment.utc() ? (
                                                <>
                                                  <strong>
                                                    Early Access Started Since:{' '}
                                                  </strong>
                                                  {moment
                                                    .tz(
                                                      Math.min.apply(
                                                        null,
                                                        report.project.earlyAccess.accessDetails.map(
                                                          (data) =>
                                                            new Date(
                                                              data.accessDate,
                                                            ),
                                                        ),
                                                      ),
                                                      report.project.timezone,
                                                    )
                                                    .format(
                                                      'MMM DD, YYYY h:mm A',
                                                    )}
                                                </>
                                              ) : (
                                                <>
                                                  <strong>
                                                  {this.props.users.languageStateForRedux?.reports?.accessEndedSince}{' '}
                                                  </strong>
                                                  {moment
                                                    .tz(
                                                      moment(
                                                        report.project.startDate,
                                                      ),
                                                      report.project.timezone,
                                                    )
                                                    .format(
                                                      'MMM DD, YYYY h:mm A',
                                                    )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <strong>
                                          {this.props.users.languageStateForRedux?.reports?.noearlyAccess}
                                          </strong>
                                        </>
                                      )}
                                    </Box>
                                  </Grid>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                        {/* Event end*/}

                        {/* Active Pages*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={ActivePagesIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">
                                  <RoundBadge
                                    color={'primary'}
                                    badgeContent={
                                      report.realTimeData?.activeusers
                                        ? report.realTimeData.activeusers.length
                                        : 0
                                    }
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.activePages}
                                  </RoundBadge>
                                </span>
                              </Grid>
                            </Box>
                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                      {this.props.users?.languageStateForRedux?.overviewTab?.pages}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.reports?.activeVisitors}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {report.realTimeData?.activeusers &&
                                    report?.realTimeData?.activeusers?.length > 0 ? (
                                    <>
                                      {report.realTimeData.activeusers.map(
                                        (data) => (
                                          <TableRow>
                                            <TableCell
                                              className="tableBodyCell"
                                              scope="row"
                                            >
                                              {data.page}
                                            </TableCell>
                                            <TableCell
                                              className="tableBodyCell"
                                              align="left"
                                            >
                                              {data.count} Visitors
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        colspan="2"
                                        style={{ textAlign: 'center' }}
                                      >
                                        {this.props.users.languageStateForRedux?.common?.noRecords}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Active Pages end*/}

                        {/* Gamification*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Stack
                              direction={'row'}
                              px={3}
                              py={2}
                              alignItems="center"
                              justifyContent={'space-between'}
                              className="group-info-header"
                            ><Box>
                                <Grid container spacing={1} alignItems='center'>
                                  <Grid  >
                                    <Box
                                      component={'img'}
                                      mt={0}
                                      alt="icon"
                                      src={GamificationIcon}
                                    />
                                  </Grid>
                                  <Grid  >
                                    <span className="paragraph bold">
                                      {this.props.users.languageStateForRedux?.settingsSidebar?.rolesAndPermissions?.gamification}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box className="team-dash-right">
                                <form onSubmit={this.handleSearchSubmit}>
                                  <TextField
                                    name="searchText"
                                    variant='standard'
                                    placeholder={this.props.users.languageStateForRedux?.actions?.gamification?.searchChallenges}
                                    className="search"
                                    size="small"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img alt="" src={SearchIcon} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={searchText}
                                    onChange={this.handleChange}
                                  />
                                </form>
                              </Box>
                            </Stack>

                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                      {this.props.users.languageStateForRedux?.column_names?.gamification?.challenges}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.column_names?.gamification?.points}
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      {this.props.users.languageStateForRedux?.column_names?.gamification?.numAttendees}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                {Array.isArray(challengesList) &&
                                  challengesList?.length > 0 ? (
                                  <>
                                    <TableBody>
                                      {Array.isArray(challengesList) &&
                                        challengesList?.length &&
                                        challengesList?.map((challenges) => {
                                          return (
                                            <TableRow>
                                              <TableCell
                                                className="tableBodyCell"
                                                scope="row"
                                              >
                                                {challenges.challengeName
                                                  ? challenges.challengeName
                                                  : '--'}
                                              </TableCell>
                                              <TableCell
                                                className="tableBodyCell"
                                                align="left"
                                              >
                                                {challenges.points
                                                  ? challenges.points
                                                  : '--'}
                                              </TableCell>
                                              <TableCell
                                                className="tableBodyCell"
                                                align="left"
                                              >
                                                {challenges
                                                  ? challenges.totalUsers
                                                  : '--'}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        })}
                                    </TableBody>
                                  </>
                                ) : (
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        colspan="3"
                                        style={{ textAlign: 'center' }}
                                      >
                                        {this.props.users.languageStateForRedux?.common?.noRecords}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                )}
                              </Table>
                            </Box>
                            {Array.isArray(challengesList) &&
                              challengesList?.length > 0 && (
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  className="tablePagination"
                                  px={3}
                                  py={2}
                                >
                                  <Grid  >
                                    <Box
                                      component="span"
                                      className="small-subtitle"
                                    >
                                      {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of}{' '}
                                      {
                                        this.props.report
                                          ?.totalResultsChallenges?.totalResults
                                      }
                                    </Box>
                                  </Grid>
                                  <Grid  >
                                    <Grid container alignItems="center">
                                      <Grid  >
                                        <span className="small-subtitle">
                                        {this.props.users.languageStateForRedux?.pagination?.shows}
                                        </span>
                                        <select
                                          value={itemCount}
                                          onChange={this.selectItemCount}
                                        >
                                          <option value={5}>5</option>
                                          <option value={20}>20</option>
                                          <option value={50}>50</option>
                                          <option value={100}>100</option>
                                        </select>
                                        <span className="small-subtitle">
                                        {this.props.users.languageStateForRedux?.pagination?.entries}
                                        </span>
                                      </Grid>
                                      <Grid  >
                                        <Pagination
                                          activePage={this.state.page_no}
                                          itemsCountPerPage={this.state.itemCount}
                                          totalItemsCount={
                                            this.props.report
                                              ?.totalResultsChallenges
                                              ?.totalResults
                                          }
                                          pageRangeDisplayed={2}
                                          onChange={this.handlePageChange}
                                          prevPageText={
                                            <NavigateBeforeIcon
                                              style={{ fontSize: 18 }}
                                            />
                                          }
                                          nextPageText={
                                            <NavigateNextIcon
                                              style={{ fontSize: 18 }}
                                            />
                                          }
                                          itemClassFirst="first d-none"
                                          itemClassLast="last d-none"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                          </Box>
                        </Grid>
                        {/* Gamification end*/}

                        {/* Traffic Sources*/}
                        {/* <Grid   xs='12' className='dashboard-table-card'>
                        <Box className='' sx={{ backgroundColor: '#ffffff', border: '1px solid #EDECF5', borderRadius: '8px', }}>
                          <Box component={Grid} container spacing={1} px={3} py={2} alignItems='center' className='group-info-header'>
                            <Grid  >
                              <Box component={'img'} mt={0} alt='icon' src={TrafficSourcesIcon} />
                            </Grid>
                            <Grid  >
                              <span className='paragraph bold'>Traffic Sources</span>
                            </Grid>
                          </Box>
                          <Box className='info-table' component={TableContainer}>
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell className='tableHeadCell'>Medium</TableCell>
                                  <TableCell className='tableHeadCell' align="left">Source</TableCell>
                                  <TableCell className='tableHeadCell' align="left">Attendees</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {report.realTimeData?.trafficSources && report.realTimeData.trafficSources.length > 0 && 
                                  report.realTimeData.trafficSources.map(data => (
                                  <TableRow>
                                    <TableCell className='tableBodyCell' scope="row">{data.medium}</TableCell>
                                    <TableCell className='tableBodyCell' align="left">{data.source}</TableCell>
                                    <TableCell className='tableBodyCell' align="left">{`${data.count}`}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Box>
                      </Grid> */}
                        {/* Traffic Sources end*/}

                        {/* Devices */}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={DevicesIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">{this.props.users.languageStateForRedux?.reports?.devices}</span>
                              </Grid>
                            </Box>
                            <Box sx={{ borderTop: '1px solid #eeeeee' }}>
                              <Grid container spacing={2} alignItems={'center'}>
                                <Grid size={{xs: 6, sm:4}}>
                                  <Box
                                    mx="auto"
                                    sx={{ position: 'relative', width: '155px' }}
                                  >
                                    <Chart
                                      chartType="PieChart"
                                      data={[
                                        ['Devices', 'Percentage'],
                                        [
                                          '',
                                          report.realTimeData?.devices?.desktop
                                            ? report.realTimeData.devices.desktop
                                            : 0,
                                        ],
                                        [
                                          '',
                                          100 -
                                          (report.realTimeData?.devices?.desktop
                                            ? report.realTimeData.devices
                                              .desktop
                                            : 0),
                                        ],
                                      ]}
                                      options={DesktopOptions}
                                      width={'155px'}
                                      height={'155px'}
                                    />
                                    <Box
                                      style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          color: '#00D0A8',
                                        }}
                                      >
                                        {report.realTimeData?.devices?.desktop
                                          ? report.realTimeData.devices.desktop
                                          : 0}
                                        %
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        {this.props.users.languageStateForRedux?.reports?.desktop}
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid size={{xs: 6, sm:4}}>
                                  <Box
                                    mx="auto"
                                    sx={{ position: 'relative', width: '155px' }}
                                  >
                                    <Chart
                                      chartType="PieChart"
                                      data={[
                                        ['Devices', 'Percentage'],
                                        [
                                          '',
                                          report.realTimeData?.devices?.mobile
                                            ? report.realTimeData.devices.mobile
                                            : 0,
                                        ],
                                        [
                                          '',
                                          100 -
                                          (report.realTimeData?.devices?.mobile
                                            ? report.realTimeData.devices.mobile
                                            : 0),
                                        ],
                                      ]}
                                      options={MobileOptions}
                                      width={'155,px'}
                                      height={'155px'}
                                    />
                                    <Box
                                      style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          color: '#F29B35',
                                        }}
                                      >
                                        {report.realTimeData?.devices?.mobile
                                          ? report.realTimeData.devices.mobile
                                          : 0}
                                        %
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        {this.props.users.languageStateForRedux?.reports?.mobile}
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid size={{xs: 6, sm:4}}>
                                  <Box
                                    mx="auto"
                                    sx={{ position: 'relative', width: '150px' }}
                                  >
                                    <Chart
                                      chartType="PieChart"
                                      data={[
                                        ['Devices', 'Percentage'],
                                        [
                                          '',
                                          report.realTimeData?.devices?.other
                                            ? report.realTimeData.devices.other
                                            : 0,
                                        ],
                                        [
                                          '',
                                          100 -
                                          (report.realTimeData?.devices?.other
                                            ? report.realTimeData.devices.other
                                            : 0),
                                        ],
                                      ]}
                                      options={OtherOptions}
                                      width={'150px'}
                                      height={'150px'}
                                    />
                                    <Box
                                      style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          color: '#FF4170',
                                        }}
                                      >
                                        {report.realTimeData?.devices?.other
                                          ? report.realTimeData.devices.other
                                          : 0}
                                        %
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        {this.props.users.languageStateForRedux?.reports?.other}
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Devices end*/}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <NotificationContainer />
              </Box>
            </ThemeProvider>
          </>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <Box
                className="dashboardMiddleArea"
                style={{ backgroundColor: '#f6f6fc' }}
              >
                <Box
                  component={'div'}
                  px={0}
                  pt={1}
                  mb={4}
                  className="header-common"
                >
                  <Grid  >
                    <Grid container spacing={3}>
                      <Grid  >
                        <Box className="heading"> {this.props.users.languageStateForRedux?.reports?.liveDashboard} </Box>
                      </Grid>

                      <Grid  >
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid  >
                    <Stack alignItems={'end'} spacing={0.5}>
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        sx={{ color: '#000056' }}
                      >
                        <img alt="" src={TimeCircle} />
                        <Box
                          ml={1.25}
                          mr={1.5}
                          sx={{ fontSize: '16px', fontWeight: '500' }}
                        >
                          Official Time
                        </Box>
                      </Stack>
                      <Box sx={{ color: '#726F8E !important', fontSize: '10px' }}>
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          sx={{ color: '#000056' }}
                          spacing={1}
                        >
                          <Box>
                            Last Updated :{' '}
                          </Box>
                          <Box
                            component="div"
                          >
                            <Button
                              fullWidth
                              variant="text"
                              style={{
                                padding: '0px 15px',
                                justifyContent: 'space-between',
                                textTransform: 'capitalize',
                                fontSize: '10px',
                              }}
                              size="small"
                              disableElevation
                              ref={this.anchorRefRefresh}
                              aria-controls={
                                refresh ? 'menu-list-grow' : undefined
                              }
                              aria-haspopup="true"
                              endIcon={<ArrowDropDownIcon />}
                            >
                              <Stack
                                direction={'row'}
                                alignItems="center"
                                sx={{ fontSize: '14px', color: '#000056' }}
                              >
                                <Box mr={1.3} sx={{ fontWeight: '500' }}>
                                  <img alt="" src={RefreshIcon} />
                                </Box>
                                Refresh{' '}
                              </Stack>
                            </Button>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                </Box>
                <Box>
                  <Grid container spacing={3}>
                    <Grid size={{xs: 12, md:6, lg:7, xl:8}}>
                      <Grid container spacing={3}>
                        {/* Summary cards */}
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.totalRegistered}
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#0091FF' }}>
                                      0
                                    </span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img
                                    src={TotalRegistered}
                                    alt="TotalRegistered"
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Checked-In
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#0ADDB4' }}>
                                      0
                                    </span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={CheckedIn} alt="TotalRegistered" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Checked-Out
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#130F26' }}>
                                      0
                                    </span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={CheckedOut} alt="Checked-Out" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Not Checked-In
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#A358DF' }}>
                                      0
                                    </span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={NotCheckedIn} alt="Not Checked-In" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Help Tickets
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#F26135' }}>0</span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={HelpTickets} alt="Help Tickets" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Chat
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#5141E7' }}>0</span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={ChatIcon} alt="Chat" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Failed to login
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#FF4170' }}>0</span>{' '}
                                    {this.props.users.languageStateForRedux?.reports?.last30mins}
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img src={FailedLogin} alt="Failed to login" />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                          <Box className="report-summary-card">
                            <Grid container spacing={3} alignItems="center">
                              <Grid size={{xs: 8,}}>
                                <Stack>
                                  <h3
                                    style={{
                                      fontSize: '12px',
                                      color: '#726F8E',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Upcoming Sessions
                                  </h3>
                                  <h2
                                    style={{ fontSize: '24px', color: '#111111' }}
                                  >
                                    0
                                  </h2>
                                  <h4
                                    style={{ fontSize: '12px', color: '#726F8E' }}
                                  >
                                    <span style={{ color: '#0091FF' }}>
                                      0
                                    </span>{' '}
                                    Next 30 Minutes
                                  </h4>
                                </Stack>
                              </Grid>
                              <Grid size={{xs: 4,}}>
                                <Avatar style={styles.avatar}>
                                  <img
                                    src={UpcomingSessions}
                                    alt="Upcoming Sessions"
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        {/* Summary cards end*/}

                        {/* Active Attendees*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={ActiveAttendeesIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">
                                  Active Visitors
                                </span>
                              </Grid>
                            </Box>
                            <Box
                              sx={{ borderTop: '1px solid #eeeeee' }}
                              py={1}
                              px={1}
                            >
                              <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={AttendeesDataBlank}
                                options={AttendeesOptions}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        {/* Active Attendees end*/}

                        {/* General Early Access Details*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={groupDetailIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">
                                  Early Access for Group Details
                                </span>
                              </Grid>
                            </Box>
                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                      Group Name
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Early Access Time
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Pages
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Status
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      colspan="4"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {this.props.users.languageStateForRedux?.common?.noRecords}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* General Early Access Details end*/}

                        {/* Agenda*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Stack
                              direction={'row'}
                              px={3}
                              py={2}
                              alignItems="center"
                              justifyContent={'space-between'}
                              className="group-info-header"
                            >
                              <Box>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid  >
                                    <Box
                                      component={'img'}
                                      mt={0}
                                      alt="icon"
                                      src={AgendaCalendarIcon}
                                    />
                                  </Grid>
                                  <Grid  >
                                    <span className="paragraph bold">Agenda</span>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box component="div">
                                <Button
                                  fullWidth
                                  variant="text"
                                  style={{
                                    padding: '10px 15px',
                                    borderRadius: '7px',
                                    justifyContent: 'space-between',
                                    textTransform: 'capitalize',
                                  }}
                                  size="small"
                                  disableElevation
                                  ref={this.anchorRef1}
                                  aria-controls={
                                    open1 ? 'menu-list-grow' : undefined
                                  }
                                  aria-haspopup="true"
                                  endIcon={<ArrowDropDownIcon />}
                                >
                                  All
                                </Button>
                              </Box>
                            </Stack>
                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                      Name
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Date & Time
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="right"
                                    >
                                      Duration (h:m)
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Location
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Regs./Attendees
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Status
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      colspan="6"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {this.props.users.languageStateForRedux?.common?.noRecords}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Agenda end*/}

                        {/* Locations*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={LocationIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">Locations</span>
                              </Grid>
                            </Box>
                            <Box
                              sx={{ borderTop: '1px solid #eeeeee' }}
                              py={1}
                              px={1}
                            >
                              <Chart
                                chartEvents={[
                                  {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => {
                                      const chart = chartWrapper.getChart()
                                      const selection = chart.getSelection()
                                      if (selection.length === 0) return
                                      const region =
                                        LocationsData[selection[0].row + 1]
                                    },
                                  },
                                ]}
                                chartType="GeoChart"
                                width="100%"
                                height="500px"
                                data={LocationsData}
                                options={LocationsOptions}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        {/* Locations end*/}
                      </Grid>
                    </Grid>
                    <Grid size={{xs: 12, md:6, lg:5, xl:4}}>
                      <Grid container spacing={3}>
                        {/* Event*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems={"center"}
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={EventIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">Event</span>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Event end*/}

                        {/* Active Pages*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={ActivePagesIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">
                                  <RoundBadge
                                    color={'primary'}
                                    badgeContent={
                                      0
                                    }
                                  >
                                    {this.props.users.languageStateForRedux?.reports?.activePages}
                                  </RoundBadge>
                                </span>
                              </Grid>
                            </Box>
                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                      Pages
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Active Visitors
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      colspan="2"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {this.props.users.languageStateForRedux?.common?.noRecords}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Active Pages end*/}

                        {/* Gamification*/}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Stack
                              direction={'row'}
                              px={3}
                              py={2}
                              alignItems="center"
                              justifyContent={'space-between'}
                              className="group-info-header"
                            ><Box>
                                <Grid container spacing={1} alignItems='center'>
                                  <Grid  >
                                    <Box
                                      component={'img'}
                                      mt={0}
                                      alt="icon"
                                      src={GamificationIcon}
                                    />
                                  </Grid>
                                  <Grid  >
                                    <span className="paragraph bold">
                                      Gamification
                                    </span>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Stack>

                            <Box
                              className="info-table"
                              component={TableContainer}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="tableHeadCell">
                                      Challenges
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      Points
                                    </TableCell>
                                    <TableCell
                                      className="tableHeadCell"
                                      align="left"
                                    >
                                      No of Attendees
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      colspan="3"
                                      style={{ textAlign: 'center' }}
                                    >
                                      {this.props.users.languageStateForRedux?.common?.noRecords}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Gamification end*/}

                        {/* Devices */}
                        <Grid size={{xs: 12,}} className="dashboard-table-card">
                          <Box
                            className=""
                            sx={{
                              backgroundColor: '#ffffff',
                              border: '1px solid #EDECF5',
                              borderRadius: '8px',
                            }}
                          >
                            <Box
                              component={Grid}
                              container
                              spacing={1}
                              px={3}
                              py={2}
                              alignItems="center"
                              className="group-info-header"
                            >
                              <Grid  >
                                <Box
                                  component={'img'}
                                  mt={0}
                                  alt="icon"
                                  src={DevicesIcon}
                                />
                              </Grid>
                              <Grid  >
                                <span className="paragraph bold">Devices</span>
                              </Grid>
                            </Box>
                            <Box sx={{ borderTop: '1px solid #eeeeee' }}>
                              <Grid container spacing={2} alignItems={'center'}>
                                <Grid size={{xs: 4,}}>
                                  <Box
                                    mx="auto"
                                    sx={{ position: 'relative', width: '155px' }}
                                  >
                                    <Chart
                                      chartType="PieChart"
                                      data={[
                                        ['Devices', 'Percentage'],
                                        [
                                          '',
                                          0
                                        ],
                                        [
                                          '',
                                          100 -
                                          (0),
                                        ],
                                      ]}
                                      options={DesktopOptions}
                                      width={'155px'}
                                      height={'155px'}
                                    />
                                    <Box
                                      style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          color: '#00D0A8',
                                        }}
                                      >
                                        0
                                        %
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Desktop
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid size={{xs: 4,}}>
                                  <Box
                                    mx="auto"
                                    sx={{ position: 'relative', width: '155px' }}
                                  >
                                    <Chart
                                      chartType="PieChart"
                                      data={[
                                        ['Devices', 'Percentage'],
                                        [
                                          '',
                                          0,
                                        ],
                                        [
                                          '',
                                          100 -
                                          (0),
                                        ],
                                      ]}
                                      options={MobileOptions}
                                      width={'155,px'}
                                      height={'155px'}
                                    />
                                    <Box
                                      style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          color: '#F29B35',
                                        }}
                                      >
                                        0
                                        %
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Mobile
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid size={{xs: 4,}}>
                                  <Box
                                    mx="auto"
                                    sx={{ position: 'relative', width: '150px' }}
                                  >
                                    <Chart
                                      chartType="PieChart"
                                      data={[
                                        ['Devices', 'Percentage'],
                                        [
                                          '',
                                          0,
                                        ],
                                        [
                                          '',
                                          100 -
                                          (0),
                                        ],
                                      ]}
                                      options={OtherOptions}
                                      width={'150px'}
                                      height={'150px'}
                                    />
                                    <Box
                                      style={{
                                        textAlign: 'center',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '10px',
                                        fontWeight: '700',
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: '18px',
                                          color: '#FF4170',
                                        }}
                                      >
                                        0
                                        %
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '10px',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Other
                                      </p>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                        {/* Devices end*/}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </ThemeProvider>
          </>
        )}
        {loading || this.props.users.isLoading ? <Loader /> : null}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  report: state.Report,
  gamification: state.Gamification,
  users: state.Users
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getReport: Actions.getReportRequest,
      getPublishedEvents: Actions.getPublishedEventsRequest,
      getReportChallenges: Actions.getReportChallengesRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(Report)

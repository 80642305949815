import React, { Component } from 'react';
import { getProjectIdFromUrl } from '../../../helpers/common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { Stack } from '@mui/material';
import moment from 'moment' 
import Grid from '@mui/material/Grid2';


export class RecentAttendees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            attendees: []
        }
    }

    async componentDidMount() {
        this.getAttendeesList();
    }

    componentDidUpdate(prevProps, prevState) { 
        if (this.props.attendees.sortFilterAttendeesSuccess && !prevProps.attendees.sortFilterAttendeesSuccess) {
            this.setState({
              attendees: this.props.attendees.attendees
            })
        } 
    }

    getAttendeesList = () => {
        const projectId = getProjectIdFromUrl();
        const requestbody = {
          projectId,
          sort_by: '_id',
          order: 'desc',
          pageNumber: 1,
          limit: 10,
          permission: "View Project",
          overview:true
        }
        this.props.sortFilterAttendeesRequest(requestbody)
    } 

    render() {      
        return (
            <div class="tabpanel_content3">
                <div class="block_head3">
                    <h5>{this.props.users.languageStateForRedux?.overviewTab?.recentAttendees}</h5>
                    { Array.isArray(this.props.attendees.attendees) && this.props.attendees.attendees.length > 0 && (
                    <h6 style={{ cursor: 'pointer' }}  onClick={() => this.props.navigate({pathname:`/project-dashboard/attendees/${ this.props.params.eventId}`})}>{this.props.users.languageStateForRedux?.overviewTab?.viewAll}</h6>
                    )}
                </div>
                {Array.isArray(this.props.attendees.attendees) && this.props.attendees.attendees.length > 0 ?
                    <ul class="block_para3">
                        {this.state.attendees.map(attendee => {
                            return (
                                <Grid container component="li" alignItems='start' sx={{ display: 'flex' }} className="block_list1">
                                <Grid component="Box" size={{xs: "grow",}} >
                                    <Grid
                                    container
                                    width={'100%'}
                                    >
                                    <Grid size={{xs: "grow",}}>
                                        <h5>{attendee.email}</h5>
                                    </Grid>
                                    <Grid component="p">{moment.tz(attendee.createdAt, this.props.project?.project?.timezone).format("MMM DD, YYYY, h:mm a")}</Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                            )
                        })}
                    </ul>
                :(
                    <Grid container >
                        <Grid size={{xs: "grow",}}>
                            <h5 style={{ textAlign: 'center',marginTop:'20px' }}>
                                {' '}
                                {this.props.users.languageStateForRedux?.common?.noRecords}
                            </h5>
                        </Grid>
                    </Grid>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    attendees: state.Attendees,
    events: state.Events,
    users: state.Users,
});
  
  const mapActionsToProps = dispatch =>
    bindActionCreators(
      {
        sortFilterAttendeesRequest: Actions.sortFilterAttendeesRequest
      },
      dispatch
    );
  export default connect(mapStateToProps, mapActionsToProps)(RecentAttendees);


import React, { Component } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box'; 
import { connect } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/styles';
import { theme } from '../../../../theme/theme';
import moment from 'moment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import { getSlotsForMeetingApi, getMeetingsIntegrationDetails } from 'ReduxStore/API';
import { convertTZ } from 'helpers/common';
import { createNotification } from 'helpers';
import { getProjectIdFromUrl, getEventURL, getHostName } from '../../../../helpers/common';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../../ReduxStore/Actions';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import GoogleIconImg from '../../../../assets/images/icons/Google_G_Logo.svg'
import Loader from '../../../../Components/Loader/loader';

// StyledTextField is a styled variant of TextField from Material-UI.
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: '12.5px 14px',
  },
});

/**
 * @classdesc React component for creating and editing meeting content.
 * @category Components
 * @extends Component
 */
class MeetingContent extends Component {
  constructor(props) {
    super(props);
    this.anchorRef1 = React.createRef();
    this.anchorRef2 = React.createRef();
    this.anchorRef = React.createRef();
    this.state = {
      emailSubject: '',
      senderName: '',
      replyToEmail: '',
      errors: {},
      meetingTitle: '',
      buttonText: '',
      dateTime: new Date(),
      description: '',
      meetingLocation: '',
      hours: '',
      minutes: '',
      availableTimeSlots: [],
      meetingTime: null,
      meetingDate:
        moment.utc(this.props.projects?.project?.startDate) > moment.utc()
          ? moment
            .utc(this.props.projects?.project?.startDate)
            .tz(this.props.projects?.project?.timezone)
            ?.format('YYYY-MM-DD')
          : moment
            .utc()
            .tz(this.props.projects?.project?.timezone)
            ?.format('YYYY-MM-DD'),
      openSlots: false,
      selectedDateFilter: 'Slots',
      duration: {},
      meetingId: null,
      meetingOrganizer: '',
      email: '',
      SearchDropdown: false,
      listAllUser: [],
      integrations: {},
      loading: false,

    };
  }

  /**
   * @method componentDidMount
   * @description Lifecycle method called after the component has been inserted into the DOM.
   * Fetches the list of all users and sets initial state values.
   */
  componentDidMount(prevProps, prevState) {
    this.listAllUser();
    this.setState(
      {
        meetingLocation: `${this.getLocationURL()}`
      });
    this.getCalendarIntegrationForMeeting();

  }

  /**
  * @function getCalendarIntegrationForMeeting
  * @description A function to get calendar integrations.
  * @returns {Promise} Result of the integration request.
  */
  async getCalendarIntegrationForMeeting() {
    const projectId = getProjectIdFromUrl();
    const paramsData = {
      eventId: projectId
    };
    await getMeetingsIntegrationDetails(paramsData).then(async (result) => {
      if (result.data.integrations) {
        this.setState({
          integrations: result.data.integrations,
        });
      }
    });
  };

  /**
   * @method componentDidUpdate
   * @description Lifecycle method called after the component updates.
   * Updates the component state based on props changes.
   * @param {Object} prevProps - Previous props.
   * @param {Object} prevState - Previous state.
   */

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.Meeting.listAllUserSuccess !==
      prevProps.Meeting.listAllUserSuccess &&
      this.props.Meeting.listAllUserSuccess === true
    ) {
      this.setState({
        loading: false,
        listAllUser: this.props.Meeting.listAllUser,
      });
    }

    if (
      this.props.Meeting.getMeetingTemplateSuccess !==
      prevProps.Meeting.getMeetingTemplateSuccess &&
      this.props.Meeting.getMeetingTemplateSuccess === true
    ) {
      this.setState(
        {
          emailSubject:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.emailSubject
              : this.state.emailSubject,
          senderName:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.senderName
              : this.props.projects?.project?.emailSenderName,
          replyToEmail:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.replyToEmail
              : this.props.projects?.project?.replyToEmail,
          meetingTitle:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.meetingTitle
              : this.state.meetingTitle,
          meetingOrganizer:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.meetingOrganizer
              : JSON.parse(localStorage.getItem('user_details'))?.email,
          description:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.description
              : this.state.description,
          meetingLocation:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?.meetingLocation
              : this.state.meetingLocation,
          meetingTime:
            this.props.isEdit === true &&
              this.props.Meeting.meetingTemplate[0]?.meetingStartDateTime
              ? moment
                .utc(
                  this.props.Meeting.meetingTemplate[0]?.meetingStartDateTime,
                )
                .tz(this.props.projects?.project?.timezone)
                ?.format('YYYY-MM-DD H:mm')
              : this.state.meetingTime,
          meetingDate:
            this.props.isEdit === true &&
              this.props.Meeting.meetingTemplate[0]?.meetingStartDateTime
              ? moment
                .utc(
                  this.props.Meeting.meetingTemplate[0]?.meetingStartDateTime,
                )
                .tz(this.props.projects?.project?.timezone)
                ?.format('YYYY-MM-DD')
              : this.state.meetingDate,
          meetingId:
            this.props.isEdit === true
              ? this.props.Meeting.meetingTemplate[0]?._id
              : null,
        },
        () => this.getSlotsForMeeting(this.state.meetingId),
      );
    }

    if (this.state !== prevState) {
      this.props.getDataFromMeetingContent(this.state);
    }

    if (this.state !== prevState) {
      this.props.getDataFromMeetingContent(this.state);
    }
  }

  /**
   * @method handleChange
   * @description Handles the change event for input fields.
   * Updates the component state based on the input field's value.
   * @param {Object} e - The event object.
   */
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /**
 * @method getLocationURL
 * @description Constructs and returns the location URL based on project details.
 * @returns {string} Location URL for the project.
 */
  getLocationURL = () => {
    let locationUrl = getEventURL(
      this.props.projects.project.eventDomain,
      this.props.projects.project.vanityDomainName,
      this.props.projects.project.isVanityDomainActive,
      this.props.projects.project.isVanityDomainVerified
    );
    return locationUrl;
  };

  /**
  * @method handleChangeForMeetingTitle
  * @description Handles the change event for the meeting title input field.
  * Updates the component state with the entered meeting title and triggers
  * the checkMeetingTitle function when the title is not empty.
  * @param {Object} e - The event object.
  */
  handleChangeForMeetingTitle = (e) => {
    e.preventDefault();
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (
          this.state.meetingTitle !== '' ||
          this.state.meetingTitle.trim().length !== 0
        ) {
          this.checkMeetingTitle(e);
        }
      },
    );
  };

  /**
 * @method handleMeetingDate
 * @description Handles the change event for the meeting date.
 * Updates the component state with the selected meeting date and resets
 * the meeting time, triggering the getSlotsForMeeting function.
 * @param {Object} e - The event object containing the selected date.
 */
  handleMeetingDate = (e) => {
    this.setState(
      {
        meetingDate: moment(e).format('YYYY-MM-DD'),
        meetingTime: null,
      },
      () => this.getSlotsForMeeting(this.state.meetingId),
    );
  };

  /**
 * @method handleChangeSlot
 * @description Handles the selection of a time slot.
 * Updates the component state with the selected time slot and closes the slots dropdown.
 * @param {Object} e - The event object.
 * @param {string} slotTime - The selected time slot.
 * @param {number} i - The index of the selected time slot.
 */
  handleChangeSlot = (e, slotTime, i) => {
    this.setState({
      meetingTime: slotTime,
      openSlots: false,
    });
  };

  /**
 * @method addHours
 * @description Adds specified hours to the given start time.
 * @param {Date} start - The starting date.
 * @param {number} hours - The number of hours to add.
 * @returns {Date} The updated date after adding hours.
 */
  addHours = (start, hours) => {
    new Date(start).setHours(new Date(start).getHours() + hours);
    return start;
  };

  /**
 * @method getSlotsForMeeting
 * @description Fetches available time slots for the selected meeting date.
 * Updates the component state with the retrieved time slots.
 * @param {string} meetingId - ID of the meeting (optional).
 */
  getSlotsForMeeting = (meetingId = null) => {
    const { meetingDate } = this.state;
    const projectId = getProjectIdFromUrl();
    getSlotsForMeetingApi({
      meetingDate: convertTZ(
        moment.utc(meetingDate),
        this.props.projects.project?.timezone,
      ),
      eventId: projectId,
      meetingId: meetingId,
      integrationId: this.state.integrations?._id
    }).then(async (result) => {
      if (result.data.status === 200) {
        this.setState({
          availableTimeSlots: result.data.slots,
        });
      } else {
        createNotification('error', result.data.message);
      }
    });
  };

  /**
 * @method handleToggle3
 * @description Toggles the state of the 'openSlots' to show/hide time slots.
 * Updates the component state with the opposite value of the current 'openSlots' state.
 */
  handleToggle3 = () => {
    this.setState({
      openSlots: !this.state.openSlots,
    });
  };


  /**
   * @method checkMeetingTitle
   * @description Checks the validity of the meeting title.
   * Invokes the checkMeetingTitle function from props with the entered meeting title,
   * project ID, and meeting ID to validate the title.
   * @param {Object} e - The event object containing the entered meeting title.
   */
  checkMeetingTitle = (e) => {
    const projectId = getProjectIdFromUrl();
    if (e.target.value.length > 0 && e.target.value.trim()) {
      this.props.checkMeetingTitle({
        eventId: projectId,
        meetingTitle: e.target.value.trim(),
        meetingId: this.props.Meeting.meetingTemplate[0]?._id,
      });
    }
  };

  /**
 * @method handleSearchDropdown
 * @description Sets the 'SearchDropdown' state to true, opening the search dropdown.
 */
  handleSearchDropdown = () => {
    this.setState({
      SearchDropdown: true
    });
  };

  /**
 * @method handleClearSearchDropdown
 * @description Clears the search dropdown and resets related states.
 * Invokes the listAllUser function to fetch the user list.
 */
  handleClearSearchDropdown = () => {
    this.setState({
      meetingOrganizer: '',
      SearchDropdown: false,
      SearchEnabled: false,
    }, () => this.listAllUser());
  };

  /**
 * @method handleSearchEnabled
 * @description Enables the search dropdown and updates the meeting organizer state.
 * Invokes the listAllUser function to fetch the user list.
 * @param {Object} e - The event object containing the entered search value.
 */
  handleSearchEnabled = (e) => {
    this.setState({
      SearchDropdown: true,
      SearchEnabled: true,
      meetingOrganizer: e.target.value,
    }, () => this.listAllUser());
  };

  /**
 * @method handleSearchDropdownClose
 * @description Closes the search dropdown by setting 'SearchDropdown' state to false.
 * Triggered on certain events like clicking away from the dropdown.
 * @param {Object} event - The event object.
 */
  handleSearchDropdownClose = (event) => {
    this.setState({
      SearchDropdown: false
    });
  };

  /**
 * @method handleListKeyDown
 * @description Handles keydown events on the search dropdown.
 * Closes the dropdown if the 'Tab' key is pressed.
 * @param {Object} event - The event object representing the keydown event.
 */
  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        SearchDropdown: false
      });
    }
  };

  /**
 * @method listAllUser
 * @description Fetches the list of all users based on the entered search value
 * and project details. Invoked when searching or clearing the search dropdown.
 */
  listAllUser = () => {
    this.setState({
      loading:true
    })
    const projectId = getProjectIdFromUrl();
    this.props.listAllUser({ email: this.state.meetingOrganizer, eventId: projectId, isEdit: this.props.isEdit });
  };

  /**
 * @method handleSelectResult
 * @description Handles the selection of a result in the search dropdown.
 * Updates the meeting organizer state and closes the dropdown.
 * @param {string} data - The selected result data.
 */
  handleSelectResult = (data) => {
    this.setState({
      meetingOrganizer: data,
      SearchDropdown: false
    });
  };


  /**
   * @method isDisabled
   * @description Checks if the component should be disabled based on the number of users.
   * Returns true if there is only one user and it matches the current user's email.
   * @returns {boolean} - True if disabled, false otherwise.
   */
  isDisabled = () => {
    if (
      Array.isArray(this.state.listAllUser) && this.state.listAllUser.length === 1) {
      return this.state.listAllUser.includes(JSON.parse(localStorage.getItem('user_details'))?.email);
    }
    return false;
  };

  /**
  * @function render
  * @description Renders the CreateMeeting component UI.
  * @returns {JSX.Element} Rendered component.
  */
  render() {
    const {
      meetingTime,
      meetingDate,
      availableTimeSlots,
      emailSubject,
      senderName,
      replyToEmail,
      meetingTitle,
      meetingOrganizer,
      description,
      meetingLocation,
    } = this.state;

    return (
      <Grid size={{xs: 12, md:6, lg:4}}>
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              border: '1px solid #EDECF5',
              borderRadius: '4px',
              backgroundColor: '#fff',
            }}
            mb={3}
            component={'form'}
            px={3}
            py={3}
          >
            <Box component={Grid} spacing={4} container className="inputField">
              <Grid size={{xs: 12,}}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.emailSub} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterSub}
                  name="emailSubject"
                  value={emailSubject}
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.props.errors.emailSubject}{' '}
                </span>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: 'auto',
              border: '1px solid #EDECF5',
              borderRadius: '4px',
              backgroundColor: '#fff',
            }}
            component={'form'}
            px={3}
            py={3}
          >
            <Box component={Grid} spacing={4} container className="inputField">
              <Grid size={{xs: 12,}}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.meetingTitle} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterTitle}
                  name="meetingTitle"
                  value={meetingTitle}
                  onChange={this.handleChangeForMeetingTitle}
                />
                <span className={this.props.errors.meetingTitle === "Available" ? "success_message" : "error_mesage required"}>
                  {' '}
                  {
                    this.props.errors?.meetingTitle && this.props.errors.meetingTitle}{' '}
                </span>
              </Grid>
              <Grid size={{xs: 12,}} pb={3}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.meetingOrganizer}
                </label>
                <Box
                  style={{
                    position: 'relative',
                  }}
                >
                  <OutlinedInput
                    sx={{
                      marginTop: '2px',
                      padding: '3px 0px',
                    }}
                    notched={false}
                    size="medium"
                    fullWidth
                    placeholder={this.props.users.languageStateForRedux?.settingsSidebar?.users?.searchManager}
                    name=""
                    value={this.state.meetingOrganizer}
                    className=""
                    ref={this.anchorRef}
                    aria-controls={this.state.SearchDropdown ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleSearchDropdown}
                    onChange={this.handleSearchEnabled}
                    inputProps={{
                      maxLength: 63,
                    }}
                    disabled={this.isDisabled()}

                    endAdornment={
                      <InputAdornment position="end">
                        {!this.isDisabled()
                          && this.state.meetingOrganizer &&
                          <IconButton
                            aria-label="clear search"
                            onClick={this.handleClearSearchDropdown}
                            edge="end"
                            size='small'
                          >
                            <CloseIcon fontSize='small' />
                          </IconButton>
                        }
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                  {!this.isDisabled() &&
                    <Popper
                      open={this.state.SearchDropdown}
                      style={{ right: '0 !important', zIndex: 1099 }}
                      className='modal-searchable-dropdown'
                      placement="bottom-start"
                      transition
                      anchorEl={this.anchorRef.current}
                      role={undefined}
                      disablePortal={false}
                      modifiers={{
                        flip: {
                          enabled: true,
                        },
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: 'window',
                        },
                      }}

                    >
                      {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={250}>
                          <Box
                            sx={{
                              maxHeight: '40vh',
                              overflow: 'scroll',
                              backgroundColor: theme.palette.common.white,
                              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                              borderRadius: '4px',
                            }}
                          >
                            <ClickAwayListener onClickAway={this.handleSearchDropdownClose}>
                              <Box id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                <>
                                  {Array.isArray(this.state.listAllUser) && this.state.listAllUser.length > 0 ?
                                    (
                                      <MenuList>
                                        {Array.isArray(this.state.listAllUser) && this.state.listAllUser.length > 0 &&
                                          this.state.listAllUser.map((user) => {
                                            return (
                                              <MenuItem className="search-item"
                                                onClick={() =>
                                                  this.handleSelectResult(user)
                                                }
                                              >
                                                <Box display={'flex'} alignItems={'center'} >
                                                  {user}
                                                </Box>
                                              </MenuItem>
                                            );
                                          }
                                          )
                                        }
                                      </MenuList>
                                    )
                                    :
                                    (
                                      <MenuList>
                                        <MenuItem disabled style={{ justifyContent: 'center' }}>
                                          {this.props.users.languageStateForRedux?.common?.noRecords}
                                        </MenuItem>
                                      </MenuList>
                                    )
                                  }
                                </>
                              </Box>
                            </ClickAwayListener>
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  }
                </Box>
                <span className="error_mesage required">
                  {' '}
                  {this.props.errors.meetingOrganizer}{' '}
                </span>
              </Grid>
              <Grid size={{xs: 12,}} component="div" className="inputField">
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.availableSlot} <span className="required">*</span>
                </label>
                <Grid container spacing={2}>
                  <Grid size={{sm:7}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        value={meetingDate}
                        minDate={
                          this.props.projects?.project?.startDate
                            ? moment.utc(
                              this.props.projects.project?.startDate,
                            ) > moment.utc()
                              ? moment
                                .utc(this.props.projects.project?.startDate)
                                .tz(this.props.projects.project?.timezone)
                                ?.format('YYYY-MM-DD')
                              : moment
                                .utc()
                                .tz(this.props.projects.project?.timezone)
                                .format('YYYY-MM-DD')
                            : new Date()
                        }
                        maxDate={
                          this.props.projects?.project?.endDate &&
                          moment
                            .utc(this.props.projects.project?.endDate)
                            .tz(this.props.projects.project?.timezone)
                            ?.format('YYYY-MM-DD')
                        }
                        onChange={(e) => {
                          this.handleMeetingDate(e);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            variant="outlined"
                            fullWidth
                            size="medium"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid size={{sm:5}} >
                    <Box style={{ position: 'relative', zIndex: 1 }}>
                      <Box
                        component={Button}
                        variant="outlined"
                        style={{
                          padding: '10px 15px',
                          borderRadius: '7px',
                          justifyContent: 'space-between',
                          textTransform: 'capitalize',
                        }}
                        size="medium"
                        disableElevation
                        ref={this.anchorRef1}
                        aria-controls={
                          this.state.openSlots ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={this.handleToggle3}
                        endIcon={<ArrowDropDownIcon />}
                        fullWidth
                      >
                        {meetingTime
                          ? moment(meetingTime).format('h:mm A')
                          : this.props.users.languageStateForRedux?.formfields?.meetings?.slots}
                      </Box>
                      <Popper
                        open={this.state.openSlots}
                        style={{ right: 0 }}
                        placement="bottom-start"
                        className="filter-options"
                        transition
                        anchorEl={this.anchorRef1?.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={{
                                backgroundColor: theme.palette.common.white,
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                borderRadius: '4px',
                                marginTop: '8px',
                                overflowY: 'scroll',
                                maxHeight: 200,
                              }}
                            >
                              {' '}
                              <ClickAwayListener
                                onClickAway={(e) => {
                                  this.setState({
                                    openSlots: false,
                                  });
                                }}
                              >
                                <Stack spacing={1} p={1.5} direction="column">
                                  {Array.isArray(availableTimeSlots) &&
                                    availableTimeSlots.length > 0 &&
                                    availableTimeSlots.map((slots, i) => {
                                      return (
                                        <Button
                                          size="small"
                                          className={'active'}
                                          variant={
                                            slots.disabled
                                              ? 'contained'
                                              : 'outlined'
                                          }
                                          color={''}
                                          disabled={
                                            slots.disabled ? true : false
                                          }
                                          onClick={(e) => {
                                            this.handleChangeSlot(
                                              e,
                                              slots.slotTime,
                                              i,
                                            );
                                          }}
                                        >
                                          {' '}
                                          {moment(slots.slotTime).format(
                                            'h:mm A',
                                          )}

                                          {slots.showGoogleIcon && <img
                                            alt="image to show for google"
                                            width={'16'}
                                            src={GoogleIconImg} 
                                            style={{position: 'absolute',
                                          right: '10px',}}
                                          />
                                          }
                                        </Button>
                                      );
                                    })}
                                </Stack>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </Box>
                    <span className="error_mesage required">
                      {' '}
                      {this.props.errors.meetingStartDateTime}{' '}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs: 12,}}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.desc} <span className="required">*</span>
                </label>
                <textarea
                  rows="5"
                  class="form-control"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterdesc}
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.props.errors.description}{' '}
                </span>
              </Grid>
              <Grid size={{xs: 12,}}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.meetingLocation}
                </label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterMeetingLocation}
                  name="meetingLocation"
                  value={meetingLocation}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid size={{xs: 12,}}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.senderName} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterName}
                  name="senderName"
                  value={senderName}
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.props.errors.senderName}{' '}
                </span>
              </Grid>
              <Grid size={{xs: 12,}}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.meetings?.replytoEmail} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.meetings?.enterRepEmail}
                  name="replyToEmail"
                  value={replyToEmail}
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.props.errors.replyToEmail}{' '}
                </span>
              </Grid>
            </Box>
          </Box>
        </Box>
        {this.state.loading ? <Loader /> : null}
      </Grid>
    );
  }
}

/**
 * @function mapStateToProps
 * @description Maps the Redux state to component props.
 * @param {Object} state - Redux state object.
 * @returns {Object} Props derived from the Redux state.
 */
const mapStateToProps = (state) => ({
  projects: state.Projects,
  Meeting: state.Meeting,
  events: state.Events,
  users: state.Users
});

/**
 * @function mapActionsToProps
 * @description Maps Redux actions to component props.
 * @param {Function} dispatch - Redux dispatch function.
 * @returns {Object} Props containing action functions.
 */
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAllUser: Actions.listAllUserRequest,
      checkMeetingTitle: Actions.checkMeetingTitleRequest,
    },
    dispatch,
  ); 
export default connect(
  mapStateToProps,
  mapActionsToProps,
)(MeetingContent);

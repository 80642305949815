//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
} from '@mui/material';
import { GmailData } from '.';
import IconButton from '@mui/material/IconButton';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '../../../src/assets/images/icons/search.svg';
import SearchIconBar from '../../../src/assets/images/searchIco.png';
import moment from 'moment';
import MoreIcon from '../../../src/assets/images/icons/more.svg';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import { xpAccountIntegrationGmailArchiveEmail, xpAccountIntegrationGmailArchiveMultipleEmails, xpAccountIntegrationGmailArchiveMultipleThreads, xpAccountIntegrationGmailDeleteEmail, xpAccountIntegrationGmailDeleteMultiple, xpAccountIntegrationGmailDeleteMultipleThreads, xpAccountIntegrationGmailGetList, xpAccountIntegrationGmailMarkEmailAsRead, xpAccountIntegrationGmailMarkEmailAsUnread, xpAccountIntegrationGmailMarkMultipleEmailsAsRead, xpAccountIntegrationGmailMarkMultipleEmailsAsUnread, xpAccountIntegrationGmailMarkMultipleThreadsAsRead, xpAccountIntegrationGmailMarkMultipleThreadsAsUnread } from 'ReduxStore/API';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { createNotification } from 'helpers';
import Loader from 'Components/Loader/loader';
import { setIsLoading } from 'Components/ChatWidget/Store/Slices/WidgetSlice';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

type Props = {
  gmailData: GmailData;
  getGmailData: (limit?: number, page_no?: number, showLoader?: false) => void;
  viewGmailContent: (gmail: any, i: number) => void;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  page_no: number;
  setPage_no: React.Dispatch<React.SetStateAction<number>>;
  gmailId: string;
  mailType: string;
  viewDraftMailsInCompose: () => void;
};

function EmailList({
  gmailData,
  getGmailData,
  viewGmailContent,
  limit,
  setLimit,
  page_no,
  setPage_no,
  gmailId,
  mailType,
  viewDraftMailsInCompose,
}: Props) {
  // variables for pagination started
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [labelIdsArray, setLabelIdsArray] = useState<string[]>([]);
  const initialRender = useRef(true);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  //   const [gmailData, setGmailData] = useState<GmailData>({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  console.log("==============mailType", mailType)
  useEffect(() => {
    console.log("mailtype", mailType);
    setSelectedCheckboxes([]);
  }, [page_no]);

  useEffect(() => {
    handleEndCountForPagination(
      gmailData?.totalMailCount,
      limit,
      page_no,
      setEndCount,
    );
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(
        gmailData?.totalMailCount,
        limit,
        page_no,
        setEndCount,
      );
    }
  }, [gmailData]);

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mailId: string,
    labelArray: string[]
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setLabelIdsArray((prev) => [...prev, ...labelArray]);
      setSelectedCheckboxes((prev) => [...prev, mailId]);
    } else {
      setLabelIdsArray((prev) =>  filterOnce(prev,labelArray));
      setSelectedCheckboxes((prev) => prev.filter((id) => id !== mailId));
    }
  };
  console.log('===========labelIdsArray', labelIdsArray)
  const filterOnce = (source, toRemove) => {
    const toRemoveCount = {}; toRemove.forEach(item => { toRemoveCount[item] = (toRemoveCount[item] || 0) + 1; }); return source.filter(item => {
      if (toRemoveCount[item]) {
        toRemoveCount[item]--; return false; // Remove this occurrence 
      } return true; // Keep this item 
    });
  }; 

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      gmailData?.data?.forEach((mail) => arr.push(mail._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleDeleteMultiple = async () => {
    try {
      setIsLoading(true);
      const data = {
        ids: selectedCheckboxes,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailDeleteMultipleThreads(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([])
    }
  };

  const handleDeleteSingle = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailDeleteEmail(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };
  const handleArchiveEmail = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailArchiveEmail(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };

  const handleMarkEmailAsRead = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailMarkEmailAsRead(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };
  const handleMarkEmailAsUnread = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const markMultipleThreadsAsRead = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsRead(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([])
      setIsLoading(false);
    }
  };

  const markMultipleThreadsAsUnread = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsUnread(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([])
      setIsLoading(false);
    }
  };

  const archiveMultipleThreads = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId
      };
      const res = await xpAccountIntegrationGmailArchiveMultipleThreads(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([])
      setIsLoading(false);
    }
  };

  const handleArchiveSingle = async (messageId: string) => {
    try {
      const data = {
        messageId: messageId,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailArchiveEmail(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
      createNotification('error', error.message);

    }
  };

  console.log('========selectedCheckboxes', selectedCheckboxes);

  return (
    <div>
      <Box>
        <Box
          pb={1}
          mt={-5}
          mb={2}
          sx={{ borderBottom: '1px solid #EDECF5' }}
          className="top-filter-area"
        >
          <Grid container spacing={1} width={'100%'}>
            <Grid size={{ md: 'grow' }}>
              <Grid container spacing={1} alignItems="center">
                <Grid>
                  <Box pl={'15px'}>
                    <Checkbox
                      color="primary"
                      onClick={handleSelectAllCheckboxes}
                      checked={
                        gmailData?.data?.length
                          ? selectedCheckboxes.length ===
                          gmailData?.data?.length
                          : false
                      }
                    />
                  </Box>
                </Grid>
                <Grid>
                {mailType !== "TRASH" ? <IconButton title="Archive" onClick={()=> archiveMultipleThreads()}>
                    <ArchiveOutlinedIcon />
                  </IconButton>:null}
                </Grid>
                <Grid>
                {mailType !== "TRASH" ? <IconButton title="Delete">
                    <DeleteOutlineOutlinedIcon onClick={()=> setOpenDeleteConfirmationModel(true)}/>
                  </IconButton>:null}
                </Grid>
                {/* <Grid>
                  <IconButton title="Refresh">
                    <RefreshOutlinedIcon />
                  </IconButton>
                </Grid> */}
                <Grid>
                  {labelIdsArray.includes("UNREAD") ? (
                    <IconButton
                      title="Mark as read"
                      onClick={() => markMultipleThreadsAsRead()}
                    >
                      <DraftsOutlinedIcon />
                    </IconButton>
                  ) : (selectedCheckboxes.length >= 1 &&
                    <IconButton
                      title="Mark as unread"
                      onClick={() => markMultipleThreadsAsUnread()}
                    >
                      <MarkEmailUnreadOutlinedIcon />
                    </IconButton>
                  )}
                </Grid>
                {/* <Grid>
                  <Box
                    className="options"
                    style={{ minWidth: '50px', maxWidth: '50px' }}
                  >
                    {' '}
                    <div className="options-button">
                      <IconButton title="More Options">
                        <MoreVertOutlinedIcon />
                      </IconButton>{' '}
                    </div>
                    <div className="options-list" style={{ right: '10px' }}>
                      <div>Option 1</div>
                      <hr />
                      <div>Option 2</div>
                    </div>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid size={{ xs: 3, }}>
              <Grid container spacing={1.25} className="team-dash-right">
                <Grid size={{ xs: "grow", }} className="">
                  <form>
                    <TextField
                      name="searchText"
                      placeholder={'Search'}
                      className="search"
                      size="small"
                      // value={searchText}
                      // onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      style={{ width: '100%' }}
                      variant="standard"
                    />
                    <button className="d-none" type="submit">
                      <img src={SearchIconBar} alt="" />
                    </button>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <TableContainer
          className="list-table email-listing"
          style={{ maxHeight: 'calc(100vh - 200px)' }}
        >
          <Table className="table">
            <TableBody>
              {gmailData.data ?
                gmailData.data.map((gmail: any, index: number) => {
                  return (
                    <TableRow
                      style={{
                        cursor: 'pointer',
                        backgroundColor: gmail.messages.some((message) =>
                          message.labelIds.includes('UNREAD'),
                        )
                          ? '#edecf5'
                          : '#fff',
                      }}
                    >
                      <TableCell className="checkbox ">
                        <Checkbox
                          color="primary"
                          onClick={(e) => {
                            handleSelectCheckBox(
                              e,
                              gmail.messages[0].threadId,
                              gmail.messages[0].labelIds,
                            );
                          }}
                          checked={selectedCheckboxes.includes(
                            gmail.messages[0].threadId,
                          )}
                        />
                      </TableCell>
                      <TableCell
                        className={`long-text  ${
                          gmail.messages[0].labelIds.includes('UNREAD')
                            ? 'bold'
                            : ''
                        }`}
                        onClick={() =>
                          mailType === 'DRAFT'
                            ? viewDraftMailsInCompose(gmail.messages[0])
                            : viewGmailContent(gmail.messages, index)
                        }
                      >
                        {
                          gmail.messages[0].payload.headers.find(
                            (g: any) => g.name === 'From',
                          ).value
                        }
                      </TableCell>
                      <TableCell
                        className="long-text"
                        style={{ maxWidth: 'unset' }}
                        onClick={() =>
                          mailType === 'DRAFT'
                            ? viewDraftMailsInCompose(gmail.messages[0])
                            : viewGmailContent(gmail.messages, index)
                        }
                      >
                        <Stack
                          direction={'row'}
                          className="email-title"
                          spacing={1}
                        >
                          <Box
                            className="long-text"
                            style={{ maxWidth: '100%' }}
                            onClick={() =>
                              mailType === 'DRAFT'
                                ? viewDraftMailsInCompose(gmail.messages[0])
                                : viewGmailContent(gmail.messages, index)
                            }
                          >
                            <span
                              className={`${
                                gmail.messages[0].labelIds.includes('UNREAD')
                                  ? 'bold'
                                  : ''
                              }`}
                            >
                              {
                                gmail.messages[0].payload.headers.find(
                                  (g: any) => g.name === 'Subject',
                                ).value
                              }{' '}
                            </span>{' '}
                            -{' '}
                            {gmail.messages[0].snippet.length > 80
                              ? `${gmail.messages[0].snippet.slice(0, 80)}...`
                              : gmail.messages[0].snippet}
                          </Box>
                          {gmail.messages[0].payload.mimeType ===
                            'multipart/mixed' &&
                            gmail.messages[0].payload.parts &&
                            Array.isArray(gmail.messages[0].payload.parts) &&
                            gmail.messages[0].payload?.parts?.length > 1 && (
                              <Badge
                                color="primary"
                                badgeContent={
                                  gmail.messages[0].payload?.parts?.length - 1
                                }
                              >
                                <AttachmentOutlinedIcon />
                              </Badge>
                            )}
                        </Stack>
                      </TableCell>
                      <TableCell
                        className=""
                        style={{ minWidth: '90px', maxWidth: '90px' }}
                      >
                        {moment(
                          gmail.messages[0].payload.headers.find(
                            (g: any) => g.name === 'Date',
                          ).value,
                        ).format('MMM, DD')}
                      </TableCell>
                      <TableCell
                        className="options"
                        style={{ minWidth: '50px', maxWidth: '50px' }}
                      >
                        {' '}
                        <div className="options-button">
                          <img alt="" src={MoreIcon} />{' '}
                        </div>
                        <div className="options-list" style={{ right: '10px' }}>
                          <div
                            onClick={() =>
                              handleArchiveEmail(gmail.messages[0].threadId)
                            }
                          >
                            Archive
                          </div>
                          <div
                            onClick={() =>
                              gmail.messages[0].labelIds.includes('UNREAD')
                                ? handleMarkEmailAsUnread(
                                    gmail.messages[0].threadId,
                                  )
                                : handleMarkEmailAsRead(
                                    gmail.messages[0].threadId,
                                  )
                            }
                          >{`Mark as ${
                            gmail.messages[0].labelIds.includes('UNREAD')
                              ? 'unread'
                              : 'read'
                          }`}</div>
                          
                          { mailType !== "TRASH" && 
                          <>
                          <hr />
                          <div
                            style={{ color: '#ff4170' }}
                            onClick={() =>
                              handleDeleteSingle(gmail.messages[0].threadId)
                            }
                          >
                            Delete
                          </div>
                          </>}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }) : <TableRow style={{ cursor: 'pointer' }}>
                  <TableCell sx={{ textAlign: "center" }} colSpan={5}>
                    No records found
                  </TableCell>

                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        {gmailData?.data?.length !== 0 && <TableContainer>
          <Table>
            <TableFooter>
              <TableRow>
                <PaginationFunctional
                  startCount={startCount}
                  setStartCount={setStartCount}
                  endCount={endCount}
                  limit={limit}
                  setLimit={setLimit}
                  page_no={page_no}
                  setPage_no={setPage_no}
                  listItemsFn={getGmailData}
                  totalItemsCount={gmailData?.totalMailCount}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>}
      </Box>
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => handleDeleteMultiple()}
      />
      {isLoading && <Loader />}
    </div>
  );
}

export default EmailList;

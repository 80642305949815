import React, { useEffect, useState } from 'react';
import { Box, Button, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '../../../../../assets/images/icons/search.svg';
import Avatar from '@mui/material/Avatar';

import { styles } from './common';
import { useDispatch, useSelector } from 'react-redux';
 
import { Actions } from 'ReduxStore/Actions';
import { getProjectIdFromUrl } from 'helpers/common';
import CustomSwitchForGoogleManagement from './CustomSwitchForGoogleManagement';
import PaginationFunctional, { handleEndCountForPagination } from './PaginationFunctional';
import Loader from 'Components/Loader/loader';
import moment from 'moment';
import Grid from '@mui/material/Grid2';

function GroupManagement(props) {
    const dispatch = useDispatch();
    const groupsState = useSelector(store => store.GoogleWorkspace.groups);
    const getGroupsForGoogleWorkspaceSuccessState = useSelector(store => store.GoogleWorkspace.getGroupsForGoogleWorkspaceSuccess);
    const googleWorkspaceState = useSelector(store => store.GoogleWorkspace);
    const projectsState = useSelector(store => store.Projects);
    const totalNumberOfGroups = useSelector(store => store.GoogleWorkspace.totalNumberOfGroups);
    const updateGoogleManagementListSuccessState = useSelector(store => store.GoogleWorkspace.updateGoogleManagementListSuccess);
    const errorState = useSelector(store => store.GoogleWorkspace.error);
    const [groups, setGroups] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [toShowGroupIds, setToShowGroupIds] = useState([]);
    const [toNotShowGroupIds, setToNotShowGroupIds] = useState([]);
    const [loading, setLoading] = useState(false);
    // variables for pagination started
    const [limit, setLimit] = useState(10);
    const [page_no, setPage_no] = useState(1);
    const [startCount, setStartCount] = useState(1);
    const [endCount, setEndCount] = useState(0);
    // variables for pagination ends

    // Calling listing API
    useEffect(() => {
        dispatch(Actions.setUpdateGoogleManagementListSuccess(false));
        getGroupsList();
    }, []);

    // Function for calling listing API
    const getGroupsList = (limitFromPagination, pageNumFromPagination) => {
        const projectId = getProjectIdFromUrl();
        const body = {
            projectId,
            integrationId: props.integrationId,
            page_no: pageNumFromPagination || page_no,
            searchText,
            limit: limitFromPagination || limit,
        };
        dispatch(Actions.getGroupsForGoogleWorkspaceRequest(body));
        setLoading(true);
    };

    // handling the response from listing API
    useEffect(() => {
        if (getGroupsForGoogleWorkspaceSuccessState) {
            setGroups(groupsState);

            handleEndCountForPagination(totalNumberOfGroups, limit, page_no, setEndCount); // Imported from pagination component

            let showGroupIdsArr = [];
            let notShowGroupIdsArr = [];
            groupsState?.forEach(el => {
                el?.showToGroup ? showGroupIdsArr.push(el._id) : notShowGroupIdsArr.push(el._id);
            });
            setToShowGroupIds(showGroupIdsArr);
            setToNotShowGroupIds(notShowGroupIdsArr);
            setLoading(false);
            dispatch(Actions.setGetGroupsForGoogleWorkspaceSuccess(false));
        }
    }, [getGroupsForGoogleWorkspaceSuccessState]);

    // If we successfully get the response from the server we will hide the loader
    useEffect(() => {
        updateGoogleManagementListSuccessState && setLoading(false);
        dispatch(Actions.setUpdateGoogleManagementListSuccess(false));
    }, [updateGoogleManagementListSuccessState]);

    // If error arises in the response from the server we will hide the loader
    useEffect(() => {
        errorState && setLoading(false);
    }, [errorState]);


    // When we type something in the search input
    const handleSearchInput = (e) => {
        e.preventDefault();
        setSearchText(e.target.value);
    };

    // When we press enter after typing something in the search input
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getGroupsList();
    };

    const handleSyncGroups = () => {
        const projectId = getProjectIdFromUrl();
        const body = {
            projectId,
            integrationId: props.integrationId
        };
        dispatch(Actions.updateGroupsFromGoogleWorkspaceRequest(body));
    };

    useEffect(() => {
        if (googleWorkspaceState.checkWhetherGroupNameExistInIllumeetSuccess) {
            if (googleWorkspaceState.groupDoesExist) {
                setToNotShowGroupIds([...toNotShowGroupIds, googleWorkspaceState.groupId]);
                const filteredArray = removeGroupFromArray(toShowGroupIds, googleWorkspaceState.groupId);
                setToShowGroupIds([...filteredArray]);

                let groupsArr = [...groups];

                groupsArr.forEach(obj => {
                    if (obj._id === googleWorkspaceState.groupId) {
                        obj.showToGroup = false;
                        return obj;
                    } else {
                        return obj;
                    }
                });
                setGroups([...groupsArr]);
            }
        }
    }, [googleWorkspaceState.checkWhetherGroupNameExistInIllumeetSuccess]);

    const handleChange = (checked, groupId, j) => {
        groups[j].showToGroup = checked;
        if (checked) {
            setToShowGroupIds([...toShowGroupIds, groupId]);
            const filteredArray = removeGroupFromArray(toNotShowGroupIds, groupId);
            setToNotShowGroupIds([...filteredArray]);
        } else {
            setToNotShowGroupIds([...toNotShowGroupIds, groupId]);
            const filteredArray = removeGroupFromArray(toShowGroupIds, groupId);
            setToShowGroupIds([...filteredArray]);
        }
        setGroups([...groups]);
    };

    const removeGroupFromArray = (array, groupId) => {
        return array.filter(id => id !== groupId);
    };

    return (
        <Box mt={1}>
            {/* ---header--- */}
            <Box
                pb={3}
                pt={2.5}
                mb={1}
                // sx={{ borderBottom: '1px solid #EDECF5' }}
                className="top-filter-area"
            >
                <Grid container spacing={2}>
                    <Grid size={{md: 'grow',}}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                        >
                            <Grid >
                                <Box
                                    component="h4"
                                    className="subsecondary"
                                    sx={{
                                        marginRight: 50,
                                        marginBottom: 0,
                                    }}
                                >
                                    Group Management ({totalNumberOfGroups})
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid
                            container
                            spacing={1.25}
                            className="team-dash-right"
                        >
                            <Grid >
                                <Button
                                    variant="text"
                                    color="primary"
                                    disableElevation
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={handleSyncGroups}
                                >
                                    Sync Groups
                                </Button>
                            </Grid>
                            <Grid className="">
                                <form onSubmit={handleSearchSubmit}>
                                    <TextField
                                        name="searchText"
                                        variant='standard'
                                        placeholder="Search..."
                                        className="search"
                                        size="small"
                                        value={searchText}
                                        onChange={handleSearchInput}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img alt="" src={SearchIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <button
                                        className="d-none"
                                        type="button"
                                    ></button>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <TableContainer className="list-table">
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="name-avatar sticky-cell"
                                style={{
                                    left: '0px',
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell className="email">
                                Email
                            </TableCell>
                            <TableCell className="date-time">
                                Updated
                            </TableCell>

                            <TableCell className="text">
                                Members Count
                            </TableCell>
                            <TableCell
                                className="options"
                                style={{ maxWidth: '150px', minWidth: '150px' }}
                            >
                                Show to Groups
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(groups) && groups.length ?
                            groups.map((group, j) => {
                                return (
                                    <TableRow key={j}>
                                        <TableCell className="name-avatar">
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                            >
                                                <Grid >
                                                    <Avatar style={styles.blue}>
                                                        <GroupIcon />
                                                    </Avatar>
                                                </Grid>
                                                <Grid component={"p"} size={{xs: "grow",}}>
                                                    <Box className="">
                                                        {group.attendeesGroupName}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell
                                            className="email sticky-cell"
                                            style={{
                                                left: '0px',
                                                backgroundColor: '#ffffff',
                                            }}
                                        >
                                            {group.email}
                                        </TableCell>
                                        <TableCell className="date-time">
                                            {moment
                                                .tz(
                                                    group.updatedAt,
                                                    projectsState?.project?.timezone,
                                                )
                                                .format('MMM DD YYYY')}
                                        </TableCell>

                                        <TableCell className="text">{group.totalAttendeesCount}</TableCell>

                                        <TableCell
                                            className="options"
                                            style={{
                                                maxWidth: '150px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <CustomSwitchForGoogleManagement
                                                color={'primary'}
                                                groupId={group._id}
                                                checked={group.showToGroup || false}
                                                setToShowGroupIds={setToShowGroupIds}
                                                toShowGroupIds={toShowGroupIds}
                                                toNotShowGroupIds={toNotShowGroupIds}
                                                setToNotShowGroupIds={setToNotShowGroupIds}
                                                attendeesGroupName={group.attendeesGroupName}
                                                integrationId={props.integrationId}
                                                onChange={(checked) => handleChange(checked, group._id, j)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) :

                            "There are no records to display"
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationFunctional
                startCount={startCount}
                setStartCount={setStartCount}
                endCount={endCount}
                limit={limit}
                setLimit={setLimit}
                page_no={page_no}
                setPage_no={setPage_no}
                totalItemsCount={totalNumberOfGroups}
                listItemsFn={getGroupsList}
            />
            {loading && <Loader />}
        </Box>
    );
}

export default GroupManagement;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import '../../sass/main.scss'
import illumeetLogo from '../../assets/images/loginLogo.png'
import { Button } from '@mui/material'
import Shape1 from '../../assets/images/Path_421.png'
import Shape2 from '../../assets/images/Path_422.png'
import Shape3 from '../../assets/images/Path_423.png'
import Shape4 from '../../assets/images/Path_424.png'
import Shape5 from '../../assets/images/Path_425.png'
import { Helmet } from 'react-helmet'
import axios from 'axios'
const URL = process.env.REACT_APP_API_URL
class Login extends Component {
  constructor(props) {
    super(props)
    // window.location.href = Url.wixUrl;
    this.state = {
      email: '',
      password: '',
      isRemember: false,
      errors: {},
      loading: false,
    }
  }

  componentDidMount() {
    if (localStorage.isRemember && localStorage.email !== '') {
      this.setState({
        isRemember: true,
        email: localStorage.email,
        password: localStorage.password,
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const login = this.props.login 
    if (login && login.success && login.token) { 
      if (login.user.email && login.user.email !== '') {
        let st = prevState.loading ? this.setState({ loading: false }) : null
        let userDetails = JSON.parse(localStorage.getItem("user_details"))
        if(!userDetails.servicePlanId){
          this.getServicePlanId()
          this.props.navigate('/my-events')
        }
      }
      //this.props.navigate("/profile")
      /* if(login.user.isComplete)
            {
                if(login.user.role === 1)
                {
                    this.props.navigate("/profile");
                }else{
                    this.props.navigate("/profile");
                }
             
            }else{
                if(login.user.role === 1)
                {
                    this.props.navigate("/complete-registration");
                }else{
                    this.props.navigate("/profile");
                }
            }*/
    } else if (login && login.error && this.state.loading) {
      createNotification('error', login.message, '')
      this.handleLoading()
    }
  }

  UserData = async () => {
    return JSON.parse(localStorage.getItem('user_details'))
  }

  getServicePlanId = async () => {
    try {
      const users = await this.UserData()
      const res = await axios.get(`${URL}/addServicePlanInIllumeetXP`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      })

      let servicePlanId
      if (res.status === 200) {
        if (res.data.status === 200) {
          servicePlanId = res.data.servicePlanId
          let user_details = JSON.parse(localStorage.getItem('user_details'))
          user_details.servicePlanId = servicePlanId
          localStorage.setItem("user_details", JSON.stringify(user_details))
        } else {
          console.log('Something went wrong')
        }
      } else {
        console.log('Something went wrong')
      }
    } catch (error) {
      return error
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleCheckBox = (event) => { 
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isLoginValid = () => { 
    const { email, password } = this.state
    let error = {}
    let formIsValid = true 
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (email === '' || email.trim().length === 0) {
      formIsValid = false
      error['email'] = '*Please enter email.'
    }
    if (email && regexTest.test(email) === false) { 
      formIsValid = false
      error['email'] = '*Please enter a valid email address.'
    }
    if (password === '' || password.trim().length === 0) {
      formIsValid = false
      error['password'] = '*Please enter password.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  handleSubmit = (e) => {
    e.preventDefault() 
    if (this.isLoginValid()) {
      const { email, password, isRemember } = this.state
      if (isRemember) {
        localStorage.email = email
        localStorage.password = password
        localStorage.isRemember = isRemember
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('password')
        localStorage.removeItem('isRemember')
      }

      const requestbody = { email, password }
      this.props.userLogin(requestbody)
      this.handleLoading()
      // this.props.startLoading();
    }
  }

  render() {
    const { email, password, errors, loading, isRemember } = this.state
     return (
      <div className="main_page">
        <Helmet>
          <title>IllumeetXP | Login</title>
        </Helmet>
        <div className="registerPage">
          <div className="leftSidebar">
            <div className="registerSidebarBrand">
              <img src={illumeetLogo} alt="Illumeet Logo" />
              <div className="brandingText">
                <h1>
                  One platform. <br />
                  One process. <br />
                  All your events.
                </h1>
              </div>
            </div>
            <div className="signupShapes">
              <span className="shape-1">
                <img src={Shape1} alt="" />
              </span>
              <span className="shape-2">
                <img src={Shape2} alt="" />
              </span>
              <span className="shape-3">
                <img src={Shape3} alt="" />
              </span>
              <span className="shape-4">
                <img src={Shape4} alt="" />
              </span>
              <span className="shape-5">
                <img src={Shape5} alt="" />
              </span>
            </div>
          </div>
          <div className="registerFormMain">
            <div className="registerFormInner">
              <div className="pageHeading text-center">
                <h1>Login</h1>
                <p>Hello! To get started please login! </p>
                {loading ? <Loader /> : null}
              </div>
              <div className="formSection LoginForm">
                <form onSubmit={this.handleSubmit}>
                  <div className="field-group fullWidthInput">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      className="form-control emailLoweCase"
                    />
                    <p className="error_mesage"> {errors.email} </p>
                  </div>
                  <div className="field-group fullWidthInput">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    <p className="error_mesage"> {errors.password} </p>
                  </div>
                  <div className="field-group fullWidthInput m-0">
                    <div className="conditionsterms">
                      <div className="conditionGroup">
                        <label className="checkBoxColl">
                          {' '}
                          Remember Password
                          <input
                            name="isRemember"
                            type="checkbox"
                            onChange={this.handleCheckBox}
                            checked={isRemember}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="field-group fullWidthInput">
                    <div className="submitForm">
                      <Button type="submit" className="themeBlueButton">
                        Login
                      </Button>
                    </div>
                  </div>
                  {/* <div className="field-group fullWidthInput loginHere">
                                   <p>Don't have an account ? &nbsp;<a onClick={()=>this.props.navigate("/register")}>Register</a></p> 
                                </div> */}
                  <div className="field-group fullWidthInput loginHere">
                    <p>
                      Forgot Password ? &nbsp;
                      <a
                        onClick={() =>
                          this.props.navigate('/forgotPassword')
                        }
                      >
                        Click Here
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <NotificationContainer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,

  // loading: state.Loader.loaderOne,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,

      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Login)

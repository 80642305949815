import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
// import '../../sass/main.scss';
import { Button } from '@mui/material';
import 'react-responsive-modal/styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import ExportIcon from '../../assets/images/icons/export.svg';
import { ExportToCsv } from 'export-to-csv';
import { samplecsv } from 'ReduxStore/API';

const ImportModal = (props) => {
  const [state, setState] = useState({
    errors: {},
    loading: false,
    importOpen: props.importOpen ? props.importOpen : false,
    csvImport: '',
    customFields: props.customFields ? props.customFields : [],
    page_no: props.page_no ? props.page_no : 1,
    group_id: props.group_id ? props.group_id : null,
    openSuccess: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const onOpenImportModal = () => {
    setState({ ...state, groupOpen: true });
  };

  const onCloseImportModal = () => {
    setState({ ...state, groupOpen: false });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (props?.contacts?.importSuccess === true && props?.contacts?.message !== state.contacts?.message) {
      setState({ ...state, loading: false });
    }

    if (props.contacts.importError && props.contacts.message !== state.contacts?.message) {
      createNotification('error', props.contacts.message, '');
      setState({ ...state, loading: false });
    }

    if (props.contacts.importContactValidation === true && props.contacts.message !== state.contacts?.message) {
      setState({ ...state, loading: false });
    }
  }, [props.contacts]);

  const handleLoading = () => {
    setState({
      ...state,
      loading: !state.loading,
    });
  };

  const isValidGroup = () => {
    const { csvImport, group_id } = state;
    let error = {};
    let formIsValid = true;
    if (csvImport === '') {
      formIsValid = false;
      error['csvImport'] = '*Please select csv file.';
    }
    setState({ ...state, errors: error });
    return formIsValid;
  };

  const handleGroupSubmit = (e) => {
    e.preventDefault();
    if (isValidGroup()) {
      const { csvImport, group_id } = state;
      const formData = new FormData();

      formData.append('page_no', props.page_no);
      formData.append('group_id', props.group_id);
      formData.append('csvImport', csvImport);
      formData.append('permission', 'Create Audience');
      props.importContactRequest(formData);
      setState({ ...state, groupOpen: false });
      handleLoading();
    }
  };

  const onCSVChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (!imageFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.');
        return false;
      }
      setState({ ...state, csvImport: event.target.files[0] });
    }
  };

  const sampleCsv = () => {
    samplecsv()
      .then((result) => {
        if (result.data.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([result.data.csvdata]),
          );

          const link = document.createElement('a');

          link.href = url;

          link.setAttribute('download', 'Audience_sample.csv'); //or any other extension

          document.body.appendChild(link);

          link.click();
        } else {
          createNotification('error', result.data.message);
        }
        setState({ ...state, loading: false, downloadExport: false });
      })
      .catch(console.log);
  };

  const { errors, loading, group_id } = state;
  let options = [];
  {
    props.groups &&
      props.groups.map((data, index) => {
        options.push({ id: data._id, groupName: data.groupName });
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid className="modal-wrapper" container component={'form'} onSubmit={handleGroupSubmit}>
        <Grid size={{xs: 12,}} pb={4}>
          <Box component="p" mb={1} className="subtitle">{props.users.languageStateForRedux?.audienceTab?.importContacts}</Box>
          <p className="small-subtitle">
            {props.users.languageStateForRedux?.audienceTab?.importContactDesc}
          </p>
        </Grid>

        <Grid size={{xs: 12,}}>
          <Box component="div" className="inputField" pb={4}>
            <label> {props.users.languageStateForRedux?.actions?.attendees?.select} <span className="required">*</span></label>
            <input
              className="form-control small"
              type="file"
              id="csvImport"
              name="csvImport"
              onChange={onCSVChange}
              accept=".csv"
            />
            <span className="error_mesage"> {errors.csvImport} </span>
            <Button
              onClick={sampleCsv}
              size="small"
              variant="text"
              color="primary"
              startIcon={<img alt="" src={ExportIcon} />}
            >
              {props.users.languageStateForRedux?.actions?.attendees?.download}
            </Button>
          </Box>
        </Grid>

        <Grid size={{xs: 12,}}>
          <Stack spacing={1} direction="row" justifyContent="end">
            <Button type="submit" variant="contained" color="primary">
              {props.users.languageStateForRedux?.audienceTab?.importContacts}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <NotificationContainer />
      {loading ? <Loader /> : null}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      importContactRequest: Actions.importContactRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(ImportModal);

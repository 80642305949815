import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Helmet } from 'react-helmet'
import EditingBack from '../../assets/images/backPage.png'
import footerLogo from '../../assets/images/footer__logo.png'
import Pagination from 'react-js-pagination'
import { Modal } from 'react-responsive-modal'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import UpdateModal from '../Contact/updateModal'
import ContactModal from '../Contact/contactModal'
import { redirectTo } from '../../helpers'
import PageToolBar from '../../Components/PageToolBar'
import DashboardLayout from '../../Layouts/DashboardLayout'
import {
  cancelScheduleForSelected,
  rescheduleForSelected,
  getSingleTemplateApi,
  AddMoreScheduleEmailAPI,
  updateSchedule,
} from 'ReduxStore/API'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class Reschedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFilterVisible: false,
      isFilterImports: false,
      title: 'Reschedule',
      pageTitle: 'Reschedule',
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      totalResults: 1,
      groupOpen: false,
      importOpen: false,
      updateOpen: false,
      singleData: {},
      contacts: [],
      allChecked: false,
      list: [],
      listAll: [],
      showDelete: true,
      searchText: '',
      firstChar: '',
      contacteditPopup: true,
      eventId: '',
      templateId: '',
      openResipients: false,
      ids: [],
      group_id: '',
      emailDate: new Date(),
      initialDate: new Date(),
      scheduleList: [],
      scheduleListWithoutEdit: [],
      addMoreContactList: [],
      templateData: '',
    }
  }

  componentDidMount() {
    if ( this.props.params.eventId !== '') {
      this.setState({ eventId:  this.props.params.eventId })
    }
    if ( this.props.params.templateId !== '') {
      this.setState({ templateId:  this.props.params.templateId })
      this.getTemplateList( this.props.params.templateId)
    }

    if (this.props) {
      this.props.groupRequest()
      let requestbody =
        this.state.group_id !== ''
          ? { page_no: this.state.page_no, group_id: this.state.group_id }
          : { page_no: this.state.page_no }
      this.props.listContactRequest(requestbody)
      this.props.listAllContactRequest(requestbody)
      this.handleLoading()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contacts.success === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.setState({ totalResults: this.props.contacts.totalResults })
      this.setState((prevState) => {
        let { list, listAll } = prevState
        list = list.map((item) => ({ ...item, isChecked: false }))
        listAll = listAll.map((item) => ({ ...item, isChecked: false }))

        return { list, listAll }
      })
      this.setState({ allChecked: false })
    }

    if (
      this.props.contacts.listSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.setState({ totalResults: this.props.contacts.totalResults })
      let data = await this.f1(this.props.contacts.contacts)
      this.setState({ list: data })
    }

    if (
      this.props.contacts.contactSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        open: false,
      })
      let requestbody =
        this.state.group_id !== ''
          ? { page_no: this.state.page_no, group_id: this.state.group_id }
          : { page_no: this.state.page_no }
      this.props.listAllContactRequest(requestbody)
      this.getTemplateList(this.state.templateId)
    }
    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        updateOpen: false,
      })
      //let data =  await this.f1(this.props.contacts.contacts);
      //this.setState({list:data});
      let requestbody =
        this.state.group_id !== ''
          ? { page_no: this.state.page_no, group_id: this.state.group_id }
          : { page_no: this.state.page_no }
      this.props.listAllContactRequest(requestbody)
      this.getTemplateList(this.state.templateId)
    }

    if (
      this.props.contacts.contacts &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let data = await this.f1()
      this.setState({ list: data })
    }

    if (
      this.props.contacts.listAllSuccess &&
      this.props.contacts.allContactsList &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      let allData = await this.all(this.props.contacts.allContactsList)
      this.setState({ listAll: allData })
      
    }

    if (
      this.props.contacts.error === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
    if (
      this.props.contacts.listError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }

    if (
      this.props.events.success === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
    if (
      this.props.events.emailSuccess === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.setState({ openResipients: false })

      this.setState((prevState) => {
        let { list, listAll } = prevState
        list = list.map((item) => ({ ...item, isChecked: false }))
        listAll = listAll.map((item) => ({ ...item, isChecked: false }))

        return { list, listAll }
      })
      this.setState({ allChecked: false })
    }
    if (
      this.props.events.error === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      createNotification('error', this.props.events.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  f1 = async () => {
    //var contacts = [];
    let data =
      this.props.contacts.contacts &&
      this.props.contacts.contacts.map((data, index) => {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          _id: data._id,
          isChecked: false,
          email: data.email,
          company: data.company,
          profilePic: data.profilePic,
          groupName: data.group_id ? data.group_id.groupName : 'N/A',
        }
      })

    return await data
  }

  all = async () => {
    //var contacts = [];
    let data =
      this.props.contacts.allContactsList &&
      this.props.contacts.allContactsList.map((data, index) => {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          _id: data._id,
          isChecked: false,
          email: data.email,
          company: data.company,
          profilePic: data.profilePic,
          groupName: data.group_id ? data.group_id.groupName : 'N/A',
        }
      })

    return await data
  }

  handleChangeCheck = async (e) => {
    let itemName = e.target.name
    let checked = e.target.checked
    let addMoreContactList = []
    await this.setState((prevState) => {
      let {
        allChecked,
        list,
        listAll,
        scheduleList,
        scheduleListWithoutEdit,
      } = prevState
      if (itemName === 'checkAll') {
        allChecked = checked
        list = list.map((item) => ({ ...item, isChecked: checked }))
        listAll = listAll.map((item) => ({ ...item, isChecked: checked }))
      } else {
        list = list.map((item) =>
          item.email === itemName ? { ...item, isChecked: checked } : item,
        )
        listAll = listAll.map((item) =>
          item.email === itemName ? { ...item, isChecked: checked } : item,
        )
        let scheduleData = listAll.filter((item) => item.email === itemName)
        let scheduleListD = scheduleList.filter(
          (item) => item !== scheduleData[0]._id,
        )

        for (let i = 0; i < scheduleListWithoutEdit.length; i++) {
          for (let j = 0; j < listAll.length; j++) {
            if (
              listAll[j]._id !== scheduleListWithoutEdit[i] &&
              listAll[j].isChecked === true
            ) {
              if (addMoreContactList.includes(listAll[j]._id) === false) {
                addMoreContactList.push(listAll[j]._id)
              }
            }
          }
        }

       

        this.setState({
          addMoreContactList: addMoreContactList,
          scheduleList: scheduleListD,
        })

        allChecked = listAll.every((item) => item.isChecked)
      }

      return { list, listAll, allChecked }
    })

    let data = await this.state.listAll.map((item) => {
      if (true === item.isChecked) {
        return item._id
      }
    })
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined
    })

    // ids.length > 0?this.setState({showDelete:true}):this.setState({showDelete:false})
  }

  onOpenResipentsModal = () => {
    this.setState({ openResipients: true })
  }

  onCloseResipentsModal = () => {
    this.setState({ openResipients: false })
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }
  onCloseUpdateModal = () => {
    this.setState({ updateOpen: false })
  }
  openUpdateModal = (data) => {
    this.setState({ updateOpen: true, singleData: data })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })

    if (event.target.name === 'group_id') {
      this.props.listContactRequest({
        page_no: this.state.page_no,
        group_id: event.target.value,
      })
      this.props.listAllContactRequest({
        page_no: this.state.page_no,
        group_id: event.target.value,
      })
      this.handleLoading()
    }
  }
  handlePageChange = (pageNumber) => {
    
    this.setState({ page_no: pageNumber })
    let requestbody =
      this.state.group_id !== ''
        ? { page_no: pageNumber, group_id: this.state.group_id }
        : { page_no: pageNumber }
    this.props.listContactRequest(requestbody)
    this.handleLoading()
  }

  handleLoading = () => {
    
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  deleteContact = (id) => { 
    this.props.deleteContactRequest({ page_no: this.state.page_no, id: id, permission: 'Delete Audience' })
    this.setState({ allChecked: false })
    this.handleLoading()
  }
  cancelSchedule = async () => {
    this.handleLoading()
    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item._id
      }
    })
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined
    }) 
    const { eventId, templateId } = this.state
    const requestbody = { ids: ids, eventId: eventId, templateId: templateId }

    cancelScheduleForSelected(requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
          this.getTemplateList(templateId)
          this.getTemplateList(templateId)
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  getTemplateList = (templateId) => {
    getSingleTemplateApi(templateId)
      .then((result) => {
        if (result.data.status === 200) {
          // createNotification('success', result.data.message);
          this.setState({
            templateData: result.data.template,
            emailDate: new Date(result.data.template.scheduleDate),
            scheduleList: result.data.scheduledIds,
            scheduleListWithoutEdit: result.data.scheduledIds,
          })
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  rescheduleForSelected = async (e) => {
    e.preventDefault()
    this.handleLoading()
    const { eventId, templateId, emailDate } = this.state

    updateSchedule(templateId, { scheduleDate: emailDate })
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
          this.setState({ openResipients: false })
        } else {
          createNotification('error', result.data.message)
        }
        this.handleLoading()
      })
      .catch(console.log)
  }

  deleteCheck = async () => {
    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item._id
      }
    })
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined
    }) 
    this.props.deleteManyContactRequest({
      ids: ids,
      page_no: this.state.page_no,
    })
    this.handleLoading()
  }
  sendEmail = async () => {
    let data = await this.state.listAll.map((item) => {
      if (true === item.isChecked) {
        return item._id
      }
    })
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined
    }) 
    this.props.eventEmailRequest({ ids: ids, eventId: this.state.eventId })
    this.handleLoading()
  }

  matchArray = () => {
    let newArray = []
    let list = this.state.list
    let listAll = this.state.listAll
    for (let item of list) {
      for (let each of listAll) {
        if (item.email === each.email && each.isChecked === true) {
          let record = this.state.scheduleList.filter(
            (data) => data === item._id,
          )
          if (record.length > 0) {
            each['isChecked'] = true
          }

          newArray.push(each)
        } else if (item.email === each.email && each.isChecked === false) {
          let record = this.state.scheduleList.filter(
            (data) => data === item._id,
          )
          if (record.length > 0) {
            item['isChecked'] = true
          }
          newArray.push(item)
        }
      }
    }
    return newArray
  }

  renderList = () => {
    let finalArray = this.matchArray() 
    if (finalArray.length > 0) {
      let dataCharArray = []
      return finalArray.map((data, index) => {
        let dataChar = data.firstName.charAt(0)
        dataCharArray.push(dataChar)
        return (
          <tr key={index}>
            <td className="tableUserInfo">
              <span className="tableAlphabet">
                {index !== 0
                  ? dataCharArray[index] !== dataCharArray[index - 1]
                    ? dataCharArray[index]
                    : null
                  : dataCharArray[index]}
              </span>
              <label className="checkBoxColl">
                <input
                  key={data._id}
                  type="checkbox"
                  name={data.email}
                  value={data.email}
                  checked={data.isChecked}
                  onChange={this.handleChangeCheck}
                />
                <span className="checkmark"></span>
              </label>
              {data.profilePic && data.profilePic !== '' ? (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + data.profilePic}
                  alt=""
                />
              ) : (
                <span className="listLetterContact">
                  {data.firstName.charAt(0) + data.lastName.charAt(0)}
                </span>
              )}

              <span className="eventName">
                {data.firstName + ' ' + data.lastName}
              </span>
            </td>
            <td>{data.company}</td>
            <td>{data.email}</td>
            <td className="userStatus">{data.groupName}</td>
            <td className="tableDotMore">
              <button className="tableDots">
                <span className="tableDotsExpo"></span>
                <span className="tableDotsExpo"></span>
                <span className="tableDotsExpo"></span>
              </button>
              <div className="contacteditPopup">
                <p>
                  {' '}
                  <span onClick={() => this.openUpdateModal(data)}>Edit</span>
                </p>
                <p>
                  <span
                    onClick={() => {
                      if (window.confirm('Delete the contact?')) {
                        this.deleteContact(data._id)
                      }
                    }}
                  >
                    Delete
                  </span>
                </p>
              </div>
            </td>
          </tr>
        )
      })
    }
  }
  handleDate = (date) => { 
    this.setState({ emailDate: date })
  }

  addMoreContact = () => {
    const { eventId, templateId, emailDate, addMoreContactList } = this.state
    const requestbody = {
      eventId,
      templateId,
      emailDate,
      ids: addMoreContactList,
    }
    AddMoreScheduleEmailAPI(requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  render() {
    const {
      title,
      pageTitle,
      open,
      loading,
      page_no,
      totalResults,
      groupOpen,
      importOpen,
      updateOpen,
      singleData,
      showDelete,
      eventId,
      openResipients,
      list,
      listAll,
      group_id,
      templateId,
      emailDate,
      initialDate,
    } = this.state
    let projectType = '/project-type/' + eventId
    if (this.props.location.pathname.includes('member')) {
      projectType = '/member/project-type/' + eventId
    }
   
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="editingPageWrapper">
          <div className="editingDashboard">
            {/* <PageToolBar
                showIcons={false}
                onSaveFn={()=>redirectTo(this.props.navigate,`review/${eventId}`,this.props.location.pathname)}
                saveBtnLabel='Continue'
                showPreviewOption={false}
                showActiveBtn={false}
                // backTo={backTo}
           />*/}

            <div className="reschedule_Page">
              <div class="recipientsMainsec">
                <div class="fullSreenHeader inputField">
                  <div class="headingRecipients">
                    <h2>Contacts</h2>
                  </div>
                  <div class="buttonsRecipient">
                    <div class="selectButton mr-3">
                      <select
                        name="group_id"
                        onChange={this.handleChange}
                        value={group_id}
                        class="largeOutlineThemeBttn desktopSelectGroup p-3"
                      >
                        <option value="">Select Group</option>
                        {this.props.groups.groups &&
                          this.props.groups.groups.map((data, index) => {
                            return (
                              <option value={data._id}>{data.groupName}</option>
                            )
                          })}
                      </select>
                    </div>
                    <button
                      onClick={this.onOpenModal}
                      class="largeBlueThemeBttn addRecipntss m-0 text-center"
                    >
                      {' '}
                      Add Recipients{' '}
                    </button>
                    <button
                      onClick={this.onOpenModal}
                      class="largeBlueThemeBttn addRecipntssIcon m-0 text-center"
                    >
                      {' '}
                      +{' '}
                    </button>
                  </div>
                </div>
                <div className="myEventTable contactsTable">
                  <div className="eventTableInner table-responsive contactTableInner">
                    <div class="MobileSelectgroup">
                      <select
                        name="group_id"
                        onChange={this.handleChange}
                        value={group_id}
                        class="largeOutlineThemeBttn p-3"
                      >
                        <option value="">Select Group</option>
                        {this.props.groups.groups &&
                          this.props.groups.groups.map((data, index) => {
                            return (
                              <option value={data._id}>{data.groupName}</option>
                            )
                          })}
                      </select>
                    </div>
                    <table className="table contactTableInner">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Company</th>
                          <th scope="col">Email</th>
                          <th scope="col">Group Name</th>
                          <th scope="col" className="tableSetting"></th>
                        </tr>
                      </thead>
                      <tbody className="tableBodyC">
                        <div className="checkedAllSection">
                          <label className="checkBoxColl">
                            <input
                              type="checkbox"
                              name="checkAll"
                              checked={this.state.allChecked}
                              onChange={this.handleChangeCheck}
                            />
                            <span className="checkmark"></span>
                            <span className="selectAllName">Select All</span>
                          </label>
                          {showDelete ? (
                            <div className="tableActionBothButton">
                              <button
                                onClick={() => {
                                  if (window.confirm('Are you sure?')) {
                                    this.cancelSchedule()
                                  }
                                }}
                              >
                                Cancel Schedule
                              </button>{' '}
                              &nbsp;
                              <button onClick={this.addMoreContact}>
                                Add More Contact
                              </button>
                              &nbsp;
                              <button onClick={this.onOpenResipentsModal}>
                                Reschedule
                              </button>
                            </div>
                          ) : null}
                        </div>
                        {this.props.contacts.contacts && this.renderList()}
                      </tbody>
                    </table>
                  </div>

                  {totalResults > 10 ? (
                    <div className="tablePagination">
                      <Pagination
                        activePage={page_no}
                        itemsCountPerPage={10}
                        totalItemsCount={totalResults}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        prevPageText="Prev"
                        // firstPageText="First"
                        // lastPageText="Last"
                        nextPageText="Next"
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <Modal
                open={openResipients}
                onClose={this.onCloseResipentsModal}
                center
              >
                <div>
                  <h2>Email Scheduler</h2>
                  <div className="rsvpEventfieldBox emailSchedulerModal">
                    <div className="formSection p-0">
                      <form onSubmit={this.rescheduleForSelected}>
                        <div class="field-group fullWidthInput inputField">
                          <label>Select datetime</label>
                          <DatePicker
                            selected={emailDate}
                            showTimeSelect
                            dateFormat="Pp"
                            onChange={this.handleDate}
                            minDate={initialDate}
                          />
                        </div>
                        <div className="editProfileBtn submitForm justify-content-center">
                          <Button type="submit" className="themeBlueButton">
                            Reschedule
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal open={open} onClose={this.onCloseModal} center>
                <ContactModal page_no={page_no} />
              </Modal>

              <Modal open={updateOpen} onClose={this.onCloseUpdateModal} center>
                <UpdateModal page_no={page_no} singleData={singleData} />
              </Modal>

              <NotificationContainer />
              {loading ? <Loader /> : null}
            </div>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes,
  contacts: state.Contacts,
  events: state.Events,
  groups: state.Groups,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      groupRequest: Actions.groupRequest,
      listContactRequest: Actions.listContactRequest,
      listAllContactRequest: Actions.listAllContactRequest,
      deleteContactRequest: Actions.deleteContactRequest,
      deleteManyContactRequest: Actions.deleteManyContactRequest,
      eventEmailRequest: Actions.eventEmailRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Reschedule)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions' 
import { NotificationContainer } from 'react-notifications'
import Sidebar from '../../../Components/Sidebar'
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material';

import Button from '@mui/material/Button';
import { theme } from '../../../theme/theme';
import BackArrow from '../../../assets/images/icons/backArrow.svg';
import { getProjectIdFromUrl } from '../../../helpers/common'
import { createNotification } from '../../../helpers'
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TamplateThum from '../../../assets/images/tamplate-thum.png';
import TamplateThum1 from '../../../assets/images/tamplate-thum1.png';
import TamplateThum2 from '../../../assets/images/tamplate-thum2.png';
import TamplateThum3 from '../../../assets/images/tamplate-thum3.png';
import TamplateThum4 from '../../../assets/images/tamplate-thum4.png';
import SearchIcon from '../../../assets/images/icons/search.svg';
import MenuItem from '@mui/material/MenuItem';
import Loader from '../../../Components/Loader/loader'
import { Modal } from 'react-responsive-modal';
import pagethum from '../../../assets/images/tamplate-thum.png';
import ReactHtmlParser from 'react-html-parser';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
const URL = process.env.REACT_APP_API_URL;
import withCustomRouter from '../../../Common/withCustomRouter'; 

 
class SelectCampaignTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventId: '',
            campaignName: '',
            campaignType: "Default",
            campaignTemplatesList: [],
            isAllTemplatesActive: true,
            isMyTemplatesActive: false,
            searchText: '',
            loading: false,
            openDeleteModal: false,
        }
    }

    componentDidMount() { 
        const { ei, cn } = this.props.params; 
        const eventId =  ei;
        const campaignName =  cn;
        this.setState({
            eventId,
            campaignName,
        }, () => this.listCampaignTemplates())
    }

    componentDidUpdate(prevProps) {
        if (this.props.campaigns.listCampaignTemplatesSuccess && !prevProps.campaigns.listCampaignTemplatesSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
            this.setState({
                campaignTemplatesList: this.props.campaigns.campaignTemplates
            })
            setTimeout(() => {
                if (this.state.loading) {
                    this.handleLoading()
                }
            }, 200)
        }

        if (this.props.campaigns.error && !prevProps.campaigns.error && this.props.campaigns.message !== prevProps.campaigns.message) {
            createNotification("error", this.props.campaigns.message)
            if (this.state.loading) {
                this.setState({
                    loading: false
                })
            }
        }
        if (this.props.campaigns.deleteCampaignSuccess && !prevProps.campaigns.deleteCampaignSuccess && this.props.campaigns.message !== prevProps.campaigns.message) {
            createNotification("success", this.props.campaigns.message)
            if (this.state.loading) {
                this.setState({
                    loading: false,
                })
            }
            this.setState({
                openDeleteModal: false
            })
            this.listCampaignTemplates()
        }
    }

    listCampaignTemplates = () => {
        const { campaignType, searchText, eventId } = this.state
        this.setState({
            loading: true
        }, () => {
            const requestBody = {
                campaignType,
                searchText,
                eventId,
            }
            this.props.listCampaignTemplates(requestBody)
        })
    }

    showMyTemplates = () => {
        this.setState({
            campaignType: "UserDefined",
            isMyTemplatesActive: true,
            isAllTemplatesActive: false,
        }, () => this.listCampaignTemplates())
    }

    showAllTemplates = () => {
        this.setState({
            campaignType: "Default",
            isMyTemplatesActive: false,
            isAllTemplatesActive: true,
        }, () => this.listCampaignTemplates())
    }

    useTemplate = (template) => {
        if (template !== "blank") {
            this.props.navigate({
                pathname: `/project-dashboard/campaign-template-edior/tid/${template._id}/cn/${this.state.campaignName}/eid/${this.state.eventId}`,
                state: { template: template, fromSelectTemplatePage: true }
            })
        } else {
            this.props.navigate({
                pathname: `/project-dashboard/campaign-template-edior/tid/blank/cn/${this.state.campaignName}/eid/${this.state.eventId}`,
                state: { template: template }
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSearchSubmit = (e) => {
        e.preventDefault();
        this.listCampaignTemplates()
    }

    deleteCampaign = () => {
        this.props.deleteCampaign({ id: this.state.deleteId })
        this.setState({loading: true})
    }

    handleLoading = () => {
        let loading = this.state.loading
        this.setState({
            loading: !loading,
        })
    }

    handleCloseDeleteModal = () => {
        this.setState({
            openDeleteModal: false
        })
    }

    handleOpenDeleteModal = (template) => {
        this.setState({
            openDeleteModal: true,
            deleteId: template._id,
        })
    }

    openPreview = (template) => {
        this.props.navigate(`/project-dashboard/preview-page/${eventId}`, {
            state: { template: template },
            search: `?pagepath=${template._id}&sentFrom=selectCampaignTemplates&campaignName=${campaignName}&templateEventId=${template.eventId}&campaignType=${template.campaignType}`,
        });
    }

    render() {
        const { eventId, loading, campaignTemplatesList, isMyTemplatesActive, isAllTemplatesActive, searchText } = this.state
        return (
            <ThemeProvider theme={theme}>
                <div className="dashboardMainPage">
                    <Sidebar />
                    <div className="dashboardContentArea">
                        <Box my={{ xs: 3, md: 4 }} mx={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 7.5 }} className="">
                            <Grid container alignItems={'center'} spacing={{ xs: 1, md: 2, lg: 3 }} mb={{ xs: 2, md: 3, lg: 5 }}>
                                <Grid ml={{ xs: 1, sm: 0, md: -1, lg: -2, xl: -4 }} >
                                    <Link to={`/project-dashboard/campaigns/create-campaign/${eventId}`}>
                                        <Box component={Button} className='back-button' variant="outlined">
                                            <img alt="" src={BackArrow} />
                                        </Box>
                                    </Link>
                                </Grid>
                                <Grid >
                                    <Box component={'span'} className='subtitle'>
                                    {this.props.users.languageStateForRedux?.actions?.managePages?.selectTemp}
                                        {/* Select Template */}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2.3} className="top-filter-area">
                                <Grid ml={{ xs: 1, sm: 0, md: 4, lg: 5, xl: 7 }}>
                                    <Grid container spacing={1} >
                                        <Grid >
                                            <Box
                                                component={Button}
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: theme.palette.background.light,
                                                    color: theme.palette.common.black,
                                                    textTransform: 'capitalize',
                                                    fontWeight: '400',

                                                    '&:hover, &.active': {
                                                        backgroundColor: theme.palette.primary.dark,
                                                        color: theme.palette.primary.contrastText,
                                                    }
                                                }}
                                                className={isAllTemplatesActive ? 'active' : ''}
                                                size='small'
                                                aria-haspopup="true"
                                                onClick={this.showAllTemplates}
                                            >
                                                {(this.props.users.languageStateForRedux?.buttons?.managePages?.allTemp) + ` (${this.props.campaigns.filterTemplatesByType?.Default ? this.props.campaigns.filterTemplatesByType.Default : 0})`}
                                            </Box>
                                        </Grid>
                                        <Grid >
                                            <Box
                                                component={Button}
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: theme.palette.background.light,
                                                    color: theme.palette.common.black,
                                                    textTransform: 'capitalize !important',
                                                    fontWeight: '400',

                                                    '&:hover, &.active': {
                                                        backgroundColor: theme.palette.primary.dark,
                                                        color: theme.palette.primary.contrastText,
                                                    }
                                                }}
                                                className={isMyTemplatesActive ? 'active' : ''}
                                                size='small'
                                                aria-haspopup="true"
                                                onClick={this.showMyTemplates}
                                            >
                                                {(this.props.users.languageStateForRedux?.buttons?.managePages?.myTemp) + 
                                                ` (${this.props.campaigns.filterTemplatesByType?.UserDefined ? this.props.campaigns.filterTemplatesByType.UserDefined : 0})`}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid >
                                    <Grid container spacing={1.25} className="team-dash-right">
                                        <Grid className="">
                                            <form onSubmit={this.handleSearchSubmit}>
                                                <TextField
                                                    name="searchText"
                                                    variant='standard'
                                                    placeholder={this.props.users.languageStateForRedux?.common?.search}
                                                    className="search"
                                                    size="small"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <img alt="" src={SearchIcon} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={searchText}
                                                    onChange={this.handleChange}
                                                />
                                            </form>
                                        </Grid>
                                        <Grid >
                                            <FormControl variant="">
                                                {/* <Select
                                                    className="sort"
                                                    labelId="sort-simple-select-outlined-label"
                                                    id="sort-simple-select-outlined"
                                                    defaultValue="Popular First"
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="Popular First" selected>Popular First</MenuItem>
                                                    <MenuItem value="Most used">Newest First</MenuItem>
                                                </Select> */}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid size={{xs: "grow",}} ml={{ xs: 0, sm: 0, md: 4, lg: 5, xl: 7 }} className='tamplate-wrapper'>
                                    <Grid container justifyContent={{ xs: 'center', sm: 'start' }} columnSpacing={{ xs: 2, md: 3, lg: 3, xl: 4.75 }} rowSpacing={{ xs: 2, md: 3, lg: 3, xl: 3.75 }} >
                                        {!isMyTemplatesActive &&
                                            <Grid onClick={() => this.useTemplate('blank')}>
                                                <div className='tamplate' >
                                                    <div className='tamplate-thum' style={{ background: `url(${TamplateThum}) center no-repeat` }}>
                                                    </div>

                                                    <div className='tamplate-name'>
                                                        <p>{this.props.users.languageStateForRedux?.actions?.managePages?.blank}</p>
                                                    </div>
                                                </div>
                                            </Grid>
                                        }

                                        {Array.isArray(campaignTemplatesList) && campaignTemplatesList.length > 0 ? campaignTemplatesList.map(template => {
                                            return (
                                                <Grid >
                                                    <div className='tamplate'>
                                                        <div className='tamplate-thum' style={{ background: `url(${TamplateThum}) center no-repeat`, backgroundSize: 'cover', width: '100%', }}>
                                                            {template.campaignType === 'UserDefined' ? ReactHtmlParser(template.html) :
                                                                template.pageThumb !== "" && template.pageThumb !== undefined && <img style={{ width: '100%', height: '100%', }} alt='thumbnail' src={`${process.env.REACT_APP_IMAGE_URL}/thumbnails/${template.pageThumb}`} />}
                                                        </div>
                                                        <Grid container className='tamplate-action' direction="column" alignItems="center" justifyContent={'center'}>
                                                            {isMyTemplatesActive && <Box className='delete' onClick={() => this.handleOpenDeleteModal(template)}>{this.props.users.languageStateForRedux?.buttons?.teams?.remove}</Box>}
                                                            <Stack spacing={1} direction="column" fullWidth>
                                                                <Button fullWidth className='white-btn' variant='contained' onClick={() => this.openPreview(template)}>{this.props.users.languageStateForRedux?.unlayer?.preview}</Button>
                                                                <Button fullWidth variant='contained' color='primary' onClick={() => this.useTemplate(template)}>{this.props.users.languageStateForRedux?.common?.useTemplate}</Button>
                                                            </Stack>
                                                        </Grid>
                                                        <div className='tamplate-name'>
                                                            <p>{template.campaignName}</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        })

                                            : this.state.campaignType === 'UserDefined' ?

                                                <Grid container >
                                                    <Grid size={{xs: "grow",}}>
                                                        <h5 style={{ textAlign: 'center', marginTop: '100px' }}>
                                                            {' '}
                                                            There are no records to display
                                                        </h5>
                                                    </Grid>
                                                </Grid>

                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                </div>
                <Modal
                    open={this.state.openDeleteModal} onClose={this.handleCloseDeleteModal}
                    classNames={{
                        overlay: '',
                        modal: 'Invite-User-Modal',
                    }}
                    center
                >
                    <Box component={Grid} container className="" px={1.5} >
                        <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                            <Box component="p" mb={1} className="subtitle">
                                {' '}
                                {this.props.users.languageStateForRedux?.common?.confirmDelete}!
                            </Box>
                            <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
                        </Box>
                        <Grid size={{xs: 12,}} >
                            <Grid container justifyContent="end">
                                <Stack spacing={1} direction="row" fullWidth>
                                    <Button variant="contained" color="secondary" disableElevation onClick={this.deleteCampaign}>{this.props.users.languageStateForRedux?.common?.delete}</Button>
                                    <Button variant="outlined" disableElevation onClick={this.handleCloseDeleteModal}>{this.props.users.languageStateForRedux?.common?.cancel}</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {loading ? <Loader /> : null}
            </ThemeProvider >
        )
    }
}

const mapStateToProps = (state) => ({
    campaigns: state.Campaigns,
    events: state.Events,
    users: state.Users,
})

const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            listCampaignTemplates: Actions.listCampaignTemplatesRequest,
            deleteCampaign: Actions.deleteCampaignRequest,
        },
        dispatch,
    )

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(SelectCampaignTemplate))
//@ts-nocheck
import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ReactQuill from 'react-quill';
import DocIcon from '../../../src/assets/images/icons/Doc-icon.svg';
import AddFile from '../../../src/assets/images/icons/attach_file_add.svg';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useState, ChangeEvent } from 'react';
import { isValidEmail } from 'helpers/common';
import { xpAccountIntegrationDownloadAllAttachment, xpAccountIntegrationDownloadAttachment, xpAccountIntegrationMailSaveAsDraft, xpAccountIntegrationMailSend, xpAccountIntegrationMailSendDraft, xpAccountIntegrationMailUpdateDraft } from 'ReduxStore/API';
import Loader from 'Components/Loader/loader';
import { createNotification } from 'helpers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

type ComposeSubComponent = {
    gmailId: string;
    recipients?: string;
    subject?: string;
    editorText?: string;
    cc: boolean;
    bcc: boolean;
    modules: Object;
    handleOpenCC: () => void;
    handleCloseCC: () => void;
    handleOpenBCC: () => void;
    handleCloseBCC: () => void;
    setRecipients: () => void;
    setSubject: () => void;
    setPayload: () => void;
    setEditorText: () => void;
    viewCompose?: boolean;
    handleBackFromCompose?: () => void;
    payload: string;
    messageIdForDraft: string;
    draftId: string;
};
type Errors = {
    emailBody?: string;
    subject?: string;
    recipients?: string;
    cc?: string;
    bcc?: string;
    general?: string;
    fieldName?: string;
};

const Compose = ({ gmailId, recipients, subject, editorText, cc, bcc, modules, handleOpenCC, handleCloseCC, handleOpenBCC, handleCloseBCC, setRecipients, setSubject, setEditorText, viewCompose, handleBackFromCompose, payload, setPayload, messageIdForDraft, draftId }: ComposeSubComponent) => {
    // const [recipients, setRecipients] = useState('');
    // const [subject, setSubject] = useState('');
    const [cC, setCc] = useState('');
    const [bcC, setBcc] = useState('');
    const [removedAttachments, setRemovedAttachments] = useState([]);
    // const [editorText, setEditorText] = useState('');
    const [fileNames, setFileNames] = useState<File[]>([]);
    const [errors, setErrors] = useState<Errors>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    console.log("===============payload", payload);
    console.log("===============subject", subject);

    const handleMailStatus = async (status: string) => {
        if (isValid(status)) {
            const allRecipients = recipients.split(",").map((mail) => mail.trim());
            const allCcMails = cC.split(",").map((mail) => mail.trim());
            const allBCcMails = bcC.split(",").map((mail) => mail.trim());
            const formattedRecipients = allRecipients.join(',');
            const formattedCcMails = allCcMails.join(',');
            const formattedBCcMails = allBCcMails.join(',');
            const formData = new FormData();
            formData.append('from', gmailId);
            formData.append('to', formattedRecipients);
            formData.append('cC', formattedCcMails);
            formData.append('bcC', formattedBCcMails);
            formData.append('subject', subject);
            formData.append('htmlContent', editorText);

            fileNames.forEach((file: File) => {
                console.log("file in loop", file);
                formData.append('attachments', file);
            });

            if (status === 'send') {
                setIsLoading(true);
                let response = {};
                if (messageIdForDraft) {
                    formData.append('gmailId', gmailId);
                    formData.append('draftId', draftId);
                    removedAttachments.forEach(el => {
                        formData.append('removedAttachments', el);
                    });
                    response = await xpAccountIntegrationMailSendDraft(formData);
                } else {
                    response = await xpAccountIntegrationMailSend(formData);
                }
                setIsLoading(false);
                if (response?.data?.status == 200) {
                    createNotification('success', response?.data?.message);
                    setRecipients('');
                    setSubject('');
                    setPayload({});
                    setCc('');
                    setBcc('');
                    setEditorText('');
                    setFileNames([]);
                }

            } else {
                setIsLoading(true);
                console.log("===========messageIdForDraft", messageIdForDraft);
                console.log("===========draftId", draftId);
                let response = {};
                if (messageIdForDraft) {
                    formData.append('messageId', messageIdForDraft);
                    formData.append('draftId', draftId);
                    formData.append('gmailId', gmailId);
                    removedAttachments.forEach(el => {
                        formData.append('removedAttachments', el);
                    });
                    response = await xpAccountIntegrationMailUpdateDraft(formData);
                } else {
                    response = await xpAccountIntegrationMailSaveAsDraft(formData);
                }
                setIsLoading(false);
                if (response?.data?.status == 200) {
                    createNotification('success', response?.data?.message);
                    setRecipients('');
                    setSubject('');
                    setPayload({});
                    setCc('');
                    setBcc('');
                    setEditorText('');
                    setFileNames([]);
                }
            }
            setIsLoading(false);
        }
    };


    const handleRecipientsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRecipients(event.target.value);
    };
    const handleSubChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSubject(event.target.value);
    };
    const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCc(event.target.value);
    };
    const handleBccChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBcc(event.target.value);
    };
    const handleEditorText = (val: string) => {
        setEditorText(val);
    };



    // Custom handler to upload attachments
    const handleAttachment = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('multiple', ''); // Enable multiple file selection
        input.click();

        input.onchange = () => {
            const files = Array.from(input.files || []); // Convert the FileList to an array
            if (files.length > 0) {
                console.log('Selected files:', files);
                setFileNames([...fileNames, ...files]);
            }
        };
    };

    const handleDelete = (fileToDelete: File) => {
        setFileNames((prevFileNames) =>
            prevFileNames.filter((file) => file.name !== fileToDelete.name)
        );
    };

    const isValid = (status: string) => {
        const error: Errors = {};
        let isValid = true;

        const validateEmailField = (field: string | undefined, fieldName: string): boolean => {
            if (!field) {
                return false; // Immediately return false if field is undefined or empty
            }

            const allMails = field.split(",").map((mail) => mail.trim()); // Split and trim all emails
            const invalidMail = allMails.find((mail) => mail.length > 0 && !isValidEmail(mail)); // Find the first invalid email

            if (invalidMail) {
                error.fieldName = `The field is not valid. Please enter a valid email address for ${fieldName.toUpperCase()}.`;
                return false;
            }

            return true; // All emails are valid
        };

        // Condition to check if editorText is empty or only contains placeholder HTML
        const isBodyEmpty = !editorText || editorText === '<p><br></p>';

        // Validate mandatory fields based on status
        if (status === "send") {
            if (!validateEmailField(recipients, 'recipients')) {
                error.recipients = "Please enter the recipients email again";
                isValid = false;
            }
            if (!subject || subject.trim().length === 0) {
                error.subject = "Please enter the subject again";
                isValid = false;
            }
            if (isBodyEmpty) {
                error.emailBody = "Please enter the email body agin";
                isValid = false;
            }

        } else if (status === "draft") {

            // Check if at least one field is valid in "draft"
            const hasValidField =
                validateEmailField(recipients, 'recipients') ||
                (subject && subject.trim().length > 0) ||
                validateEmailField(cC, 'cc') ||
                validateEmailField(bcC, 'bcc') ||
                !isBodyEmpty;

            if (!hasValidField) {
                error.general = "For a draft, at least one of recipients, subject, cc, bcc, or email body must be filled in and valid.";
                isValid = false;
            }

        }
        //Additional validation for cc and bcc fields, if provided
        if (recipients && !validateEmailField(recipients, 'recipients')) {
            error.recipients = "Recepient is not valid. Please enter a valid email address.";
            error.general = "";
        }
        //Additional validation for cc and bcc fields, if provided
        if (cC && !validateEmailField(cC, 'cc')) {
            error.cc = "CC field is not valid. Please enter a valid email address.";
            error.general = "";
        }
        if (bcC && !validateEmailField(bcC, 'bcc')) {
            error.bcc = "BCC field is not valid. Please enter a valid email address.";
            error.general = "";
        }

        setErrors(error);
        return isValid;
    };

    const handleRemovedAttachments = (attachmentId) => {
        setRemovedAttachments(prev => [...prev, attachmentId]);
    };

    console.log("===========removedAttachments", removedAttachments);

    const renderAttachments = () => {
        if (!payload) return null;
        const { parts } = payload;

        if (
            payload.mimeType === 'multipart/mixed' &&
            Array.isArray(parts) &&
            parts.length > 1
        ) {
            return parts.map((part, i) => {
                if (i > 0 && !removedAttachments.includes(part.body.attachmentId)) {
                    return (
                        <Grid item>
                            <Box className="attachment-file">
                                <Box display={'flex'} alignItems={'center'}>
                                    <Box className="file-icon">
                                        <img src={DocIcon} alt="file-icon" />
                                    </Box>
                                    <Box>
                                        <Box className="file-name">{part.filename}</Box>
                                        <Box className="file-size">{part.body.size}</Box>
                                    </Box>
                                </Box>
                                <FileDownloadIcon
                                    onClick={() => downloadAttachment(gmailId, part.filename, part.body.attachmentId)}
                                    style={{ fontSize: '18px' }}
                                    className="options-button"
                                />
                                <Box ml={1} className="options">
                                    <ClearIcon onClick={() => handleRemovedAttachments(part.body.attachmentId)} style={{ fontSize: '18px' }} />
                                </Box>
                            </Box>
                        </Grid>
                    );
                } else {
                    return null;
                }
            });
        } else {
            return null;
        }
    };

    const downloadAttachment = async (messageId, filename, attachmentId) => {
        try {
            const data = {
                messageId,
                gmailId,
                filename,
                attachmentId
            };
            await xpAccountIntegrationDownloadAttachment(data);
        } catch (error) {
            createNotification("error", error.message);
        }
    };
    const downloadAllAttachments = async () => {
        try {
            const data = {
                messageId,
                gmailId,
                attachmentIds
            };
            await xpAccountIntegrationDownloadAllAttachment(data);
        } catch (error) {
            createNotification("error", error.message);
        }
    };

    return (
        <>
            <Box>
                <Box pb={0} mt={-5} mb={2} sx={{ borderBottom: '0px solid #EDECF5' }} className="top-filter-area">
                    <Grid container width={'100%'} spacing={1}>
                        <Grid item size={'grow'}>
                            <Stack direction={'row'} alignItems={'center'}>
                                {viewCompose && (<IconButton size="medium" title="Back" onClick={handleBackFromCompose} >
                                    <KeyboardBackspaceOutlinedIcon />
                                </IconButton>)}
                                <Box className="subtitle">
                                    New Message (From: {gmailId})
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container width={'100%'} spacing={1.25} className="team-dash-right">
                                <Grid item>
                                    <Button onClick={() => handleMailStatus("draft")} variant="outlined" color="primary" size="small" startIcon={<DraftsIcon />}>Draft</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleMailStatus("send")} variant="contained" color="primary" size="small" endIcon={<SendIcon />}>Send</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="email-composer">
                    <Stack spacing={1} sx={{ height: "100%" }}>
                        <Grid container width={'100%'} columnGap={1}>
                            <Grid item size={'grow'}>
                                <TextField
                                    name="Recipients"
                                    placeholder="Recipients"
                                    variant='standard'
                                    fullWidth
                                    size="small"
                                    value={recipients}
                                    onChange={handleRecipientsChange}
                                />
                                <span className="error_message required">{errors.recipients}</span>
                            </Grid>
                            <Grid item>{!cc && <Button onClick={handleOpenCC} variant="text" color="primary" size="small">CC</Button>}</Grid>
                            <Grid item>{!bcc && <Button onClick={handleOpenBCC} variant="text" color="primary" size="small">BCC</Button>}</Grid>
                        </Grid>

                        {cc && (
                            <Grid container columnGap={1}>
                                <Grid item size={'grow'}>
                                    <TextField
                                        name="CC"
                                        placeholder="CC"
                                        variant='standard'
                                        fullWidth
                                        size="small"
                                        value={cC}
                                        onChange={handleCcChange}
                                    />
                                    <span className="error_message required">{errors.cc}</span>
                                </Grid>
                                <Grid item><IconButton onClick={handleCloseCC} color="primary" size="small"><CloseIcon /></IconButton></Grid>
                            </Grid>
                        )}


                        {bcc && (
                            <Grid container columnGap={1}>
                                <Grid item size={'grow'}>
                                    <TextField
                                        name="BCC"
                                        placeholder="BCC"
                                        variant='standard'
                                        fullWidth
                                        size="small"
                                        value={bcC}
                                        onChange={handleBccChange}
                                    />
                                    <span className="error_message required">{errors.bcc}</span>
                                </Grid>
                                <Grid item><IconButton onClick={handleCloseBCC} color="primary" size="small"><CloseIcon /></IconButton></Grid>
                            </Grid>
                        )}

                        <TextField
                            name="Subject"
                            placeholder="Subject"
                            fullWidth
                            variant='standard'
                            size="small"
                            value={subject}
                            onChange={handleSubChange}
                        />
                        <span className="error_message required">{errors.subject}</span>
                        <span className="error_message required">{errors.general}</span>
                        <ReactQuill
                            theme="snow"
                            value={editorText}
                            onChange={handleEditorText}
                            modules={modules}
                        />
                        <span className="error_message required">{errors.emailBody}</span>

                        <Grid
                            container
                            spacing={1}
                            className="attachments"
                        >
                            {fileNames?.map((file) => {
                                const imageSizeInBytes = file?.size;
                                let convertedFileSizeKbMb;
                                if (imageSizeInBytes > 1024 * 1024) {
                                    // Convert to MB if file size is larger than 1024 KB
                                    convertedFileSizeKbMb = `${(imageSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
                                } else {
                                    convertedFileSizeKbMb = `${(imageSizeInBytes / 1024).toFixed(2)} KB`;
                                }
                                return (
                                    <Grid item>
                                        <Box className="attachment-file">
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Box className="file-icon">
                                                    <img src={DocIcon} alt="file-icon" />
                                                </Box>
                                                <Box>
                                                    <Box className="file-name">{file.name}</Box>
                                                    <Box className="file-size">Size: {convertedFileSizeKbMb}</Box>
                                                </Box>
                                            </Box>
                                            <Box ml={1} className="options">
                                                <ClearIcon onClick={() => handleDelete(file)} style={{ fontSize: '18px' }} />

                                            </Box>
                                        </Box>
                                    </Grid>
                                );
                            })}
                            <Grid item>
                                <Box className="attachment-file upload" onClick={handleAttachment}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box className="file-icon" p={1}>
                                            <img src={AddFile} alt="add-file" />
                                        </Box>
                                        <Box>
                                            <Box className="file-name secondary-text">
                                                Attach file
                                            </Box>
                                            {/* <Box className="file-size">10 Mb Max.</Box> */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Stack>
                </Box>
            </Box>
            <Stack spacing={1} direction={'row'} mt={2} className="attachments">
                {renderAttachments()}
            </Stack>
            {isLoading && <Loader />}
        </>
    );
};

export default Compose;
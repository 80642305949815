import React, { Component } from 'react' 
import { Helmet } from 'react-helmet'
import { flatten, unflatten } from 'flat'
import { get } from 'lodash'
import { NotificationContainer } from 'react-notifications'
import { connect } from 'react-redux'
import { getEventAPI, updateEventPageDesign } from 'ReduxStore/API'
import { fileReader, dataURItoBlob } from '../../helpers/common'
import * as qs from 'qs'

/*custom components */
import PageToolBar from '../../Components/PageToolBar'
// import SignInForm from "../PageDesign/pageSections/SignInForm";
import SignInForm from '../PageDesign/pageSections/SignInForm'
import EditingSection from '../PageDesign/editingSection'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
/*default ui configuration*/
import defaultUIConfig from '../PageDesign/pageSections/defaultPageSectionValues'
import editorLayoutConfig from '../PageDesign/editingSection/config'
import '../../sass/main.scss'

import { createNotification } from '../../helpers'
import Loader from '../../Components/Loader/loader'
import { getUnLayerConfig } from './config'

class PageDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      formValues: {},
      uiConfig: {
        ...defaultUIConfig,
      },
      activeLayout: 'signInForm',
      activeMagazineCarouselCard: 0,
      changedValues: {},
      activeDevice: 'tablet',
      selectedView: 'preview_as_guest',
      showLoader: true,
      json: {},
      html: '',
    }
  }

  componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_URL
    const eventId = get(this.props, 'match.params.eventId')
    if (eventId) {
      this.props.eventRequest(eventId)
    } else {
      createNotification('error', 'Invalid event id.')
    }
    window.scrollTo(0, 0)
    const view = this.getSearchView()
    if (view) {
      this.setState({ view })
      this.updateActiveDevice(view)
    }

    window.unlayer.init(getUnLayerConfig(apiUrl, eventId))
    const setState = this.setState.bind(this)
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design
        const html = data.html
        setState(() => ({ json, html }))
      })
    })
  }

  getSearchView = () => {
    const search = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    })
    return search &&
      (search.view === 'desktop' ||
        search.view === 'tablet' ||
        search.view === 'mobile')
      ? search.view
      : false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newUiConfig = get(this.props, 'events.event.pageDesign')
    const oldUiConfig = get(prevProps, 'events.event.pageDesign')
    if (newUiConfig && newUiConfig !== oldUiConfig) {
      /*const flattenOldUiConfig = flatten(get(prevState, 'uiConfig', {}));
            const flattenNewUiConfig = flatten(newUiConfig);
            for(let key in flattenNewUiConfig){
                if(key.startsWith('magazineCarousel') && flattenNewUiConfig[key] === null){
                    delete flattenNewUiConfig[key];
                }
            }
            const uiConfig = unflatten({
                ...flattenOldUiConfig,
                ...flattenNewUiConfig
            });*/
      const { json, html } = newUiConfig.login || {}
      this.setState({ json, html, showLoader: false })
      window.unlayer.loadDesign(json)
    }
  }

  handleLayoutChange = async (fieldName, value) => {
    let files
    if (
      fieldName === 'backgroundImage' ||
      fieldName === 'logo' ||
      fieldName === 'img'
    ) {
      const dataUrl = await fileReader(value)
      value = dataUrl
    }

    const {
      uiConfig,
      activeLayout,
      activeMagazineCarouselCard,
      changedValues,
    } = this.state
    let currentActiveForm = uiConfig[activeLayout]
    if (activeLayout === 'magazineCarousel') {
      currentActiveForm.forEach((item, index) => {
        if (index === activeMagazineCarouselCard) {
          item[fieldName] = value
          let key = `${activeLayout}.${activeMagazineCarouselCard}.${fieldName}`
          changedValues[key] = value
        }
      })
      this.setState({
        uiConfig: {
          ...uiConfig,
          [activeLayout]: currentActiveForm,
        },
      })
    } else {
      currentActiveForm[fieldName] = value
      let key = `${activeLayout}.${fieldName}`
      changedValues[key] = value
      if (fieldName === 'backgroundColor') {
        currentActiveForm['color'] = value
      }
      if (files) {
        currentActiveForm = {
          ...currentActiveForm,
          ...files,
        }
      }

      this.setState({
        uiConfig: {
          ...uiConfig,
          [activeLayout]: {
            ...currentActiveForm,
          },
        },
      })
    }
  }

  setActiveLayout = (activeLayout) => {
    this.setState({ activeLayout })
  }

  setActiveMagazineCarouselCard = (activeMagazineCarouselCard) => {
    this.setState({
      activeMagazineCarouselCard,
    })
  }

  saveLayout = () => {
    const { match: { params = {} } = {} } = this.props
    /*const {changedValues} = this.state;
        const formData = new FormData();
        for (const key in changedValues) {
            if (
                key.includes('backgroundImage') ||
                key.includes('img') ||
                key.includes('logo')
            ) {
                formData.append(key, dataURItoBlob(changedValues[key]));
            } else {
                formData.append(key, changedValues[key]);
            }
        }*/

    const { json, html } = this.state
    const formData = { login: { json, html } }
    updateEventPageDesign(params.eventId, formData)
      .then((result) => {
        if (result.data.success) {
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  onPreviewClick = (e) => {
    const view = e.target.value
    if (view === 'desktop' || view === 'tablet' || view === 'mobile') {
      const { origin, pathname } = window.location
      window.open(`${origin}${pathname}?view=${view}`)
    }
  }

  updateActiveDevice = (activeDevice) => {
    this.setState({ activeDevice })
  }

  render() {
    const {
      activeLayout,
      uiConfig,
      activeMagazineCarouselCard,
      activeDevice,
      selectedView,
      view,
      showLoader,
    } = this.state
    const signInFormProps = uiConfig['signInForm']
    const activeDeviceViewClass =
      activeDevice === 'desktop'
        ? 'pageDesktopView'
        : activeDevice === 'tablet'
        ? 'pageTabletView'
        : 'pageMobileView'

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            {!view && (
              <PageToolBar
                showIcons={false}
                onSaveFn={this.saveLayout}
                updateActiveDevice={this.updateActiveDevice}
                onPreviewClick={this.onPreviewClick}
                selectedView={selectedView}
                showPreviewOption={false}
              />
            )}

            {/*<div className={`editingDashboardContent ${view ? activeDeviceViewClass : ''}`}>
                        <div className={'registorEditiorSection pagedesignVhScreen'}
                        >
                            <SignInForm {...signInFormProps} setActiveLayout={this.setActiveLayout} activeLayout={activeLayout} view={view} />
                        </div>
                        {!view && <EditingSection
                            editorLayoutConfig={editorLayoutConfig}
                            activeLayout={activeLayout}
                            handleLayoutChange={this.handleLayoutChange}
                            uiConfig={{
                                ...uiConfig,
                                magazineCarousel: uiConfig['magazineCarousel'][activeMagazineCarouselCard]
                            }}
                        />}


                    </div>*/}

            <div id="pageDesignEditor" style={{ height: '100vh' }}></div>
          </div>
        }

        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(PageDesign)


import React, { Component } from 'react'
import { get } from 'lodash'
import DashboardLayout from '../../Layouts/DashboardLayout'
import AddAgenda from './AddAgendaModal'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { confirmAlert } from 'react-confirm-alert'
import {
  EventsAllAPI,
  getEventAgenda,
  createAgenda,
  updateEventAgendaById,
} from 'ReduxStore/API'
import { createNotification } from '../../helpers'
import '../../sass/main.scss'
import { NotificationContainer } from 'react-notifications'
import moment from 'moment'
import './index.scss'

class AgendaPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      agendas: [],
      selectedEvent: '',
      selectedAgenda: '',
      open: false,
    }
  }

  componentDidMount() {
    EventsAllAPI()
      .then((result) => {
        const events = get(result, 'data.events', [])
        const selectedEvent = events[0]
        this.setState({ events, selectedEvent: selectedEvent._id }, () => {
          if (selectedEvent) {
            this.getAgenda()
          }
        })
      })
      .catch(console.log)
  }

  getAgenda = () => {
    const { selectedEvent } = this.state
    getEventAgenda({ eventId: selectedEvent })
      .then((result) => {
        const agendas = get(result, 'data.agenda', [])
        this.setState({ agendas })
      })
      .catch(console.log)
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false, selectedAgenda: '' })
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.getAgenda)
  }

  handleEdit = (selectedAgenda) => {
    this.setState({ selectedAgenda }, this.onOpenModal)
  }

  handleSubmit = (data = {}) => {
    const { selectedAgenda, selectedEvent } = this.state
    const { title, description, scheduled_at, time } = data
    if (selectedAgenda) {
      updateEventAgendaById(selectedAgenda._id, {
        title,
        description,
        scheduled_at,
        time,
      })
        .then((result) => {
          createNotification('success', 'Event Agenda updated successfully')
          this.getAgenda()
          this.onCloseModal()
        })
        .catch(console.log)
    } else {
      createAgenda({
        title,
        description,
        scheduled_at,
        time,
        eventId: selectedEvent,
      })
        .then((result) => {
          createNotification('success', 'Event Agenda created successfully')
          this.getAgenda()
          this.onCloseModal()
        })
        .catch(console.log)
    }
  }

  deleteAgenda = (agendaId) => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure want to delete the Agenda ?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Continue',
          onClick: () => {
            updateEventAgendaById(agendaId, { isDeleted: true })
              .then((result) => {
                createNotification(
                  'success',
                  'Event Agenda deleted successfully',
                )
                this.getAgenda()
                this.onCloseModal()
              })
              .catch(console.log)
          },
        },
      ],
    })
  }

  render() {
    const {
      open,
      events,
      agendas,
      selectedEvent,
      selectedAgenda = {},
    } = this.state
    return (
      <DashboardLayout title="Agenda" pageTitle="Agenda">
        <div className="dashboardMiddleArea">
          <div className="filterControler agendaFilter">
            <div className="formSection">
              <form>
                <div className="field-group">
                  <label for="select_agenda_label"> Choose Agenda </label>
                  <select
                    name="selectedEvent"
                    value={selectedEvent}
                    className="outlineButton"
                    onChange={this.handleOnChange}
                  >
                    <option value="">Select Event</option>
                    {events.map((event) => (
                      <option key={event._id} value={event._id}>
                        {event.eventName}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <Button
              type="button"
              className="themeBlueButton"
              onClick={this.onOpenModal}
            >
              Add Agenda
            </Button>
          </div>
          <div className="eventListBoxes myEventTable">
            <div className="eventTableInner eventAgendaTable table-responsive eventOnlyList">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" colSpan="2">
                      Title
                    </th>
                    <th scope="col">Description</th>
                    <th scope="col">Scheduled</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {agendas.map((item) => {
                    const date = moment(item.scheduled_at).format('MM/DD/YYYY')
                    const dateTime = date + ' ' + item.time
                    return (
                      <tr>
                        <td>{item.title}</td>
                        <td className="agenda-description">
                          {item.description}
                        </td>
                        <td>
                          {moment(dateTime).format('dddd, MMM, Do YYYY, LT')}{' '}
                        </td>
                        <td class="tableDotMore ">
                          <button class="tableDots">
                            <span class="tableDotsExpo"></span>
                            <span class="tableDotsExpo"></span>
                            <span class="tableDotsExpo"></span>
                          </button>
                          <div class="contacteditPopup">
                            <p>
                              <span onClick={() => this.handleEdit(item)}>
                                Edit
                              </span>
                            </p>
                            <p>
                              <span onClick={() => this.deleteAgenda(item._id)} variant="contained" color="secondary" >
                                Delete
                              </span>
                            </p>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Modal open={open} onClose={this.onCloseModal} center>
            <AddAgenda
              selectedAgenda={selectedAgenda}
              handleSubmit={this.handleSubmit}
            />
          </Modal>
          <NotificationContainer />
        </div>
      </DashboardLayout>
    )
  }
}

export default AgendaPage //connect(mapStateToProps, mapActionsToProps)(AgendaPage);

import { combineReducers } from 'redux';

import Gamification from './gamification.reducer';
import Attendees from './attendees.reducer';
import ProjectNotifications from './projectNotifications.reducer';
import Login from './login.reducer';
import Register from './register.reducer';
import Profile from './profile.reducer';
import Loader from './loader.reducer';
import Events from './events.reducer';
import Contacts from './contacts.reducer';
import Groups from './groups.reducer';
import Members from './members.reducer';
import Notifications from './notifications.reducer';
import Proutes from './proutes.reducer';
import AutoLogin from './autologin.reducer';
import Roles from './roles.reducer';
import Permission from './permission.reducer';
import Projects from './projects.reducer';
import Agenda from './agenda.reducer';
import Users from './users.reducer';
import ManagePage from '../../Pages/ProjectDashboard/ManagePage/reducer/managePageReducer';
import Campaigns from './campaigns.reducer';
import Report from './report.reducer';
import Integration from './integration.reducer';
import AccessCode from './accessCode.reducer';
import ServicePlan from './servicePlan.reducer';
import AppsIntegration from './integrations/apps.Reducer';
import AppConfigModal from './integrations/appsConfigModal.Reducer';
import Meeting from './meeting.reducer';
import VanityDomain from './vanityDomainReducer';
import GoogleWorkspace from './googleWorkspace.reducer';
import GoogleOrgUnit from './integrations/googleOrgUnit.Reducer';
import GoogleSignIn from './googleSignIn.reducer';
import AIChatbot from './aiChatbot.reducer';
import Applications from './integrations/applications/application.reducer';
import OpportunityAccounts from './opportunity/accounts.reducer'
import OpportunityCustomFields from './opportunity/opportunityCustomFields.reducer'
import OpportunityStates from './opportunity/opportunityStates.reducer'
import OpportunityCommon from './opportunity/opportunityCommon.reducer'
import OpportunityOpportunities from './opportunity/opportunities.reducer'
import OpportunityContacts from './opportunity/contacts.reducer'
import Files from './files.reducer'
import ApiKey from './apiKey.reducer'
import OpportunityAudience from './opportunity/audience.reducer'
import XpUsersWebSocket from './xpUsersWebSocket.reducer'
import XpGmailIntegration from './../Reducers/integrations/xpGmailIntegration.reducer'

export default combineReducers({
  Gamification,
  Campaigns,
  Attendees,
  ProjectNotifications,
  Login,
  Register,
  Profile,
  Loader,
  Events,
  Contacts,
  Groups,
  Members,
  Notifications,
  Proutes,
  Users,
  AutoLogin,
  Roles,
  Permission,
  Projects,
  ManagePage,
  Agenda,
  Report,
  Integration,
  AccessCode,
  ServicePlan,
  AppsIntegration,
  AppConfigModal,
  Meeting,
  VanityDomain,
  GoogleWorkspace,
  GoogleOrgUnit,
  GoogleSignIn,
  AIChatbot,
  Applications,
  OpportunityAccounts,
  OpportunityCustomFields,
  OpportunityStates,
  OpportunityCommon,
  OpportunityOpportunities,
  Files,
  OpportunityContacts,
  ApiKey,
  OpportunityAudience,
  XpUsersWebSocket,
  XpGmailIntegration
});

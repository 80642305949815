import React, { useState, useEffect, memo } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import PageNotFound from '../Components/PageNotFound';
import Loader from '../Components/Loader/loader';
import Pages from '../Pages/demo/pages';
import MyProfile from '../Pages/demo/MyProfile';
import AttendeeQr from '../Pages/demo/AttendeeQr';
import { getAllPagesOfEvent } from 'ReduxStore/API';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import EventWidgetContainer from 'Components/ChatWidget/EventWidgetContainer';

// Using memo to prevent unnecessary re-renders
const AppRoutes = memo(({ allPagesPath, eventId, isPublished, navigate, location }) => {
  return (
    <Routes>
      <Route path="/profile/update-profile" element={<MyProfile eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/profile/security" element={<MyProfile eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/profile/my-agenda" element={<MyProfile eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/profile/agenda" element={<MyProfile eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/profile/gamification" element={<MyProfile eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/profile/access-code" element={<MyProfile eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/attendee/qr-code" element={<AttendeeQr eventId={eventId} navigate={navigate} location={location} />} />
      <Route path="/" element={<Pages eventId={eventId} allPagesPath={allPagesPath} isPublished={isPublished} />} />
      <Route path="/logout" element={<Pages eventId={eventId} allPagesPath={allPagesPath} isPublished={isPublished} />} />
      {allPagesPath.map((path) => (
        <Route key={path.path} path={`/${path.path}`} element={<Pages eventId={eventId} allPagesPath={allPagesPath} isPublished={isPublished} />} />
      ))}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
});

const EndUserEventRoutes = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [allPagesPath, setAllPagesPath] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [eventId, setEventId] = useState(null);
  const navigate = useNavigate(); // Get navigate function from useNavigate
  const location = useLocation(); // Get location from useLocation

  useEffect(() => {
    async function fetchData() {
      const eventDomain = window.location.hostname;
      try {
        const result = await getAllPagesOfEvent({ eventDomain });
        const { allPagesPath, isPublished, eventId } = result.data;

        setAllPagesPath(allPagesPath || []);
        setEventId(eventId);
        setIsPublished(isPublished);
        setShowLoader(false);
      } catch (e) {
        setShowLoader(false);
      }
    }
    fetchData();
  }, []);

  // Conditional rendering based on loading state and publication status
  if (showLoader) return <Loader />;
  if (!isPublished) return <Navigate to="*" element={<PageNotFound />} />;

  return eventId ? (
    <>
      <AppRoutes 
        allPagesPath={allPagesPath} 
        eventId={eventId} 
        isPublished={isPublished} 
        navigate={navigate} 
        location={location} // Pass navigate and location props down
      />
      {/* Add EventWidgetContainer here if needed */}
    </>
  ) : null; // Ensure a fallback for cases where eventId isn't set
};

const mapStateToProps = (state) => ({
  accessCode: state.AccessCode,
  events: state.Events,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      setNewAccessCodeExpiryTime: Actions.setNewAccessCodeExpiryTime,
      setAwsNotificationMessage: Actions.setAwsNotificationMessage,
      showCommonPopup: Actions.showCommonPopup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapActionsToProps)(EndUserEventRoutes);

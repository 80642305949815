import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import EditBtn from '../../../assets/images/icons/edit.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { createNotification } from '../../../helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getProjectIdFromUrl } from '../../../helpers/common';
import Loader from 'Components/Loader/loader';

export class BadgesQrCodeTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasRenderedByBadges: true,
            badgesTemplates: [],
            loading: false
        };
    }

    componentDidMount() {
        const projectId = getProjectIdFromUrl();
        this.listBadgesTemplateFun(projectId); 
    }

    componentDidUpdate(prevProps) {  
        if (this.props.attendees.listBadgesTemplateSuccess === true && this.props.attendees.message !== prevProps.attendees.message) {
            this.setState({
                badgesTemplates: this.props.attendees.badgesTemplates,
                loading: false
            });
        }
    }

    listBadgesTemplateFun = (projectId) => {
       this.props.listBadgesTemplate({ projectId }); 
        this.setState({
            loading: true
        });
    };

    render() {
        const { badgesTemplates } = this.state; 
        return (
            <ThemeProvider theme={theme}>
                <Grid container spacing={2} mb={3} className="top-filter-area">
                    <Grid >
                        <Grid container alignItems="center">
                            <IconButton size='small' aria-label="delete">
                                <KeyboardBackspaceIcon onClick={this.props.closeBadgesModel} style={{ color: theme.palette.primary.dark, }} />
                            </IconButton>
                            <p className='subtitle' style={{ color: theme.palette.grey[800], marginLeft: 10, }}>Badges Templates</p>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer className="list-table">
                    <Table className="table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='long-text sort-asc'>Badge Name</TableCell>
                                <TableCell className='long-text sort-asc'>Subject</TableCell>
                                <TableCell className='long-text sort-asc'>Last Updated On</TableCell>
                                <TableCell className='options'>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(badgesTemplates) && badgesTemplates.length &&
                                badgesTemplates.map(badgesTemplate => {
                                    return (
                                        <TableRow>
                                            <TableCell className='long-text'>{badgesTemplate.pageName}</TableCell>
                                            <TableCell className='long-text'>{badgesTemplate.pageName}</TableCell>
                                            <TableCell className='long-text'> {badgesTemplate.updatedAt ? moment(badgesTemplate.updatedAt).format("dddd, MMMM Do YYYY, h:mm a") : "--"} </TableCell>
                                            <TableCell className='options'>
                                                {' '}
                                                <Box sx={{ textAlign: 'center', }}>
                                                    <Link
                                                        to={{
                                                            pathname: `/project-page/update-badges-template-page/pid/${badgesTemplate?.eventId}/tid/${badgesTemplate?._id}/pn/${badgesTemplate?.pageName}/purl/${badgesTemplate?.path}`,
                                                            state: { hasRenderedByBadges: this.state.hasRenderedByBadges }
                                                        }}>
                                                        <img alt="" src={EditBtn} />
                                                    </Link>

                                                    {' '}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.loading ? <Loader /> : null}
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    attendees: state.Attendees,
});
const mapActionsToProps = (dispatch) =>
    bindActionCreators(
        {
            listBadgesTemplate: Actions.listBadgesTemplateRequest,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapActionsToProps)(BadgesQrCodeTemplate);

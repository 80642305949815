//@ts-nocheck
import { createReducer } from "reduxsauce";
import { Constants } from '../../../Actions';
import ZapierIcon from '../../../../assets/images/icons/Zapier-icon.svg';

export type Application_state = {
    error: boolean,
    message: string | null,
    loading: boolean,
    applications: Applications,
    enableDisableApplicationIntegrationSuccess: boolean,
    checkApplicationIntegrationEnabledSuccess: boolean,
};

export type Applications = Application[];

export type Application = {
    name: string,
    description: string,
    icon: string,
    label: string,
    activeStatus: boolean;
};

export const applications: Applications = [{
    name: 'Zapier',    
    description: 'Automate your things easily on this platform',
    icon: ZapierIcon,
    label: 'Zapier',
    activeStatus: false
}];

const INITIAL_STATE: Application_state = {
    error: false,
    message: null,
    loading: false,
    applications: applications,
    enableDisableApplicationIntegrationSuccess: false,
    checkApplicationIntegrationEnabledSuccess: false,
};

const enableDisableApplicationIntegrationRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        enableDisableApplicationIntegrationSuccess: false,
        error: false,
        message: null,
        loading: true
    };
};

const enableDisableApplicationIntegrationSuccess = (state = INITIAL_STATE, action: any) => {
    let apps = applications.map(app => {
        if (app.name === action.appName) {
            return {
                ...app,
                activeStatus: action.activeStatus
            };
        } else {
            return app;
        }
    });

    return {
        ...state,
        enableDisableApplicationIntegrationSuccess: true,
        error: false,
        applications: apps,
        message: action.message,
        loading: false
    };
};

const enableDisableApplicationIntegrationError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        enableDisableApplicationIntegrationSuccess: false,
        error: true,
        message: action.error,
        loading: false
    };
};

const checkApplicationIntegrationEnabledRequest = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        checkApplicationIntegrationEnabledSuccess: false,
        error: false,
        loading: true
    };
};
const checkApplicationIntegrationEnabledSuccess = (state = INITIAL_STATE, action: any) => {
    const activeStatusMap: Object = {};

    action.apps.forEach((app: any) => {
        // @ts-ignore
        activeStatusMap[app.appName] = app.activeStatus;
    });
    // Combine the properties from both arrays
    const combinedArray = applications.map(app => ({
        ...app,
        // @ts-ignore
        activeStatus: activeStatusMap[app.name] ? activeStatusMap[app.name]: false
    }));

    return {
        ...state,
        checkApplicationIntegrationEnabledSuccess: true,
        error: false,
        loading: false,
        applications: combinedArray,
    };
};
const checkApplicationIntegrationEnabledError = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        checkApplicationIntegrationEnabledSuccess: false,
        error: true,
        loading: false
    };
};

const HANDLERS = {
    [Constants.ENABLE_DISABLE_APPLICATION_INTEGRATION_REQUEST]: enableDisableApplicationIntegrationRequest,
    [Constants.ENABLE_DISABLE_APPLICATION_INTEGRATION_SUCCESS]: enableDisableApplicationIntegrationSuccess,
    [Constants.ENABLE_DISABLE_APPLICATION_INTEGRATION_ERROR]: enableDisableApplicationIntegrationError,
    [Constants.CHECK_APPLICATION_INTEGRATION_ENABLED_REQUEST]: checkApplicationIntegrationEnabledRequest,
    [Constants.CHECK_APPLICATION_INTEGRATION_ENABLED_SUCCESS]: checkApplicationIntegrationEnabledSuccess,
    [Constants.CHECK_APPLICATION_INTEGRATION_ENABLED_ERROR]: checkApplicationIntegrationEnabledError,
};

export default createReducer(INITIAL_STATE, HANDLERS);

import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { MenuProps } from './common';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { getProjectIdFromUrl, UserData } from 'helpers/common';
import { createNotification } from 'helpers';
import axios from 'axios';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';

function MapCustomFields(props) {
  const dispatch = useDispatch();

  const attendeesReduxState = useSelector(store => store.Attendees);
  const googleWorkspaceState = useSelector(store => store.GoogleWorkspace);
  const [listOfcustomFields, setListOfCustomFields] = useState([]);
  const [workspaceCustomFields, setWorkspaceCustomFields] = useState({});
  const [customFieldsMappedValues, setCustomFieldsMappedValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listCustomFields();
    setLoading(true);
    findGoogleWorkspaceAttendeesData();
  }, []);

  useEffect(() => {
    if (attendeesReduxState.getCustomFieldSuccess) {
      let onlyTextTypeCustomFields = attendeesReduxState?.customFields?.filter(cf => cf.fieldData.fieldType === "text");
      onlyTextTypeCustomFields = onlyTextTypeCustomFields.map(el => {
        return {
          fieldObject: {fieldLabel: el.fieldData.fieldLabel, fieldId: el._id},
          inUse: false
        };
      });
      if (props.integrationData.appData.fieldMapping) {
        let customFieldsPresentInFieldMapping = [];
        for (const key in props.integrationData?.appData?.fieldMapping) {
          if (props.integrationData?.appData?.fieldMapping[key] !== "") {
            customFieldsPresentInFieldMapping.push(props.integrationData?.appData?.fieldMapping[key]);
          }
        }
        let customFieldsModified = onlyTextTypeCustomFields.map(el => {
          if (customFieldsPresentInFieldMapping.includes(el.fieldObject.fieldId)) {
            return {
              ...el,
              inUse: true
            };
          } else {
            return el;
          }
        });
        setListOfCustomFields(customFieldsModified);
      } else {
        setListOfCustomFields(onlyTextTypeCustomFields);
      }
      setLoading(false);
    }
  }, [attendeesReduxState?.customFields, attendeesReduxState.getCustomFieldSuccess, props.integrationData.appData.fieldMapping]);

  const listCustomFields = () => {
    try {
      const projectId = getProjectIdFromUrl();
      let data = {
        projectId,
        permission: 'Edit Custom Fields',
      };
      dispatch(Actions.customFieldsRequest(data));
    } catch (error) {

      createNotification('error', 'Something went wrong');
    }
  };

  const findGoogleWorkspaceAttendeesData = async () => {
    try {
      const projectId = getProjectIdFromUrl();
      const users = await UserData();
      const res = await axios.get(`/appIntegration/getFieldMapping/${props.integrationId}/${projectId}`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
      const fieldMapping = res.data.fieldMapping;
      let fieldMappingArray = convertingObjectIntoArray(fieldMapping)
      setWorkspaceCustomFields(fieldMappingArray)
      listWorkSpaceCustomFields(fieldMapping);
      setLoading(false);
    } catch (error) {
      createNotification('error', 'Something went wrong'); 
    }
  };

  const convertingObjectIntoArray = (obj) => {
    let arr = []
    for (let key in obj) {
      arr.push(key)
    }
    return arr
  }

  const handleMenuItem = (e, key) => {
    let obj = {
      ...customFieldsMappedValues,
      [key]: e.target.value
    };
    setCustomFieldsMappedValues({ ...obj });

    let arr = [...listOfcustomFields];
    let index = arr.findIndex(el => el.fieldObject.fieldId === e.target.value);

    if (index > -1) {
      arr[index].inUse = true;

      const prevValue = customFieldsMappedValues[key];
      let index2 = arr.findIndex(el => el.fieldObject.fieldId === prevValue);
      if (index2 > -1) {
        arr[index2].inUse = false;
      }
    } else {
      const prevValue = customFieldsMappedValues[key];
      let index2 = arr.findIndex(el => el.fieldObject.fieldId === prevValue);
      arr[index2].inUse = false;
    }
    setListOfCustomFields(arr);
  };

  const renderWorkspaceCustomFields = () => {
    return Array.isArray(workspaceCustomFields) && workspaceCustomFields?.map((cf, i) => {
      return (
        <Box mb={2} component="div" className="inputField" key={i}>
          <input
            type="text"
            class="form-control small"
            placeholder={cf}
            disabled={true}
          />
        </Box>
      );
    });
  };

  const listWorkSpaceCustomFields = (arr) => {

    if (props.integrationData.appData.fieldMapping) {  
      setCustomFieldsMappedValues(props.integrationData?.appData?.fieldMapping);
    } else {                                                                                // If field mapping isn't present in the integrationData
      const customFieldsMappedValuesArr = arr.reduce((acc, curr) => (acc[curr] = '', acc), {});
      setCustomFieldsMappedValues(customFieldsMappedValuesArr);
    }
  };

  const handleApplyChanges = () => {
    const body = {
      customFieldsMappedValues: customFieldsMappedValues,
      integrationId: props.integrationId
    };
    dispatch(Actions.updateGoogleWorkspaceCustomFieldsRequest(body));
  };

  useEffect(() => {
    if (googleWorkspaceState.updateGoogleWorkspaceCustomFieldsSuccess) {
      dispatch(Actions.googleWorkspaceGetWidgetRequest(props.integrationId));  // Getting the updated integrationData
    }
  }, [googleWorkspaceState.updateGoogleWorkspaceCustomFieldsSuccess]);

  useEffect(() => {
    if (googleWorkspaceState.googleWorkspaceGetWidgetSuccess) {
      setCustomFieldsMappedValues(googleWorkspaceState?.integrationData?.appData?.fieldMapping); // Setting the updated integrationData
    }
  }, [googleWorkspaceState.googleWorkspaceGetWidgetSuccess]);

  return (
    <Box mt={1}>
      {/* ---header--- */}
      <Box
        pb={3}
        pt={2.5}
        mb={1}
        // sx={{ borderBottom: '1px solid #EDECF5' }}
        className="top-filter-area"
      >
        <Grid container spacing={2}>
          <Grid size={{md: 'grow',}}>
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid >
                <Box
                  component="h4"
                  className="subsecondary"
                  sx={{
                    marginRight: 50,
                    marginBottom: 0,
                  }}
                >
                  Map Custom Fields
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid >
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleApplyChanges}
            >
              Apply Changes
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        rowSpacing={{ xs: 2, xl: 2.5 }}
        columnSpacing={1}
      >
        <Grid size={{xs: 6, md:6}}>
          <Box ml={2.5} component="div" className="inputField">
            <label>Google Workspace</label>
          </Box>
        </Grid>
        <Grid size={{xs: 5, md:5}}>
          <Box component="div" className="inputField">
            <label>IllumeetXP custom fields</label>
          </Box>
        </Grid>
      </Grid>
      <Box
        p={{ xs: 1, sm: 2, lg: 2.5 }}
        className=""
        sx={{
          border: '1px solid #EAEAEA',
          borderRadius: '8px',
        }}
      >
        <Grid
          container
          rowSpacing={{ xs: 2, xl: 2.5 }}
          columnSpacing={1}
          alignItems={'center'}
        >
          <Grid size={{xs: "grow", md:5}}>
            {Array.isArray(workspaceCustomFields) && workspaceCustomFields.length > 0 && renderWorkspaceCustomFields()}
          </Grid>
          <Grid size={{xs: 1, md:1}}  textAlign="center">
            {Array.isArray(workspaceCustomFields) && workspaceCustomFields.length > 0 && workspaceCustomFields.map((item, m) => {
              return (
                <Box key={m} component={Stack} alignItems={'center'} justifyContent={'center'} sx={{ minHeight: '48px', marginBottom: '16px' }}>
                  <ArrowForwardIcon />
                </Box>
              );
            })}
          </Grid>

          <Grid size={{xs: "grow", md:5}}>
            {Object.keys(customFieldsMappedValues).length > 0 && Array.isArray(workspaceCustomFields) && workspaceCustomFields.length > 0 && workspaceCustomFields?.map((el, i) => {
              return (
                (<Box component="div" className="inputField" key={i}>
                  <FormControl fullWidth size="small">
                    <Select
                      type="text"
                      className='form-control select small'
                      style={{ padding: '0px', marginBottom: '16px', border: 'none !important' }}
                      MenuProps={MenuProps}

                      displayEmpty
                      onChange={(e) => handleMenuItem(e, el)}
                      value={customFieldsMappedValues[el] || ""}
                    >
                      <MenuItem value="" >
                        Select
                      </MenuItem>


                      {listOfcustomFields?.map((cf, j) => {
                        return (
                          !cf.inUse ? <MenuItem key={j} value={cf.fieldObject.fieldId} >{cf?.fieldObject.fieldLabel}</MenuItem> :
                            <MenuItem key={j} hidden value={cf.fieldObject.fieldId} >{cf?.fieldObject.fieldLabel}</MenuItem>
                        );
                      })}

                    </Select>
                  </FormControl>
                </Box>
                )

              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MapCustomFields;
import ChatWidget, { WidgetActions } from 'Components/ChatWidget/Widget/Widget';
import {
  AIChatbotState,
  ChatInterfaceType,
} from 'ReduxStore/Reducers/aiChatbot.reducer';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PreviewWidget from './Sections/Preview';

type ChatInterfaceProps = {
  chatInterface: ChatInterfaceType;
};
export default function ChatPreview({ chatInterface }: ChatInterfaceProps) {
  const widgetRef = useRef<WidgetActions>(null);
  const state = useSelector((store: any) => store.AIChatbot) as AIChatbotState;
  const [previewMessages, setPreviewMessages] = useState<any[]>([]);
  useEffect(() => {
    const initM = state.chatBot?.settings?.chatInterface?.initialMessages;
    if (initM && initM.length) {
      const messages = state.widgetPreviewMessages;
      messages[0].text = initM[0];
      setPreviewMessages((pre) => { return [...messages]; });
    }

  }, [state.chatBot?.settings?.chatInterface?.initialMessages, state.widgetPreviewMessages]);
  return (
    <div>
      <PreviewWidget
        loading={false}
        error=''
        disableSendButton={true}
        ref={widgetRef}
        widgetStyle='inline'
        messages={previewMessages}
        chatInterface={chatInterface}
        for="preview"
      />
    </div>
  );
}

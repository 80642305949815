//@ts-nocheck
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Grid from '@mui/material/Grid2';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import {
  Contact,
  CustomFields,
  Source,
  Account,
} from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import { CustomField } from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { createNotification } from 'helpers';
import { isValidEmail, isValidURL, validateSocialMediaUrl } from 'helpers/common';
import OpportunityAccountsCustomFieldsAPI from 'ReduxStore/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import OpportunityContactsAPI from 'ReduxStore/API/opportunity/opportunityContactsAPI';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import DropdownForListDataTypeCF from '../Common/DropdownForListDataTypeCF';
import { useDispatch, useSelector } from 'react-redux';
import { OpportunityState } from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import InputFieldForPhoneDataTypeCF from '../Common/InputFieldForPhoneDataTypeCF';
import moment from 'moment';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';
import ReactSelect from 'react-select';
import { customStyles } from 'Pages/Dashboard/Notications/addNotification';
import { Option } from 'Pages/Dashboard/Notications/addNotification';
import { Actions } from 'ReduxStore/Actions';
import { AudienceState } from 'ReduxStore/Reducers/opportunity/audience.reducer';
import { getActiveUsersForCompanyAPI } from 'ReduxStore/API';
import CustomDropdownWithActiveUsers from 'Components/CustomDropdowns/CustomDropdownWithActiveUsers';
import ReactDatePicker from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type CustomFieldsValidationError = {
  [key: string]: boolean | string;
};

type CustomFieldsObj = {
  [key: string]: string | string[];
};
type ContactGroup = {
  value: string;
  label: string;
};

type ContactGroups = ContactGroup[];

type Props = {
  closeAddNewPopup: () => void;
  cfId: string;
  handleContactCFChangeForSearchableDropdown: (
    openedContact: Contact | null,
    cfId: string,
    isSaving?: boolean,
  ) => void;
  contactToBeEdited?: Contact;
  onClose: () => void;
  addNewContact: (cfId: string, addingNew: boolean) => void;
  toBeEdited?: Account;
  callFrom: string;
};

type BodyParams = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  customFields: CustomFields;
  _id?: string;
  source: Source;
  accountId: string;
  audienceIds: string[];
};

type BodyToCheckEmptyObject = {
  [key: string]: CustomFieldsInBody | string | string[];
};

type CustomFieldsInBody = {
  [key: string]: string;
};

type AlreadyExistingData = {
  account?: Account;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  customFields?: CustomFieldsObj;
  selectedContactGroups?: ContactGroups;
};

let confirmationPopupMessgae = '';
let changesMade = false;

const CreateOrEditContact = forwardRef(
  (
    {
      cfId,
      handleContactCFChangeForSearchableDropdown,
      contactToBeEdited,
      onClose,
      closeAddNewPopup,
      addNewContact,
      toBeEdited,
      callFrom,
    }: Props,
    ref,
  ) => {
    let isSavingContact = useRef(false);
    let isSavingAccount = useRef(false);
    let isUnlinkingFromParent = useRef(false);
    let isValidForDraft = useRef(false);
    const dispatch = useDispatch();
    const opportunityState = useSelector(
      (state: any) => state.OpportunityOpportunities,
    ) as OpportunityState;

    const { groups } = useSelector(
      (state: any) => state.OpportunityAudience,
    ) as AudienceState;

    const { activeUsersForCompany } = useSelector((state: any) => state.Users);

    const [customFields, setCustomFields] = useState<CustomFields>({});
    const [customFieldsObj, setCustomFieldsObj] = useState<CustomFieldsObj>({});
    const [idBeingEdited, setIdBeingEdited] = useState<string>('');
    const [customFieldsValidationError, setCustomFieldsValidationError] =
      useState<CustomFieldsValidationError>({} as CustomFieldsValidationError);
    const [account, setAccount] = useState<Account>({} as Account);
    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [source, setSource] = useState<Source>('manuallyAdded');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alreadyExistingDataInTheForm, setAlreadyExistingDataInTheForm] =
      useState<AlreadyExistingData>({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
      });
    const [openConfirmationPop, setOpenConfirmationPop] =
      useState<boolean>(false);
    const [isFormEmpty, setIsFormEmpty] = useState<boolean>(false);
    const [isUnlinking, setIsUnlinking] = useState<boolean>(false);
    const [selectedContactGroups, setSelectedContactGroups] = useState<ContactGroups>([]);
    const [contactGroups, setContactGroups] = useState<ContactGroups>([]);
    const [formIsLoaded, setFormIsLoaded] = useState<boolean>(false);

    useEffect(() => {
      fetchAllOpportunityCustomField();
    }, []);

    useEffect(() => {
      // if(idBeingEdited){
      const isObjEmpty = isEmptyObject({
        // @ts-ignore
        customFields: customFieldsObj,
        firstName,
        middleName,
        lastName,
        email,
      });
      setIsFormEmpty(isObjEmpty);
      // }
    }, [customFieldsObj, firstName, middleName, lastName, email]);

    const fetchAllOpportunityCustomField = async () => {
      try {
        let from = '';
        if (toBeEdited && Object?.keys(toBeEdited)?.length) {
          if (callFrom === 'opportunity') {
            from = 'editOpportunity';
          } else {
            from = 'editAccount';
          }
        } else {
          if (callFrom === 'opportunity') {
            from = 'createOpportunity';
          } else {
            from = 'createAccount';
          }
        }
        const opportunityAccountsCustomFieldsAPI =
          new OpportunityAccountsCustomFieldsAPI();
        const data = {
          customFieldType: 'contacts',
          from: from,
        };
        const res =
          await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
            data,
          );
        setCustomFields(res.data.customFields);
        settingCustomFieldObjWithUndeletedCustomFields(res.data.customFields);
      } catch (error) {
        console.log(error);
      }
    };

    const settingCustomFieldObjWithUndeletedCustomFields = (
      customFieldsAsArgument: CustomFields,
    ) => {
      const customFieldsArr = customFieldsAsArgument || customFields;
      const obj: CustomFieldsObj = {};
      Array.isArray(customFieldsArr) &&
        customFieldsArr.forEach((cf) => {
          if (cf.fieldData.fieldType === 'boolean') {
            return (obj[cf._id as string] = '');
          } else {
            return (obj[cf._id as string] = '');
          }
        });
      return obj;
    };

    const settingCustomFieldObjWithExistingCustomFieldsForAGivenContact = (
      existingCFInContact: CustomFieldsObj,
    ) => {
      let commonKeysObj: CustomFieldsObj = {};
      Array.isArray(customFields) &&
        customFields.forEach((cf) => {
          if (Object.keys(existingCFInContact).includes(cf._id as string)) {
            return (commonKeysObj[cf._id as string] =
              existingCFInContact[cf._id as string]);
          } else {
            if (cf.fieldData.fieldType === 'boolean') {
              commonKeysObj[cf._id as string] = '';
            } else {
              commonKeysObj[cf._id as string] = '';
            }
          }
        });
      setCustomFieldsObj(commonKeysObj);
      setAlreadyExistingDataInTheForm((prev) => {
        return {
          ...prev,
          customFields: commonKeysObj,
        };
      });
    };

    useEffect(() => {
      resetForm();
    }, [contactToBeEdited, customFields]);

    useEffect(() => {
      let groupsModified = [] as ContactGroups;
      if (Array.isArray(groups) && groups.length) {
        groupsModified = groups.map(group => ({ value: group._id, label: group.audienceName }));
      }
      setContactGroups(groupsModified);
    }, [groups]);

    useImperativeHandle(ref, () => {
      return {
        handleAddOrUpdate,
        handlevalidation,
        checkIfFormHasChanged,
        cfId,
        saveContactBeforeUnlinking,
        idBeingEdited,
        isFormEmpty,
        resetForm,
        onClose,
        checkIfContactInfoHasChanged,
        setCustomFieldsValidationErrorToDefault,
        handleValidationCfIsMandatory,
        modifyIsUnlinkingFromParent,
        modifyIsSavingContact,
        modifyIsSavingAccount,
        isValidForDraft: isValidForDraft.current
      };
    });

    const setCustomFieldsValidationErrorToDefault = () => {
      setCustomFieldsValidationError({});
    };

    const resetForm = () => {
      if (Array.isArray(customFields) && customFields.length > 0) {
        if (
          typeof contactToBeEdited === 'object' &&
          contactToBeEdited !== null &&
          Object.keys(contactToBeEdited).length
        ) {
          let alreadyExistingData: AlreadyExistingData = {};
          if (Object.keys(contactToBeEdited).length) {
            if (contactToBeEdited.firstName) {
              setFormIsLoaded(true);
              setFirstName(contactToBeEdited.firstName);
              alreadyExistingData.firstName = contactToBeEdited.firstName;
            } else {
              alreadyExistingData.firstName = '';
              setFirstName('');
            }
            if (contactToBeEdited.middleName) {
              setMiddleName(contactToBeEdited.middleName);
              alreadyExistingData.middleName = contactToBeEdited.middleName;
            } else {
              alreadyExistingData.middleName = '';
              setMiddleName('');
            }

            if (contactToBeEdited.lastName) {
              setLastName(contactToBeEdited.lastName);
              alreadyExistingData.lastName = contactToBeEdited.lastName;
            } else {
              alreadyExistingData.lastName = '';
              setLastName('');
            }

            if (contactToBeEdited.audienceIds) {
              let { audienceIds } = contactToBeEdited;
              let selectedGroup = groups.filter(group => audienceIds.includes(group._id));
              let formattedGroups = selectedGroup.map(group => ({ label: group.audienceName, value: group._id }));
              setSelectedContactGroups(formattedGroups);
              alreadyExistingData.selectedContactGroups = formattedGroups;
            } else {
              alreadyExistingData.selectedContactGroups = [];
              setSelectedContactGroups([]);
            }

            if (contactToBeEdited.email) {
              setEmail(contactToBeEdited.email);
              alreadyExistingData.email = contactToBeEdited.email;
            } else {
              alreadyExistingData.email = '';
              setEmail('');
            }

            if (contactToBeEdited.source) {
              setSource(contactToBeEdited.source);
            }

            if (contactToBeEdited.customFields) {
              settingCustomFieldObjWithExistingCustomFieldsForAGivenContact(
                contactToBeEdited.customFields as any,
              );
            }
            setIdBeingEdited(contactToBeEdited._id);

            setAlreadyExistingDataInTheForm((prev) => ({
              ...prev,
              ...alreadyExistingData,
            }));
          }
        } else {
          setFormIsLoaded(true);
          setAccount({} as Account);
          setFirstName('');
          setMiddleName('');
          setLastName('');
          setEmail('');
          setIdBeingEdited('');
          settingCustomFieldObjWithExistingCustomFieldsForAGivenContact(
            [] as any,
          );
        }
      }
    };

    const checkIfFormHasChanged = () => {
      const dataEntered: any = {
        customFields: customFieldsObj,
        firstName,
        middleName,
        lastName,
        email,
        selectedContactGroups,
      };
      return areDifferentObjects(alreadyExistingDataInTheForm, dataEntered);
    };

    const handlevalidation = () => {
      let returnedValue = false;
      if (!isFormEmpty) {
        returnedValue = isValid();
      } else {
        returnedValue = true;
      }
      return returnedValue;
    };

    const handleValidationCfIsMandatory = () => {
      let returnedValue = false;
      if (!isFormEmpty) {
        returnedValue = isValid();
      }
      return returnedValue;
    };

    const modifyIsUnlinkingFromParent = (isUnlinking: boolean) => {
      isUnlinkingFromParent.current = isUnlinking;
    };
    const modifyIsSavingContact = (isSaving: boolean) => {
      isSavingContact.current = isSaving;
    };
    const modifyIsSavingAccount = (isSaving: boolean) => {
      isSavingAccount.current = isSaving;
    };

    const handleAddOrUpdate = async (
      savingBeforeUnlinkingFromMainParent?: boolean,
    ) => {
      if (!isFormEmpty) {
        let isValidForm = true;
        if (isUnlinkingFromParent.current || isUnlinking) {
          isValidForm = isValid();
          openConfirmationPop && setOpenConfirmationPop(false);
        }
        if (isValidForm) {
          const bodyParams: BodyParams = {
            customFields: customFieldsObj,
            firstName: firstName.trim(),
            middleName: middleName.trim(),
            lastName: lastName.trim(),
            email: email.trim().toLocaleLowerCase(),
            source: source,
            accountId: account._id,
            audienceIds: selectedContactGroups.map(el => el.value)
          };

          const isEmpty = isEmptyObject(bodyParams as BodyToCheckEmptyObject);

          if (isEmpty) {
            createNotification('error', 'At least one value is required');
            return;
          }

          if (idBeingEdited) {
            bodyParams._id = idBeingEdited;
          }
          let data = {
            bodyParams: bodyParams,
          };
          if (idBeingEdited) {
            if (savingBeforeUnlinkingFromMainParent) {
              await update(data);
              onClose();
            } else if (isUnlinkingFromParent.current || isUnlinking) {
              if (isSavingContact.current) {
                await update(data);
              }
              isUnlinking && setIsUnlinking(false);
              isUnlinkingFromParent.current = false;
              isSavingContact.current = false;
              if (isUnlinking) {
                onClose();
              }
            } else {
              handleContactCFChangeForSearchableDropdown(
                bodyParams,
                cfId,
                true,
                // @ts-ignore
                checkIfContactInfoHasChanged(),
              );
              isUnlinking && setIsUnlinking(false);
            }
          } else {
            if (!isUnlinkingFromParent.current) {
              handleContactCFChangeForSearchableDropdown(
                bodyParams,
                cfId,
                true,
                // @ts-ignore
                checkIfContactInfoHasChanged(),
              );
            } else {
              addNewContact(cfId, true);
              resetForm();
            }
          }
        }
      } else {
        handleContactCFChangeForSearchableDropdown(
          null,
          cfId,
          true,
          // @ts-ignore
          checkIfContactInfoHasChanged(),
        );
      }
    };

    const update = async (data: any) => {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      const response = await opportunityContactsAPI.updateContactForAccountTab(
        data,
      );
      if (isUnlinkingFromParent.current) {
        if (response.status === 200) {
          if (response.data.success && response.data.updatedContact) {
            if (isSavingAccount.current) {
              handleContactCFChangeForSearchableDropdown(
                response.data.updatedContact,
                cfId,
                true,
              );
              isUnlinkingFromParent.current = false;
              isSavingContact.current = false;
            }
          }
        }
        return response;
      };
    };

    const updateContact = async (
      data: any,
      savingBeforeUnlinkingFromMainParent?: boolean,
    ) => {
      try {
        setIsLoading(true);
        const opportunityContactsAPI = new OpportunityContactsAPI();
        const response =
          await opportunityContactsAPI.updateContactForAccountTab(data);
        if (response.status === 200) {
          if (response.data.success && response.data.updatedContact) {
            setIsLoading(false);
            if (savingBeforeUnlinkingFromMainParent) {
              onClose();
            } else if (isUnlinkingFromParent.current || isUnlinking) {
              isUnlinking && setIsUnlinking(false);
              isUnlinkingFromParent.current = false;
              if (isUnlinking) {
                onClose();
              }
            } else {
              handleContactCFChangeForSearchableDropdown(
                response.data.updatedContact,
                cfId,
                true,
              );
              isUnlinking && setIsUnlinking(false);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    function isEmptyObject(body: BodyToCheckEmptyObject) {
      const obj = { ...body };
      delete obj.source;
      // Check if obj is not an object or is null
      if (typeof obj !== 'object' || obj === null) {
        return false;
      }

      // Check if obj has any own properties
      if (Object.keys(obj).length === 0) {
        return true;
      }

      // Iterate over each property of obj
      for (let key in obj) {
        // If the property value is an object, recursively call isEmptyObject
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (!isEmptyObject(obj[key] as BodyToCheckEmptyObject)) {
            return false;
          }
        } else {
          // If the property value is not an object and not empty, return false
          if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
            return false;
          }
        }
      }
      // If all properties are empty, return true
      return true;
    }

    const areDifferentObjects = (
      obj1?: any,
      obj2?: any,
      seen = new WeakMap(),
    ) => {
      // If both values are strictly equal, they are the same
      if (obj1 === obj2) {
        return false;
      }

      // If either value is null or not an object, they are different
      if (
        obj1 === null ||
        obj2 === null ||
        typeof obj1 !== 'object' ||
        typeof obj2 !== 'object'
      ) {
        return true;
      }

      // Handle circular references
      if (seen.has(obj1) && seen.has(obj2)) {
        return seen.get(obj1) !== seen.get(obj2);
      }
      seen.set(obj1, obj2);
      seen.set(obj2, obj1);

      // Compare the number of keys in both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return true;
      }

      // Compare each key and value in both objects
      for (let key of keys1) {
        if (
          !obj2.hasOwnProperty(key) ||
          areDifferentObjects(obj1[key], obj2[key], seen)
        ) {
          return true;
        }
      }

      return false;
    };

    const isValid = () => {
      let isValid = true;
      let errorsObj: CustomFieldsValidationError = {};
      try {
        if (!firstName) {
          errorsObj.firstName = 'Please enter the first name';
        }
        if (!opportunityState.isSavingAsDraft) {
          if (!isValidEmail(email)) {
            errorsObj.email = 'Please enter a valid email';
          }
          Array.isArray(customFields) &&
            customFields.forEach((cf: CustomField) => {
              for (const key in customFieldsObj) {
                if (key === cf._id) {
                  if (cf.isMandatory) {
                    if (
                      Array.isArray(customFieldsObj[key]) &&
                      !customFieldsObj[key].length
                    ) {
                      errorsObj[key] = true;
                    }
                    if (cf.fieldData.fieldType === 'date') {
                      if ((customFieldsObj?.[cf._id as string] &&
                        moment(
                          customFieldsObj?.[cf._id as string] as string,
                        ).format('YYYY-MM-DD') === 'Invalid date' ||
                        !customFieldsObj?.[cf._id as string])) {
                        errorsObj[key] = true;
                      }
                    }
                    if (!customFieldsObj[key]) {
                      errorsObj[key] = true;
                    }
                  }
                  if (cf.fieldData.fieldType === 'email') {
                    if (
                      customFieldsObj[key] &&
                      !isValidEmail(customFieldsObj[key] as string)
                    ) {
                      errorsObj[key] = true;
                    }
                  }
                  if (cf.fieldData.fieldType === 'url') {
                    if (
                      customFieldsObj[key] &&
                      !isValidURL(customFieldsObj[key] as string)
                    ) {
                      errorsObj[key] = true;
                    }
                  }
                  if (cf.fieldData.fieldType === 'facebook' || cf.fieldData.fieldType === 'linkedin' ||
                    cf.fieldData.fieldType === 'x' || cf.fieldData.fieldType === 'pinterest' ||
                    cf.fieldData.fieldType === 'instagram'
                  ) {
                    if (
                      customFieldsObj[key] &&
                      !validateSocialMediaUrl(customFieldsObj[key] as string, cf.fieldData.fieldType)
                    ) {
                      errorsObj[key] = true;
                    }
                  }
                  if (cf.fieldData.fieldType === 'number') {
                    const numbersAndHyphensRegex = /^[0-9-]*$/;
                    if (
                      !numbersAndHyphensRegex.test(
                        customFieldsObj[key] as string,
                      )
                    ) {
                      errorsObj[key] = true;
                    }
                  }
                  if (cf.fieldData.fieldType === 'date') {
                    if (customFieldsObj?.[cf._id as string] &&
                      moment(
                        customFieldsObj?.[cf._id as string] as string,
                      ).format('YYYY-MM-DD') === 'Invalid date') {
                      errorsObj[key] = true;
                    }
                  }
                }
              }
            });
        }
      } catch (error) {
        console.log(error);
      }

      if (Object.keys(errorsObj).length) {
        setCustomFieldsValidationError(errorsObj);
        isValid = false;
      } else {
        setCustomFieldsValidationError({});
      }
      if (opportunityState.isSavingAsDraft) {
        isValid ? isValidForDraft.current = false : isValidForDraft.current = true;
      }
      return isValid;
    };

    const closeConfirmationPop = () => {
      setIsUnlinking(false);
      setOpenConfirmationPop(false);
    };

    const checkIfContactInfoHasChanged = () => {
      const dataEntered = {
        customFields: customFieldsObj,
        firstName,
        middleName,
        lastName,
        email,
      };
      return areDifferentObjects(alreadyExistingDataInTheForm, dataEntered);
    };

    const handleOpenConfirmationPop = () => {
      setIsUnlinking(true);
      const dataEntered = {
        customFields: customFieldsObj,
        firstName,
        middleName,
        lastName,
        email,
      };
      if (idBeingEdited) {
        if (areDifferentObjects(alreadyExistingDataInTheForm, dataEntered)) {
          confirmationPopupMessgae =
            'You have made some changes, do you want to save the contact before unlinking?';
          changesMade = true;
        } else {
          confirmationPopupMessgae = `Are you sure you want to unlink the contact from the ${callFrom}`;
          changesMade = false;
        }
      } else {
        confirmationPopupMessgae =
          'Are you sure you want to discard these changes?';
      }
      setOpenConfirmationPop(true);
    };

    const handleCustomFieldsObj = (
      e: any,
      id: string,
      fieldType?: string,
      isMultiSelect?: boolean,
    ) => {
      const setingCFObj = (value: any,) => {
        let obj = {
          ...customFieldsObj,
        };
        if (fieldType === 'user') {
          let prevArr = (obj[id as string] as string[]);
          if (!prevArr) {
            prevArr = [];
          }
          if (prevArr.includes(value)) {
            prevArr = prevArr.filter(el => el !== value);
            obj[id as string] = [...prevArr];
          } else {
            obj[id as string] = [...prevArr, value];
          }
        } else {
          obj[id as string] = value;
        }
        setCustomFieldsObj(obj);
      };
      if (fieldType === 'number') {
        const regex = /^[0-9-]*$/;
        if (regex.test(e.target.value)) {
          setingCFObj(e.target.value);
        }
      } else if (fieldType === 'list' && !isMultiSelect) {
        setingCFObj([e.target.value]);
      } else if (fieldType === 'phone') {
        setingCFObj(e);
      } else if (fieldType === 'user') {
        setingCFObj(e);
      } else {
        setingCFObj(e.target.value);
      }
    };

    const handleCheckboxes = (
      e: React.ChangeEvent<HTMLInputElement>,
      id: string,
      value: string,
    ) => {
      let obj = {
        ...customFieldsObj,
      };
      let checkboxArr = obj[id] as string[];
      if (e.target.checked) {
        obj[id] = [...checkboxArr, value];
      } else {
        let arr = Array.isArray(checkboxArr)
          ? checkboxArr.filter((v) => value !== v)
          : [];
        obj[id] = arr;
      }
      setCustomFieldsObj(obj);
    };

    const errorStatement = (cf: CustomField) => {
      if (customFieldsValidationError?.[cf._id as string]) {
        if (cf.fieldData.fieldType === 'email') {
          return 'Please enter a valid email';
        }
        if (cf.fieldData.fieldType === 'url') {
          return 'Please enter a valid URL';
        }
        if (cf.fieldData.fieldType === 'number') {
          return 'Please enter a valid number';
        }
        if (cf.fieldData.fieldType === 'date') {
          return 'Please enter a valid date';
        }
        if (cf.fieldData.fieldType === 'boolean') {
          return 'Please choose at least one option';
        }
        if (cf.fieldData.fieldType === 'list') {
          return 'Please choose an option';
        }
        if (cf.fieldData.fieldType === 'contact') {
          return 'Please choose an option';
        }
        if (cf.fieldData.fieldType === 'account') {
          return 'Please choose an option';
        }
      } else return '';
    };

    const handleChangeMultiselectForContactGroup = (selected: ContactGroups) => {
      setSelectedContactGroups(selected);
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName(e.target.value);
    };
    const handleMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setMiddleName(e.target.value);
    };
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLastName(e.target.value);
    };
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    };

    const saveContactBeforeUnlinking = (
      unlinkingFromParent?: boolean,
      savingBeforeUnlinking?: boolean,
    ) => {
      isUnlinkingFromParent.current = unlinkingFromParent || true;
      isSavingContact.current = savingBeforeUnlinking || false;
      setIsUnlinking(true);
      handleAddOrUpdate(unlinkingFromParent);
    };

    const handleClearAllUsers = (checked: boolean, cfId: string) => {
      let obj = { ...customFieldsObj };
      if (checked) {
        obj[cfId] = [];
        setCustomFieldsObj({ ...obj });
      }
    };

    return formIsLoaded ? (
      <Grid size={{ xs: 12, }} mt={2}>
        <Box
          className="account-details add-contacts"
          //   @ts-ignore
          sx={{ backgroundColor: '#EDEBFF' }}
        >
          <Stack
            mb={2}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box className="paragraph bold primary-text">Contact Details</Box>
            <Stack direction={'row'} spacing={1}>
              <IconButton
                size="small"
                onClick={() => {
                  if (!idBeingEdited) {
                    isFormEmpty
                      ? closeAddNewPopup()
                      : handleOpenConfirmationPop();
                  } else {
                    handleOpenConfirmationPop();
                  }
                }}
              >
                {<LinkOffIcon color={'secondary'} />}
              </IconButton>
            </Stack>
          </Stack>

          <Box
            component={Grid}
            //   @ts-ignore
            rowSpacing={2}
            columnSpacing={2}
            container
            className="inputField"
          >
            <Grid size={{ xs: 12, sm: 6 }}>
              <label>
                First Name
                <span className="required">*</span>
              </label>
              <input
                onChange={handleFirstNameChange}
                value={firstName}
                type="text"
                className="form-control small"
                placeholder=""
              />
              <span className="error_mesage required">
                {customFieldsValidationError.firstName}
              </span>
            </Grid>

            <Grid size={{ xs: 12, sm: 6 }}>
              <label>Middle Name</label>
              <input
                onChange={handleMiddleNameChange}
                value={middleName}
                type="text"
                className="form-control small"
                placeholder=""
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <label>Last Name</label>
              <input
                onChange={handleLastNameChange}
                value={lastName}
                type="text"
                className="form-control small"
                placeholder=""
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <label>Email</label>
              <input
                onChange={handleEmailChange}
                value={email}
                type="text"
                className="form-control small"
                placeholder=""
              />
              <span className="error_mesage required">
                {customFieldsValidationError.email}
              </span>
            </Grid>

            <Grid size={{ xs: 12, sm: 6 }} >
              <label>Contact Group</label>
              <ReactSelect
                styles={customStyles}
                className="custom-multi-select"
                //@ts-ignore
                options={contactGroups}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                //@ts-ignore
                onChange={handleChangeMultiselectForContactGroup}
                // allowSelectAll={true}
                value={selectedContactGroups}
              />
              {/* <span className="error_mesage required">{errors.email}</span> */}
            </Grid>

            {Array.isArray(customFields) &&
              customFieldsObj &&
              customFields.map((cf) => {
                return (
                  <Grid
                    size={{
                      xs: cf.fieldData.fieldType === 'longText'
                        ? 12 : 6
                    }}


                    key={cf._id}
                  >
                    <label>
                      {cf.fieldData.fieldName}
                      {cf.isMandatory && <span className="required">*</span>}
                    </label>
                    {cf.fieldData.fieldType === 'list' ? (
                      <DropdownForListDataTypeCF
                        cf={cf}
                        customFieldsObj={customFieldsObj}
                        handleCustomFieldsObj={handleCustomFieldsObj}
                      />
                    ) : cf.fieldData.fieldType === 'boolean' ? (
                      <FormControl fullWidth component="fieldset">
                        <RadioGroup
                          // @ts-ignore
                          aria-label={cf.fieldData.fieldName}
                          name={cf.fieldData.fieldName}
                          row
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={
                            customFieldsObj?.[cf._id as string]?.toString() ||
                            ''
                          }
                        >
                          <FormControlLabel
                            value={'true'}
                            control={<Radio color="primary" />}
                            label="True"
                          />
                          <FormControlLabel
                            value={'false'}
                            control={<Radio color="primary" />}
                            label="False"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : cf.fieldData.fieldType === 'date' ? (
                      <>
                        {/* <input
                          type="date"
                          className="form-control small"
                          value={
                            customFieldsObj?.[cf._id as string] &&
                            moment(
                              customFieldsObj?.[cf._id as string] as string,
                            ).format('YYYY-MM-DD')
                          }
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                        /> */}
                        <div className="date-picker-with-icon form-control">
                            <ReactDatePicker
                              selected={customFieldsObj?.[cf._id] ? new Date(customFieldsObj[cf._id]) : null}
                              onChange={(date) =>
                                handleCustomFieldsObj(
                                  { target: { value: date } },
                                  cf._id
                                )
                              }
                              id={`date-picker-${[cf._id]}`}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              className="form-control small"
                            />
                            <CalendarTodayIcon fontSize='small' style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => document.querySelector(`#date-picker-${[cf._id]}`).focus()} />
                          </div>
                        {customFieldsObj?.[cf._id as string] &&
                          moment(
                            customFieldsObj?.[cf._id as string] as string,
                          ).format('YYYY-MM-DD') === 'Invalid date' && (
                            <>{customFieldsObj?.[cf._id as string]}</>
                          )}
                      </>
                    ) : cf.fieldData.fieldType === 'number' ? (
                      <input
                        type="text"
                        className="form-control small"
                        placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                        onChange={(e) =>
                          handleCustomFieldsObj(
                            e,
                            cf._id as string,
                            cf.fieldData.fieldType,
                          )
                        }
                        value={customFieldsObj?.[cf._id as string] as string}
                      />
                    ) : cf.fieldData.fieldType === 'phone' ? (
                      <InputFieldForPhoneDataTypeCF
                        handleCustomFieldsObj={handleCustomFieldsObj}
                        cf={cf}
                        customFieldsObj={customFieldsObj}
                      />
                    ) : cf.fieldData.fieldType === 'longText' ? (
                      <textarea
                        className="form-control"
                        placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                        onChange={(e) =>
                          handleCustomFieldsObj(e, cf._id as string)
                        }
                        value={customFieldsObj?.[cf._id as string] as string}
                        rows={4}
                      ></textarea>
                    ) : cf.fieldData.fieldType === 'user' ? (
                      <CustomDropdownWithActiveUsers
                        handleChange={(option: {
                          _id: string;
                          email: string;
                        }) =>
                          handleCustomFieldsObj(
                            option,
                            cf._id as string,
                            cf.fieldData.fieldType,
                          )
                        }
                        handleClearAll={(checked: boolean) =>
                          handleClearAllUsers(checked, cf._id as string)
                        }
                        handleAllCheckbox={() => { }}
                        listOfSelectedIds={
                          customFieldsObj?.[cf._id as string] || []
                        }
                        dropdownFor="users"
                        handlePageChange={() => { }}
                        disabled={false}
                      />
                    ) : (
                      <input
                        type={
                          cf.fieldData.fieldType === 'number'
                            ? 'number'
                            : cf.fieldData.fieldType === 'email'
                              ? 'email'
                              : 'text'
                        }
                        className="form-control small"
                        placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                        onChange={(e) =>
                          handleCustomFieldsObj(e, cf._id as string)
                        }
                        value={customFieldsObj?.[cf._id as string]}
                      />
                    )}
                    <span className="error_mesage required">
                      {errorStatement(cf as CustomField)}
                    </span>
                  </Grid>
                );
              })}
          </Box>
        </Box>
        <ConfirmationAlertPopUp
          openModal={openConfirmationPop}
          closeModalFunc={() => {
            if (idBeingEdited) {
              if (changesMade) {
                setIsUnlinking(false);
                onClose();
              } else {
                closeConfirmationPop();
              }
            } else {
              if (changesMade) {
                setIsUnlinking(false);
                closeAddNewPopup();
                closeConfirmationPop();
              } else {
                setIsUnlinking(false);
                closeConfirmationPop();
              }
            }
          }}
          handleCrossIconClick={() => {
            closeConfirmationPop();
          }}
          title={idBeingEdited ? 'Unlink Contact!' : 'Discard Changes'}
          text={confirmationPopupMessgae}
          confirmationButtonText={
            idBeingEdited
              ? changesMade
                ? 'Save Contact And Unlink'
                : 'Unlink'
              : 'Yes'
          }
          confirmationButtonColor="secondary"
          closeButtonText={
            idBeingEdited
              ? changesMade
                ? 'Unlink Without Saving'
                : 'Cancel'
              : 'Cancel'
          }
          functionality={() => {
            if (idBeingEdited) {
              if (changesMade) {
                saveContactBeforeUnlinking(false, true);
              } else {
                setIsUnlinking(false);
                onClose();
              }
            } else {
              closeAddNewPopup();
            }
            confirmationPopupMessgae = '';
          }}
        />
      </Grid>
    ) : (<Stack
      spacing={1}
      alignItems={'center'}
      py={8}
      className="account-details"
      mb={3}
    >
      <CircularProgress />
    </Stack>);
  },
);

export default CreateOrEditContact;

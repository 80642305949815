import React, { Component } from 'react'; 
import { NotificationContainer } from 'react-notifications';
import { createNotification } from '../helpers';
import { ProgressBarLine } from 'react-progressbar-line';
import EditingBack from '../assets/images/backPage.png';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NotifyIcon from '../assets/images/notification.png';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { redirectTo } from '../helpers'; 
import withCustomRouter from '../Common/withCustomRouter';
class MyEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProfileModalVisible: false,
      isNotificationModalVisible: false,
      ProfileImage: process.env.REACT_APP_IMAGE_URL + 'user.png',
      name: '',
      Pic: '',
    };
  }

  componentDidMount() {
    const storage = this.props.location.pathname.includes('member')
      ? localStorage.getItem('member_details')
      : localStorage.getItem('user_details');

    if (storage && storage !== 'undefined') {
      const user = JSON.parse(storage);
      let data = { page_no: 1, limit: 10 };
      this.setState({
        ProfileImage: process.env.REACT_APP_IMAGE_URL + user.profilePic,
        Pic: user.profilePic,
        name: user.firstName.charAt(0) + user.lastName.charAt(0),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let data = { page_no: 1, limit: 10 };
      this.props.listNotiRequest(data);
    }

    if (this.props.notifications) {
      if (
        this.props.notifications.updateSuccess === true &&
        this.props.notifications.message !== prevProps.notifications.message
      ) {
        let data = { page_no: 1, limit: 10 };
        this.props.listNotiRequest(data);
      }

      if (
        this.props.notifications.error &&
        this.props.notifications.error !== prevProps.notifications.error
      ) {
        createNotification('error', this.props.notifications.message, '');
        this.handleLoading();
      }
    }
  }

  UsertoggleBox = () => {
    this.setState((prevState) => ({
      isProfileModalVisible: !prevState.isProfileModalVisible,
    }));
    this.setState({ isNotificationModalVisible: false });
  };

  NotifytoggleBox = () => {
    this.setState((prevState) => ({
      isNotificationModalVisible: !prevState.isNotificationModalVisible,
    }));
    this.setState({ isProfileModalVisible: false });
  };

  handleLogout = () => {
    const { location } = this.props;
    const isMember = location.pathname.includes('member');
    const store = isMember
      ? ['member_role', 'member_details']
      : ['user_role', 'user_details'];

    store.forEach((item) => localStorage.removeItem(item));
    localStorage.removeItem('importProgress');
    createNotification('success', 'You logged out successfully');
    window.location.href = '/';
  };

  notificationRead = (id) => {
    this.props.updateNotiRequest({ id: id, readSt: true });
  };

  backConfirm = () => {
    const { backTo, proutes, location } = this.props;
    const navigate = this.props.navigate;

    if (this.props.showChangeConfirmation) {
      confirmAlert({
        title: 'Confirmation',
        message:
          'Please confirm that you have saved all the changes before leaving this edit feature.',
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: 'Continue',
            onClick: () => {
              navigate(backTo || proutes.proutes);
            },
          },
        ],
      });
    } else {
      navigate(backTo || proutes.proutes);
    }
  };

  render() {
    const {
      isProfileModalVisible,
      isNotificationModalVisible,
      ProfileImage,
      name,
      Pic,
    } = this.state;
    const { title, pageTitle, backTo } = this.props;
    const profile = this.props.location.pathname.includes('member')
      ? '/member/profile'
      : '/profile';
    const notifications = this.props.location.pathname.includes('member')
      ? '/member/notifications'
      : '/notifications';

    return (
      <div>
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <AppBar position="static">
          <Toolbar>
            {this.props.proutes &&
            pageTitle !== 'My Events' &&
            pageTitle !== 'Analytics' &&
            title !== 'Campaigns' &&
            pageTitle !== 'Contact' &&
            pageTitle !== 'Email Campaigns' &&
            pageTitle !== 'Complete Registration' &&
            pageTitle !== 'Audience' ? (
              <Typography className="" variant="h6" noWrap>
                <a onClick={this.backConfirm}>
                  <img src={EditingBack} />
                </a>
                {title}
              </Typography>
            ) : (
              <Typography className="" variant="h6" noWrap></Typography>
            )}

            {/* Other components and conditions */}
          </Toolbar>
        </AppBar>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.Notifications,
  proutes: state.Proutes,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listNotiRequest: Actions.listNotiRequest,
      updateNotiRequest: Actions.updateNotiRequest,
    },
    dispatch,
  );

 

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(MyEvents));

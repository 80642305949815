import React, { Component } from 'react';
import { theme } from '../../../theme/theme';  
import PageIcon from '../../../assets/images/icons/pageIcon.svg';
import Button from '@mui/material/Button';
import BackArrow from '../../../../src/assets/images/icons/backArrow.svg';
import DoubleArrow from '../../../../src/assets/images/icons/double-arrow.svg';
import Avatar from '@mui/material/Avatar';
import Sidebar from '../../../Components/Sidebar'; 
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { createNotification } from '../../../helpers';
import { NotificationContainer } from 'react-notifications';
import { addPageAndPageUrl } from './reducer/managePageReducer';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material';

import { getHostName } from '../../../helpers/common';
import { Tooltip } from '@mui/material';
import InfoIcon from '../../../../src/assets/images/icons/setting-user-Info.svg'; 

const URL = process.env.REACT_APP_API_URL;

const styles = {
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        width: '80px',
        height: '80px'
    },
};


export class AddNewPage extends Component {

    constructor(props) {
        super(props); 
        this.state = {
            eventId: "",
            templateId: "",
            pageName: "",
            pageNameInUrl: "",
            eventDomain: "",
            pageTemplateId: "",
            errors: {}
        };
    }



    componentDidMount() {
        if (this.props.project && this.props.project.project) {
            this.setState({
                eventId: this.props.project.project._id,
                eventDomain: this.props.project.project.eventDomain
            });
            localStorage.setItem("eventId", this.props.project.project._id);
            localStorage.setItem("eventDomain", this.props.project.project.eventDomain);

            this.props.getProjectRequest(this.props.project.project._id);
        }
        else {
            this.props.getProjectRequest(localStorage.getItem("eventId"));
            this.setState({
                eventId: localStorage.getItem("eventId"),
                eventDomain: localStorage.getItem("eventDomain")
            });
        }
    }

    componentDidUpdate() {
    }

    onPageNameChange = (e) => {
        this.setState({
            pageName: e.target.value,
            pageNameInUrl: e.target.value.replace(/\s+/g, '').toLowerCase(),
        });
    };

    submitForm = async () => {
        const { eventId, pageName, pageNameInUrl, pageTemplateId } = this.state;
        let error = {};
        let regexForPageName = /[*|\":<>[\]{}`\\()';@&$]/;
        const body = {
            eventId,
            pageName,
            pageUrl: pageNameInUrl,
        };
        if (pageName.trim() !== "" && pageName !== undefined) {
            if (regexForPageName.test(pageName) === true ){
                error['blankPageName'] = 'Please enter a valid page name';
            }else{
            const response = await axios.post(`${URL}/checkProjectPage`, body, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                if (response.data.message === "Page Available." && response.data.success === true) {
                    this.props.navigate(`/select-page-template/pid/${this.state.eventId}/pn/${this.state.pageName}/purl/${this.state.pageNameInUrl}`, { state: { lngCode: this.props.users.newLangState } });
                } else if (!response.data.success) {
                    createNotification("error", response.data.message);
                }
            } else {
                createNotification("error", "Something Went Wrong.");
            }
            }
        }
        else {
            error['blankPageName'] = "Please enter the page name";
        }
        this.setState({
            errors: error
        });
    };

    
    render() {
        const { pageName, pageNameInUrl } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <div className="dashboardMainPage">
                    <Sidebar />
                    <div className="dashboardContentArea">
                        <Box my={{ xs: 3, md: 4 }} mx={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 7.5 }} className="">
                            <Grid container spacing={2} mb={12}>
                                <Grid >
                                    <Link to={`/project-dashboard/manage-pages/${this.state.eventId}`}>
                                        <Box component={Button} className='back-button' variant="outlined">
                                            <img alt="" src={BackArrow} />
                                        </Box>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justifyContent={"center"}>
                                <Grid size={{xs: "grow", sm:9, md:7, lg:5, xl:4}} >
                                    <Box mb={3}>
                                        <Avatar style={styles.avatar} >
                                            <img alt="" src={PageIcon} />
                                        </Avatar>
                                    </Box>
                                    <Box component={'p'} mb={4} className='heading'>
                                    {this.props.users.languageStateForRedux?.actions?.managePages?.newPageHeading}
                                    </Box>
                                    <Box component="div" mb={4} className='inputField'>
                                        <label>{this.props.users.languageStateForRedux?.actions?.managePages?.newPageSubhead}<span className='required'>*</span>
                                            <Tooltip title={this.props.users.languageStateForRedux?.validations?.addNewPage} arrow placement="right">
                                                <img style={{display: "inline", marginLeft: "5px"}} src={InfoIcon} alt="info"/>     
                                            </Tooltip>
                                        </label>

                                        <TextField fullWidth variant="outlined" value={pageName} onChange={(e) => this.onPageNameChange(e)} />
                                        <span className="error_mesage"> {this.state.errors['blankPageName']} </span>
                                    </Box>

                                    <Box component="div" mb={4} className='inputField'>
                                        <Tooltip title={window.location.protocol +

                                            '//' +

                                            this.state.eventDomain +

                                            '.' +


                                            getHostName() +

                                            '/'
                                            +  pageNameInUrl
                                            } arrow placement='bottom'>
                                            <OutlinedInput
                                                notched={false}
                                                size="medium"
                                                fullWidth
                                                id="outlined-adornment-amount"
                                                value={pageNameInUrl}
                                                onChange={() => { }}
                                                style={{ paddingLeft: "0" }}
                                                startAdornment={

                                                    <Box className='paragraph long-url'
                                                        style={{
                                                            backgroundColor: '#EDECF5',
                                                            padding: '4px 13px',
                                                            borderRadius: '4px',
                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {window.location.protocol +

                                                            '//' +

                                                            this.state.eventDomain +

                                                            '.' +


                                                            getHostName() +

                                                            '/'}
                                                    </Box>

                                                }
                                            />
                                        </Tooltip>
                                    </Box>
                                    <Box component="div" className='inputField'>
                                        <Button onClick={() => this.submitForm()} fullWidth size='large' variant="contained" color="primary">
                                        {this.props.users.languageStateForRedux?.buttons?.managePages?.createPage} <Box component={'img'} ml={1.5} alt="DoubleArrow" src={DoubleArrow} />
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <NotificationContainer />

                </div>
            </ThemeProvider>
        );
    }
}


const mapStateToProps = state => ({
    project: state.Projects,
    pageInfo: state.ManagePage,
    events: state.Events,
    users: state.Users,
});

const mapActionsToProps = dispatch =>
    bindActionCreators(
        {
            getProjectRequest: Actions.getProjectRequest,
            addPageAndPageUrl: addPageAndPageUrl

        },
        dispatch
    );
export default connect(mapStateToProps, mapActionsToProps)(AddNewPage);

import React, { Component } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
export class PersonalInfoInput extends Component {
    render() {
        const {label, value, handleChangeValue, name, type, disabled} = this.props
        return (
            <>
                <Grid size={{xs: 12, md:12}}>
                    <Box component="div" className="inputField">
                        <label>
                            {label}
                            <span className="required">*</span>
                        </label>
                        <input
                            type={type}
                            className="form-control small"
                            placeholder=""
                            value={value}
                            onChange={handleChangeValue}
                            name={name}
                            disabled={disabled ? true : false}
                        />
                    </Box>
                </Grid>
            </>
        )
    }
}

export default PersonalInfoInput
//@ts-nocheck
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CustomSwitch from 'Components/customSwitch';
import SearchIcon from '../../../src/assets/images/icons/search.svg';
import MoreIcon from '../../../src/assets/images/icons/more.svg';
import {
  AIChatbotState,
  ChatBot,
  ProjectId,
} from 'ReduxStore/Reducers/aiChatbot.reducer';
import AiChatbotDashboardLayout from './AiChatbotDashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import moment from 'moment';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import { useParams, useNavigate } from 'react-router-dom'; // updated
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import EmbedCodePopUp from './EmbedCodePopUp';
import { getSystemLevelPermissions } from './../../helpers/common';
import { AllEventsForAiAgentWithPagination } from 'ReduxStore/API';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';

type ChatWidgetListProps = {
  userId: string;
  chatbotsList: ChatBot[];
};

type SortBy = 'name' | 'isEnabled' | 'updatedAt';
type SortOrder = 'asc' | 'desc';
type Project = {
  _id: string;
  eventName: string;
  assignedToChatbot?: string;
};
type ProjectsList = Project[];

type GetProjectsParams = {
  page_no: number;
  limit: number;
  searchText: string;
};

const limitForEventsList: number = 20;

const ChatWidgetList = ({ userId, chatbotsList }: ChatWidgetListProps) => {
  const {
    page_no,
    limit,
    totalChatBotCount,
    createOrUpdateAiAgentGeneralDataSuccess,
    listAllChatbotsSuccess,
    deleteChatbotSuccess,
    enableChatbotSuccess,
    setChatbotAsDefaultSuccess,
  } = useSelector((store: any) => store.AIChatbot) as AIChatbotState;

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedAgentId, setSelectedAgentId] = useState<string>('');
  const [idToEdit, setIdToEdit] = useState<string | undefined>('');
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  const [searchTextTemp, setSearchTextTemp] = useState<string>('');
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy>('updatedAt');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [embbedCode, setEmbbedCode] = useState<string>('');
  const [openEmbbedCodePopup, setOpenEmbbedCodePopup] = React.useState(false);
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(0);
  const [allowed, setAllowed] = useState<any>({});
  const [listOfSelectedProjectId, setListOfSelectedProjectId] = useState<string[]>([]);

  const [projectsList, setProjectsList] = useState<ProjectsList>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextForProjects, setSearchTextForProjects] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const dispatch = useDispatch();

  const navigate = useNavigate(); // updated to useNavigate
  const params = useParams(); // updated

  useEffect(() => {
    setAllowed(getSystemLevelPermissions());
    if (params.id) {
      setIdToEdit(params.id);
      setOpenEdit(true);
      navigate(`/aichatbot/general/${params.id}`);
    }
  }, []);

  useEffect(() => {
    listAllChatbots();
  }, [listOfSelectedProjectId]);

  useEffect(() => {
    if (createOrUpdateAiAgentGeneralDataSuccess) {
      handleEndCount();
    }
  }, [createOrUpdateAiAgentGeneralDataSuccess]);

  useEffect(() => {
    if (listAllChatbotsSuccess) {
      handleEndCount();
      !searchTextTemp && !chatbotsList?.length && handleOpenCreateChatWidget();
    }
  }, [listAllChatbotsSuccess]);

  useEffect(() => {
    if (deleteChatbotSuccess) {
      handleEndCount();
    }
  }, [deleteChatbotSuccess]);

  useEffect(() => {
    if (enableChatbotSuccess) {
      handleEndCount();
    }
  }, [enableChatbotSuccess]);

  useEffect(() => {
    if (setChatbotAsDefaultSuccess) {
      handleEndCount();
    }
  }, [setChatbotAsDefaultSuccess]);

  const handleEndCount = () => {
    handleEndCountForPagination(totalChatBotCount, limit, page_no, setEndCount);
  };

  const handleOpenEditPage = (id: string | undefined) => {
    setIdToEdit(id);
    setOpenEdit(true);
    navigate(`/aichatbot/general/${id}`);
  };

  const handleOpenCreateChatWidget = () => {
    setOpenEdit(true);
    setIdToEdit('');
    navigate(`/aichatbot/general`);
  };

  const handleDeleteChatWidget = async () => {
    const data = {
      bodyParams: {
        agentId: idToDelete,
      },
      queryParams: {
        page_no,
        limit,
        searchText,
      },
    };
    dispatch(Actions.deleteChatbotRequest(data));
    closeDeleteConfirmationModel();
  };

  const handleEnableChatWidget = async (
    e: ChangeEvent<HTMLInputElement>,
    agentId: string | undefined,
  ) => {
    const data = {
      bodyParams: {
        agentId,
        isEnabled: e.target.checked,
      },
      queryParams: {
        page_no,
        limit,
        searchText,
      },
    };
    dispatch(Actions.enableChatbotRequest(data));
  };

  const handleDefaultChatWidget = async (agentId: string | undefined) => {
    const data = {
      bodyParams: {
        agentId,
      },
      queryParams: {
        page_no,
        limit,
        searchText,
      },
    };
    dispatch(Actions.setChatbotAsDefaultRequest(data));
  };

  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    setPage_no(1); // pagination
    setStartCount(1); // pagination
    setSearchText(searchTextTemp);
    e.preventDefault();
    listAllChatbots();
  };

  const listAllChatbots = (
    limitFromPagination?: number,
    pageNumFromPagination?: number,
    sortByArg?: SortBy,
    sortOrderArg?: SortOrder,
  ) => {
    const queryParams = {
      page_no: pageNumFromPagination ? pageNumFromPagination : 1,
      limit: limitFromPagination ? limitFromPagination : limit,
      searchText: searchTextTemp,
      sortBy: sortByArg,
      sortOrder: sortOrderArg,
    };
    const bodyParams = {
      filterBy: [...listOfSelectedProjectId],
    };

    const data = {
      queryParams,
      bodyParams,
    };
    dispatch(Actions.listAllChatbotsRequest(data));
  };

  const setPage_no = (num: number) => {
    dispatch(Actions.setPageNo(num));
  };
  const setLimit = (num: number) => {
    dispatch(Actions.setLimit(num));
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = (chatBotId: string | undefined) => {
    setIdToDelete(chatBotId);
    setOpenDeleteConfirmationModel(true);
  };

  const handleSorting = (sort_by: SortBy) => {
    setSortBy(sort_by);
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    listAllChatbots(
      undefined,
      undefined,
      sort_by,
      sortOrder === 'asc' ? 'desc' : 'asc',
    );
  };

  const handleReplicateChatWidget = (agentId: string | undefined) => {
    const queryParams = {
      page_no,
      limit,
      chatBotId: agentId,
    };

    dispatch(Actions.replicateChatWidgetRequest(queryParams));
  };

  const handleEmbedOnSite = (agentId: string | undefined) => {
    agentId && setSelectedAgentId(agentId);
    setOpenEmbbedCodePopup(true);
  };

  const handleCloseEmbbedCodePopup = () => {
    setOpenEmbbedCodePopup(false);
  };

  const showRestOfPages = (projectId: ProjectId[]) => {
    return projectId?.slice(1, projectId.length);
  };

  const handleProjectCheckbox = (option: any, checked: boolean) => {
    let arr: string[] = [...listOfSelectedProjectId];
    if (checked) {
      arr.push(option._id);
    } else {
      arr = arr.filter((el) => el !== option._id);
    }
    setListOfSelectedProjectId(arr);
  };

  const handleClearAll = (checked: boolean) => {
    let arr: string[] = [...listOfSelectedProjectId];
    if (checked) {
      setListOfSelectedProjectId([]);
    }
  };

  //for CustomSearchableMultiSelectedDropdownWithLoadMore starts
  useEffect(() => {
    const params = {
      page_no: pageNo,
      limit: limitForEventsList,
      searchText: searchTextForProjects,
    };
    getProjectList(params);
  }, [pageNo]);

  useEffect(() => {
    setPageNo(1);
    const params = {
      page_no: 1,
      limit: limitForEventsList,
      searchText: searchTextForProjects,
    };
    const timeout = setTimeout(() => {
      getProjectList(params);
    }, 400);
    return () => clearTimeout(timeout);
  }, [searchTextForProjects]);

  const getProjectList = async (params: GetProjectsParams) => {
    try {
      const result: any = await AllEventsForAiAgentWithPagination(params);
      const projects: ProjectsList = result.data.events;
      if (projects.length < result.data.total) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = () => {
    setPageNo((prev) => prev + 1);
  };

  const handleSearchListInSearchableDropdownForProjects = (text: any) => {
    setSearchTextForProjects(text);
  };
  //for CustomSearchableMultiSelectedDropdownWithLoadMore ends

  return !openEdit ? (
    <div className="chat-widget-list">
      <Grid size={{xs: 6,}}></Grid>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        className="top-filter-area"
        mb={4}
      >
        <Grid size={{xs: "grow",}}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid  className="heading">
              Chat Widget
            </Grid>
            <Grid size={{xs: "grow",}}>
              <CustomSearchableMultiSelectedDropdownWithLoadMore
                options={projectsList}
                handleChange={handleProjectCheckbox}
                handleClearAll={handleClearAll}
                handleAllCheckbox={() => {}}
                handleSearchListInSearchableDropdown={
                  handleSearchListInSearchableDropdownForProjects
                }
                listOfSelectedIds={listOfSelectedProjectId}
                dropdownFor="projectsChatWidgetList"
                handlePageChange={handlePageChange}
                disabled={disabledButton}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid >
          <Grid container spacing={1.25} className="team-dash-right">
            <form onSubmit={handleSearch}>
              <Grid  className="">
                <TextField
                  name="searchText"
                  variant='standard'
                  placeholder="Search..."
                  className="search"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={SearchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  value={searchTextTemp}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchTextTemp(e.target.value);
                  }}
                />
              </Grid>
            </form>
            <Grid >
              {allowed['createChatWidget'] && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenCreateChatWidget}
                >
                  Create chat widget
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer
        className="list-table"
        style={{ maxHeight: 'calc(100vh - 200px)' }}
      >
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ minWidth: 300 }}
                className={
                  'long-text ' +
                  (sortBy === 'name'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('name')}
              >
                Name
              </TableCell>
              <TableCell
                className={
                  'long-text ' +
                  (sortBy === 'name'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                // onClick={() => handleSorting('project')}
              >
                Project
              </TableCell>
              <TableCell
                className={
                  'date-time ' +
                  (sortBy === 'updatedAt'
                    ? sortOrder === 'asc'
                      ? 'sort-asc'
                      : 'sort-des'
                    : '')
                }
                onClick={() => handleSorting('updatedAt')}
              >
                Modified on
              </TableCell>
              <TableCell className="options">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chatbotsList?.map((chatbot) => {
              return (
                <TableRow key={chatbot._id}>
                  {allowed['editChatWidget'] ? (
                    <TableCell
                      style={{ minWidth: 300 }}
                      className="long-text link"
                      onClick={() => handleOpenEditPage(chatbot._id)}
                    >
                      {chatbot.agent.name}
                    </TableCell>
                  ) : (
                    <TableCell style={{ minWidth: 300 }} className="long-text">
                      {chatbot.agent.name}
                    </TableCell>
                  )}
                  <TableCell className="long-text">
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                      <span className="long-text" style={{ maxWidth: '80%' }}>
                        {' '}
                        {(chatbot.projectIds &&
                          Array.isArray(chatbot.projectIds) &&
                          // @ts-ignore
                          (chatbot.projectIds[0]?.eventName as string)) ||
                          '-'}
                      </span>
                      {chatbot.projectIds?.length > 1 && (
                        <div className="detail-popup">
                          <span className="count">
                            +{chatbot.projectIds?.length - 1}
                          </span>
                          <div className="info">
                            {showRestOfPages(chatbot.projectIds)?.map(
                              (projectId: any, i: number) => {
                                return (
                                  <div key={i} style={{ zIndex: 999999 }}>
                                    <p>{projectId?.eventName}</p>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell className="date-time">
                    <>
                      {moment(chatbot.updatedAt).format('MMM, Do YYYY, h:mm A')}
                    </>
                  </TableCell>
                  <TableCell className="options">
                    {' '}
                    <div className="options-button">
                      <img alt="" src={MoreIcon} />{' '}
                    </div>
                    <div className="options-list">
                      {allowed['editChatWidget'] && (
                        <div onClick={() => handleOpenEditPage(chatbot._id)}>
                          Edit
                        </div>
                      )}
                      <hr />
                      {allowed['deleteChatWidget'] && (
                        <div
                          onClick={() =>
                            handleOpenDeleteConfirmationModel(chatbot._id)
                          }
                          style={{ color: '#ff4170' }}
                        >
                          Delete
                        </div>
                      )}
                      <div onClick={() => handleDefaultChatWidget(chatbot._id)}>
                        Set Default
                      </div>
                      {allowed['createChatWidget'] && (
                        <div
                          onClick={() => handleReplicateChatWidget(chatbot._id)}
                        >
                          Replicate
                        </div>
                      )}
                      {allowed['embedOnSide'] && (
                        <div onClick={() => handleEmbedOnSite(chatbot._id)}>
                          Embed On Site
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
            {chatbotsList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No Records
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table>
          <TableFooter>
            <TableRow>
              <PaginationFunctional
                startCount={startCount}
                setStartCount={setStartCount}
                endCount={endCount}
                limit={limit}
                setLimit={setLimit}
                page_no={page_no}
                setPage_no={setPage_no}
                totalItemsCount={totalChatBotCount}
                listItemsFn={listAllChatbots}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={handleDeleteChatWidget}
      />
      {openEmbbedCodePopup && selectedAgentId && (
        <EmbedCodePopUp
          open={openEmbbedCodePopup}
          handleClose={handleCloseEmbbedCodePopup}
          agentId={selectedAgentId}
        />
      )}
    </div>
  ) : (
    <AiChatbotDashboardLayout
      userId={userId}
      agentId={idToEdit}
      setOpenEdit={setOpenEdit}
      setIdToEdit={setIdToEdit}
      searchText={searchText}
    />
  );
};

export default ChatWidgetList;

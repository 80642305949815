import React, { Component, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import Button from '@mui/material/Button';
// import '../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import copyKey from '../../../src/assets/images/icons/copyKey.svg';
import refreshKey from '../../../src/assets/images/icons/refreshKey.svg';

import CustomSwitch from 'Components/customSwitch';
import { IconButton } from '@mui/material';
import { Actions } from 'ReduxStore/Actions';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment';
import Loader from 'Components/Loader/loader';

export default function ApiKey(){

    const [apiKeyEnabled, setApiKeyEnabled] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [apiKeyUpdatedAt, setApiKeyUpdatedAt] = useState('')
    const dispatch = useDispatch()
    const {
        getApiKeySuccess, 
        apiKeyData, 
        generateApiKeySuccess,
        loading
    } = useSelector((state)=>state.ApiKey)

    /**
     * for getting api key if already generated
     */
    useEffect(() => {
        getApiKey()
    }, [])

    const getApiKey = () => {
        dispatch(Actions.getApiKeyRequest())
    }   
    
    useEffect(() => {        
        if(apiKeyData.apiKey){
            setApiKeyEnabled(apiKeyData.activeStatus)
            setApiKey(apiKeyData.apiKey)
            setApiKeyUpdatedAt(apiKeyData.apiKeyUpdatedAt)
        }
    }, [getApiKeySuccess])
    

    /**
     * for generate or disable api key
     */
    const handleToggle = (e) => {
        if (e.target.checked) {
            setApiKeyEnabled(true)
        }else{
            setApiKeyEnabled(false)
        }
        dispatch(Actions.generateApiKeyRequest({activeStatus: e.target.checked}))
    }

    /**
     * for regenerate api key
     */
    const regenerateApiKey = (e) => {
        dispatch(Actions.generateApiKeyRequest({regenerate: true}))
    }

    useEffect(() => {        
        if(apiKeyData.apiKey){
            setApiKey(apiKeyData.apiKey)
            setApiKeyUpdatedAt(apiKeyData.apiKeyUpdatedAt)
        }
    }, [generateApiKeySuccess])    
    
    return (
        <ThemeProvider theme={theme}>
            {loading ? <Loader /> : null}
            <Box pb={3} pt={2.5} mb={1} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
                <Grid container spacing={2}>
                    <Grid size={{xs: "grow",}}>
                        <Box component="h4" className='subsecondary' sx={{ marginRight: 50, marginBottom: 0, }}>API Key</Box>
                    </Grid>

                </Grid>
            </Box>
            <Box
                pb={3}
                mt={2}
                sx={{
                    borderBottom: '1px solid #EDECF5',
                }}
                className='api-keys'
            >
                <Stack spacing={2} direction={'row'} alignItems={'center'}>
                    <Box className="secondary" mb={1}>
                        Generate the API key
                    </Box>
                    <Box>
                        <CustomSwitch 
                            color={'primary'} 
                            onChange={handleToggle}
                            checked={apiKeyEnabled}
                        />
                    </Box>
                </Stack>
                <Box className="paragraph text-primary-light">
                    Leverage API keys for secure access to third-party data, facilitating seamless integration and connectivity.
                </Box>
            </Box>
            {apiKey !== "" &&
                <Box
                    sx={{
                        borderBottom: '1px solid #EDECF5',
                    }}
                    pb={2}
                    mt={2}
                    mb={3}
                >
                    <Stack
                        spacing={2}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            <Box className="small-subtitle">API Key</Box>
                            <CopyToClipboard text={apiKey}>
                            <Button
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: theme.palette.background.light,
                                    color: '#000056',
                                    textTransform: 'none',
                                    fontWeight: '400',
                                }}
                                endIcon={<img alt="" src={copyKey} />}
                            >
                                {apiKey}
                            </Button>
                            </CopyToClipboard>
                            <IconButton 
                                size="small"
                                onClick={regenerateApiKey}
                            >
                                <img alt="" src={refreshKey} />
                            </IconButton>
                        </Stack>
                        <Box>
                            <span className="small-subtitle">
                                Last Generated on:&nbsp;
                                <span className="primary-text">
                                    {moment(apiKeyUpdatedAt).format("dddd, MMM, Do YYYY, h:mm A")}
                                </span>
                            </span>
                        </Box>
                    </Stack>
                </Box>
            }

        </ThemeProvider>
    )
}


// class ApiKeys extends Component {


    // render() {
    //     return (
    //         <ThemeProvider theme={theme}>
    //             <Box pb={3} pt={2.5} mb={1} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
    //                 <Grid container spacing={2}>
    //                     <Grid size={{xs: "grow",}}>
    //                         <Box component="h4" className='subsecondary' sx={{ marginRight: 50, marginBottom: 0, }}>API Key</Box>
    //                     </Grid>

    //                 </Grid>
    //             </Box>
    //             <Box
    //                 pb={3}
    //                 mt={2}
    //                 sx={{
    //                     borderBottom: '1px solid #EDECF5',
    //                 }}
    //                 className='api-keys'
    //             >
    //                 <Stack spacing={2} direction={'row'} alignItems={'center'}>
    //                     <Box className="secondary" mb={1}>
    //                         Generate the API key
    //                     </Box>
    //                     <Box>
    //                         <CustomSwitch color={'primary'} />
    //                     </Box>
    //                 </Stack>
    //                 <Box className="paragraph text-primary-light">
    //                     Leverage API keys for secure access to third-party data, facilitating seamless integration and connectivity.
    //                 </Box>
    //             </Box>
    //             <Box
    //                 sx={{
    //                     borderBottom: '1px solid #EDECF5',
    //                 }}
    //                 pb={2}
    //                 mt={2}
    //                 mb={3}
    //             >
    //                 <Stack
    //                     spacing={2}
    //                     direction={'row'}
    //                     justifyContent={'space-between'}
    //                     alignItems={'center'}
    //                 >
    //                     <Stack spacing={2} direction={'row'} alignItems={'center'}>
    //                         <Box className="small-subtitle">API Key</Box>
    //                         <Button
    //                             size="small"
    //                             variant="contained"
    //                             style={{
    //                                 backgroundColor: theme.palette.background.light,
    //                                 color: '#000056',
    //                                 textTransform: 'none',
    //                                 fontWeight: '400',
    //                             }}
    //                             endIcon={<img alt="" src={copyKey} />}
    //                         >
    //                             cv6b5cb6cx65b65xvx646vcnmg46c46Sdf489zsdv4x6vzx3
    //                         </Button>
    //                         <IconButton size="small">
    //                             <img alt="" src={refreshKey} />
    //                         </IconButton>
    //                     </Stack>
    //                     <Box>
    //                         <span className="small-subtitle">
    //                             Last Generated on:&nbsp;
    //                             <span className="primary-text">
    //                                 Friday, Sep, 17th 2021, 4:15 PM
    //                             </span>
    //                         </span>
    //                     </Box>
    //                 </Stack>
    //             </Box>

    //         </ThemeProvider>
    //     );
    // }
// }


// export default (withRouter(ApiKeys));
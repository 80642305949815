import { useState, useEffect } from 'react';
// import '../../../../../../sass/main.scss';
import Box from '@mui/material/Box';
import { ProjectsList } from '../../GeneralDashboard';
import { useParams } from 'react-router-dom'; // Updated for react-router-dom 6.27.0
import { ProjectId } from 'ReduxStore/Reducers/aiChatbot.reducer';
import Grid from '@mui/material/Grid2';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';
import { AllEventsForAiAgentWithPagination } from 'ReduxStore/API';

type ProjectAccessProps = {
  addProjectsToState: (arr: ProjectId[]) => void;
  alreadySelectedProjectIds: ProjectId[];
};

type GetProjectsParams = {
  page_no: number;
  limit: number;
  searchText: string;
};

const limitForEventsList: number = 20;

function ProjectAccess({
  addProjectsToState,
  alreadySelectedProjectIds,
}: ProjectAccessProps) {
  // Ensure useParams has the correct typing for id
  const params = useParams<{ id: string }>();

  const [listOfSelectedProjectId, setListOfSelectedProjectId] = useState<string[]>(
    []
  );
  const [listOfSelectedProjectName, setListOfSelectedProjectName] = useState<string[]>(
    []
  );

  // for searchableDropdownWithLoadMore
  const [projectsList, setProjectsList] = useState<ProjectsList>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(1);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  useEffect(() => {
    setListOfSelectedProjectId(alreadySelectedProjectIds as string[]);
    let arr: string[] = [];
    projectsList.forEach((project) => {
      alreadySelectedProjectIds.includes(project._id) && arr.push(project.eventName);
    });
    setListOfSelectedProjectName(arr);
  }, [alreadySelectedProjectIds, projectsList]);

  const handleProjectCheckbox = (option: any, checked: boolean) => {
    let arr = [...listOfSelectedProjectId];
    let arr2 = [...listOfSelectedProjectName];
    if (checked) {
      arr.push(option._id);
      arr2.push(option.eventName);
    } else {
      arr = arr.filter((el) => el !== option._id);
      arr2 = arr2.filter((el) => el !== option.eventName);
    }
    setListOfSelectedProjectId(arr);
    setListOfSelectedProjectName(arr2);
    addProjectsToState(arr);
  };

  const handleAllCheckbox = (checked: boolean) => {
    if (checked) {
      let allProjectIds: (string | false)[] = projectsList.map(
        (el) => (!el.assignedToChatbot || el.assignedToChatbot === params?.id) && el._id
      );
      allProjectIds = allProjectIds.filter((el) => el !== false);
      setListOfSelectedProjectId(allProjectIds as string[]);
      addProjectsToState(allProjectIds as ProjectId[]);
    } else {
      setListOfSelectedProjectId([]);
      addProjectsToState([]);
    }
  };

  // for CustomSearchableMultiSelectedDropdownWithLoadMore starts
  useEffect(() => {
    const params = {
      page_no: pageNo,
      limit: limitForEventsList,
      searchText,
    };
    getProjectList(params);
  }, [pageNo, searchText]);

  const getProjectList = async (params: GetProjectsParams) => {
    try {
      const result: any = await AllEventsForAiAgentWithPagination(params);
      const projects: ProjectsList = result.data.events;
      if (projects.length < result.data.total) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setProjectsList(projects);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = () => {
    setPageNo((prev) => prev + 1);
  };

  const handleSearchListInSearchableDropdownForProjects = (text: string) => {
    setSearchText(text);
  };

  return (
    <Box className="tab-content">
      <Box className="tab-title">Project Access</Box>
      <Box>
        <Box
          component="div"
          style={{
            position: 'relative',
          }}
          pb={3.6}
          className="inputField"
        >
          <label>Select Projects</label>

          {projectsList?.length > 0 && (
            <Grid size={{xs: 6,}}>
              <CustomSearchableMultiSelectedDropdownWithLoadMore
                options={projectsList}
                handleChange={handleProjectCheckbox}
                handleAllCheckbox={handleAllCheckbox}
                handleClearAll={() => {}}
                handleSearchListInSearchableDropdown={
                  handleSearchListInSearchableDropdownForProjects
                }
                listOfSelectedIds={listOfSelectedProjectId}
                dropdownFor="projects"
                handlePageChange={handlePageChange}
                disabled={disabledButton}
              />
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ProjectAccess;

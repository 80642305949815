import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityOpportunitiesAPI from 'ReduxStore/API/opportunity/opportunityOpportunitiesAPI';
import { removeStorage } from 'helpers/common';

function* fetchAllOpportunityOpportunities(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.fetchAllOpportunityOpportunities, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.fetchAllOpportunityOpportunitiesSuccess(
            response.data.opportunities,
            response.data.totalCount,
            response.data.filterCount,
            response.data.contactsObj,
            response.data.accountsObj,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchAllOpportunityOpportunitiesError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.fetchAllOpportunityOpportunitiesError(response.data.message));
      }
    } else {
      yield put(Actions.fetchAllOpportunityOpportunitiesError(response.data.message));
    }
  }

function* fetchOpportunityOpportunity(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.fetchOpportunityOpportunity, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.fetchOpportunityOpportunitySuccess(
            response.data.opportunity,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchOpportunityOpportunityError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.fetchOpportunityOpportunityError(response.data.message));
      }
    } else {
      yield put(Actions.fetchOpportunityOpportunityError(response.data.message));
    }
  }

function* createOpportunityOpportunity(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.createOpportunityOpportunity, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.createOpportunityOpportunitySuccess(
            response.data.opportunity,
            response.data.opportunities,
            response.data.totalCount,
            response.data.filterCount,
            response.data.contactsObj,
            response.data.accountsObj,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.createOpportunityOpportunityError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.createOpportunityOpportunityError(response.data.message));
      }
    } else {
      yield put(Actions.createOpportunityOpportunityError(response.data.message));
    }
  }

  function* updateOpportunityOpportunity(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.updateOpportunityOpportunity, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.updateOpportunityOpportunitySuccess(
            response.data.updatedOpportunity,
            response.data.opportunities,
            response.data.totalCount,
            response.data.filterCount,
            response.data.contactsObj,
            response.data.accountsObj,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.updateOpportunityOpportunityError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.updateOpportunityOpportunityError(response.data.message));
      }
    } else {
      yield put(Actions.updateOpportunityOpportunityError(response.data.message));
    }
  }

  function* deleteOpportunityOpportunity(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.deleteOpportunityOpportunity, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.deleteOpportunityOpportunitySuccess(
            response.data.opportunities,
            response.data.totalCount,
            response.data.filterCount,
            response.data.contactsObj,
            response.data.accountsObj,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.deleteOpportunityOpportunityError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.deleteOpportunityOpportunityError(response.data.message));
      }
    } else {
      yield put(Actions.deleteOpportunityOpportunityError(response.data.message));
    }
  }

  function* updateMultipleOpportunityOpportunities(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.updateMultipleOpportunityOpportunities, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.updateMultipleOpportunityOpportunitiesSuccess(
            response.data.opportunities,
            response.data.totalCount,
            response.data.filterCount,
            response.data.contactsObj,
            response.data.accountsObj,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.updateMultipleOpportunityOpportunitiesError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.updateMultipleOpportunityOpportunitiesError(response.data.message));
      }
    } else {
      yield put(Actions.updateMultipleOpportunityOpportunitiesError(response.data.message));
    }
  }

  function* listTransactionEmail(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.listTransactionEmail, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.listTransactionEmailSuccess(
            response.data.templates,
            response.data.message,
          ),
        );
      } else {
        yield put(Actions.listTransactionEmailError(response.data.message));
      }
    } else {
      yield put(Actions.listTransactionEmailError(response.data.message));
    }
  }

  function* updateTransactionEmail(action) {
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    const response = yield call(opportunityOpportunitiesAPI.updateTransactionEmail, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.updateTransactionEmailSuccess(
            response.data.message,
          ),
        );
      } else {
        yield put(Actions.updateTransactionEmailError(response.data.message));
      }
    } else {
      yield put(Actions.updateTransactionEmailError(response.data.message));
    }
  }

const opportunityOpportunitiesSaga = {
    fetchAllOpportunityOpportunities,
    fetchOpportunityOpportunity,
    createOpportunityOpportunity,
    updateOpportunityOpportunity,
    deleteOpportunityOpportunity,
    updateMultipleOpportunityOpportunities,
    listTransactionEmail,
    updateTransactionEmail
}

export default opportunityOpportunitiesSaga
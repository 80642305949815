import React, { useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from 'Components/Loader/loader';

const URL = process.env.REACT_APP_API_URL;

function CreateProjectStep1(props) {
  const [projectTypesLoaded, setProjectTypesLoaded] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeClass, setActiveClass] = React.useState({});
  const classes = ["color-1", "color-2", "color-3", "color-4", "color-5", "color-6"];
  const icons = ["icon icon1", "icon icon2", "icon icon3", "icon icon4", "icon icon5", "icon icon6"];
  const [isProjectTypesFetched, setIsProjectTypesFetched] = React.useState(false);
  const handleProjectType = async (params) => {
    await props.projectTypeParams(params);
    props.handleDoContinue(true);
    props.handleProjectType(params.title);
    setActiveClass({ [params._id]: "active" });
  };

  const fetchProjectTypes = async () => {
    async function getProjectTypes() {
      const response = await axios.get(`${URL}/getProjectTypes`);
      return response.data.projectTypes;
    }
    const finalTypes = await getProjectTypes();
    getAllProjectTypes(finalTypes);
  };

  React.useEffect(() => {
    if (props?.servicePlan?.findIfAllowedFromSuperadminSuccess && !isProjectTypesFetched) {
      fetchProjectTypes();
      setIsProjectTypesFetched(true);
    }

  }, [props?.servicePlan?.findIfAllowedFromSuperadminSuccess]);

  useEffect(() => {
    setIsLoading(true);
    fetchProjectTypes();
  }, [props.users.languageStateForRedux]);
  const optionsList = (val) => {
    let temp;
    setIsLoading(false);
    switch (val) {
      case 'Sale enablement project': temp = props.users.languageStateForRedux?.projectTypes?.sales;
        return temp;

      case 'Sale Enablement Project': temp = props.users.languageStateForRedux?.projectTypes?.sales;
        return temp;

      case 'Registration': temp = props.users.languageStateForRedux?.projectTypes?.registration;
        return temp;

      case 'Hybrid Project': temp = props.users.languageStateForRedux?.projectTypes?.hybrid;
        return temp;

      case 'Email-Campaign': temp = props.users.languageStateForRedux?.projectTypes?.email;
        return temp;

      case 'In-Person Project': temp = props.users.languageStateForRedux?.projectTypes?.inPerson;
        return temp;

      case 'Virtual Event': temp = props.users.languageStateForRedux?.projectTypes?.virtual;
        return temp;

      default: temp = val;
        break;
    }
  };

  const optionsListTwo = (val) => {
    const substring = val.split(' ').at(2);
    let temp;
    setIsLoading(false);
    switch (substring) {
      case 'event': temp = props.users.languageStateForRedux?.newProject?.hybridProjectDesc;
        return temp;

      case 'platform': temp = props.users.languageStateForRedux?.newProject?.inPersonProjectDesc;
        return temp;

      case 're-use': temp = props.users.languageStateForRedux?.newProject?.salesProjectDesc;
        return temp;

      case 'comprehensive': temp = props.users.languageStateForRedux?.newProject?.registrationDesc;
        return temp;

      case 'email': temp = props.users.languageStateForRedux?.newProject?.emailCampProjectDesc;
        return temp;

      case 'and': temp = props.users.languageStateForRedux?.newProject?.virtualEventDesc;
        return temp;

      default: temp = val;
        break;
    }
  };


  const getAllProjectTypes = async (projectTypes) => {
    let projectTypesLoaded = await projectTypes.map((projectType, index) => {
      return returnProjectType(projectType, index);
    });
    setProjectTypesLoaded(projectTypesLoaded);
  };

  const returnProjectType = (projectType, index) => {

    let project = <Grid size={{xs: 12, sm:6, lg:4 }}key={index} onClick={() => handleProjectType(projectType)} className={'project-type '} >
      <Box p={{ xs: (1), sm: (2), lg: (3) }} className={'project-wrapper ' + (classes[index]) + ' ' + (activeClass[projectType._id])}>
        <div className={icons[index]}>  </div>
        <h3 className="subtitle"> {optionsList(projectType.title)} </h3>
        <p>{optionsListTwo(projectType.description)}</p>
      </Box>
    </Grid>;
    if (projectType.title === 'Hybrid Project') {
      if (props?.servicePlan?.featuredAllowed?.hybridProjects) {
        return project;
      }
    } else if (projectType.title === 'Virtual Event') {
      if (props?.servicePlan?.featuredAllowed?.virtualProjects) {
        return project;
      }
    } else {
      return project;
    }
  };

  return (
    <Box px={{ xs: (2), lg: (3) }} className="step1-outer stepper-outer-common">
      <div className="step1-heading">
        <h2 className="subsecondary"> {props.users.languageStateForRedux?.newProject?.heading} </h2>
      </div>
      <Box className="step1-block">
        <Grid className='project-type-wrapper' container spacing={{ xs: (2), lg: (3) }}>
          {projectTypesLoaded && projectTypesLoaded.length > 0 &&
            projectTypesLoaded
          }
        </Grid>
      </Box>
      {isLoading || props.users.isLoading ? <Loader /> : null}
    </Box>
  );
}

const mapStateToProps = state => ({
  servicePlan: state.ServicePlan,
  users: state.Users
});
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      handleDoContinue: Actions.handleDoContinueState,
      handleProjectType: Actions.handleProjectType,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(CreateProjectStep1);


import { Button } from '@mui/material'
import React, { Component } from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { EmailsAnalyticsInfoAPI } from 'ReduxStore/API'
import { createNotification, formatNumber } from '../../helpers'

class EmailPerformance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Email Performance',
      pageTitle: 'Email Performance',
      analyticsData: {},
    }
  }

  componentDidMount() {
    this.analyticsApiCall()
  }

  analyticsApiCall = () => {
    let requestbody = { eventId: '', templateId: '' }
    if (
       this.props.params.eventId &&
       this.props.params.eventId !== '' &&
      ! this.props.params.templateId
    ) {
      requestbody = { eventId:  this.props.params.eventId, templateId: '' }
    } else if (
       this.props.params.eventId &&
       this.props.params.eventId !== '' &&
       this.props.params.templateId &&
       this.props.params.templateId !== ''
    ) {
      requestbody = {
        eventId:  this.props.params.eventId,
        templateId:  this.props.params.templateId,
      }
    }

    EmailsAnalyticsInfoAPI(requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({ analyticsData: result.data.data })
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  render() {
    const { title, pageTitle, analyticsData } = this.state
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          <div className="eventAnalyticsPage">
            <div className="createProjectAccount projectStepRow mobileBaseMargin20px">
              <div className="projectCreateSection">
                {analyticsData && Object.keys(analyticsData).length > 0 && (
                  <div className="CreatePresentation">
                    {/* <div class="accountTypeTitle">
                                        <h1>Email Performance: </h1>
                                    </div> */}
                    <div className="performanceBoxeDigital">
                      <div className="EmailPcard CRCircularBox">
                        <h6>Sent Rate</h6>
                        <h2>
                          {analyticsData.sentRate &&
                            formatNumber(analyticsData.sentRate)}
                          %
                        </h2>
                      </div>
                      <div className="EmailPcard CRCircularBox">
                        <h6>Open Rate</h6>
                        <h2>
                          {analyticsData.openRate &&
                            formatNumber(analyticsData.openRate)}
                          %
                        </h2>
                      </div>
                      <div className="EmailPcard CRCircularBox">
                        <h6>Click Rate</h6>
                        <h2>
                          {analyticsData.clickRate &&
                            formatNumber(analyticsData.clickRate)}
                          %
                        </h2>
                      </div>
                    </div>
                    <div className="performanceListView">
                      <div className="performanceListCard">
                        <ul>
                          <li>
                            <span className="lsHeadingPV">Opens</span>
                            <span className="activeColored">
                              {analyticsData.open &&
                                formatNumber(analyticsData.open)}
                            </span>
                          </li>
                          <li>
                            <span className="lsHeadingPV">Sent</span>
                            <span className="activeColored">
                              {analyticsData.sent &&
                                formatNumber(analyticsData.sent)}
                            </span>
                          </li>
                          <li>
                            <span className="lsHeadingPV">Bounces</span>
                            <span className="activeColored">
                              {analyticsData.bounce &&
                                formatNumber(analyticsData.bounce)}
                            </span>
                          </li>
                          <li>
                            <span className="lsHeadingPV">
                              Successful Deliveries
                            </span>
                            <span className="activeColored">
                              {analyticsData.successSent &&
                                formatNumber(analyticsData.successSent)}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="performanceListCard">
                        <ul>
                          <li>
                            <span className="lsHeadingPV">Clicks</span>
                            <span className="activeColored">
                              {analyticsData.clicks &&
                                formatNumber(analyticsData.clicks)}
                            </span>
                          </li>
                          <li>
                            <span className="lsHeadingPV">Didn't Open</span>
                            <span className="activeColored">
                              {analyticsData.unOpen &&
                                formatNumber(analyticsData.unOpen)}
                            </span>
                          </li>
                          <li>
                            <span className="lsHeadingPV">Unsubscribed</span>
                            <span className="activeColored">Not Done</span>
                          </li>
                          <li>
                            <span className="lsHeadingPV">Spam Reports</span>
                            <span className="activeColored">
                              {analyticsData.spam &&
                                formatNumber(analyticsData.spam)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default EmailPerformance

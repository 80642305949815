import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import { AIChatbotState, ChatBot } from 'ReduxStore/Reducers/aiChatbot.reducer';
import { useParams } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Stack } from '@mui/material';

interface SourcesProps {
  agentId: string | undefined;
  // agentIdFromParent: string | undefined;
  handlleTrainAgentClick: () => void;
  isTrained: boolean;
  handleSetIsTrained: (isTrained: boolean) => void;
  saveAIChatAgent: (
    isTrainedFromChild?: boolean,
    savingBeforeTraining?: boolean,
  ) => void;
  lastTrainedAt: null | Date | string;
}

type SourceCalc = {
  numOfContextFiles: number;
  contextFilesChar: number;
  contextChar: number;
  numOfContextUrls: number;
  contextUrlsChar: number;
  numOfFAQs: number;
  FAQsChar: number;
  totalChar: number;
};

const Sources: FunctionComponent<SourcesProps> = (props: SourcesProps) => {

  const params = useParams<{ id?: string }>(); 
  const id = params.id;
  const isInitialRender = useRef(true);
  const isSavingAiChatAgentToTrain = useRef(false);
  const { chatBot, createOrUpdateAiAgentGeneralDataSuccess } = useSelector(
    (store: any) => store.AIChatbot,
  ) as AIChatbotState;
  const dispatch = useDispatch();
  const [sources, setSources] = useState<SourceCalc>({} as SourceCalc);

  useEffect(() => {
    if (!params.id) {
      dispatch(Actions.setChatBotToInitialState());
    }
  }, []);

  const trainAgent = () => {
    props.handleSetIsTrained(true);
    props.saveAIChatAgent(true, true);
    isSavingAiChatAgentToTrain.current = true;
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      if (isSavingAiChatAgentToTrain.current) {
        if (createOrUpdateAiAgentGeneralDataSuccess) {
          if (true || props.agentId) {
            dispatch(Actions.trainAgentRequest({ agentId: props.agentId }));
            isSavingAiChatAgentToTrain.current = false;
          }
        }
      }
    }
  }, [createOrUpdateAiAgentGeneralDataSuccess]);

  useEffect(() => {
    if (Object.keys(chatBot).length) {
      calcNumOfSources(chatBot);
    }
  }, [chatBot]);

  const calcNumOfSources = (chatBot: ChatBot) => {
    const { FAQs, context, contextFiles, contextUrls } = chatBot;
    const contextChar = context?.length;
    const numOfContextFiles = contextFiles?.length;
    const numOfFAQs = FAQs?.length;
    const numOfContextUrls = contextUrls?.length;
    let contextUrlsChar = 0;

    contextUrls?.forEach((url) => {
      contextUrlsChar += url.characterCount ? url.characterCount : 0;
    });

    let contextFilesChar = 0;
    contextFiles?.forEach((file) => {
      contextFilesChar += file?.characterCount ? file?.characterCount : 0;
    });
    let FAQsChar = 0;
    FAQs?.forEach((qa) => {
      FAQsChar += qa.answer.length;
      FAQsChar += qa.question.length;
    });

    const totalChar =
      contextChar + contextFilesChar + contextUrlsChar + FAQsChar;

    setSources({
      numOfContextFiles,
      contextFilesChar,
      contextChar,
      numOfContextUrls,
      contextUrlsChar,
      numOfFAQs,
      FAQsChar,
      totalChar,
    });
  };


  return (
    <Grid size={{xs: 12, md: 3, lg: 4, xl: 3}}>
      <Box className="sources">
        <Box className="paragraph" mb={4}>
          <strong>Sources</strong>
        </Box>
        <Box className="paragraph">
          {sources.numOfContextFiles}{' '}
          {`File${sources.numOfContextFiles > 1 ? 's' : ''}`} ({' '}
          {sources.contextFilesChar} Characters)
        </Box>
        <Box className="paragraph">
          Custom Info ( {sources.contextChar} Characters)
        </Box>
        <Box className="paragraph">
          {' '}
          {sources.numOfFAQs} Q&A ({sources.FAQsChar} Characters)
        </Box>
        <Box className="paragraph" mb={4}>
          {sources.numOfContextUrls} Links ( {sources.contextUrlsChar}{' '}
          Characters)
        </Box>

        <Box className="paragraph">Total Detected Characters</Box>
        <Box className="paragraph">
          <strong>{sources.totalChar}</strong>/4000,000 Limit
        </Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={trainAgent}
        disabled={!props.agentId ? true : false}
      >
        Train Agent
      </Button>
      <Box mt={2}>
        {
          props.lastTrainedAt !== null
            ? <span><strong>Last Trained Date:</strong> {moment(props.lastTrainedAt).format('MMM, Do YYYY, h:mm A')}</span>
            : ''
        }
      </Box>
    </Grid>
  );
};

export default Sources;

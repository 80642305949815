import React, { Component } from 'react'
import '../../sass/main.scss'
import EditingBack from '../../assets/images/backPage.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import footerLogo from '../../assets/images/footer__logo.png'
import {
  updateEventTemplate,
  createEventTemplate,
  getEventTemplate,
  EventEmailToUserAPI,
  getTemplateLib,
  createTemplateToLib,
  getEventTemplates,
} from 'ReduxStore/API'
import { Button } from '@mui/material'
import { createNotification, redirectTo } from '../../helpers'
import EmailEditor from 'react-email-editor'
import sample from './sample.json'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { NotificationContainer } from 'react-notifications'
import { emailTemplateJson } from '../CreateEvent/sampleEvent'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import {
  blankJson,
  cidV2Html,
  cidV1Html,
  cidP1Html,
  CidE2Html,
  countDownJson,
  CidP2Html,cid30PHtml,cid30EHtml,cid5julyP
   ,cid5julyE,cid12JulyE,cid12JultP,
  cid15JulyE,
  cid15JulyP,
  cid16JulyE,
  cid16JulyP,
  cid19JulyE,
  cid19JulyP
} from '../../Components/defaultTemplates'

import { cid21P,cid21E,cid20P,cid20E } from '../../Components/emailTemplates'
import { Modal } from 'react-responsive-modal'
import SentTestEmailModal from './sentTestEmailModal'
import * as qs from 'qs'
import ReactHtmlParser from 'react-html-parser'
import HTMLDecoderEncoder from 'html-encoder-decoder'

class EditTemplateDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageTitle: 'Edit Template Design',
      eventId: '',
      sample: {},
      loading: false,
      eventName: '',
      eventDomain: '',
      eventStartDate: '',
      eventEndDate: '',
      location: '',
      templateId: 0,
      template: [],
      mode: '',
      templateName: '',
      templateNameCheck: '',
      isActive: false,
      isSave: false,
      errors: {},
      email: '',
      open: false,
      subject: '',
      html: '',
      teamName: '',
    }
    this.emailEditorRef = null
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  componentDidMount() {
    window.addEventListener('popstate', (e) => {
      // Nope, go back to your page
      this.props.navigate.go(1)
    })

    var storage = localStorage.getItem('user_details')
    if (storage && storage !== 'undefined') {
      const user = JSON.parse(storage) 
      this.setState({
        userId: user._id,
      }) 
    } 
    if (
       this.props.params.eventId !== '' &&
       this.props.params.templateId &&
       this.props.params.mode
    ) {
      this.setState({
        eventId:  this.props.params.eventId,
        templateId:  this.props.params.templateId,
        mode:  this.props.params.mode,
      })

      this.props.eventRequest( this.props.params.eventId)

      if ( this.props.params.mode === 'new') {
        const template =  this.props.params.templateId
        if (template === 'saveTheDate') {
          this.setState({
            sample: countDownJson,
            templateName: 'Save the date',
            templateNameCheck: 'Save the date',
          })
        } else {
          this.setState({
            sample: blankJson,
            templateName: '',
            templateNameCheck: 'Blank',
          })
        }
      } else {
        getEventTemplate( this.props.params.templateId)
          .then((result) => {
            if (result.data.status === 200) {
              //createNotification('success', result.data.message);
              this.setState({
                sample: result.data.template.emailTemplateJson,
                templateName: result.data.template.templateName,
                templateNameCheck: result.data.template.templateName,
                isActive: result.data.template.isActive,
                isSave: true,
                emailSubject: result.data.template.emailSubject,
                teamName: result.data.template.teamName,
                html: HTMLDecoderEncoder.decode(
                  result.data.template.emailTemplate,
                ),
              })
            } else {
              createNotification('error', result.data.message)
            }
          })
          .catch(console.log)
      }
    }

    const search = qs.parse(get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    })

    if (search && search.chooseTempId !== 'blank') {
      getTemplateLib({})
        .then(async (result) => {
          if (
            result.data &&
            result.data.templates &&
            result.data.templates.length
          ) {
            let temp = result.data.templates.filter(
              (obj) => obj._id === search.chooseTempId,
            )
            if (temp.length) {
              this.setState({
                sample: temp[0].json,
                templateName: temp[0].templateName,
                templateNameCheck: '',
              })
            }
          }
        })
        .catch(console.log)
    }

    if ( this.props.params.eventId) {
      getEventTemplates(
        {
          eventId:  this.props.params.eventId,
          type: 'event-page',
          registration: true,
        },
        '?fields=_id,path,templateName',
      ).then((result) => {
        if (result && result.data.templates) {
          const specialLinks = get(result, 'data.templates', []).map((item) => {
            return {
              name: item.templateName,
              href: item.path,
              target: '_blank',
            }
          })
          this.setState({ specialLinks: specialLinks })
        }
      })
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValid = () => {
    let error = {}
    let formIsValid = true
    const { templateName, emailSubject = '' } = this.state
    if (templateName === '' || templateName.trim().length === 0) {
      formIsValid = false
      error['templateName'] = '*Please enter templateName.'
    }
    if (emailSubject === '' || emailSubject.trim().length === 0) {
      formIsValid = false
      error['emailSubject'] = '*Please enter campaign subject.'
    }
    this.setState({ errors: error })
    return formIsValid
  }
  exportHtml = async () => {
    if (this.isValid()) {
      await this.emailEditorRef.editor.exportHtml((data) => {
        const { design, html } = data
        const { templateName, eventId, emailSubject, teamName } = this.state

        const requestbody = {
          emailTemplate: html,
          emailTemplateJson: design,
          templateName: templateName,
          eventId: eventId,
          emailSubject: emailSubject,
          teamName: teamName,
        }
        if (this.state.mode === 'new') {
          createEventTemplate(requestbody)
            .then(async (result) => {
              if (result.data.status === 200) {
                createNotification('success', result.data.message)
                this.setState({
                  mode: 'edit',
                  templateId: result.data.template._id,
                  isActive: result.data.template.isActive,
                  isSave: true,
                })

                await this.emailEditorRef.editor.saveDesign((design) => {
                  const requestbody = {
                    emailTemplateJson: design,
                    eventId: this.state.eventId,
                  }
                  updateEventTemplate(result.data.template._id, requestbody)
                    .then((result) => {
                      if (result.data.status === 200) {
                        //createNotification('success', result.data.message,'');
                      } else {
                        createNotification('error', result.data.message)
                      }
                    })
                    .catch(console.log)
                })

                setTimeout(() => {
                  redirectTo(
                    this.props.navigate,
                    `edit-template-design/${this.props.events.event._id}/edit/${result.data.template._id}`,
                    this.props.location.pathname,
                  )
                }, 2000)
              } else {
                createNotification('error', result.data.message)
              }
            })
            .catch(console.log)
        } else {
          updateEventTemplate(this.state.templateId, requestbody)
            .then((result) => {
              if (result.data.status === 200) {
                this.setState({ isActive: result.data.template.isActive })
                createNotification('success', result.data.message)
              } else {
                createNotification('error', result.data.message)
              }
            })
            .catch(console.log)
        }

        //this.handleLoading();
      })
    }
  }

  exportHtmlAndActive = async () => {
    if (this.isValid()) {
      await this.emailEditorRef.editor.exportHtml((data) => {
        const { design, html } = data
        const { templateName, eventId, emailSubject, teamName } = this.state

        const requestbody = {
          emailTemplate: html,
          emailTemplateJson: design,
          templateName: templateName,
          eventId: eventId,
          makeActive: true,
          emailSubject: emailSubject,
          teamName: teamName,
        }
        if (this.state.mode === 'new') {
          createEventTemplate(requestbody)
            .then(async (result) => {
              if (result.data.status === 200) {
                createNotification('success', result.data.message)
                this.setState({
                  mode: 'edit',
                  templateId: result.data.template._id,
                  isActive: result.data.template.isActive,
                  isSave: true,
                })

                await this.emailEditorRef.editor.saveDesign((design) => {
                  const requestbody = {
                    emailTemplateJson: design,
                    eventId: this.state.eventId,
                    makeActive: true,
                  }
                  updateEventTemplate(result.data.template._id, requestbody)
                    .then((result) => {
                      if (result.data.status === 200) {
                        //createNotification('success', result.data.message,'');
                      } else {
                        createNotification('error', result.data.message)
                      }
                    })
                    .catch(console.log)
                })

                setTimeout(() => {
                  redirectTo(
                    this.props.navigate,
                    `edit-template-design/${this.props.events.event._id}/edit/${result.data.template._id}`,
                    this.props.location.pathname,
                  )
                }, 2000)
              } else {
                createNotification('error', result.data.message)
              }
            })
            .catch(console.log)
        } else {
          updateEventTemplate(this.state.templateId, requestbody)
            .then((result) => {
              if (result.data.status === 200) {
                this.setState({ isActive: result.data.template.isActive })
                createNotification('success', result.data.message)
              } else {
                createNotification('error', result.data.message)
              }
            })
            .catch(console.log)
        }

        //this.handleLoading();
      })
    }
  }

  updateSubject = () => {
    const { emailSubject, templateName, eventId, teamName } = this.state
    const requestbody = {
      emailTemplate: 'test html',
      emailTemplateJson: '{}',
      templateName: templateName,
      eventId: eventId,
      makeActive: true,
      emailSubject: emailSubject,
      teamName: teamName,
    }
    updateEventTemplate(this.state.templateId, requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          this.setState({ isActive: result.data.template.isActive })
          createNotification('success', result.data.message)
        } else {
          createNotification('error', result.data.message)
        }
      })
      .catch(console.log)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.events) {
      if (
        this.props.events.success === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        let st = prevState.loading ? this.setState({ loading: false }) : null
        const events = this.props.events.event 
        const startDate1 = moment(events.startDate).format('MM/DD/YYYY')
        const startD = startDate1 + ' ' + events.time
        const startDateTime = moment(startD).format('LLL')

        const endDate1 = moment(events.endDate).format('MM/DD/YYYY')
        const endD = endDate1 + ' ' + events.time
        const endDateTime = moment(endD).format('LLL') 
        this.setState({
          eventName: events.eventName,
          eventDomain: events.eventDomain,
          location: events.location,
          eventStartDate: startDateTime,
          eventEndDate: endDateTime,
        })
      }

      if (
        this.props.events.error &&
        this.props.events.error !== prevProps.events.error
      ) {
        createNotification('error', this.props.events.message, '')
        this.handleLoading()
      }
    }
  }

  onLoad = (templateJson) => {
    if (this.emailEditorRef) {
      this.emailEditorRef.editor.loadDesign(templateJson)
    } else {
      setTimeout(() => {
        if (
          this.emailEditorRef &&
          this.emailEditorRef.editor &&
          this.emailEditorRef.editor.loadDesign
        ) {
          this.emailEditorRef.editor.loadDesign(templateJson)
        } else {
          setTimeout(() => {
            if (
              this.emailEditorRef &&
              this.emailEditorRef.editor &&
              this.emailEditorRef.editor.loadDesign
            ) {
              this.emailEditorRef.editor.loadDesign(templateJson)
            }
          }, 3000)
        }
      }, 3000)
    }
  }

  backConfirm = () => {
    let path = 'project-type-edit'
    if (localStorage.getItem('pageType')) {
      if (localStorage.getItem('pageType') !== 'edit') {
        path = 'project-type'
      }
    }

    confirmAlert({
      title: 'Confirmation',
      message:
        'Please confirm that you have saved all the changes before leaving this edit feature.',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Continue',
          onClick: () =>
            redirectTo(
              this.props.navigate,
              `email-campaigns/${this.state.eventId}`,
              this.props.location.pathname,
            ),
        },
      ],
    })
  }
  handleChange = (event) => { 
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  sendSampleEmail = (e) => {
    e.preventDefault()
    const { eventId, templateId, email, emailSubject: subject } = this.state
    if (this.isValidEmail()) {
      const requestbody = {
        eventId: eventId,
        templateId: templateId,
        email: email,
        subject: subject,
      }
      EventEmailToUserAPI(requestbody)
        .then((result) => {
          if (result.data.status === 200) {
            createNotification('success', result.data.message)
            this.setState({ open: false })
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    }
  }

  isValidEmail = () => {
    const { email, emailSubject: subject } = this.state
    let formIsValid = true
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (email === '' || email.trim().length === 0) {
      formIsValid = false
      createNotification('error', '*Please enter email.')
    }
    if (email && regexTest.test(email) === false) { 
      formIsValid = false
      createNotification('error', '*Please enter a valid email address.')
    }
    if (subject === '' || subject.trim().length === 0) {
      formIsValid = false
      createNotification('error', '*Please enter subject.')
    }

    return formIsValid
  }

  saveTemplateToLib = async () => {
    await this.emailEditorRef.editor.exportHtml((data) => {
      const { design, html } = data
      const { eventId, templateNameLib } = this.state
      if (templateNameLib) {
        createTemplateToLib({
          json: design,
          html: html,
          templateName: templateNameLib,
          type: 'email-campaign',
        })
          .then((result) => {
            if (result.data.success) {
              this.setState({ saveTempDisabled: true })
              createNotification('success', result.data.message)
            } else {
              createNotification('error', result.data.message)
            }
          })
          .catch(console.log)
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body inputField">
                <h1>Template Name</h1>
                <input
                  type="text"
                  className="form-control"
                  name="templateNameLib"
                  placeholder={'Please enter template name'}
                  value={this.state.templateNameLib}
                  maxLength="25"
                  onChange={this.handleChange}
                />
                <div className="react-confirm-alert-button-group">
                  <button onClick={() => onClose()}>Cancel</button>
                  <button
                    onClick={() => {
                      if (this.state.templateNameLib) {
                        this.saveTemplateToLib()
                        onClose()
                      } else {
                        createNotification(
                          'error',
                          'Please enter the template name first.',
                        )
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )
          },
        })
      }
    })
  }

  render() {
    const {
      pageTitle,
      eventId,
      loading,
      sample,
      eventDomain,
      eventName,
      location,
      eventStartDate,
      eventEndDate,
      template,
      templateName,
      isActive,
      isSave,
      templateNameCheck,
      errors,
      templateId,
      open,
      email,
      subject,
      saveTempDisabled,
      emailSubject,
      specialLinks,
      userId,
      html,
      teamName,
    } = this.state
    const url = `${window.location.protocol}//${eventDomain}.${window.location.host}`
    const loginUrl = `${url}`
    const registrationUrl = `${url}/registration`
    const specialLinkOpt = getSpecialLinks(eventDomain, specialLinks, url)

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <div className="editingDashboard">
          <div className="editingHeader">
            <div className="editingHeading">
              <h3>
                <Link onClick={() => this.backConfirm()}>
                  <img src={EditingBack} alt="" />
                </Link>
                Choose Email Templates
              </h3>
            </div>
            <div className="saveTemplateSubmit">
              <div className="mobile-none continueSaveBttn">
                {isActive ? (
                  <Button
                    className="saveButton mobile-None"
                    onClick={() =>
                      redirectTo(
                        this.props.navigate,
                        `resipients/${eventId}/${templateId}`,
                        this.props.location.pathname,
                      )
                    }
                  >
                    Run Campaign
                  </Button>
                ) : (
                  <Button className="mobile-None disablegreyButton">
                    Run Campaign
                  </Button>
                )}
              </div>

              <a className="MobileSaveButton desktop-None">
                <i className="fa fa-check"></i>
              </a>
            </div>
          </div>
          <div className="templateName templateNameField formSection pt-0">
            <div class="field-group fieldFroup30 inputField">
              <label>Campaign Name</label>
              <input
                type="text"
                class="form-control"
                placeholder={'Please enter campaign name.'}
                name="templateName"
                value={templateName}
                maxLength="25"
                onChange={this.handleChange}
              />
              <p className="error_mesage"> {errors.templateName} </p>
            </div>
            <div className="field-group fieldFroup30 inputField">
              <label>Email Subject</label>
              <input
                type="text"
                className="form-control"
                name="emailSubject"
                placeholder={'Enter campaign subject'}
                value={emailSubject}
                maxLength="50"
                onChange={this.handleChange}
              />
              <p className="error_mesage"> {errors.emailSubject} </p>
            </div>

            <div className="continueSaveBttn">
              {userId && userId !== '60b71073f67377506455bfcd' ? (
                <Button
                  className="saveButton mobile-None"
                  onClick={this.exportHtmlAndActive}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="saveButton mobile-None"
                  onClick={this.updateSubject}
                >
                  Save
                </Button>
              )}
              {isActive && (
                <Button
                  type="button"
                  className="saveButton mobile-None"
                  onClick={this.onOpenModal}
                >
                  Send a test email
                </Button>
              )}
              {this.state.mode === 'new' && !saveTempDisabled && (
                <Button
                  type="button"
                  className="saveButton mobile-None"
                  onClick={this.saveTemplateToLib}
                >
                  Save as Template
                </Button>
              )}
            </div>
          </div>
          <div className="field-group fieldFroup30 inputField">
            <label>Team Name</label>
            <input
              type="text"
              className="form-control"
              name="teamName"
              placeholder={'Team Name'}
              value={teamName}
              maxLength="50"
              onChange={this.handleChange}
            />
          </div>
          {userId && userId !== '60b71073f67377506455bfcd' ? (
            <div className="editTemplateDashboard editTempDesign emailEditorForm">
              {this.props.events.success &&
                this.props.events.event &&
                eventDomain !== '' &&
                Object.keys(sample).length !== 0 && (
                  <EmailEditor
                    ref={(ref) => (this.emailEditorRef = ref)}
                    onLoad={async () => {
                      {
                        /* const data = get(this.props, 'events.event.emailTemplateJson');
                    this.onLoad(template[0].emailTemplateJson ? template[0].emailTemplateJson : this.state.sample);*/
                      }
                      this.onLoad(sample ? sample : {})
                    }}
                    options={
                      ({
                        mergeTags: {
                          first_name: {
                            name: 'First Name',
                            value: '#first_name#',
                            sample: 'John',
                          },
                          last_name: {
                            name: 'Last Name',
                            value: '#last_name#',
                            sample: 'Doe',
                          },
                          event_name: {
                            name: 'Event Name',
                            value: '#event_name#',
                            sample: eventName,
                          },
                          event_domain: {
                            name: 'Event Domain',
                            value: '#event_domain#',
                            sample: eventDomain,
                          },
                          start_date: {
                            name: 'Event Start Date',
                            value: '#start_date#',
                            sample: eventStartDate,
                          },
                          end_date: {
                            name: 'Event End Date',
                            value: '#end_date#',
                            sample: eventEndDate,
                          },
                          event_location: {
                            name: 'Event Location',
                            value: '#event_location#',
                            sample: location,
                          },
                        },
                      },
                      {
                        specialLinks: specialLinkOpt,
                      })
                    }
                  />
                )}{' '}
              1
            </div>
          ) : html ? (
            templateId == '60cc2510b780823db41a586c' ? (
              ReactHtmlParser(ReactHtmlParser(cidV1Html))
            ) : templateId == '60d2cd2b9c21bc0f6cf7af2f' ||
              templateId == '60d0a81b7a624a0f5487b553' ? (
              ReactHtmlParser(ReactHtmlParser(cidV2Html))
            ) : templateId == '60d2f8e86cfdce4470ed2f00' ? (
              ReactHtmlParser(ReactHtmlParser(cidP1Html))
            ) : templateId == '60d5af13131c6057b6c7236a' ? (
              ReactHtmlParser(ReactHtmlParser(CidE2Html))
            ) : templateId == '60d5af0a01f85457d7539675' ? (
              ReactHtmlParser(ReactHtmlParser(CidP2Html))
            ) : templateId == '60dc3de76faed3461f54eeff' ? (
              ReactHtmlParser(ReactHtmlParser(cid30PHtml))
            ) : templateId == '60dc3de06faed3461f54eefd' ? (
              ReactHtmlParser(ReactHtmlParser(cid30EHtml))
            ) : templateId == '60deef217818b904f26faf5d' ? (
              ReactHtmlParser(ReactHtmlParser(cid5julyP))
            ) : templateId == '60deef297818b904f26faf5f' ? (
              ReactHtmlParser(ReactHtmlParser(cid5julyE))
              ) : templateId == '60ebcb590a88c804ef0eded3' ? (
                ReactHtmlParser(ReactHtmlParser(cid12JulyE))
                ) : templateId == '60ebcb510a88c804ef0eded1' ? (
                  ReactHtmlParser(ReactHtmlParser(cid12JultP))
            ) : templateId == '60eec822c6ae100b99038022' ? (
              ReactHtmlParser(ReactHtmlParser(cid15JulyE))
            ) : templateId == '60eec82bc6ae100b99038024' ? (
              ReactHtmlParser(ReactHtmlParser(cid15JulyP))
            ) : templateId == '60f19ca9f1a10e3330e31a3b' ? (
                ReactHtmlParser(ReactHtmlParser(cid16JulyP))
            ) : templateId == '60f19eec7c99ca3381465eb8' ? (
                ReactHtmlParser(ReactHtmlParser(cid16JulyE)) 
            ) : templateId == '60f3d14152b024614454bcb7' ? (
                ReactHtmlParser(ReactHtmlParser(cid19JulyP)) 
            ) : templateId == '60f3d14a52b024614454bcb9' ? (
                ReactHtmlParser(ReactHtmlParser(cid19JulyE)) 
            ) : templateId == '60f6713e81455a130e65f82a' ? (
                ReactHtmlParser(ReactHtmlParser(cid21P))  
            ) : templateId == '60f6714881455a130e65f82c' ? (
                ReactHtmlParser(ReactHtmlParser(cid21E))
            ) : templateId == '60f670bb5bf4b71341fe2271' ? (
                ReactHtmlParser(ReactHtmlParser(cid20P)) 
            ) : templateId == '60f670c45bf4b71341fe2273' ? (
                ReactHtmlParser(ReactHtmlParser(cid20E))                                 
            ) : (
              ReactHtmlParser(html)
            )
          ) : null}

          <Modal open={open} onClose={this.onCloseModal} center>
            <SentTestEmailModal
              subject={subject}
              email={email}
              eventId={eventId}
              templateId={templateId}
              handleChange={this.handleChange}
              sendSampleEmail={this.sendSampleEmail}
            />
          </Modal>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

const getSpecialLinks = (eventDomain, specialLinks, url) => {
  let optionData = []
  if (eventDomain) {
    optionData =
      specialLinks &&
      specialLinks.map((item) => {
        item.href = url + '/' + item.href
        return item
      })
  }
  return optionData
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateEvent: Actions.updateEventsRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(EditTemplateDesign)

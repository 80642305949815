import React, { Component } from 'react' 
import { Helmet } from 'react-helmet'
import { get, cloneDeep } from 'lodash'
import { NotificationContainer } from 'react-notifications'
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'
import {
  updateEventTemplateById,
  createTemplate,
  getEventTemplates,
  makeTemplateActive,
} from 'ReduxStore/API'

/*custom components */
import PageToolBar from '../../Components/PageToolBar'
import Loader from '../../Components/Loader/loader'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
/*default ui configuration*/
import '../../sass/main.scss'
import { createNotification, redirectTo } from '../../helpers'
import getUnLayerConfig from './config'
import { Button } from '@mui/material'

class PageDesign extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      showLoader: false,
      registrationTemplates: [],
      templateName: 'My form',
      selectedRegTemplate: '',
      formType: 'registrationForm',
    }
  }

  componentDidMount() {
    const apiUrl = process.env.REACT_APP_API_URL
    const eventId = get(this.props, 'match.params.eid')
    const templateId = get(this.props, 'match.params.tid')
    const type = this.state.formType
    const setState = this.setState.bind(this)
    window.scrollTo(0, 0)
    window.unlayer.init(getUnLayerConfig(apiUrl, eventId, type))
    window.unlayer.addEventListener('design:updated', function (updates) {
      window.unlayer.exportHtml(function (data) {
        const json = data.design
        const html = data.html
        setState({ json, html })
      })
    })

    if (templateId !== 'blank') {
      const params = { type: type.toLowerCase(), templateId }
      getEventTemplates(params).then((result) => {
        if (result.data.success) {
          const templates = result.data.templates || {}
          const oldTemplates = cloneDeep(templates)
          const { json } = templates
          this.setState({ ...templates, showLoader: false, oldTemplates })
          window.unlayer.loadDesign(json)
          if (type.toLowerCase() == 'meeting') {
            const params = { type: 'registration', eventId }
            getEventTemplates(params)
              .then(async (result) => {
                const registrationTemplates = result.data.templates || []
                const selectedRegTemplate = templates.selectedRegTemplate
                  ? templates.selectedRegTemplate
                  : get(registrationTemplates, '0._id')
                this.setState({ registrationTemplates, selectedRegTemplate })
              })
              .catch(console.log)
          }
        }
      })
    }
  }

  saveLayout = () => {
    const {
      json,
      html,
      templateName,
      oldTemplates,
      defaultTemplate,
      selectedRegTemplate,
    } = this.state
    const templateId = get(this.props, 'match.params.tid')
    const eventId = get(this.props, 'match.params.eid')
    const type = get(this.props, 'type', '').toLowerCase()
    if (type === 'login') {
      makeTemplateActive(eventId, templateId, {
        type: type.toLowerCase(),
      }).then(() => {
        createNotification('success', 'Design has been activated successfully')
        this.setState({ templateName: oldTemplates.templateName })
        window.unlayer.loadDesign(oldTemplates.json)
        window.unlayer.loadDesign(oldTemplates.json)
      })
      return
    }

    if (templateId !== 'blank' && !defaultTemplate) {
      const data = { json, html, templateName, selectedRegTemplate }
      updateEventTemplateById(templateId, data)
        .then((result) => {
          if (result.data.success) {
            createNotification('success', result.data.message)
            const newTemplate = get(result, 'data.template', {})
            const oldTemplates = cloneDeep(newTemplate)
            this.setState({
              ...newTemplate,
              oldTemplates,
            })
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    } else {
      const data = { json, html, type, templateName, selectedRegTemplate }
      createTemplate(eventId, data)
        .then((result) => {
          if (result.data.success) {
            const templateId = result.data.template._id
            const { type } = this.props
            const editRoute =
              type === 'Registration'
                ? `edit-event-form/eid/${eventId}/tid`
                : type === 'Login'
                ? `edit-login-page/eid/${eventId}/tid`
                : `edit-event-form/eid/${eventId}/tid`
            redirectTo(
              this.props.navigate,
              `${editRoute}/${templateId}`,
              this.props.location.pathname,
            )
            createNotification('success', result.data.message)
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    }
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  makeTemplateActive = () => {
    const eventId = get(this.props, 'match.params.eid')
    const templateId = get(this.props, 'match.params.tid')
    const type = get(this.props, 'type', '')
    makeTemplateActive(eventId, templateId, { type: type.toLowerCase() }).then(
      () => {
        this.setState({ isActive: true })
        createNotification('success', 'Template has been saved as active')
      },
    )
  }

  saveConfirmation = (isActive) => {
    this.setState(
      ({ templateName = '' }) => ({ templateName: templateName.trim() }),
      () => {
        const {
          html,
          templateName,
          oldTemplates,
          defaultTemplate,
          selectedRegTemplate,
        } = this.state
        const oldHtml = get(oldTemplates, 'html', '')
        const oldTemplateName = get(oldTemplates, 'templateName', '')
        if (
          html &&
          (html !== oldHtml ||
            (templateName && templateName !== oldTemplateName) ||
            selectedRegTemplate !== oldTemplates.oldTemplates)
        ) {
          /*confirmAlert({
                    title: 'Confirmation',
                    message: defaultTemplate ? `Default template can't be changed. A copy of this template will be created. Do you want to continue` :
                        'Are you sure want to save the changes',
                    buttons: [
                        {
                            label: 'Cancel'
                        },
                        {
                            label: 'Continue',
                            onClick: () => {
                                this.saveLayout(isActive);
                            }
                        }
                    ]
                });*/
          this.saveLayout(isActive)
        } else {
          const message = !templateName.trim()
            ? `Can't save template with blank name`
            : 'No changes found'
          createNotification('error', message)
        }
      },
    )
  }

  handleRegTemplate = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.getAgenda)
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const {
      view,
      showLoader,
      templateName,
      registrationTemplates,
      isActive,
      selectedRegTemplate,
      transactionalDesign,
      formType,
    } = this.state
    const { type } = this.props
    const templateId = get(this.props, 'match.params.tid')
    const eventId = get(this.props, 'match.params.eid')
    const backTo =
      type === 'Registration'
        ? `/event-form-list/${eventId}`
        : type === 'Login'
        ? `/login-page-list/${eventId}`
        : `/meeting-page-list/${eventId}`
    const showTransactionalDesign = templateId !== 'blank'
    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | Page Design </title>
        </Helmet>
        {showLoader && <Loader />}
        {
          <div className="editingDashboard">
            {!view && (
              <PageToolBar
                showIcons={false}
                onSaveFn={this.saveConfirmation}
                showPreviewOption={false}
                makeActive={this.makeTemplateActive}
                showSaveBtn={type !== 'Login'}
                showActiveBtn={false}
                activeBtnLabel="Make Design Active"
                activeBtnDisabled={isActive || templateId === 'blank'}
                backTo={backTo}
              />
            )}
            <div className="templateName templateNameField edit_event_form_page32 formSection pt-0">
              <form className="chooseEmail_fields32">
                <div className="field-group fieldFroup20 inputField">
                  <label>Form Template</label>
                  <input
                    type="text"
                    className="form-control"
                    name="templateName"
                    value={templateName}
                    maxLength="25"
                    onChange={this.onChange}
                  />
                </div>
                <div
                  className="field-group timeClock fieldFroup20 inputField">
                  <label>Select Form Type</label>
                  <div
                    className="formTime formIcon">
                    <select
                      className="form-control"
                      name="formType"
                      value={formType}
                    >
                      <option value={false}>Select form type</option>
                      <option value={'registrationForm'}>
                        Registration Form
                      </option>
                      <option value={'loginForm'}>Login Form</option>
                    </select>
                  </div>
                </div>
                <div className="field-group fieldFroup20 inputField">
                  {!!registrationTemplates.length && (
                    <>
                      <label>Select Register Template</label>
                      <select
                        name="selectedRegTemplate"
                        value={selectedRegTemplate}
                        className="outlineButton"
                        onChange={this.handleRegTemplate}
                      >
                        {registrationTemplates.map((template) => (
                          <option key={template._id} value={template._id}>
                            {template.templateName}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {showTransactionalDesign && (
                    <div className="fieldFroup20 inputField event_list_che34">
                      <label>&nbsp;</label>{' '}
                      <Button
                        type="button"
                        className="themeBlueButton"
                        style={{marginTop:'5px'}}
                        onClick={() => {
                          const transactionalUrl = `/edit-event-form/eid/${eventId}/tid/${templateId}/transactional`
                          this.props.navigate(transactionalUrl) 
                        }}
                      >
                        {transactionalDesign
                          ? 'Edit Transactional Design'
                          : 'Create Transactional Design'}
                      </Button>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div id="pageDesignEditor" style={{ height: '100vh' }}>
              {''}
            </div>
          </div>
        }
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(PageDesign)
  

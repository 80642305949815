import React, { Component } from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import { Button } from '@mui/material'
import DatePicker from 'react-datepicker'
import TimePicker from 'react-time-picker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import moment1 from 'moment-timezone'

class CreateYEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Create Your Event',
      pageTitle: 'Create Your Event',
      initialDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      openDate: new Date(),
      time: moment().format('HH:mm'),
      endTime: moment().format('HH:mm'),
      currentTime: moment().format('HH:mm'),
      openTime: moment().format('HH:mm'),
    }
  }
  onChangeDate = (dates) => {
    const [start, end] = dates
    this.setState({ startDate: start, endDate: end })
  }

  onChangeOpenDate = (date) => {
    this.setState({ openDate: date })
  }

  onTimeChange = (time) => {
    this.setState({ time: time })
  }

  onEndTimeChange = (time) => {
    this.setState({ endTime: time })
  }
  onOpenTimeChange = (time) => {
    this.setState({ openTime: time })
  }
  render() {
    const {
      title,
      pageTitle,
      startDate,
      endDate,
      initialDate,
      startD,
      endTime,
    } = this.state
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <Helmet>
          <title>IllumeetXP | Create Your Event</title>
        </Helmet>
        <div className="dashboardMiddleArea">
          <div className="createProjectAccount projectStepRow">
            <div className="projectCreateSection">
              <div className="CreatePresentation">
                <div class="accountTypeTitle">
                  <h1>Start creating your event below:</h1>
                </div>
                <div className="createPersentSteps">
                  <div className="CPTabs"></div>
                </div>
                <div className="CPTabsDetails">
                  <div className="rsvpEventfieldBox">
                    <div className="formSection p-0">
                      <form>
                        <div className="completeRegisterboxes">
                          <div className="eventFullInput CRCircularBox">
                            <div className="field-group inputField">
                              <label>Project Name</label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="field-group inputField">
                              <label>Domain Name</label>
                              <input type="text" className="form-control" />
                            </div>
                            <div className="field-group inputField tabletResponsiveNone">
                              {/* <label>Project Name</label>
                                                <input type="text" className="form-control" /> */}
                            </div>
                            <div className="field-group inputField">
                              <label>Location</label>
                              <input type="text" className="form-control" />
                            </div>
                            <div class="field-group inputField">
                              <label> Date </label>
                              <div class="formDate formIcon">
                                <DatePicker
                                  selected={startDate}
                                  onChange={this.onChangeDate}
                                  startDate={startDate}
                                  minDate={initialDate}
                                  selectsRange
                                  shouldCloseOnSelect={false}
                                />
                              </div>
                            </div>
                            <div class="field-group inputField ">
                              <label> Time</label>
                              <div class="formTime formIcon">
                                <TimePicker
                                  onChange={this.onEndTimeChange}
                                  value={endTime}
                                  format="h:m a"
                                  minTime={new Date(startD)}
                                  disableClock={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="submitForm createYourEvntBttn mt-3 justify-content-center">
                          <Button type="button" className="outlineButton mr-3">
                            BACK
                          </Button>
                          <Button type="button" className="themeBlueButton">
                            CHOOSE A THEME
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default CreateYEvent

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from './Loader/loader'
import { createNotification } from '../helpers'
import { NotificationContainer } from 'react-notifications'
import '../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class RescheduleModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      templateId: '',
      loading: false,
      emailDate: new Date(),
      errors: {},
      initialDate: new Date(),
    }
  }

  componentDidMount() {
    if (this.props.scheduleDate) {
      this.setState({ emailDate: new Date(this.props.scheduleDate) })
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValid = () => { 
    const { emailDate } = this.state
    let error = {}
    let formIsValid = true

    if (emailDate === '' || emailDate === null) {
      formIsValid = false
      error['emailDate'] = '*Please select email date.'
    }
    this.setState({ errors: error })
    return formIsValid
  }
  handleSubmit = async (e) => {
    e.preventDefault() 
    if (this.isValid()) {
      this.props.rescheduleSubmit(this.state.emailDate)
    }
  }

  handleDate = (date) => { 
    this.setState({ emailDate: date })
  }

  render() {
    const { loading, emailDate, errors, initialDate } = this.state 
    return (
      <div>
        <h2>Email Scheduler</h2>
        <div className="rsvpEventfieldBox emailSchedulerModal">
          <div className="formSection p-0">
            <form onSubmit={this.handleSubmit}>
              <div class="field-group inputField">
                <label>Select datetime</label>
                <DatePicker
                  selected={emailDate}
                  showTimeSelect
                  dateFormat="Pp"
                  onChange={this.handleDate}
                  minDate={initialDate}
                  autoFocus={false}
                />
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  Reschedule
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default RescheduleModal

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import { Multiselect } from 'multiselect-react-dropdown'

class ContactModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      profilePic: '',
      firstName: this.props.singleData.firstName
        ? this.props.singleData.firstName
        : '',
      lastName: this.props.singleData.lastName
        ? this.props.singleData.lastName
        : '',
      email: this.props.singleData.email ? this.props.singleData.email : '',
      company: this.props.singleData.company
        ? this.props.singleData.company
        : '',
      _id: this.props.singleData._id ? this.props.singleData._id : '',
      singleData: this.props.singleData ? this.props.singleData : {},
      ProfileImage:
        process.env.REACT_APP_IMAGE_URL +
        (this.props.singleData.profilePic
          ? this.props.singleData.profilePic
          : 'user.png'),
      page_no: this.props.page_no ? this.props.page_no : 1,
      event_id: this.props.singleData.event_id
        ? this.props.singleData.event_id
        : [],
      selectedOptions: [],
    }
  }

  componentDidMount() {
    let selectedOptions = []
    let groupArray = []
    this.props.singleData.groupId &&
      this.props.singleData.groupId.map((data, index) => {
        selectedOptions.push({ id: data._id, groupName: data.groupName })
        groupArray.push(data._id)
      })
    this.setState({ event_id: groupArray, selectedOptions: selectedOptions })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;

    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        open: false,
      })
    }

    if (
      this.props.contacts.updateError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => {
     
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader()
      const imageFile = event.target.files[0]
      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        createNotification('error', 'Please select valid image.')
        // this.setState({ invalidImage: 'Please select valid image.' });
        return false
      }

      reader.onload = (e) => {
        this.setState({ ProfileImage: e.target.result })
      }
      this.setState({ profilePic: event.target.files[0] })
      reader.readAsDataURL(event.target.files[0])
    }
  }

  isValid = () => { 
    const { firstName, lastName, email, company } = this.state
    let error = {}
    let formIsValid = true
    const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (email === '' || email.trim().length === 0) {
      formIsValid = false
      error['email'] = '*Please enter email.'
    }
    if (email && regexTest.test(email) === false) {
      
      formIsValid = false
      error['email'] = '*Please enter a valid email address.'
    }
    this.setState({ errors: error })
    return formIsValid
  }

  handleSubmit = (e) => {
    e.preventDefault()
     
    if (this.isValid()) {
     
      const {
        firstName,
        lastName,
        email,
        company,
        profilePic,
        page_no,
        _id,
        event_id,
      } = this.state
     
      if (profilePic !== '') { 
        const formData = new FormData()

        formData.append('firstName', firstName)
        formData.append('lastName', lastName)
        formData.append('email', email)
        formData.append('company', company)
        formData.append('page_no', page_no)
        formData.append('event_id', event_id)
        formData.append('profilePic', profilePic)
        formData.append('id', _id)
        this.props.updateContactRequest(formData)
      } else {
       
        const requestbody = {
          firstName,
          lastName,
          email,
          company,
          page_no,
          id: _id,
          event_id,
        }

        this.props.updateContactRequest(requestbody)
      }

      this.handleLoading()
      // this.props.startLoading();
    }
  }

  onSelect = (selectedList, selectedItem) => {
    let groupArray = []
    selectedList.map((data) => {
      groupArray.push(data.id)
    })
    let group =
      groupArray.length > 0
        ? this.setState({ event_id: groupArray }, function () {
          
        })
        : null
  }

  onRemove = (selectedList, removedItem) => {
    let groupArray = []
    selectedList.map((data) => {
      groupArray.push(data.id)
    })

    let group =
      groupArray.length > 0
        ? this.setState({ event_id: groupArray }, function () {
           
        })
        : null
  }

  render() {
    const {
      errors,
      loading,
      ProfileImage,
      firstName,
      lastName,
      email,
      company,
      selectedOptions,
    } = this.state
     
    let options = []
    {
      this.props.events &&
        this.props.events.map((data, index) => {
          options.push({ id: data._id, eventName: data.eventName })
        })
    }
    return (
      <div>
        <h2>Update Contact</h2>
        <div className="rsvpEventfieldBox">
          <div className="editProfieImg">
            <div className="editProfieInner">
              <div className="profileImgBox">
                <img src={ProfileImage} alt="" />
              </div>
              <div className="profileEditButton">
                <input
                  type="file"
                  id="profileImg"
                  name="filename"
                  onChange={this.onImageChange}
                  accept="image/*"
                />
                <span className="buttonText">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.602"
                    height="17.584"
                    viewBox="0 0 17.602 17.584"
                  >
                    <g id="pencil" transform="translate(0 -0.246)">
                      <path
                        id="Path_934"
                        data-name="Path 934"
                        d="M10.87,82.473,1.185,92.158a.386.386,0,0,0-.1.177L.011,96.644a.381.381,0,0,0,.37.474.379.379,0,0,0,.092-.011l4.309-1.074a.381.381,0,0,0,.177-.1l9.686-9.685Zm0,0"
                        transform="translate(0 -79.287)"
                        fill="#fff"
                      ></path>
                      <path
                        id="Path_935"
                        data-name="Path 935"
                        d="M339.423,1.865,338.345.787a1.952,1.952,0,0,0-2.7,0l-1.321,1.321L338.1,5.881l1.321-1.321a1.907,1.907,0,0,0,0-2.7Zm0,0"
                        transform="translate(-322.379)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="formSection p-0">
            <form onSubmit={this.handleSubmit}>
              <div className="field-group inputField">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.firstName} </p>
              </div>
              <div className="field-group inputField">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.lastName} </p>
              </div>
              <div className="field-group inputField fullWidthInput">
                <label>Company</label>
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  value={company}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.company} </p>
              </div>
              <div className="field-group inputField fullWidthInput">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control emailLoweCase"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.email} </p>
              </div>
              <div class="field-group inputField fullWidthInput">
                <label>Events</label>

                {options && options.length > 0 && (
                  <Multiselect
                    options={options} // Options to display in the dropdown
                    selectedValues={selectedOptions} // Preselected value to persist in dropdown
                    onSelect={this.onSelect} // Function will trigger on select event
                    onRemove={this.onRemove} // Function will trigger on remove event
                    displayValue="eventName" // Property name to display in the dropdown options
                    showCheckbox={true}
                    closeOnSelect={false}
                    closeIcon="close"
                  />
                )}
                <p className="error_mesage"> {errors.group_id} </p>
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  Update Contact
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateContactRequest: Actions.updateContactRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ContactModal)

import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../src/theme/theme';
import SearchIcon from '../../../src/assets/images/icons/search.svg';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import '../../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { createNotification } from "../../helpers";
import {
    IconButton,
    ButtonGroup,
    Chip,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { withStyles } from '@mui/styles';

import { DropzoneArea } from 'mui-file-dropzone';
import CreateFolderModal from './CommonComponent/CreateFolderModal';
import GridView from '../../../src/assets/images/icons/icon-grid.svg';
import ListView from '../../../src/assets/images/icons/icon-list.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LinearProgress from '@mui/material/LinearProgress';
import newFolderIcon from '../../../src/assets/images/icons/newFolder-icon.svg';
import sheetIcon from '../../../src/assets/images/icons/xls-icon.svg';
import VideoIcon from '../../../src/assets/images/icons/Video-icon.svg';
import DocsIcon from '../../../src/assets/images/icons/Doc-icon.svg';
import noDocIcon from '../../../src/assets/images/icons/no-doc-icon.svg';
import pdfIcon from '../../../src/assets/images/icons/pdf-icon.svg';
import imgIcon from '../../../src/assets/images/icons/Image-icon.svg';
import imgThum from '../../../src/assets/images/banner_image.png';
import { Modal } from 'react-responsive-modal';
import { Actions } from 'ReduxStore/Actions';
import FileCreators from 'ReduxStore/Actions/files.action';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader/loader';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FILE_SECTION, FILE_TYPE } from "../../constants/constants";
import UploadFolderModal from './CommonComponent/UploadFolderModal';
import RenameFolderModal from './CommonComponent/RenameFolderModal';
import DeleteFolderModal from './CommonComponent/DeleteFolderModal';
import image_preview from '../../../src/assets/images/banner_image.png';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PdfPreview from './CommonComponent/PdfPreview';
import Tooltip from '@mui/material/Tooltip';


const extensionNames = ['.pdf', '.doc', '.xlsx', '.xls', '.docx', '.pptx', '.txt'];
const section = FILE_SECTION.docs;
const IMAGE_URL = process.env.REACT_APP_FILE_MODULE_URL;
const subText = "Accepted formats: PDF, Doc, Docx, xls, xlsx, txt, PPTX. Maximum file size: 50MB. Please ensure your document adheres to these guidelines before uploading.";
const smallTextOne = "Accepted formats: PDF, Doc, Docx, xls, xlsx, txt, PPTX.";
const smallTextTwo = "Upto 50MB.";
const smallTextThree = " Please ensure your document adheres to these guidelines before uploading";
const Docs = () => {
    const dispatch = useDispatch((store) => Actions.FileCreators);
    const fileObject = useSelector((store) => store.Files);
    const urlParams = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [FilesListView, setFilesListView] = useState(false);
    const [FilesGridView, setFilesGridView] = useState(true);
    const [folderName, setFolderName] = useState("");
    const [createFolder, setCreateFolder] = useState(false);
    const [foldersList, setFoldersList] = useState([]);
    const [docNames, setDocNames] = useState([]);
    const [uploadFile, setUploadFile] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [deleteFolder, setDeleteFolder] = useState(false);
    const [breadCrumb, setBreadCrumb] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [showFiles, setShowFiles] = useState(false);
    const [userId, setUserId] = useState("");
    const [previewFiles, setPreviewFiles] = useState(true);
    const [isFolderSelected, setIsFolderSelected] = useState(false);
    const [fileToRename, setFileToRename] = useState({});
    const [folderToRename, setFolderToRename] = useState({});
    const [deleteFolderModal, setDeleteFolderModal] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState({});
    const [fileToDelete, setFileToDelete] = useState({});
    const [searchText, setSearchText] = useState("");
    const [folderMessage, setFolderMessage] = useState("");
    const [isFolderListEmpty, setIsFolderListEmpty] = useState(false);
    const [isFileListEmpty, setIsFileListEmpty] = useState(false);
    const [previewFile, setPreviewFile] = useState(false);
    const [previewS3File, setPreviewS3File] = useState("");
    const [limit, setLimit] = useState(12);
    const [foldersPresent, setFoldersPresent] = useState(0);
    const [filesPresent, setFilesPresent] = useState(0);
    const [docArray, setDocArray] = useState([]);
    const [jwtToken, setJwtToken] = useState("");
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOrderForDate, setSortOrderForDate] = useState('asc');
    const [nameFieldActive, setNameFieldActive] = useState(true);
    const [viewDocument, setViewDocument] = useState(false);
    const [fileExtension, setFileExtension] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileNameInS3, setFileNameInS3] = useState('');
    /**
  * @function useEffect
  * @description React Hook that runs after the component mounts.
  */
    useEffect(() => {
        const userDetails = JSON.parse(localStorage.getItem('user_details'));
        setJwtToken(userDetails.token);
        setUserId(userDetails.companyOwnerId);
    }, []);

    useEffect(() => {
        getFolders();
    }, [urlParams]);

    const getFolders = () => {
        dispatch(FileCreators.getFolderListRequest({
            section: section, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit
        }));

    };
    const getFolderIdFromUrl = () => {
        const completeUrlParams = urlParams.folderId === undefined ? null : urlParams.folderId;
        if (completeUrlParams) {
            const completeUrlParamsArr = completeUrlParams.split('/');
            const currentFolderId = completeUrlParamsArr[completeUrlParamsArr.length - 1];

            return currentFolderId;
        }
        else {
            return completeUrlParams;
        }

    };
    useEffect(() => {
        if (fileObject.folderListSuccess) {

            setFoldersList(fileObject.foldersList);
            setDocNames(fileObject.filesList);
            setBreadCrumb(fileObject.breadCrumbs);
            setFoldersPresent(fileObject.foldersCount);
            setFilesPresent(fileObject.filesCount);

            if (fileObject.foldersList.length === 0 && fileObject.filesList.length === 0) {
                setIsFolderListEmpty(true);
                setIsFileListEmpty(true);
            }
            else if (fileObject.foldersList.length !== 0 && fileObject.filesList.length !== 0) {
                setIsFileListEmpty(false);
                setIsFolderListEmpty(false);
            }
            else if (fileObject.foldersList.length === 0) {
                setIsFolderListEmpty(true);
                setIsFileListEmpty(false);
            }
            else {
                setIsFileListEmpty(true);
                setIsFolderListEmpty(false);
            }
        }
    }, [fileObject.folderListSuccess]);

    useEffect(() => {
        if (fileObject.alreadyExist) { // true in case of folder with same name
            setFolderMessage(fileObject.message);
        }
        else {
            setFolderMessage("");
        }
    }, [fileObject.alreadyExist]);

    useEffect(() => {
        if (fileObject.downloadFileSuccess) {
            window.location.href = fileObject.url;
        }
    }, [fileObject.downloadFileSuccess]);

    useEffect(() => {
        if ((fileObject.createFolderSuccess && !fileObject.alreadyExist) || fileObject.addDocumentSuccess || fileObject.renameFolderSuccess || fileObject.deleteFolderSuccess) {
            setCreateFolder(false);
            if (nameFieldActive) {
                dispatch(FileCreators.getFolderListRequest({
                    section: section, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "name", sortOrder: sortOrder
                }));
            }
            else {
                dispatch(FileCreators.getFolderListRequest({
                    section: section, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "createdAt", sortOrder: sortOrderForDate
                }));
            }
        }
    }, [fileObject.createFolderSuccess, fileObject.addDocumentSuccess, fileObject.renameFolderSuccess, fileObject.deleteFolderSuccess]);

    useEffect(() => {
        if (fileObject.addDocumentSuccess) {
            createNotification('success', 'Document uploaded successfully');
            setShowFiles(false);
            setUploadedFiles([]);
        }
    }, [fileObject.addDocumentSuccess]);

    //CreateFolderModal functions
    const handleClickOpenCreateFolder = () => {
        setCreateFolder(true);
    };

    const handleCloseCreateFolder = () => {
        setCreateFolder(false);
        setFolderMessage("");
    };




    const handleCreateNewFolder = () => {
        dispatch(FileCreators.createFolderRequest({
            folderName: folderName,
            section: FILE_SECTION.docs,
            type: FILE_TYPE.folder,
            parent: getFolderIdFromUrl()
        }));
        setFolderMessage("");

    };

    const handleFolderChange = (e) => {
        setFolderName(e.target.value);
    };

    //RenameFolderModal functions
    const handleOpenRenameFolder = (folder) => {
        setRenameModal(true);
        setFolderToRename(folder);
        setIsFolderSelected(true);
    };

    const handleUpdateFolderName = (value) => {
        setFolderToRename({
            ...folderToRename,
            name: value
        });
    };
    const handleUpdateFolderSubmit = (folderName, folderId) => {
        dispatch(FileCreators.renameFolderRequest({ name: folderName, id: folderId, type: FILE_TYPE.folder }));
        setRenameModal(false);
    };

    const handleCloseRenameFolder = () => {
        setRenameModal(false);
    };

    //DeleteFolderModal funtions
    const handleOpenDeleteFolder = (folder) => {
        setDeleteFolderModal(true);
        setFolderToDelete(folder);
        setIsFolderSelected(true);
    };

    const handleCloseDeleteFolder = () => {
        setDeleteFolderModal(false);
    };

    const handleDeleteFolder = (folderId) => {
        dispatch(FileCreators.deleteFolderRequest({ id: folderId, type: FILE_TYPE.folder }));
        setDeleteFolderModal(false);
    };

    /*
    * Document releted functions
    * Upload or add a document
    */
    const handleClickOpenUploadFile = () => {
        setUploadFile(true);
    };

    const handleCloseUploadFile = () => {
        setUploadFile(false);
        setShowFiles(false);
        setUploadedFiles([]);
    };

    const handleSelectedFiles = (files) => {
        setUploadedFiles([...uploadedFiles, ...files]);
        setShowFiles(true);
    };

    const handleUploadDocument = () => {
        if (uploadedFiles.length === 0) {
            createNotification('error', 'Please add the files for uploading');
        }
        const formData = new FormData();
        for (var i = 0; i < uploadedFiles.length; i++) {
            var file = uploadedFiles[i]?.file;
            if (!file?.name?.match(/\.(xlsx|xls|pdf|doc|txt|pptx|docx)$/i)) {
                return false;
            }

            if (file.size > 10000000000) { //5 mb
                return false;
            }
            formData.append('docFile', file);
        }
        formData.append('section', section);
        formData.append('type', FILE_TYPE.file);
        formData.append('parent', getFolderIdFromUrl());
        dispatch(FileCreators.addDocumentRequest((formData)));
        //setUploadFile(false);
    };

    // Rename a document 
    const handleOpenRenameDoc = (file) => {
        setRenameModal(true);
        setFileToRename(file);
        setIsFolderSelected(false);
    };

    const handleUpdateFileName = (value) => {
        setFileToRename({
            ...fileToRename,
            name: value
        });
    };

    const handleUpdateFileSubmit = (docName, docId) => {
        dispatch(FileCreators.renameFolderRequest({ name: docName, id: docId, type: FILE_TYPE.file }));
        setRenameModal(false);
    };

    // Deleting a document
    const handleOpenDeleteDoc = (doc) => {
        setDeleteFolderModal(true);
        setFileToDelete(doc);
        setIsFolderSelected(false);
    };

    const handleDeleteFile = (docId) => {
        dispatch(FileCreators.deleteFolderRequest({ id: docId, type: FILE_TYPE.file, section: section }));
        setDeleteFolderModal(false);
    };

    const handleRemoveFromDropzone = (fileToRemove) => {
        const updatedFiles = uploadedFiles.filter(file => {
            return file.file !== fileToRemove;
        });
        setUploadedFiles(updatedFiles);
    };

    const redirectToSubfolder = (folderId, folderName) => {
        navigate({
            pathname: location.pathname + '/' + folderId,
            state: { name: folderName, folderId: folderId }
        });
    };

    const handleOpenDownload = (fileNameInS3) => {
        dispatch(FileCreators.downloadFileRequest({ section: section, fileName: fileNameInS3 }));
    };

    const handleFilesListView = () => {
        setFilesGridView(false);
        setFilesListView(true);
    };

    const handleFilesGridView = () => {
        setFilesListView(false);
        setFilesGridView(true);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        getFolders();

    };

    const handleOpenPreviewFile = (fileNameInS3) => {
        const fileUrl = IMAGE_URL + '/' + userId + '/Documents' + '/' + fileNameInS3;
        setPreviewFile(true);
        setPreviewS3File(fileUrl);
        setDocArray(prevDocArray => [...prevDocArray, { uri: fileUrl }]);

    };

    const handleClosePreviewFile = () => {
        setPreviewFile(false);
    };

    const handleLoadMoreButton = (num) => {
        setLimit(num);
        dispatch(FileCreators.getFolderListRequest({
            section: section, parent: getFolderIdFromUrl(), searchText: searchText, limit: num
        }));
    };

    const handleBackButton = () => {
        const lastIndex = location.pathname.lastIndexOf('/');
        const trimmedPath = location.pathname.substring(0, lastIndex);
        navigate({
            pathname: trimmedPath
        });
    };

    const handleSortByName = () => {
        setNameFieldActive(true);
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        dispatch(FileCreators.getFolderListRequest({
            section: section, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "name", sortOrder: newSortOrder
        }));
    };

    const handleSortByDate = () => {
        setNameFieldActive(false);
        const newSortOrder = sortOrderForDate === 'asc' ? 'desc' : 'asc';
        setSortOrderForDate(newSortOrder);
        dispatch(FileCreators.getFolderListRequest({
            section: section, parent: getFolderIdFromUrl(), searchText: searchText, limit: limit, sortBy: "createdAt", sortOrder: newSortOrder
        }));
    };

    const pdfPreview = (file) => {
        setViewDocument(true);
        const fileUrl = IMAGE_URL + '/' + userId + '/Documents' + '/' + file.fileNameInS3 + '?jwt=' + jwtToken;
        setPreviewFile(true);
        setPreviewS3File(fileUrl);
        setFileExtension(file.fileExtension);
        setFileName(file.name);
        setFileNameInS3(file.fileNameInS3);
    };


    return (
        <ThemeProvider theme={theme}>
            <Box className="files all-files">
                <Grid container spacing={2.5}>
                    <Grid size={{ xs: 12, }}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'start'}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'start'}
                                spacing={1}
                            >
                                {getFolderIdFromUrl() && <IconButton onClick={handleBackButton} size='small' style={{ marginTop: '2px' }} variant="text">
                                    <KeyboardBackspaceIcon />
                                </IconButton>}
                                <Box>
                                    <Box className="secondary">{breadCrumb}</Box>
                                    <Box className="small-subtitle">
                                        All Documents Listed here
                                    </Box>
                                </Box>
                            </Stack>
                            <Stack
                                direction={'row'}
                                spacing={0.5}
                                alignItems={'start'}
                                className="file-view-buttons"
                            >
                                <Box mr={2}>
                                    <form onSubmit={handleSearch}>
                                        <TextField
                                            name="searchText"
                                            variant='standard'
                                            placeholder="Search..."
                                            className="search"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img alt="" src={SearchIcon} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={searchText}
                                            onChange={(e) => { setSearchText(e.target.value); }}
                                        />
                                    </form>
                                </Box>
                                <Grid >
                                    <Stack spacing={1.25} direction={'row'}>
                                        <Button color="primary" size='small' variant="outlined" onClick={handleClickOpenCreateFolder}>
                                            Create
                                        </Button>
                                        <Button color="primary" size='small' variant="contained" onClick={handleClickOpenUploadFile}>
                                            Upload
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Button
                                    className={
                                        FilesListView ? 'viewBtn active' : 'viewBtn'
                                    }
                                    onClick={handleFilesListView}
                                >
                                    <img src={ListView} alt="" className="" />
                                </Button>
                                <Button
                                    className={
                                        FilesGridView ? 'viewBtn active' : 'viewBtn'
                                    }
                                    onClick={handleFilesGridView}
                                >
                                    <img src={GridView} alt="" className="" />
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid size={{ xs: 12, }} className={`files-container ${FilesGridView ? 'grid' : 'list'}`}>
                        {!isFolderListEmpty &&
                            <>
                                <Box mb={2} className="files-heading paragraph">
                                    Folders
                                </Box>

                                <Grid container spacing={FilesListView ? 0.5 : 3}>
                                    {FilesListView &&
                                        <Grid size={{ xs: FilesListView && 12, }}  >
                                            <Box className={`file-tile list-header`}>
                                                <Grid
                                                    container
                                                    alignItems={FilesListView ? 'center' : 'start'}
                                                    justifyContent="space-between"
                                                >

                                                    <Grid size={{ xs: "grow", }}>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={1}
                                                        >
                                                            <Box className='file-icon'>

                                                            </Box>
                                                            <Box className='file-details'>
                                                                <Box onClick={handleSortByName} className={nameFieldActive ? (sortOrder === "asc" ? "file-title sort-asc" : "file-title sort-des") : "file-title"} mr={2.75}>
                                                                    Name
                                                                </Box>
                                                                <Box onClick={handleSortByDate} className={!nameFieldActive ? (sortOrderForDate === "asc" ? "small-subtitle date sort-asc" : "small-subtitle date sort-des") : "small-subtitle date "}>
                                                                    Created on
                                                                </Box>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>

                                                    <Grid >
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                    {foldersList?.map((folder) => {
                                        return (
                                            <Grid
                                                size={{ xs: FilesListView && 12, }}
                                                className='file-row'
                                            >
                                                <Box onClick={() => redirectToSubfolder(folder._id, folder.name, folder.section)} className="file-tile">
                                                    <Grid
                                                        container
                                                        alignItems={FilesListView ? 'center' : 'start'}
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid size={{ xs: "grow", }}>
                                                            <Stack
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                spacing={1}
                                                            >
                                                                <Box className='file-icon'>
                                                                    <img src={newFolderIcon} />
                                                                </Box>
                                                                <Box className='file-details'>
                                                                    <Box className="file-title">
                                                                        {folder.name}
                                                                    </Box>
                                                                    {FilesListView &&
                                                                        <Box className="small-subtitle date">
                                                                            {moment(folder.createdAt).format('DD-MMMM-YYYY, h:mm A')}
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                            </Stack>
                                                            {!FilesListView &&
                                                                <Box className="small-subtitle">
                                                                    Created on: {moment(folder.createdAt).format('DD-MMMM-YYYY, h:mm A')}
                                                                </Box>
                                                            }
                                                        </Grid>
                                                        <Grid >
                                                            <Box onClick={(e) => e.stopPropagation()} className="options">
                                                                <IconButton
                                                                    className="options-button"
                                                                    size="small"
                                                                >
                                                                    <MoreHorizIcon
                                                                        style={{
                                                                            color: theme.palette.primary.dark,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                                <Box className="options-list">
                                                                    <div onClick={() => handleOpenRenameFolder(folder)}>Rename</div>
                                                                    <hr />
                                                                    <div className='required' onClick={() => handleOpenDeleteFolder(folder)}>Delete</div>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>

                        }
                        {!isFileListEmpty && !isFolderListEmpty &&
                            <Box component={'hr'} mt={3} />
                        }
                        {isFileListEmpty && isFolderListEmpty &&

                            <Stack
                                alignItems={'center'}
                                spacing={1}
                                className="no-files"
                            >
                                <Box p={1}>
                                    <img
                                        src={noDocIcon}
                                        alt="Folder Icon"
                                        className=""
                                    />
                                </Box>
                                <Box className="subtitle ">No Document found</Box>
                                <Box className="small-subtitle ">
                                    {subText}
                                </Box>
                            </Stack>
                        }
                        {!isFileListEmpty &&
                            <>
                                <Box mb={4} className="files-heading paragraph">
                                    Documents ({filesPresent})
                                </Box>

                                <Grid container spacing={FilesListView ? 0.5 : 3}>
                                    {FilesListView && isFolderListEmpty &&
                                        <Grid size={{ xs: FilesListView && 12, }}  >
                                            <Box className={`file-tile list-header`}>
                                                <Grid
                                                    container
                                                    alignItems={FilesListView ? 'center' : 'start'}
                                                    justifyContent="space-between"
                                                >

                                                    <Grid size={{ xs: "grow", }}>
                                                        <Stack
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            spacing={1}
                                                        >
                                                            <Box className='file-icon'>

                                                            </Box>
                                                            <Box className='file-details'>
                                                                <Box onClick={handleSortByName} className={nameFieldActive ? (sortOrder === "asc" ? "file-title sort-asc" : "file-title sort-des") : "file-title"} mr={2.75}>
                                                                    Name
                                                                </Box>
                                                                <Box onClick={handleSortByDate} className={!nameFieldActive ? (sortOrderForDate === "asc" ? "small-subtitle date sort-asc" : "small-subtitle date sort-des") : "small-subtitle date "}>
                                                                    Created on
                                                                </Box>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>

                                                    <Grid >
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    }
                                    {docNames?.map((file) => {
                                        return (
                                            <Grid size={{ xs: FilesListView && 12, }}

                                                className='file-row'
                                            >
                                                <Box onClick={() => pdfPreview(file)} className="file-tile">
                                                    <Grid
                                                        container
                                                        alignItems={FilesListView ? 'center' : 'start'}
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid size={{ xs: "grow", }}>
                                                            <Stack
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                spacing={1}
                                                            >
                                                                <Box className='file-icon' >
                                                                    <img
                                                                        src={file.fileExtension === 'pdf' ? pdfIcon
                                                                            : file.fileExtension === 'doc' ? DocsIcon
                                                                                : file.fileExtension === 'docx' ? DocsIcon
                                                                                    : file.fileExtension === 'jpg' ? imgIcon
                                                                                        : file.fileExtension === 'txt' ? DocsIcon
                                                                                            : file.fileExtension === 'pptx' ? pdfIcon
                                                                                                : sheetIcon}
                                                                    />
                                                                </Box>
                                                                <Box className='file-details'>
                                                                    <Box className="file-title">
                                                                        <>
                                                                            {['doc', 'xls'].includes(file.fileExtension) ?
                                                                                <Tooltip
                                                                                    title={"Sorry! Currently, we can't preview this file"}
                                                                                    placement="top"
                                                                                    arrow
                                                                                >

                                                                                    <Box onClick={(e) => e.stopPropagation()} component="p" mb={0} className="paragraph" style={{ cursor: "pointer" }}
                                                                                    >
                                                                                        {file.name}.{file.fileExtension}{' '}
                                                                                    </Box>
                                                                                </Tooltip>
                                                                                :
                                                                                <Box>


                                                                                    {file.name}.{file.fileExtension}
                                                                                </Box>
                                                                            }
                                                                        </>

                                                                    </Box>
                                                                    <Box className="small-subtitle date">
                                                                        {!FilesListView && 'Created on:'} {moment(file.createdAt).format('DD-MMMM-YYYY, h:mm A')}
                                                                    </Box>
                                                                </Box>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid >
                                                            <Box onClick={(e) => e.stopPropagation()} className="options">
                                                                <IconButton
                                                                    className="options-button"
                                                                    size="small"
                                                                >
                                                                    <MoreHorizIcon
                                                                        style={{
                                                                            color: theme.palette.primary.dark,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                                <Box className="options-list">
                                                                    <div onClick={() => handleOpenDownload(file.fileNameInS3)}>Download</div>
                                                                    <hr />
                                                                    <div onClick={() => handleOpenRenameDoc(file)}>Rename</div>
                                                                    <hr />
                                                                    <div className='required' onClick={() => handleOpenDeleteDoc(file)}>Delete</div>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>

                                        );
                                    })}
                                </Grid>
                            </>
                        }
                        {(foldersPresent > limit || filesPresent > limit) &&
                            <Stack direction={"row"} justifyContent={"center"} py={2}>
                                <Button

                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleLoadMoreButton(limit + 12)}
                                >
                                    Load More
                                </Button>
                            </Stack>
                        }

                    </Grid>
                </Grid>
            </Box>
            {/* Create Folder Modal */}
            <CreateFolderModal
                createFolder={createFolder}
                handleCloseCreateFolder={handleCloseCreateFolder}
                handleClickOpenCreateFolder={handleClickOpenCreateFolder}
                handleCreateNewFolder={handleCreateNewFolder}
                handleFolderChange={handleFolderChange}
                folderMessage={folderMessage}
            />

            {/* Upload Modal */}
            <UploadFolderModal
                uploadedFiles={uploadedFiles}
                uploadFile={uploadFile}
                showFiles={showFiles}
                extensionNames={extensionNames}
                handleCloseUploadFile={handleCloseUploadFile}
                handleClickOpenUploadFile={handleClickOpenUploadFile}
                handleSelectedFiles={handleSelectedFiles}
                handleRemoveFromDropzone={handleRemoveFromDropzone}
                handleUploadDocument={handleUploadDocument}
                subText={subText}
                smallTextOne={smallTextOne}
                smallTextTwo={smallTextTwo}
                smallTextThree={smallTextThree}
            />
            {/* Rename Folder Modal */}
            <RenameFolderModal
                renameModal={renameModal}
                folderToRename={folderToRename}
                fileToRename={fileToRename}
                isFolderSelected={isFolderSelected}
                handleCloseRenameFolder={handleCloseRenameFolder}
                handleUpdateFolderName={handleUpdateFolderName}
                handleUpdateFileName={handleUpdateFileName}
                handleUpdateFolderSubmit={handleUpdateFolderSubmit}
                handleUpdateFileSubmit={handleUpdateFileSubmit}
            />
            {/* Delete Folder Modal */}
            <DeleteFolderModal
                isFolderSelected={isFolderSelected}
                deleteFolderModal={deleteFolderModal}
                folderToDelete={folderToDelete}
                fileToDelete={fileToDelete}
                handleCloseDeleteFolder={handleCloseDeleteFolder}
                handleDeleteFolder={handleDeleteFolder}
                handleDeleteFile={handleDeleteFile}
            />

            {viewDocument &&
                <PdfPreview
                    previewS3File={previewS3File}
                    fileExtension={fileExtension}
                    fileName={fileName}
                    previewFile={previewFile}
                    handleClosePreviewFile={handleClosePreviewFile}
                    fileNameInS3={fileNameInS3}
                />
            }


            {fileObject.loading && <Loader />}
        </ThemeProvider>
    );
};


export default Docs;
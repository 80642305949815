import { createReducer } from 'reduxsauce'
import { Constants } from '../../Actions'

const INITIAL_STATE = {
    isGmailConnected: false,
    gmailData: {},
}

const setGmailConnectionState = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isGmailConnected: action.isConnected,
        gmailData: action.gmailData

    }
}

const HANDLERS = {
    [Constants.SET_GMAIL_CONNECTION_STATE]: setGmailConnectionState,
}

export default createReducer(INITIAL_STATE, HANDLERS)
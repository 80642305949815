import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import '../../sass/main.scss'
import illumeetLogo from '../../assets/images/loginLogo.png'
import Shape1 from '../../assets/images/Path_421.png'
import Shape2 from '../../assets/images/Path_422.png'
import Shape3 from '../../assets/images/Path_423.png'
import Shape4 from '../../assets/images/Path_424.png'
import Shape5 from '../../assets/images/Path_425.png'

class Invitation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      acceptToken: '',
      acceptSt: 1,
      errors: {},
      loading: false,
      success: '',
    }
  }

  componentDidMount() {
    if (
       this.props.params.token !== '' &&
       this.props.params.status !== ''
    ) { 
      this.setState({
        acceptToken:  this.props.params.token,
        acceptSt:  this.props.params.status,
      })

      const requestbody = {
        acceptToken:  this.props.params.token,
        acceptSt:  this.props.params.status,
        emailVerified: true,
      }
      this.props.invitation(requestbody)
      this.handleLoading()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const members = this.props.members
    if (
      members &&
      members.updateSuccess &&
      this.props.members.message !== prevProps.members.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      createNotification('success', members.message, '')
      this.setState({ success: members.message })
    } else if (members && members.updateError && this.state.loading) {
      createNotification('error', members.message, '')
      this.setState({ error: members.message })
      this.handleLoading()
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    const { error, loading, success } = this.state
    return (
      <div className="main_page">
        <div className="registerPage">
          <div className="leftSidebar">
            <div className="registerSidebarBrand">
              <img src={illumeetLogo} alt="Illumeet Logo" />
              <div className="brandingText">
                <h1>
                  One platform. <br />
                  One process. <br />
                  All your events.
                </h1>
              </div>
            </div>
            <div className="signupShapes">
              <span className="shape-1">
                <img src={Shape1} alt="" />
              </span>
              <span className="shape-2">
                <img src={Shape2} alt="" />
              </span>
              <span className="shape-3">
                <img src={Shape3} alt="" />
              </span>
              <span className="shape-4">
                <img src={Shape4} alt="" />
              </span>
              <span className="shape-5">
                <img src={Shape5} alt="" />
              </span>
            </div>
          </div>
          <div className="registerFormMain">
            <div className="registerFormInner">
              <div className="pageHeading text-center">
                <h1>Member Invitation Status</h1>
                {loading ? <Loader /> : null}
              </div>
              <div className="formSection LoginForm">
                <form>
                  <div className="field-group fullWidthInput loginHere">
                    {this.props.members.success ? (
                      <p>{success}</p>
                    ) : (
                      <p>{error}</p>
                    )}
                    <br />
                    { this.props.params.status &&
                       this.props.params.status != 3 && (
                        <button
                          onClick={() => this.props.navigate('/')}
                          className="themeBlueButton"
                        >
                          Login Here
                        </button>
                      )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <NotificationContainer />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  members: state.Members,
  // loading: state.Loader.loaderOne,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      invitation: Actions.invitationRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Invitation)

import React, { Component } from 'react'
import '../../sass/main.scss'
import EditingBack from '../../assets/images/backPage.png'
import footerLogo from '../../assets/images/footer__logo.png'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { createNotification, redirectTo } from '../../helpers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { NotificationContainer } from 'react-notifications'
import moment from 'moment'
import PageToolBar from '../../Components/PageToolBar'
import { getEmailCampaigns } from 'ReduxStore/API'

class Reviews extends Component {
  constructor() {
    super()
    this.state = {
      pageTitle: 'Review',
      eventId: '',
      loading: false,
      eventName: '',
      eventDomain: '',
      location: '',
      startDate: new Date(),
      time: '',
      endDate: '',
      emailSenderName: '',
      emailSubject: '',
      templateCount: 1,
    }
  }

  componentDidMount() {
    if ( this.props.params.eventId !== '') {
      this.setState({ eventId:  this.props.params.eventId })
      this.props.eventRequest( this.props.params.eventId)

      getEmailCampaigns( this.props.params.eventId)
        .then((result) => {
          if (result.data.status === 200) {
            //createNotification('success', result.data.message);
            this.setState({ templateCount: result.data.allTemplates.length })
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.events) {
      if (
        this.props.events.success === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        let st = prevState.loading ? this.setState({ loading: false }) : null
        const events = this.props.events.event

        this.setState({
          eventName: events.eventName,
          eventDomain: events.eventDomain,
          location: events.location,
          time: events.time,
          startDate: new Date(events.startDate),
          endDate: new Date(events.endDate ? events.endDate : events.startDate),
          _id: events._id,
          emailSenderName: events.emailSenderName,
          emailSubject: events.emailSubject,
        })
      }
      if (
        this.props.events.updateSuccess === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        createNotification('success', this.props.events.message)
        // this.handleLoading();
        let st = prevState.loading ? this.setState({ loading: false }) : null
        this.props.eventRequest(this.state.eventId)
      }

      if (
        this.props.events.error &&
        this.props.events.error !== prevProps.events.error
      ) {
        createNotification('error', this.props.events.message, '')
        this.handleLoading()
      }
    }
  }

  render() {
    const {
      pageTitle,
      eventId,
      loading,
      eventName,
      startDate,
      time,
      eventDomain,
      location,
      emailSenderName,
      emailSubject,
      templateCount,
    } = this.state
    let startDate1 = moment(startDate).format('MM/DD/YYYY')
    const startD = startDate1 + ' ' + time
    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <div className="editingDashboard">
          <PageToolBar
            showIcons={false}
            onSaveFn={() =>
              redirectTo(
                this.props.navigate,
                `email-campaigns/${eventId}`,
                this.props.location.pathname,
              )
            }
            saveBtnLabel="Proceed To Email Campaign"
            showPreviewOption={false}
            showChangeConfirmation={false}
            showActiveBtn={false}
            // backTo={`/resipients/${eventId}`}
          />
          <div className="editDBSteppers">
            <div class="projectStepRow mb-5">
              <div class="projectSteps">
                <ul class="projectStepColl fourStepColl">
                  {/*  <li class="active">
                                <span class="projectProcess"></span>
                                Event Details
                            </li>
                            <li class="active">
                                <span class="projectProcess"></span>
                                Design
                            </li>*/}
                  <li class="active">
                    <span class="projectProcess"></span>
                    Recipients
                  </li>
                  <li>
                    <span class="projectProcess singleDot"></span>
                    Review
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="recipientsPage">
            <div class="fullSreenHeader inputField mb-5">
              <div class="headingRecipients">
                <h2>Confirm that everything is correct</h2>
              </div>
            </div>
            <div class="reviewContentSection">
              <div class="reviewcardList">
                <div class="reviewcard">
                  <div class="eventSummary">
                    <div class="eventDetailsicon">
                      <i class="fa fa-envelope"></i>
                    </div>
                    <div class="eventDetailsFull">
                      <label>Total Email Template</label>
                      <p>{templateCount}</p>
                    </div>
                  </div>
                </div>
                <div class="reviewcard">
                  <div class="eventSummary">
                    <div class="eventDetailsicon">
                      <i class="fa fa-envelope"></i>
                    </div>
                    <div class="eventDetailsFull">
                      <label>Campaign Name: {eventName}</label>
                      <p>
                        Event Start Date -{' '}
                        {moment(startD).format('dddd, MM Do YYYY, LT')}{' '}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="reviewcard successRecipients">
                  <div class="eventSummary">
                    <div class="eventDetailsicon">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="eventDetailsFull">
                      <label>Sender Name:</label>
                      <p>{emailSenderName}</p>
                    </div>
                  </div>
                </div>
                <div class="reviewcard successRecipients">
                  <div class="eventSummary">
                    <div class="eventDetailsicon">
                      <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="eventDetailsFull">
                      <label>Subject Line: </label>
                      <p>{emailSubject}</p>
                    </div>
                  </div>
                </div>
                {/*  <div class="SubchildReviewCard">
                            <div class="InnerMainChildCard">
                                <div class="eventSummary">
                                    <div class="eventDetailsicon">
                                        <i class="fa fa-link"></i>
                                    </div>
                                    <div class="eventDetailsFull">
                                        <label>Calendar Invite: </label>
                                        <p>Attached <a href="">(Download Calendar Invite)</a></p>
                                    </div>
                                </div>
                                <div class="getLink">
                                    <a href="#" class="accessRequest">Remove</a>
                                </div>
                            </div>
                            <div class="InnerViewcard">
                                <div class="eventSummary">
                                    <div class="eventDetailsicon">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="eventDetailsFull">
                                        <label>webinar with client</label>
                                        <p>{location}</p>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
              </div>
              <div class="eventpageSetting">
                <h3>Event Page Settings</h3>
                <div class="reviewcard">
                  <div class="eventSummary">
                    <div class="eventDetailsicon">
                      <i class="fa fa-cog"></i>
                    </div>
                    <div class="eventDetailsFull">
                      <label>Event Summary:</label>
                      <p>Title: {eventName}</p>
                      <p>Domain: {eventDomain}</p>
                      <p>Location: {location}</p>
                      <p>
                        Time & Date:{' '}
                        {moment(startD).format('dddd, MM Do YYYY, LT')}
                      </p>
                    </div>
                  </div>
                  <div class="getLink">
                    <Link
                      onClick={() =>
                        redirectTo(
                          this.props.navigate,
                          `update-event/${eventId}`,
                          this.props.location.pathname,
                        )
                      }
                    >
                      Edit
                    </Link>
                  </div>
                </div>

                <div class="reviewcard">
                  <div class="eventSummary">
                    <div class="eventDetailsicon">
                      <i class="fa fa-lock"></i>
                    </div>
                    <div class="eventDetailsFull">
                      <label>Page Access:</label>
                      <p>Event page is public</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
        {/* <footer className="footer_main">
            <div className="footer_logo">
                <img src={footerLogo} alt="" />
            </div>
        </footer>  */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateEvent: Actions.updateEventsRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Reviews)

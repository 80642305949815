import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../../theme/theme';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { connect } from 'react-redux';
import { Stack, Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { createNotification, redirectTo} from '../../../helpers'; 
import QRCode from "../../../Common/qrcode";
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet' 
import { languageCode } from 'helpers/common';
import { UserData } from 'helpers/common'
import withCustomRouter from '../../../Common/withCustomRouter';  
const URL = process.env.REACT_APP_API_URL 

const isUrlValid = (userInput) => {
  var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  if (res == null)
    return false;
  else
    return true;
} 
export class PagePreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: "",
      templateId: "blank",
      pageTemplates: [],
      pageName: "",
      pageNameUrl: "",
      preview: {},
      pageTitle: "",
      pageDesc: "",
      customCss: "",
      customeJs: "",
      eventStartDate: "",
      pageNameFromURL: "",
      isAccessCode: true, 
      loaded: '', 
    }
  }

  UserData = async () => {
    const currentPath = window.location.pathname
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'))
    } else {
      return JSON.parse(localStorage.getItem('user_details'))
    }
  }

   

  qrCodePreview = async () => { 
    let getQrcodeDiv = document.getElementsByClassName('outputQrcodeclassDiv');
    
    setTimeout(() => {
      if (getQrcodeDiv) {
        for (let i = 0; i < getQrcodeDiv.length; i++) {
          let qrdropdownval = getQrcodeDiv[i].innerText
          let qrCodeFgColornew = getQrcodeDiv[i].getAttribute('colordark') ? getQrcodeDiv[i].getAttribute('colordark') : '#000000';
          let qrCodeBgColornew = getQrcodeDiv[i].getAttribute('colorlight') ? getQrcodeDiv[i].getAttribute('colorlight') : '#ffffff';
          let size = getQrcodeDiv[i].getAttribute('size') ? getQrcodeDiv[i].getAttribute('size') : 400;
           var qrrcode=  new QRCode(document.getElementById("qrcodeId"), {
            text: `${qrdropdownval}`,
            width: size,
            height: size,
            colorDark: `${qrCodeFgColornew}`,
            colorLight: `${qrCodeBgColornew}`,
            correctLevel: QRCode.CorrectLevel.H
        });
        }
      } 
    },20)
  }
  async getPagePerview(eventId, path, fromMyTemp) {
    const users = await this.UserData()
    let url = ''
    try { 
      if (this.state.sentFrom === 'selectCampaignTemplates') {
        url = `${URL}/campaigns/previewPage?eventId=${eventId}&path=${path}&templateEventId=${this.state.templateEventId}&campaignType=${this.state.campaignType}`
      } else if (this.state.sentFrom === 'managepages') {
        url = `${URL}/events/previewPage?eventId=${eventId}&path=${path}`
      } else if (this.state.sentFrom === 'selectPageTemplates') {
        url = `${URL}/pages/previewPage?eventId=${eventId}&path=${path}&pageType=${this.state.pageType}&pageTemplateId=${this.state.pageTemplateId}&templateEventId=${this.state.templateEventId}`
      } else if (this.state.sentFrom === 'campaignEditorPage') {
        url = `${URL}/events/previewCampaignPage?eventId=${eventId}&path=${path}`
      } else {
        if (fromMyTemp === "true") {
          url = `${URL}/events/previewPage?eventId=${eventId}&path=${path}&myTemp=true`
        } else {
          url = `${URL}/events/previewPage?eventId=${eventId}&path=${path}`
        }

      }
      const response = await axios.get(url, {
        headers: {
          token: `bearer ${users.token} `,
          'Content-Type': 'application/json',
        }
      });
      if (response.data && response.data.status == 200) {
        this.qrCodePreview()
        return response?.data
      } else {
        throw Error(response.data.error)
      }
    } catch (err) {
      createNotification("error", err.message);
    }
  }

  componentDidMount() {
    const { eventId, pn, purl, pageName, pageNameUrl } = this.props.params; 
     
    console.log("params~~~~~~~~~~~~~~~~~~",this.props.params)
   
    const users = UserData();
    this.props.handleLang(languageCode(users.language));
    this.props.newLangStateForRedux(users.language)
   
    let params = this.props.location;
    let pagepath = new URLSearchParams(params.search).get("pagepath")
    let fromMyTemp = new URLSearchParams(params.search).get("myTemp")
    let sentFrom = new URLSearchParams(params.search).get("sentFrom")
    let campaignName = new URLSearchParams(params.search).get("campaignName")
    let campaignType = new URLSearchParams(params.search).get("campaignType")
    let pageNameFromURL = new URLSearchParams(params.search).get("pageName")
    let pageUrl = new URLSearchParams(params.search).get("pageUrl")
    let pageType = new URLSearchParams(params.search).get("pageType")
    let pageTemplateId = new URLSearchParams(params.search).get("pageTemplateId")
    let templateEventId = new URLSearchParams(params.search).get("templateEventId")
    let lngCode = new URLSearchParams(params.search).get("lngCode")
    this.setState({
      sentFrom,
      campaignName,
      pagepath,
      pageNameFromURL,
      pageUrl,
      pageType,
      pageTemplateId,
      templateEventId,
      campaignType,
      lngCode
    }, async () => {
      let pagedetails = await this.getPagePerview(eventId, pagepath, fromMyTemp);
      this.setState({
        eventId:  this.props.params.eventId,
        preview: pagedetails?.data,
        pageId: pagedetails?.data?._id,
        pageName: pagedetails?.data?.pageName,
        path: pagedetails?.data?.path,
        pageTitle: pagedetails?.data?.pageTitle,
        pageDesc: pagedetails?.data?.pageDesc,
        customCss: pagedetails?.data?.customCss,
        customeJs: pagedetails?.data?.customeJs,
        eventStartDate: pagedetails?.eventData?.startDate,
        pagedetails
      });
      // this.props.handleLang(languageCode(this.props.users.newLangState));
      //code to hide access code field from preview if not active access codes
      setTimeout(() => {
        var accessCodeDataDiv = document.getElementById('accessCodeDataDiv');
        if (accessCodeDataDiv) {
          accessCodeDataDiv.style.display = 'none';
          if (this.state.pagedetails?.eventData?.isAccessCode == true) {
            accessCodeDataDiv.style.display = 'flex';
            document.getElementById('accesscodeInput').required = true;
          } else {
            accessCodeDataDiv.style.display = 'none';
            document.getElementById('accesscodeInput').required = false;
          }
        }
      }, 0)
    })

    setTimeout(() => {
      var Startnow = document.getElementById('StartCountDown');
      var SetCountDown = document.getElementById('SetCountDown');
      if (Startnow && Startnow.value !== "false") {
        if (SetCountDown && SetCountDown.value !== "undefined" && SetCountDown.value !== "") {
          let setCount = SetCountDown.value;
          let customDate = new Date(setCount).getTime();
          this.countDown(customDate);
        } else {
          if (this.state.eventStartDate) {
            let statDateIs = new Date(this.state.eventStartDate).getTime();
            this.countDown(statDateIs);
          }
        }
      }
    }, 0)


    /* count Down timer widget function start here */
    setTimeout(() => {
      var StartWidgetCountDown = document.getElementById('StartWidgetCountDown');
      var timerStartDate = document.getElementById('timerStartDate');
      if (StartWidgetCountDown && StartWidgetCountDown.value !== "false") {
        if (timerStartDate && timerStartDate.value !== "undefined" && timerStartDate.value !== "") {
          // alert(timerStartDate.value);
          let setCount = timerStartDate.value;
          let customWidgetDate = new Date(setCount).getTime();
          this.countDownWidgetTimerFun(customWidgetDate);
        }
      }
    }, 1000)
    /* count Down timer widget function end here */
  }


  exitPreview = () => {
    let url = ''
    const { pageTemplateId, pageNameFromURL, pagepath, eventId } = this.state
    if (this.state.sentFrom === 'managepages') {
      url = `/project-dashboard/manage-pages/${ this.props.params.eventId}`;
    } else if (this.state.sentFrom === 'selectCampaignTemplates') {
      url = `/project-dashboard/campaigns/select-template/ei/${this.props.params.eventId}/cn/${this.state.campaignName}`
    } else if (this.state.sentFrom === 'selectPageTemplates') {
      url = `/select-page-template/pid/${this.props.params.eventId}/pn/${this.state.pageNameFromURL}/purl/${this.state.pageUrl}`
    } else if (this.state.sentFrom === 'selectProjectTemplates') {
      url = `/create-project`
    } else if (this.state.sentFrom === 'pageEditor') {
      url = `/project-page/update-page/pid/${this.props.params.eventId}/tid/${pageTemplateId}/pn/${pageNameFromURL}/purl/${pagepath}`
    } else if (this.state.sentFrom === 'projectPageEditor' || this.state.sentFrom === 'campaignEditorPage' || this.state.sentFrom ==='projectBadgesEditor') {
      window.close()
    }
    this.props.navigate({
      pathname: url
    })
  }

  redirectToManagePage = () => {
    window.location.href = `/project-dashboard/manage-pages/${ this.props.params.eventId}`;
  }

  async openUnlayerEditor(pageId, pageName, pageUrl) {
    if (this.state.sentFrom === 'managepages') {
      this.props.navigate({
        pathname: `/project-page/update-page/pid/${this.props.params.eventId}/tid/${pageId}/pn/${pageName}/purl/${pageUrl}`,
        state: { lngCode: this.props.users.newLangState},
      })
    } else if (this.state.sentFrom === 'selectCampaignTemplates') {
      this.props.navigate({
        pathname: `/project-dashboard/campaign-template-edior/tid/${this.state.pagepath}/cn/${this.state.campaignName}/eid/${this.state.eventId}`,
        state: { template: this.props.location.state.template, fromSelectTemplatePage: true }
      })
    } else if (this.state.sentFrom === 'selectPageTemplates') {
      this.props.navigate({
        pathname: `/project-page/pid/${this.props.params.eventId}/tid/${pageId}/pn/${this.state.pageNameFromURL}/purl/${this.state.pageUrl}`,
        state: { template: this.props.location.state.template, fromSelectTemplatePage: true }
      })
    }
  }

  /* Login form code will end here */
  countDown = (eventStartDate) => {
    var StartCountDown = document.getElementById('StartCountDown');
    if (StartCountDown.value !== "false") {
      // The data/time we want to countdown to
      setTimeout(() => {
        var CountDownBackgroundColor = document.getElementById('CountDownBackgroundColor');
        var backgroundColor = "";
        var fontColor = "";
        if (CountDownBackgroundColor && !CountDownBackgroundColor.value == "") {
          if (CountDownBackgroundColor.value) {
            backgroundColor = CountDownBackgroundColor.value;
          } else {
            backgroundColor = "#281374";
          }
        }

        var CountDownTextColor = document.getElementById('CountDownTextColor');
        if (CountDownTextColor && !CountDownTextColor.value == "") {
          if (CountDownTextColor.value) {
            fontColor = CountDownTextColor.value;
          } else {
            fontColor = "#ffffff";
          }
        } else {
          fontColor = "#ffffff";
        }
        var CountDownAlign = "";
        var bgcolor = backgroundColor;
        var mainbody = document.getElementById("product-card-body");
        if (mainbody) {
          if (!document.getElementById("coming-soon-left-top")) {
            mainbody.insertAdjacentHTML('afterbegin', ' <div class="coming-soon-left-top" id="coming-soon-left-top" style="text-align:' + CountDownAlign + '"> <div class="lauch-div" id="demo"></div></div>');
          }
        } 
        var timerHtml = '<div  class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="days"></div><div class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="hours"></div><div  class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="mins"></div><div  class="date-list" style="background-color:' + bgcolor + '; color: ' + fontColor + '" id="secs"></div>';
        var timerHtmlVar = document.getElementById("demo");

        var countDownDate = new Date(eventStartDate).getTime();
        // Run myfunc every second
        var myfunc = setInterval(function () {
          var now = new Date().getTime();
          var timeleft = countDownDate - now;
          // Calculating the days, hours, minutes and seconds left
          var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
          var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

          var demoBody = document.getElementById("demo");
          if (demoBody) {
            if (timerHtmlVar) {
              timerHtmlVar.innerHTML = timerHtml;
            }
          }
          // Result is output to the specific element
          var timerdays = document.getElementById("days");
          if (timerdays) {
            timerdays.innerHTML = days + " <span style='display: block'>DAYS</span> ";
            document.getElementById("hours").innerHTML = hours + "<span style='display: block'>  HRS</span>  ";
            document.getElementById("mins").innerHTML = minutes + "<span style='display: block'>  MINS</span>  ";
            document.getElementById("secs").innerHTML = seconds + "<span style='display: block'>  SECS</span>  ";
          }
          // Display the message when countdown is over
          if (timeleft < 0) {
            clearInterval(myfunc);
            document.getElementById("days").innerHTML = "";
            document.getElementById("hours").innerHTML = "";
            document.getElementById("mins").innerHTML = "";
            document.getElementById("secs").innerHTML = "";
            var TimerBody1 = document.getElementById("coming-soon-left-top");
            if (TimerBody1) {
              TimerBody1.remove();
            }
          }
        }, 1000);
      }, 0)
    } else {
      var TimerBody = document.getElementById("coming-soon-left-top");
      if (TimerBody) {
        TimerBody.remove();
      }
    }
  }


  countDownWidgetTimerFun = (widgetTimerStartDateTime) => {
    var StartCountDown = document.getElementById('StartWidgetCountDown');


    if (StartCountDown.value !== "false") {
      // The data/time we want to countdown to
      setTimeout(() => {
        var CountDownBackgroundColor = document.getElementById('CountDownWidgetBackgroundColor');
        var backgroundColor = "";
        var fontColor = "";
        if (CountDownBackgroundColor && !CountDownBackgroundColor.value == "") {
          if (CountDownBackgroundColor.value) {
            backgroundColor = CountDownBackgroundColor.value;
          } else {
            backgroundColor = "#281374";
          }
        }

        var CountDownTextColor = document.getElementById('CountDownWidgetTextColor');
        if (CountDownTextColor && !CountDownTextColor.value == "") {
          if (CountDownTextColor.value) {
            fontColor = CountDownTextColor.value;
          } else {
            fontColor = "#ffffff";
          }
        }

        var timerTimeType = "";
        var timerTimeTypeHtml = document.getElementById('timerTimeType');
        if (timerTimeTypeHtml) {
          timerTimeType = timerTimeTypeHtml.value;
        }

        var timerStartDate = "";
        var timerStartDateHtml = document.getElementById('timerStartDate');
        if (timerStartDateHtml) {
          timerStartDate = timerStartDateHtml.value;
        }

        var CountDownWidgetDaysLabel = "";
        var CountDownWidgetDaysLabelHtml = document.getElementById('CountDownWidgetDaysLabel');
        if (CountDownWidgetDaysLabelHtml) {
          CountDownWidgetDaysLabel = CountDownWidgetDaysLabelHtml.value;
        }

        var CountDownWidgetHrsLabel = "";
        var CountDownWidgetHrsLabelHtml = document.getElementById('CountDownWidgetHrsLabel');
        if (CountDownWidgetHrsLabelHtml) {
          CountDownWidgetHrsLabel = CountDownWidgetHrsLabelHtml.value;
        }
        var CountDownWidgetMinsLabel = "";
        var CountDownWidgetMinsLabelHtml = document.getElementById('CountDownWidgetMinsLabel');
        if (CountDownWidgetMinsLabelHtml) {
          CountDownWidgetMinsLabel = CountDownWidgetMinsLabelHtml.value;
        }

        var CountDownWidgetSecsLabel = "";
        var CountDownWidgetSecsLabellHtml = document.getElementById('CountDownWidgetSecsLabel');
        if (CountDownWidgetSecsLabellHtml) {
          CountDownWidgetSecsLabel = CountDownWidgetSecsLabellHtml.value;
        }

        var StartWidgetCountDown = "";
        var StartWidgetCountDownHtml = document.getElementById('StartWidgetCountDown');
        if (StartWidgetCountDownHtml) {
          StartWidgetCountDown = StartWidgetCountDownHtml.value;
        }

        var cdctBorderTopStyle = "";
        var cdctBorderTopStyleHtml = document.getElementById('cdctBorderTopStyle');
        if (cdctBorderTopStyleHtml) {
          cdctBorderTopStyle = cdctBorderTopStyleHtml.value;
        }

        var cdctBorderTopColor = "";
        var cdctBorderTopColorHtml = document.getElementById('cdctBorderTopColor');
        if (cdctBorderTopColorHtml) {
          cdctBorderTopColor = cdctBorderTopColorHtml.value;
        }

        var cdctBorderTopWidth = "";
        var cdctBorderTopWidthHtml = document.getElementById('cdctBorderTopWidth');
        if (cdctBorderTopWidthHtml) {
          cdctBorderTopWidth = cdctBorderTopWidthHtml.value;
        }

        var cdctBorderLeftStyle = "";
        var cdctBorderLeftStyleHtml = document.getElementById('cdctBorderLeftStyle');
        if (cdctBorderLeftStyleHtml) {
          cdctBorderLeftStyle = cdctBorderLeftStyleHtml.value;
        }
        var cdctBorderLeftColor = "";
        var cdctBorderLeftColorHtml = document.getElementById('cdctBorderLeftColor');
        if (cdctBorderLeftColorHtml) {
          cdctBorderLeftColor = cdctBorderLeftColorHtml.value;
        }
        var cdctBorderLeftWidth = "";
        var cdctBorderLeftWidthHtml = document.getElementById('cdctBorderLeftWidth');
        if (cdctBorderLeftWidthHtml) {
          cdctBorderLeftWidth = cdctBorderLeftWidthHtml.value;
        }
        var cdctBorderRightStyle = "";
        var cdctBorderRightStyleHtml = document.getElementById('cdctBorderRightStyle');
        if (cdctBorderRightStyleHtml) {
          cdctBorderRightStyle = cdctBorderRightStyleHtml.value;
        }

        var cdctBorderRightColor = "";
        var cdctBorderRightColorHtml = document.getElementById('cdctBorderRightColor');
        if (cdctBorderRightColorHtml) {
          cdctBorderRightColor = cdctBorderRightColorHtml.value;
        }

        var cdctBorderRightWidth = "";
        var cdctBorderRightWidthHtml = document.getElementById('cdctBorderRightWidth');
        if (cdctBorderRightWidthHtml) {
          cdctBorderRightWidth = cdctBorderRightWidthHtml.value;
        }

        var cdctBorderBottomStyle = "";
        var cdctBorderBottomStyleHtml = document.getElementById('cdctBorderBottomStyle');
        if (cdctBorderBottomStyleHtml) {
          cdctBorderBottomStyle = cdctBorderBottomStyleHtml.value;
        }

        var cdctBorderBottomColor = "";
        var cdctBorderBottomColorHtml = document.getElementById('cdctBorderBottomColor');
        if (cdctBorderBottomStyleHtml) {
          cdctBorderBottomColor = cdctBorderBottomColorHtml.value;
        }

        var cdctBorderBottomWidth = "";
        var cdctBorderBottomWidthHtml = document.getElementById('cdctBorderBottomWidth');
        if (cdctBorderBottomWidthHtml) {
          cdctBorderBottomWidth = cdctBorderBottomWidthHtml.value;
        }

        var cdtwctRoundedBorderAll = "";
        var cdtwctRoundedBorderAllHtml = document.getElementById('cdtwctRoundedBorderAll');
        if (cdtwctRoundedBorderAllHtml) {
          cdtwctRoundedBorderAll = cdtwctRoundedBorderAllHtml.value;
        }
        var cdtwctRoundedBorderTopLeft = "";
        var cdtwctRoundedBorderTopLeftHtml = document.getElementById('cdtwctRoundedBorderTopLeft');
        if (cdtwctRoundedBorderTopLeftHtml) {
          cdtwctRoundedBorderTopLeft = cdtwctRoundedBorderTopLeftHtml.value;
        }

        var cdtwctRoundedBorderTopRight = "";
        var cdtwctRoundedBorderTopRightHtml = document.getElementById('cdtwctRoundedBorderTopRight');
        if (cdtwctRoundedBorderTopRightHtml) {
          cdtwctRoundedBorderTopRight = cdtwctRoundedBorderTopRightHtml.value;
        }

        var cdtwctRoundedBorderBottomleft = "";
        var cdtwctRoundedBorderBottomleftHtml = document.getElementById('cdtwctRoundedBorderBottomleft');
        if (cdtwctRoundedBorderBottomleftHtml) {
          cdtwctRoundedBorderBottomleft = cdtwctRoundedBorderBottomleftHtml.value;
        }

        var cdtwctRoundedBorderBottomRight = "";
        var cdtwctRoundedBorderBottomRightHtml = document.getElementById('cdtwctRoundedBorderBottomRight');
        if (cdtwctRoundedBorderBottomRightHtml) {
          cdtwctRoundedBorderBottomRight = cdtwctRoundedBorderBottomRightHtml.value;
        }
        var projectId = "";
        var projectIdHtml = document.getElementById('projectId');
        if (projectIdHtml) {
          projectId = projectIdHtml.value;
        }

        var CountDownAlign = "";
        var bgcolor = backgroundColor;
        var mainbodyTimerWidget = document.getElementById("product-card-body-timer");
        if (mainbodyTimerWidget) {
          if (!document.getElementById("coming-soon-left-top-widget")) {
            mainbodyTimerWidget.insertAdjacentHTML('afterbegin', ' <div class="coming-soon-left-top-widget" id="coming-soon-left-top-widget" style="text-align:' + CountDownAlign + '"> <div class="lauch-div" id="countdown-timer-custom-tool-demo"></div></div>');
          }
        }

        var timerHtmlWidget = `<div style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor}; border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;" class="date-list " id="daysTimer"></div>
        
        
        <div style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor};border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;"class="date-list" id="hoursTimer"></div>
        
        <div class="date-list" style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor};border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;" id="minsTimer"></div> 
        
        <div style="background-color: ${bgcolor}; color: ${fontColor}; border-top-style: ${cdctBorderTopStyle}; border-top-color: ${cdctBorderTopColor};border-top-width: ${cdctBorderTopWidth};border-left-style: ${cdctBorderLeftStyle}; border-left-color: ${cdctBorderLeftColor}; border-left-width: ${cdctBorderLeftWidth}; border-right-style: ${cdctBorderRightStyle};border-right-color: ${cdctBorderRightColor}; border-right-width: ${cdctBorderRightWidth}; border-bottom-style: ${cdctBorderBottomStyle}; border-bottom-color: ${cdctBorderBottomColor}; border-bottom-width: ${cdctBorderBottomWidth};border-radius: ${cdtwctRoundedBorderAll}px;border-top-left-radius:${cdtwctRoundedBorderTopLeft}px;border-top-right-radius:${cdtwctRoundedBorderTopRight}px; border-bottom-left-radius:${cdtwctRoundedBorderBottomleft}px; border-bottom-right-radius:${cdtwctRoundedBorderBottomRight}px;" class="date-list" id="secsTimer"></div>`


        var timerHtmlWidgetVar = document.getElementById("countdown-timer-custom-tool-demo");
        var countDownDate = new Date(widgetTimerStartDateTime).getTime();
        var daysTimerLabelHtml = document.getElementById("CountDownWidgetDaysLabel");
        var hrsTimerLabelHtml = document.getElementById("CountDownWidgetHrsLabel");
        var minsTimerLabelHtml = document.getElementById("CountDownWidgetMinsLabel");
        var secsTimerLabelHtml = document.getElementById("CountDownWidgetSecsLabel");
        if (daysTimerLabelHtml) {
          var daysTimerLabel = daysTimerLabelHtml.value ? daysTimerLabelHtml.value : '';
        }
        if (hrsTimerLabelHtml) {
          var hrsTimerLabel = hrsTimerLabelHtml.value ? hrsTimerLabelHtml.value : '';
        }
        if (minsTimerLabelHtml) {
          var minsTimerLabel = minsTimerLabelHtml.value ? minsTimerLabelHtml.value : '';
        }
        if (secsTimerLabelHtml) {
          var secsTimerLabel = secsTimerLabelHtml.value ? secsTimerLabelHtml.value : '';
        }

        var myfunct = setInterval(function () {
          let nowTime = new Date().getTime();
          var timeleft = countDownDate - nowTime

          if (timerHtmlWidgetVar) {
            timerHtmlWidgetVar.innerHTML = timerHtmlWidget;


          }
          // Calculating the days, hours, minutes and seconds left
          var daysTimer = timeleft > 0 ? Math.floor(timeleft / (1000 * 60 * 60 * 24)) : 0;
          var hoursTimer = timeleft > 0 ? Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0;
          var minutesTimer = timeleft > 0 ? Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)) : 0;
          var secondsTimer = timeleft > 0 ? Math.floor((timeleft % (1000 * 60)) / 1000) : 0;

          // Result is output to the specific element
          var timerDays = document.getElementById("daysTimer");

          if (timerDays) {
            timerDays.innerHTML = daysTimer + " <span style='display: block'>" + daysTimerLabel + "</span> ";
          }
          var timerHours = document.getElementById("hoursTimer");
          if (timerHours) {
            timerHours.innerHTML = hoursTimer + "<span style='display: block'>  " + hrsTimerLabel + "</span>";
          }

          var timerMins = document.getElementById("minsTimer");
          if (timerMins) {
            timerMins.innerHTML = minutesTimer + "<span style='display: block'>  " + minsTimerLabel + "</span>";
          }

          var timerSecs = document.getElementById("secsTimer");
          if (timerSecs) {
            timerSecs.innerHTML = secondsTimer + "<span style='display: block'>  " + secsTimerLabel + "</span>";
          }

          // Display the message when counter is over
          if (timeleft < 0) {
            // Calculating the days, hours, minutes and seconds left
            var daysTimer = timeleft > 0 ? Math.floor(timeleft / (1000 * 60 * 60 * 24)) : 0;
            var hoursTimer = timeleft > 0 ? Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0;
            var minutesTimer = timeleft > 0 ? Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)) : 0;
            var secondsTimer = timeleft > 0 ? Math.floor((timeleft % (1000 * 60)) / 1000) : 0;

            // Result is output to the specific element
            var timerDays = document.getElementById("daysTimer");
            if (timerDays) {
              timerDays.innerHTML = daysTimer + " <span style='display: block'>" + daysTimerLabel + "</span> ";
            }
            var timerHours = document.getElementById("hoursTimer");
            if (timerHours) {
              timerHours.innerHTML = hoursTimer + "<span style='display: block'> " + hrsTimerLabel + "</span>";
            }
            var timerMins = document.getElementById("minsTimer");
            if (timerMins) {
              timerMins.innerHTML = minutesTimer + "<span style='display: block'> " + minsTimerLabel + "</span>";
            }
            var timerSecs = document.getElementById("secsTimer");
            if (timerSecs) {
              timerSecs.innerHTML = secondsTimer + "<span style='display: block'>   " + secsTimerLabel + "</span>";
            }
          }
        }, 1000);
      }, 10)
    } else {
      var TimerBody = document.getElementById("coming-soon-left-top-widget");
      if (TimerBody) {
        TimerBody.remove();
      }
    }
  } 
  render() {
    let track = 0;
    const { preview, pageId, pageName, path, loading, pageTitle, pageDesc, customCss, customeJs, sentFrom, lngCode } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>IllumeetXP | {pageTitle ? pageTitle : ""}</title>
          <meta name="description" content={pageDesc ? pageDesc : ""} />
          <style>{customCss ? customCss : ""}</style>
          <script>{customeJs ? customeJs : ""}</script>
        </Helmet>


        <div className="unlayer-header">
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid >
            </Grid>
            <Grid className='buttons'>
              <Stack spacing={1} direction="row">
                {sentFrom === 'managepages' || sentFrom === 'selectCampaignTemplates' || sentFrom === 'selectPageTemplates' || sentFrom === 'selectProjectTemplates' ? <Button variant="outlined" disableElevation onClick={() => this.openUnlayerEditor(pageId, pageName, path)}>{this.props.users.languageStateForRedux?.buttons?.managePages?.edit}</Button> : null}
                {sentFrom === 'managepages' || sentFrom === 'selectCampaignTemplates' || sentFrom === 'selectPageTemplates' || sentFrom === 'selectProjectTemplates' || sentFrom === 'pageEditor' || sentFrom === 'projectPageEditor' || sentFrom === 'projectBadgesEditor' ? 
                <Button variant="outlined" disableElevation onClick={this.exitPreview}>{this.props.users.languageStateForRedux?.buttons?.managePages?.exitPrev}</Button> 
                : 
                 sentFrom === 'campaignEditorPage' ? 
                 <Button variant="outlined" disableElevation onClick={this.exitPreview}>{this.props.users.languageStateForRedux?.buttons?.managePages?.exitPrev}</Button> 
                 : null
                }
              </Stack>
            </Grid>
          </Grid>
        </div>


        <div style={{ pointerEvents: 'none' }}>
          {ReactHtmlParser(preview?.html, {
            transform: function (domNode) {
              if (track == 0) {
                if (customCss && isUrlValid(customCss)) {
                  var link = document.createElement('link');
                  link.rel = "stylesheet";
                  link.type = "text/css";
                  link.href = customCss;
                  document.head.appendChild(link);
                }
                if (customeJs && isUrlValid(customeJs)) {
                  var script = document.createElement('script');
                  script.src = customeJs;
                  document.head.appendChild(script);
                }
              }
              track = 1;
            }
          })}
        </div>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  project: state.Projects,
  pageInfo: state.ManagePage,
  events: state.Events,
  users: state.Users,
});

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      handleLang: Actions.handleLang,
      newLangStateForRedux: Actions.newLangStateForRedux
    },
    dispatch
  );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(PagePreview));


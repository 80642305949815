import { Box,  } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 14,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: '#EDEBFF',
    },
    bar: {
      borderRadius: 10,
      backgroundColor: '#5141E7',
    },
}))(LinearProgress);

class ProgresBar extends Component {
    render() { 
        return (
            <>
                <Grid component="form" container className="modal-wrapper">
                <Grid size={{xs: 12,}} pb={3}>
                    <Box component="p" mb={1} className="subsecondary">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.importModalHead}
                    </Box>
                    <Box className="small-subtitle" mb={1.5}>
                    {this.props?.import ? this.props.users.languageStateForRedux?.formfields?.attendees?.importing:this.props.users.languageStateForRedux?.formfields?.attendees?.copying} {this.props.users.languageStateForRedux?.formfields?.attendees?.importModalDesc}
                    </Box>
                </Grid>
                <Grid size={{xs: 12,}} pb={4} >
                    <Box component="div" className="">
                        <BorderLinearProgress variant="determinate" value={this.props.progressPercentage} />
                    </Box>
                    <Box pt={0.5} textAlign={'right'} className="paragraph">{this.props.progressPercentage}%</Box>
                </Grid>
                </Grid>
            </>
        );
    }
}
 
const mapStateToProps = state => ({
    users: state.Users,
  });
  

  export default connect(mapStateToProps)(ProgresBar);
//@ts-nocheck
import { Box, colors, Stack, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
// import Badge from '@mui/material/Badge';
import Badge from '@mui/material/Badge';
import Loader from 'Components/Loader/loader';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { theme } from '../../../theme/theme';
import {
  Opportunity
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import {
  Account
} from 'ReduxStore/Reducers/opportunity/accounts.reducer';
import OpportunityView from '../Opportunity/OpportunityView/OpportunityView';
import CommentsAndAttachments from 'Pages/Opportunity/Common/CommentsAndAttachments/CommentsAndAttachments';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import {
  OpportunityCommon_State,
  CommentsAndAttachments as CommentsAndAttachmentsType,
  CommentSection,
  ViewType
} from 'ReduxStore/Reducers/opportunity/opportunityCommon.reducer';
import AccountView from '../Accounts/AccountView';
import { Contact } from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import ContactDetails from '../Contacts/ContactsView/ContactDetails';
import ContactsViewLayout from '../Contacts/ContactsView/ContactsViewLayout';
import { useSelector } from 'react-redux';

const SidebarBadge = withStyles((theme) => ({
  badge: {
    top: 8,
    right: 8,
    // backgroundColor: '#FF4170',
  },
}))(Badge);


const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: '48px',
    height: '48px',
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
  },
  customSubTab: {
    minHeight: 42,
    color: '#000056',
    borderBottom: '3px solid #ffffff',
  },
};

type Props = {
  closeViewModal: () => void;
  opportunityToBeEdited?: Opportunity | undefined;
  accountToBeEdited?: Account | undefined;
  contactToBeEdited?: Contact | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChangeFromMainLayout: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  type: ViewType;
};

function ViewLayout({
  closeViewModal,
  opportunityToBeEdited,
  accountToBeEdited,
  contactToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChangeFromMainLayout,
  type
}: Props) {
  const {
    totalAttachments,
    totalComments,
    fetchCommentAndAttachmentSuccess,
    loading,
  } = useSelector(
    (state: any) => {
      return state.OpportunityCommon
    },
  ) as OpportunityCommon_State;
  const [tabValue, setTabValue] = React.useState('1');
  const [openCommentbar, setCommentbarOpen] = React.useState(false);
  const [section, setSection] = React.useState<CommentSection>("comments");
  const [commentSectionOpened, setCommentSectionOpened] = React.useState(false);

  const handleDrawerOpen = () => {
    setCommentbarOpen(true);
    setCommentSectionOpened(true);
  };

  const handleDrawerClose = () => {
    setCommentbarOpen(false);
    setCommentSectionOpened(false);
  };

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setTabValue(newValue);
  };

  const changeSection = (clickedSection: CommentSection) => {
    if (section == clickedSection) {
      openCommentbar ? handleDrawerClose() : handleDrawerOpen();
    } else {
      // If a different section is clicked, open the drawer and switch the section
      setCommentbarOpen(true);
    }
    setSection(clickedSection);
  };

  // ---for drag sidebar---

  const sidebarRef = useRef<HTMLDivElement>(null);
  const isResizing = useRef<boolean>(false);
  const initialWidth = useRef<number>(400);
  const startX = useRef<number>(0);

  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    if (sidebarRef.current) {
      isResizing.current = true;
      startX.current = mouseDownEvent.clientX;
      initialWidth.current = sidebarRef.current.offsetWidth;
      mouseDownEvent.preventDefault(); // Prevent text selection
    }
  }, []);

  const stopResizing = useCallback(() => {
    isResizing.current = false;
  }, []);

  const resize = useCallback((mouseMoveEvent: MouseEvent) => {
    if (isResizing.current && sidebarRef.current) {
      const newWidth = initialWidth.current - (mouseMoveEvent.clientX - startX.current);
      sidebarRef.current.style.width = `${newWidth}px`;
    }
  }, []);

  useEffect(() => {
    const handleMouseUp = () => stopResizing();
    const handleMouseMove = (event: MouseEvent) => {
      if (isResizing.current) {
        requestAnimationFrame(() => resize(event));
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resize, stopResizing]);

  // ---end drag sidebar

  return (
    <Box>
      {/* <> */}
      <Grid container spacing={1} flexWrap={'nowrap'}>
        <Grid size={{xs: "grow",}} sx={{ overflow: 'auto' }}>

          {type == 'opportunity' ?
            <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)', height: '100%', }}>
              <Box className='tab-container'>
                <OpportunityView
                  closeViewModal={closeViewModal}
                  opportunityToBeEdited={opportunityToBeEdited}
                  setShowAddNew={setShowAddNew}
                  setShowViewModal={setShowViewModal}
                  handleTabChange={handleTabChangeFromMainLayout}
                  commentSectionOpened={commentSectionOpened}
                />
              </Box>
            </Box>
            : type == 'account' ?
              <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)', }}>
                <Box className='tab-container'>
                  <AccountView
                    closeViewModal={closeViewModal}
                    accountToBeEdited={accountToBeEdited}
                    setShowAddNew={setShowAddNew}
                    setShowViewModal={setShowViewModal}
                    handleTabChange={handleTabChangeFromMainLayout}
                    commentSectionOpened={commentSectionOpened}
                  />
                </Box>
              </Box>
              :
              <Box px={1.25} py={1.5} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)', minWidth: '530px' }}>

                <ContactsViewLayout
                  handleTabChangeFromMainLayout={handleTabChangeFromMainLayout}
                  closeViewModal={closeViewModal}
                  setShowAddNew={setShowAddNew}
                  setShowViewModal={setShowViewModal}
                  contactToBeEdited={contactToBeEdited as Contact}
                  commentSectionOpened={commentSectionOpened}
                />
              </Box>
          }
        </Grid>
        <Grid >
          <Stack direction={'row'} justifyContent={'end'} className={'collapsible-sidebar'}>
            <Box className={`sidebar-content right ${openCommentbar && 'show'}`}

              ref={sidebarRef}
              style={
                openCommentbar ?
                  { width: initialWidth.current, transition: 'width 0.05s' }
                  : { width: '0', minWidth: 'unset' }
              }
            >
            <Box display={openCommentbar ? 'block' : 'none'}>
                <CommentsAndAttachments
                  id={
                    type == 'opportunity' ? opportunityToBeEdited?._id :
                      type == 'account' ? accountToBeEdited?._id :
                        contactToBeEdited?._id
                  }
                  type={type}
                  section={section}
                />
              </Box>

              {openCommentbar &&
                <Box component={'div'} className="app-sidebar-resizer left" onMouseDown={startResizing} />
              }
            </Box>
            <Box className="sidebar-tabs" p={1}>
              <Stack spacing={1}>
                <Tooltip placement='right-start' enterDelay={800} arrow title={openCommentbar ? "Show less information" : "Expand to see Comments"}>

                  <IconButton color='primary' onClick={openCommentbar ? handleDrawerClose : handleDrawerOpen} >
                    {openCommentbar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </Tooltip>

                {fetchCommentAndAttachmentSuccess === true ?
                  <SidebarBadge badgeContent={totalComments} color="secondary">
                    <IconButton color='primary' className={`tab-buttons 2 ${openCommentbar && section == "comments" && "active"}`} onClick={() => changeSection('comments')} >
                      <CommentOutlinedIcon />
                    </IconButton>
                  </SidebarBadge>
                  :
                    <IconButton color='primary' className={`tab-buttons 2 ${openCommentbar && section == "comments" && "active"}`} onClick={() => changeSection('comments')} >
                      <CommentOutlinedIcon />
                    </IconButton>
                }
                {fetchCommentAndAttachmentSuccess === true ?
                  <SidebarBadge badgeContent={totalAttachments} color="secondary">
                    <IconButton color='primary' className={`tab-buttons ${openCommentbar && section == "attachments" && "active"}`} onClick={() => changeSection('attachments')} >
                      <AttachFileOutlinedIcon />
                    </IconButton>
                  </SidebarBadge>
                  :
                    <IconButton color='primary' className={`tab-buttons ${openCommentbar && section == "attachments" && "active"}`} onClick={() => changeSection('attachments')} >
                      <AttachFileOutlinedIcon />
                    </IconButton>
                }
              </Stack>
            </Box>
          </Stack>

        </Grid>

      </Grid>
    </Box>
  );
}

export default ViewLayout;

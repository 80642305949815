import React, { Component } from "react";
import { ThemeProvider, createTheme } from "@mui/material";

export const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: "#5141E7",
      dark: "#000056",
      light: "#726F8E",
      gradient: "linear-gradient(135deg, rgba(135,224,253,1) 0%,rgba(83,203,241,1) 52%,rgba(5,171,224,1) 100%)",
      border: "rgba(153, 66, 134, 0.5)",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF4170",
      light: "#ff4081",
      dark: "#c51162",
      contrastText: "#FFFFFF",
    },
    background: {
      // default: "#EDECF5",
      // paper: "#FAFAFA",
      light: "#EDECF5",
      ButtonBg: "#FAFAFA",
      contrastTextLight: "#BCBCBC",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 7,
        },
        input: {
          padding: "12.5px 14px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#000000",
          fontSize: "14px",
          fontWeight: 400,
          borderRadius: "5px",
          padding: "12px 24px",
        },
        arrow: {
          color: "#000000",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 86, 0.3)",
        },
      },
    },
    MuiList:{
      styleOverrides: {
        root: {
          maxHeight: "300px",
        },
      },
    },
  },
});

const ThemeComponent = (UiComponent) => {
  class NewComponent extends Component {
    render() {
      return (
        <ThemeProvider theme={theme}>
          <UiComponent {...this.props} />
        </ThemeProvider>
      );
    }
  }
  return NewComponent;
};

export default ThemeComponent;

import React, { Component } from 'react'
import { get, cloneDeep } from 'lodash'
import DashboardLayout from '../../Layouts/DashboardLayout'
import { createNotification, redirectTo } from '../../helpers'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import {
  EventsAllAPI,
  deleteMultipleEventUsers,
  deleteEventUser,
  getEventUser,
} from 'ReduxStore/API'
import '../../sass/main.scss'
import { NotificationContainer } from 'react-notifications'
import moment from 'moment'
import ViewModal from './ViewModal'
import { confirmAlert } from 'react-confirm-alert'
import { Button } from '@mui/material'
import { generateCsv, mkConfig } from 'export-to-csv';
import SearchIconBar from '../../assets/images/searchIco.png'
import Pagination from 'react-js-pagination'
import Loader from '../../Components/Loader/loader'
import * as qs from 'qs'

const delayDebounce = (fn, delay) => {
  let timer = null
  return function (...args) {
    const context = this
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

const THead = ({ columns, selectItem }) => {
  const elements = columns.map((column) => {
    return (
      <th scope="col" colSpan="1">
        {column}
      </th>
    )
  })
  return (
    <thead>
      <tr>
        {!!elements.length && (
          <>
            <th scope="col" colSpan="1"></th>
            <th scope="col" colSpan="1">
              S.no
            </th>
            {elements}
            <th scope="col">Registered On</th>
            <th scope="col">Action</th>
          </>
        )}
      </tr>
    </thead>
  )
}

const Tbody = ({
  eventUsers = [],
  columns,
  onViewUser,
  onDelete,
  page_no,
  selectItem,
  isAllSelected,
  totalCount,
  selectedItems,
  deleteSelectedItems,
}) => {
  const elements = eventUsers.map((item, index) => {
    return (
      <tr>
        <td>
          <label className="checkBoxColl">
            <input
              key={item._id}
              type="checkbox"
              name={item.user.email}
              value={item._id}
              checked={item.checked}
              onChange={selectItem}
            />
            <span className="checkmark"></span>
          </label>{' '}
        </td>
        <td>{page_no * 10 + index + 1}</td>
        {columns.map((column) => {
          const data = get(item, `user.${column}`)
          return (
            <>
              <td>{data}</td>
            </>
          )
        })}
        <td>
          {' '}
          {moment(item.createdAt)
            .tz('America/Los_Angeles')
            .format('dddd, MMM, Do YYYY, LT')}
        </td>
        <td className="tableDotMore ">
          <button className="tableDots">
            <span className="tableDotsExpo"></span>
            <span className="tableDotsExpo"></span>
            <span className="tableDotsExpo"></span>
          </button>
          <div className="contacteditPopup">
            <p>
              <span onClick={() => onViewUser(item)}>View</span>
            </p>
            <p>
              <span onClick={() => onDelete(item)}>Delete</span>
            </p>
          </div>
        </td>
      </tr>
    )
  })

  return (
    <tbody>
      {!!eventUsers.length && (
        <div className="checkedAllSection">
          <label className="checkBoxColl" style={{ marginLeft: '12px' }}>
            <input
              type="checkbox"
              name="checkAll"
              checked={isAllSelected}
              onChange={() => selectItem({ target: { value: 'all' } })}
            />
            <span className="checkmark" />
            <span className="selectAllName">
              Select All ({totalCount} records)
            </span>
          </label>
          {!!selectedItems.length && (
            <button onClick={deleteSelectedItems}>Delete checked</button>
          )}
        </div>
      )}
      {elements}
    </tbody>
  )
}

class EventRegisteredUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      eventUsers: [],
      columns: [],
      selectedEvent: '',
      selectedAgenda: '',
      showModal: false,
      page_no: 1,
      totalResults: 0,
      showLoader: false,
      selectedItems: [],
      isAllSelected: false,
    }
  }

  componentDidMount() {
    EventsAllAPI()
      .then((result) => {
        const events = get(result, 'data.events', [])
        const eventId = qs.parse(get(this.props, 'location.search'), {
          ignoreQueryPrefix: true,
        })
        const urlEvents = events.filter((item) => item._id === eventId.eventId)
        const selectedEvent = urlEvents.length ? urlEvents[0] : events[0]
        this.setState({ events, selectedEvent: selectedEvent._id }, () => {
          if (selectedEvent) {
            this.getEventUser()
          }
        })
      })
      .catch(console.log)
    this.delayQuery = delayDebounce(this.getEventUser, 1000)
  }

  handleExport = () => {
    const { selectedEvent } = this.state
    this.setState({ showLoader: true })
    getEventUser(selectedEvent, { export: true })
      .then((result) => {
        const eventUsers = get(result, 'data.registeredUser').filter(
          (item) => item.user,
        )
        const columnsKeysObj = {}
        const clonedEventUser = cloneDeep(eventUsers)
        clonedEventUser.forEach((item) => {
          for (let key in item.user) {
            if (typeof columnsKeysObj[key] != 'object') {
              columnsKeysObj[key] = key
            }
          }
        })
        let columns = Object.keys(columnsKeysObj).filter(
          (key) => key !== 'standford_policy' && key !== 'illumeet_privacy',
        )
        const data = eventUsers
          .map((item) => {
            let data = {}
            columns.forEach((key) => {
              if (key.includes('agenda') && Array.isArray(item.user[key])) {
                data[key] = item.user[key].map((item) => item.title).toString()
                return
              }
              data[key] = item.user[key] || ''
            })
            data['createdAt'] = moment(item.createdAt)
              .tz('America/Los_Angeles')
              .format('dddd, MMM, Do YYYY, LT')
            return Object.keys(data).length > 0 ? data : undefined
          })
          .filter((item) => item)

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Event Registered User Csv',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: 'eventRegisteredUser',
        }

        const csvExporter = new generateCsv(options)
        if (data.length > 0) {
          csvExporter.generateCsv(data)
        }
        this.setState({ showLoader: false })
      })
      .catch((e) => { 
        this.setState({ showLoader: false })
      })
  }

  getEventUser = () => {
    const { selectedEvent, searchText, page_no } = this.state
    this.setState({ showLoader: true })
    getEventUser(selectedEvent, { searchText, page_no, size: 10 })
      .then((result) => {
        const eventUsers = get(result, 'data.registeredUser').filter(
          (item) => item.user,
        )
        const totalCount = get(result, 'data.totalCount')
        const columnsKeysObj = {}
        eventUsers.forEach((item) => {
          for (let key in item.user) {
            if (typeof columnsKeysObj[key] != 'object') {
              columnsKeysObj[key] = key
            }
          }
        })
        const allColumns = Object.keys(columnsKeysObj)
        const columns = allColumns.slice(0, 5)
        this.setState({
          eventUsers,
          columns,
          allColumns,
          totalCount,
          showLoader: false,
        })
      })
      .catch((e) => {
        this.setState({ showLoader: false })
       
      })
  }

  deleteUser = (item) => {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure want to delete the User ?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Continue',
          onClick: () => {
            deleteEventUser(item._id)
              .then(() => {
                createNotification('success', 'User deleted successfully')
                this.getEventUser()
              })
              .catch(console.log)
          },
        },
      ],
    })
  }

  onViewUser = (user) => {
    this.setState({ user }, this.handleModel)
  }

  handleModel = () => {
    this.setState(({ showModal }) => ({ showModal: !showModal }))
  }

  handleOnChange = (e) => {
    e.preventDefault()
    const name = e.target.name
    this.setState({ [name]: e.target.value }, () => {
      const { searchText } = this.state
      if (name === 'selectedEvent') {
        this.setState({ page_no: 1, searchText: '' }, this.delayQuery)
      }
      if (name === 'searchText') {
        this.setState({ page_no: 1 }, this.delayQuery)
      }
    })
  }

  handlePageChange = (page_no) => {
    this.setState({ page_no }, () => {
      this.getEventUser()
    })
  }

  selectItem = (e) => {
    let { eventUsers = [], selectedItems, isAllSelected } = this.state
    const value = e.target.value
    if (value === 'all') {
      this.setState({ isAllSelected: !isAllSelected }, () => {
        let { isAllSelected } = this.state
        let selectedItems = []
        eventUsers.forEach((item) => {
          item.checked = isAllSelected
          if (isAllSelected) {
            selectedItems.push(item._id)
          } else {
            selectedItems = []
          }
        })
        this.setState({ eventUsers, selectedItems })
      })
    } else {
      eventUsers.forEach((item) => {
        if (item) {
          if (item._id === value) {
            item.checked = !item.checked
            if (item.checked) {
              selectedItems.push(value)
            } else {
              selectedItems = selectedItems.filter((id) => id !== value)
            }
          }
        }
      })
      this.setState({ eventUsers, selectedItems })
    }
  }

  deleteSelectedItems = () => {
    const { selectedEvent, selectedItems, isAllSelected } = this.state
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure want to delete the User ?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Continue',
          onClick: () => {
            this.setState({ showLoader: true })
            deleteMultipleEventUsers(selectedEvent, {
              selectedItems,
              isAllSelected,
            })
              .then(() => {
                createNotification('success', 'User deleted successfully')
                this.setState({
                  selectedItems: [],
                  isAllSelected: false,
                  showLoader: false,
                })
                this.getEventUser()
              })
              .catch(console.log)
          },
        },
      ],
    })
  }

  render() {
    const {
      eventUsers,
      events,
      columns,
      selectedEvent,
      showModal,
      user,
      searchText,
      page_no = 1,
      totalCount,
      showLoader,
      isAllSelected,
      selectedItems,
    } = this.state
    
    return (
      <DashboardLayout title="Registered Users" pageTitle="Registered Users">
        <div className="dashboardMiddleArea">
          <div className="filterControler eventRegisterUserF agendaFilter">
            <div className="formSection">
              <form onSubmit={this.handleOnChange}>
                <div className="field-group mr-2">
                  <label for="filter_byEvent"> Choose Event </label>
                  <select
                    name="selectedEvent"
                    value={selectedEvent}
                    className="outlineButton"
                    onChange={this.handleOnChange}
                  >
                    <option value="">Select Event</option>
                    {events.map((event) => (
                      <option key={event._id} value={event._id}>
                        {event.eventName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="eventControlSearch mr-2">
                  <div className="filterSearchBar">
                    <label for="filter_byEvent"> &nbsp; </label>
                    <div className="filterSerchSubmit SearchGrid">
                      <button type="submit">
                        <img src={SearchIconBar} alt="" />
                      </button>
                      <input
                        type="text"
                        placeholder="Search......"
                        name="searchText"
                        value={searchText}
                        className="form-control"
                        onChange={this.handleOnChange}
                        autoComplete="false"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <Button
                type="button"
                className="themeBlueButton mr-2"
                onClick={() =>
                  redirectTo(
                    this.props.navigate,
                    `registration-analytics`,
                    this.props.location.pathname,
                  )
                }
              >
                Analytics
              </Button>
              <Button
                type="button"
                className="themeBlueButton"
                onClick={this.handleExport}
              >
                Export
              </Button>
            </div>
          </div>
          <div className="eventListBoxes myEventTable">
            <div className="eventTableInner table-responsive eventRegisteredUser">
              <table className="table">
                <THead columns={columns} selectItem={this.selectItem} />
                <Tbody
                  columns={columns}
                  eventUsers={eventUsers}
                  onViewUser={this.onViewUser}
                  onDelete={this.deleteUser}
                  page_no={page_no - 1}
                  selectItem={this.selectItem}
                  selectedItems={selectedItems}
                  isAllSelected={isAllSelected}
                  totalCount={totalCount}
                  deleteSelectedItems={this.deleteSelectedItems}
                />
              </table>
            </div>
            {11 > 10 ? (
              <div className="tablePagination">
                {totalCount > 0 && (
                  <label
                    style={{ float: 'left', width: '50%', marginTop: '13px' }}
                  >
                    Total : {totalCount}
                  </label>
                )}
                {totalCount > 0 && (
                  <Pagination
                    activePage={page_no}
                    itemsCountPerPage={10}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                    prevPageText="Prev"
                    nextPageText="Next"
                    // firstPageText="First"
                    // lastPageText="Last"
                  />
                )}
              </div>
            ) : null}
          </div>
          <NotificationContainer />
        </div>
        <Modal open={showModal} onClose={this.handleModel} center>
          <ViewModal
            data={user}
            onClose={this.handleModel}
            eventId={selectedEvent}
          />
        </Modal>
        {showLoader && <Loader />}
      </DashboardLayout>
    )
  }
}

export default EventRegisteredUser

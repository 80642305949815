import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  TableHead,
  IconButton,
  Collapse,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

import { OrgUnit } from 'ReduxStore/Reducers/integrations/googleOrgUnit.Reducer';
import { useState } from 'react';
interface OrgUnitNode extends OrgUnit {
  attendeeCount?: number;
}
const OrgUnitView = ({
  orgUnits,
  setOrgUnits,
  setIsDirty,
}: {
  orgUnits: OrgUnit[];
  setOrgUnits: Function;
  setIsDirty: Function;
}) => {
  const [isClosed, setIsClosed] = useState<{
    [id: string]: boolean;
  }>({});

  const onSyncCheck = (checked: boolean, orgUnitId: string) => {
    setIsDirty(true);
    const updateNodeAndChildren = (nodes: OrgUnitNode[], checked: boolean) => {
      nodes = [...nodes].map((node) => {
        if (node.orgUnitId === orgUnitId) {
          node.isSyncActive = checked;
        }
        node.children = updateNodeAndChildren(node.children, checked);
        return node;
      });
      return nodes;
    };
    updateNodeAndChildren([...orgUnits], checked);
    setOrgUnits((st: any) => {
      return [...orgUnits];
    });
  };

  const renderRow = (orgUnit: OrgUnitNode) => {
    return (
      <TableRow>
        <TableCell align="left">
          {orgUnit.children.length ? (
            <Box component={'span'} ml={-1}>
              <IconButton
                size="small"
                aria-label="expand row"
                onClick={() => {
                  isClosed[orgUnit.orgUnitId] = !Boolean(
                    isClosed[orgUnit.orgUnitId],
                  );
                  setIsClosed({ ...isClosed });
                }}
              >
                {isClosed[orgUnit.orgUnitId] ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </Box>
          ) : null}

          {orgUnit.name}
        </TableCell>
        <TableCell className="short-text" align="left">
          {orgUnit.attendeeCount}
        </TableCell>
        <TableCell align="left">{orgUnit.description}</TableCell>
        <TableCell className="short-text" align="right">
          <Checkbox
            style={{
              padding: 0,
              marginRight: '8px',
            }}
            onChange={(e) => onSyncCheck(e.target.checked, orgUnit.orgUnitId)}
            checked={orgUnit.isSyncActive}
            color="primary"
          />
        </TableCell>
      </TableRow>
    );
  };
  const renderUnit = (orgUnit: OrgUnitNode, index: number): JSX.Element => {
    return (
      <Box pl={2} key={'oU_Row_' + index}>
        {orgUnit.children.length ? (
          <>
            {renderRow(orgUnit)}
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={4}>
                <Collapse
                  in={!Boolean(isClosed[orgUnit.orgUnitId])}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box>
                    <Table
                      className="collapse-table inner-table"
                      aria-label="purchases"
                    >
                      <TableBody>
                        {orgUnit.children.map((ou, oIn) => renderUnit(ou, oIn))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ) : (
          renderRow(orgUnit)
        )}
      </Box>
    );
  };

  return (
    <Box mt={2}>
      <TableContainer>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell className="short-text">Users</TableCell>
              <TableCell>Description</TableCell>
              <TableCell
                className="short-text"
                align="right"
                style={{ maxWidth: '100px' }}
              >
                Sync
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgUnits.map((orgUnit: OrgUnit, index: number) =>
              renderUnit(orgUnit, index),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrgUnitView;

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import NotificationEditor from './notificationEditor';
import { NotificationContainer } from 'react-notifications';
import { default as ReactSelect } from 'react-select';
import { createNotification, redirectTo } from '../../../helpers';
import { SketchPicker } from 'react-color';
import { components } from 'react-select';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import { Button } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import ReviewNotification from '../../../assets/images/review-notification.svg';
import ScheduleNotification from '../../../assets/images/schedule-notification.svg';
import NotificationSent from '../../../assets/images/Notification-Sent.svg';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import CheckedInOut from '../../../assets/images/checkedInOut.svg';
import axios from 'axios';
import { getProjectIdFromUrl, convertTZ } from '../../../helpers/common';
import Loader from '../../../Components/Loader/loader' 
import moment, { duration } from 'moment'

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { theme } from '../../../theme/theme'
import { ThemeProvider } from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import GoogleWorkspace from '../../../assets/images/icons/Google_G_Logo.svg';
import { permissions } from '../../../constants/constants';
import { Link } from 'react-router-dom'; 
import withCustomRouter from '../../../Common/withCustomRouter'; 


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};
const content = "<h1 class='ql-align-center bold'><strong>Enter the Notification Heading</strong></h1><p class='ql-align-center'>Enter the notification description.</p>"

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

export const customStyles = {
  option: (provided, state, styles) => ({
    ...provided,
    ...styles,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (styles) => ({
    ...styles,
    minHeight: 48,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (styles) => ({
    ...styles,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    top: '-235px',
    overflow: 'hidden',
    maxHeight:220,
    minHeight:220,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return { ...provided, opacity, transition }
  },
}

const URL = process.env.REACT_APP_API_URL;

function parseToHtml(text) {
  let div = document.createElement("div");
  div.innerHTML = text;
  return div.textContent || div.innerText;
}

 

class addNotification extends Component {
  constructor(props) {
    super(props)
    this.anchorRef = React.createRef()
    this.state = {
      title: '',
      description: content,
      buttonText: 'Got It',
      primaryColor: '#FF4170',
      buttonAction: "Close Notification",
      layoutType: 'center',
      NotificationId: '',
      eventTags: '',
      errors: {},
      externalURL: '',
      displayOnPages: [],
      displayColorPicker: false,
      activeClass: 'active',
      status: 'scheduled',
      urlPathname: '',
      sendNowModal: false,
      scheduleLaterModal: false,
      createdAt: null,
      loading: false,
      openScheduleModal: false,
      scheduledDate: new Date(),
      openReviewModal: false,
      pages: [],
      listOfGroups: [],
      groupsMultiSelectArray: [],
      openMultiSelectForGroups: false,
      attendeesCount: 0,
      sendToGroups: 'All',
      sendToPages: 'All',
      groupsForReactSelect: [],
      timezone: null,
      notificationDate: null,
      sendPopup: false,
      open: false,
      lngCode: '',
      page_no:1,
      itemCount:10,
      sort_by: 'updatedAt',
      sortOrder: "desc"
    }
    this.BUTTON_ACTION_DROP_DOWN = [
      {
        key: this.props.users.languageStateForRedux?.dropdowns?.notifications?.closeNot,
        value: 'Close Notification'
      },
      {
        key: this.props.users.languageStateForRedux?.dropdowns?.notifications?.refreshPage,
        value: 'Refresh Page'
      },
      {
        key: this.props.users.languageStateForRedux?.dropdowns?.notifications?.external,
        value: 'External URL'
      },
    ]
  }

  componentDidMount() { 
    const { pid, tid, pn, purl } = this.props.params; 
    this.setState({
      openScheduleModal: this.props?.location?.state?.openScheduleModal,
      sendPopup: this.props?.location?.state?.sendPopup,
      lngCode: this.props?.location?.state?.lngCode
    })
    const length = window.location.pathname.split('/').length
    const notiId = window.location.pathname.split('/')[length - 2]
    const projectId = window.location.pathname.split('/')[length - 1]
    if (window.location.pathname.includes('project-dashboard')) {
      this.setState({
        projectId,
        urlPathname: 'addNoti',
      }, () => {
        this.getProjectRequest(projectId)
        this.getProjectTemplate()
        this.listgroups()
        this.getAttendeesList()
      })
    }

    if (window.location.pathname.includes('update-notification')) {
      let length = window.location.pathname.split('/').length
      const id = window.location.pathname.split('/')[length - 2]
      let data = { projectId, id }
      this.props.editProjectNotification(data)
      this.setState({
        loading:true,
        NotificationId: notiId,
        projectId,
        urlPathname: 'updateNoti',
      }, () => {
        this.getProjectRequest(projectId)
        this.getProjectTemplate()
        this.listgroups()
        this.getAttendeesList()
      })
    }
  }

  getProjectRequest(projectId) {
    this.props.getProjectRequest(projectId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projects.getProjectSuccess === true && !prevProps.projects.getProjectSuccess) {
      this.setState({
        timezone: this.props.projects.project.timezone,
        scheduledDate: moment.tz(new Date(), this.props.projects.project.timezone).format('MM/DD/YYYY hh:mm a'),
        currentTimeInSelectedTimeZOne: moment.tz(new Date(), this.props.projects.project.timezone).format('MM/DD/YYYY hh:mm a'),
      })


      if (this.state.notificationDate) {
        this.setState({
          scheduledDate: moment.tz(new Date(this.state.notificationDate), this.props.projects.project.timezone).format('MM/DD/YYYY hh:mm a'),
          currentTimeInSelectedTimeZOne: moment.tz(new Date(this.state.notificationDate), this.props.projects.project.timezone).format('MM/DD/YYYY hh:mm a'),
        })

        this.state.scheduledDate = moment.tz(this.state.notificationDate, this.props.projects.project.timezone).format('MM/DD/YYYY hh:mm a')
        this.state.currentTimeInSelectedTimeZOne = moment.tz(this.state.notificationDate, this.props.projects.project.timezone).format('MM/DD/YYYY hh:mm a')
      }
    }

    if (
      this.props.projectNotifications.editSuccess === true &&
      prevProps.projectNotifications.editSuccess === false) {
      const {
        title,
        buttonText,
        buttonAction,
        externalURL,
        primaryColor,
        displayOnPages,
        layoutType,
        description,
        status,
        notificationDate
      } = this.props.projectNotifications.notification

      this.setState({
        title,
        buttonText,
        buttonAction,
        externalURL,
        primaryColor,
        displayOnPages,
        layoutType,
        description,
        status,
        notificationDate,
        sendToPages: displayOnPages?.length === this.state.pagesComplete?.length ? 'All' : 'Pages',
        scheduledDate: notificationDate,
        loading:false
      })
    }

    if (
      this.state.groupsMultiSelectArray.length > 1 &&
      this.props.attendees?.getAttendeesGroupsSuccess === true &&
      prevProps.attendees?.getAttendeesGroupsSuccess === false
    ) {
      let selectedGroupsIdArr = this.props.projectNotifications?.notification?.groups
      let allGroups = this.props.attendees.attendeesGroups
      let selected = allGroups.filter(group => selectedGroupsIdArr.includes(group._id))
      let checkedInIds = selected.map(group => group.countByAttendeesStatus['Checked In'])
      checkedInIds = checkedInIds.filter(el => el !== undefined)
      let checkedInIdsAdded = checkedInIds.reduce((a, b) => a + b, 0)
      this.setState({
        attendeesCount: checkedInIdsAdded
      })
    }

    if (this.props.projectNotifications.addSuccess === true &&
      this.props.projectNotifications.message !==
      prevProps.projectNotifications.message) {
      createNotification("success", this.props.projectNotifications.message)
      this.handleCloseScheduleModal()
      this.handleCloseReviewModal()
      this.props.onCloseAddNotification()
      this.listRequest()
    }

    if (this.props.projectNotifications.error && !prevProps.projectNotifications.error) {
      createNotification("error", this.props.projectNotifications.message)
      this.handleCloseScheduleModal()
      this.handleSendPopupClose()
      setTimeout(() => {
        this.props.handleLoadingFromParent()
      }, 300);
    }

    if (window.location.pathname.includes('update-notification')) {
      if (this.props.projectNotifications.updateAfterEditSuccess === true && prevProps.projectNotifications.updateAfterEditSuccess === false &&
        prevProps.projectNotifications.message !== this.props.projectNotifications.message && this.props.projectNotifications.success === false && prevProps.projectNotifications.success === false
        && this.props.projectNotifications.message !== prevProps.projectNotifications.message
      ) {
        createNotification('success', this.props.projectNotifications.message)
        this.setState({ loading: false })
        setTimeout(() => {
          this.props.navigate(`/project-dashboard/notifications/${this.props.projects?.project?._id}`) 
         }, 500)
        this.listRequest()
      }
    }

    if (window.location.pathname.includes('update-notification')) {
      if (this.props.projectNotifications.updateAfterEditError === true && prevProps.projectNotifications.updateAfterEditError === false &&
        prevProps.projectNotifications.message !== this.props.projectNotifications.message
      ) {
        createNotification("error", this.props.projectNotifications.message)
        this.handleSendPopupClose()
        this.setState({ loading: false })
      }
    }

    if (this.props.attendees.getAttendeesGroupsSuccess === true && this.props.attendees.message !== prevProps.attendees.message) {
      const groupsForReactSelect = this.props.attendees?.attendeesGroups?.map(group => ({ label: group?.attendeesGroupName, value: group?._id, countByAttendeesStatus: group?.countByAttendeesStatus, showToGroup: group?.showToGroup, source: group?.source }))
      this.setState({
        listOfGroups: this.props.attendees.attendeesGroups,
        groupsForReactSelect
      }, () => {
        if (window.location.pathname.includes('update-notification')) {
          const filteredGroups = this.state.groupsForReactSelect.filter(group => this.props.projectNotifications?.notification?.groups?.includes(group.value))
          this.setState({
            groupsMultiSelectArray: filteredGroups,
            sendToGroups: this.state.groupsForReactSelect?.length === this.props.projectNotifications?.notification?.groups?.length ? 'All' : 'Groups'
          })
        }
      });
    }

    if (this.props.attendees?.sortFilterAttendeesSuccess && !prevProps.attendees?.sortFilterAttendeesSuccess &&
      this.props.attendees?.message !== prevProps.attendees?.message) {
      this.setState({
        filterCnt: this.props.attendees?.filterCnt
      })
      if (this.state.sendToGroups === "All") {
        this.setState({
          attendeesCount: Object.keys(this.props.attendees?.filterCnt).length && this.props.attendees?.filterCnt?.status['Checked In']
        })
      } else if (this.state.sendToGroups === "Groups") {
        this.setState({

        })
      }
    }
  }

  listRequest = () => {
    const { projectId } = this.state
    let requestBody = {
      projectId,
      page_no: 1,
      limit: this.props.limit,
      searchText: '',
    }
    this.props.listRequest(requestBody)
  }

  UserData = async () => {
    const currentPath = window.location.pathname
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'))
    } else {
      return JSON.parse(localStorage.getItem('user_details'))
    }
  }

  async getProjectTemplate() {
    let length = window.location.pathname.split('/').length
    const projectId = window.location.pathname.split('/')[length - 1]
    const users = await this.UserData()
    try {
      let requestFrom = "notificationsReq";
      let requestCheck = "viewNotifications";
      
      const response = await axios.get(`${URL}/getProjectTemplate?projectId=${projectId}&requestFrom=${requestFrom}&requestCheck=${requestCheck}`, {
        headers: {
          'token': `bearer ${users.token}`,
        }
      })
      if (response.data && response.data.status == 200) {
        let pages = response.data.templates.map(template => {
          return { value: template._id, label: template.pageName }
        })
        let pagesComplete = response.data.templates.map(template => {
          return { value: template._id, label: template.pageNamem, path: template.path }
        })
        this.setState({
          template: response.data.templates,
          events: response.data.events,
          pages,
          pagesComplete,
        }, () => {
          this.setState({
            allPagesSelected: pages,
          })
          if (!window.location.pathname.includes('update-notifications')) {
            this.setState({
              displayOnPages: pages,
            })
          } else {
            this.setState({
              sendToPages: this.state.displayOnPages?.length === pages?.length ? 'All' : 'Pages',
            })
          }
        })
      } else {
        throw Error(response.data.error)
      }
    } catch (err) {
      createNotification("error", err.message);
      setTimeout(() => {
        window.location.href = `/my-events`;
      }, 1500);
    }
  }

  handleCloseReviewModal = () => {
    this.setState({
      sendPopup: false,
    })
  }

  handleOpenReviewModal = () => {
    this.setState({
      sendPopup: true,
    })

  }

  handleSendPopup = async () => {
    if (await this.checkIfNameAlreadyExist()) {
      this.setState({
        sendPopup: true
      })
    }
  }

  handleSendPopupClose = () => {
    this.setState({
      sendPopup: false
    })
  }

  checkIfNameAlreadyExist = async () => {
    const users = await this.UserData()
    let requestbody = {
      title: this.state.title.replace(/\s{2,}/g, ' ').trim(),
      projectId: this.state.projectId,
      notificationId: this.state.NotificationId
    }
    let res = await axios.post(`${URL}/notifications/checkIfNotificationTitleAlreadyExist`, requestbody, {
      headers: {
        'token': `bearer ${users.token}`,
      }
    })
    if (!res.data.available) {
      createNotification('error', 'Notification title already exists')
    }
    return res.data.available
  }


  handleNotificationSubmit = (e) => {
    const { groupsMultiSelectArray, groupsForReactSelect } = this.state

    let values = this.state.displayOnPages?.map(page => page.value)
    let pageNames = this.state.pagesComplete?.filter(page => values?.includes(page.value))
    let onPages = pageNames.map(obj => obj.path)
    var groupValues = [];
    var groupNames = [];

    var groupsValues = [];
    var forGroups = [];

    if (this.state.sendToGroups === 'All') {
      forGroups = this.state.groupsForReactSelect?.map(obj => obj.label)
      groupsValues = this.state.groupsForReactSelect?.map(group => group.value)
    } else {
      try {
        groupValues = groupsMultiSelectArray.length && groupsMultiSelectArray?.map(group => group.value)
        groupNames = groupsForReactSelect?.filter(group => groupValues?.includes(group.value))
      } catch (error) {
        return error
      }

      forGroups = groupNames?.map(obj => obj.label)
      groupsValues = groupNames?.map(obj => obj.value)
    }

    if (this.isValid()) {
      const {
        title,
        description,
        buttonText,
        primaryColor,
        buttonAction,
        layoutType,
        projectId,
        displayOnPages,
        externalURL,
        status,
        scheduledDate,
        sendToGroups,
        sendToPages
      } = this.state

      if (window.location.pathname.includes('project-dashboard')) {
        let requestBody = {
          title: title.replace(/\s{2,}/g, ' ').trim(),
          description: description.trim(),
          buttonText,
          primaryColor,
          buttonAction,
          layoutType,
          projectId,
          displayOnPages,
          createdAt: convertTZ(new Date(), this.state.timezone),
          externalURL,
          status,
          onPages,
          groups: groupsValues,
          forGroups,
          sendToGroupsOption: sendToGroups,
          sendToPagesOption: sendToPages,
          permission: "Create Notification",
          lngCode: this.props.users.newLangState
        }

        if (status === 'scheduled') {
          requestBody = {
            ...requestBody,
            scheduledAt: convertTZ(scheduledDate, this.state.timezone),
          }
        }
        this.props.createProjectNotification(requestBody)
        this.props.handleLoadingFromParent()
      }

      if (window.location.pathname.includes('update-notification')) {
        let requestBody = {
          title: title.replace(/\s{2,}/g, ' ').trim(),
          description: description.trim(),
          buttonText,
          primaryColor,
          buttonAction,
          layoutType,
          projectIid: this.state.NotificationId,
          displayOnPages,
          createdAt: convertTZ(new Date(), this.state.timezone),
          externalURL,
          status,
          groups: groupsValues,
          projectId,
          onPages,
          forGroups,
          sendToGroupsOption: sendToGroups,
          sendToPagesOption: sendToPages,
          permission: "Edit Notification",
          lngCode: this.state.lngCode
        }

        if (status === 'scheduled') {
          requestBody = {
            ...requestBody,
            scheduledAt: convertTZ(scheduledDate, this.state.timezone),
          }
        }
        const id = this.state.NotificationId
        this.props.updateProjectNotification(requestBody, id)
        this.props?.location?.function?.handleLoadingFromParent()
      }
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSendToGroupsChange = (e) => {
    e.preventDefault()
    this.setState({
      sendToGroups: e.target.value,
    }, () => {
      if (e.target.value === 'Groups') {
        this.setState({
          attendeesCount: 0,
          groupsMultiSelectArray: []
        })
      } else if (e.target.value === 'All') {
        this.setState({
          attendeesCount: Object.keys(this.props.attendees?.filterCnt).length > 0 && this.props.attendees?.filterCnt?.status['Checked In'] && this.props.attendees?.filterCnt?.status['Checked In'],
          groupsMultiSelectArray: this.state.groupsForReactSelect.length && this.state.groupsForReactSelect
        })
      }
    })
  }

  handleSendToPagesChange = (e) => {
    e.preventDefault()
    this.setState({
      sendToPages: e.target.value,
    },
      () => {
        if (e.target.value === 'Pages') {
          this.setState({
            displayOnPages: [],
          })
        } else if (e.target.value === 'All') {
          this.setState({
            displayOnPages: this.state.allPagesSelected,
          })
        }
      }
    )
  }

  isValid = (statusType) => {
    const {
      title,
      description,
      buttonText,
      buttonAction,
      externalURL,
      sendToGroups,
      sendToPages,
      displayOnPages,
      groupsMultiSelectArray,
    } = this.state
    let error = {}
    let formIsValid = true
    if ((statusType === 'sendNow' || statusType === 'scheduled') || this.state.status !== "draft") {
      if (sendToPages === 'Pages') {
        if (displayOnPages.length < 1) {
          formIsValid = false
          error['displayOnPages'] = "Please select at least one page"//*Please choose at least one group.
        }
      }
    }

    if ((statusType === 'sendNow' || statusType === 'scheduled') || this.state.status !== "draft") {
      if (sendToGroups === 'Groups') {
        if (groupsMultiSelectArray.length < 1) {
          formIsValid = false
          error['sendToGroups'] = this.props.users.languageStateForRedux?.validations?.atLeastOne//*Please choose at least one group.
        }
      }
    }
    if (title === '' || title.trim().length === 0) {
      formIsValid = false
      error['title'] = this.props.users.languageStateForRedux?.validations?.fillTitle//*Please fill the title.
    }
    if (description === '' || description.trim().length === 0) {
      formIsValid = false
      error['description'] = this.props.users.languageStateForRedux?.validations?.fillHeading//*Please fill the heading.
    }

    if (!parseToHtml(description) || parseToHtml(description).trim().length === 0 || description === '') {
      formIsValid = false
      error['description'] = this.props.users.languageStateForRedux?.validations?.desc//Please enter the description
    }

    if (buttonText === '' || buttonText.trim().length === 0) {
      formIsValid = false
      error['buttonText'] = this.props.users.languageStateForRedux?.validations?.buttonText//Please fill the button text.
    }
    if (buttonAction === 'External URL') {
      if (this.isValidHttpUrl(externalURL) === false) {
        formIsValid = false
        error['externalURL'] =
        this.props.users.languageStateForRedux?.validations?.enterUrl//*Please fill the correct URL. Scheme(http:// or https://) is required.
      }
    }

    if (parse(`${this.state.description}`)[1]) {
      if (
        Array.isArray(parse(`${description}`)) === false ||
        parse(`${description}`).length < 1
      ) {
        formIsValid = false
        error['description'] = this.props.users.languageStateForRedux?.validations?.desc//Please enter the description'
      }
    } else {
      formIsValid = false
      error['description'] = this.props.users.languageStateForRedux?.validations?.desc//Please enter the description'
    }

    this.setState({ errors: error })
    return formIsValid
  }

  isValidHttpUrl = (string) => {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null)
      return false;
    else
      return true;
  }

  handleChipsKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault()

      var value = this.state.eventTags.trim()

      if (value && this.isChipValid(value)) {
        this.setState({
          displayOnPages: [...this.state.displayOnPages, this.state.eventTags],
          eventTags: '',
        })
      }
    }
  }

  isChipValid(value) {
    let chipError = null

    if (this.isInChipsList(value)) {
      chipError = `${value} has already been added.`
    }

    if (chipError) {
      this.setState({ chipError })

      return false
    }

    return true
  }

  handleChipsChange = (evt) => {
    this.setState({
      eventTags: evt.target.value,
      chipError: null,
    })
  }

  isInChipsList(value) {
    return this.state.displayOnPages.includes(value)
  }

  handleChipDelete = (item) => {
    this.setState({
      displayOnPages: this.state.displayOnPages.filter((i) => i !== item),
    })
  }

  handleChipPaste = (evt) => {
    evt.preventDefault()

    var paste = evt.clipboardData.getData('text')
    var emails = paste.match('^[a-zA-Z ]*$')

    if (this.state.displayOnPages && emails) {
      var toBeAdded = emails.filter((email) => !this.isInChipsList(email))

      this.setState({
        displayOnPages: [...this.state.displayOnPages, ...toBeAdded],
      })
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleColorChange = (color) => {
    this.setState({ primaryColor: color.hex })
  }

  handleChangeMultiselect = (selected) => {
    this.setState({
      displayOnPages: selected,
    })
  }
  handleChangeMultiselectForGroups = (selected) => {
    this.setState({
      groupsMultiSelectArray: selected,
    })
    let attendeesCount = 0
    selected.forEach(obj => {
      attendeesCount = attendeesCount + (obj.countByAttendeesStatus['Checked In'] ? obj.countByAttendeesStatus['Checked In'] : 0)
    })
    this.setState({
      attendeesCount
    })
  }

  onSendNowModal = () => {
    this.setState({ sendNowModal: true })
  }

  onCloseSendNowModal = () => {
    this.setState({
      sendNowModal: false,
    })
  }

  modules = {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold'],
        [{ align: [] }, { list: 'bullet' }],
        ['link'],
        ['underline', 'blockquote'],
      ],
      handlers: { image: this.imageHandler },
    },
    clipboard: { matchVisual: false },
  }

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'size',
    'color',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align',
  ]

  handleEditorChange = (content) => {
    this.setState({
      description: content,
    })
  }
  handleMultiChange = (e) => {
    this.setState({
      displayOnPages: Array.isArray(e) ? e.map((x) => x.label) : [],
    })
  }

  onCloseUpdateButton = () => {
    this.props.navigate({ pathname: `/project-dashboard/notifications/${this.state.projectId}`})
  }

  handleCloseScheduleModal = () => {
    this.setState({
      openScheduleModal: false,
    })
  }

  handleOpenScheduleModal = async () => {
    if (await this.checkIfNameAlreadyExist()) {
      this.setState({
        openScheduleModal: true,
      })
    }
  }

  listgroups = () => {
    try {
      const projectId = getProjectIdFromUrl();
      const body = {
        projectId,
        page_no2: 1,
        searchText: ''
      }
      if (projectId) {
        this.props.listAttendeesGroups(body)
      } else {
        createNotification("error", "project Id is missing")
      }
    } catch (error) {
      createNotification("error", "Something went wrong")
    }
  }

  handleToggleMultiSelectGroups = () => {
    this.setState({
      openMultiSelectForGroups: !this.state.openMultiSelectForGroups
    });
  }

  isChecked = (groupId) => {
    if (this.state.groupsMultiSelectArray.includes(groupId)) {
      return true
    } else {
      return false
    }
  }

  handleMultiSelectForGroups = (e, group) => {
    if (this.state.groupsMultiSelectArray.indexOf(e.target.value) === -1) {
      this.state.groupsMultiSelectArray.push(e.target.value)
      if (group?.countByAttendeesStatus['Checked In'] > 0) {
        this.setState({
          attendeesCount: this.state.attendeesCount + (group?.countByAttendeesStatus['Checked In'] !== undefined ? group?.countByAttendeesStatus['Checked In'] : 0)
        })
      }
    } else {
      this.state.groupsMultiSelectArray = this.state.groupsMultiSelectArray.filter(el => el != e.target.value)
      if (group?.countByAttendeesStatus['Checked In'] > 0) {
        this.setState({
          attendeesCount: this.state.attendeesCount - (group?.countByAttendeesStatus['Checked In'] !== undefined ? group?.countByAttendeesStatus['Checked In'] : 0)
        })
      }
    }

    this.setState({
      selectedGroups: this.state.groupsMultiSelectArray,
    })
  }

  getAttendeesList = () => {
    const projectId = getProjectIdFromUrl();
    const {
      statusFilterOptionsSelected,
      sourceFilterOptionsSelected,
      groupFilterOptionsSelected,
      page_no,
      itemCount,
      sort_by,
      sortOrder
    } = this.state
    const requestbody = {
      projectId,
      status: statusFilterOptionsSelected,
      source: sourceFilterOptionsSelected,
      group: groupFilterOptionsSelected,
      sort_by: sort_by,
      order: sortOrder,
      pageNumber: page_no,
      limit: itemCount,
    }
    this.props.sortFilterAttendeesRequest(requestbody)
  }

  isValidScheduleDate = () => {
    try {
      let formIsValid = true
      const error = {}
      const { scheduledDate } = this.state

      let currentTimeInSelectedTimeZOne = moment.tz(new Date(), this.state.timezone).format('MM/DD/YYYY hh:mm a')

      var currentTime = new Date(currentTimeInSelectedTimeZOne)
      currentTime.setMinutes(currentTime.getMinutes() + 2);

      let scheduledTime = new Date(scheduledDate)

      if (scheduledTime.getTime() <= currentTime.getTime()) {
        formIsValid = false;
        error['earlyScheduledTime'] = this.props.users.languageStateForRedux?.validations?.timeValidationMsg//"*Please select scheduled time which is greater by 5 minutes than the current time.";
      }
      this.setState({
        errors: error
      })
      return formIsValid 
    } catch (error) {
      return error
    }
  }

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      this.setState({
        open: false,
      })
    }
  }

  handleCloseAction = () => {
    this.setState({
      open: false,
    })
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    })
  }


  listOfGroupsSelected = () => {
    let groupsArr = [];
    
    // Check if groupsMultiSelectArray exists and is an array
    if (Array.isArray(this.state.groupsMultiSelectArray)) {
        groupsArr = this.state.groupsMultiSelectArray.map(groupObject => groupObject.label);
        groupsArr = groupsArr.join(', ');
    } else {
        console.error("groupsMultiSelectArray is not an array or does not exist.");
    }
    
    return groupsArr;
}

  customOptionLabel = (prop) => {
    return <div>
      {prop.source === "GoogleWorkspace" && prop.showToGroup && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginLeft: "5px" }} />}
      <span>{' '}</span>
      <span>{prop.label}</span>
    </div>
  }
  render() {
    const {
      title,
      buttonText,
      buttonAction,
      displayOnPages,
      eventTags,
      primaryColor,
      description,
      externalURL,
      errors,
      layoutType,
      isLayoutButtonActiveClass,
      sendNowModal,
      scheduleLaterModal,
      listOfGroups,
      groupsMultiSelectArray,
      open
    } = this.state

    const styles = {
      color: {
        width: '32px',
        height: '32px',
        borderRadius: '4px',
        position: 'absolute',
        top: '8px',
        left: '8px',
        background: `${this.state.primaryColor}`,
      },
      swatch: {
        position: 'relative',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },

      popup: {
        borderColor: `${primaryColor}`,
      },

      button: {
        background: `${primaryColor}`,
      },
    }

    const StyledTextField = styled(TextField)({
      '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px',
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <Grid container justifyContent="end" className='add-notification_modal modal-default-close-none' >
          <Grid size={{xs: 12, md:6, lg:8, xl:8}} className="add-notification_modal__preview ">
            <div class="notification-wraper ql-snow">
              <div
                style={styles.popup}
                className={`notification-popup ql-editor ${layoutType}`}
              >
                <div>{parse(`${description}`)}</div>
                <div className="text-center">
                  <button style={styles.button}>{buttonText}</button>
                </div>
              </div>
            </div>
            <p className="error_mesage"> {errors.description} </p>
          </Grid>
          <Grid size={{xs: 12, md:6, lg:4, xl:4}} className='add-notification_modal__form'>
            <Box component='div' className='full-screen_modal__form_header'>

              <Grid container alignItems="center" justifyContent="space-between">
                <Grid >
                  <p className='subtitle'>{this.state.urlPathname === 'updateNoti' ? this.props.users.languageStateForRedux?.formfields?.notifications.edit : this.props.users.languageStateForRedux?.formfields?.notifications?.addNew}</p>
                </Grid>
                <Grid >
                  <Grid container>
                    <Grid >
                      <Box>
                        <Button
                          variant="contained"
                          color='primary'
                          style={{
                            width: '160px',
                            justifyContent: 'space-between',
                            minWidth: 'max-content'
                          }}
                          disableElevation
                          ref={this.anchorRef}
                          aria-controls={open ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={this.handleToggle}
                          endIcon={<ArrowDropDownIcon />}
                        >
                          {this.props.users.languageStateForRedux?.dropdowns?.common?.actions}
                        </Button>
                        <Popper open={this.state.open} style={{ zIndex: 9 }} placement="bottom-end" transition anchorEl={this.anchorRef.current} role={undefined} disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Fade {...TransitionProps} timeout={250} >
                              <Box sx={{ backgroundColor: theme.palette.common.white, boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)', borderRadius: '4px', marginTop: '8px', }}>
                                <ClickAwayListener onClickAway={this.handleCloseAction}>
                                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                    <MenuItem
                                      onClick={() => {
                                        if (this.isValid()) {
                                          this.handleSendPopup()
                                        }
                                      }}> {this.props.users.languageStateForRedux?.buttons?.campaigns?.sendNow}</MenuItem>

                                    {
                                      this.state.urlPathname === 'updateNoti' ? (
                                        <>
                                          <MenuItem
                                            onClick={() => {
                                              this.setState({ status: 'draft' },
                                                () => this.handleNotificationSubmit());
                                            }}
                                          >
                                            {this.props.users.languageStateForRedux?.common?.draft}
                                          </MenuItem>
                                          {this.state.status !== 'draft' && <MenuItem

                                            onClick={() => {
                                              if (this.isValid()) {
                                                this.setState({
                                                  status: 'scheduled'
                                                }, () => this.handleNotificationSubmit())
                                              }
                                            }}
                                          >
                                            Save as Scheduled
                                          </MenuItem>}
                                          <MenuItem
                                            onClick={() => {
                                              if (this.isValid()) {
                                                this.handleOpenScheduleModal()
                                              }
                                            }}
                                          >
                                            {this.state.status === 'draft' ? this.props.users.languageStateForRedux?.buttons?.campaigns?.schedule : 'Re-schedule'}
                                          </MenuItem>
                                        </>
                                      ) : (
                                        <>
                                          <MenuItem

                                            onClick={() => {
                                              this.setState({ status: 'draft' },
                                                () => this.handleNotificationSubmit())
                                            }}
                                          >
                                            {this.props.users.languageStateForRedux?.common?.draft}
                                          </MenuItem>

                                          <MenuItem
                                            onClick={() => {
                                              if (this.isValid()) {
                                                this.handleOpenScheduleModal()
                                              }
                                            }}
                                          >
                                            {this.props.users.languageStateForRedux?.buttons?.campaigns?.schedule}
                                          </MenuItem>
                                        </>
                                      )
                                    }
                                  </MenuList>
                                </ClickAwayListener>
                              </Box>
                            </Fade>
                          )}
                        </Popper>
                      </Box>
                    </Grid>
                    <Box component={Grid} ml={1}>
                      <CloseIcon
                        onClick={this.state.urlPathname === 'updateNoti' ? (
                          this.onCloseUpdateButton
                        ) : this.props.onCloseModal}
                        className="modal-close-button"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <form >
              <Box component='div' className="add-notification_modal__form_body">
                <Box px={3} pt={2} pb={4}>
                  <Box component={Grid} rowSpacing={4} columnSpacing={2.75} container className="inputField">
                    <Grid size={{xs: 12,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.title} <span className='required'>*</span></label>
                      <input
                        type="text"
                        class="form-control"
                        name="title"
                        placeholder=""
                        value={title}
                        maxLength={60}
                        onChange={this.handleChange}
                      />
                      <span className="error_mesage"> {errors.title} </span>
                    </Grid>
                    <Grid size={{xs: 12,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.layout}</label>
                      <div className="layout__possition">
                        <span
                          onClick={(e) => {
                            this.setState({ layoutType: 'center' })
                          }}
                          className={`layout__possition_center ${layoutType === 'center' ? 'active' : null
                            }`}
                        ></span>
                        <span
                          onClick={(e) => {
                            this.setState({ layoutType: 'bottom-left' })
                          }}
                          className={`layout__possition_bottom-left ${layoutType === 'bottom-left' ? 'active' : null
                            }`}
                        ></span>
                        <span
                          onClick={(e) => {
                            this.setState({ layoutType: 'bottom-right' })
                          }}
                          className={`layout__possition_bottom-right ${layoutType === 'bottom-right' ? 'active' : null
                            }`}
                        ></span>
                        <span
                          onClick={(e) => {
                            this.setState({ layoutType: 'top-left' })
                          }}
                          className={`layout__possition_top-left ${layoutType === 'top-left' ? 'active' : null
                            }`}
                        ></span>
                        <span
                          onClick={(e) => {
                            this.setState({ layoutType: 'top-right' })
                          }}
                          className={`layout__possition_top-right `}
                        ></span>
                      </div>
                    </Grid>
                  </Box>
                </Box>
                <Box>
                  <NotificationEditor
                    description={this.state.description}
                    handleEditorChange={this.handleEditorChange}
                  />
                </Box>
                <Box px={3} py={0.5}>
                  <span className="error_mesage required">
                    {this.state.errors.description}{' '}
                    {' '}
                  </span>
                </Box>
                <Box px={3} pt={2} pb={4}>
                  <Box component={Grid} rowSpacing={4} columnSpacing={2.75} container className="inputField">
                    <Grid size={{xs: 6,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.buttonText}</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={buttonText}
                        name="buttonText"
                        onChange={this.handleChange}
                      />
                      <span className="error_mesage"> {errors.buttonText} </span>
                    </Grid>
                    <Grid size={{xs: 6,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.buttonAction}</label>
                      <FormControl
                        size="small"
                        fullWidth
                      >
                      <Select
                          class="form-control select"
                          onChange={this.handleChange}
                          name="buttonAction"
                          value={buttonAction}
                          style={{ padding: '4px 0px', }}
                          MenuProps={MenuProps}
                          displayEmpty
                        >
                          {this.BUTTON_ACTION_DROP_DOWN.map((action) => (
                            <MenuItem key={action.key} value={action.value}>
                              {action.key}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {buttonAction === 'External URL' ? (
                      <Grid size={{xs: 12,}}>
                        <label></label>
                        {this.props.users.languageStateForRedux?.dropdowns?.agenda?.external}                       
                        <input
                          type="string"
                          class="form-control"
                          placeholder=""
                          value={externalURL}
                          name="externalURL"
                          onChange={(e) => {
                            this.handleChange(e)
                          }}
                        />
                        <p className="error_mesage"> {errors.externalURL} </p>
                      </Grid>
                    ) : null}

                    <Grid size={{xs: 6,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.color}</label>
                      <div className="color-swatch">
                        <div style={styles.swatch} onClick={this.handleClick}>
                          <input
                            type="text"
                            class="form-control"
                            value={this.state.primaryColor}
                            placeholder=""
                            onChange={() => { }}
                          />
                          <div style={styles.color} />
                        </div>
                        {this.state.displayColorPicker ? (
                          <div style={styles.popover}>
                            <div
                              style={styles.cover}
                              onClick={this.handleClose}
                            />
                            <SketchPicker
                              color={this.state.primaryColor}
                              onChange={this.handleColorChange}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Grid>

                    <Grid size={{xs: 12,}}>
                      <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectPage}</label>
                      <FormControl
                        size="small"
                        fullWidth
                      >
                      <Select
                        type="text"
                        class="form-control select small"
                        value={this.state.sendToPages}
                        name="sendToPages"
                        onChange={this.handleSendToPagesChange}
                        style={{ padding: '4px 0px', }}
                        MenuProps={MenuProps}
                        displayEmpty
                      >
                        <MenuItem value="All">{this.props.users.languageStateForRedux?.common?.all}</MenuItem>
                        <MenuItem value="Pages">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.pages}</MenuItem>
                      </Select>
                      </FormControl>
                    </Grid>
                    {this.state.sendToPages === "Pages" &&
                     <Grid size={{xs: 12,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.showSelected} <span className='required'>*</span></label>
                      <ReactSelect
                        styles={customStyles}
                        className="custom-multi-select"
                        options={this.state.pages}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={this.handleChangeMultiselect}
                        allowSelectAll={true}
                        value={this.state.displayOnPages}
                      />
                      <span className="error_mesage"> {errors.displayOnPages} </span>
                    </Grid>
                    }

                    <Grid size={{xs: 12,}}>
                      <label>{this.props.users.languageStateForRedux?.formfields?.notifications?.sendTo}</label>
                      <FormControl
                        size="small"
                        fullWidth
                      >
                        <Select
                          type="text"
                          class="form-control select small"
                          value={this.state.sendToGroups}
                          name="sendToGroups"
                          onChange={this.handleSendToGroupsChange}
                          style={{ padding: '4px 0px', }}
                          MenuProps={MenuProps}
                          displayEmpty
                        >
                          <MenuItem value="All">{this.props.users.languageStateForRedux?.common?.all}</MenuItem>
                          <MenuItem value="Groups">{this.props.users.languageStateForRedux?.dropdowns?.agenda?.groups}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {this.state.sendToGroups === "Groups" && <Grid size={{xs: 12,}}>
                      <Box component="div" style={{ position: 'relative', }} className='inputField' >

                        {this.state.sendToGroups === "Groups" && <Grid size={{xs: 12,}}>
                          <label>{this.props.users.languageStateForRedux?.dropdowns?.agenda?.selectGroups}</label>
                          <ReactSelect
                            styles={customStyles}
                            className="custom-multi-select"
                            options={this.state.groupsForReactSelect.filter(g => (g.source === "GoogleWorkspace" && g.showToGroup) || g.source === "Manually Added")}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                            }}
                            onChange={this.handleChangeMultiselectForGroups}
                            allowSelectAll={true}
                            value={this.state.groupsMultiSelectArray}
                            getOptionLabel={this.customOptionLabel}
                          />
                        </Grid>}

                        <p className="error_mesage"> {errors.sendToGroups} </p>
                      </Box>
                    </Grid>}
                  </Box>
                </Box>
              </Box>

            </form>
          </Grid>
        </Grid>


        <Modal
          open={sendNowModal}
          onClose={this.onCloseSendNowModal}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'Invite-User-Modal send-notification-popup',
          }}
          center
        >
          <div className="modal-wrapper">
            <div className="modal-header align-items-center">
              <img alt="" src={ReviewNotification} />
              <Box className='subtitle'>{this.props.users.languageStateForRedux?.formfields?.notifications?.review}</Box>
              <p className="small-subtitle">You're about to send a notification to:</p>
              <p className="small-subtitle">15 Recipients</p>{' '}
            </div>
            <div className="modal-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="my-3"
                    >
                      {this.props.users.languageStateForRedux?.buttons?.campaigns?.sendNow}
                    </Button>
                    <Button
                      onClick={this.onCloseSendNowModal}
                      fullWidth
                      color="primary"
                      className=""
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="modal-wrapper">
            <div className="modal-header align-items-center text-center">
              <h1>Sending notification in...</h1>
              TIMER
              <Button variant="outlined" className="mt-4">
                Cancel
              </Button>
            </div>
          </div>

          <div className="modal-wrapper">
            <div className="modal-header align-items-center text-center">
              <img alt="" src={NotificationSent} />
              <h1>Notification Sent</h1>
              <p>
                Great job! You have successfuly sent the <br />
                notification to 15 recipients
              </p>
              <Button variant="contained" color="primary" className="mt-4">
                Done
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.openScheduleModal} onClose={this.handleCloseScheduleModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component='form' container alignItems="center" justifyContent="center" className='modal-wrapper'>
            <Grid size={{xs: 12,}} pb={3}>
              <Box textAlign="center" mb={4}>
                <img alt="" src={CheckedInOut} />
              </Box>
              <Box component="p" textAlign="center" mb={1} className='subtitle'>{this.props.users.languageStateForRedux?.formfields?.notifications?.schedule}</Box>
            </Grid>
            <Box component={Grid} mb={5} size={{xs: "grow",}}>
              <label className='paragraph'>{this.props.users.languageStateForRedux?.column_names?.attendees?.choose}<span className="required">*</span></label>
              <LocalizationProvider style={{ width: 100, }} dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  renderInput={(params) => <StyledTextField variant='outlined' fullWidth size='medium' {...params} />}
                  value={this.state.scheduledDate}
                  minDate={new Date()}
                  onChange={(newDate) => {
                    this.setState({
                      scheduledDate: newDate
                    })
                  }}
                  inputFormat="MMM, dd yyyy, hh:mm a" 
                />
              </LocalizationProvider>
            </Box>

            <Grid size={{xs: 12,}}>

              <Grid component='form' spacing={2} container alignItems="center" justifyContent="center">

                <Grid size={{xs: 12,}}>
                  <p className="required small-subtitle"> {errors['earlyScheduledTime']} </p>
                  <Button fullWidth size='large' variant="contained" color="primary" disableElevation onClick={() => {
                    if (this.isValidScheduleDate()) {
                      this.setState({
                        status: 'scheduled'
                      }, () => this.handleNotificationSubmit())
                    }
                  }}>
                    {this.props.users.languageStateForRedux?.buttons?.campaigns?.schedule}
                  </Button>
                </Grid>
                <Grid size={{xs: 12,}}>
                  <Button fullWidth size='large' variant="text" color="primary" disableElevation onClick={this.handleCloseScheduleModal}>
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.sendPopup} onClose={this.handleSendPopupClose}
          classNames={{
            overlay: 'team_add-remove-people_overlay',
            modal: 'Invite-User-Modal send-notification-popup',
          }}
          center
        >
          <div className='modal-wrapper'>
            <div className='modal-header align-items-center'>
              <img alt="" src={ReviewNotification} />
              <Box className="subtitle">{this.props.users.languageStateForRedux?.formfields?.notifications?.review}</Box>

              {this.state.sendToGroups === 'All' && <p className="small-subtitle">{this.props.users.languageStateForRedux?.formfields?.notifications?.sendAll}</p>}
              {this.state.sendToGroups === 'Groups' && <p className="small-subtitle">{this.props.users.languageStateForRedux?.formfields?.notifications?.sendToGroups} {this.listOfGroupsSelected()}</p>}
            </div>
            <div className='modal-body'>
              <form>
                <div class="row">
                  <div class="col-12">
                    <Button fullWidth variant="contained" color="primary" className='my-3' disableElevation
                      onClick={() => {
                        this.setState({ status: 'sent' },
                          () => this.handleNotificationSubmit())
                      }}>

                      {this.props.users.languageStateForRedux?.buttons?.campaigns?.sendNow}
                    </Button>
                    <Button fullWidth color="primary" className='' disableElevation onClick={this.handleSendPopupClose}>
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal >
        {(this.props.loading || this.state.loading)? <Loader /> : null}
        <NotificationContainer />
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  projectNotifications: state.ProjectNotifications,
  projects: state.Projects,
  events: state.Events,
  attendees: state.Attendees,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listRequest: Actions.listProjectNotificationsRequest,
      createProjectNotification: Actions.projectNotificationRequest,
      editProjectNotification: Actions.editProjectNotificationRequest,
      updateProjectNotification: Actions.updateProjectNotificationRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      sortFilterAttendeesRequest: Actions.sortFilterAttendeesRequest,
      getProjectRequest: Actions.getProjectRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(addNotification))

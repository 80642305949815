//@ts-nocheck
import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import { Box, Grid } from '@mui/material';
import DocIcon from '../../../../../src/assets/images/icons/Doc-icon.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddFile from '../../../../../src/assets/images/icons/attach_file_add.svg';
import Avatar from '@mui/material/Avatar';
import { theme } from '../../../../theme/theme';
import Button from '@mui/material/Button';
import {
  OpportunityCommon_State,
  CommentsAndAttachments as CommentsAndAttachmentsType,
  Attachment,
} from 'ReduxStore/Reducers/opportunity/opportunityCommon.reducer';
import { ChangeEvent, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Actions } from 'ReduxStore/Actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'Components/Loader/loader';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import CloseIcon from '@mui/icons-material/Close';
import { bytesToSize } from 'helpers/common';
import { LinearProgress } from '@mui/material';
import { width } from 'pdfkit/js/page';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import { createNotification } from 'helpers';

const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: '32px',
    height: '32px',
    borderRadius: 16,
    fontSize: '12px',
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
    padding: '12px 16px',
  },
};

type AddOrEditCommentsAndAttachmentsProps = {
  id: string | undefined;
  type: 'opportunity' | 'account' | 'contact';
  isBeingEdited: boolean;
  commentAndAttachmentData: CommentsAndAttachmentsType | undefined;
  loggedInUser: any;
  getIcon: (fileType: string, fileExtension: string) => string;
  getIconForNewAttachment: (type: string) => string
  cancelEditCommentAndAttachment?: () => void
};

const newCommentAndAttachmentDefaultValue: CommentsAndAttachmentsType = {
  comment: '',
  attachments: [],
};

function AddOrEditCommentsAndAttachments({
  id,
  type,
  isBeingEdited,
  commentAndAttachmentData,
  loggedInUser,
  getIcon,
  getIconForNewAttachment,
  cancelEditCommentAndAttachment
}: AddOrEditCommentsAndAttachmentsProps) {
  const dispatch = useDispatch();
  const firstRender1 = useRef(true);

  const {
    createCommentAndAttachmentSuccess
  } = useSelector(
    (state: any) => state.OpportunityCommon,
  ) as OpportunityCommon_State;

  const {
    message: xpUsersWebSocketMessage
  } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  const [createOrEditComment, setCreateOrEditComment] =
    useState<CommentsAndAttachmentsType>(
      newCommentAndAttachmentDefaultValue as CommentsAndAttachmentsType,
    );

  const [newAttachments, setNewAttachments] = useState<Attachment[]>([]);

  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);

  const [progressMap, setProgressMap] = useState({});

  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);  

  useEffect(() => {
    if (commentAndAttachmentData) {
      setCreateOrEditComment(commentAndAttachmentData);
    }
  }, [commentAndAttachmentData]);

  useEffect(() => {
    if (firstRender1.current) {
      firstRender1.current = false;
    } else {
      if (createCommentAndAttachmentSuccess) {
        setCreateOrEditComment(newCommentAndAttachmentDefaultValue);
      }
    }
  }, [createCommentAndAttachmentSuccess]);  

  const saveComment = (e: ChangeEvent<HTMLInputElement>) => {
    setCreateOrEditComment({
      ...createOrEditComment,
      comment: e.target.value,
    });
  };

  const saveOrUpdateCommentAndAttachment = () => {
    if (id) {
      if (!isBeingEdited) {
        let bodyPayload: any = {
          type,
          id
        }
        if (createOrEditComment.comment) {
          bodyPayload.comment = createOrEditComment.comment;
        }
        if (createOrEditComment.attachments?.length) {
          bodyPayload.attachments = createOrEditComment.attachments
        }
        dispatch(Actions.createCommentAndAttachmentRequest(bodyPayload));
      } else {
        let bodyPayload: any = {
          type,
          id,
          commentsAndAttachmentsId: createOrEditComment._id
        }
        if (createOrEditComment.comment) {
          bodyPayload.comment = createOrEditComment.comment;
        }
        if (removedAttachments.length) {
          bodyPayload.removedAttachments = removedAttachments;
        }
        if (newAttachments.length) {
          bodyPayload.newAttachments = newAttachments;
        }
        dispatch(Actions.updateCommentAndAttachmentRequest(bodyPayload));
      }
    }
  };

  const cancelSaveOrUpdateCommentAndAttachment = () => {
    if(isBeingEdited){
      if(cancelEditCommentAndAttachment){
        cancelEditCommentAndAttachment()
      }
    }else{        
        setCreateOrEditComment(newCommentAndAttachmentDefaultValue);
    }    
  }

  const inputFile = useRef(null);
  const uploadAttachment = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  type FileType = 'image' | 'document' | 'video';

  interface Attachment {
    fileName: string;
    fileNameInS3: string; // This should be set based on your implementation
    fileType: FileType;
    fileExtension: string;
    fileSize: number;
  }
  
  const handleFilesUpload = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      if (id) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('type', type);
        
        const allFiles = [...e.target.files];
        const attachments: Attachment[] = [];
        let isValid = true;
  
        allFiles.forEach((file: File) => {
          // Define accepted formats and size limits
          const imageFormats = ['image/jpeg', 'image/png', 'image/gif'];
          const documentFormats = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'text/csv', // Added .csv support
          ];
          const videoFormats = ['video/mp4'];
  
          const imageSizeLimit = 25 * 1024 * 1024; // 25MB
          const documentSizeLimit = 50 * 1024 * 1024; // 50MB
          const videoSizeLimit = 50 * 1024 * 1024; // 50MB
  
          // Determine file type and size
          let fileType: FileType | undefined;
          if (imageFormats.includes(file.type) && file.size <= imageSizeLimit) {
            fileType = 'image';
          } else if (documentFormats.includes(file.type) && file.size <= documentSizeLimit) {
            fileType = 'document';
          } else if (videoFormats.includes(file.type) && file.size <= videoSizeLimit) {
            fileType = 'video';
          } else {
            isValid = false;
            if (!imageFormats.includes(file.type) && !documentFormats.includes(file.type) && !videoFormats.includes(file.type)) {
              createNotification('error', 'The uploaded file format is not supported');
            } else {
              const exceededLimit = imageFormats.includes(file.type) ? '25MB' : '50MB';
              createNotification('error', `The file exceeds the ${exceededLimit} limit`);
            }
            return; // Skip adding this file
          }
  
          // Add valid file to formData
          formData.append('attachments', file);
        });
  
        if (isValid) {
          if (!isBeingEdited) {
            setCreateOrEditComment((prevData) => ({
              ...prevData,
              attachments: [...prevData?.attachments, ...e.target.files],
            }));
          } else {
            let allAttachments = [...newAttachments, ...e.target.files]
            setNewAttachments(allAttachments);
          }
  
          dispatch(Actions.uploadAttachmentToCloudRequest(formData));
          setSaveButtonDisabled(true);
        }
      }
    }
  }

  const removeFileOfCreate = (i: number) => {
    if (createOrEditComment.attachments) {
      let attachments = createOrEditComment.attachments;
      attachments.splice(i, 1);
      setCreateOrEditComment({
        ...createOrEditComment,
        attachments: attachments,
      });
    }
  };

  const removeFileOfEdit = (id: string) => {
    if (createOrEditComment.attachments) {
      let attachments = createOrEditComment.attachments;
      const index = attachments.findIndex((obj) => obj._id === id);
      if (index !== -1) {
        attachments.splice(index, 1);
      }
      setCreateOrEditComment({
        ...createOrEditComment,
        attachments: attachments,
      });

      let removedIds = [...removedAttachments, id];
      setRemovedAttachments(removedIds);
    }
  };

  const removeFileOfNewAttachments = (i: number) => {
    let allAttachments = [...newAttachments];
    allAttachments.splice(i, 1);
    setNewAttachments(allAttachments);
  };

  useEffect(() => {  
    if(createOrEditComment.comment?.trim() == "" &&
      (createOrEditComment?.attachments && createOrEditComment.attachments.filter(file=>file.fileName != undefined).length == 0)
    ){
      setSaveButtonDisabled(true)
    }else if(createOrEditComment?.attachments && createOrEditComment.attachments.filter(file=>file.name != undefined).length > 0){
      setSaveButtonDisabled(true)
    }else if(newAttachments.filter(file=>file.name != undefined).length > 0){
      setSaveButtonDisabled(true)
    }else{
      setSaveButtonDisabled(false)
    }
  }, [createOrEditComment, newAttachments])
  

  useEffect(() => {
    if(xpUsersWebSocketMessage.message){
      if(xpUsersWebSocketMessage.message == `opp_${type}_uploading_attachment` && 
        xpUsersWebSocketMessage.type == type &&
        xpUsersWebSocketMessage.id == id
      ){
        if (xpUsersWebSocketMessage.progress > (progressMap[xpUsersWebSocketMessage.fileId] || 0)) {
          setProgressMap(prev => {
            return { 
              ...prev, 
              [xpUsersWebSocketMessage.fileId]: xpUsersWebSocketMessage.progress 
            };
          });
        }
      }      

      if(xpUsersWebSocketMessage.message == `opp_${type}_attachments_uploaded` && 
        xpUsersWebSocketMessage.type == type &&
        xpUsersWebSocketMessage.id == id
      ){
        if(!isBeingEdited){
          let attachments = createOrEditComment.attachments ? createOrEditComment.attachments: [];
          if(attachments.length){
            xpUsersWebSocketMessage.uploadedFiles.map(file=>{
              let fileIndex = attachments.findIndex(obj=>obj.name == file.fileName)
              attachments[fileIndex] = file;
            })
          }
          setCreateOrEditComment((prevData) => ({
            ...prevData,
            attachments: attachments
          }));                           
        }else{
          let attachments = newAttachments;
          if(attachments.length){
            xpUsersWebSocketMessage.uploadedFiles.map(file=>{
              let fileIndex = attachments.findIndex(obj=>obj.name == file.fileName)
              attachments[fileIndex] = file;
            })
          }
          setNewAttachments([...attachments])       
        }   
        setProgressMap({})   
        dispatch(Actions.setXpUsersWebSocketConnectionMessage({}));     
      }
    }
  }, [xpUsersWebSocketMessage]);

  return (
    <Box p={1.5}>
      <Stack spacing={1} className="inputField add-comment">
        <>
          <textarea
            className="form-control"
            placeholder="Add Comment"
            rows={3}
            onChange={saveComment}
            name="comment"
            value={createOrEditComment.comment}
          />
          <Stack
            mt={1}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            px={0.5}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Button
                style={{ minWidth: '30px' }}
                variant="text"
                color="primary"
                size="small"
                onClick={uploadAttachment}
              >
                <img src={AddFile} alt="add file" />
              </Button>
              <input
                type="file"
                id="file"
                multiple
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => handleFilesUpload(e)}
              />
            </Stack>
            <Stack direction={'row'} spacing={0.5}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={cancelSaveOrUpdateCommentAndAttachment}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={saveOrUpdateCommentAndAttachment}
                disabled={saveButtonDisabled}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </>

        {createOrEditComment.attachments && (
          <Box px={0.5} pb={0.5}>
          <Grid container spacing={0.5} className="attachments">
            {createOrEditComment.attachments.map((attachment, i) => (
              <Grid size={{md:6,}}>
                {!isBeingEdited ? (
                  <>
                    {attachment.fileName ?
                      <Box className="attachment-file">
                        <Box display={'flex'} alignItems={'center'}>
                          <Box className="file-icon">
                            <img
                              src={getIcon(attachment.fileType, attachment.fileExtension)}
                              alt="file-icon"
                            />
                          </Box>
                          <Box>
                            <Box className="file-name">{attachment.fileName}</Box>
                            <Box className="file-size">
                              {attachment.fileSize}
                            </Box>                        
                          </Box>
                        </Box>
                      
                        <Box
                          className="options"
                          onClick={() => removeFileOfCreate(i)}
                        >
                          <CloseIcon
                            style={{ fontSize: '18px' }}
                            className="options-button"
                            color="secondary"
                          />
                        </Box>
                      </Box>                      
                      :
                      <>
                        <Box className="attachment-file">
                          <Box display={'flex'} alignItems={'center'}>
                            <Box className="file-icon">
                              <img
                                src={getIconForNewAttachment(attachment.type)}
                                alt="file-icon"
                              />
                            </Box>
                            <Box>
                              <Box className="file-name">{attachment.name}</Box>
                              <Box className="file-size">
                                {bytesToSize(attachment.size)}
                              </Box>                        
                            </Box>
                          </Box>    
                        </Box>
                        <Box>
                          <LinearProgress style={{width: '100%'}} variant="determinate" value={progressMap[attachment.name] | 0} />
                        </Box>
                      </>
                    }
                  </>
                ) : (
                  <Box className="attachment-file">
                    <Box display={'flex'} alignItems={'center'}>
                      <Box className="file-icon">
                        <img
                          src={getIcon(
                            attachment.fileType,
                            attachment.fileExtension,
                          )}
                          alt="file-icon"
                        />
                      </Box>
                      <Box>
                        <Box className="file-name">{attachment.fileName}</Box>
                        <Box className="file-size">{attachment.fileSize}</Box>
                      </Box>
                    </Box>
                    <Box
                      className="options"
                      onClick={() => removeFileOfEdit(attachment._id)}
                    >
                      <CloseIcon
                        style={{ fontSize: '18px' }}
                        className="options-button"
                        color="secondary"
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          </Box>
        )}
        {isBeingEdited && (
          <Grid container spacing={0.5} className="attachments">
            {newAttachments.length > 0 &&
              newAttachments.map((attachment, i) => (  
                <Grid size={{md: 6,}}>
                  {attachment.fileName ?
                    <Box className="attachment-file">
                      <Box display={'flex'} alignItems={'center'}>
                        <Box className="file-icon">
                          <img
                            src={getIcon(attachment.fileType, attachment.fileExtension)}
                            alt="file-icon"
                          />
                        </Box>
                        <Box>
                          <Box className="file-name">{attachment.fileName}</Box>
                          <Box className="file-size">
                            {attachment.fileSize}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="options"
                        onClick={() => removeFileOfNewAttachments(i)}
                      >
                        <CloseIcon
                          style={{ fontSize: '18px' }}
                          className="options-button"
                          color="secondary"
                        />
                      </Box>
                    </Box>
                    :
                    <>
                      <Box className="attachment-file">
                        <Box display={'flex'} alignItems={'center'}>
                          <Box className="file-icon">
                            <img
                              src={getIconForNewAttachment(attachment.type)}
                              alt="file-icon"
                            />
                          </Box>
                          <Box>
                            <Box className="file-name">{attachment.name}</Box>
                            <Box className="file-size">
                              {bytesToSize(attachment.size)}
                            </Box>
                          </Box>
                        </Box>                      
                      </Box>
                      <Box>
                        <LinearProgress style={{width: '100%'}} variant="determinate" value={progressMap[attachment.name] | 0} />
                      </Box>
                    </>
                  }
                </Grid>
              ))}
          </Grid>
        )}
      </Stack>
    </Box>
  );
}

export default AddOrEditCommentsAndAttachments;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { unflatten, flatten } from 'flat'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Helmet } from 'react-helmet'
import { redirectTo } from '../../helpers'
import PageToolBar from '../../Components/PageToolBar'
import { Multiselect } from 'multiselect-react-dropdown'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getEventTemplate } from 'ReduxStore/API'
import { Modal } from 'react-responsive-modal'

class Resipients extends Component {
  progressInterval = 0

  constructor(props) {
    super(props)
    this.state = {
      title: 'Recipients',
      pageTitle: 'Recipients',
      loading: false,
      firstChar: '',
      eventId: '',
      templateId: '',
      openResipients: false,
      ids: [],
      group_id: '',
      emailDate: new Date(),
      sendStatus: 'sendNow',
      showDate: false,
      emailSubject: 'Event Invitation',
      errors: {},
      initialDate: new Date(),
      open: false,
      replyToEmail: '',
      emailSenderName: '',
    }
  }

  componentDidMount() {
    if ( this.props.params.eventId !== '') {
      this.setState({ eventId:  this.props.params.eventId })
      this.props.eventRequest( this.props.params.eventId)
    }

    if ( this.props.params.templateId !== '') {
      this.setState({ templateId:  this.props.params.templateId })

      getEventTemplate( this.props.params.templateId)
        .then((result) => {
          if (result.data.status === 200) {
            //createNotification('success', result.data.message);
            this.setState({ emailSubject: result.data.template.emailSubject })
          } else {
            createNotification('error', result.data.message)
          }
        })
        .catch(console.log)
    }

    if (this.props) {
      this.props.groupRequest()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.events.success === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
      const events = this.props.events.event 
      this.setState({
        replyToEmail: events.replyToEmail,
        emailSenderName: events.emailSenderName,
      })
    }
    if (
      this.props.events.emailSuccess === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
    if (
      this.props.events.error === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      createNotification('error', this.props.events.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })

    if (
      event.target.name === 'sendStatus' &&
      event.target.value === 'schedule'
    ) {
      this.setState({ showDate: true })
    } else if (
      event.target.name === 'sendStatus' &&
      event.target.value === 'sendNow'
    ) {
      this.setState({ showDate: false })
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  onSelect = (selectedList, selectedItem) => {
    let groupArray = []
    selectedList.map((data) => {
      groupArray.push(data.id)
    })
    let group =
      groupArray.length > 0 ? this.setState({ group_id: groupArray }) : null
  }

  onRemove = (selectedList, removedItem) => {
    let groupArray = []
    selectedList.map((data) => {
      groupArray.push(data.id)
    })

    let group =
      groupArray.length > 0 ? this.setState({ group_id: groupArray }) : null
  }

  isValid = () => { 
    const { emailSubject, group_id } = this.state
    let error = {}
    let formIsValid = true

    if (emailSubject === '' || emailSubject.trim().length === 0) {
      formIsValid = false
      error['emailSubject'] = '*Please enter email subject.'
    }
    if (group_id === '' || group_id.length === 0) {
      formIsValid = false
      error['group_id'] = '*Please select audience.'
    }
    
    this.setState({ errors: error })
    return formIsValid
  }
  handleSubmit = async (e) => {
    e.preventDefault() 
    if (this.isValid()) {
      const {
        emailSubject,
        emailDate,
        group_id,
        eventId,
        templateId,
      } = this.state
      this.props.eventEmailRequest({
        groupIds: group_id,
        eventId: eventId,
        templateId: templateId,
        sendStatus: this.state.sendStatus,
        emailDate: emailDate,
        emailSubject,
      })
      this.setState({ open: true }) 
    }
  }

  handleDate = (date) => { 
    this.setState({ emailDate: date })
  }

  analytics = () => {
    const { eventId, templateId } = this.state
    this.props.navigate(`/analytics/${eventId}/${templateId}`)
  }

  render() {
    const {
      pageTitle,
      loading,
      eventId,
      emailDate,
      sendStatus,
      showDate,
      emailSubject,
      errors,
      initialDate,
      group_id,
      open,
      emailSenderName,
      replyToEmail,
    } = this.state

    let options = []
    {
      this.props.groups.groups &&
        this.props.groups.groups.map((data, index) => {
          options.push({ id: data._id, groupName: data.groupName })
        })
    }

    return (
      <div className="editingPageWrapper">
        <Helmet>
          <title>IllumeetXP | {pageTitle}</title>
        </Helmet>
        <div className="editingDashboard">
          <PageToolBar
            showIcons={false}
            onSaveFn={() =>
              redirectTo(
                this.props.navigate,
                `analytics/${eventId}`,
                this.props.location.pathname,
              )
            }
            saveBtnLabel="Continue"
            showPreviewOption={false}
            showActiveBtn={false}
            showChangeConfirmation={false}
            // backTo={backTo}
          />

          <div className="editDBSteppers">
            <div class="projectStepRow mb-5">
              <div class="projectSteps">
                <ul class="projectStepColl fourStepColl">
                  <li class="active">
                    <span class="projectProcess singleDot"></span>
                    Recipients
                  </li>
                  <li>
                    <span class="projectProcess"></span>
                    Review
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="recipientsPage">
            <div class="recipientsMainsec">
              {/* <div class="fullSreenHeader inputField resipientsHeader1Sec">
                                <div class="headingRecipients">
                                    <h2>Contacts</h2>
                                </div>


                            </div> */}

              <div>
                <div className="rsvpEventfieldBox emailSchedulerModal">
                  <div className="completeRegisterboxes eventRegistrationPage">
                    <div className="eventFullInput reseventRegBox CRCircularBox recipeintsCard">
                      <div className="formSection p-0">
                        <form onSubmit={this.handleSubmit}>
                          <div class="buttonsRecipient field-group inputField">
                            <label>Audience Name</label>
                            <div class="rescipientsMulti">
                              {options && options.length > 0 && (
                                <Multiselect
                                  options={options} // Options to display in the dropdown
                                  selectedValues={[]} // Preselected value to persist in dropdown
                                  onSelect={this.onSelect} // Function will trigger on select event
                                  onRemove={this.onRemove} // Function will trigger on remove event
                                  displayValue="groupName" // Property name to display in the dropdown options
                                  showCheckbox={true}
                                  closeOnSelect={false}
                                  closeIcon="close"
                                  placeholder="Search Audience Name"
                                />
                              )}
                            </div>
                            <p className="error_mesage"> {errors.group_id} </p>
                          </div>
                          <div class="field-group inputField">
                            <label>Email Subject</label>
                            <input
                              type="text"
                              class="form-control"
                              name="emailSubject"
                              value={emailSubject}
                              readOnly
                            />
                            <p className="error_mesage">
                              {' '}
                              {errors.emailSubject}{' '}
                            </p>
                          </div>
                          <div class="field-group inputField">
                            <label>From Name</label>
                            <input
                              type="text"
                              class="form-control"
                              name="emailSenderName"
                              value={emailSenderName}
                              readOnly
                            />
                          </div>
                          <div class="field-group inputField">
                            <label>Reply To Email</label>
                            <input
                              type="text"
                              class="form-control"
                              name="replyToEmail"
                              value={replyToEmail}
                              readOnly
                            />
                          </div>
                          <div class="field-group inputField">
                            <label>Select when you want to send email</label>

                            <div className="paymentRadio2">
                              <label className="customRadioBtn mr-2">
                                <input
                                  type="radio"
                                  value="sendNow"
                                  name="sendStatus"
                                  checked={sendStatus === 'sendNow'}
                                  onChange={this.handleChange}
                                />
                                Now
                                <span className="radiobtn"></span>
                              </label>
                              <label className="customRadioBtn">
                                <input
                                  type="radio"
                                  value="schedule"
                                  name="sendStatus"
                                  checked={sendStatus === 'schedule'}
                                  onChange={this.handleChange}
                                />
                                Schedule Date
                                <span className="radiobtn"></span>
                              </label>
                            </div>
                          </div>
                          {showDate ? (
                            <div class="field-group inputField EmailSchdularRescipnt">
                              <label>Select datetime</label>
                              <DatePicker
                                selected={emailDate}
                                showTimeSelect
                                dateFormat="Pp"
                                onChange={this.handleDate}
                                minDate={initialDate}
                              />
                            </div>
                          ) : null}
                          <div className="editProfileBtn mb-3 submitForm justify-content-center">
                            <Button type="submit" className="themeBlueButton">
                              Continue & Send Email
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <NotificationContainer />
                {loading ? <Loader /> : null}
              </div>
            </div>

            <NotificationContainer />
            {loading ? <Loader /> : null}
          </div>
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <div className="campaignEmailpopup">
            <h2>Campaign status</h2>
            <div className="rsvpEventfieldBox emailSchedulerModal campaignStatusEmail">
              <div className="formSection">
                <p> Thank you</p>
                <button className="themeBlueButton" onClick={this.analytics}>
                  Go to analytics
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes,
  events: state.Events,
  groups: state.Groups,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      groupRequest: Actions.groupRequest,
      eventEmailRequest: Actions.eventEmailRequest,
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(Resipients)

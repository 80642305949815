import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'

class UpdateMemberModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      firstName: this.props.singleData.firstName
        ? this.props.singleData.firstName
        : '',
      lastName: this.props.singleData.lastName
        ? this.props.singleData.lastName
        : '',
      email: this.props.singleData.email ? this.props.singleData.email : '',
      phoneNumber: this.props.singleData.phoneNumber
        ? this.props.singleData.phoneNumber
        : '',
      _id: this.props.singleData._id ? this.props.singleData._id : '',
      singleData: this.props.singleData ? this.props.singleData : {},
      page_no: this.props.page_no ? this.props.page_no : 1,
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;

    if (
      this.props.members.updateSuccess === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('success', this.props.members.message)
      let st = prevState.loading ? this.setState({ loading: false }) : null
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: false,
      })
    }

    if (
      this.props.members.updateError === true &&
      this.props.members.message !== prevProps.members.message
    ) {
      createNotification('error', this.props.members.message, '')
      let st = prevState.loading ? this.setState({ loading: false }) : null
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  isValid = () => { 
    const { firstName, lastName, phoneNumber } = this.state
    let error = {}
    let formIsValid = true
    const mobPattern = /^[0-9]{10}$/

    if (firstName === '' || firstName.trim().length === 0) {
      formIsValid = false
      error['firstName'] = '*Please enter first name.'
    }
    if (lastName === '' || lastName.trim().length === 0) {
      formIsValid = false
      error['lastName'] = '*Please enter last name.'
    }
    if (phoneNumber === '') {
      formIsValid = false
      error['phoneNumber'] = '*Please enter phone number.'
    }
    if (phoneNumber && mobPattern.test(phoneNumber) === false) {
      formIsValid = false
      error['phoneNumber'] = '*Please enter a valid phone number.'
    } 
    this.setState({ errors: error })
    return formIsValid
  }

  handleSubmit = () => { 
    if (this.isValid()) { 
      const { firstName, lastName, phoneNumber, _id } = this.state
      const requestbody = { firstName, lastName, phoneNumber, id: _id }
      this.props.updateMemberRequest(requestbody)
      this.handleLoading() 
    }
  }

  render() {
    const {
      errors,
      loading,
      firstName,
      lastName,
      email,
      phoneNumber,
    } = this.state 
    return (
      <div>
        <h2>Update Members</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form>
              <div className="field-group inputField">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.firstName} </p>
              </div>
              <div className="field-group inputField">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.lastName} </p>
              </div>
              <div className="field-group inputField">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                />
                <p className="error_mesage"> {errors.phoneNumber} </p>
              </div>
              <div className="field-group inputField">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control emailLoweCase"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  readOnly
                />
                <p className="error_mesage"> {errors.email} </p>
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button
                  type="button"
                  className="themeBlueButton"
                  onClick={this.handleSubmit}
                >
                  Update Member
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  members: state.Members,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMemberRequest: Actions.updateMemberRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(UpdateMemberModal)

import React, { Component } from 'react'
import Loader from '../../Components/Loader/loader'
import { createNotification } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import '../../sass/main.scss'
import { Button } from '@mui/material'
import 'react-responsive-modal/styles.css'
import { EventEmailToUserAPI } from 'ReduxStore/API'

class TestEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      loading: false,
      open: this.props.importOpen ? this.props.importOpen : false,
      email: '',
    }
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  render() {
    const { loading, errors } = this.state 
    return (
      <div>
        <h2>Test Email</h2>
        <div className="rsvpEventfieldBox">
          <div className="formSection p-0">
            <form onSubmit={this.props.sendSampleEmail}>
              {/* <div class="field-group inputField fullWidthInput">
                                    <label>Email Subject</label>
                                    <input type="text" class="form-control" name="subject" value={this.props.subject} onChange={this.props.handleChange}/>
                                </div>*/}
              <div class="field-group inputField fullWidthInput">
                <label>Email</label>
                <input
                  type="text"
                  class="form-control emailLoweCase"
                  name="email"
                  value={this.props.email}
                  onChange={this.props.handleChange}
                />
              </div>
              <div className="editProfileBtn submitForm justify-content-center">
                <Button type="submit" className="themeBlueButton">
                  Sent Test Email
                </Button>
              </div>
            </form>
          </div>
        </div>
        <NotificationContainer />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default TestEmail

 // @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatInterfaceType,
  settingsInitialState,
} from 'ReduxStore/Reducers/aiChatbot.reducer';

interface FeedBack {
  _id?: string;
  type: string;
  from?: string;
  response?: any;
  comment?: string;
}

export interface Message {
  timestamp: string;
  _id?: string;
  id: string;
  text: string;
  prompt?: string;
  sender: 'user' | 'assistant' | 'system';
  feedback?: FeedBack;
}

interface ChatState {
  messages: Message[];
  activeChatId: string;
  activeUserId: string;
  widgetStyle?: ChatInterfaceType;
  openChatWidget?: boolean;
  isConnected: boolean;
  loading: boolean;
  diableSend: boolean;
  error: string | null;
  widgetLoaded: boolean;
  userEngaged?: boolean;
}

// Initial state for the chat
const initialState: ChatState = {
  messages: [],
  widgetLoaded: false,
  openChatWidget: false,
  widgetStyle: settingsInitialState.chatInterface,
  activeChatId: '',
  activeUserId: '',
  isConnected: false,
  loading: false,
  error: null,
  diableSend: false,
  userEngaged: false,
};

// Create a slice for chat
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setWidgetStyle(state, action: PayloadAction<ChatInterfaceType>) {
      state.widgetStyle = action.payload;
    },
    setMessages(state, action: PayloadAction<Message[]>) {
      state.messages = action.payload;
    },
    setActiveChatId(state, action: PayloadAction<string>) {
      state.activeChatId = action.payload;
    },
    setActiveUserId(state, action: PayloadAction<string>) {
      state.activeUserId = action.payload;
    },
    setIsConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setDisableSendButton(state, action: PayloadAction<boolean>) {
      state.diableSend = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setWidgetLoaded(state, action: PayloadAction<boolean>) {
      state.widgetLoaded = action.payload;
    },
    setOpenChatWidget(state, action: PayloadAction<boolean>) {
      state.openChatWidget = action.payload;
    },
    setUserEngaged(state, action: PayloadAction<boolean>) {
      state.userEngaged = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setMessages,
  setActiveChatId,
  setActiveUserId,
  setIsConnected,
  setWidgetStyle,
  setLoading,
  setDisableSendButton,
  setOpenChatWidget,
  setWidgetLoaded,
  setError,
  setUserEngaged
} = chatSlice.actions;

export default chatSlice.reducer;

//@ts-nocheck
import { theme } from 'theme/theme';
import { ThemeProvider } from '@mui/material';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Box, Button, Avatar } from '@mui/material';
import { Grid } from '@mui/material';
import { Modal } from 'react-responsive-modal';

import BackArrow from '../../../../../assets/images/icons/backArrowWhite.svg';
import PageIcon from '../../../../../assets/images/icons/pageIconSmall.svg';

//import MailNotificationIcon from '../../../../assets/images/icons/MailNotification.svg'
import WidgetsConfigurationIcon from '../../../../../assets/images/icons/WidgetsConfiguration.svg';
import WidgetsAppearanceIcon from '../../../../../assets/images/icons/WidgetsAppearance.svg';

import WidgetAppearance from './widgetAppearence';
import WidgetConfiguration from './widgetConfiguration';
//import MailNotification from './conigTabs/mailNotifications'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Actions } from 'ReduxStore/Actions';

import { WidgetState } from 'ReduxStore/Reducers/integrations/appsConfigModal.Reducer';
import Loader from 'Components/Loader/loader';

const styles = {
  pink: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: '40px',
    height: '40px',
  },
};
export interface WidgetModalProps {
  eventId: string;
  showModal: boolean;
  propertyId?: string;
  widgetId?: string;
  integrationId: string;
  onClose: () => void;
}
const WidgetConfigurationModal: React.FC<WidgetModalProps> = (props) => {
  const state: WidgetState = useSelector((store: any) => store.AppConfigModal);
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  useEffect(() => {
    const queryData = {
      integrationId: props.integrationId,
      eventId: props.eventId,
    };

    dispatch(Actions.tawkToGetWidget(queryData));
  }, [dispatch, props.eventId, props.integrationId]);

  useEffect(() => {
    if (state.success && state.actionType === 'TAWK_TO_UPDATE_WIDGET_SUCCESS') {
      props.onClose();
    }
    if (!state.success && state.actionType === 'TAWK_TO_GET_WIDGET_FAILURE') {
      dispatch(Actions.getAppIntegrations(props.eventId));
      props.onClose();
    }
  }, [dispatch, props, state.actionType, state.success]);
  if (state.loading) {
    return <Loader />;
  } 
  return (
    <ThemeProvider theme={theme}>
      {/* <Box mb={1}>Full Screen Modal</Box>
        <Button variant="outlined" disableElevation onClick={openModal}>
          Open Modal
        </Button> */}
      <Modal
        open={props.showModal}
        onClose={props.onClose}
        classNames={{
          overlay: 'full-screen_overlay',
          modal: 'full-screen_modal modal-default-close-none',
        }}
        center
      >
        <Box style={{ overflow: 'auto', background: '#ffffff', width: '100%' }}>
          <Box className="unlayer-header">
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid >
                <Grid container alignItems="center">
                  <Button
                    className="back-button"
                    onClick={props.onClose}
                    variant="text"
                  >
                    <img alt="" src={BackArrow} />
                  </Button>

                  <Avatar style={styles.pink}>
                    <img alt="" src={PageIcon} />
                  </Avatar>

                  <h3 className="page-title">Tawk.to </h3>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className="app-dashboard">
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid size={{xs: "grow",  lg:10, xl:8}} >
                <Box className="container-with-sidebar">
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    onSelect={(ind: number) => setSelectedTabIndex(ind)}
                  >
                    <Box className="project-sidebar">
                      <TabList>
                        <Tab>
                          <img alt="" src={WidgetsAppearanceIcon} />
                          <a>Widgets Appearance</a>
                        </Tab>
                        <Tab>
                          {' '}
                          <img alt="" src={WidgetsConfigurationIcon} />{' '}
                          <a>Widgets Configuration</a>
                        </Tab>
                        {/* <Tab>
                          <img
                            //   component="img"
                            //   ml={0.5}
                            alt=""
                            src={MailNotificationIcon}
                          />
                          <a>Mail Notification</a>
                        </Tab> */}
                      </TabList>
                    </Box>

                    <Box className="container-sidebar-right">
                      <TabPanel>
                        <Box mt={1.5}>
                          <Box mb={5} className="subtitle tab-title">
                            Widgets Appearance
                          </Box>
                          <WidgetAppearance onClose={props.onClose} />
                        </Box>
                      </TabPanel>

                      <TabPanel>
                        <Box mt={1.5}>
                          <Box mb={5} className="subtitle tab-title">
                            Widgets Configuration
                          </Box>
                          <WidgetConfiguration onClose={props.onClose} />
                        </Box>
                      </TabPanel>

                      {/* <TabPanel>
                        <Box mt={1.5}>
                          <Box mb={5} className="subtitle tab-title">
                            Mail Notification
                          </Box>
                          <MailNotification />
                        </Box>
                      </TabPanel> */}
                    </Box>
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default WidgetConfigurationModal;

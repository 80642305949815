import { call, put } from 'redux-saga/effects';
import { Actions } from '../../Actions';
import OpportunityAccountsAPI from 'ReduxStore/API/opportunity/opportunityAccountsAPI';
import { removeStorage } from 'helpers/common';

function* fetchAllOpportunityAccounts(action) {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = yield call(opportunityAccountsAPI.fetchAllOpportunityAccounts, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.fetchAllOpportunityAccountsSuccess(
            response.data.accounts,
            response.data.totalCount,
            response.data.contactsObj,
            response.data.filterCount
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchAllOpportunityAccountsError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.fetchAllOpportunityAccountsError(response.data.message));
      }
    } else {
      yield put(Actions.fetchAllOpportunityAccountsError(response.data.message));
    }
  }

function* fetchOpportunityAccount(action) {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = yield call(opportunityAccountsAPI.fetchOpportunityAccount, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.fetchOpportunityAccountSuccess(
            response.data.account,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.fetchOpportunityAccountError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.fetchOpportunityAccountError(response.data.message));
      }
    } else {
      yield put(Actions.fetchOpportunityAccountError(response.data.message));
    }
  }

function* createOpportunityAccount(action) {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = yield call(opportunityAccountsAPI.createOpportunityAccount, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.createOpportunityAccountSuccess(
            response.data.account,
            response.data.accounts,
            response.data.totalCount,
            response.data.contactsObj,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.createOpportunityAccountError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.createOpportunityAccountError(response.data.message));
      }
    } else {
      yield put(Actions.createOpportunityAccountError(response.data.message));
    }
  }

  function* updateOpportunityAccount(action) {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = yield call(opportunityAccountsAPI.updateOpportunityAccount, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.updateOpportunityAccountSuccess(
            response.data.updatedAccount,
            response.data.accounts,
            response.data.totalCount,
            response.data.contactsObj,
            response.data.filterCount,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.updateOpportunityAccountError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.updateOpportunityAccountError(response.data.message));
      }
    } else {
      yield put(Actions.updateOpportunityAccountError(response.data.message));
    }
  }

  function* deleteOpportunityAccount(action) {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const response = yield call(opportunityAccountsAPI.deleteOpportunityAccount, action.data);
    if (response.status === 200) {
      if (response.data.success) {
        yield put(
          Actions.deleteOpportunityAccountSuccess(
            response.data.accounts,
            response.data.totalCount,
            response.data.contactsObj,
          ),
        );
      } else if (response.data.status === 403) {
        yield put(Actions.deleteOpportunityAccountError(response.data.message));
        removeStorage();
      } else {
        yield put(Actions.deleteOpportunityAccountError(response.data.message));
      }
    } else {
      yield put(Actions.deleteOpportunityAccountError(response.data.message));
    }
  }

const opportunityAccountsSaga = {
    fetchAllOpportunityAccounts,
    fetchOpportunityAccount,
    createOpportunityAccount,
    updateOpportunityAccount,
    deleteOpportunityAccount
}

export default opportunityAccountsSaga
import React, { Component } from 'react'
import '../../sass/main.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import DashboardLayout from '../../Layouts/DashboardLayout'
import ClockBlue from '../../assets/images/clockBlue.png'
import MapBlue from '../../assets/images/mapBlue.png'
import UserBlue from '../../assets/images/userBlue.png'
import designSystemBG from '../../assets/images/designSystemBG.png'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { createNotification, redirectTo } from '../../helpers'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../Components/Loader/loader'
import moment from 'moment'

class ProjectType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBoxVisible: false,
      pageTitle: 'Event Debits',
      title: 'Event Debits',
      eventId: '',
      eventName: '',
      eventDomain: '',
      location: '',
      startDate: new Date(),
    }
  }

  componentDidMount() {
    if ( this.props.params.eventId !== '') {
      localStorage.setItem('pageType', 'new')
      this.setState({ eventId:  this.props.params.eventId })
      this.props.eventRequest( this.props.params.eventId)
      this.handleLoading()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.events) {
      if (
        this.props.events.success === true &&
        this.props.events.message !== prevProps.events.message
      ) {
        let st = prevState.loading ? this.setState({ loading: false }) : null
        const events = this.props.events.event

        this.setState({
          eventName: events.eventName,
          eventDomain: events.eventDomain,
          location: events.location,
          time: events.time,
          startDate: new Date(events.startDate),
          _id: events._id,
        })
      }
      if (
        this.props.events.error &&
        this.props.events.error !== prevProps.events.error
      ) {
        createNotification('error', this.props.events.message, '')
        this.handleLoading()
      }
    }
  }

  handleLoading = () => { 
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  toggleBox = () => {
    this.setState((prevState) => ({ isBoxVisible: !prevState.isBoxVisible }))
  }

  redirectPage = (url) => {}

  render() {
    const {
      isBoxVisible,
      title,
      pageTitle,
      eventId,
      loading,
      startDate,
      location,
      eventName,
      eventDomain,
      time,
    } = this.state
    let updateEvent = '/update-event/' + eventId
    let pageDesign = '/event-page-list/' + eventId
    let registrationEditing = '/event-form-list/' + eventId
    let loginFormDesign = '/login-page-list/' + eventId
    let editTemplateDesign = '/edit-template-design/' + eventId
    let emailCampaign = '/email-campaigns/' + eventId

    if (this.props.location.pathname.includes('member')) {
      updateEvent = '/member/update-event/' + eventId
      pageDesign = '/member/page-design/' + eventId
      registrationEditing = '/member/registration-editing/' + eventId
      editTemplateDesign = '/member/edit-template-design/' + eventId
      loginFormDesign = '/member/login-form-design/' + eventId
      emailCampaign = '/member/email-campaigns/' + eventId
    }

    const startDate1 = moment(startDate).format('MM/DD/YYYY')
    const startD = startDate1 + ' ' + time
    return (
      <DashboardLayout title={title} pageTitle={pageTitle}>
        <div className="dashboardMiddleArea">
          {loading ? (
            <Loader />
          ) : (
            <div className="typeProjectName">
              <div className="eventIDRow">
                <div className="eventIDTitle">
                  <h2>
                    {eventName}
                    <span className="eventDateMobile" onClick={this.toggleBox}>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </h2>
                </div>
              </div>
              <div className={`eventTypeDateLoc ${isBoxVisible ? 'Open' : ''}`}>
                <div className="eventTypeDate eventTypeVenue">
                  <ul>
                    <li>
                      <span className="venueIcon">
                        <img src={ClockBlue} alt="" />
                      </span>
                      <span className="venueTitle">Date &amp; Time</span>
                      <h3>{moment(startD).format('dddd, MM Do YYYY, LT')}</h3>
                    </li>
                  </ul>
                </div>
                <div className="eventTypeLoc eventTypeVenue">
                  <ul>
                    <li>
                      <span className="venueIcon">
                        <img src={MapBlue} alt="" />
                      </span>
                      <span className="venueTitle">Location</span>
                      <h3>{location}</h3>
                    </li>
                  </ul>
                </div>
                <div className="eventTypeHost eventTypeVenue">
                  <ul>
                    <li>
                      <span className="venueIcon">
                        <img src={UserBlue} alt="" />
                      </span>
                      <span className="venueTitle">Domain</span>
                      <h3>{eventDomain}</h3>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="projectDesignRsvpRow">
                <div className="rsvpFormSec">
                  <div className="designSystemImg rsvpFormInner">
                    <img src={designSystemBG} alt="" />
                    <div className="DesignSystemText">
                      <h4>
                        <span> Event Forms</span>
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor{' '}
                      </p>
                    </div>
                  </div>
                  <div className="TypeprojectCardTilte">
                    <h3>
                      <span> Event Forms</span>
                    </h3>
                  </div>
                  <div className="editEmailBttn">
                    <Link to={registrationEditing}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="projectDesignSystem">
                  <div className="designSystemImg">
                    <img src={designSystemBG} alt="" />
                    <div className="DesignSystemText">
                      <h4>
                        <span> Event Pages</span>
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor{' '}
                      </p>
                    </div>
                  </div>
                  <div className="TypeprojectCardTilte">
                    <h3>
                      <span>Event Pages</span>
                    </h3>
                  </div>
                  <div className="editEmailBttn">
                    <Link to={pageDesign}>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="customemailEdit">
                  <div className="editibaleEmailBox">
                    <img src={designSystemBG} alt="" />
                    <div className="DesignSystemText">
                      <h4>
                        Email<span>Campaigns </span>
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor{' '}
                      </p>
                    </div>
                  </div>
                  <div className="TypeprojectCardTilte">
                    <h3>
                      Email<span>Campaigns </span>
                    </h3>
                  </div>
                  <div className="editEmailBttn">
                    <Link to={emailCampaign}>
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              {/*
        <div className="createEventButton text-center">
          <Button 
            type="button"
              className="themeBlueButton"
              onClick={()=>redirectTo(this.props.navigate,`resipients/${eventId}`,this.props.location.pathname) }
            >
                Continue
          </Button>
        </div>*/}

              <NotificationContainer />
            </div>
          )}
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
  // loading: state.Loader.loading,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventRequest: Actions.listSingleEventRequest,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapActionsToProps)(ProjectType)

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { connect } from 'react-redux'
import MoreIcon from '../../../assets/images/icons/more.svg' 

import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import BadgeGold from '../../../assets/images/icons/Badge_Gold.svg'
import BadgeSilver from '../../../assets/images/icons/Badge_Silver.svg'
import BadgeBronze from '../../../assets/images/icons/Badge_Bronze.svg'
import { getProjectIdFromUrl } from '../../../helpers/common'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { theme } from '../../../theme/theme'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import CustomSwitch from '../../../Components/customSwitch'
import urlImg from '../../../assets/images/icons/url.svg'
import SearchIcon from '../../../assets/images/icons/search.svg'
import ImportIcon from '../../../assets/images/icons/import.svg'
import { Modal } from 'react-responsive-modal'
import { NotificationContainer } from 'react-notifications'
import { createNotification } from '../../../helpers'
import { ErrorSharp } from '@mui/icons-material'
import Loader from '../../../Components/Loader/loader'
import { hi } from 'date-fns/locale'
import Pagination from 'react-js-pagination'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { isValid } from 'date-fns'


export class GamificationLeaderBoards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gamificationGradesList: [],
      challengesList: [],
      searchText: '',
      page_no: 1,
      itemCount: 10,
      startCount: 1,
      endCount: 0,
      limit: 10,
      sort_by: 'updatedAt',
      order: 'desc',
      openEditGamificationGrade: false,
      badgePointsGold: '',
      badgePointsSilver: '',
      badgePointsBronze: '',
      gradesToUpdate: {},
      loading: false,
      openAddConfigrationChallenges: false,
      challengeName: '',
      points: null,
      status: '',
      page: '',
      countOptions: 'oneTime',
      page: '',
      errors: {},
      isChallengeEdit: false,
      challengeIdForEdit: false,
      openDeleteChallengeModal: false,
      challengeId: false,
      filterByStatus: {},
      statusForActiveClass: 'all',
      openEnabledModal: false,
      isDisabledButton: false      
    }
  }

  componentDidMount() {
    let projectId = getProjectIdFromUrl()
    this.setState(
      {
        projectId,
      },
      () => {
        this.listGamificationGrades()
        this.configrationChallenges()
      },
    )
  }

  listGamificationGrades = () => {
    this.props.listGamificationGrades(this.state.projectId)
  }

  configrationChallenges = () => {
    const {
      page_no,
      searchText,
      projectId,
      limit,
      sort_by,
      sortOrder,
      statusForActiveClass,
    } = this.state
    const body = {
      page_no: page_no,
      searchText:searchText.trim(),
      projectId,
      limit,
      sort_by,
      order: sortOrder,
      status: statusForActiveClass,
    }
    this.props.configrationChallenges(body)
    this.handleLoading()
  }

  changeEnableStatus = () => {
    const { projectId, id } = this.state
    const body = {
      projectId,
      status: 'enable',
      challengeId: id,
      lngCode: this.props.users.newLangState,
    }
    this.props.changeChallengeStatus(body)
    this.handleLoading()
  }
  changeDisableStatus = () => {
    const { projectId, id } = this.state
    const body = {
      projectId,
      status: 'disable',
      challengeId: id,
      lngCode: this.props.users.newLangState,
    }
    this.props.changeChallengeStatus(body)
    this.handleLoading()
  }

  configrationChallengesForFilter = (statusForFilter) => {
    const {
      page_no,
      searchText,
      projectId,
      limit,
      sort_by,
      sortOrder,
      status,
      statusForActiveClass,
    } = this.state
    this.setState({ statusForActiveClass })
    const body = {
      page_no: page_no,
      searchText:searchText.trim(),
      projectId,
      limit,
      sort_by,
      order: sortOrder,
      status: statusForActiveClass,
    }
    this.props.configrationChallenges(body)
    this.handleLoading()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        })
      } else {
        this.setState({
          endCount: parseInt(
            this.props.gamification?.totalConfigrationChallenges,
          ),
        })
      }
    }

    if (
      this.props.gamification.addConfigrationChallengesSuccess !==
      prevProps.gamification.addConfigrationChallengesSuccess &&
      this.props.gamification.addConfigrationChallengesSuccess === true
    ) {
      createNotification('success', this.props.gamification.message)
      this.openCloseConfigrationChallengesModel()
      this.configrationChallengesForFilter()
      this.setState({
        loading:false
      })
    }

    if (
      this.props.gamification.addConfigrationChallengesError !==
      prevProps.gamification.addConfigrationChallengesError &&
      this.props.gamification.addConfigrationChallengesError === true
    ) {
      createNotification('error', this.props.gamification.message)
      this.setState({
        loading:false
      })
    }

    if (
      this.props.gamification.deleteConfigrationChallengesSuccess !==
      prevProps.gamification.deleteConfigrationChallengesSuccess &&
      this.props.gamification.deleteConfigrationChallengesSuccess === true
    ) {
      createNotification('success', this.props.gamification.message)
      this.closeDeleteChallengeModal()
      this.configrationChallengesForFilter()
      this.setState({
        loading:false
      })
    }

    if (
      this.props.gamification.updateConfigrationChallengesSuccess !==
      prevProps.gamification.updateConfigrationChallengesSuccess &&
      this.props.gamification.updateConfigrationChallengesSuccess === true
    ) {
      if (this.props.gamification.isStatusUpdated === false) {
        createNotification('success', this.props.gamification.message)
      }
      this.openCloseConfigrationChallengesModel()
      this.configrationChallengesForFilter()
      this.setState({
        loading:false
      })
    }

    if (
      this.props.gamification.updateConfigrationChallengesError !==
      prevProps.gamification.updateConfigrationChallengesError &&
      this.props.gamification.updateConfigrationChallengesError === true
    ) {
      createNotification('error', this.props.gamification.message)
      this.setState({
        loading:false
      })
    }
    if (
      this.props.gamification.challengeStatusSuccess !==
      prevProps.gamification.challengeStatusSuccess &&
      this.props.gamification.challengeStatusSuccess === true
    ) {
      createNotification('success', this.props.gamification.message)
      this.handleCloseEnabledModal()
      this.configrationChallengesForFilter()
      this.setState({
        loading:false
      })
    }

    if (
      this.props.gamification.challengeStatusError !==
      prevProps.gamification.challengeStatusError &&
      this.props.gamification.challengeStatusError === true
    ) {
      createNotification('error', this.props.gamification.message)
      this.setState({
        loading:false
      })
    }

    if (
      this.props.gamification.listGamificationGradessuccess !==
      prevProps.gamification.listGamificationGradessuccess
    ) {
      this.setState({
        gamificationGradesList: this.props.gamification.gamificationGrades,
        loading:false
      })
    }
    if (
      this.props.gamification.configrationChallengesSuccess === true &&
      this.props.gamification.challengesList !==
      prevProps.gamification.challengesList
    ) {
      // finding if the selected service plan allows to create more challenges
      let userDetails = JSON.parse(localStorage.getItem('user_details'))
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.projectId
      }
      this.props.findIfAllowedFromSuperadminRequest(reqData)
      // finding if the selected service plan allows to create more challenges ends
      this.setState(
        {
          challengesList: this.props.gamification.challengesList,
          totalResults: this.props.gamification?.totalConfigrationChallenges,
          filterByStatus: this.props.gamification.filterByStatus,
          loading:false
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            })
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            })
          }
        },
      )
    }
    if (
      this.props.gamification?.listGamificationGradessuccess === true &&
      prevProps.gamification?.listGamificationGradessuccess === false
    ) {
      this.setState({
        gamificationGradesList: this.props.gamification.gamificationGrades,
        loading:false
      })
      this.props.gamification?.gamificationGrades?.forEach((obj) => {
        this.setState({
          [`badgePoints${obj.grade}`]: obj.points,
        })
      })
    }

    if (
      this.props.gamification?.updateGamificationGradessuccess === true &&
      prevProps.gamification?.updateGamificationGradessuccess === false
    ) {
      this.setState(
        {
          openEditGamificationGrade: false,
          loading:false
        },
        () => {
          this.listGamificationGrades()
          createNotification('success', this.props.gamification.message)
        },
      )
    }    
    
  }

  listGamificationGrades = () => {
    try {
      this.props.listGamificationGrades(this.state.projectId)
      this.handleLoading()
    } catch (error) { }
  }

  badgeIcon = (grade) => {
    if (grade === 'Gold') {
      return BadgeGold
    }
    if (grade === 'Silver') {
      return BadgeSilver
    }
    if (grade === 'Bronze') {
      return BadgeBronze
    }
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  getGradePoints = (grade) => {
    for (const gradeObj of this.props.gamification.gamificationGrades) {
      if (gradeObj.grade === grade) {
        return gradeObj.points
      }
    }
  }

  handleCloseEditGamificationGradeModal = () => {
    this.setState({
      openEditGamificationGrade: false,
      openEditChallengesList: false,
    })
  }

  handleOpenEditGamificationGradeModal = () => {
   let goldPoints = this.state.gamificationGradesList[0].points
   let silverPoints = this.state.gamificationGradesList[1].points
   let bronzePoints = this.state.gamificationGradesList[2].points
  
    this.setState({
      [`badgePointsGold`]:goldPoints,
      [`badgePointsSilver`]:silverPoints,
      [`badgePointsBronze`]:bronzePoints
    })
    this.setState({
      openEditGamificationGrade: true,
    })
  }
  openAddConfigrationChallengesModel = () => {
    this.setState({
      openAddConfigrationChallenges: true,
    })
  }

  openEditConfigrationChallengesModel = (challenges) => {
    const { challengeIdForEdit } = this.state
    this.setState({
      openAddConfigrationChallenges: true,
      isChallengeEdit: true,
      challengeIdForEdit: challenges._id,
      challengeName: challenges.challengeName,
      points: challenges.points,
      countOptions: challenges.countOptions,
    })
  }

  openDeleteChallengeModal = (id) => {
    this.setState({
      challengeId: id,
      openDeleteChallengeModal: true,
    })
  }

  handleDeleteConfirm = () => {
    try {
      const { challengeId ,projectId} = this.state
      let data={projectId,challengeId,lngCode:this.props.users.newLangState}
      this.props.deleteChallenge({ data })
      this.setState({
        page_no: 1,
        startCount: 1,
      })
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
    this.handleLoading()
  }

  closeDeleteChallengeModal = () => {
    this.setState({
      openDeleteChallengeModal: false,
    })
  }

  openCloseConfigrationChallengesModel = () => {
    this.setState({
      openAddConfigrationChallenges: false,
      challengeName: '',
      points: null,
      isChallengeEdit: false,
      countOptions: 'oneTime',
      errors: {},
    })
  }
  openCloseConfigrationChallenges

  handleGradeChange = (e, grade) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value),
     
    })
    this.setState({
      gradesToUpdate: {
        ...this.state.gradesToUpdate,
        [grade._id]: { ['points']: e.target.value },
      },
    })
  }

  handleEditChallenges = () => {
    try {
      const projectId = getProjectIdFromUrl()
      const {
        challengeName,
        points,
        countOptions,
        challengeIdForEdit,
        statusSwitch,
      } = this.state
      if (this.isValidAddChallengeForm()) {
        const data = {
          projectId,
          challengeName:challengeName.replace(/\s{2,}/g, ' ').trim(),
          points:parseInt(points),
          countOptions,
          challengeId: challengeIdForEdit,
          lngCode:this.props.users.newLangState,
        }
        this.props.updateChallenge(data)
        this.handleLoading()
      } else {
        createNotification('error', 'Please fill the input fields')
      }
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  handleStatusChange = (id, status) => {
    try {
      this.setState({
        statusForActiveClass: this.state.statusForActiveClass,
      })
      const projectId = getProjectIdFromUrl()
      const data = {
        projectId,
        challengeId: id,
        status: status === 'enable' ? 'disable' : 'enable',
      }
      this.props.updateChallenge(data)
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()
      this.setState(
        {
          page_no: 1,
          startCount:1
        },
        () => this.configrationChallenges(),
      )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
      },
      () => this.configrationChallenges(),
    )
  }

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults: this.props.gamification?.totalConfigrationChallenges,
      }),
      () => {
        this.configrationChallenges()
      },
    )
  }

  isValidAddChallengeForm = () => {
    try {
      const { challengeName, points } = this.state
      let formIsValid = true
      let error = {}

      if (
        challengeName.trim().length === 0 ||
        challengeName === '' ||
        challengeName === undefined
      ) {
        formIsValid = false
        error['challengeName'] = 'Enter challenge'
      }
      if (points === '' || points === null || points <= 0 ) {
        formIsValid = false
        error['points'] = 'Enter Points greater then 1'
      }
      this.setState({
        errors: error,
      })
      return formIsValid
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  handleAddChallenge = (e) => {
    try {
      if (this.isValidAddChallengeForm()) {
        const { challengeName, page, points, countOptions, projectId, status } =
          this.state

        const body = {
          challengeName:challengeName.replace(/\s{2,}/g, ' ').trim(),
          points:parseInt(points),
          page,
          status: 'enable',
          countOptions,
          projectId,
          lngCode: this.props.users.newLangState,
        }
        this.setState(
          {
            page_no: 1,
            startCount: 1,
          },
          () => this.props.addChallenges(body),
        )

        this.handleLoading()
      } else {
        createNotification('error', 'Please fill the input fields')
      }
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === 'asc') {
        this.setState(
          {
            sortOrder: 'desc',
          },
          () => this.configrationChallenges(),
        )
      } else {
        this.setState(
          {
            sortOrder: 'asc',
          },
          () => this.configrationChallenges(),
        )
      }
    } else {
      this.setState(
        {
          sort_by: value,
          sortOrder: 'asc',
        },
        () => {
          this.configrationChallenges()
        },
      )
    }
  }
  filterByStatus = (status) => {
    this.setState(
      {
        statusForActiveClass: status,
        page_no: 1,
        searchText: '',
        startCount: 1,
      },
      () => this.configrationChallengesForFilter(status),
    )
  }

  getFilterCount = (countOf) => {
    const { filterByStatus } = this.state
    if (filterByStatus && Object.keys(filterByStatus).length) {
      return filterByStatus[countOf] > 0 ? filterByStatus[countOf] : 0
    }
  }

  isValidGradePoints = (e) => {
    let isValid = true
    const { badgePointsGold, badgePointsSilver, badgePointsBronze } = this.state
    let error = {}
    if (
      badgePointsGold === '' ||
      badgePointsGold <= 0 ||
      isNaN(badgePointsGold)
    ) {
      error['Gold'] = this.props.users.languageStateForRedux?.formfields?.gamification?.greaterThan0
      isValid = false
    }

    if (
      badgePointsGold <= badgePointsBronze ||
      badgePointsGold <= badgePointsSilver
    ) {
      error['Gold'] = this.props.users.languageStateForRedux?.formfields?.gamification?.gold
      isValid = false
    }


    if (
      badgePointsSilver === '' ||
      badgePointsSilver <= 0 ||
      isNaN(badgePointsSilver)
    ) {
      error['Silver'] = this.props.users.languageStateForRedux?.formfields?.gamification?.greaterThan0
      isValid = false
    }

    if (
      badgePointsSilver >= badgePointsGold ||
      badgePointsSilver <= badgePointsBronze
    ) {
      error['Silver'] = this.props.users.languageStateForRedux?.formfields?.gamification?.silver
      isValid = false
    }

    if (
      badgePointsBronze === '' ||
      badgePointsBronze <= 0 ||
      isNaN(badgePointsBronze)
    ) {
      error['Bronze'] = this.props.users.languageStateForRedux?.formfields?.gamification?.greaterThan0
      isValid = false
    }

    if (
      badgePointsBronze >= badgePointsGold ||
      badgePointsBronze >= badgePointsSilver
    ) {
      error['Bronze'] = this.props.users.languageStateForRedux?.formfields?.gamification?.bronze
      isValid = false
    }


    this.setState({
      errors: error,
    })
    return isValid
  }

  submitGradesPoints = (e) => {
    try {
      if (this.isValidGradePoints()) {
        const requestBody = {
          projectId: this.state.projectId,
          grades: this.state.gradesToUpdate,
          lngCode: this.props.users.newLangState,
        }
        this.props.updateGamificationGrades(requestBody)
        this.handleLoading()
      }
    } catch (error) { }
  }

  handleClickOpenEnabledModal = (id, status) => {
    this.setState({
      isDisabledButton: true,
      id: id,
      status: status,
      openEnabledModal: true,
    })
  }

  handleClickOpenDisableModal = (id, status) => {
    this.setState({
      isDisabledButton: false,
      id: id,
      status: status,
      openEnabledModal: true,
    })
  }

  handleCloseEnabledModal = () => {
    this.setState({
      openEnabledModal: false,
    })
  }

  chooseFilter = (val) => {
    let temp;
    switch (val) {
      case 'Gold': temp = this.props.users.languageStateForRedux?.buttons?.gamification?.gold;   
      return temp;   
  
      case 'Silver': temp = this.props.users.languageStateForRedux?.buttons?.gamification?.silver;
      return temp;
  
      case 'Bronze': temp = this.props.users.languageStateForRedux?.buttons?.gamification?.bronze;
      return temp;
  
      default: temp = val;
      break;
    }
  }

  render() {
    const {
      statusForActiveClass,
      gamificationGradesList,
      challengesList,
      openAddConfigrationChallenges,
      challengeName,
      points,
      sortOrder,
      sort_by,
      page,
      countOptions,
      searchText,
      itemCount,
      startCount,
      endCount,
      status,
      isDisabledButton,
      filterByStatus
    } = this.state
    return (
      <div>
        <Box className="dashboard-table-card">
          <Box className="subtitle" mb={3} mt={2}>
          {this.props.users.languageStateForRedux?.column_names?.gamification?.leaderboard}
          </Box>
          <Box
            className=""
            sx={{
              backgroundColor: '#ffffff',
              border: '1px solid #EDECF5',
              borderRadius: '8px',
            }}
          >
            <Stack
              direction={'row'}
              px={3}
              py={1.5}
              alignItems="center"
              justifyContent={'space-between'}
              className="group-info-header"
            >
              <Box>
                <Grid container spacing={1} alignItems="center">
                  <Grid  >
                  <span className="paragraph bold">{this.props.users.languageStateForRedux?.column_names?.gamification?.grade}</span>
                  </Grid>
                </Grid>
              </Box>
              {this.props.allowed['Edit Gamification'] &&     
                <Box component="div">
                  <Button
                    fullWidth
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={this.handleOpenEditGamificationGradeModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.edit}
                  </Button>
                </Box>
              }
            </Stack>
            <Box
              className="subtitle-regular"
              sx={{ borderTop: '1px solid #eeeeee' }}
              p={3}
              pb={4}
            >
              <Box>
                <Grid container spacing={1} alignItems="center" flexWrap={{xs:"wrap", sm:"nowrap" }} sx={{ overflow: 'auto',}}>
                  {Array.isArray(gamificationGradesList) &&
                    gamificationGradesList.length ?
                    gamificationGradesList.map((grade) => {
                      return (
                        <Grid
                        size={{xs: 12, sm:4,
                          md: "grow",
                          xl:"grow",}}
                          
                          key={grade.grade}
                          sx={{minWidth: 'fit-content'}}
                        >
                          <Stack
                            direction={'row'}
                            alignItems={'start'}
                            spacing={2}
                            justifyContent={{ xs: 'center', sm: 'start' }}
                          >
                            <Box
                              component={'img'}
                              mt={0}
                              alt="icon"
                              src={this.badgeIcon(grade.grade)}
                            />
                            <Box>
                              <Box className="small-subtitle">
                                {this.chooseFilter(grade.grade)}
                              </Box>
                              <Box className="secondary">{grade.points}</Box>
                              <Box
                                component={'h5'}
                                className="text-primary-light"
                              >
                                {this.props.users.languageStateForRedux?.column_names?.gamification?.points}
                              </Box>
                            </Box>
                          </Stack>
                        </Grid>
                      )
                    }): null}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box className="subtitle" mb={3} mt={2}>
          {this.props.users.languageStateForRedux?.column_names?.gamification?.challengesList}
          </Box>
          <Grid container spacing={2} mb={2} className="top-filter-area">
            <Grid  >
              <Grid container spacing={1}>
                <Grid  >
                  <Box
                    component={Button}
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.background.light,
                      color: theme.palette.common.black,
                      textTransform: 'capitalize',
                      fontWeight: '400',

                      '&:hover, &.active': {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                      },
                    }}
                    className={statusForActiveClass === 'all' ? 'active' : null}
                    size="small"
                    disableElevation
                    onClick={() => this.filterByStatus('all')}
                  >
                    {this.props.users.languageStateForRedux?.common?.all} (
                    {filterByStatus && Object.keys(filterByStatus).length
                      ? this.getFilterCount('all')
                      : '0'}
                    )
                  </Box>
                </Grid>
                <Grid  >
                  <Box
                    component={Button}
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.background.light,
                      color: theme.palette.common.black,
                      textTransform: 'capitalize',
                      fontWeight: '400',

                      '&:hover, &.active': {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                      },
                    }}
                    className={
                      statusForActiveClass === 'enable' ? 'active' : null
                    }
                    size="small"
                    disableElevation
                    onClick={() => this.filterByStatus('enable')}
                  >
                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.enabledUpperCase} (
                    {filterByStatus && Object.keys(filterByStatus).length
                      ? this.getFilterCount('enable')
                      : '0'}
                    )
                  </Box>
                </Grid>
                <Grid  >
                  <Box
                    component={Button}
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.background.light,
                      color: theme.palette.common.black,
                      textTransform: 'capitalize',
                      fontWeight: '400',

                      '&:hover, &.active': {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                      },
                    }}
                    className={
                      statusForActiveClass === 'disable' ? 'active' : null
                    }
                    size="small"
                    disableElevation
                    onClick={() => this.filterByStatus('disable')}
                  >
                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.disabledUpperCase} (
                    {filterByStatus && Object.keys(filterByStatus).length
                      ? this.getFilterCount('disable')
                      : '0'}
                    )
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid  >
              <Grid container spacing={1.25} className="team-dash-right">
                <Grid   className="">
                  <form onSubmit={this.handleSearchSubmit}>
                    <TextField
                      name="searchText"
                      variant='standard'
                      placeholder={this.props.users.languageStateForRedux?.common?.search}
                      className="search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      value={searchText}
                      onChange={this.handleChange}
                    />
                  </form>
                </Grid>
                {this.props.allowed['Edit Gamification'] &&      
                  <Grid  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={this.openAddConfigrationChallengesModel}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.gamification?.add}
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <TableContainer className="list-table" style={{ minHeight: '300px', maxHeight: 'calc(100vh - 650px)', }}>
            <Table className="table" style={{minHeight: 'unset',}}>
              <TableHead style={{ position: 'sticky', top: '0', backgroundColor: '#f6f6fc', zIndex: 2, }}>
                <TableRow>
                  <TableCell
                    className={
                      'long-text ' +
                      (sort_by === 'challengeNameForSorting'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('challengeNameForSorting')}
                  >
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.challenges}{' '}
                  </TableCell>                 

                  <TableCell
                    className={
                      'text ' +
                      (sort_by === 'points'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('points')}
                  >
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.points}{' '}
                  </TableCell>
                  <TableCell
                    className={
                      'text' +
                      (sort_by === 'countOptions'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('countOptions')}
                  >
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.countOptions} {' '}
                  </TableCell>
                  <TableCell
                    className={
                      'status' +
                      (sort_by === 'status'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('status')}
                  >
                    {this.props.users.languageStateForRedux?.common?.status}{' '}
                  </TableCell>
                  <TableCell className="options" >{this.props.users.languageStateForRedux?.common?.action}</TableCell>
                </TableRow>
              </TableHead>

              {Array.isArray(challengesList) && challengesList?.length > 0 ? (
                <>
                  <TableBody>
                    {Array.isArray(challengesList) &&
                      challengesList.length &&
                      challengesList.map((challenges) => {
                        return (
                          <TableRow>
                            <TableCell className="long-text" style={{cursor: "pointer"}} onClick={() =>
                                      this.openEditConfigrationChallengesModel(
                                        challenges,
                                      )
                                    }>
                              {challenges.challengeName
                                ? challenges.challengeName
                                : '--'}
                            </TableCell>
                            <TableCell className="text">
                              {challenges.points ? challenges.points : '--'}
                            </TableCell>
                            <TableCell className="text">
                              {challenges.countOptions
                                ? (challenges.countOptions === 'oneTime' ? 'One Time': 'Every Time')
                                : '--'}
                            </TableCell>
                            <TableCell className="status">
                              {challenges.status &&
                              challenges.status.length &&
                              challenges.status === 'enable' ? 
                              this.props.users.languageStateForRedux?.dropdowns?.attendees?.enabledUpperCase
                             : (
                              <Box
                                style={{
                                  color: theme.palette.secondary.main,
                                }}
                              >
                                {this.props.users.languageStateForRedux?.dropdowns?.attendees?.disabledUpperCase}
                              </Box>
                            )}
                          </TableCell>
                          <TableCell className="options">
                            {this.props.allowed['Edit Gamification'] &&
                              <>
                                {' '}
                                <div className="options-button">
                                  <img alt="" src={MoreIcon} />{' '}
                                </div>
                                <div className="options-list">
                                  <div
                                    onClick={() =>
                                      this.openEditConfigrationChallengesModel(
                                        challenges,
                                      )
                                    }
                                  >
                                  {this.props.users.languageStateForRedux?.common?.edit}
                                  </div>

                                  <div
                                    onClick={() =>
                                      this.openDeleteChallengeModal(
                                        challenges._id,
                                      )
                                    }
                                    style={{ color: theme.palette.secondary.main, }}
                                  >
                                    {this.props.users.languageStateForRedux?.common?.delete}
                                  </div>

                              {challenges.status &&
                                 challenges.status.length &&
                                 challenges.status !== 'enable' ? (
                                  <div
                                    onClick={() =>
                                      this.handleClickOpenEnabledModal(
                                        challenges._id,
                                        challenges.status,
                                      )
                                    }
                                  >
                                    {this.props.users.languageStateForRedux?.actions?.gamification?.enable}
                                  </div>
                              ):(
                                  <div
                                    onClick={() =>
                                      this.handleClickOpenDisableModal(
                                        challenges._id,
                                        challenges.status,
                                      )
                                    }
                                  >
                                    {this.props.users.languageStateForRedux?.actions?.gamification?.disable}
                                  </div>
                              )}
                                </div>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </>
              ) : ( !this.state.loading && challengesList?.length === 0 && this.props.gamification?.configrationChallengesSuccess && 
                (<TableBody>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>
                    {this.props.users.languageStateForRedux?.common?.noRecords}
                    </TableCell>
                  </TableRow>
                </TableBody>)
              )}
            </Table>
          </TableContainer>

          {Array.isArray(challengesList) && challengesList?.length > 0 && (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              className="tablePagination"
            >
              <Grid  >
                <Box component="span" className="small-subtitle">
                {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {' '}
                  {this.props.gamification?.totalConfigrationChallenges}
                </Box>
              </Grid>
              <Grid  >
                <Grid container alignItems="center">
                  <Grid  >
                    <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                    <select
                      value={itemCount}
                      onChange={this.selectItemCount}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                  </Grid>
                  <Grid  >
                    <Pagination
                      activePage={this.state.page_no}
                      itemsCountPerPage={this.state.itemCount}
                      totalItemsCount={
                        this.props.gamification
                          ?.totalConfigrationChallenges
                      }
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      prevPageText={
                        <NavigateBeforeIcon style={{ fontSize: 18 }} />
                      }
                      nextPageText={
                        <NavigateNextIcon style={{ fontSize: 18 }} />
                      }
                      itemClassFirst="first d-none"
                      itemClassLast="last d-none"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>

        <Modal
          open={this.state.openEditGamificationGrade}
          onClose={this.handleCloseEditGamificationGradeModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.formfields?.gamification?.settings}
              </Box>
            </Grid>

            {Array.isArray(gamificationGradesList) &&
              gamificationGradesList.length &&
              gamificationGradesList.map((grade) => {
                return (
                  <Grid size={{xs: 12,}} pb={4} key={grade._id}>
                    <Grid container spacing={3.5} alignItems="start">
                      <Grid size={{xs: 2,}}>
                        <Box
                          component={'img'}
                          mt={0}
                          alt="icon"
                          src={this.badgeIcon(grade.grade)}
                        />
                      </Grid>
                      <Grid size={{xs: 10,}}>
                        <Box component="div" className="inputField">
                          <label>{this.props.users.languageStateForRedux?.formfields?.gamification?.pointsFor} {this.chooseFilter(grade.grade)} {this.props.users.languageStateForRedux?.formfields?.gamification?.badge}</label>
                          <input
                            type="number"
                            class="form-control small"
                            placeholder=""
                            name={`badgePoints${grade.grade}`}
                            value={this.state[`badgePoints${grade.grade}`]}
                            onChange={(e) => this.handleGradeChange(e, grade)}
                          />
                          <span className="error_mesage required">
                            {' '}
                            {this.state.errors[grade.grade]}{' '}
                          </span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.submitGradesPoints}
                  >
                    {this.props.users.languageStateForRedux?.overviewTab?.saveButton}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseEditGamificationGradeModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={openAddConfigrationChallenges}
          onClose={this.openCloseConfigrationChallengesModel}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="p" mb={1} className="subtitle">
              {this.state.isChallengeEdit === true
                      ? this.props.users.languageStateForRedux?.formfields?.gamification?.edit
                      : this.props.users.languageStateForRedux?.formfields?.gamification?.new}
              </Box>
              <Box className='small-subtitle'>
              {this.state.isChallengeEdit === true
                  ? this.props.users.languageStateForRedux?.formfields?.gamification?.editChallengeSubhead
                  : this.props.users.languageStateForRedux?.formfields?.gamification?.addChallengeSubhead}
              </Box>
            </Grid>
            <Grid size={{xs: 12,}} pb={4}>
              <Box component="div" className="inputField">
                <label>
                {this.props.users.languageStateForRedux?.formfields?.gamification?.challenge} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  class="form-control small"
                  value={challengeName}
                  placeholder={this.props.users.languageStateForRedux?.formfields?.gamification?.enter}
                  name="challengeName"
                  onChange={this.handleChange}
                />
                <span className="error_mesage required">
                  {' '}
                  {this.state.errors.challengeName}{' '}
                </span>
              </Box>
            </Grid>
            <Grid size={{xs: 12,}} pb={5}>
              <Grid container spacing={2.5}>
                <Grid size={{xs: 6,}} className="inputField">
                  <label>
                  {this.props.users.languageStateForRedux?.formfields?.gamification?.pointsCount} <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control small"
                    value={points}
                    placeholder={this.props.users.languageStateForRedux?.formfields?.gamification?.enterPoints}
                    name="points"
                    onChange={this.handleChange}
                  />
                  <span className="error_mesage required">
                    {' '}
                    {this.state.errors.points}{' '}
                  </span>
                </Grid>
                <Grid size={{xs: 6,}} className="inputField" pb={1}>
                  <label>{this.props.users.languageStateForRedux?.formfields?.gamification?.counted}</label>
                  <select
                    type="text"
                    class="form-control small"
                    value={countOptions}
                    name="countOptions"
                    onChange={this.handleChange}
                  >
                    <option value="oneTime">{this.props.users.languageStateForRedux?.formfields?.gamification?.oneTime}</option>
                    <option value="everyTime">{this.props.users.languageStateForRedux?.formfields?.gamification?.everyTime}</option>
                  </select>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      this.state.isChallengeEdit
                        ? this.handleEditChallenges
                        : this.handleAddChallenge
                    }
                  >
                    {this.state.isChallengeEdit === true
                      ? this.props.users.languageStateForRedux?.overviewTab?.saveButton
                      : this.props.users.languageStateForRedux?.buttons?.agenda.addOnly}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.openCloseConfigrationChallengesModel}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        {/* delete challenge */}
        <Modal
          open={this.state.openDeleteChallengeModal}
          onClose={this.closeDeleteChallengeModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">{this.props.users.languageStateForRedux?.common?.deleteMsg}</p>
            </Box>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevationon
                      onClick={() => this.handleDeleteConfirm()}
                    >
                      {this.props.users.languageStateForRedux?.common?.delete}
                    </Button>

                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={() => this.closeDeleteChallengeModal()}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={this.state.openEnabledModal}
          onClose={this.handleCloseEnabledModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{xs: 12,}} pb={3}>
              <Box component="p" mb={1} className="subtitle">
              {this.props.users.languageStateForRedux?.messages?.change} {isDisabledButton ? this.props.users.languageStateForRedux?.common?.enable: this.props.users.languageStateForRedux?.common?.disable}{' '}
              </Box>
              <p className="small-subtitle">
              {this.props.users.languageStateForRedux?.messages?.sure}{' '}
                  {isDisabledButton ? this.props.users.languageStateForRedux?.common?.enable: this.props.users.languageStateForRedux?.common?.disable} {this.props.users.languageStateForRedux?.messages?.selected}{' '} ?
              </p>
            </Grid>
            <Grid size={{xs: 12,}}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {isDisabledButton ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => this.changeEnableStatus()}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.gamification?.yes}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => this.changeDisableStatus()}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.gamification?.yes}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseEnabledModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        {this.state.loading ? <Loader /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  gamification: state.Gamification,
  project: state.Projects,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listGamificationGrades: Actions.listGamificationGradesRequest,
      configrationChallenges: Actions.configrationChallengesRequest,
      updateGamificationGrades: Actions.updateGamificationGradesRequest,
      addChallenges: Actions.addConfigrationChallengesRequest,
      updateChallenge: Actions.updateConfigrationChallengesRequest,
      deleteChallenge: Actions.deleteConfigrationChallengesRequest,
      changeChallengeStatus: Actions.challengeStatusRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GamificationLeaderBoards)

import { useState } from 'react'
import { Box } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/FileCopyOutlined'
import IconButton from '@mui/material/IconButton'

const CopyToClipBoard = ({ value }) => {
  const [isCopied, setIsCopied] = useState(false)
  return (
    <>
      <Box
        onClick={(e) => {
          navigator.clipboard.writeText(value).then(() => {
            setIsCopied(true)
            setTimeout(() => {
              setIsCopied(false)
            }, 2000)
          })
        }}
        ml={1}
        component={IconButton}
        size="small"
      >
        <ContentCopyIcon color="primary" />
      </Box>
      {isCopied && (
        <Box className="copy-record show" component={'span'}>
          Copied!
        </Box>
      )}
    </>
  )
}

export default CopyToClipBoard
